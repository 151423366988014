import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { AddExactasOddsDTO } from '@/types/odds/addOdds';

interface ADD_EXACTAS_EVENT_ODDS {
  sport: string;
  eventId: string;
  exactasType: number;
  payload: AddExactasOddsDTO;
}

const addExactasEventOdds = async ({
  sport,
  eventId,
  exactasType,
  payload,
}: ADD_EXACTAS_EVENT_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/events/${eventId}/exactas/odds/${exactasType}`,
    data: payload,
  });

  return data;
};

export default function useAddExactasEventOdds() {
  return useMutation(({ sport, eventId, exactasType, payload }: ADD_EXACTAS_EVENT_ODDS) =>
    addExactasEventOdds({ sport, eventId, exactasType, payload }),
  );
}
