import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { HIDDEN_SPORTS } from '@/constants/sportTypes';

async function getEvents({
  sportType,
  // queryParam,
  eventStatus,
}: {
  sportType: string;
  queryParam: string;
  eventStatus: number[] | undefined;
}) {
  if (HIDDEN_SPORTS.includes(sportType)) return [];

  const {
    data: { data = [] },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/events`,
    params: {
      eventStatus,
    },
  }).catch(() => {
    return { data: { data: [] } };
  });
  return data;
}

export default function useFetchEvents({
  sportType,
  queryParam,
  eventStatus,
  currentUser,
}: {
  sportType: string;
  queryParam: string;
  eventStatus?: number[];
  currentUser: any;
}) {
  return useQuery(
    [keys.events.fetchEvents, sportType, eventStatus],
    () => getEvents({ sportType, queryParam, eventStatus }),
    {
      staleTime: Infinity,
      enabled: currentUser ? true : false,
    },
  );
}
