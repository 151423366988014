import * as React from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled, Theme } from '@mui/material';

import RoundCard from '@/components/RoundCard';
import HeatCard from '@/components/HeatCard';
import RoundCardWithSwitch from '@/components/RoundCardWithSwitch';
import HeatCardWithSwitch from '@/components/HeatCardWithSwitch';
import FightCardWithSwitch from '@/components/FightCardWithSwitch';
import MatchCardJaiAlai from '@/features/scores/JAIALAI/components/MatchCard';
import MatchCardMASL from '@/features/scores/MASL/components/MatchCard';
import MatchCardPOWERSLAP from '@/features/scores/POWERSLAP/MatchCardPOWERSLAP';

import { RoundDTO } from '@/types/round';
import { HeatDTO } from '@/types/heat';
import { GameEvent, Match } from '@/types/game';

interface SliderProps {
  sliderItems: RoundDTO[] | HeatDTO[] | GameEvent[] | Match[];
  selectedItem?: RoundDTO | HeatDTO | null | any;
  handleItemSelect?: (round: RoundDTO | HeatDTO) => void;
  itemType: string;
  handleRoundPublish?: any;
  handleVoidHeat?: any;
  gameDateId?: string;
  setFieldValue?: any;
}

interface CardRef {
  id?: HTMLDivElement;
}

const ScrollButton = styled(IconButton)(({ theme }: { theme: Theme }) => ({
  borderRadius: '0',
  backgroundColor: 'white',
  position: 'absolute',
  bottom: '0',
  top: '0',
  border: 'none',
  boxShadow: '0 0rem 1rem rgba(0, 0, 0, 0.15)',
  opacity: '0',
  pointerEvents: 'none',
  padding: '0.375rem 0.75rem',
  ':hover': {
    backgroundColor: 'white',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.info.main,
    },
  },
}));

// const useCompare = (val: any) => {
//   const prevVal = usePrevious(val);
//   return `${prevVal}` !== `${val}`;
// };

// function usePrevious(value: any) {
//   const ref = React.useRef();
//   React.useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

function sanitizeFalsy(value: any, defaultValue = null) {
  const falsyStrings = [undefined, null, '', 'undefined', 'null', false, 0];

  return falsyStrings.includes(value) ? defaultValue : value;
}

function usePreviousWithLocalStorage(key: string, value: any) {
  const [prevValue, setPrevValue] = React.useState(() => {
    // Get the initial value from localStorage if it exists
    const savedValue = localStorage.getItem(key);
    return sanitizeFalsy(savedValue) ? JSON.parse(savedValue as string) : null;
  });

  React.useEffect(() => {
    // Save the current value to localStorage as the previous value
    if (value !== prevValue) {
      localStorage.setItem(key, JSON.stringify(value));
      setPrevValue(value);
    }
  }, [key, value, prevValue]);

  return prevValue;
}

const Slider = (props: SliderProps) => {
  const {
    sliderItems = [],
    selectedItem,
    handleItemSelect,
    itemType,
    handleRoundPublish,
    handleVoidHeat,
    gameDateId,
    setFieldValue,
  } = props;
  const sliderRef = React.useRef<HTMLDivElement>(null);
  const leftBtnRef = React.useRef<HTMLButtonElement>(null);
  const rightBtnRef = React.useRef<HTMLButtonElement>(null);
  const cardRefs = React.useRef<CardRef>({});

  const showHideScrollBtns = (sliderElem: HTMLDivElement) => {
    const leftBtn = leftBtnRef.current;
    const rightBtn = rightBtnRef.current;
    if (leftBtn && sliderElem.scrollLeft !== 0) {
      leftBtn.style.opacity = '1';
      enableBtn(leftBtn);
    } else if (leftBtn) {
      leftBtn.style.opacity = '0';
    }
    if (
      rightBtn &&
      Math.round(sliderElem.scrollWidth - sliderElem.offsetWidth) ===
        Math.round(sliderElem.scrollLeft)
    ) {
      rightBtn.style.opacity = '0';
    } else if (rightBtn) {
      rightBtn.style.opacity = '1';
      enableBtn(rightBtn);
    }
  };

  /* Prevent user from accidently clicking card item underneath a scroll button,
  when a button is being pressed continously. */
  const disableBtnsWhenReady = () => {
    const leftBtn = leftBtnRef.current;
    const rightBtn = rightBtnRef.current;
    if (leftBtn && leftBtn.style.opacity === '0') disableBtn(leftBtn);
    if (rightBtn && rightBtn.style.opacity === '0') disableBtn(rightBtn);
  };

  const enableBtn = (button: HTMLButtonElement) => (button.style.pointerEvents = 'all');
  const disableBtn = (button: HTMLButtonElement) => (button.style.pointerEvents = 'none');

  const onScroll = (event: any) => showHideScrollBtns(event.target);
  const onMouseMove = () => disableBtnsWhenReady();

  const prevSelectedItem = usePreviousWithLocalStorage('selectedItem', selectedItem || null);

  const hasSelectedItemChanged = prevSelectedItem?.id !== selectedItem?.id;

  /* Automatically scroll to the selected card item */
  React.useEffect(() => {
    if (hasSelectedItemChanged) scrollAction(selectedItem?.id || '');
  }, [selectedItem]);

  React.useEffect(() => {
    const slider = sliderRef.current;
    if (slider) {
      slider.addEventListener('scroll', onScroll);
      showHideScrollBtns(slider);
    }
    return () => {
      slider?.removeEventListener('scroll', onScroll);
    };
  }, [sliderRef.current]);

  /* Re-enable scroll button after mouse is moved,
  prevent card item underneath from being clicked accidently */
  React.useEffect(() => {
    const leftBtn = leftBtnRef.current;
    const rightBtn = rightBtnRef.current;
    if (leftBtn && rightBtn) {
      leftBtn?.addEventListener('mousemove', onMouseMove);
      rightBtn?.addEventListener('mousemove', onMouseMove);
    }
    return () => {
      leftBtn?.removeEventListener('mousemove', onMouseMove);
      rightBtn?.removeEventListener('mousemove', onMouseMove);
    };
  }, [leftBtnRef.current, rightBtnRef.current]);

  /* Scroll to cardId */
  const scrollAction = (cardId: string) => {
    cardRefs.current[cardId as keyof CardRef]?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    const slider = sliderRef.current;
    if (slider)
      slider.scrollTo({
        top: 0,
        left: slider.scrollLeft - 240,
        behavior: 'smooth',
      });
  };

  const scrollRight = () => {
    const slider = sliderRef.current;
    if (slider)
      slider.scrollTo({
        top: 0,
        left: slider.scrollLeft + 240,
        behavior: 'smooth',
      });
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        position: 'relative',
        marginTop: '1rem',
        overflow: 'hidden',
      }}
    >
      <ScrollButton
        ref={leftBtnRef}
        aria-label="slider left button"
        size="small"
        sx={{ left: '0', zIndex: '1' }}
        onClick={scrollLeft}
      >
        <NavigateBeforeIcon sx={{ fontSize: '1.25rem', fill: '#6C757D' }} />
      </ScrollButton>
      <Box
        ref={sliderRef}
        className={`${itemType}-slider hide-slider-scrollbar`}
        sx={{
          display: 'flex',
          whiteSpace: 'nowrap',
          width: '100%',
          overflowX: 'auto',
        }}
      >
        {React.Children.toArray(
          sliderItems.map((item: any, idx: number) => {
            if (idx === 0) cardRefs.current = {};
            if (itemType === 'rounds') {
              return (
                <RoundCard
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  round={item}
                  selectedRound={selectedItem as RoundDTO}
                  handleCurrentRound={handleItemSelect as Function}
                />
              );
            }
            if (itemType === 'heats') {
              return (
                <HeatCard
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  heat={item}
                  selectedHeat={selectedItem as HeatDTO}
                  handleCurrentHeat={handleItemSelect as Function}
                />
              );
            }

            /* Heat Winner Cards*/

            if (itemType === 'roundsHeatWinnerOdd') {
              return (
                <RoundCardWithSwitch
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  round={item}
                  selectedRound={selectedItem as HeatDTO}
                  handleCurrentRound={handleItemSelect as Function}
                  handleRoundPublish={handleRoundPublish}
                />
              );
            }

            if (itemType === 'heatsHeatWinnerOdd') {
              return (
                <HeatCardWithSwitch
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  heat={item}
                  selectedHeat={selectedItem as HeatDTO}
                  handleCurrentHeat={handleItemSelect as Function}
                  handleVoidHeat={handleVoidHeat}
                />
              );
            }

            if (itemType === 'fightWinnerOdd') {
              return (
                <FightCardWithSwitch
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  heat={item}
                  selectedHeat={selectedItem as HeatDTO}
                  handleCurrentHeat={handleItemSelect as Function}
                  handleVoidHeat={handleVoidHeat}
                />
              );
            }

            if (itemType === 'roundsExactaHeatOdd') {
              return (
                <RoundCardWithSwitch
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  round={item}
                  selectedRound={selectedItem as RoundDTO}
                  handleCurrentRound={handleItemSelect as Function}
                  handleRoundPublish={handleRoundPublish}
                />
              );
            }

            if (itemType === 'heatsExactaHeatOdd') {
              return (
                <HeatCardWithSwitch
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                  idx={idx}
                  heat={item}
                  selectedHeat={selectedItem as HeatDTO}
                  handleCurrentHeat={handleItemSelect as Function}
                  handleVoidHeat={handleVoidHeat}
                />
              );
            }

            if (itemType === 'matchCardJaiAlai') {
              return (
                <MatchCardJaiAlai
                  match={item}
                  gameDateId={gameDateId}
                  setFieldValue={setFieldValue}
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                />
              );
            }

            if (itemType === 'matchCardMASL') {
              return (
                <MatchCardMASL
                  match={item}
                  gameDateId={gameDateId}
                  setFieldValue={setFieldValue}
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                />
              );
            }

            if (itemType === 'matchCardPOWERSLAP') {
              return (
                <MatchCardPOWERSLAP
                  match={item}
                  ref={(el: any) => el && (cardRefs.current[item.id as keyof CardRef] = el)}
                />
              );
            }
          }),
        )}
      </Box>
      <ScrollButton
        ref={rightBtnRef}
        aria-label="slider right button"
        size="small"
        sx={{ right: '0' }}
        onClick={scrollRight}
      >
        <NavigateNextIcon sx={{ fontSize: '1.25rem', fill: '#6C757D' }} />
      </ScrollButton>
    </Box>
  );
};

export default Slider;
