import { IconButton, Typography } from '@mui/material';
import v from 'voca';
import * as React from 'react';

import {
  JASubMarketTypes,
  JABetTypes,
  JASubMarketTypesValues,
  JAMarketTypes,
} from '@/constants/jaialai';

import {
  // Styled Components
  GridCell,
  UnLockedIcon,
  LockedIcon,
  OddSubMarketLabel,
  CalculatedDecimalField,
  subMarketTypeStyles,
  // Normal Components
  CommonOddField,
} from '../components';
import {
  BACKGROUND_COLOR,
  impossibleOutcomeBoxShadowStyles,
  teamTypeToKey,
  TEXT_COLOR,
} from '../constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import DebugLabelDisplay from '../DebugLabelDisplay';
import { isImpossibleOutcome } from '../marketHelper';

const Outcome3Column = ({
  bet,
  oddType,
  toggleSubMarketLock,
  updateSubMarketTeam,
  updateSubMarketTeamKey,
  commonBoxShadowStyles,
  id,
}: {
  bet: any;
  oddType: number;
  toggleSubMarketLock: Function;
  updateSubMarketTeam: Function;
  updateSubMarketTeamKey: Function;
  commonBoxShadowStyles: any;
  id: string;
}) => {
  const { isDisabledMatch, isMatchOutcomeImpossible } = React.useContext(JAIALAIGameContext);
  const subMarketTypeNameKebabCase = v.kebabCase(
    JASubMarketTypesValues[JASubMarketTypes.OUTCOME_3 as keyof {}] || '',
  );
  const isSubMarketLocked =
    bet[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]?.isSubMarketLocked &&
    bet[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.awayTeam]?.isSubMarketLocked;

  const isCorrectSetsBetType =
    +bet[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]?.betType === JABetTypes.CORRECT_SETS;
  const isCurrentOutcomeImpossible =
    isCorrectSetsBetType &&
    isMatchOutcomeImpossible &&
    isImpossibleOutcome({ currentBet: bet, currentSubMkt: JASubMarketTypes.OUTCOME_3 });
  return (
    <GridCell
      sx={{
        gridArea: 'outcome3',
        justifyContent: 'start',
        display: 'grid',
        gridTemplateRows: 'repeat(3, minmax(0, 50px))',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '2px',
        borderBottomRightRadius: '0.25rem',
        backgroundColor: isCurrentOutcomeImpossible ? BACKGROUND_COLOR.SUSPENDED : '',
        ...(isCurrentOutcomeImpossible ? impossibleOutcomeBoxShadowStyles : commonBoxShadowStyles),
        ...subMarketTypeStyles({
          betType: +bet.marketType === JAMarketTypes.SETS ? bet.betType : +bet.betType,
          subMarketType: JASubMarketTypes.OUTCOME_3,
        }),
      }}
    >
      <GridCell
        sx={{
          gridArea: 'lock',
          boxShadow: 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{ color: TEXT_COLOR.GRAY, fontSize: '0.875rem', lineHeight: '125%' }}
          component={'span'}
        >
          {[JABetTypes.CORRECT_SETS].includes(+bet.betType)
            ? `${v.titleCase(JASubMarketTypesValues[JASubMarketTypes.OUTCOME_3 as keyof {}])}`
            : ``}
        </Typography>
        &nbsp;
        {![JABetTypes.CORRECT_SETS].includes(+bet.betType) && (
          <IconButton
            disableRipple
            onClick={(e) => {
              e.stopPropagation();
              const subMarketType = JASubMarketTypes.OUTCOME_3;
              toggleSubMarketLock(subMarketType);
            }}
            id={`toggle-${id}-${subMarketTypeNameKebabCase}-market-lock`}
            disabled={isDisabledMatch}
            {...{
              [`data-${id}-${subMarketTypeNameKebabCase}-market-lock`]: isSubMarketLocked,
            }}
          >
            {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
          </IconButton>
        )}
      </GridCell>

      {+bet.betType === JABetTypes.CORRECT_SETS && (
        <>
          <GridCell
            sx={{
              gridArea: 'homeTeamCalculatedValue',
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]} />
            <CalculatedDecimalField
              id={`${id}-${subMarketTypeNameKebabCase}-homeTeamCalculatedValue`}
              allowNegative
              value={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]?.calculatedValue}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_3,
                  teamType: teamTypeToKey.homeTeam,
                  key: 'calculatedValue',
                  val: +e.target.value || 0,
                });
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_3,
                  teamType: teamTypeToKey.homeTeam,
                  key: 'hasModifiedProbability',
                  val: true,
                });
              }}
              InputProps={{
                readOnly: true,
              }}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'awayTeamCalculatedValue',
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.awayTeam]} />
            <CalculatedDecimalField
              id={`${id}-${subMarketTypeNameKebabCase}-awayTeamCalculatedValue`}
              allowNegative
              value={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.awayTeam]?.calculatedValue}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_3,
                  teamType: teamTypeToKey.awayTeam,
                  key: 'calculatedValue',
                  val: +e.target.value || 0,
                });
                updateSubMarketTeamKey({
                  subMarketType: JASubMarketTypes.OUTCOME_3,
                  teamType: teamTypeToKey.awayTeam,
                  key: 'hasModifiedProbability',
                  val: true,
                });
              }}
              InputProps={{
                readOnly: true,
              }}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'odds',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: 'none',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]} />
            <CommonOddField
              id={`${id}-${subMarketTypeNameKebabCase}-homeTeam-odds`}
              oddType={oddType}
              bet={bet}
              subMarketType={JASubMarketTypes.OUTCOME_3}
              teamType={teamTypeToKey.homeTeam}
              updateSubMarketTeam={updateSubMarketTeam}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>
        </>
      )}

      {[JABetTypes.MATCH_WINNER, JABetTypes.TOTAL_SETS].includes(+bet.betType) && (
        <>
          <GridCell
            sx={{
              gridArea: 'calculatedValue',
              boxShadow: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <OddSubMarketLabel component="span">o</OddSubMarketLabel>
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]} />
            <CalculatedDecimalField
              id={`${id}-${subMarketTypeNameKebabCase}-homeTeamCalculatedValue`}
              allowNegative
              value={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]?.calculatedValue}
              onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                Object.keys(teamTypeToKey).forEach((key) => {
                  updateSubMarketTeamKey({
                    subMarketType: JASubMarketTypes.OUTCOME_3,
                    teamType: teamTypeToKey[key as keyof {}],
                    key: 'calculatedValue',
                    val: +e.target.value || 0,
                  });
                  updateSubMarketTeamKey({
                    subMarketType: JASubMarketTypes.OUTCOME_3,
                    teamType: teamTypeToKey[key as keyof {}],
                    key: 'hasModifiedProbability',
                    val: true,
                  });
                });
              }}
              InputProps={{
                readOnly: true,
              }}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
            <OddSubMarketLabel component="span">u</OddSubMarketLabel>
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'homeTeamOutcome3',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              boxShadow: 'none',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.homeTeam]} />
            <CommonOddField
              id={`${id}-${subMarketTypeNameKebabCase}-homeTeam-odds`}
              oddType={oddType}
              bet={bet}
              subMarketType={JASubMarketTypes.OUTCOME_3}
              teamType={teamTypeToKey.homeTeam}
              updateSubMarketTeam={updateSubMarketTeam}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'awayTeamOutcome3',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              boxShadow: 'none',
            }}
          >
            <DebugLabelDisplay row={bet?.[JASubMarketTypes.OUTCOME_3]?.[teamTypeToKey.awayTeam]} />
            <CommonOddField
              id={`${id}-${subMarketTypeNameKebabCase}-awayTeam-odds`}
              oddType={oddType}
              bet={bet}
              subMarketType={JASubMarketTypes.OUTCOME_3}
              teamType={teamTypeToKey.awayTeam}
              updateSubMarketTeam={updateSubMarketTeam}
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>
        </>
      )}
    </GridCell>
  );
};
export default Outcome3Column;
