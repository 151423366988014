import { useMutation } from '@tanstack/react-query';

import { WSLTraderEventSeedAddDTO } from '@/types/wsl/trader';
import { SLSTraderEventSeedAddDTO } from '@/types/sls/trader';
import { NRXTraderEventSeedAddDTO } from '@/types/nrx/trader';
import { SPRTraderEventSeedAddDTO } from '@/types/spr/trader';

import { authenticatedAxios } from '../../';

interface ADD_TRADER_EVENT_SEEDS {
  sport: string;
  requestPayload: {
    items:
      | WSLTraderEventSeedAddDTO
      | SLSTraderEventSeedAddDTO
      | NRXTraderEventSeedAddDTO
      | SPRTraderEventSeedAddDTO;
  };
}

const addTraderEventSeeds = async ({ sport, requestPayload }: ADD_TRADER_EVENT_SEEDS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/events/seeds`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderEventSeeds() {
  return useMutation(({ sport, requestPayload }: ADD_TRADER_EVENT_SEEDS) =>
    addTraderEventSeeds({ sport, requestPayload }),
  );
}
