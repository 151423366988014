import * as React from 'react';
import { Button } from '@mui/material';

import config from '@/config/vars';
import { FIELD_TO_DEBUG } from './constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';

const DebugBtn = ({ showDebugBtn = false }) => {
  const { setIsInTestMode, setCurrentFieldsToDebug } = React.useContext(JAIALAIGameContext);

  const handleToggleDebug = () => {
    if (config.isDevelop) {
      setIsInTestMode((prev: boolean) => !prev);
      /* Note:
  Add any field you want to debug to FIELD_TO_DEBUG.
  Use setCurrentFieldsToDebug to set the fields you want to debug. For example:
  setCurrentFieldsToDebug([FIELD_TO_DEBUG.id, FIELD_TO_DEBUG.playerLean])
  or setCurrentFieldsToDebug([FIELD_TO_DEBUG.id]).
           */
      setCurrentFieldsToDebug([
        // FIELD_TO_DEBUG.id,
        // FIELD_TO_DEBUG.isSubMarketLocked,
        // FIELD_TO_DEBUG.playerLean,
        FIELD_TO_DEBUG.probability,
      ]);
    }
  };

  if (!config.isDevelop) return null;

  return (
    showDebugBtn && (
      <Button onClick={handleToggleDebug} variant="outlined" disableElevation>
        Debug
      </Button>
    )
  );
};

export default DebugBtn;
