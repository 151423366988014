import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const isReactNode = (value: any) => React.isValidElement(value);

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1rem 1rem 0.5rem 1rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '0.5rem 1rem 0.5rem 1rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1rem 1rem 1rem',
  },
}));

// const LabelText = styled(Typography)({
//     color: 'info.main',
//     fontSize: '0.812rem',
//     fontWeight: '500',
//     marginBottom: '0.4rem',
// });

type DeleteConfirmDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  body: string | React.ReactNode;
  cancelBtnText?: string;
  confirmBtnText?: string;
  deleteText?: string;
};

const DeleteConfirmDialog = (props: DeleteConfirmDialogProps) => {
  const {
    open,
    handleClose,
    handleConfirm,
    title = 'Confirm Deletion',
    body = 'Data will be deleted permanently, to confirm deletion, type DELETE in the text input field.',
    cancelBtnText = 'Cancel',
    confirmBtnText = 'Delete',
    deleteText = 'DELETE',
  } = props;

  const [enableDelete, setEnableDelete] = React.useState(false);
  const [text, setText] = React.useState('');

  useEffect(() => {
    if (text === deleteText) {
      if (!enableDelete) {
        setEnableDelete(true);
      }
    } else {
      if (enableDelete) {
        setEnableDelete(false);
      }
    }
  }, [text]);

  return (
    <BootstrapDialog
      open={open}
      //   onClose={(_, reason) => {
      //     if (reason !== 'backdropClick') {
      //       resetEndDateHandler();
      //     }
      //   }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle sx={{ fontWeight: 500, fontSize: '1.2rem' }}>{title}</DialogTitle>
      <DialogContent>
        {isReactNode(body) ? <>{body}</> : <Typography>{body}</Typography>}

        <Grid container sx={{ marginTop: '0.8rem' }}>
          <TextField
            fullWidth
            value={text}
            onChange={({ target: { value } }) => {
              setText(value);
            }}
            placeholder={deleteText}
            sx={{
              fontWeight: '400',
              '& .MuiInputBase-input': {
                padding: '0.25rem',
                fontSize: '0.812rem',
              },
              '& .MuiInputBase-root': {
                padding: '0.40rem 0.6rem',
              },
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
          >
            <Button
              id="clickCancel"
              variant="contained"
              color="secondary"
              onClick={handleClose}
              fullWidth
              disableElevation
            >
              {cancelBtnText}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
          >
            <Button
              id="clickConfirm"
              variant="contained"
              onClick={handleConfirm}
              fullWidth
              disableElevation
              disabled={!enableDelete}
              color="error"
              //   sx={{ backgroundColor: '#f44336' }}
              startIcon={<DeleteIcon />}
            >
              {confirmBtnText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default DeleteConfirmDialog;
