import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

export interface UPDATE_EVENT_PAYLOAD {
  name?: string;
  tourYearId?: string;
  leagueYearId?: string;
  startDate?: string | Date | null;
  endDate?: string | Date | null;
  eventLocation?: string;
  eventLocationGroup?: string;
  eventStatus?: number;
  eventNumber?: number | string;
  isSimulationEnabled?: boolean;
  gender?: string;
  tourName?: string;
  year?: string | number;
}

interface UPDATE_EVENT {
  sport: string;
  eventId: string;
  requestPayload: UPDATE_EVENT_PAYLOAD;
}

const updateEvent = async ({ sport, eventId, requestPayload }: UPDATE_EVENT) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/events/${eventId}`,
    data: requestPayload,
  });

  return data;
};

export default function useUpdateEvent() {
  return useMutation(({ sport, eventId, requestPayload }: UPDATE_EVENT) =>
    updateEvent({ sport, eventId, requestPayload }),
  );
}
