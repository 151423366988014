import { Typography } from '@mui/material';
import { Column } from '@material-table/core';
import { tableCellStyle } from '@/utils/TableMisc';
import { FightMatchMemberTypesLabel } from '@/constants/fights';

const TableFightEventSeedsColumns: Column<any>[] = [
  { title: 'Athlete Id', field: 'athlete.id', hidden: true, cellStyle: tableCellStyle },
  {
    title: `Member Type`,
    field: 'role',
    cellStyle: tableCellStyle,
    editable: 'never',
    render: (rowData: any) => (
      <Typography sx={{ ...tableCellStyle }}>
        {FightMatchMemberTypesLabel[rowData?.role] || ''}
      </Typography>
    ),
  },
  {
    title: 'Name',
    field: 'name',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Hometown',
    field: 'homeTown',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Date Of Birth',
    field: 'dateOfBirth',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  { title: 'Federal Id', field: 'federalId', cellStyle: tableCellStyle, editable: 'never' },
  { title: 'Weight Class', field: 'weightClass', cellStyle: tableCellStyle, editable: 'never' },
  {
    title: `Height (ft'in'')`,
    field: 'height',
    cellStyle: tableCellStyle,
    editable: 'never',
    render: (rowData: any) => (
      <Typography sx={{ ...tableCellStyle }}>
        {`${rowData?.feet || 0}'${rowData?.inches || 0}''`}
      </Typography>
    ),
  },
  { title: 'Weight (lbs)', field: 'lbs', cellStyle: tableCellStyle, editable: 'never' },
];

export default TableFightEventSeedsColumns;
