import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';
import round from 'lodash.round';

import { sportsType } from '@/atoms/sportsType';
import Dropzone from '@/components/Dropzone';
import SPORT_TYPES, { sportOptions } from '@/constants/sportTypes';
import { WSLTraderEventSeed } from '@/types/wsl/trader';
import { SLSTraderEventSeed } from '@/types/sls/trader';
import { NRXTraderEventSeed } from '@/types/nrx/trader';
import { SPRTraderEventSeed } from '@/types/spr/trader';
import { AthleteStatusValues } from '@/constants/athletes';
import { processAthleteStatusBadges } from '@/helpers/athletes';
import useAddTraderEventSeeds from '@/hooks/traders/seeds/useAddTraderEventSeeds';
import {
  EventSeedRow,
  allowedTableHeaders,
  requiredPayloadKeys,
} from '@/pages/trader/seeds/upload/constants';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';
import routes from '@/constants/routes';
import { formatSecondsToMMSS } from '@/helpers/timeConverters';

type TRADER_EVENT_SEED =
  | WSLTraderEventSeed
  | SLSTraderEventSeed
  | NRXTraderEventSeed
  | SPRTraderEventSeed;
type TRADER_EVENT_SEEDS =
  | WSLTraderEventSeed[]
  | SLSTraderEventSeed[]
  | NRXTraderEventSeed[]
  | SPRTraderEventSeed[];

export default function SeedsUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const { mutate: addTraderEventSeeds } = useAddTraderEventSeeds();
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<TRADER_EVENT_SEEDS>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        const modSheetDTO = modSheet.map((data: TRADER_EVENT_SEED) => {
          const playerStatus = AthleteStatusValues[data?.playerStatusMod?.toUpperCase() || ''];
          delete data.playerStatusMod;
          const finalData = {
            ...data,
            playerStatus,
          };
          return finalData;
        });
        addTraderEventSeeds(
          {
            sport: selectedSport.toLowerCase(),
            requestPayload: {
              items: modSheetDTO,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Seeds Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders[selectedSport] || [];

      const formattedAllowedHeaders = headers.map((header: any) => {
        if (header.field === 'baseProjection' && selectedSport === SPORT_TYPES.WSL) {
          header.render = (rowData: WSLTraderEventSeed) => (
            <Typography>{round(+rowData?.baseProjection, 3)}</Typography>
          );
          return header;
        }
        if (header.field === 'baseProjection' && selectedSport === SPORT_TYPES.NRX) {
          header.render = (rowData: NRXTraderEventSeed) => (
            <Typography>{formatSecondsToMMSS(`${+rowData?.baseProjection}`)}</Typography>
          );
          return header;
        }
        if (header.field === 'baseHeadLapTime') {
          header.render = (rowData: NRXTraderEventSeed) => (
            <Typography>{formatSecondsToMMSS(`${+rowData?.baseHeadLapTime}`)}</Typography>
          );
          return header;
        }
        if (header.field === 'baseNonJokerLapTime') {
          header.render = (rowData: NRXTraderEventSeed) => (
            <Typography>{formatSecondsToMMSS(`${+rowData?.baseNonJokerLapTime}`)}</Typography>
          );
          return header;
        }
        if (header.field === 'baseRoundScore') {
          header.render = (rowData: SLSTraderEventSeed) => (
            <Typography>{round(+rowData?.baseRoundScore, 3)}</Typography>
          );
          return header;
        }
        if (header.field === 'baseRunScore') {
          header.render = (rowData: SLSTraderEventSeed) => (
            <Typography>{round(+rowData?.baseRunScore, 3)}</Typography>
          );
          return header;
        }
        if (header.field === 'baseTrickScore') {
          header.render = (rowData: SLSTraderEventSeed) => (
            <Typography>{round(+rowData?.baseTrickScore, 3)}</Typography>
          );
          return header;
        }
        if (header.field === 'trickCompletion') {
          header.render = (rowData: SLSTraderEventSeed) => (
            <Typography>{round(+rowData?.trickCompletion, 3)}</Typography>
          );
          return header;
        }
        if (header.field === 'playerStatusMod') {
          header.render = (rowData: SLSTraderEventSeed) =>
            processAthleteStatusBadges(
              AthleteStatusValues[String(rowData?.playerStatusMod || '').toUpperCase()],
            );
          return header;
        }

        return header;
      });

      setAllowedHeaders(formattedAllowedHeaders);
    }
  }, [selectedSport]);

  const handleChange = () => {
    setSheetData([]);
  };

  const tableRowChecker = (rows: EventSeedRow[]) => {
    let message = '';
    const index = rows.findIndex((row: EventSeedRow) => {
      if (
        !row.hasOwnProperty('athlete') ||
        !row.hasOwnProperty('stance') ||
        !row.hasOwnProperty('nationality') ||
        !row.hasOwnProperty('gender') ||
        !row.hasOwnProperty('event') ||
        !row.hasOwnProperty('status') ||
        !row.hasOwnProperty('seed_number') ||
        !row.hasOwnProperty('tour_year')
      )
        return true;

      switch (selectedSport) {
        case SPORT_TYPES.WSL:
          return !row.hasOwnProperty('base_projection');
        case SPORT_TYPES.NRX:
          return (
            !row.hasOwnProperty('base_solo') ||
            !row.hasOwnProperty('base_head_to_head') ||
            !row.hasOwnProperty('base_race')
          );
        case SPORT_TYPES.SLS:
          return (
            !row.hasOwnProperty('base_run_score') ||
            !row.hasOwnProperty('base_trick_score') ||
            !row.hasOwnProperty('trick_completion')
          );
        default:
          return false;
      }
    });
    if (index !== -1) {
      const row = rows[index];
      let missing_columns = '';
      const requiredKeys = requiredPayloadKeys[selectedSport];
      requiredKeys.map((key) => {
        if (!row[key as keyof EventSeedRow]) missing_columns += `${key} `;
        return key;
      });
      missing_columns = missing_columns.split(' ').join(', ').slice(0, -2);
      message = `Error on row { ${index + 2} }, Missing { ${missing_columns} }`;
    }

    return message;
  };

  const tableDataModifierFn = (data: EventSeedRow) => {
    const {
      athlete,
      stance,
      nationality,
      gender,
      event,
      status,
      notes,
      seed_number = 0,
      tour_year,
      base_projection = 0,
      base_run_score = 0,
      base_trick_score = 0,
      trick_completion = 0,
      base_solo = 0,
      base_head_to_head = 0,
      base_race = 0,
      head_to_head_crash_rate = 0,
      race_crash_rate = 0,
      solo_crash_rate = 0,
      tier,
      tier_seed,
    } = data;

    const nameSplit = athlete?.trim().split(' ');
    const athleteFirstName = nameSplit?.[0]?.trim();
    const athleteMiddleName = nameSplit.slice(1, -1).join(' ');
    // to avoid having the first name also as the last name
    const athleteLastName = nameSplit.length > 1 ? nameSplit?.[nameSplit.length - 1]?.trim() : '';

    return {
      firstName: athleteFirstName,
      middleName: athleteMiddleName,
      lastName: athleteLastName,
      nationality,
      stance,
      gender,
      seed: seed_number,
      eventName: event,
      playerStatusMod: status,
      notes,
      ...([SPORT_TYPES.WSL].includes(selectedSport) && {
        tourYear: tour_year,
        baseProjection: base_projection,
        tier: tier || '',
        tierSeed: tier_seed ? `${tier_seed}` : '',
      }),
      ...(selectedSport === SPORT_TYPES.SLS && {
        leagueYear: tour_year,
        baseRoundScore: +base_projection,
        baseRunScore: +base_run_score,
        baseTrickScore: +base_trick_score,
        trickCompletion: +trick_completion,
      }),
      ...(selectedSport === SPORT_TYPES.NRX && {
        tourYear: tour_year,
        baseProjection: +base_solo,
        baseHeadLapTime: +base_head_to_head,
        baseNonJokerLapTime: +base_race,
        headCrashRate: round(head_to_head_crash_rate, 2),
        raceCrashRate: round(race_crash_rate, 2),
        soloCrashRate: round(solo_crash_rate, 2),
      }),
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.traders.seeds.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Seeds
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Seeds"
        sheetName="Event Seed"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />

      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
          >
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} SEEDS?`}
          body={'This will upload the seeds.'}
        />
      )}
    </Box>
  );
}
