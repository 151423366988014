import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { PropBetsOddsDTO } from '@/types/odds/getOdds';

const getPropBetOdds = async (sport: string, eventId: string): Promise<PropBetsOddsDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/propBets/odds`,
  });

  return data;
};

export default function useFetchPropBetOdds(sport: string, eventId: string) {
  return useQuery(
    [keys.odds.fetchPropBetOdds, sport, eventId],
    () => getPropBetOdds(sport, eventId),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
