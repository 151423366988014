import { useQuery } from '@tanstack/react-query';

import { authenticatedAxios } from '..';
import keys from '@/constants/queryKeys';
import { GameDTO } from '@/types/game';

interface FETCH_GAME_HEAT_SCORES {
  sportType: string;
  gameDateId?: string;
  params?: any;
}

const getGameHeatScores = async ({
  sportType,
  gameDateId,
  params,
}: FETCH_GAME_HEAT_SCORES): Promise<GameDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/traders/scores/games/heatScore/${gameDateId}`,
    params,
  });

  return data;
};

export default function useFetchGameHeatScores({
  sportType,
  gameDateId,
  params,
}: FETCH_GAME_HEAT_SCORES) {
  return useQuery(
    [keys.games.fetchGameHeatScores, sportType, gameDateId],
    () => getGameHeatScores({ sportType, gameDateId, params }),
    {
      staleTime: Infinity,
      enabled: !!gameDateId,
    },
  );
}
