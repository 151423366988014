import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getEventLocationGroup = async (
  sportType: string,
): Promise<{ id: string; eventLocationGroup: string }[]> => {
  const query = new URLSearchParams();
  query.append('sportType', sportType);
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/traders/eventLocationGroups?${query.toString()}`,
  });

  return data;
};

export default function useFetchEventLocationGroup(sportType: string, fromComponent: string) {
  return useQuery(
    [keys.events.fetchEventLocationGroup, sportType, fromComponent],
    () => getEventLocationGroup(sportType),
    {
      staleTime: Infinity,
    },
  );
}
