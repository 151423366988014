import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface UPDATE_POWERSLAP_SCORES {
  sport: string;
  eventId: string;
  roundHeatId: string;
  requestPayload: any;
}

const updatePOWERSLAPScores = async ({
  sport,
  eventId,
  roundHeatId,
  requestPayload,
}: UPDATE_POWERSLAP_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/traders/${sport}/${eventId}/round/${roundHeatId}/score`,
    data: requestPayload,
  });

  return data;
};

export default function useUpdatePOWERSLAPScores() {
  return useMutation(({ sport, eventId, roundHeatId, requestPayload }: UPDATE_POWERSLAP_SCORES) =>
    updatePOWERSLAPScores({ sport, eventId, roundHeatId, requestPayload }),
  );
}
