import React from 'react';
import MaterialTable from '@material-table/core';

import { Tab, Box, styled, Typography, Button, Grid, Breadcrumbs, TabProps } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { ThemeProvider } from '@mui/material/styles';

// import DownloadIcon from '@mui/icons-material/Download';
import Editicon from '@/images/icons/table-edit-icon.png';
import Deleteicon from '@/images/icons/table-delete-icon.png';
import AddIcon from '@mui/icons-material/Add';

import { oddsTablesTheme } from '@/constants/theme';

import { tableIcons, defaultTableOptions, createTablePageOptions } from '@/utils/TableMisc';

import useUserManagement from './useUserManagement';
import TableUserColumns from './TableUserColumns';
import TableClientColumns from './TableClientColumns';
import { BreadcrumbLink } from '@/components/EventBreadcrumbs';
import routes from '@/constants/routes';
import AddOrEditUserModal from './AddOrEditUserModal';
import AddOrEditClientModal from './AddOrEditClientModal';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import useDeleteUser from '@/hooks/user/useDeleteUser';
import useDeleteClient from '@/hooks/user/useDeleteClient';
import { useLocation, useNavigate } from 'react-router-dom';
// import { excelFileDownloader } from '@/helpers/fileDownloader';

const MuiTabs = styled(TabList)({
  marginTop: '0.25rem',
  marginBottom: '0.75rem',
  minHeight: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const MuiTab = styled((props: TabProps) => <Tab component="span" disableRipple {...props} />)(
  ({ theme }) => ({
    minHeight: 'unset',
    textTransform: 'none',
    alignItems: 'flex-start',
    color: theme.palette.info.contrastText,
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    minWidth: 'auto',
    maxWidth: 'auto',
    padding: 0,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'white',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    paddingRight: '1rem',
  }),
);

const MuiTabPanel = styled(TabPanel)({
  // padding: '1rem 0',
  // maxWidth: '100%',
  padding: '0.5rem 0',
});

const TAB_OPTIONS = [
  { label: 'Users', value: '1' },
  { label: 'Clients', value: '2' },
];

// const RESULT_TYPE = {
//   match: 'Match',
//   round: 'Round',
// };

const TABS = {
  USER: '1',
  CLIENT: '2',
};

const QUERY_PARAM = 'tab';

const DEFAULT_TAB = TABS.USER;

export default function UserManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userManagementTab = queryParams.get(QUERY_PARAM);

  const queryClient = useQueryClient();
  // const [value, setValue] = useState('1');

  React.useEffect(() => {
    if (!userManagementTab && DEFAULT_TAB) {
      const redirectParams = new URLSearchParams();
      redirectParams.set(QUERY_PARAM, DEFAULT_TAB);
      navigate(
        {
          pathname: location.pathname,
          search: redirectParams.toString(),
        },
        { replace: true },
      );
    }
  }, [userManagementTab, DEFAULT_TAB]);

  const [showAddOrEditUserDialog, setShowAddOrEditUserDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>(null);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = React.useState(false);

  const toggleShowAddOrEditUserDialog = (currentUser?: any) => {
    setShowAddOrEditUserDialog((prev) => !prev);
    setSelectedUser(currentUser);
  };

  const toggleShowDeleteUserDialog = (currentUser?: any) => {
    setShowDeleteUserDialog((prev) => !prev);
    setSelectedUser(currentUser);
  };

  const [showAddOrEditClientDialog, setShowAddOrEditClientDialog] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState<any>(null);
  const [showDeleteClientDialog, setShowDeleteClientDialog] = React.useState(false);

  const toggleShowAddOrEditClientDialog = (currentClient?: any) => {
    setShowAddOrEditClientDialog((prev) => !prev);
    setSelectedClient(currentClient);
  };

  const toggleShowDeleteClientDialog = (currentClient?: any) => {
    setShowDeleteClientDialog((prev) => !prev);
    setSelectedClient(currentClient);
  };

  const { modUserList, modClientList } = useUserManagement();
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { mutateAsync: deleteClient } = useDeleteClient();

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (newValue !== userManagementTab) {
      const redirectParams = new URLSearchParams();
      redirectParams.set(QUERY_PARAM, newValue);
      navigate(
        {
          pathname: location.pathname,
          search: redirectParams.toString(),
        },
        { replace: true },
      );
    }
    // setValue(newValue);
  };

  const OddsTabs = React.useMemo(
    () =>
      TAB_OPTIONS?.map((tab) => (
        <MuiTab key={`${tab.label}`} value={tab.value} label={tab.label} />
      )),
    [TAB_OPTIONS],
  );

  // const handleDownloadResults = (resultType: string) => {
  //   let columns: Column<any>[] = [];
  //   let data: any[] = [];

  //   if (resultType === RESULT_TYPE.match) {
  //     columns = TableUserColumns;
  //     data = [...modUserList];
  //   } else if (resultType === RESULT_TYPE.round) {
  //     columns = TableClientColumns;
  //     data = [...modClientList];
  //   }

  //   const headers = columns.map((col) => col?.title ?? '');
  //   const rows = data.map((row) => columns.map((col) => row[col.field as keyof typeof row] ?? ''));

  //   excelFileDownloader({
  //     fileName: `${resultType} Results`,
  //     fileType: 'xlsx',
  //     headers,
  //     rows,
  //   });
  // };

  const handleDeleteUser = () => {
    const user = selectedUser;
    toggleShowDeleteUserDialog();
    deleteUser(
      { userId: user?.id || '' },
      {
        onSettled: (_, err) => {
          if (!err) {
            enqueueSnackbar(`User deleted successfully!`);
            queryClient.invalidateQueries([keys.userManagement.fetchUserList]);
          }
        },
      },
    );
  };

  const handleDeleteClient = () => {
    const client = selectedClient;
    toggleShowDeleteClientDialog();
    deleteClient(
      { clientId: client?.id || '' },
      {
        onSettled: (_, err) => {
          if (!err) {
            enqueueSnackbar(`Client deleted successfully!`);
            queryClient.invalidateQueries([keys.userManagement.fetchClientList]);
          }
        },
      },
    );
  };

  return (
    <Box>
      <Grid
        container
        direction="column"
        sx={{ position: 'relative', marginTop: { xs: '6rem', sm: 0 } }}
      >
        <Breadcrumbs>
          <BreadcrumbLink underline="hover" color="inherit" href={routes.events.home}>
            Events
          </BreadcrumbLink>
          <BreadcrumbLink className="eventName" underline="none">
            {'User Management'}
          </BreadcrumbLink>
        </Breadcrumbs>
        <Typography
          sx={{ fontSize: '2rem', lineHeight: '2.4rem', mt: 2, mb: 3 }}
        >{`User Management`}</Typography>
      </Grid>

      <TabContext value={userManagementTab as string}>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            flexDirection: { xs: 'column' },
            gap: { xs: '1rem', sm: 0 },
          }}
        >
          <MuiTabs
            value={userManagementTab as string}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Odd Tabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {OddsTabs}
          </MuiTabs>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between' }}>
          <MuiTabs
            value={userManagementTab as string}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Odd Tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {OddsTabs}
          </MuiTabs>
        </Box>
        <ThemeProvider theme={oddsTablesTheme}>
          <MuiTabPanel value={TAB_OPTIONS[0].value}>
            <Box mb={2} display="flex" alignItems={'center'} justifyContent={'flex-end'}>
              <Button
                startIcon={<AddIcon />}
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => toggleShowAddOrEditUserDialog(undefined)}
              >
                {`Add User`}
              </Button>
            </Box>
            <MaterialTable
              icons={tableIcons}
              columns={TableUserColumns}
              data={modUserList}
              options={{
                toolbar: false,
                // header: false,
                actionsColumnIndex: -1,
                rowStyle: (rowData) => ({
                  overflowWrap: 'break-word',
                  whiteSpace: 'nowrap',
                  backgroundColor: rowData?.isArchived ? '#d3d3d3' : 'inherit', // Light grey if archived
                }),
                headerStyle: {
                  whiteSpace: 'nowrap',
                },
                thirdSortClick: false,
                sorting: true,
                ...defaultTableOptions,
                pageSize: createTablePageOptions(modUserList?.length || 0).pageSize,
                pageSizeOptions: createTablePageOptions(modUserList?.length || 0).pageSizeOptions,
              }}
              localization={{
                header: {
                  actions: '',
                },
              }}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <Box
                      component="img"
                      alt="edit icon"
                      src={Editicon}
                      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
                    />
                  ),
                  onClick: (_, rowData) => {
                    toggleShowAddOrEditUserDialog(rowData);
                  },
                  disabled: rowData?.isArchived,
                }),
                (rowData) => ({
                  icon: () => (
                    <Box
                      component="img"
                      alt="delete icon"
                      src={Deleteicon}
                      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
                    />
                  ),
                  onClick: (_, rowData) => {
                    toggleShowDeleteUserDialog(rowData);
                  },
                  disabled: rowData?.isArchived,
                }),
              ]}
            />
          </MuiTabPanel>
          <MuiTabPanel value={TAB_OPTIONS[1].value}>
            <Box mb={2} display="flex" alignItems={'center'} justifyContent={'flex-end'}>
              <Button
                startIcon={<AddIcon />}
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => toggleShowAddOrEditClientDialog(undefined)}
              >
                {`Add Client`}
              </Button>
            </Box>
            <MaterialTable
              icons={tableIcons}
              columns={TableClientColumns}
              data={modClientList}
              options={{
                toolbar: false,
                // header: false,
                actionsColumnIndex: -1,
                rowStyle: (rowData) => ({
                  overflowWrap: 'break-word',
                  whiteSpace: 'nowrap',
                  backgroundColor: rowData?.isArchived ? '#d3d3d3' : 'inherit', // Light grey if archived
                }),
                headerStyle: {
                  whiteSpace: 'nowrap',
                },
                thirdSortClick: false,
                sorting: true,
                ...defaultTableOptions,
                pageSize: createTablePageOptions(modClientList?.length || 0).pageSize,
                pageSizeOptions: createTablePageOptions(modClientList?.length || 0).pageSizeOptions,
              }}
              localization={{
                header: {
                  actions: '',
                },
              }}
              actions={[
                (rowData) => ({
                  icon: () => (
                    <Box
                      component="img"
                      alt="edit icon"
                      src={Editicon}
                      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
                    />
                  ),
                  onClick: (_, rowData) => {
                    toggleShowAddOrEditClientDialog(rowData);
                  },
                  disabled: rowData?.isArchived,
                }),
                (rowData) => ({
                  icon: () => (
                    <Box
                      component="img"
                      alt="delete icon"
                      src={Deleteicon}
                      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
                    />
                  ),
                  onClick: (_, rowData) => {
                    toggleShowDeleteClientDialog(rowData);
                  },
                  disabled: rowData?.isArchived,
                }),
              ]}
            />
          </MuiTabPanel>
        </ThemeProvider>
      </TabContext>
      {showAddOrEditUserDialog && (
        <AddOrEditUserModal
          open={showAddOrEditUserDialog}
          handleClose={() => {
            toggleShowAddOrEditUserDialog(undefined);
          }}
          user={selectedUser}
        />
      )}
      {showAddOrEditClientDialog && (
        <AddOrEditClientModal
          open={showAddOrEditClientDialog}
          handleClose={() => {
            toggleShowAddOrEditClientDialog(undefined);
          }}
          client={selectedClient}
        />
      )}
      {showDeleteUserDialog && (
        <ConfirmDialog
          open={showDeleteUserDialog}
          handleClose={toggleShowDeleteUserDialog}
          handleConfirm={handleDeleteUser}
          title={`ARE YOU SURE YOU WANT TO DELETE THE USER ${[
            selectedUser?.firstName,
            selectedUser?.middleName,
            selectedUser?.lastName,
          ]
            .filter(Boolean)
            .join(' ')}?`}
          body={'This will delete the user.'}
        />
      )}
      {showDeleteClientDialog && (
        <ConfirmDialog
          open={showDeleteClientDialog}
          handleClose={toggleShowDeleteClientDialog}
          handleConfirm={handleDeleteClient}
          title={`ARE YOU SURE YOU WANT TO DELETE THE CLIENT ${[
            selectedClient?.firstName,
            selectedClient?.middleName,
            selectedClient?.lastName,
          ]
            .filter(Boolean)
            .join(' ')}?`}
          body={'This will delete the client.'}
        />
      )}
    </Box>
  );
}
