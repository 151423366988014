import { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import round from 'lodash.round';

import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';

import useAddTraderEventSeeds from '@/hooks/traders/seeds/useAddTraderEventSeeds';
import { useRecoilState } from 'recoil';
import { unsavedChangesState } from '@/atoms/unsavedChanges';
import { invalidateParticipants } from '@/helpers/cachedQueries';
import SPORT_TYPES from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';
import { EVENT_SEED_WEIGHT_TYPES } from '@/constants/misc';

import {
  DEFAULT_START_OF_LAP_TIME,
  formatMMSSToSeconds,
  formatSecondsToMMSS,
} from '@/helpers/timeConverters';

import { ToursProps } from '@/components/EventDetails';

const ARCHIVED = 1;

const wslUpdateDataFormatter = (seed: any) => {
  return {
    firstName: seed?.athlete?.firstName || '',
    middleName: seed?.athlete?.middleName || '',
    lastName: seed?.athlete?.lastName || '',
    gender: seed?.athlete?.gender || '',
    nationality: seed?.athlete?.nationality || '',
    stance: seed?.athlete?.stance || '',
    playerStatus: +seed?.status,
    seed: +seed?.seedNo,
    eventName: seed?.event?.name || '',
    notes: seed?.notes || '',
    tourYear: +seed?.event?.tourYear,
    baseProjection: +seed?.baseProjection,
    tier: seed?.tier || '',
    tierSeed: seed?.tierSeed || '',
  };
};

const slsUpdateDataFormatter = (seed: any) => {
  return {
    firstName: seed?.athlete?.firstName || '',
    middleName: seed?.athlete?.middleName || '',
    lastName: seed?.athlete?.lastName || '',
    gender: seed?.athlete?.gender || '',
    playerStatus: +seed?.status,
    stance: seed?.athlete?.stance || '',
    nationality: seed?.athlete?.nationality || '',
    seed: +seed?.seedNo,
    eventName: seed?.event?.name || '',
    notes: seed?.notes || '',
    leagueYear: +seed?.event?.leagueYear,
    baseRoundScore: +seed?.baseRoundScore,
    baseRunScore: +seed?.baseRunScore,
    baseTrickScore: +seed?.baseTrickScore,
    trickCompletion: +seed?.trickCompletion,
  };
};

const nrxUpdateDataFormatter = (seed: any) => {
  return {
    firstName: seed?.athlete?.firstName || '',
    middleName: seed?.athlete?.middleName || '',
    lastName: seed?.athlete?.lastName || '',
    gender: seed?.athlete?.gender || '',
    nationality: seed?.athlete?.nationality || '',
    stance: seed?.athlete?.stance || '',
    playerStatus: +seed?.status,
    seed: +seed?.seedNo,
    eventName: seed?.event?.name || '',
    notes: seed?.notes || '',
    tourYear: +seed?.event?.tourYear,
    baseProjection: +formatMMSSToSeconds(
      seed?.baseProjection.toString() || DEFAULT_START_OF_LAP_TIME,
    ),
    baseHeadLapTime: +formatMMSSToSeconds(
      seed?.baseHeadLapTime.toString() || DEFAULT_START_OF_LAP_TIME,
    ),
    baseNonJokerLapTime: +formatMMSSToSeconds(
      seed?.baseNonJokerLapTime.toString() || DEFAULT_START_OF_LAP_TIME,
    ),
    headCrashRate: round(+seed?.headCrashRate, 2),
    raceCrashRate: round(+seed?.raceCrashRate, 2),
    soloCrashRate: round(+seed?.soloCrashRate, 2),
  };
};

const formatters = {
  [SPORT_TYPES.WSL]: wslUpdateDataFormatter,
  [SPORT_TYPES.SLS]: slsUpdateDataFormatter,
  [SPORT_TYPES.NRX]: nrxUpdateDataFormatter,
};

const nrxInitialDataFormatter = (seed: any) => {
  return {
    ...seed,
    baseProjection: formatSecondsToMMSS(`${seed?.baseProjection || ''}`),
    baseHeadLapTime: formatSecondsToMMSS(`${seed?.baseHeadLapTime || ''}`),
    baseNonJokerLapTime: formatSecondsToMMSS(`${seed?.baseNonJokerLapTime || ''}`),
    headCrashRate: round(+seed?.headCrashRate, 2),
    raceCrashRate: round(+seed?.raceCrashRate, 2),
    soloCrashRate: round(+seed?.soloCrashRate, 2),
  };
};

const initialFormatters = {
  [SPORT_TYPES.WSL]: (seed: any) => seed,
  [SPORT_TYPES.SLS]: (seed: any) => seed,
  [SPORT_TYPES.NRX]: nrxInitialDataFormatter,
};

const useEventSeeds = ({ sportType }: { sportType: string }) => {
  const { eventId = '' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: eventSeeds, isFetching } = useFetchParticipants(sportType, eventId, ARCHIVED);
  const tours: ToursProps[] | undefined = queryClient.getQueryData([
    keys.events.fetchTours,
    sportType,
    'Event',
  ]);
  const eventLocationGroups: { id: string; eventLocationGroup: string }[] | undefined =
    queryClient.getQueryData([keys.events.fetchEventLocationGroup, sportType, 'Event']);

  const [tableData, setTableData] = useState<any[]>([]);
  const { mutate: addTraderEventSeeds } = useAddTraderEventSeeds();
  const [hasEventSeedsBeenEdited, setHasEventSeedsBeenEdited] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesState);
  const [showResetSeedsDialog, setShowResetSeedsDialog] = useState(false);
  const [showUpdateSeedsDialog, setShowUpdateSeedsDialog] = useState(false);

  const event: any = queryClient.getQueryData([
    keys.events.fetchEvent,
    sportType,
    eventId,
    'Event',
  ]);

  const handleToggleSaveDialog = () => setShowUpdateSeedsDialog(!showUpdateSeedsDialog);
  const handleToggleResetDialog = () => setShowResetSeedsDialog(!showResetSeedsDialog);
  const handleToggleHasEventSeedsBeenEdited = () => {
    setHasEventSeedsBeenEdited(!hasEventSeedsBeenEdited);
    setUnsavedChanges({
      ...unsavedChanges,
      isChangeUnsaved: !unsavedChanges.isChangeUnsaved,
    });
  };

  const initialDataFormatter = initialFormatters[sportType];

  const initialDataLoader = (seeds: any[]) => {
    if (seeds && seeds?.length === 0) {
      setTableData([]);
    }
    const seedsCopy = JSON.parse(JSON.stringify(seeds));
    const formattedSeeds = seedsCopy.map((seed: any) => {
      return initialDataFormatter(seed);
    });
    setTableData(formattedSeeds);
  };

  useEffect(() => {
    if (eventSeeds && eventSeeds.length > 0) initialDataLoader(eventSeeds);
  }, [JSON.stringify(eventSeeds)]);

  const refetchSeeds = () => {
    invalidateParticipants(queryClient, sportType, eventId, ARCHIVED);
  };

  useEffect(() => {
    return () => {
      refetchSeeds();
    };
  }, []);

  const rowUpdateHandler = (newData: any) =>
    new Promise((resolve) => {
      const tableDataCopy = tableData.slice();
      const newTableData = tableDataCopy.map((data: any) =>
        data.id === newData.id ? newData : data,
      );
      setTableData(newTableData);
      if (!hasEventSeedsBeenEdited) handleToggleHasEventSeedsBeenEdited();
      resolve('success');
    });

  const updateDataFormatter = formatters[sportType];

  const processUpdateData = () => {
    const seedsCopy = tableData.slice();
    const formattedSeeds = seedsCopy.map((seed: any) => {
      return updateDataFormatter(seed);
    });
    return formattedSeeds;
  };
  const updateSeeds = () =>
    addTraderEventSeeds(
      {
        sport: sportType,
        requestPayload: {
          items: processUpdateData(),
        },
      },
      {
        onSuccess: () => {
          refetchSeeds();
          if (hasEventSeedsBeenEdited) handleToggleHasEventSeedsBeenEdited();
        },
      },
    );

  const WEIGHT_YEAR_OPTIONS = useMemo(() => {
    const EVENT_GENDER = event?.tour?.gender || event?.league?.gender || '';
    const TOUR_OR_LEAGUE = tours?.find(
      (tourOrLeague: { gender: string }) => tourOrLeague?.gender === EVENT_GENDER,
    );
    return (
      TOUR_OR_LEAGUE?.years?.map((tourOrLeagueYear: { year: number; id: string }) => ({
        type: EVENT_SEED_WEIGHT_TYPES.year,
        year: tourOrLeagueYear.year,
        weight: 0,
        // id: tourOrLeagueYear?.id || '',
      })) || []
    );
  }, [event, tours]);

  const WEIGHT_EVENT_LOCATION_OPTIONS = useMemo(() => {
    return (
      eventLocationGroups?.map((eLoc: { eventLocationGroup: string; id: string }) => ({
        type: EVENT_SEED_WEIGHT_TYPES.location,
        location: eLoc.eventLocationGroup,
        weight: 0,
        // id: eLoc?.id || '',
      })) || []
    );
  }, [eventLocationGroups]);

  return {
    navigate,
    isFetching,
    eventSeeds,
    rowUpdateHandler,
    updateSeeds,
    hasEventSeedsBeenEdited,
    handleToggleHasEventSeedsBeenEdited,
    showResetSeedsDialog,
    handleToggleResetDialog,
    showUpdateSeedsDialog,
    handleToggleSaveDialog,
    tableData,
    initialDataLoader,
    WEIGHT_YEAR_OPTIONS,
    WEIGHT_EVENT_LOCATION_OPTIONS,
    refetchSeeds,
  };
};

export default useEventSeeds;
