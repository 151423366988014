import { Column } from '@material-table/core';
import { tableCellStyle } from '@/utils/TableMisc';

const TableRoundResultsColumns: Column<any>[] = [
  { title: 'Date', field: 'date', cellStyle: tableCellStyle },
  {
    title: 'Round Number',
    field: 'round',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Corner',
    field: 'corner',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Name',
    field: 'name',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Order in Rd.',
    field: 'orderInRd',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  // {
  //   title: 'Slap On #',
  //   field: 'slapOn',
  //   cellStyle: tableCellStyle,
  //   editable: 'never',
  // },
  {
    title: 'Step',
    field: 'step',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Stagger',
    field: 'stagger',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'KD',
    field: 'kd',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Points Deducted',
    field: 'pointsDeducted',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Fouls',
    field: 'fouls',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Stepping',
    field: 'stepping',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Clubbing',
    field: 'clubbing',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Illegal Wind-Up',
    field: 'illegalWindUp',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Flinching',
    field: 'flinching',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Blocking',
    field: 'blocking',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Coin Toss Winner Wins by 1st Round KO',
    field: 'coinTossWinnerWinsByFirstRound',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Other',
    field: 'other',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Match Number',
    field: 'matchNumber',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Match Code',
    field: 'matchCode',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Win By*',
    field: 'winBy',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Winner Code',
    field: 'winnerCode',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Winner',
    field: 'winner',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Total Slaps',
    field: 'totalSlaps',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Round',
    field: 'round',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Judges Score*',
    field: 'judgeScore',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
];

export default TableRoundResultsColumns;
