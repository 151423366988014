import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { navbarState, expandMenuState } from '@/atoms/misc';

import {
  Box,
  Drawer as MuiDrawer,
  Grid,
  IconButton,
  List,
  ListSubheader,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Toolbar,
  Typography,
  Collapse,
  // useTheme,
} from '@mui/material';

import orderBy from 'lodash.orderby';

import { TreeView, TreeItem } from '@mui/x-tree-view';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StorageIcon from '@mui/icons-material/Storage';

import CalendarIco from '@/images/icons/events-icon.svg';
import IconCompleted from '@/images/icons/icon_completed.svg';
import IconLive from '@/images/icons/icon_live.svg';

import { drawerWidth, SPORT_LOGOS } from '@/constants/misc';
import routes from '@/constants/routes';
import { EventStatus } from '@/constants/events';
import { FUTURE_STATUS } from '@/constants/futures';
import SPORT_TYPES from '@/constants/sportTypes';

import useFetchSports from '@/hooks/sports/useFetchSports';
import useFetchSportsFutures from '@/hooks/futures/sports/useFetchSportsFutures';

import { styles } from './styles';

import { EventOrFutureData, TourOrLeague, Year, Event } from './types';

interface DrawerProps {
  open?: boolean;
  toggleDrawer?: () => void;
}

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: 0,
      },
    }),
  },
}));

const STICKY_EVENT_HEADER_CSS = {
  position: 'sticky',
  top: 0,
  zIndex: 4,
  backgroundColor: '#ffffff',
};
const STICKY_SUB_HEADER_CSS = { position: 'sticky', top: `2.4rem`, zIndex: 2 };

const NAV_FUTURE_SECTIONS = [
  {
    code: 'CURRENTFUTURES',
    name: 'Current Futures',
  },
  {
    code: 'COMPLETEDFUTURES',
    name: 'Completed Futures',
  },
];

const NAV_EVENT_SECTIONS = [
  {
    code: 'LIVEANDUPCOMING',
    name: 'Live and Upcoming Events',
  },
  {
    code: 'COMPLETED',
    name: 'Completed Events',
  },
];

const EVENT_MANAGEMENT = [
  {
    name: 'Management',
    links: [
      {
        label: 'Resources',
        icon: <StorageIcon />,
        items: [
          {
            label: 'Scores',
            route: routes.traders.scores.upload,
            items: [],
          },
          {
            label: 'Seeds',
            route: routes.traders.seeds.upload,
            items: [],
          },
          {
            label: 'Points',
            route: routes.traders.points.upload,
            items: [],
          },

          {
            label: 'Odds Management',
            items: [
              {
                label: 'Event',
                route: routes.traders.odds.event.upload,
                items: [],
              },
              {
                label: 'Heat',
                route: routes.traders.odds.heat.upload,
                items: [],
              },
              {
                label: 'Head To Head',
                route: routes.traders.odds.headToHead.upload,
                items: [],
              },
              {
                label: 'Prop Bets',
                route: routes.traders.odds.propBets.upload,
                items: [],
              },
              {
                label: 'Shows',
                route: routes.traders.odds.shows.upload,
                items: [],
              },
              {
                label: 'Podiums',
                route: routes.traders.odds.podiums.upload,
                items: [],
              },
            ],
          },
          {
            label: 'Event Management',
            items: [
              {
                label: 'Events',
                route: routes.events.upload,
                items: [],
              },
              {
                label: 'Tours/ Leagues',
                route: routes.toursLeagues.upload,
                items: [],
              },
              {
                label: 'Rounds',
                route: routes.rounds.upload,
                items: [],
              },
              {
                label: 'Heats',
                route: routes.heats.upload,
                items: [],
              },
            ],
          },
        ],
      },
    ],
  },
];

let expandedMenuItems: string[] = [];

const LINK_TYPES = {
  event: 'event',
  futures: 'futures',
};

// const COMPLETED_HOVER_CLR = '#D5D9DD';

const Drawer = ({ open, toggleDrawer }: DrawerProps) => {
  const navigate = useNavigate();
  // const theme = useTheme();
  const [menu, setMenu] = useRecoilState(navbarState);
  const [expandMenu, setExpandMenu] = useRecoilState(expandMenuState);
  const { data: sports } = useFetchSports();
  const { data: sportsFutures } = useFetchSportsFutures();
  const { eventId = '', tourYearId = '', gameDateId = '', sportType: sp = '' } = useParams();

  useEffect(() => {
    setExpandMenu(expandedMenuItems);
  }, [sports, sportsFutures]);

  useEffect(() => {
    const interval = setInterval(() => {
      const element =
        document.getElementById(`${sp}-${eventId}`) ||
        document.getElementById(`${sp}-${tourYearId}`) ||
        document.getElementById(`${sp}-${gameDateId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'auto', block: 'center' });
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Mark menu items thant need to be expanded by default.
  const updateExpandedMenuItemsObj = ({
    sectionCode,
    ids,
  }: {
    sectionCode: string;
    ids: string[];
  }): void => {
    // Expand items in "Live and Upcoming" & "Current Future" section.
    if (['LIVEANDUPCOMING_', 'CURRENTFUTURES_'].includes(sectionCode)) {
      expandedMenuItems = [
        ...new Set([...expandedMenuItems, ...ids.map((id) => sectionCode + id)]),
      ];
    }
  };

  const handleToggle = (_event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpandMenu(nodeIds);
  };

  const handleTopLevelClick = (link: string) => {
    setMenu({
      parent: '',
      child: '',
      top: menu.top === link ? '' : link,
    });
  };

  const handleParentLevelClick = (link: string) => {
    setMenu({
      ...menu,
      parent: menu.parent === link ? '' : link,
      child: '',
    });
  };

  const redirectTo = (path: string) => {
    navigate(path);
  };

  const renderTopNav = (link: any) => {
    return (
      <React.Fragment key={link.label}>
        <ListItemButton
          selected={menu.top === link.label}
          key={link.label}
          onClick={() => {
            handleTopLevelClick(link.label);
            if (link.items?.length === 0) redirectTo(link.route || '');
          }}
        >
          {link.items?.length > 0 && (
            <ListItemIcon
              sx={{
                color: menu.top === link.label ? 'primary.main' : '',
                minWidth: '2rem',
              }}
            >
              {link.icon}
            </ListItemIcon>
          )}
          <ListItemText
            primary={link.label}
            primaryTypographyProps={{
              fontSize: '1rem',
              fontWeight: '500',
              color: menu.top === link.label ? 'primary.main' : '',
            }}
          />
        </ListItemButton>
        {link.items?.length > 0 && (
          <Collapse in={menu.top === link.label} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {ParentNav(link.items)}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  const ParentNav = (links: any[]) => {
    return (
      <React.Fragment>
        {React.Children.toArray(
          links.map((link) => (
            <React.Fragment>
              <ListItemButton
                selected={menu.parent === link.label}
                key={link.label}
                onClick={() => {
                  if (link.items?.length === 0) {
                    redirectTo(link.route || '');
                  } else {
                    handleParentLevelClick(link.label);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: menu.parent === link.label ? 'primary.main' : '',
                    minWidth: '2rem',
                  }}
                >
                  {menu.parent === link.label
                    ? link.items?.length > 0 && <ExpandLess />
                    : link.items?.length > 0 && <ExpandMore />}
                </ListItemIcon>
                <ListItemText
                  primary={link.label}
                  primaryTypographyProps={{
                    color: menu.parent === link.label ? 'primary.main' : '',
                  }}
                />
              </ListItemButton>
              {link.items?.length > 0 && (
                <Collapse in={menu.parent === link.label} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {ParentNav(link.items)}
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          )),
        )}
      </React.Fragment>
    );
  };

  const renderSports = ({
    sportsData,
    sectionCode,
    linkType = LINK_TYPES.event,
  }: {
    sportsData: EventOrFutureData[];
    sectionCode: string;
    linkType: string;
  }) => {
    sectionCode = sectionCode + '_';

    return React.Children.toArray(
      sportsData.map((sport) => {
        const sportsType = sport.sportType;

        updateExpandedMenuItemsObj({
          sectionCode,
          ids: [sport.id],
        });

        return (
          <TreeItem
            // key={sectionCode + sport.id}
            nodeId={sectionCode + sport.id}
            ContentProps={{
              style: {
                ...styles.contentProps,
                ...styles.sportsContentProps,
                // ...(['COMPLETEDFUTURES_', 'COMPLETED_'].includes(sectionCode) && {
                //   borderRadius: 0,
                //   paddingLeft: '1.1rem',
                // }),
              },
            }}
            sx={{
              ...styles.treeItem,
              ...styles.sportsTreeItem,
              ...styles.treeItemSelected,
              // ...(['COMPLETEDFUTURES_', 'COMPLETED_'].includes(sectionCode) && {
              //   backgroundColor: theme.palette.info.light,
              //   margin: 0,
              // }),
            }}
            label={
              <Box
                sx={{
                  ...styles.sportsLabel,
                  // color: expandMenu.includes(sectionCode + sport.id) ? 'primary.main' : 'info.dark',
                }}
              >
                {sport.name}
              </Box>
            }
            icon={
              <Box
                component="img"
                src={SPORT_LOGOS[sportsType || '']}
                alt={sport.name}
                sx={styles.sportsItemIcon}
              />
            }
          >
            {renderToursOrLeagues({
              toursOrLeagues:
                sport?.tours && sport?.tours?.length > 0
                  ? sport.tours
                  : sport?.leagues && sport?.leagues?.length > 0
                  ? sport.leagues
                  : [],
              sectionCode,
              sportsType,
              linkType,
            })}
          </TreeItem>
        );
      }),
    );
  };

  const renderToursOrLeagues = ({
    toursOrLeagues,
    sectionCode,
    sportsType,
    linkType,
  }: {
    toursOrLeagues: TourOrLeague[];
    sectionCode: string;
    sportsType: string;
    linkType: string;
  }) => {
    return React.Children.toArray(
      toursOrLeagues.map((tourOrLeague) => {
        const { years, id: currentTourOrLeagueId } = tourOrLeague;
        let filteredYears: any[] = [];

        // FUTURES link type handling
        if (linkType === LINK_TYPES.futures) {
          filteredYears = years.filter((year: any) => {
            return (
              (sectionCode === 'CURRENTFUTURES_' && year.futureStatus === FUTURE_STATUS.LIVE) ||
              (sectionCode === 'COMPLETEDFUTURES_' && year.futureStatus === FUTURE_STATUS.COMPLETED)
            );
          });

          // Sort based on section code (asc for current, desc for completed)
          const sortedYears = orderBy(
            filteredYears,
            ['startDate'],
            sectionCode === 'CURRENTFUTURES_' ? ['asc'] : ['desc'],
          );

          if (sortedYears.length === 0) {
            return null; // No tours or leagues to display
          }

          filteredYears = sortedYears;
        } else {
          // For non-futures, filter years based on event validity
          filteredYears = years.filter((year) => {
            if (sportsType === SPORT_TYPES.JAIALAI) {
              return true; // Include all years for JAIALAI
            }

            const isValidEvent = (event: any) => {
              if (sectionCode === 'LIVEANDUPCOMING_') {
                return [
                  EventStatus.LIVE,
                  EventStatus.UPCOMING,
                  EventStatus.IN_WINDOW,
                  EventStatus.NEXT,
                ].includes(event.eventStatus);
              } else if (sectionCode === 'COMPLETED_') {
                return [EventStatus.COMPLETED, EventStatus.CANCELED].includes(event.eventStatus);
              }
              return false;
            };

            return year.events.some(isValidEvent);
          });
        }

        // If no valid years, return null
        if (filteredYears.length === 0) {
          return null;
        }

        // Update expanded menu state
        updateExpandedMenuItemsObj({
          sectionCode,
          ids: [currentTourOrLeagueId],
        });

        return (
          <TreeItem
            key={sectionCode + currentTourOrLeagueId}
            nodeId={sectionCode + currentTourOrLeagueId}
            ContentProps={{
              style: {
                ...styles.contentProps,
              },
            }}
            sx={{
              ...styles.treeItem,
              ...styles.treeItemSelected,
            }}
            label={<Box sx={styles.toursOrLeaguesLabel}>{tourOrLeague.name}</Box>}
          >
            {linkType === LINK_TYPES.futures
              ? renderFutureYear({
                  years: filteredYears,
                  sectionCode,
                  sportsType,
                  linkType,
                })
              : renderEventYear({
                  years: filteredYears,
                  sectionCode,
                  sportsType,
                })}
          </TreeItem>
        );
      }),
    );
  };

  const renderFutureYear = ({
    years,
    sectionCode,
    sportsType,
    linkType,
  }: {
    years: Year[];
    sectionCode: string;
    sportsType: string;
    linkType: string;
  }) => {
    return React.Children.toArray(
      years.map((year) => (
        <TreeItem
          // key={sectionCode + year.id}
          nodeId={sectionCode + year.id}
          ContentProps={{
            style: {
              ...styles.contentProps,
            },
          }}
          sx={{
            ...styles.eventsTreeItem,
            ...styles.treeItem,
            backgroundColor: tourYearId === year.id ? 'rgba(13, 110, 253, 0.08)' : '',
          }}
          label={
            <Box sx={styles.eventLabelWrapper}>
              <Box
                sx={{
                  ...styles.eventNameLabel,
                  color: tourYearId === year.id ? 'primary.main' : 'info.dark',
                }}
              >
                {year.name}
              </Box>
              <Box
                sx={{
                  ...styles.eventStatusLabel,
                  color: year.isOpen ? 'success.main' : 'info.dark',
                }}
              >
                {year.isOpen ? '• OPEN' : '• CLOSED'}
              </Box>
            </Box>
          }
          onClick={() => {
            navigate(`/${sportsType}/${linkType}/${year.id}`, { replace: true });
          }}
          id={`${sportsType}-${year.id}`}
        ></TreeItem>
      )),
    );
  };

  const renderEventYear = ({
    years,
    sectionCode,
    sportsType,
  }: {
    years: Year[];
    sectionCode: string;
    sportsType: string;
  }) => {
    return React.Children.toArray(
      years.map((year) => {
        updateExpandedMenuItemsObj({ sectionCode, ids: [year.id] });
        return (
          <TreeItem
            key={sectionCode + year.id}
            nodeId={sectionCode + year.id}
            ContentProps={{
              style: {
                ...styles.contentProps,
              },
            }}
            sx={{
              ...styles.treeItem,
              ...styles.treeItemSelected,
            }}
            label={<Box sx={styles.eventYearLabel}>{year.year}</Box>}
          >
            {sportsType === SPORT_TYPES.JAIALAI
              ? renderEventMatchDate({
                  events: year.events,
                  sectionCode,
                  sportsType,
                  yearId: year.id,
                })
              : renderEvents({
                  events: year.events,
                  sectionCode,
                  sportsType,
                })}
          </TreeItem>
        );
      }),
    );
  };

  const renderEventMatchDate = ({
    events,
    sectionCode,
    sportsType,
    yearId,
  }: {
    events: Event[];
    sectionCode: string;
    sportsType: string;
    yearId: string;
  }) => {
    const validEventStatus =
      sectionCode === 'LIVEANDUPCOMING_'
        ? [EventStatus.LIVE, EventStatus.UPCOMING, EventStatus.IN_WINDOW, EventStatus.NEXT]
        : sectionCode === 'COMPLETED_'
        ? [EventStatus.COMPLETED, EventStatus.CANCELED]
        : [];

    const filteredEvents = events
      .map((game) => ({
        ...game,
        matches: game.matches?.filter((match) => validEventStatus.includes(match.eventStatus)),
      }))
      .filter((game) => game?.matches && game?.matches?.length > 0);

    if (filteredEvents.length === 0) return null;

    return React.Children.toArray(
      filteredEvents.map((event) => {
        updateExpandedMenuItemsObj({ sectionCode, ids: [yearId + '_' + event?.id] });
        return (
          <TreeItem
            key={`${sectionCode}${yearId}_${event.id}`}
            nodeId={`${sectionCode}${yearId}_${event.id}`}
            ContentProps={{ style: styles.contentProps }}
            sx={{ ...styles.treeItem, ...styles.treeItemSelected }}
            label={<Box sx={styles.eventYearLabel}>{event.name}</Box>}
          >
            {event?.matches?.map((match) =>
              renderEvents({
                events: [{ ...match, name: `${match.awayTeam} at ${match.homeTeam}` }],
                sectionCode,
                sportsType,
                matchId: match.id,
              }),
            )}
          </TreeItem>
        );
      }),
    );
  };

  const renderEvents = ({
    events,
    sectionCode,
    sportsType,
    matchId,
  }: {
    events: Event[];
    sectionCode: string;
    sportsType: string;
    matchId?: string;
  }) => {
    if (events.length === 0) return;

    const isValidEvent = (event: any) => {
      if (sectionCode === 'LIVEANDUPCOMING_') {
        return [
          EventStatus.LIVE,
          EventStatus.UPCOMING,
          EventStatus.IN_WINDOW,
          EventStatus.NEXT,
        ].includes(event.eventStatus);
      } else if (sectionCode === 'COMPLETED_') {
        return [EventStatus.COMPLETED, EventStatus.CANCELED].includes(event.eventStatus);
      }
      return false;
    };

    const filteredEvents = events.filter(isValidEvent);

    /* Sort events by startDate. 
    Live and Upcoming section - oldest to newest
    Completed section - newest to oldest */
    const sortedEvents = orderBy(
      filteredEvents,
      [(event) => new Date(event.startDate).valueOf()],
      sectionCode === 'LIVEANDUPCOMING_' ? ['asc'] : ['desc'],
    );

    if (sortedEvents.length === 0) {
      return;
    }

    return React.Children.toArray(
      sortedEvents.map((event) => {
        const { id } = event;
        let isActive: string | boolean = false;

        if (
          (matchId && matchId === gameDateId) ||
          (eventId && id === eventId) ||
          (gameDateId && id === gameDateId)
        ) {
          isActive = sportsType === sp ? 'isActive' : false;
        }

        return (
          <TreeItem
            // key={sectionCode + event.id}
            nodeId={sectionCode + event.id}
            ContentProps={{
              style: {
                ...styles.contentProps,
              },
            }}
            sx={{
              ...styles.eventsTreeItem,
              ...styles.treeItem,
              backgroundColor:
                event.eventStatus === EventStatus.LIVE
                  ? 'success.light'
                  : isActive
                  ? 'rgba(13, 110, 253, 0.08)'
                  : '',
            }}
            label={
              <Box
                sx={{
                  ...styles.eventLabelWrapper,
                }}
              >
                <Box
                  sx={{
                    ...styles.eventNameLabel,
                    color:
                      event.eventStatus === EventStatus.LIVE
                        ? 'success.main'
                        : isActive
                        ? 'primary.main'
                        : 'info.dark',
                  }}
                >
                  {event.name}
                </Box>
                <Box
                  sx={{
                    ...styles.eventStatusLabel,
                    color:
                      event.eventStatus === EventStatus.LIVE
                        ? 'success.main'
                        : event.eventStatus === EventStatus.NEXT
                        ? '#8C68CD'
                        : 'info.dark',
                  }}
                >
                  {event.eventStatus === EventStatus.LIVE
                    ? '• LIVE'
                    : event.eventStatus === EventStatus.NEXT
                    ? '• NEXT'
                    : ''}
                </Box>
              </Box>
            }
            onClick={() => {
              navigate(
                [SPORT_TYPES.JAIALAI, SPORT_TYPES.MASL].includes(sportsType || '')
                  ? `/${sportsType}/event-games/${
                      sportsType === SPORT_TYPES.MASL ? event.id : matchId
                    }`
                  : `/${sportsType}/event/${event.id}`,
                { replace: true },
              );
            }}
            id={
              [SPORT_TYPES.JAIALAI, SPORT_TYPES.MASL].includes(sportsType || '')
                ? `${sportsType}-${sportsType === SPORT_TYPES.MASL ? event.id : matchId}`
                : `${sportsType}-${event.id}`
            }
          ></TreeItem>
        );
      }),
    );
  };

  return (
    <StyledDrawer variant="permanent" open={open}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: [1],
        }}
      >
        <Grid container>
          <Box sx={{}}></Box>
          <Typography sx={{ marginLeft: '1rem' }} variant="h6">
            Alt Sports Data
          </Typography>
        </Grid>

        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>

      {/* --------------------- Events --------------------- */}

      <List component="nav" key={'events-list'} sx={{ ...STICKY_EVENT_HEADER_CSS, py: 0 }}>
        <ListItemButton
          onClick={() => {
            handleTopLevelClick('events');
            redirectTo(routes.events.home || '');
          }}
          selected={menu.top === 'events'}
          key={'events'}
        >
          <ListItemIcon
            sx={{
              color: menu.top === 'events' ? 'primary.main' : '',
              minWidth: '2rem',
            }}
          >
            <Box
              component="img"
              src={CalendarIco}
              alt="World Surf League"
              sx={{ width: '1.25rem' }}
            />
          </ListItemIcon>
          <ListItemText
            primary={'Events'}
            primaryTypographyProps={{
              fontSize: '0.875rem',
              fontWeight: '500',
              color: menu.top === 'events' ? 'primary.main' : '',
            }}
          />
        </ListItemButton>
      </List>

      {/* --------------------- xx ---------------------*/}

      {/* --------------------- Season Futures --------------------- */}

      {/* <List component="nav" key={'futures-list'}>
        <ListItemButton
          onClick={() => {
            handleTopLevelClick('futures');
            redirectTo(routes.futures.home || '');
          }}
          selected={menu.top === 'futures'}
          key={'futures'}
        >
          <ListItemIcon
            sx={{
              color: menu.top === 'futures' ? 'primary.main' : '',
              minWidth: '2rem',
            }}
          >
            <Box component="img" src={CalendarIco} alt="Season Futures" sx={{ width: '1.25rem' }} />
          </ListItemIcon>
          <ListItemText
            primary={'Season Futures'}
            primaryTypographyProps={{
              fontSize: '0.875rem',
              fontWeight: '500',
              color: menu.top === 'events' ? 'primary.main' : '',
            }}
          />
        </ListItemButton>
      </List> */}

      {NAV_FUTURE_SECTIONS.map((NAV_FUTURES_SECTION) => (
        <React.Fragment key={NAV_FUTURES_SECTION.name}>
          <List
            component="nav"
            subheader={
              <ListSubheader
                sx={{
                  textTransform: 'uppercase',
                  // marginTop: '0.5rem',
                  height: '3rem',
                  lineHeight: '2.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  color: `${
                    NAV_FUTURES_SECTION.code === 'CURRENTFUTURES'
                      ? 'success.main'
                      : NAV_FUTURES_SECTION.code === 'COMPLETEDFUTURES'
                      ? 'primary.main'
                      : 'info.contrastText'
                  }`,
                  ...STICKY_SUB_HEADER_CSS,
                  // backgroundColor: ['COMPLETEDFUTURES', 'COMPLETED'].includes(
                  //   NAV_FUTURES_SECTION.code,
                  // )
                  //   ? theme.palette.info.light
                  //   : '',
                  // '&:hover': {
                  //   backgroundColor: ['COMPLETEDFUTURES', 'COMPLETED'].includes(
                  //     NAV_FUTURES_SECTION.code,
                  //   )
                  //     ? COMPLETED_HOVER_CLR
                  //     : '',
                  // },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '2rem',
                  }}
                >
                  <Box
                    component="img"
                    src={NAV_FUTURES_SECTION.code === 'CURRENTFUTURES' ? IconLive : IconCompleted}
                    alt="Fixture Icon"
                    sx={{ width: '1.2rem', height: '1.2rem' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={NAV_FUTURES_SECTION.name}
                  primaryTypographyProps={{
                    fontSize: '0.75rem',
                  }}
                />
              </ListSubheader>
            }
            key={NAV_FUTURES_SECTION.name}
          >
            <TreeView
              defaultCollapseIcon={<ExpandLess sx={{ width: '1.2rem', height: '1.2rem' }} />}
              defaultExpandIcon={<ExpandMore sx={{ width: '1.2rem', height: '1.2rem' }} />}
              expanded={expandMenu}
              onNodeToggle={handleToggle}
            >
              {sportsFutures &&
                renderSports({
                  sportsData: sportsFutures,
                  sectionCode: NAV_FUTURES_SECTION.code,
                  linkType: LINK_TYPES.futures,
                })}
            </TreeView>
          </List>
        </React.Fragment>
      ))}

      {/* --------------------- xx --------------------- */}

      {/* --------------------- Events --------------------- */}

      {NAV_EVENT_SECTIONS.map((NAV_EVENT_SECTION) => (
        <React.Fragment key={NAV_EVENT_SECTION.name}>
          <List
            component="nav"
            subheader={
              <ListSubheader
                sx={{
                  textTransform: 'uppercase',
                  height: '3rem',
                  lineHeight: '2.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  color: `${
                    NAV_EVENT_SECTION.code === 'LIVEANDUPCOMING'
                      ? 'success.main'
                      : NAV_EVENT_SECTION.code === 'COMPLETED'
                      ? 'primary.main'
                      : 'info.contrastText'
                  }`,
                  ...STICKY_SUB_HEADER_CSS,
                  // backgroundColor: ['COMPLETEDFUTURES', 'COMPLETED'].includes(
                  //   NAV_EVENT_SECTION.code,
                  // )
                  //   ? theme.palette.info.light
                  //   : '',
                  // '&:hover': {
                  //   backgroundColor: ['COMPLETEDFUTURES', 'COMPLETED'].includes(
                  //     NAV_EVENT_SECTION.code,
                  //   )
                  //     ? COMPLETED_HOVER_CLR
                  //     : '',
                  // },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: '2rem',
                  }}
                >
                  <Box
                    component="img"
                    src={NAV_EVENT_SECTION.code === 'LIVEANDUPCOMING' ? IconLive : IconCompleted}
                    alt="Event icon"
                    sx={{ width: '1.2rem', height: '1.2rem' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={NAV_EVENT_SECTION.name}
                  primaryTypographyProps={{
                    fontSize: '0.75rem',
                  }}
                />
              </ListSubheader>
            }
            key={NAV_EVENT_SECTION.name}
          >
            <TreeView
              defaultCollapseIcon={<ExpandLess sx={{ width: '1.2rem', height: '1.2rem' }} />}
              defaultExpandIcon={<ExpandMore sx={{ width: '1.2rem', height: '1.2rem' }} />}
              expanded={expandMenu}
              onNodeToggle={handleToggle}
            >
              {sports &&
                renderSports({
                  sportsData: sports,
                  sectionCode: NAV_EVENT_SECTION.code,
                  linkType: LINK_TYPES.event,
                })}
            </TreeView>
          </List>
        </React.Fragment>
      ))}

      {/* --------------------- xx --------------------- */}

      {/* --------------------- Event Management Section --------------------- */}

      {EVENT_MANAGEMENT.map((section) => (
        <React.Fragment key={section.name}>
          <List
            component="nav"
            subheader={
              <ListSubheader sx={{ textTransform: 'uppercase' }}>{section.name}</ListSubheader>
            }
            key={section.name}
          >
            {section.links.map((link: any) => renderTopNav(link))}
          </List>
        </React.Fragment>
      ))}

      {/* --------------------- xx ---------------------*/}
    </StyledDrawer>
  );
};

export default Drawer;
