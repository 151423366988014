import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

interface IFightParticipants {
  sport: string;
  eventId: string;
  archived: number;
}

const getFightParticipants = async ({ sport, eventId, archived }: IFightParticipants) => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/traders/${eventId}/participants?archived=${archived}&sportType=${sport}`,
  });
  return data;
};

export default function useFetchFightParticipants({
  sport,
  eventId,
  archived = 0,
}: IFightParticipants) {
  return useQuery(
    [keys.fights.fetchFightParticipants, sport, eventId, archived],
    () => getFightParticipants({ sport, eventId, archived }),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
