import * as React from 'react';
import { DebugLabel } from './components';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import { FIELD_TO_DEBUG } from './constants';
import { convertToAbbreviation, trimId } from './marketHelper';

const DebugLabelDisplay = ({ row, showFields = [] }: any) => {
  const { isInTestMode, currentFieldsToDebug } = React.useContext(JAIALAIGameContext);
  const currentDebugFields = showFields?.length > 0 ? showFields : currentFieldsToDebug;

  return isInTestMode && row ? (
    <DebugLabel>
      {currentDebugFields.map((field: string) => (
        <React.Fragment key={field}>
          {`${convertToAbbreviation(field)}: ${
            field === FIELD_TO_DEBUG.id ? trimId(row[field]) : row[field]
          }`}
          <br />
        </React.Fragment>
      ))}
    </DebugLabel>
  ) : null;
};

export default DebugLabelDisplay;
