import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import SPORT_TYPES from '@/constants/sportTypes';

const { persistAtom } = recoilPersist();

const sportsType = atom({
  key: 'sportsTypeState',
  default: SPORT_TYPES.SLS,
  effects_UNSTABLE: [persistAtom],
});

export { sportsType };
