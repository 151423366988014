import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';
import { IEvent } from '@/types/newTypes/event';

const getEvent = async (sportType: string, eventId: string): Promise<IEvent> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/events/${eventId}`,
  });

  return data;
};

export default function useFetchEvent(sportType: string, eventId: string, fromComponent: string) {
  return useQuery(
    [keys.events.fetchEvent, sportType, eventId, fromComponent],
    () => getEvent(sportType, eventId),
    {
      staleTime: Infinity,
      refetchInterval: 10 * (60 * 1000),
      refetchIntervalInBackground: true,
      enabled: !!eventId,
    },
  );
}
