import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  // TextField,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const InfoIcon = styled(InfoOutlinedIcon)({
  color: '#0D6EFD',
  // marginRight: '5px',
  position: 'absolute',
});

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1rem 1rem 0.5rem 1rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '0.5rem 1rem 0.5rem 3rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1rem 1rem 3rem',
  },
}));

const LabelText = styled(Typography)({
  color: 'info.main',
  fontSize: '0.812rem',
  fontWeight: '500',
  marginBottom: '0.4rem',
});

type CustomEndFightDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: (val: any) => void;
  title: string;
  body: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  isStartTime?: boolean;
};

const CustomEndFightDialog = (props: CustomEndFightDialogProps) => {
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    body,
    cancelBtnText = 'Cancel',
    confirmBtnText = 'Confirm',
    isStartTime = false,
  } = props;

  const [currentEndTime, setCurrentEndTime] = React.useState<Date | null>(new Date());

  const resetEndDateHandler = () => {
    setCurrentEndTime(new Date());

    handleClose();
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          resetEndDateHandler();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <InfoIcon />
        <Typography sx={{ fontWeight: 500, paddingLeft: '2rem' }}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{body}</Typography>
        <Grid container sx={{ marginTop: '0.8rem' }}>
          <LabelText>{`Set ${isStartTime ? 'Start' : 'End'} Time:`}</LabelText>
          <DateTimePicker
            ampm={false}
            value={currentEndTime}
            onChange={(newValue) => {
              setCurrentEndTime(newValue);
            }}
            slotProps={{
              textField: {
                color: 'secondary',
                fullWidth: true,
                placeholder: 'End Time',
                sx: {
                  fontWeight: '400',
                  '& .MuiInputBase-input': {
                    padding: '0.7rem 1.5rem',
                    fontSize: '0.812rem',
                  },
                },
              },
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                resetEndDateHandler();
              }}
              id="clickCancel"
              fullWidth
              disableElevation
            >
              {cancelBtnText}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleConfirm(currentEndTime);
              }}
              id="clickConfirm"
              fullWidth
              disableElevation
            >
              {confirmBtnText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default CustomEndFightDialog;
