import React, { useState, useEffect, useMemo } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';

import { oddsType } from '@/atoms/oddsType';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import { EventListingSkeleton } from '@/components/PageSkeletons';

import useFetchHeatWinnerOdds from '@/hooks/odds/heatWinner/useFetchHeatWinnerOdds';
import useUpdateHeatWinnerOdds from '@/hooks/odds/heatWinner/useUpdateHeatWinnerOdds';
import useUpdateHeatWinnerOddsVoid from '@/hooks/odds/heatWinner/useUpdateHeatWinnerOddsVoid';

import { sortRoundsByRoundNo } from '@/helpers/rounds';
import { sortHeatsByHeatNo } from '@/helpers/heats';
import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidateHeatWinnerOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import {
  heatWinnerDataLoader,
  resetHeatWinnerHeat,
  heatWinnerOddsPayloadFormatter,
} from '@/helpers/heatWinner';

import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import * as httpStatusCodes from '@/constants/httpStatus';
import {
  SKIP_SCORE_FORM_ROUND_STATUSES,
  SKIP_SCORE_FORM_HEAT_STATUSES,
} from '@/constants/skipStatuses';
import { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';
import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';
import { HEAT_VIEW_MODES } from '@/constants/heats';

import HeatWinnerOddsDefaultView from '@/features/odds/Tabs/HeatWinnerTab/HeatWinnerOddsDefaultView';
import HeatWinnerOddsListView from '@/features/odds/Tabs/HeatWinnerTab/HeatWinnerOddsListView';
import HeatViewToggle from '@/components/HeatViewToggle';
import RoundDetails from '@/components/RoundDetails';
import OddMarketToggle from '@/features/odds/OddMarketToggle';
import OddMarketNotes from '@/features/odds/Notes';

import Slider from '@/components/Slider';
import { ODD_MARKET_NOTES, ODD_MARKET_TOGGLE_TYPE } from '@/constants/oddTabs';
import ExportBtn from '@/components/ExportBtn';
import { Box } from '@mui/material';

interface HeatWinnerTabProps {
  sport: string;
  eventId: string;
  handlePublishOdds: (
    oddsProjectionType: string,
    roundHeatId: string | null | undefined,
    roundId?: string | null | undefined,
  ) => void;
  handleSelectWinner: any;
}

const HeatWinnerTab = ({
  sport,
  eventId,
  handlePublishOdds,
  handleSelectWinner,
}: HeatWinnerTabProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [heatViewMode, setHeatViewMode] = useState<number>(HEAT_VIEW_MODES.default);
  const { data: oddsData } = useFetchHeatWinnerOdds(sport, eventId);
  const [oddType] = useRecoilState(oddsType);
  const [roundList, setRoundList] = useState<any[]>([]);
  const [selectedRoundId, setSelectedRoundId] = useState<string>('');
  const [selectedHeatId, setSelectedHeatId] = useState<string>('');
  const [selectedToBeVoidedHeatId, setSelectedToBeVoidedHeatId] = useState<string>('');
  const [selectedToBeResetHeatId, setSelectedToBeResetHeatId] = useState<string>('');
  const [currentHeatSelection, setCurrentHeatSelection] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const { mutateAsync: updateHeatWinnerOdds } = useUpdateHeatWinnerOdds();
  const { mutate: updateHeatWinnerOddsVoid } = useUpdateHeatWinnerOddsVoid();
  const queryClient = useQueryClient();

  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  const handleToggleEditConfirmDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);

  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);

  const findRound = (roundId: string, rounds: any[]) => {
    if (!roundId || rounds.length === 0) return null;
    if (roundId && rounds.length > 0) {
      return rounds.find((round) => round.roundId === roundId) || null;
    }
  };

  const selectedRound = useMemo(
    () => findRound(selectedRoundId, roundList),
    [selectedRoundId, roundList],
  );

  const findHeat = (heatId: string, round: any) => {
    if (!heatId || !round) return null;
    if (heatId && round) {
      const heats = round.heats;
      if (heats.length === 0) return null;
      return heats.find((heat: any) => heat.id === heatId) || null;
    }
  };

  const selectedHeat = useMemo(
    () => findHeat(selectedHeatId, selectedRound),
    [selectedHeatId, selectedRound],
  );

  const toBeVoidedHeat = useMemo(() => {
    if (!selectedToBeVoidedHeatId) return null;
    if (selectedToBeVoidedHeatId) {
      const heats = selectedRound.heats;
      if (heats.length === 0) return null;
      return heats.find((heat: any) => heat.id === selectedToBeVoidedHeatId) || null;
    }
  }, [selectedToBeVoidedHeatId]);

  const configureHeats = (heats: any[], currentlySelectedHeatId: string) => {
    if (currentlySelectedHeatId) setSelectedHeatId(currentlySelectedHeatId);

    // Filter heats
    const modHeats = sortHeatsByHeatNo(heats);

    const currentlySelectedHeat = modHeats.find((heat) => heat.id === currentlySelectedHeatId);

    if (currentlySelectedHeat) {
      setSelectedHeatId(currentlySelectedHeat?.id);
    } else {
      const firstHeat = modHeats.find(
        (heat: any) => !SKIP_SCORE_FORM_HEAT_STATUSES.includes(heat.heatStatus),
      );
      if (firstHeat) {
        setSelectedHeatId(firstHeat?.id);
      } else {
        const [alternateFirstHeat] = modHeats;
        if (alternateFirstHeat) {
          setSelectedHeatId(alternateFirstHeat?.id);
        }
      }
    }
  };

  const configureRound = (rounds: any[]) => {
    const firstRound = rounds.find(
      (round: any) => !SKIP_SCORE_FORM_ROUND_STATUSES.includes(round.roundStatus),
    );
    if (firstRound) {
      setSelectedRoundId(firstRound.roundId);
      if (firstRound?.heats) {
        configureHeats(firstRound?.heats || [], '');
      }
    } else {
      const [alternateFirstRound] = rounds;
      if (alternateFirstRound) {
        setSelectedRoundId(alternateFirstRound.roundId);
        if (alternateFirstRound?.heats) {
          configureHeats(alternateFirstRound?.heats || [], '');
        }
      }
    }
  };

  const refetchOdds = () => {
    invalidateHeatWinnerOdds(queryClient, sport, eventId);
  };

  useEffect(() => {
    if (eventId) {
      refetchOdds();
      if (selectedRoundId) setSelectedRoundId('');
      if (selectedHeatId) setSelectedHeatId('');
    }
  }, [eventId]);

  useEffect(() => {
    if (oddsData?.odds) {
      const sortedRounds = sortRoundsByRoundNo(oddsData?.odds as any);
      const modRounds = heatWinnerDataLoader(sortedRounds);
      setRoundList(modRounds);
    }
  }, [JSON.stringify(oddsData)]);

  useEffect(() => {
    if (roundList.length > 0 && !selectedHeatId) configureRound(roundList);
  }, [JSON.stringify(roundList), selectedHeatId]);

  const saveHeatWinnersOddsData = async (
    payload: UpdateOddsPayloadDTO,
    message = 'Successfully updated odds',
  ) => {
    const response = await updateHeatWinnerOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refetchOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const markHeatAsVoid = (heatId = '', message = 'Successfully voided heat') => {
    updateHeatWinnerOddsVoid(
      { sport, eventId, heatId },
      {
        onSettled: (_, error) => {
          if (!error) {
            enqueueSnackbar(message);
            refetchOdds();
          }
        },
      },
    );
  };

  const handleCurrentRound = (round: any) => {
    setSelectedRoundId(round.roundId);
    configureHeats(round?.heats || [], '');
  };

  const handleCurrentHeat = (heat: any) => {
    configureHeats(selectedRound?.heats, heat?.id || '');
  };

  // Function for updating local heat winner odds
  const changeHeatWinners = (
    roundId: string,
    heatId: string,
    athletes: any[],
    hasBeenEdited = true,
  ) => {
    // copy the existing rounds
    const newRoundList = JSON.parse(JSON.stringify(roundList));
    // Check if round exist
    const roundIdx = newRoundList.findIndex((round: any) => round?.roundId == roundId);
    if (roundIdx !== -1) {
      const round = newRoundList[roundIdx];
      // Check if heat exist
      const heatIdx = round.heats.findIndex((heat: any) => heat?.id == heatId);
      if (heatIdx !== -1) {
        const heat = round.heats[heatIdx];
        // assign newly updated athletes odds
        heat.athletes = athletes;
        heat.hasBeenEdited = hasBeenEdited;
        round.heats[heatIdx] = heat;
        const heats = round.heats;
        // sort the heats
        const sortedHeats = sortHeatsByHeatNo(heats);
        // assign to current round heats
        round.heats = sortedHeats;
        newRoundList[roundIdx] = round;
        // sort the rounds
        const sortedRounds = sortRoundsByRoundNo(newRoundList);
        setRoundList(sortedRounds);
      }
    }
  };

  const updateOddsData = async ({ heat }: { heat: any }) => {
    const payload = heatWinnerOddsPayloadFormatter(heat.athletes);
    const responseStatus = await saveHeatWinnersOddsData({ items: payload });

    if (responseStatus === httpStatusCodes.OK && heat?.hasBeenEdited) {
      changeHeatWinners(selectedRoundId, heat.id, heat.athletes, false);
    }
  };

  const setInitialOddsData = (heatId: string) => {
    const round = findRound(selectedRoundId, oddsData?.odds || []);
    const heat = findHeat(heatId, round);
    const initialHeat = resetHeatWinnerHeat(heat);
    changeHeatWinners(selectedRoundId, heatId, initialHeat.athletes, false);
  };

  const handleVoidHeat = (heatId: string) => {
    setSelectedToBeVoidedHeatId(heatId);
    handleToggleConfirmDialog();
  };

  const handleRoundPublish = (roundId: string) => {
    handlePublishOdds(ODDS_PROJECTION_TYPE.heatProjections, undefined, roundId);
  };

  const exportHandler = () => {
    const modData = JSON.parse(JSON.stringify(selectedRound || {}));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType: ODD_DATA_TYPES.HEATS,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo: any = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
        ...(selectedRound?.name && { roundName: selectedRound?.name }),
      };
      const lastItemUpdatedAt = selectedHeat?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  if (!oddsData?.odds) return <EventListingSkeleton />;

  return (
    <>
      <OddMarketNotes
        eventId={eventId}
        sportType={sport}
        marketType={ODD_MARKET_NOTES.HEAT_MARKET_NOTES}
        note={oddsData?.notes || ''}
        refetchOdds={refetchOdds}
      />
      <HeatViewToggle heatViewMode={heatViewMode} setHeatViewMode={setHeatViewMode} />
      <Slider
        sliderItems={roundList}
        selectedItem={selectedRound}
        handleItemSelect={handleCurrentRound}
        handleRoundPublish={handleRoundPublish}
        itemType={'roundsHeatWinnerOdd'}
      />
      {selectedRound && (
        <>
          <RoundDetails selectedRound={selectedRound} />
          <Box mt={2} mb={3}>
            <ExportBtn handleOnClick={exportHandler} btnText={'Export All Heats'} />
          </Box>
          {heatViewMode === HEAT_VIEW_MODES.default && (
            <>
              <Slider
                sliderItems={sortHeatsByHeatNo(selectedRound.heats)}
                selectedItem={selectedHeat}
                handleItemSelect={handleCurrentHeat}
                itemType={'heatsHeatWinnerOdd'}
                handleVoidHeat={handleVoidHeat}
              />
              <OddMarketToggle
                eventId={eventId}
                sport={sport}
                marketType={ODD_MARKET_TOGGLE_TYPE.IS_HEAT_WINNER_MARKET_OPEN}
                isMarketOpen={selectedHeat?.isHeatWinnerMarketOpen as boolean}
                heatId={selectedHeat?.id}
                refetchOdds={refetchOdds}
              />
              <HeatWinnerOddsDefaultView
                selectedRoundId={selectedRoundId}
                selectedHeat={selectedHeat}
                changeHeatWinners={changeHeatWinners}
                handlePublishOdds={handlePublishOdds}
                handleSelectWinner={handleSelectWinner}
                setSelectedToBeResetHeatId={setSelectedToBeResetHeatId}
                setCurrentHeatSelection={setCurrentHeatSelection}
                handleToggleEditConfirmDialog={handleToggleEditConfirmDialog}
                handleToggleResetDialog={handleToggleResetDialog}
              />
            </>
          )}
          {heatViewMode === HEAT_VIEW_MODES.list && (
            <div>
              {selectedRound.heats &&
                React.Children.toArray(
                  sortHeatsByHeatNo(selectedRound.heats).map((heat: any) => (
                    <HeatWinnerOddsListView
                      eventId={eventId}
                      sport={sport}
                      refetchOdds={refetchOdds}
                      selectedRoundId={selectedRoundId}
                      selectedHeat={heat}
                      changeHeatWinners={changeHeatWinners}
                      handlePublishOdds={handlePublishOdds}
                      handleSelectWinner={handleSelectWinner}
                      handleVoidHeat={handleVoidHeat}
                      setSelectedToBeResetHeatId={setSelectedToBeResetHeatId}
                      setCurrentHeatSelection={setCurrentHeatSelection}
                      handleToggleEditConfirmDialog={handleToggleEditConfirmDialog}
                      handleToggleResetDialog={handleToggleResetDialog}
                    />
                  )),
                )}
            </div>
          )}
        </>
      )}
      <ConfirmDialog
        open={isDialogOpen}
        handleClose={() => {
          handleToggleConfirmDialog();
          setSelectedToBeVoidedHeatId('');
        }}
        handleConfirm={() => {
          handleToggleConfirmDialog();
          markHeatAsVoid(selectedToBeVoidedHeatId);
          setSelectedToBeVoidedHeatId('');
        }}
        title={`ARE YOU SURE YOU WANT TO VOID ${v.upperCase(toBeVoidedHeat?.name)}?`}
        body={`This heat will be voided.`}
      />
      <ConfirmDialog
        open={showOddsUpdateDialog}
        handleClose={() => {
          handleToggleEditConfirmDialog();
          setCurrentHeatSelection(null);
        }}
        handleConfirm={() => {
          updateOddsData({ heat: currentHeatSelection });
          handleToggleEditConfirmDialog();
          setCurrentHeatSelection(null);
        }}
        title={`ARE YOU SURE YOU WANT TO UPDATE THE ${v.upperCase(
          currentHeatSelection?.name || '',
        )} ODDS?`}
        body={'This will update the odds.'}
      />
      <ConfirmDialog
        open={showResetOddsDialog}
        handleClose={() => {
          handleToggleResetDialog();
          setSelectedToBeResetHeatId('');
        }}
        handleConfirm={() => {
          setInitialOddsData(selectedToBeResetHeatId);
          handleToggleResetDialog();
          setSelectedToBeResetHeatId('');
        }}
        title={`ARE YOU SURE YOU WANT TO RESET THE ${v.upperCase(
          (selectedRound &&
            selectedRound?.heats.find((heat: any) => heat.id === selectedToBeResetHeatId)?.name) ||
            '',
        )} ODDS?`}
        body={'This will reset the odds.'}
      />
    </>
  );
};

export default HeatWinnerTab;
