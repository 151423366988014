import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface UPDATE_HEAT_WINNER_VOID {
  sport: string;
  eventId: string;
  heatId: string;
}

const updateHeatWinnerOddsVoid = async ({ sport, eventId, heatId }: UPDATE_HEAT_WINNER_VOID) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/heats/${heatId}/odds/void`,
  });

  return data;
};

export default function useUpdateHeatWinnerOddsVoid() {
  return useMutation(({ sport, eventId, heatId }: UPDATE_HEAT_WINNER_VOID) =>
    updateHeatWinnerOddsVoid({ sport, eventId, heatId }),
  );
}
