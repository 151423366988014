import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getWeightClasses = async (sportType: string): Promise<string[]> => {
  const query = new URLSearchParams();
  query.append('sportType', sportType);
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/traders/${sportType}/weightClass`,
  });

  return data;
};

export default function useFetchWeightClasses(sportType: string, fromComponent: string) {
  return useQuery(
    [keys.fightSportMisc.fetchWeightClasses, sportType, fromComponent],
    () => getWeightClasses(sportType),
    {
      staleTime: Infinity,
    },
  );
}
