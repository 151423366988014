import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton, Tooltip } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import DecimalField from '@/components/DecimalField';

import { HeatStatus } from '@/constants/heats';

import { AthleteDTO } from '@/types/athlete';
import { HeatDTO, HeatScoresDTO } from '@/types/heat';

import { initialAthleteOption } from '@/constants/misc';
import { getUpdatedSLSRanksInScores } from '@/helpers/heats';

const tableColumns = ({
  formik,
  isSmallAndDown,
  isMediumAndDown,
  selectedHeat,
  heatScores,
  players,
  fieldCount,
  lineScoreCount,
  roundNo,
}: {
  formik: any;
  isSmallAndDown: boolean;
  isMediumAndDown: boolean;
  selectedHeat: HeatDTO;
  heatScores: { heat: any; scores: HeatScoresDTO[] };
  players: AthleteDTO[];
  fieldCount: number;
  lineScoreCount: number;
  roundNo: number;
}) => {
  const TSColumns = [...Array(fieldCount).keys()].map((key, ind) => {
    const index = ind + 1;
    const column = {
      title: `Trick Score ${index}`,
      hidden: isMediumAndDown,
      component: (idx: number) => {
        const rowValue: string = formik.values.heatScores[idx][`trick${index}Score`];
        let score: number | null = null;
        if (rowValue) score = +rowValue;

        return (
          <DecimalField
            key={`${formik.values.heatScores[idx].id}_trick_score_${index}`}
            disabled={formik.values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].trick${index}Score`}
            id={`${formik.values.heatScores[idx].id}_trick_score_${index}`}
            value={score?.toFixed(2)}
            fullWidth
            sx={
              (roundNo === 1 && [5, 6].includes(Number(index))) ||
              heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
              (score && score >= 0)
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
            onBlur={(event: React.BaseSyntheticEvent) => {
              formik.handleBlur(event);
              // Deep clone so that nested properties' refs are not linked to formik values.
              const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
              newHeatScores[idx] = {
                ...newHeatScores[idx],
                [`trick${index}Score`]: parseFloat(event.target.value),
              };
              formik.setFieldValue('heatScores', getUpdatedSLSRanksInScores(newHeatScores));
            }}
          />
        );
      },
    };
    return column;
  });
  const LineScoreColumns = [...Array(lineScoreCount).keys()].map((key, ind) => {
    const index = ind + 1;
    const column = {
      title: `Line Score ${index}`,
      hidden: isMediumAndDown,
      component: (idx: number) => {
        const rowValue: string = formik.values.heatScores[idx][`lineScore${index}`];
        let score: number | null = null;
        if (rowValue) score = +rowValue;

        return (
          <DecimalField
            key={`${formik.values.heatScores[idx].id}_line_score_${index}`}
            disabled={formik.values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].lineScore${index}`}
            id={`${formik.values.heatScores[idx].id}_line_score_${index}`}
            value={score?.toFixed(2)}
            fullWidth
            sx={
              (roundNo === 1 && [5, 6].includes(Number(index))) ||
              heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
              (score && score >= 0)
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
            onBlur={(event: React.BaseSyntheticEvent) => {
              formik.handleBlur(event);
              // Deep clone so that nested properties' refs are not linked to formik values.
              const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
              newHeatScores[idx] = {
                ...newHeatScores[idx],
                [`lineScore${index}`]: parseFloat(event.target.value),
              };
              formik.setFieldValue('heatScores', getUpdatedSLSRanksInScores(newHeatScores));
            }}
          />
        );
      },
    };
    return column;
  });
  return [
    {
      title: '',
      width: '10%',
      hidden: isMediumAndDown ? false : true,
      component: (idx: number) => (
        <Tooltip key={`${formik.values.heatScores[idx].id}_expand`} title={'Open details'}>
          <IconButton
            color="inherit"
            size="large"
            onClick={(event) => {
              const newIndex = formik.values.detailExpandedRow === idx ? -1 : idx;
              formik.setFieldValue('detailExpandedRow', newIndex);
              event.stopPropagation();
            }}
          >
            {<ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Seed',
      width: isMediumAndDown ? '10%' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <TextField
          key={`${formik.values.heatScores[idx].id}_seed`}
          type="number"
          disabled={formik.values.heatScores[idx].toDelete}
          name={`heatScores[${idx}].roundSeed`}
          defaultValue={formik.values.heatScores[idx].roundSeed}
          error={
            formik.errors.heatScores &&
            formik.touched.heatScores &&
            formik.errors?.heatScores[idx]?.roundSeed
              ? true
              : false
          }
          helperText={
            formik.errors.heatScores && formik.touched.heatScores
              ? formik.touched.heatScores[idx]?.roundSeed &&
                formik.errors.heatScores[idx]?.roundSeed &&
                'Round Seed is required'
              : ''
          }
          fullWidth
          onBlur={(event: React.BaseSyntheticEvent) => {
            formik.handleBlur(event);
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              roundSeed: +event.target.value,
            };
            formik.setFieldValue('heatScores', newHeatScores);
          }}
        />
      ),
    },
    {
      title: 'Athlete',
      width: isMediumAndDown ? (isSmallAndDown ? '70%' : '50%') : '30%',
      component: (idx: number) => (
        <Autocomplete
          key={`${formik.values.heatScores[idx].id}_athlete`}
          disableClearable
          disabled={formik.values.heatScores[idx].toDelete}
          //   defaultValue={null}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          options={players}
          value={formik.values.heatScores[idx]?.athlete || ''}
          onChange={(_: React.BaseSyntheticEvent, newValue: any | null) => {
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              athlete: newValue ? newValue : initialAthleteOption,
            };
            formik.setFieldValue('heatScores', newHeatScores);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].athlete`}
              onBlur={formik.handleBlur}
              fullWidth
              error={
                formik.errors.heatScores &&
                formik.touched.heatScores &&
                formik.errors?.heatScores[idx]?.athlete
                  ? true
                  : false
              }
              helperText={
                formik.errors.heatScores && formik.touched.heatScores
                  ? formik.errors?.heatScores[idx]?.athlete && 'Athlete is required'
                  : ''
              }
            />
          )}
        />
      ),
    },
    ...LineScoreColumns,
    ...TSColumns,
    {
      title: 'Round Score',
      field: 'roundScore',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const rowValue: string = formik.values.heatScores[idx].roundScore;
        let score: number | null = null;
        if (rowValue) score = +rowValue;

        return (
          <TextField
            key={`${formik.values.heatScores[idx].id}_round_score`}
            disabled={formik.values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].roundScore`}
            value={score?.toFixed(2)}
            fullWidth
            sx={{ input: { backgroundColor: '#E9ECEF' } }}
            InputProps={{
              readOnly: true,
            }}
          />
        );
      },
    },
    {
      title: 'Round Place',
      field: 'heatPosition',
      width: isMediumAndDown ? '1fr' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <TextField
          key={`${formik.values.heatScores[idx].id}_round_place`}
          disabled={formik.values.heatScores[idx].toDelete}
          name={`heatScores[${idx}].heatPosition`}
          value={formik.values.heatScores[idx].heatPosition}
          fullWidth
          sx={{ input: { backgroundColor: '#E9ECEF' } }}
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      title: 'Action',
      width: isMediumAndDown ? '1fr' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <Tooltip
          key={`${formik.values.heatScores[idx].id}_action`}
          title="Delete Row"
          placement="top"
          arrow
        >
          {/* Tooltip needs a child that isn't disabled, hence fragment */}
          <>
            <IconButton
              disabled={selectedHeat?.heatStatus === HeatStatus.COMPLETED}
              color={formik.values.heatScores[idx].toDelete ? 'warning' : 'default'}
              onClick={() =>
                formik.setFieldValue(
                  `heatScores.${idx}.toDelete`,
                  !formik.values.heatScores[idx].toDelete,
                )
              }
            >
              <DeleteIcon
                sx={{
                  fontSize: '1.65rem',
                  padding: '0.2rem',
                }}
              />
            </IconButton>
          </>
        </Tooltip>
      ),
    },
  ];
};

export default tableColumns;
