import { useMutation } from '@tanstack/react-query';
import { browserLocalPersistence, setPersistence, signInWithCustomToken } from 'firebase/auth';

import { SignInDTO } from '@/types/authentication';

import { authenticatedAxios } from '..';
import * as firebaseHelpers from '@/hooks/firebase';
import { setAuthToken } from '@/constants/localStorageKeys';

const firebaseCustomLogIn = async (token: string, forceUpdate = true) => {
  if (!token) return { token: '' };
  const auth = firebaseHelpers.getInstanceAuth();
  await setPersistence(auth, browserLocalPersistence);
  const userResponse = await signInWithCustomToken(auth, token);
  const tokenResult = userResponse.user.getIdTokenResult(forceUpdate);
  return tokenResult;
};

const logIn = async (requestPayload: SignInDTO) => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'POST',
    url: `/admin/auth/login`,
    data: requestPayload,
  });

  const user = await firebaseCustomLogIn(data?.token || '', false);
  const token = user?.token || '';

  if (token) {
    setAuthToken(token);
  }

  return { user: data?.user || null, token };
};

export default function useLogIn() {
  return useMutation((requestPayload: SignInDTO) => logIn(requestPayload));
}
