import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getSystemHealth = async (): Promise<any> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `system/health`,
  });

  return data;
};

export default function useFetchSystemHealth() {
  return useQuery([keys.misc.fetchSystemHealth], () => getSystemHealth(), {
    staleTime: Infinity,
    refetchInterval: 5 * (60 * 1000),
    refetchIntervalInBackground: true,
  });
}
