import { useQuery } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import keys from '@/constants/queryKeys';
import { SHOW_OR_PODIUM } from '@/constants/showOrPodium';

import { PodiumOddsDTO } from '@/types/odds/getOdds';

interface FETCH_PODIUM_ODDS {
  sport: string;
  eventId: string;
  showOrPodium?: string;
}

const getPodiumOdds = async ({ sport, eventId }: FETCH_PODIUM_ODDS): Promise<PodiumOddsDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/podiums/odds`,
  });
  return data;
};

export default function useFetchPodiumOdds({ sport, eventId, showOrPodium }: FETCH_PODIUM_ODDS) {
  return useQuery(
    [keys.odds.fetchPodiumOdds, sport, eventId],
    () => getPodiumOdds({ sport, eventId }),
    {
      staleTime: Infinity,
      enabled: !!eventId && showOrPodium === SHOW_OR_PODIUM.PODIUM,
    },
  );
}
