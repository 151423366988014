import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { processRoundStatus } from '@/helpers/events';

import { RoundDTO } from '@/types/round';
import { HeatDTO } from '@/types/heat';

interface RoundCardProps {
  ref: any;
  idx: number;
  round: {
    id: string;
    heats: [
      {
        id: string;
      },
    ];
    name: string;
    roundNo: number;
    roundStatus: number;
  };
  selectedRound: RoundDTO | HeatDTO | null;
  handleCurrentRound: Function;
}

const RoundCard = React.forwardRef((props: RoundCardProps, ref) => {
  const { idx, round, selectedRound, handleCurrentRound } = props;
  const { status, color, icon } = processRoundStatus(round.roundStatus);

  return (
    <Box
      ref={ref}
      data-testid={`${round.name}_carouselCard`}
      sx={{
        display: 'inline-block',
        height: 'auto',
        width: '11.875rem',
        marginRight: '0.5rem',
      }}
      key={round.id || idx}
    >
      <Paper
        onClick={() => handleCurrentRound(round)}
        sx={{
          border:
            selectedRound && selectedRound?.id === round.id
              ? '0.062rem solid #6EA8FE'
              : '0.062rem solid #DEE2E6',
          backgroundColor: selectedRound && selectedRound?.id === round.id ? '#E7F1FF' : '#ffffff',
          cursor: 'pointer',
          padding: '0.625rem 0.75rem',
          display: 'flex',
          gap: '0.75rem',
          boxShadow: 'none',
          borderRadius: '0.25rem',
          ':hover': {
            border: '0.062rem solid #6EA8FE',
          },
          transition: 'border 0.2s; backgroundColor 0.2s',
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
            },
          }}
        >
          {icon}
        </Box>
        <Box
          style={{
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Typography sx={{ color: 'info.main', fontSize: '0.875rem', fontWeight: '500' }}>
            {round.name}
          </Typography>
          <Typography color={color} fontSize={'0.812rem'}>
            {status}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
});
RoundCard.displayName = 'RoundCard';

export default RoundCard;
