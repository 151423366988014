type FightStatusKeysType = {
  [key: number]: string;
};

export const FightStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
  MEDICAL_STOPPAGE: 'MEDICAL STOPPAGE',
};

export const FightStatusKeys: FightStatusKeysType = {
  1: FightStatusNames.LIVE,
  2: FightStatusNames.UPCOMING,
  3: FightStatusNames.COMPLETED,
  4: FightStatusNames.CANCELED,
  5: FightStatusNames.POSTPONED,
  6: FightStatusNames.NEXT,
  7: FightStatusNames.IN_WINDOW,
  8: FightStatusNames.MEDICAL_STOPPAGE,
};

type FightStatusValuesType = {
  [key: string]: number;
};

export const FightStatusValues: FightStatusValuesType = {
  [FightStatusNames.LIVE]: 1,
  [FightStatusNames.UPCOMING]: 2,
  [FightStatusNames.COMPLETED]: 3,
  [FightStatusNames.CANCELED]: 4,
  [FightStatusNames.POSTPONED]: 5,
  [FightStatusNames.NEXT]: 6,
  [FightStatusNames.IN_WINDOW]: 7,
  [FightStatusNames.MEDICAL_STOPPAGE]: 8,
};

export const FightMatchMemberTypes = {
  ATHLETE: 1,
  JUDGE: 2,
  REFEREE: 3,
};

export const FightMatchMemberTypesLabel = {
  [FightMatchMemberTypes.ATHLETE]: 'Athlete',
  [FightMatchMemberTypes.JUDGE]: 'Judge',
  [FightMatchMemberTypes.REFEREE]: 'Referee',
};

export const FIGHT_MEMBER_TYPES_OPTIONS = [
  {
    label: 'Athlete',
    value: FightMatchMemberTypes.ATHLETE,
  },
  {
    label: 'Judge',
    value: FightMatchMemberTypes.JUDGE,
  },
  {
    label: 'Referee',
    value: FightMatchMemberTypes.REFEREE,
  },
];
