import v from 'voca';

export enum EventStatus {
  LIVE = 1,
  UPCOMING = 2,
  COMPLETED = 3,
  CANCELED = 4,
  POSTPONED = 5,
  NEXT = 6,
  IN_WINDOW = 7,
  FORFEIT = 8,
}

type EventStatusKeysType = {
  [key: number]: string;
};

export const EventStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
  FORFEIT: 'FORFEIT',
};

export const EventStatusKeys: EventStatusKeysType = {
  1: EventStatusNames.LIVE,
  2: EventStatusNames.UPCOMING,
  3: EventStatusNames.COMPLETED,
  4: EventStatusNames.CANCELED,
  5: EventStatusNames.POSTPONED,
  6: EventStatusNames.NEXT,
  7: EventStatusNames.IN_WINDOW,
  8: EventStatusNames.FORFEIT,
};

type EventStatusValuesType = {
  [key: string]: number;
};

export const EventStatusValues: EventStatusValuesType = {
  [EventStatusNames.LIVE]: 1,
  [EventStatusNames.UPCOMING]: 2,
  [EventStatusNames.COMPLETED]: 3,
  [EventStatusNames.CANCELED]: 4,
  [EventStatusNames.POSTPONED]: 5,
  [EventStatusNames.NEXT]: 6,
  [EventStatusNames.IN_WINDOW]: 7,
  [EventStatusNames.FORFEIT]: 8,
};

export const eventStatus = Object.values(EventStatusNames).map((value) => ({
  label: v.titleCase(value.replaceAll('_', ' ')),
  value: EventStatusValues[value],
}));
