import { useMutation } from '@tanstack/react-query';

import { WSLTourAddDTO } from '@/types/wsl/tour';
import { SLSLeagueAddDTO } from '@/types/sls/league';
import { NRXTourAddDTO } from '@/types/nrx/tour';
import { SPRLeagueAddDTO } from '@/types/spr/league';
import SPORT_TYPES from '@/constants/sportTypes';

import { authenticatedAxios } from '..';

interface ADD_TOURS_OR_LEAGUES {
  sport: string;
  requestPayload: {
    items: WSLTourAddDTO | NRXTourAddDTO | SLSLeagueAddDTO | SPRLeagueAddDTO;
  };
}

const addToursOrLeagues = async ({ sport, requestPayload }: ADD_TOURS_OR_LEAGUES) => {
  const endpoints = {
    [SPORT_TYPES.WSL]: 'admin/wsl/tours',
    [SPORT_TYPES.SLS]: 'admin/sls/leagues',
    [SPORT_TYPES.NRX]: 'admin/nrx/tours',
    [SPORT_TYPES.SPR]: 'admin/spr/tours',
  };
  const endpoint = endpoints[sport];
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: endpoint,
    data: requestPayload,
  });

  return data;
};

export default function useAddToursOrLeagues() {
  return useMutation(({ sport, requestPayload }: ADD_TOURS_OR_LEAGUES) =>
    addToursOrLeagues({ sport, requestPayload }),
  );
}
