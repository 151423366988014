import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';

export const propBetsPayoutVoidedPayloadFormatter = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return [];

  const toBeModifiedRows = JSON.parse(JSON.stringify(rows));
  const modifiedRows = toBeModifiedRows
    .filter((row: any) => (!row.payout && row.propBetPayout) || (!row.voided && row.editedVoid))
    .map((row: any) => ({
      id: row.id,
      payout: row.propBetPayout,
      voided: row.editedVoid,
    }));

  return modifiedRows;
};

export const propBetsPayoutPayloadFormatter = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return [];

  const toBeModifiedRows = JSON.parse(JSON.stringify(rows));
  const modifiedRows = toBeModifiedRows
    .filter((row: any) => !row.payout && row.propBetPayout)
    .map((row: any) => ({
      id: row.id,
      payout: row.propBetPayout,
    }));

  return modifiedRows;
};

export const propBetsVoidedPayloadFormatter = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return [];

  const toBeModifiedRows = JSON.parse(JSON.stringify(rows));
  const modifiedRows = toBeModifiedRows
    .filter((row: any) => !row.voided && row.editedVoid)
    .map((row: any) => ({
      id: row.id,
      voided: row.editedVoid,
    }));

  return modifiedRows;
};

export const propBetsFreshPayoutVoidedCount = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return 0;

  const payoutCount = rows.reduce((prevValue: number, row: any) => {
    if ((!row.payout && row.propBetPayout) || (!row.voided && row.editedVoid)) return ++prevValue;
    else return prevValue;
  }, 0);

  return payoutCount;
};

export const propBetsFreshPayoutCount = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return 0;

  const payoutCount = rows.reduce((prevValue: number, row: any) => {
    if (!row.payout && row.propBetPayout) return ++prevValue;
    else return prevValue;
  }, 0);

  return payoutCount;
};

export const initialPropBetsLoader = (propBets: any[]) => {
  const modPropBets = JSON.parse(JSON.stringify(propBets)).map((bet: any) => {
    return {
      ...bet,
      propBetId: bet?.id || '',
      ...bet?.eventParticipant,
      ...bet?.eventParticipant?.athlete,
      ...(bet?.athlete && {
        athlete: {
          ...bet?.athlete,
          athleteName: [bet?.athlete?.firstName, bet?.athlete?.middleName, bet?.athlete?.lastName]
            .filter(Boolean)
            .join(' '),
        },
      }),
      decimalOdds: round(oddsHelpers.getDecimalOdds(bet.odds), 2),
      fractionalOdds: oddsHelpers.getFractionalOdds(bet.odds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOdds(bet.odds) > 0
          ? `+${oddsHelpers.getAmericanOdds(bet.odds)}`
          : `${oddsHelpers.getAmericanOdds(bet.odds)}`,
      propBetPayout: bet?.payout || false,
      editedVoid: bet?.voided || false,
    };
  });
  // .sort((a: any, b: any) => {
  //   const rowACanEdit = !a.voided && !a.payout;
  //   const rowBCanEdit = !b.voided && !b.payout;
  //   return rowACanEdit === rowBCanEdit ? 0 : rowACanEdit ? -1 : 1;
  // });

  return modPropBets;
};

export const propBetsPayloadFormatter = (propBets: any[]) => {
  const payload = propBets.map((propBet) => {
    return {
      id: propBet?.propBetId || '',
      ...(propBet?.eventParticipantId && { eventParticipantId: propBet?.eventParticipantId }),
      proposition: propBet?.proposition || '',
      odds: +propBet?.decimalOdds,
    };
  });

  return { items: payload };
};
