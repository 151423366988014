import SPORT_TYPES from '@/constants/sportTypes';
import { ODDS_PROJECTION_TYPE } from './oddsProjectionType';
import { ODD_TAB_QUERY_PARAMS } from './misc';

export const ODD_TAB_LABELS = {
  FASTEST_LAP: 'Quickest ET',
  EVENT_WINNER: 'Event Winner',
  SECOND_PLACE: '2nd Place',
  HEAT_WINNER: 'Heat Winner',
  FIGHT_WINNER: 'Fight Winner',
  HEAD_TO_HEAD: 'Head To Head',
  PROP_BETS: 'Prop Bets',
  SHOW_ODDS: 'Show Odds',
  PODIUM_ODDS: 'Podium Odds',
  DREAM_TEAM: 'Dream Team',
  EXACTAS_EVENT: 'Exactas',
  EXACTAS_HEAT: 'Exactas Heat',
};

export const EVENT_WINNERS_POSITION = 1;
export const EVENT_SECOND_POSITION = 2;
export const FASTEST_LAP_POSITION = -1;

export const EXCEL_DOC_TITLES_BASED_ON_POS = {
  [EVENT_WINNERS_POSITION]: 'Event Winner',
  [EVENT_SECOND_POSITION]: 'Second Place',
  [FASTEST_LAP_POSITION]: 'Quickest ET',
};

export const POSITION_PROJECTION_MAPPING = {
  [EVENT_WINNERS_POSITION]: ODDS_PROJECTION_TYPE.eventWinnerProjections,
  [EVENT_SECOND_POSITION]: ODDS_PROJECTION_TYPE.eventSecondPlaceProjections,
  [FASTEST_LAP_POSITION]: ODDS_PROJECTION_TYPE.fastestLapProjections,
};

export const EXACTAS_TYPE = { '2': 2, '3': 3, '4': 4 };

export const ODD_MARKET_NOTES = {
  EVENT_WINNER_MARKET_NOTES: 'eventWinnerMarketNotes',
  EVENT_SECOND_WINNER_MARKET_NOTES: 'eventSecondWinnerMarketNotes',
  EVENT_QUICKEST_ET_MARKET_NOTES: 'eventQuickestETMarketNotes',
  HEAT_MARKET_NOTES: 'heatMarketNotes',
  HEAD_TO_HEAD_NOTES: 'headToHeadNotes',
};

export const POSITION_NOTES_MAPPING = {
  [EVENT_WINNERS_POSITION]: ODD_MARKET_NOTES.EVENT_WINNER_MARKET_NOTES,
  [EVENT_SECOND_POSITION]: ODD_MARKET_NOTES.EVENT_SECOND_WINNER_MARKET_NOTES,
  [FASTEST_LAP_POSITION]: ODD_MARKET_NOTES.EVENT_QUICKEST_ET_MARKET_NOTES,
};

export const ODD_MARKET_TOGGLE_TYPE = {
  IS_EVENT_WINNER_MARKET_OPEN: 'isEventWinnerMarketOpen',
  IS_EVENT_SECOND_WINNER_MARKET_OPEN: 'isEventSecondWinnerMarketOpen',
  IS_EVENT_QUICKEST_ET_MARKET_OPEN: 'isEventQuickestETMarketOpen',
  IS_PLAYER_HEAD_TO_HEAD_OPEN: 'isPlayerHeadToHeadOpen',
  IS_HEAT_WINNER_MARKET_OPEN: 'isHeatWinnerMarketOpen',
};

export const POSITION_MARKET_TOGGLE_MAPPING = {
  [EVENT_WINNERS_POSITION]: ODD_MARKET_TOGGLE_TYPE.IS_EVENT_WINNER_MARKET_OPEN,
  [EVENT_SECOND_POSITION]: ODD_MARKET_TOGGLE_TYPE.IS_EVENT_SECOND_WINNER_MARKET_OPEN,
  [FASTEST_LAP_POSITION]: ODD_MARKET_TOGGLE_TYPE.IS_EVENT_QUICKEST_ET_MARKET_OPEN,
};

const wslOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.PROP_BETS,
    value: '5',
    queryStr: ODD_TAB_QUERY_PARAMS.propBets,
  },
  {
    label: ODD_TAB_LABELS.SHOW_ODDS,
    value: '6',
    queryStr: ODD_TAB_QUERY_PARAMS.showOdds,
  },
  {
    label: ODD_TAB_LABELS.PODIUM_ODDS,
    value: '7',
    queryStr: ODD_TAB_QUERY_PARAMS.podiumOdds,
  },
];

const slsOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.PROP_BETS,
    value: '5',
    queryStr: ODD_TAB_QUERY_PARAMS.propBets,
  },
  {
    label: ODD_TAB_LABELS.SHOW_ODDS,
    value: '6',
    queryStr: ODD_TAB_QUERY_PARAMS.showOdds,
  },
  {
    label: ODD_TAB_LABELS.PODIUM_ODDS,
    value: '7',
    queryStr: ODD_TAB_QUERY_PARAMS.podiumOdds,
  },
];

const nrxOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.PROP_BETS,
    value: '5',
    queryStr: ODD_TAB_QUERY_PARAMS.propBets,
  },
  {
    label: ODD_TAB_LABELS.SHOW_ODDS,
    value: '6',
    queryStr: ODD_TAB_QUERY_PARAMS.showOdds,
  },
  {
    label: ODD_TAB_LABELS.PODIUM_ODDS,
    value: '7',
    queryStr: ODD_TAB_QUERY_PARAMS.podiumOdds,
  },
];

const sprOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.PROP_BETS,
    value: '5',
    queryStr: ODD_TAB_QUERY_PARAMS.propBets,
  },
  {
    label: ODD_TAB_LABELS.SHOW_ODDS,
    value: '6',
    queryStr: ODD_TAB_QUERY_PARAMS.showOdds,
  },
  {
    label: ODD_TAB_LABELS.PODIUM_ODDS,
    value: '7',
    queryStr: ODD_TAB_QUERY_PARAMS.podiumOdds,
  },
];

const fdriftOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
];

const motocrsOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.PROP_BETS,
    value: '5',
    queryStr: ODD_TAB_QUERY_PARAMS.propBets,
  },
  {
    label: ODD_TAB_LABELS.PODIUM_ODDS,
    value: '6',
    queryStr: ODD_TAB_QUERY_PARAMS.podiumOdds,
  },
  {
    label: ODD_TAB_LABELS.EXACTAS_EVENT,
    value: '7',
    queryStr: ODD_TAB_QUERY_PARAMS.eventExacta,
  },
  {
    label: ODD_TAB_LABELS.EXACTAS_HEAT,
    value: '8',
    queryStr: ODD_TAB_QUERY_PARAMS.heatExacta,
  },
];

const f1OddTabs = [
  // {
  //   label: ODD_TAB_LABELS.EVENT_WINNER,
  //   value: '1',
  //   queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  // },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAT_WINNER,
  //   value: '3',
  //   queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  // },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.DREAM_TEAM,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.dreamTeam,
  },
];

const motoGPOddTabs = [
  // {
  //   label: ODD_TAB_LABELS.EVENT_WINNER,
  //   value: '1',
  //   queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  // },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAT_WINNER,
  //   value: '3',
  //   queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  // },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.DREAM_TEAM,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.dreamTeam,
  },
];

const mxGPOddTabs = [
  // {
  //   label: ODD_TAB_LABELS.EVENT_WINNER,
  //   value: '1',
  //   queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  // },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAT_WINNER,
  //   value: '3',
  //   queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  // },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  {
    label: ODD_TAB_LABELS.DREAM_TEAM,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.dreamTeam,
  },
];

const nhraOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  {
    label: ODD_TAB_LABELS.FASTEST_LAP,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.fastestLap,
  },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '4',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAT_WINNER,
  //   value: '3',
  //   queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  // },
  // {
  //   label: ODD_TAB_LABELS.DREAM_TEAM,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.dreamTeam,
  // },
];

const motoAmericaOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAT_WINNER,
  //   value: '3',
  //   queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  // },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  // {
  //   label: ODD_TAB_LABELS.DREAM_TEAM,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.dreamTeam,
  // },
];

const bybOddTabs = [
  {
    label: ODD_TAB_LABELS.FIGHT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.fightWinner,
  },
];

const bkfcOddTabs = [
  {
    label: ODD_TAB_LABELS.FIGHT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.fightWinner,
  },
];

const usacOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
  // {
  //   label: ODD_TAB_LABELS.SECOND_PLACE,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.secondPlace,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAT_WINNER,
  //   value: '3',
  //   queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  // },
  // {
  //   label: ODD_TAB_LABELS.HEAD_TO_HEAD,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  // },
  // {
  //   label: ODD_TAB_LABELS.DREAM_TEAM,
  //   value: '2',
  //   queryStr: ODD_TAB_QUERY_PARAMS.dreamTeam,
  // },
];

const powerslapOddTabs = [
  {
    label: ODD_TAB_LABELS.FIGHT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.fightWinner,
  },
];

const sprmtcrsOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
];

const worldOutLawsOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
];

const hlrsOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
];

const xGameOddTabs = [
  {
    label: ODD_TAB_LABELS.EVENT_WINNER,
    value: '1',
    queryStr: ODD_TAB_QUERY_PARAMS.eventWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAT_WINNER,
    value: '2',
    queryStr: ODD_TAB_QUERY_PARAMS.heatWinner,
  },
  {
    label: ODD_TAB_LABELS.HEAD_TO_HEAD,
    value: '3',
    queryStr: ODD_TAB_QUERY_PARAMS.headToHead,
  },
];

const oddTabs = {
  [SPORT_TYPES.WSL]: [...wslOddTabs],
  [SPORT_TYPES.SLS]: [...slsOddTabs],
  [SPORT_TYPES.NRX]: [...nrxOddTabs],
  [SPORT_TYPES.SPR]: [...sprOddTabs],
  [SPORT_TYPES.FDRIFT]: [...fdriftOddTabs],
  [SPORT_TYPES.MOTOCRS]: [...motocrsOddTabs],
  [SPORT_TYPES.F1]: [...f1OddTabs],
  [SPORT_TYPES.MOTOGP]: [...motoGPOddTabs],
  [SPORT_TYPES.MXGP]: [...mxGPOddTabs],
  [SPORT_TYPES.NHRA]: [...nhraOddTabs],
  [SPORT_TYPES.MOTOAMERICA]: [...motoAmericaOddTabs],
  [SPORT_TYPES.BYB]: [...bybOddTabs],
  [SPORT_TYPES.BKFC]: [...bkfcOddTabs],
  [SPORT_TYPES.USAC]: [...usacOddTabs],
  [SPORT_TYPES.POWERSLAP]: [...powerslapOddTabs],
  [SPORT_TYPES.SPRMTCRS]: [...sprmtcrsOddTabs],
  [SPORT_TYPES.WORLDOUTLAWS]: [...worldOutLawsOddTabs],
  [SPORT_TYPES.HLRS]: [...hlrsOddTabs],
  [SPORT_TYPES.XGAME]: [...xGameOddTabs],
};

export default oddTabs;
