import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface ADD_EVENTS {
  sport: string;
  requestPayload: any;
}

const addEvents = async ({ sport, requestPayload }: ADD_EVENTS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/events`,
    data: requestPayload,
  });

  return data;
};

export default function useAddEvents() {
  return useMutation(({ sport, requestPayload }: ADD_EVENTS) =>
    addEvents({ sport, requestPayload }),
  );
}
