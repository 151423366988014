import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// , useSearchParams
// import { useRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';

// import { unsavedChangesState } from '@/atoms/unsavedChanges';

import useFetchGame from '@/hooks/games/useFetchGame';
import useFetchGameHeatScores from '@/hooks/games/useFetchGameHeatScores';

// import { invalidateGame } from '@/helpers/cachedQueries';
import SPORT_TYPES from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';

const useSportEventGames = () => {
  // const [searchParams] = useSearchParams();
  // const eventStatus = searchParams.get('eventStatus');
  const { sportType = '', gameDateId = '' } = useParams();

  const queryClient = useQueryClient();
  // , setCurrentTabValue
  const [currentTabValue] = useState('3');
  // const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesState);
  // nextTabValue
  const [, setNextTabValue] = useState<string | null>(null);

  //   For MASL
  // const [navigatedGameId, setnavigatedGameId] = useState<string>('');

  /* If another tab is clicked. */
  // useEffect(() => {
  //   if (unsavedChanges.isChangeUnsaved && nextTabValue) {
  //     setUnsavedChanges({
  //       ...unsavedChanges,
  //       isTabNavigation: true,
  //     });
  //   }
  // }, [nextTabValue]);

  /* If next tab was clicked while having unsaved changes and 'Stay Here'/'Cancel' was clicked.
  Then set nextTabValue back to null. */
  // useEffect(() => {
  //   const isChangeUnsaved = unsavedChanges.isChangeUnsaved;
  //   const isTabNavigation = unsavedChanges.isTabNavigation;

  //   if (isChangeUnsaved && !isTabNavigation && nextTabValue) setNextTabValue(null);
  // }, [unsavedChanges]);

  /* If next tab was clicked while having unsaved changes and 'Confirm' was clicked.
  Go to next tab if changes saved or disregarded.*/
  // useEffect(() => {
  //   const isChangeUnsaved = unsavedChanges.isChangeUnsaved;
  //   const isNextTabPending = nextTabValue && currentTabValue !== nextTabValue;

  //   if (!isChangeUnsaved && isNextTabPending) {
  //     setCurrentTabValue(nextTabValue);
  //     setNextTabValue(null);
  //   }
  // }, [unsavedChanges, nextTabValue]);

  /* To refetch data "immediately", when game/event (cached) is visited again, by invalidating the previous visited game during the current game */
  // useEffect(() => {
  //   if (navigatedGameId) invalidateGame(queryClient, SPORT_TYPES.MASL, navigatedGameId);
  //   if (gameDateId) setnavigatedGameId(gameDateId);
  // }, [gameDateId, currentTabValue]);

  const {
    data: game,
    isLoading,
    isFetching,
  } = useFetchGame({
    sportType,
    gameDateId: sportType !== SPORT_TYPES.MASL ? gameDateId : undefined,
    params: {},
  });

  /* To refetch data when event (cached) is visited again */
  useEffect(() => {
    if (game) {
      queryClient.invalidateQueries([keys.games.fetchGame, sportType, gameDateId]);
    }
  }, [gameDateId]);

  const {
    data: maslGame,
    isLoading: isMASLGameHeatScoresLoading,
    isFetching: isMASLGameHeatScoresFetching,
  } = useFetchGameHeatScores({
    sportType,
    gameDateId: sportType === SPORT_TYPES.MASL ? gameDateId : undefined,
    params: {},
  });

  /* To refetch data when event (cached) is visited again */
  useEffect(() => {
    if (maslGame) {
      queryClient.invalidateQueries([keys.games.fetchGameHeatScores, sportType, gameDateId]);
    }
  }, [gameDateId]);

  return {
    ...(sportType !== SPORT_TYPES.MASL
      ? { game, isLoading, isFetching }
      : {
          game: maslGame,
          isLoading: isMASLGameHeatScoresLoading,
          isFetching: isMASLGameHeatScoresFetching,
        }),
    currentTabValue,
    setNextTabValue,
  };
};

export default useSportEventGames;
