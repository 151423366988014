export const NRX_HEAT_SCORES_STATUS: { [key: string]: string | null } = {
  ACTIVE: 'ACT',
  DNF: 'DNF',
  DNS: 'DNS',
  BYE: 'BYE',
  DSQ: 'DSQ',
  '--': null,
};

export const NRX_HEAT_SCORES_STATUS_REVERSE: { [key: string]: string } = {
  ACT: 'ACTIVE',
  DNF: 'DNF',
  DNS: 'DNS',
  BYE: 'BYE',
  DSQ: 'DSQ',
  null: '--',
};

export const NRX_HEAT_SCORES_STATUS_OPTIONS: { label: string; value: string | null }[] =
  Object.keys(NRX_HEAT_SCORES_STATUS).map((key: string) => ({
    label: key,
    value: NRX_HEAT_SCORES_STATUS[key],
  }));

export const INITIAL_NRX_HEAT_SCORES_STATUS_OPTION: { label: string; value: string | null } = {
  label: '--',
  value: null,
};
