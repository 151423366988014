import React from 'react';
import { Navigate } from 'react-router-dom';

import routes from '@/constants/routes';
import { getAuthToken } from '@/constants/localStorageKeys';

import { Props } from './interfaces';

const UnProtectedRoute: React.VFC<Props> = ({ children }) => {
  const token = getAuthToken();
  if (token) {
    return <Navigate to={routes.events.home} replace />;
  }

  return children;
};

export default UnProtectedRoute;
