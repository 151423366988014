import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

import { HeatDTO } from '@/types/heat';

interface ADD_HEATS {
  sport: string;
  requestPayload: {
    items: HeatDTO;
  };
}

const addHeats = async ({ sport, requestPayload }: ADD_HEATS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/heats`,
    data: requestPayload,
  });

  return data;
};

export default function useAddHeats() {
  return useMutation(({ sport, requestPayload }: ADD_HEATS) => addHeats({ sport, requestPayload }));
}
