import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
// import { createStyled } from '@mui/system';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

// Update the Button's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0D6EFD',
    },
    secondary: {
      main: grey[200],
    },
    info: {
      main: '#212529', // Close to black
      contrastText: '#6C757D', // Gray
      light: '#DEE2E6', // Light gray
      dark: '#495057', // Dark gray
    },
    success: {
      main: '#198754',
      light: '#E8F6F0',
    },
    neutral: {
      main: '#72727E',
      contrastText: '#fff',
      dark: '#5d5d67',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          color: 'white',
          backgroundColor: grey[400],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          marginTop: '0',
        },
      },
    },
  },
});

export const heatManagementTheme = createTheme(theme, {
  typography: {
    h4: {
      fontWeight: 500,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          marginTop: '0',
          padding: 0,
          fontSize: '1.2rem',
        },
        input: {
          padding: '1px!important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [theme.breakpoints.only('sm')]: {
            fontSize: '0.8rem',
          },
          [theme.breakpoints.only('md')]: {
            fontSize: '0.8rem',
          },
          [theme.breakpoints.only('lg')]: {
            fontSize: '0.75rem',
          },
          [theme.breakpoints.only('xl')]: {
            fontSize: '0.85rem',
          },
          '& input.Mui-disabled': {
            backgroundColor: grey[200],
          },
        },
        input: {
          paddingLeft: '1px!important',
          paddingRight: '1px!important',
          textAlign: 'center',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          borderBottom: 'none!important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          [theme.breakpoints.only('lg')]: {
            padding: '0.25rem',
          },
          [theme.breakpoints.only('xl')]: {
            padding: '0.5rem',
          },
          borderBottom: 'none',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2rem',
        },
      },
    },
  },
});

export const scoreManagementTheme = createTheme(theme, {
  typography: {
    h4: {
      fontWeight: 500,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          marginTop: '0',
          padding: 0,
          fontSize: '1.2rem',
        },
        input: {
          padding: '1px!important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          [theme.breakpoints.only('sm')]: {
            fontSize: '0.8rem',
          },
          [theme.breakpoints.only('md')]: {
            fontSize: '0.8rem',
          },
          [theme.breakpoints.only('lg')]: {
            fontSize: '0.75rem',
          },
          [theme.breakpoints.only('xl')]: {
            fontSize: '0.85rem',
          },
          '& input.Mui-disabled': {
            backgroundColor: grey[200],
          },
        },
        input: {
          paddingLeft: '1px!important',
          paddingRight: '1px!important',
          textAlign: 'center',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          padding: '0.5rem',
          borderBottom: 'none!important',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          [theme.breakpoints.only('lg')]: {
            padding: '0.25rem',
          },
          [theme.breakpoints.only('xl')]: {
            padding: '0.5rem',
          },
          borderBottom: 'none',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2rem',
        },
      },
    },
  },
});

// export const styledHeatManagementTheme = createStyled({ defaultTheme: heatManagementTheme });

// Used in events listing tables to have rows with gaps inbetween
export const tableRowsWithGapsTheme = createTheme(theme, {
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 0.5rem',
          border: 'transparent',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '0.625rem',
          paddingTop: '0.875rem',
          paddingBottom: '0.875rem',
          borderBottom: '1px solid #DEE2E6',
          borderTop: '1px solid #DEE2E6',
          '&:first-of-type': {
            borderLeft: '1px solid #DEE2E6',
            borderTopLeftRadius: '0.25rem',
            borderBottomLeftRadius: '0.25rem',
          },
          '&:last-of-type': {
            borderRight: '1px solid #DEE2E6',
            borderTopRightRadius: '0.25rem',
            borderBottomRightRadius: '0.25rem',
          },
        },
      },
    },
  },
});

// Styles that are common for Odds tables (like Event winner, 2nd place, head to head etc.).
// These can be overwritten with local styles or by using another theme.
export const oddsTablesTheme = createTheme(theme, {
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#212529 !important',
          fontSize: '0.812rem !important',
          fontWeight: '400 !important',
        },
        body: {
          padding: '0.375rem 0.625rem',
          lineHeight: '2.38rem',
        },
        head: {
          fontWeight: '500 !important',
          backgroundColor: '#F8F9FA !important',
          lineHeight: '1.25rem',
          padding: '1rem 0.625rem',
        },
      },
    },
    // Table container
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid rgba(0, 0, 0, 0.125)',
        },
      },
    },
  },
});
