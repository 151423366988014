import SPORT_TYPES from '@/constants/sportTypes';

export const allowedWSLHeaders = [
  { title: 'Event Name', field: 'name' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Year', field: 'year' },
  { title: 'Event Location', field: 'eventLocation' },
  { title: 'Event Location Group', field: 'eventLocationGroup' },
  { title: 'Event Number', field: 'eventNumber' },
  { title: 'Event Status', field: 'eventStatusMod' },
  { title: 'Start Date', field: 'startDate' },
  { title: 'End Date', field: 'endDate' },
];

export const allowedSLSHeaders = [
  { title: 'Event Name', field: 'name' },
  { title: 'League Name', field: 'leagueName' },
  { title: 'Year', field: 'year' },
  { title: 'Gender', field: 'gender' },
  { title: 'Event Location', field: 'eventLocation' },
  { title: 'Event Location Group', field: 'eventLocationGroup' },
  { title: 'Event Status', field: 'eventStatusMod' },
  { title: 'Start Date', field: 'startDate' },
  { title: 'End Date', field: 'endDate' },
];

export const allowedNRXHeaders = [
  { title: 'Event Name', field: 'name' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Year', field: 'year' },
  { title: 'Event Location', field: 'eventLocation' },
  { title: 'Event Location Group', field: 'eventLocationGroup' },
  { title: 'Event Number', field: 'eventNumber' },
  { title: 'Event Status', field: 'eventStatusMod' },
  { title: 'Start Date', field: 'startDate' },
  { title: 'End Date', field: 'endDate' },
  { title: 'Track Type', field: 'trackType' },
];

export const allowedSPRHeaders = [
  { title: 'Event Name', field: 'name' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Year', field: 'year' },
  { title: 'Event Location', field: 'eventLocation' },
  { title: 'Event Location Group', field: 'eventLocationGroup' },
  { title: 'Event Status', field: 'eventStatusMod' },
  { title: 'Event Number', field: 'eventNumber' },
  { title: 'Start Date', field: 'startDate' },
  { title: 'End Date', field: 'endDate' },
  { title: 'Track Type', field: 'trackType' },
  { title: 'Category Name', field: 'categoryName' },
];

export const allowedTableHeaders = {
  [SPORT_TYPES.WSL]: allowedWSLHeaders,
  [SPORT_TYPES.SLS]: allowedSLSHeaders,
  [SPORT_TYPES.NRX]: allowedNRXHeaders,
  [SPORT_TYPES.SPR]: allowedSPRHeaders,
};

interface CommonEventRow {
  name: string;
  year: number;
  gender: string;
  location: string;
  location_group: string;
  event_number: number;
  event_status: string;
  start_date: string;
  end_date: string;
  trackType?: string;
}

interface WSLEventRow extends CommonEventRow {
  tour: string;
  stop?: never;
}

interface SLSEventRow extends CommonEventRow {
  league?: string;
  stop?: number;
}

interface NRXEventRow extends CommonEventRow {
  tour?: string;
  stop?: never;
  trackType?: string;
}

interface SPREventRow extends CommonEventRow {
  league?: string;
  stop?: number;
}

export type EventRow = WSLEventRow | SLSEventRow | NRXEventRow | SPREventRow;

const commonRequiredKeys = ['name', 'year', 'gender', 'location', 'event_status', 'tour'];

export const WSLRequiredKeys = [...commonRequiredKeys, 'stop'];

export const SLSRequiredKeys = [...commonRequiredKeys];

export const NRXRequiredKeys = [...commonRequiredKeys, 'stop'];

export const SPRRequiredKeys = [...commonRequiredKeys];

export const requiredPayloadKeys = {
  [SPORT_TYPES.WSL]: WSLRequiredKeys,
  [SPORT_TYPES.SLS]: SLSRequiredKeys,
  [SPORT_TYPES.NRX]: NRXRequiredKeys,
  [SPORT_TYPES.SPR]: SPRRequiredKeys,
};
