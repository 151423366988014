import * as React from 'react';
import Chip from '@mui/material/Chip';
// import Typography from '@mui/material/Typography';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { IEvent } from '@/types/newTypes/event';
import orderBy from 'lodash.orderby';
import v from 'voca';

import { EventStatus, EventStatusNames, EventStatusValues } from '@/constants/events';

const processEventStatus = (eventStatus: number) => {
  let status = '';
  switch (eventStatus) {
    case 1:
      status = 'Live';
      break;
    case 2:
      status = 'Upcoming';
      break;
    case 3:
      status = 'Completed';
      break;
    case 4:
      status = 'Canceled';
      break;
    case 5:
      status = 'Postponed';
      break;
    case 6:
      status = 'Next';
      break;
    case 7:
      status = 'In Window';
      break;
    default:
      status = 'Completed';
      break;
  }
  return status;
};

const processEventStatusBadges = (eventStatus: number) => {
  let status: any = '';
  switch (eventStatus) {
    case 1:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#198754',
          }}
          label="Live"
        />
      );
      break;

    case 2:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label="Upcoming"
        />
      );
      break;

    case 3:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
    case 4:
      status = (
        <Chip
          size="small"
          label="Canceled"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;
    case 5:
      status = (
        <Chip
          size="small"
          label="Postponed"
          sx={{
            backgroundColor: '#DE5C9D',
          }}
        />
      );
      break;

    case 6:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#8C68CD',
          }}
          label="Next"
        />
      );
      break;

    case 7:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label="In Window"
        />
      );
      break;

    default:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
  }
  return status;
};

const processRoundStatus = (roundStatus: number) => {
  let status = '';
  let color = '';
  let icon: any = '';
  switch (roundStatus) {
    case 1:
      status = 'Live';
      color = '#198754';
      icon = <CheckCircleIcon sx={{ color: '#198754' }} />;
      break;
    case 2:
      status = 'Upcoming';
      color = '#9FA7AE';
      icon = <RadioButtonUncheckedIcon sx={{ color: '#9FA7AE' }} />;
      break;
    case 3:
      status = 'Completed';
      color = '#0DCAF0';
      icon = <CheckCircleIcon sx={{ color: '#0DCAF0' }} />;
      break;
    case 4:
      status = 'Canceled';
      color = '#DC3545';
      icon = <RadioButtonCheckedIcon sx={{ color: '#DC3545' }} />;
      break;
    case 5:
      status = 'Postponed';
      color = '#DE5C9D';
      icon = <RadioButtonUncheckedIcon />;
      break;
    case 6:
      status = 'Next';
      color = '#8C68CD';
      icon = <RadioButtonUncheckedIcon sx={{ color: '#8C68CD' }} />;
      break;
    case 7:
      status = 'In Window';
      color = '#9FA7AE';
      icon = <RadioButtonUncheckedIcon sx={{ color: '#9FA7AE' }} />;
      break;

    default:
      status = 'Completed';
      color = '#0DCAF0';
      icon = <CheckCircleIcon sx={{ color: '#0DCAF0' }} />;
      break;
  }
  return { status, color, icon };
};

const processRoundStatusBadges = (roundStatus: number) => {
  let status: any = '';
  switch (roundStatus) {
    case 1:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#198754',
          }}
          label="Live"
        />
      );
      break;
    case 2:
      status = (
        <Chip
          size="small"
          label="Upcoming"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
        />
      );
      break;
    case 3:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;

    case 4:
      status = (
        <Chip
          size="small"
          label="Canceled"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;
    case 5:
      status = (
        <Chip
          size="small"
          label="Postponed"
          sx={{
            backgroundColor: '#DE5C9D',
          }}
        />
      );
      break;

    case 6:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#8C68CD',
          }}
          label="Next"
        />
      );
      break;

    case 7:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label="In Window"
        />
      );
      break;

    default:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
  }
  return status;
};

const processHeatStatus = (roundStatus: number) => {
  let status = '';
  let color = '';
  let icon: any = '';
  switch (roundStatus) {
    case 1:
      status = 'Live';
      color = '#198754';
      icon = <CheckCircleIcon sx={{ color: '#198754' }} />;
      break;
    case 2:
      status = 'Upcoming';
      color = '#9FA7AE';
      icon = <RadioButtonUncheckedIcon sx={{ color: '#9FA7AE' }} />;
      break;
    case 3:
      status = 'Completed';
      color = '#0DCAF0';
      icon = <CheckCircleIcon sx={{ color: '#0DCAF0' }} />;
      break;
    case 4:
      status = 'Canceled';
      color = '#DC3545';
      icon = <RadioButtonCheckedIcon sx={{ color: '#DC3545' }} />;
      break;
    case 5:
      status = 'Postponed';
      color = '#DE5C9D';
      icon = <RadioButtonUncheckedIcon />;
      break;
    case 6:
      status = 'Next';
      color = '#8C68CD';
      icon = <RadioButtonUncheckedIcon sx={{ color: '#8C68CD' }} />;
      break;
    case 7:
      status = 'In Window';
      color = '#9FA7AE';
      icon = <RadioButtonUncheckedIcon sx={{ color: '#9FA7AE' }} />;
      break;

    default:
      status = 'Completed';
      color = '#0DCAF0';
      icon = <CheckCircleIcon sx={{ color: '#0DCAF0' }} />;
      break;
  }
  return { status, color, icon };
};

const processHeatStatusBadges = (heatStatus: number, heatNo: number | string = '') => {
  let status: any = '';
  switch (heatStatus) {
    case 1:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          sx={{
            backgroundColor: '#198754',
          }}
          label="Live"
        />
      );
      break;
    case 2:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          label="Upcoming"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
        />
      );
      break;
    case 3:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;

    case 4:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          label="Canceled"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;
    case 5:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          label="Postponed"
          sx={{
            backgroundColor: '#DE5C9D',
          }}
        />
      );
      break;

    case 6:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          sx={{
            backgroundColor: '#8C68CD',
          }}
          label="Next"
        />
      );
      break;

    case 7:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label="In Window"
        />
      );
      break;

    default:
      status = (
        <Chip
          data-testid={`${heatNo}_heatStatusChip`}
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
  }
  return status;
};

const processEventSeedsStatusBadges = (eventStatus: number) => {
  let status: any = '';
  switch (eventStatus) {
    case 1:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#198754',
          }}
          label="Live"
        />
      );
      break;
    case 2:
      status = (
        <Chip
          size="small"
          label="Upcoming"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
        />
      );
      break;
    case 3:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;

    case 4:
      status = (
        <Chip
          size="small"
          label="Canceled"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;
    case 5:
      status = (
        <Chip
          size="small"
          label="Postponed"
          sx={{
            backgroundColor: '#DE5C9D',
          }}
        />
      );
      break;

    case 6:
      status = (
        <Chip
          size="small"
          label="Next"
          sx={{
            backgroundColor: '#8C68CD',
          }}
        />
      );
      break;

    case 7:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label="In Window"
        />
      );
      break;

    default:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
  }
  return status;
};

/* Sorts events by eventStatus (live, next & upcoming above the rest) and startDate (most recent first) */
// Changing upcoming event status to affect the sorting order
const CHANGED_UPCOMING_EVENT_STATUS = 100 + EventStatusValues[EventStatusNames.UPCOMING];
const sortEventsByStatusAndStartDate = (events: IEvent[]): IEvent[] => {
  const sortedLiveUpcomingNextEvents = orderBy(
    events
      .filter(
        (event) =>
          event.eventStatus === EventStatus.LIVE ||
          event.eventStatus === EventStatus.UPCOMING ||
          event.eventStatus === EventStatus.NEXT,
      )
      .map((event) =>
        // Changing status of upcoming event to increased value
        event.eventStatus === EventStatus.UPCOMING
          ? { ...event, eventStatus: CHANGED_UPCOMING_EVENT_STATUS }
          : event,
      ),
    ['eventStatus', (event) => new Date(event.startDate).valueOf()],
    ['asc', 'asc'],
  );
  const sortedNonLiveUpcomingNextEvents = orderBy(
    events.filter(
      (event) =>
        event.eventStatus !== EventStatus.LIVE &&
        event.eventStatus !== EventStatus.UPCOMING &&
        event.eventStatus !== EventStatus.NEXT,
    ),
    ['eventStatus', (event) => new Date(event.startDate).valueOf()],
    ['desc', 'asc'],
  );
  return [
    ...sortedLiveUpcomingNextEvents.map((event) =>
      // Changing back the status of upcoming event to its original value
      event.eventStatus === CHANGED_UPCOMING_EVENT_STATUS
        ? { ...event, eventStatus: EventStatusValues[EventStatusNames.UPCOMING] }
        : event,
    ),
    ...sortedNonLiveUpcomingNextEvents,
  ];
};

const sortEventsByStartDate = (events: IEvent[]) =>
  orderBy(events, [(event) => new Date(event.startDate).valueOf()], ['asc']);

const EVENT_STATUS_OPTIONS = [
  { label: v.titleCase(EventStatusNames.LIVE), value: EventStatusValues[EventStatusNames.LIVE] },
  {
    label: v.titleCase(EventStatusNames.UPCOMING),
    value: EventStatusValues[EventStatusNames.UPCOMING],
  },
  {
    label: v.titleCase(EventStatusNames.COMPLETED),
    value: EventStatusValues[EventStatusNames.COMPLETED],
  },
  {
    label: v.titleCase(EventStatusNames.CANCELED),
    value: EventStatusValues[EventStatusNames.CANCELED],
  },
  {
    label: v.titleCase(EventStatusNames.POSTPONED),
    value: EventStatusValues[EventStatusNames.POSTPONED],
  },
  { label: v.titleCase(EventStatusNames.NEXT), value: EventStatusValues[EventStatusNames.NEXT] },
  {
    label: v.titleCase(EventStatusNames.IN_WINDOW.replaceAll('_', ' ')),
    value: EventStatusValues[EventStatusNames.IN_WINDOW],
  },
];

export {
  processEventStatus,
  processEventStatusBadges,
  processRoundStatus,
  processRoundStatusBadges,
  processHeatStatus,
  processHeatStatusBadges,
  processEventSeedsStatusBadges,
  sortEventsByStatusAndStartDate,
  sortEventsByStartDate,
  EVENT_STATUS_OPTIONS,
};
