import * as React from 'react';

import { Typography, Grid, Box } from '@mui/material';

import SportFuturesBreadcrumbs from '@/components/SportFuturesBreadcrumbs';
import FutureMarketToggle from '@/components/FutureMarketToggle';
import FutureMarketDateTimePicker from '@/components/FutureMarketDateTimePicker';

import { ReactComponent as EventDateIcon } from '@/images/icons/event-date-icon.svg';

import { displayInPT as format } from '@/helpers/timeConverters';

import { SportTourFuturesDetails } from '@/types/futures';

const SportTourFuturesDetails = ({
  sportType,
  tourYearId,
  sportTourFutures,
}: {
  sportType: string;
  tourYearId: string;
  sportTourFutures: SportTourFuturesDetails;
}) => {
  const { name = '', startDate = '', endDate = '', year = 0 } = sportTourFutures;

  return (
    <Grid
      container
      direction="column"
      sx={{ position: 'relative', marginTop: { xs: '6rem', sm: 0 } }}
      data-testid="event-details-wrapper"
    >
      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <SportFuturesBreadcrumbs sportTourFutures={sportTourFutures} />
      </Box>
      <Grid
        container
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '1rem', sm: 0 },
          marginBottom: '1.625rem',
        }}
      >
        <Grid item xs={12} sm={12}>
          <Typography
            sx={{
              marginBottom: '0.875rem',
              marginTop: '1rem',
              color: 'info.main',
              fontSize: '2rem',
              fontWeight: '500',
              display: 'inline-flex',
              alignItems: 'center',
            }}
            variant="h4"
          >
            {name}
            &nbsp;
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'info.main',
              fontSize: '0.875rem',
              lineHeight: '125%',
            }}
          >
            {(!!startDate || !!endDate) && (
              <>
                <EventDateIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                {((startDate && !endDate) || startDate === endDate) &&
                  `${format(new Date(startDate), 'LLL d, yyyy')}`}
                {startDate &&
                  endDate &&
                  startDate !== endDate &&
                  `${format(new Date(startDate), 'LLL d')} - ${format(
                    new Date(endDate),
                    'LLL d, yyyy',
                  )}`}
                &nbsp; &nbsp; &nbsp; &nbsp;
              </>
            )}
            {year && <>{year} season</>}
          </Typography>
        </Grid>
      </Grid>
      <FutureMarketToggle sportType={sportType} tourYearId={tourYearId} />
      <Box sx={{ mt: 3 }}>
        <FutureMarketDateTimePicker sportType={sportType} tourYearId={tourYearId} />
      </Box>
    </Grid>
  );
};

export default SportTourFuturesDetails;
