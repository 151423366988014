import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  // TextField,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import { TIME_TYPE } from '@/features/scores/ScoreManage/ScoreManageTimeline';
import { HeatStatusNames, HeatStatusValues } from '@/constants/heats';

const InfoIcon = styled(InfoOutlinedIcon)({
  color: '#0D6EFD',
  // marginRight: '5px',
  position: 'absolute',
});

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1rem 1rem 0.5rem 1rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '0.5rem 1rem 0.5rem 3rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1rem 1rem 3rem',
  },
}));

const LabelText = styled(Typography)({
  color: 'info.main',
  fontSize: '0.812rem',
  fontWeight: '500',
  marginBottom: '0.4rem',
});

type ConfirmDialogProps = {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  title: string;
  body: string;
  cancelBtnText?: string;
  confirmBtnText?: string;
  formik?: any;
  hasEndTimeHandler?: boolean;
};

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const {
    open,
    handleClose,
    handleConfirm,
    title,
    body,
    cancelBtnText = 'Cancel',
    confirmBtnText = 'Confirm',
    formik,
    hasEndTimeHandler = false,
  } = props;

  const currentDateTime = new Date();
  const [currentHeatState, setCurrentHeatState] = React.useState('');

  const timeHandler = (newValue: unknown, timeType: string) => {
    if (formik) {
      formik.setFieldValue(timeType, newValue || null);
    }
  };

  useEffect(() => {
    if (hasEndTimeHandler && formik) {
      if (!formik?.values?.endTime) {
        timeHandler(currentDateTime, TIME_TYPE.END_TIME);
      }
      setCurrentHeatState(formik?.values?.heatStatus);
      formik.setFieldValue('heatStatus', HeatStatusValues[HeatStatusNames.COMPLETED]);
    }
  }, []);

  const resetEndDateHandler = () => {
    if (hasEndTimeHandler) {
      timeHandler(null, TIME_TYPE.END_TIME);
      formik.setFieldValue('heatStatus', currentHeatState);
    }
    handleClose();
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          resetEndDateHandler();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>
        <InfoIcon />
        <Typography sx={{ fontWeight: 500, paddingLeft: '2rem' }}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{body}</Typography>
        {hasEndTimeHandler && (
          <Grid container sx={{ marginTop: '0.8rem' }}>
            <LabelText>Set End Time:</LabelText>
            <MobileTimePicker
              ampm={false}
              value={formik?.values?.endTime}
              views={['hours', 'minutes']}
              format="HH:mm"
              onChange={(newValue) => timeHandler(newValue, TIME_TYPE.END_TIME)}
              // disableOpenPicker
              slotProps={{
                textField: {
                  color: 'secondary',
                  fullWidth: true,
                  placeholder: 'End Time',
                },
              }}
              // renderInput={({ inputProps, ...params }) => (
              //   <TextField
              //     {...params}
              //     color="secondary"
              //     inputProps={{
              //       ...inputProps,
              //       placeholder: 'End Time',
              //     }}
              //     fullWidth
              //   />
              // )}
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                resetEndDateHandler();
              }}
              id="clickCancel"
              fullWidth
              disableElevation
            >
              {cancelBtnText}
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleConfirm();
              }}
              id="clickConfirm"
              fullWidth
              disableElevation
            >
              {confirmBtnText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default ConfirmDialog;
