export const invalidRowErrorMsgHelper = (requiredKeysSet: any, currentRowKeys: any) => {
  const missingRequiredKeys = new Set(
    [...requiredKeysSet].filter((key) => !currentRowKeys.has(key)),
  );

  let missingColumns = '';
  [...missingRequiredKeys].forEach((key, idx, arr) => {
    missingColumns += idx !== arr.length - 1 ? `${key}, ` : `${key} `;
  });

  return missingColumns;
};

export const requiredPropertyChecker = (requiredKeys: any[], row: any) =>
  requiredKeys.every((key: string) => key in row);
