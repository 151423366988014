import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { Team } from '@/types/odds/getOdds';

interface UPDATE_DREAM_TEAM_ODDS {
  sport: string;
  eventId: string;
  dreamTeamId: string;
  payload: { teams: Team[] };
}

const updateDreamTeamOdds = async ({
  sport,
  eventId,
  dreamTeamId,
  payload,
}: UPDATE_DREAM_TEAM_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/dreamTeam/${dreamTeamId}`,
    data: payload,
  });
  return data;
};

export default function useUpdatePositionalOdds() {
  return useMutation(({ sport, eventId, dreamTeamId, payload }: UPDATE_DREAM_TEAM_ODDS) =>
    updateDreamTeamOdds({ sport, eventId, dreamTeamId, payload }),
  );
}
