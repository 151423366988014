export const ODD_DATA_TYPES = {
  ODDS: 'ODDS',
  SHOWS: 'SHOWS',
  PODIUMS: 'PODIUMS',
  HEATS: 'HEATS',
  PROP_BETS: 'PROP_BETS',
  HEAD_TO_HEAD: 'HEAD_TO_HEAD',
  EXACTAS_EVENT: 'EXACTAS_EVENT',
  EXACTAS_HEAT: 'EXACTAS_HEAT',
  FIGHTS: 'FIGHTS',
};
