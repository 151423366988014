import React from 'react';
import {
  Grid,
  Button,
  Box,
  styled,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import v from 'voca';
import { useQueryClient } from '@tanstack/react-query';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Head from '@/components/Head';

import { editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import { EVENT_STATUS_OPTIONS } from '@/helpers/events';
import routes from '@/constants/routes';

import SPORT_TYPES, { sportOptions } from '@/constants/sportTypes';

import useAddEvents from '@/hooks/events/useAddEvents';

import { invalidateCachedSports } from '@/helpers/cachedQueries';

import { UPDATE_EVENT_PAYLOAD } from '@/hooks/events/useUpdateEvent';
import useFetchTours from '@/hooks/events/useFetchTours';
import useFetchEventLocationGroup from '@/hooks/events/useFetchEventLocationGroup';
import { EventStatusNames, EventStatusValues } from '@/constants/events';
import { enqueueSnackbar } from 'notistack';
import keys from '@/constants/queryKeys';

const FormLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

type EventLocationGroupOptionProp = { id?: string; label: string; inputValue?: string };

const filter = createFilterOptions<EventLocationGroupOptionProp>();

export const DATE_TYPE = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

const AddEvent = () => {
  const { mutateAsync: addEvents } = useAddEvents();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const bybTours = useFetchTours(SPORT_TYPES.BYB, 'Event');
  const bybEventLocationGroups = useFetchEventLocationGroup(SPORT_TYPES.BYB, 'Event');

  const powerslapTours = useFetchTours(SPORT_TYPES.POWERSLAP, 'Event');
  const powerslapEventLocationGroups = useFetchEventLocationGroup(SPORT_TYPES.POWERSLAP, 'Event');

  const currentUser: any = queryClient.getQueryData([keys.user.fetchProfile]);

  const restrictedSportKeys = React.useMemo(
    () =>
      currentUser?.restrictedSportKeys?.length > 0
        ? currentUser?.restrictedSportKeys
        : [SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP],
    [currentUser],
  );

  const RESTRICTED_SPORT_OPTIONS = React.useMemo(() => {
    return sportOptions.filter((sportOpt) => restrictedSportKeys.includes(sportOpt.value)) || [];
  }, [restrictedSportKeys]);

  const currentDate = new Date();

  const initialValues = {
    name: '',
    tour: '',
    gender: '',
    year: '',
    eventStatus: EventStatusValues[EventStatusNames.UPCOMING],
    eventLocation: '',
    eventLocationGroup: {
      label: '',
      inputValue: '',
    },
    startDate: null,
    endDate: null,
    eventNumber: '',
    sportType: restrictedSportKeys?.[0] || SPORT_TYPES.BYB,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required(),
      eventLocation: Yup.string().required(),
      year: Yup.number().required(),
      tour: Yup.mixed().required(),
    }),
    onSubmit: (data, { resetForm }) => {
      const requestPayload: UPDATE_EVENT_PAYLOAD = formatPayload();

      addEvents(
        { sport: data.sportType, requestPayload: { items: [requestPayload] } },
        {
          onSettled: (_, err) => {
            if (!err) {
              invalidateCachedSports(queryClient);
              enqueueSnackbar('Added Event successfully!');
            }
            resetForm();
          },
        },
      );
    },
  });

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldTouched,
  } = formik;

  const timeHandler = (newValue: unknown, dateType: string) => {
    setFieldValue(dateType, newValue || null);
  };

  const toursObj = React.useMemo(
    () => ({ [SPORT_TYPES.BYB]: bybTours?.data, [SPORT_TYPES.POWERSLAP]: powerslapTours?.data }),
    [bybTours, powerslapTours],
  );

  const tours: any = React.useMemo(
    () => toursObj[values.sportType] || [],
    [values.sportType, toursObj],
  );

  const eventLocationGroupsObj = React.useMemo(
    () => ({
      [SPORT_TYPES.BYB]: bybEventLocationGroups?.data,
      [SPORT_TYPES.POWERSLAP]: powerslapEventLocationGroups?.data,
    }),
    [bybEventLocationGroups, powerslapEventLocationGroups],
  );

  const eventLocationGroups: any = React.useMemo(
    () => eventLocationGroupsObj[values.sportType] || [],
    [values.sportType, eventLocationGroupsObj],
  );

  const EVENT_LOCATION_GROUPS: EventLocationGroupOptionProp[] = React.useMemo(() => {
    return (
      (eventLocationGroups &&
        eventLocationGroups?.map((eLocGrp: any) => ({
          id: eLocGrp?.id,
          label: eLocGrp?.eventLocationGroup,
        }))) ||
      []
    );
  }, [eventLocationGroups]);

  const { TOUR_OPTIONS, GENDER_OPTIONS, TOURS_WITH_YEARS } = React.useMemo(() => {
    const TOUR_OPTIONS = tours?.map((tour: any) => ({
      label: tour.name,
      value: tour.id,
      gender: tour.gender,
    }));

    const GENDER_OPTIONS: { [key: string]: string } = {};
    tours?.map((tour: any) => {
      GENDER_OPTIONS[tour.id] = tour.gender;
      return tour;
    });

    const TOURS_WITH_YEARS: { [key: string]: number[] } = {};
    tours?.map((tour: any) => {
      const years = tour.years;
      TOURS_WITH_YEARS[tour.id] = years
        .map(({ year }: any) => year)
        .sort((a: any, b: any) => a - b);
      return tour;
    });
    return { TOUR_OPTIONS, GENDER_OPTIONS, TOURS_WITH_YEARS };
  }, [tours]);

  const { YEAR_OPTIONS, YEAR_IDS } = React.useMemo(() => {
    const currentSelectedTour = tours?.find((tour: any) => tour.id === values.tour);
    const years = currentSelectedTour?.years || [];
    const YEAR_IDS: { [key: number]: string } = {};
    const YEAR_OPTIONS = years
      .sort((a: any, b: any) => a.year - b.year)
      .map(({ year, id }: any) => {
        YEAR_IDS[year] = id;
        return { label: year, value: year };
      });
    // setFieldValue('year', YEAR_OPTIONS.length > 0 && YEAR_OPTIONS[YEAR_OPTIONS.length - 1].value);
    return { YEAR_OPTIONS, YEAR_IDS };
  }, [values.tour]);

  const formatPayload = () => {
    const payload: UPDATE_EVENT_PAYLOAD = {
      gender: values.gender,
      tourName: TOUR_OPTIONS?.find((tr: any) => tr.value === values?.tour)?.label || '',
      year: values?.year,
      isSimulationEnabled: false,
      name: values.name,
      startDate: values.startDate,
      endDate: values.endDate || null,
      eventLocation: values.eventLocation,
      eventLocationGroup:
        (values?.eventLocationGroup && values?.eventLocationGroup?.inputValue
          ? values?.eventLocationGroup?.inputValue
          : values?.eventLocationGroup?.label) || '',
      eventStatus: values.eventStatus,
      tourYearId: YEAR_IDS[values.year as keyof {}],
      eventNumber: (values?.eventNumber && +values?.eventNumber) || 0,
    };
    return payload;
  };

  return (
    <>
      <Head title={'Add Event'} canonicalUrl={routes.events.add} />
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(routes.events.home)}
        sx={{ color: '#6C757D', '&:hover': { backgroundColor: 'transparent' } }}
        disableRipple
      >
        Back to events
      </Button>
      <Box display={'flex'} justifyContent={'center'}>
        <Box maxWidth="md">
          <form>
            <Typography
              sx={{
                fontWeight: 500,
                // paddingLeft: '2rem',
                fontSize: '1.5rem',
                lineHeight: '120%',
                mb: 2,
              }}
              component="h2"
            >
              Add Event
            </Typography>
            <Grid container sx={{ gap: '0.6rem' }}>
              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Sports Type</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    name="sportType"
                    value={values.sportType}
                    onChange={(event: SelectChangeEvent<typeof values.sportType>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('sportType', value);
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                  >
                    {React.Children.toArray(
                      RESTRICTED_SPORT_OPTIONS.map((option: any) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Name</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ ...editCellTextFieldStyle }}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>{`Tour`}</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <FormControl error={errors?.tour && touched?.tour ? true : false} fullWidth>
                    <Select
                      name="tour"
                      value={values.tour}
                      onChange={(event: SelectChangeEvent<typeof values.tour>) => {
                        const {
                          target: { value },
                        } = event;
                        setFieldValue('tour', value);
                        setFieldValue('gender', GENDER_OPTIONS[value as keyof {}]);
                        const tourYears = TOURS_WITH_YEARS[value as keyof {}];
                        tourYears.length > 0 &&
                          setFieldValue('year', tourYears[tourYears.length - 1]);
                      }}
                      sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                      fullWidth
                      error={errors?.tour && touched?.tour ? true : false}
                    >
                      {React.Children.toArray(
                        TOUR_OPTIONS?.map((option: any) => (
                          <MenuItem value={option.value}>{option.label}</MenuItem>
                        )),
                      )}
                    </Select>
                    {errors?.tour && touched?.tour && (
                      <FormHelperText>Tour/ League is required</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Gender</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="gender"
                    value={v.titleCase(values.gender)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled
                    sx={{ ...editCellTextFieldStyle }}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Season Year</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    name="year"
                    value={values.year}
                    onChange={(event: SelectChangeEvent<typeof values.year>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('year', value);
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                  >
                    {React.Children.toArray(
                      YEAR_OPTIONS.map((option: any) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Start Date</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <MobileDateTimePicker
                    format="MM/dd/yyyy HH:mm:ss"
                    value={values.startDate || currentDate}
                    onChange={(newValue: unknown) => {
                      setFieldTouched('startDate', true);
                      timeHandler(newValue, DATE_TYPE.START_DATE);
                    }}
                    sx={{ ...editCellTextFieldStyle }}
                    slotProps={{
                      textField: {
                        name: 'startDate',
                        helperText:
                          errors?.startDate && touched?.startDate ? 'Start Date is required' : '',
                        fullWidth: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>End Date</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <MobileDateTimePicker
                    format="MM/dd/yyyy HH:mm:ss"
                    value={values.endDate || currentDate}
                    onChange={(newValue: unknown) => {
                      setFieldTouched('endDate', true);
                      timeHandler(newValue, DATE_TYPE.END_DATE);
                    }}
                    sx={{ ...editCellTextFieldStyle }}
                    slotProps={{
                      textField: {
                        name: 'endDate',
                        helperText:
                          errors?.endDate && touched?.endDate ? 'End Date is required' : '',
                        fullWidth: true,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {
                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Stop Number</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name="eventNumber"
                      value={values.eventNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{ ...editCellTextFieldStyle }}
                    />
                  </Grid>
                </Grid>
              }

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Location</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="eventLocation"
                    value={values.eventLocation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ ...editCellTextFieldStyle }}
                    error={errors?.eventLocation && touched?.eventLocation ? true : false}
                    helperText={
                      errors?.eventLocation && touched?.eventLocation ? 'Location is required' : ''
                    }
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Location Group</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <Autocomplete
                    value={values.eventLocationGroup}
                    onChange={(_, newValue) => {
                      if (typeof newValue === 'string') {
                        setFieldValue('eventLocationGroup', {
                          label: newValue,
                        });
                      } else if (newValue && newValue?.inputValue) {
                        // Create a new value from the user input
                        setFieldValue('eventLocationGroup', {
                          label: newValue?.inputValue || '',
                          inputValue: newValue?.inputValue || '',
                        });
                      } else {
                        setFieldValue('eventLocationGroup', newValue);
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue = '' } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some((option) => inputValue === option.label);
                      if (inputValue !== '' && !isExisting) {
                        filtered.push({
                          inputValue,
                          label: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    options={EVENT_LOCATION_GROUPS}
                    freeSolo
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        padding: '0.442rem 0.5625rem',
                      },
                      '& .MuiInputBase-input': {
                        height: '0.41rem !important',
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="eventLocationGroup"
                        sx={{ ...editCellTextFieldStyle }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Status</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <Select
                    name="eventStatus"
                    value={values.eventStatus}
                    onChange={(event: SelectChangeEvent<typeof values.eventStatus>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('eventStatus', value);
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                  >
                    {React.Children.toArray(
                      EVENT_STATUS_OPTIONS.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent={'flex-end'}
              alignItems="center"
              columnSpacing={{ xs: 1 }}
              mt={3}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
              >
                <Button
                  variant="contained"
                  onClick={() => handleSubmit()}
                  fullWidth
                  disableElevation
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AddEvent;
