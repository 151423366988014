import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

const addOrUpdateClient = async (requestPayload: any) => {
  const { data } = await authenticatedAxios({
    method: requestPayload?.id ? 'PUT' : 'POST',
    url: `admin/clients${requestPayload?.id ? `/${requestPayload?.id}` : ''}`,
    data: requestPayload?.data,
  });

  return data;
};

export default function useAddOrUpdateClient() {
  return useMutation((requestPayload: any) => addOrUpdateClient(requestPayload));
}
