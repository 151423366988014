import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface ADD_BYB_SCORES {
  sport: string;
  eventId: string;
  requestPayload: any;
}

const addBYBScores = async ({ sport, eventId, requestPayload }: ADD_BYB_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/events/${eventId}/scores`,
    data: requestPayload,
  });

  return data;
};

export default function useAddBYBScores() {
  return useMutation(({ sport, eventId, requestPayload }: ADD_BYB_SCORES) =>
    addBYBScores({ sport, eventId, requestPayload }),
  );
}
