export const JAMarketTypes = {
  SERVE: 1,
  EVENT: 2,
  MATCH: 3,
  SETS: 4,
  PROPS: 5,
};

export const JAMarketTypesValue = {
  1: 'SERVE',
  2: 'EVENT',
  3: 'MATCH',
  4: 'SETS',
  5: 'PROPS',
};

export const MarketTypes = [
  JAMarketTypes.SERVE,
  JAMarketTypes.EVENT,
  JAMarketTypes.MATCH,
  JAMarketTypes.SETS,
  JAMarketTypes.PROPS,
];

export const JABetTypes = {
  CORRECT_SETS: 1,
  TOTAL_SETS: 2,
  MATCH_WINNER: 3,
  EVENT_WINNER: 4,
  WINS_NEXT_SERVE: 5,
};

export const JABetTypesValues = {
  1: 'CORRECT SETS',
  2: 'TOTAL SETS',
  3: 'MATCH WINNER',
  4: 'EVENT WINNER',
  5: 'WINS NEXT SERVE',
};

export const JASubMarketTypes = {
  MONEY_LINE: 1,
  SPREAD: 2,
  TOTAL: 3,
  OUTCOME_1: 4,
  OUTCOME_2: 5,
  OUTCOME_3: 6,
  OUTCOME_4: 7,
};

export const SubMarkets = [
  JASubMarketTypes.MONEY_LINE,
  JASubMarketTypes.SPREAD,
  JASubMarketTypes.TOTAL,
  JASubMarketTypes.OUTCOME_1,
  JASubMarketTypes.OUTCOME_2,
  JASubMarketTypes.OUTCOME_3,
  JASubMarketTypes.OUTCOME_4,
];

export const JASubMarketTypesValues = {
  1: 'MONEY LINE',
  2: 'SPREAD',
  3: 'TOTAL',
  4: 'OUTCOME 1',
  5: 'OUTCOME 2',
  6: 'OUTCOME 3',
  7: 'OUTCOME 4',
};

export const JATypes = {
  POSITIVE: 1,
  NEGATIVE: 2,
  OVER: 3,
  UNDER: 4,
};

export const JaiAlaiStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
  FORFEIT: 'FORFEIT',
};
// Refer constants/events.ts for status number mappings
export const JaiAlaiStatusValues = {
  [JaiAlaiStatusNames.LIVE]: 1,
  [JaiAlaiStatusNames.UPCOMING]: 2,
  [JaiAlaiStatusNames.COMPLETED]: 3,
  [JaiAlaiStatusNames.CANCELED]: 4,
  [JaiAlaiStatusNames.FORFEIT]: 8,
};

export const JaiAlaiMatchType = {
  S: 'SINGLE SEED',
  D: 'DOUBLE SEED',
};

export const JAI_ALAI_MARKET_LABELS = {
  SERVE: 'Serve',
  EVENT: 'Event',
  MATCH: 'Match',
  SETS: 'Sets',
  PROPS: 'Props',
};
