import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface ADD_EVENT_WEIGHTS {
  sport: string;
  eventId: string;
  requestPayload: {
    items: { type: string; location?: string | null; year?: number | null; weight: number }[];
  };
}

const addEventWeights = async ({ sport, eventId, requestPayload }: ADD_EVENT_WEIGHTS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/events/${eventId}/weights`,
    data: requestPayload,
  });

  return data;
};

export default function useAddEventsWeight() {
  return useMutation(({ sport, eventId, requestPayload }: ADD_EVENT_WEIGHTS) =>
    addEventWeights({ sport, eventId, requestPayload }),
  );
}
