import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import v from 'voca';
import * as xlsx from 'xlsx';
import { useRecoilState } from 'recoil';

import { sportsType } from '@/atoms/sportsType';
import Dropzone from '@/components/Dropzone';
import SPORT_TYPES, { sportOptions } from '@/constants/sportTypes';
import { WSLTraderScore } from '@/types/wsl/trader';
import { SLSTraderScore } from '@/types/sls/trader';
import { NRXTraderScore } from '@/types/nrx/trader';
import { SPRTraderScore } from '@/types/spr/trader';
import { filterHiddenFieldsOfFile } from '@/helpers/excel';
import useAddTraderScores from '@/hooks/traders/scores/useAddTraderScores';
import {
  TraderScoreRow,
  TraderTrickScoreRow,
  allowedTableHeaders,
  requiredPayloadKeys,
  SLSRoundScoreRow,
  SLSLineScoreRow,
  SLSRoundScoreRequiredKeys,
  SLSLineScoreRequiredKeys,
  SLSTrickScoreRequiredKeys,
} from '@/pages/trader/scores/upload/constants';
import { formatMMSSToSeconds } from '@/helpers/timeConverters';
import { NRX_HEAT_SCORES_STATUS_REVERSE } from '@/constants/nrxHeatScoresStatus';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';
import routes from '@/constants/routes';

import { requiredPropertyChecker, invalidRowErrorMsgHelper } from '@/helpers/uploadHelpers';

type TRADER_SCORES = WSLTraderScore[] | SLSTraderScore[] | NRXTraderScore[] | SPRTraderScore[];

const lineScoresObj = {
  lineScore1: 0,
  lineScore2: 0,
};

const trickScoresObj = {
  trick1Score: 0,
  trick2Score: 0,
  trick3Score: 0,
  trick4Score: 0,
  trick5Score: 0,
  trick6Score: 0,
};

export default function WSLTraderScoresUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState<any[]>([]);
  const { mutate: addTraderScores } = useAddTraderScores();
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<TRADER_SCORES>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        let modSheetDTO = [];
        if (selectedSport === SPORT_TYPES.WSL) {
          modSheetDTO = modSheet.map((data: WSLTraderScore) => {
            const heatScore = Number(data?.heatScoreMod || 0);
            delete data?.heatScoreMod;
            const finalData = {
              ...data,
              heatScore,
            };
            return finalData;
          });
        }
        if (selectedSport === SPORT_TYPES.NRX) {
          modSheetDTO = modSheet.map((data: NRXTraderScore) => {
            const lapTime = data?.lapTimeMod;
            delete data?.lapTimeMod;
            const finalData = {
              ...data,
              lapTime,
              isJoker: !!data?.isJoker,
              isBye: !!data?.isBye,
              status: data?.status || null,
            };
            return finalData;
          });
        }
        if (selectedSport === SPORT_TYPES.SPR) {
          modSheetDTO = modSheet.map((data: SPRTraderScore) => {
            delete data?.lapTimeMod;
            const finalData = {
              ...data,
            };
            return finalData;
          });
        }
        addTraderScores(
          {
            sport: selectedSport,
            requestPayload: {
              items: [SPORT_TYPES.WSL, SPORT_TYPES.NRX].includes(selectedSport)
                ? modSheetDTO
                : modSheet,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Trader Score Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers = allowedTableHeaders[selectedSport] || [];

      const formattedAllowedHeaders: any[] = headers.map((header: any) => {
        if (header.field === 'lineScore1') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.lineScore1 || 0).toFixed(2)}</Typography>
          );
          return header;
        }

        if (header.field === 'lineScore2') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.lineScore2 || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'roundScore') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.roundScore || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'trick1Score') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.trick1Score || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'trick2Score') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.trick2Score || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'trick3Score') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.trick3Score || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'trick4Score') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.trick4Score || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'trick5Score') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.trick5Score || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'trick6Score') {
          header.render = (rowData: SLSTraderScore) => (
            <Typography>{Number(rowData?.trick6Score || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'heatScoreMod' && selectedSport === SPORT_TYPES.WSL) {
          header.render = (rowData: WSLTraderScore) => (
            <Typography>{Number(rowData?.heatScoreMod || 0).toFixed(2)}</Typography>
          );
          return header;
        }
        if (header.field === 'status' && selectedSport === SPORT_TYPES.NRX) {
          header.render = (rowData: NRXTraderScore) => (
            <Typography>{NRX_HEAT_SCORES_STATUS_REVERSE[rowData?.status || 'null']}</Typography>
          );
          return header;
        }
        // if (header.field === 'lapTimeMod') {
        //   header.render = (rowData: NRXTraderScore) => (
        //     <Typography>{Number(rowData?.lapTimeMod || 0).toFixed(2)}</Typography>
        //   );
        //   return header;
        // }

        return header;
      });

      setAllowedHeaders(formattedAllowedHeaders);
    }
  }, [selectedSport]);

  const handleChange = () => {
    setSheetData([]);
  };

  const tableRowChecker = (rows: TraderScoreRow[], requiredFields: never[] | string[] = []) => {
    let message = '';
    const requiredKeys =
      requiredFields.length > 0 ? requiredFields : requiredPayloadKeys[selectedSport];
    const requiredKeysSet = new Set(requiredKeys);

    rows.find((row, index) => {
      const isValidRow = requiredPropertyChecker(requiredKeys, row);
      if (!isValidRow) {
        const missingColumns = invalidRowErrorMsgHelper(requiredKeysSet, new Set(Object.keys(row)));
        message = `Error on row { ${index + 2} }, Missing { ${missingColumns} }`;
      }
      // To loop through all rows
      return !isValidRow;
    });

    return message;
  };

  const tableDataModifierFn = (
    data: TraderScoreRow & { league?: string; round_score?: number },
  ) => {
    const {
      event_name,
      stop_number,
      gender,
      tour_name,
      year,
      round,
      athlete,
      heat_score,
      heat_number,
      league,
      // run_score,
      round_score,
      lap_number,
      is_joker = false,
      is_bye = false,
      status = null,
      notes = '',
    } = data;

    return {
      eventName: event_name,
      ...(selectedSport === SPORT_TYPES.WSL && {
        stopNumber: stop_number,
        gender: gender,
        tourName: tour_name,
        year: year,
        heatScoreMod: Number(heat_score || 0),
      }),
      ...(selectedSport === SPORT_TYPES.SLS && {
        leagueYear: year,
        leagueGender: gender,
        leagueName: league,
        roundScore: round_score,
      }),
      ...(selectedSport === SPORT_TYPES.NRX && {
        stopNumber: stop_number,
        gender: gender,
        tourName: tour_name,
        year: year,
        lapTime: heat_score ? formatMMSSToSeconds(`${heat_score}`) : '',
        lapTimeMod: heat_score ? formatMMSSToSeconds(`${heat_score}`) : '',
        lapNumber: lap_number || '',
        isJoker: !!is_joker,
        isBye: !!is_bye,
        status: status,
      }),
      ...(selectedSport === SPORT_TYPES.SPR && {
        // leagueName: league,
        gender: gender,
        year: year,
        lapTime: heat_score ? +formatMMSSToSeconds(`${heat_score}`) : '',
        lapTimeMod: heat_score ? +formatMMSSToSeconds(`${heat_score}`) : '',
        lapNumber: lap_number || '',
        stopNumber: stop_number,
        tourName: tour_name,
      }),
      roundName: round,
      heatNumber: heat_number,
      athlete,
      notes,
    };
  };

  const sheetNames = ['Round Scores', 'Line Scores', 'Trader Upload Trick Scores'];

  const customReadExcel = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const workbook = xlsx.read(e.target.result, {
        type: 'array',
        cellDates: true,
      });
      const [RoundScores, LineScores, TraderUploadTrickScores] = sheetNames;

      const roundScores = workbook.Sheets[RoundScores];
      const lineScores = workbook.Sheets[LineScores];
      const traderTrickScores = workbook.Sheets[TraderUploadTrickScores];

      if (!roundScores || !lineScores || !traderTrickScores) {
        const sheetNames = [];
        !roundScores && sheetNames.push(RoundScores);
        !lineScores && sheetNames.push(LineScores);
        !traderTrickScores && sheetNames.push(TraderUploadTrickScores);
        const filteredSheetNames = sheetNames.filter((name) => Boolean(name));
        const missingSheets = filteredSheetNames.join(', ');
        enqueueSnackbar(`File does not contain a sheet named "${missingSheets}"`);
      } else {
        let error = 0;
        const roundScoresJSON: SLSRoundScoreRow[] = xlsx.utils.sheet_to_json(roundScores);
        const lineScoresJSON: SLSLineScoreRow[] = xlsx.utils.sheet_to_json(lineScores);
        const traderTrickScoresJSON: TraderTrickScoreRow[] =
          xlsx.utils.sheet_to_json(traderTrickScores);
        const modRoundScoresJSON = filterHiddenFieldsOfFile(roundScoresJSON);
        const modLineScoresJSON = filterHiddenFieldsOfFile(lineScoresJSON);
        const modTraderTrickScoresJSON = filterHiddenFieldsOfFile(traderTrickScoresJSON);

        if (tableRowChecker(modRoundScoresJSON, SLSRoundScoreRequiredKeys)) {
          const message = tableRowChecker(modRoundScoresJSON);
          enqueueSnackbar(message);
          error++;
        }

        if (tableRowChecker(modLineScoresJSON, SLSLineScoreRequiredKeys)) {
          const message = tableRowChecker(modLineScoresJSON);
          enqueueSnackbar(message);
          error++;
        }

        if (tableRowChecker(modTraderTrickScoresJSON, SLSTrickScoreRequiredKeys)) {
          const message = tableRowChecker(modTraderTrickScoresJSON);
          enqueueSnackbar(message);
          error++;
        }

        if (error === 0) {
          type UniqueRoundScores = {
            [key: string]: any;
          };
          type UniqueScores = {
            [key: string]: { [key: string]: number };
          };
          const uniqRoundScore: UniqueRoundScores = {};
          const uniqLineScores: UniqueScores = {};
          const uniqTraderTrickScores: UniqueScores = {};

          // Generating unique key that is unique across multiple sheets refer "sheetNames"
          const uniqTraderScoreKeyGenerator = (key: SLSRoundScoreRow) =>
            v.kebabCase(
              `${v.lowerCase(key.league || '')} ${v.lowerCase(key.gender || '')} ${v.lowerCase(
                String(key.year || ''),
              )} ${v.lowerCase(key.event_name || '')} ${v.lowerCase(key.round || '')} ${v.lowerCase(
                String(key.heat_number || ''),
              )} ${v.lowerCase(key.athlete || '')}`,
            );

          modRoundScoresJSON.map((traderScr) => {
            if (!uniqRoundScore[uniqTraderScoreKeyGenerator(traderScr) as keyof UniqueRoundScores])
              uniqRoundScore[uniqTraderScoreKeyGenerator(traderScr) as keyof UniqueRoundScores] =
                traderScr;
            return traderScr;
          });

          // For storing line scores
          modLineScoresJSON.map((lineScr) => {
            if (!uniqLineScores[uniqTraderScoreKeyGenerator(lineScr) as keyof UniqueScores]) {
              uniqLineScores[uniqTraderScoreKeyGenerator(lineScr) as keyof UniqueScores] = {
                ...lineScoresObj,
              };
            }

            if (lineScr.line_number && lineScr.line_number >= 1 && lineScr.line_number <= 2) {
              uniqLineScores[uniqTraderScoreKeyGenerator(lineScr) as keyof UniqueScores][
                `lineScore${lineScr.line_number}`
              ] = lineScr.line_score || 0;
            }

            return lineScr;
          });

          // For storing trick scores
          modTraderTrickScoresJSON.map((traderTrickScr) => {
            if (
              !uniqTraderTrickScores[
                uniqTraderScoreKeyGenerator(traderTrickScr) as keyof UniqueScores
              ]
            ) {
              uniqTraderTrickScores[
                uniqTraderScoreKeyGenerator(traderTrickScr) as keyof UniqueScores
              ] = { ...trickScoresObj };
            }

            if (
              traderTrickScr.trick_number &&
              traderTrickScr.trick_number >= 1 &&
              traderTrickScr.trick_number <= 6
            ) {
              uniqTraderTrickScores[
                uniqTraderScoreKeyGenerator(traderTrickScr) as keyof UniqueScores
              ][`trick${traderTrickScr.trick_number}Score`] = traderTrickScr.trick_score || 0;
            }

            return traderTrickScr;
          });

          const finalSheetData: SLSTraderScore[] = [];

          Object.keys(uniqRoundScore).map((key) => {
            const row: any = {};
            const round = uniqRoundScore[key];

            row.eventName = round?.event_name;
            row.athlete = round?.athlete;
            row.leagueYear = round?.year;
            row.leagueName = round?.league;
            row.leagueGender = round?.gender;
            row.roundName = round?.round;
            row.heatNumber = round?.heat_number;
            row.roundScore = round?.round_score;
            const lineScoresCollection = uniqLineScores[key] || { ...lineScoresObj };
            const trickScoresCollection = uniqTraderTrickScores[key] || { ...trickScoresObj };

            finalSheetData.push({ ...row, ...lineScoresCollection, ...trickScoresCollection });
            return key;
          });
          // Sheet data is set using custom fn
          setSheetData(finalSheetData);
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.traders.scores.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Trader Score
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Trader Score"
        sheetName="Trader Upload"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableDataModifierFn={tableDataModifierFn}
        tableRowChecker={selectedSport === SPORT_TYPES.SLS ? undefined : tableRowChecker}
        customReadExcel={selectedSport === SPORT_TYPES.SLS ? customReadExcel : undefined}
      />
      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
          >
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} SCORES?`}
          body={'This will upload the scores.'}
        />
      )}
    </Box>
  );
}
