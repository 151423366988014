import parsePhoneNumber, { isValidPhoneNumber, CountryCode } from 'libphonenumber-js';

import * as genericConstants from '../constants/genericConstants';

export const getPhoneParser = (
  phone: string,
  country: CountryCode = genericConstants.DEFAULT_COUNTRY_CODE,
) => parsePhoneNumber(phone, country);

export const parsedNumber = (
  phone: string,
  country: CountryCode = genericConstants.DEFAULT_COUNTRY_CODE,
) => {
  try {
    const phoneParser = getPhoneParser(phone, country);
    if (!phoneParser || (phoneParser && !phoneParser.isValid())) return null;

    return phoneParser.number;
  } catch (error) {
    return null;
  }
};

export const phoneNumberValidator = (
  phone: string,
  country: CountryCode = genericConstants.DEFAULT_COUNTRY_CODE,
) => isValidPhoneNumber(phone, country);

export const formattedPhoneText = (
  phone: string,
  country: CountryCode = genericConstants.DEFAULT_COUNTRY_CODE,
) => {
  const phoneParser = getPhoneParser(phone, country);
  return `+${phoneParser?.countryCallingCode || 1} ${phoneParser?.formatNational() || ''}` || '';
};
