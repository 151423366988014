import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

import { displayInPT as format } from '@/helpers/timeConverters';

import { TYPES_OF_START_AND_END_TIMES } from '@/constants/misc';

interface StartEndTimesProps {
  startDate: string | null;
  endDate: string | null;
  typeOfTime?: string;
}

const RoundDateHeading = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '0.812rem',
  fontWeight: '500',
}));

const RoundDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: '0.812rem',
  fontWeight: '400',
}));

const StartEndTimes = (props: StartEndTimesProps) => {
  const { startDate = '', endDate = '', typeOfTime = TYPES_OF_START_AND_END_TIMES.heat } = props;

  return (
    <Box sx={{ display: 'flex ', gap: '1.5rem' }}>
      <Box sx={{ display: 'flex ' }}>
        <RoundDateHeading>{typeOfTime} Start:&nbsp;</RoundDateHeading>
        <RoundDate>
          {startDate ? format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss') : 'TBD'}
        </RoundDate>
      </Box>
      <Box sx={{ display: 'flex ' }}>
        <RoundDateHeading>{typeOfTime} End:&nbsp;</RoundDateHeading>
        <RoundDate>{endDate ? format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss') : 'TBD'}</RoundDate>
      </Box>
    </Box>
  );
};

export default StartEndTimes;
