import { QueryClient } from '@tanstack/react-query';

import SnackbarUtils from '../utils/SnackbarUtils';

import { getAuthToken, PLAYER_URL, removeAuthToken } from '@/constants/localStorageKeys';

import * as firebaseHelpers from '@/hooks/firebase';
import config from '@/config/vars';

const queryErrorHandler = async (error: any): Promise<void> => {
  const errorCode = error?.response?.data?.statusCode;
  if (config.isDevelop) {
    console.log('error', error);
    console.log('token', getAuthToken());
  }

  const title = error?.response?.data?.message || 'Internal server error';

  const url = error?.config?.url || '';
  const isloginUrl = url.includes('login');

  // added login check to prevent redirect at the time of logging in
  // when incorrect creds are entered
  if ([401, 403].includes(errorCode) && !isloginUrl) {
    // need to logout
    try {
      localStorage.removeItem(PLAYER_URL);
      removeAuthToken();
      await firebaseHelpers.logout();
      queryClient.clear();
    } catch (ignoredError) {
      // ignored error;
    }
    window.location.replace('/');
  }

  SnackbarUtils.error(title);
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
    },
    mutations: {
      onError: queryErrorHandler,
    },
  },
});
