export const ROLES = {
  ADMIN: 1,
  TRADER: 2,
  HEAD_TRADER: 3,
  SCHEDULE_MANAGER: 4,
  DATA_LOGGER: 5,
};

export const ROLE_OPTIONS = [
  { label: 'Admin', value: ROLES.ADMIN },
  { label: 'Trader', value: ROLES.TRADER },
  { label: 'Head Trader', value: ROLES.HEAD_TRADER },
  { label: 'Schedule Manager', value: ROLES.SCHEDULE_MANAGER },
  { label: 'Data Logger', value: ROLES.DATA_LOGGER },
];

const USER_TYPES = { ADMIN: 'admin', TRADER: 'trader', CLIENT: 'client' };

export default USER_TYPES;
