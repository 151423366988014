import React from 'react';
import { Box } from '@mui/material';

import PublishOdds from '@/components/PublishOdds';

import useFetchDreamTeamOdds from '@/hooks/odds/dreamTeamOdds/useFetchDreamTeamOdds';

import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';

import DreamTeamMatchup from './DreamTeamMatchup';

import { DreamTeamOddRow } from '@/types/odds/getOdds';

const DreamTeamTab = (props: any) => {
  const { sport, eventId, handlePublishOdds } = props;
  const { data: dreamTeams } = useFetchDreamTeamOdds({
    sport,
    eventId,
  });

  const firstMatchup: DreamTeamOddRow | undefined = React.useMemo(() => {
    if (!dreamTeams) return undefined;

    const [matchup] = dreamTeams;

    return matchup;
  }, [dreamTeams]);

  return (
    <Box>
      <Box sx={{ mb: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <PublishOdds
          clientUpdatedAtDate={firstMatchup?.clientUpdatedAtDate || ''}
          traderUpdatedAtDate={firstMatchup?.traderUpdatedAtDate || ''}
          handlePublishOdds={() => {
            handlePublishOdds(ODDS_PROJECTION_TYPE.dreamTeamProjections);
          }}
        />
      </Box>
      {dreamTeams &&
        React.Children.toArray(
          dreamTeams.map((dreamTeam, idx) => (
            <DreamTeamMatchup dreamTeam={dreamTeam} idx={idx} sport={sport} />
          )),
        )}
    </Box>
  );
};

export default DreamTeamTab;
