import React from 'react';

import Typography from '@mui/material/Typography';
import { Collapse, Stack } from '@mui/material';
import Box from '@mui/material/Box';

import { HeatScoresDTO } from '@/types/heat';

const ScoreManageBody = ({ columns, formik }: { formik: any; columns: any[] }) => {
  const filteredColumns = columns.filter((column) => !column.hidden);
  const filteredHiddenColumns = columns.filter((column) => column.hidden === true);
  const columnWidths = filteredColumns.map((column) => column.width || '1fr');
  const generatedGridTemplateColumns = columnWidths.join(' ');

  return (
    <Box sx={{ display: 'block' }}>
      <Box
        sx={{
          display: 'grid',
          borderCollapse: 'collapse',
          minWidth: '100%',
          gridTemplateColumns: generatedGridTemplateColumns,
          gap: 1,
        }}
      >
        <Box
          data-role="thead"
          sx={{
            display: 'contents',
          }}
        >
          <Box
            data-role="tr"
            sx={{
              display: 'contents',
            }}
          >
            {filteredColumns.map((column, idx) => (
              <Box key={`title-${idx}`} sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontWeight: 500 }} variant="caption">
                  {column?.title || ''}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          data-role="tbody"
          sx={{
            display: 'contents',
          }}
        >
          <Box sx={{ display: 'contents' }}>
            {formik.values.heatScores.map((heatScore: HeatScoresDTO, idx: number) => (
              <Box sx={{ display: 'contents' }} key={`tbody-${idx}`}>
                <Box
                  data-role="tr"
                  sx={{
                    display: 'contents',
                  }}
                >
                  {filteredColumns.map((column, colIdx) => (
                    <Box
                      key={`title-${colIdx}`}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {column?.component(idx) || ''}
                    </Box>
                  ))}
                </Box>
                {formik.values.detailExpandedRow === idx && (
                  <Collapse
                    in={formik.values.detailExpandedRow === idx ? true : false}
                    sx={{ gridColumn: `1 / span ${filteredColumns.length}` }}
                  >
                    <Box
                      sx={{
                        display: 'grid',
                        borderCollapse: 'collapse',
                        minWidth: '100%',
                        gridTemplateColumns: '1fr 1fr',
                        gap: 1,
                        padding: { md: '2rem 6rem', xs: '2rem 1rem' },
                      }}
                    >
                      {filteredHiddenColumns.map((column, index) => {
                        return (
                          <Box sx={{ display: 'contents' }} key={`cell-${index}`}>
                            <Box>
                              <Stack
                                sx={{ height: '100%' }}
                                direction="column"
                                justifyContent="center"
                              >
                                <Typography>{column.title}</Typography>
                              </Stack>
                            </Box>
                            <Box>
                              <Box key={`title-${index}`}>{column?.component(idx) || ''}</Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Collapse>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ScoreManageBody;
