import React from 'react';

import { IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { HeatStatus } from '@/constants/heats';

import { HeatDTO, HeatScoresDTO } from '@/types/heat';

const KeyboardArrowRight = styled(KeyboardArrowRightIcon)({
  border: '2px solid #EEF0F2',
  borderRadius: '50%',
  fontSize: 40,
  padding: '0.4rem',
});
const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)({
  border: '2px solid #EEF0F2',
  borderRadius: '50%',
  fontSize: 40,
  padding: '0.4rem',
});

const ScoreManageFooter = ({
  formik,
  selectedHeat,
  handleCurrentHeat,
  prevHeat,
  nextHeat,
  finalHeatScores,
  handleToggleConfirmDialog,
  addNewRow,
  isFetchingHeatScore,
}: {
  formik: any;
  selectedHeat: HeatDTO;
  prevHeat: HeatDTO | null;
  nextHeat: HeatDTO | null;
  handleCurrentHeat: Function;
  handleToggleConfirmDialog: Function;
  addNewRow: Function;
  finalHeatScores: HeatScoresDTO[];
  isFetchingHeatScore: boolean;
}) => {
  return (
    <Grid container sx={{ marginTop: '1rem' }}>
      <Grid container item xs justifyContent="flex-end" alignItems="flex-start" direction="column">
        {prevHeat && (
          <>
            <IconButton onClick={() => handleCurrentHeat(prevHeat)}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography> {`${prevHeat?.heatName} ${prevHeat?.heatNo}`}</Typography>
          </>
        )}
      </Grid>
      <Grid item xs={8} container justifyContent="center" alignContent="center" spacing={2}>
        {formik.values.heatScores.length > 0 && (
          <Grid item>
            <Button
              disabled={isFetchingHeatScore}
              size="large"
              color="secondary"
              variant="contained"
              disableElevation
              onClick={() => {
                formik.submitForm();
              }}
            >
              Save
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            disabled={selectedHeat?.heatStatus === HeatStatus.COMPLETED}
            size="large"
            color="primary"
            variant="contained"
            disableElevation
            onClick={() => {
              addNewRow();
            }}
          >
            Add
          </Button>
        </Grid>
        {finalHeatScores.length > 0 && (
          <Grid item>
            <Button
              disabled={selectedHeat?.heatStatus === HeatStatus.COMPLETED || isFetchingHeatScore}
              size="large"
              variant="contained"
              color="primary"
              onClick={() => handleToggleConfirmDialog()}
              disableElevation
            >
              End this heat
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container item xs justifyContent="flex-end" alignItems="flex-end" direction="column">
        {nextHeat && (
          <>
            <IconButton onClick={() => handleCurrentHeat(nextHeat)}>
              <KeyboardArrowRight />
            </IconButton>
            <Typography>{`${nextHeat?.heatName} ${nextHeat?.heatNo}`}</Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ScoreManageFooter;
