import { useMutation } from '@tanstack/react-query';

import { WSLTraderScoreAddDTO } from '@/types/wsl/trader';
import { SLSTraderScoreAddDTO } from '@/types/sls/trader';
import { NRXTraderScoreAddDTO } from '@/types/nrx/trader';
import { SPRTraderScoreAddDTO } from '@/types/spr/trader';

import { authenticatedAxios } from '../../';

interface ADD_TRADER_SCORES {
  sport: string;
  requestPayload: {
    items:
      | WSLTraderScoreAddDTO
      | SLSTraderScoreAddDTO
      | NRXTraderScoreAddDTO
      | SPRTraderScoreAddDTO;
  };
}

const addTraderScores = async ({ sport, requestPayload }: ADD_TRADER_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/scores`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderScores() {
  return useMutation(({ sport, requestPayload }: ADD_TRADER_SCORES) =>
    addTraderScores({ sport, requestPayload }),
  );
}
