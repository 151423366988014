import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const navbarState = atom({
  key: 'navbarState',
  default: null || {
    top: '',
    parent: '',
    child: '',
    grandChild: '',
  },
  effects_UNSTABLE: [persistAtom],
});

const expandMenuState = atom({
  key: 'expandMenuState',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export { navbarState, expandMenuState };
