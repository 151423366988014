export enum HeatStatus {
  LIVE = 1,
  UPCOMING = 2,
  COMPLETED = 3,
  CANCELED = 4,
  POSTPONED = 5,
  NEXT = 6,
  IN_WINDOW = 7,
}

type HeatStatusKeysType = {
  [key: number]: string;
};

export const HeatStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
};

export const HeatStatusKeys: HeatStatusKeysType = {
  1: HeatStatusNames.LIVE,
  2: HeatStatusNames.UPCOMING,
  3: HeatStatusNames.COMPLETED,
  4: HeatStatusNames.CANCELED,
  5: HeatStatusNames.POSTPONED,
  6: HeatStatusNames.NEXT,
  7: HeatStatusNames.IN_WINDOW,
};

type HeatStatusValuesType = {
  [key: string]: number;
};

export const HeatStatusValues: HeatStatusValuesType = {
  [HeatStatusNames.LIVE]: 1,
  [HeatStatusNames.UPCOMING]: 2,
  [HeatStatusNames.COMPLETED]: 3,
  [HeatStatusNames.CANCELED]: 4,
  [HeatStatusNames.POSTPONED]: 5,
  [HeatStatusNames.NEXT]: 6,
  [HeatStatusNames.IN_WINDOW]: 7,
};

export const HEAT_VIEW_MODES = {
  default: 0,
  list: 1,
};

export const NRX_LAPS_STARTS_WITH = 0;

export const NRX_DEFAULT_NUMBER_OF_LAPS = 6;
