import '@/utils/highlight';
import { useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import { alpha } from '@mui/material/styles';
import { EditorProps } from './types';
import { StyledEditor } from './styles';
import Toolbar, { formats } from './toolbar';
import sanitizeHtml from 'sanitize-html';

// Extend the Quill clipboard module
const Clipboard = Quill.import('modules/clipboard');

class CustomClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault();

    const clipboardData = e.clipboardData;
    const html = clipboardData.getData('text/html');
    const text = clipboardData.getData('text');

    let sanitizedHtml = '';

    if (html) {
      sanitizedHtml = sanitizeHtml(html, {
        allowedTags: [
          'b',
          'i',
          'em',
          'strong',
          'a',
          'ul',
          'ol',
          'li',
          'p',
          'br',
          'img',
          'span',
          'div',
        ],
        allowedAttributes: {
          '*': ['class'],
          a: ['href', 'target'],
          img: ['src', 'alt', 'width', 'height'],
        },
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
      });
    } else {
      sanitizedHtml = sanitizeHtml(text, { allowedTags: [], allowedAttributes: {} });
    }

    const range = this.quill.getSelection();

    if (range) {
      // Delete any selected text before inserting the new content
      this.quill.deleteText(range.index, range.length);
      // Insert sanitized HTML at the current cursor position
      this.quill.clipboard.dangerouslyPasteHTML(range.index, sanitizedHtml);
      // Move the cursor to the end of the pasted content
      this.quill.setSelection(range.index + sanitizedHtml.length);
    }
  }
}

// Register the custom clipboard module
Quill.register('modules/clipboard', CustomClipboard);

export default function Editor({
  id = 'minimal-quill',
  error,
  simple = true,
  helperText,
  sx,
  ...other
}: EditorProps) {
  const quillRef = useRef(null);

  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    syntax: true,
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
            '& .ql-editor': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            },
          }),
          ...sx,
        }}
      >
        <Toolbar id={id} simple={simple} />

        <ReactQuill
          ref={quillRef}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...other}
        />
      </StyledEditor>

      {helperText && helperText}
    </>
  );
}
