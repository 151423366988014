import React from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Typography, Stack, styled } from '@mui/material';

import { AntSwitch } from '@/components/Switch';

import {
  invalidateCachedSportTourFutures,
  invalidateCachedSportFutures,
} from '@/helpers/cachedQueries';

import useToggleSportTourFutures from '@/hooks/futures/sports/useToggleSportTourFutures';

import keys from '@/constants/queryKeys';

import { SportTourFutures } from '@/types/futures';

const ToggleTextContainer = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

const toggleText = {
  on: (
    <ToggleTextContainer>
      Markets are <strong>OPEN</strong>. Toggle to close.
    </ToggleTextContainer>
  ),
  off: (
    <ToggleTextContainer>
      Markets are <strong>CLOSED</strong>. Toggle to open.
    </ToggleTextContainer>
  ),
};

interface FutureMarketToggleProps {
  sportType: string;
  tourYearId: string;
}

const FutureMarketToggle = ({ sportType, tourYearId }: FutureMarketToggleProps) => {
  const queryClient = useQueryClient();
  const [isToggling, setIsToggling] = React.useState(false);
  const { mutate: toggleNotification } = useToggleSportTourFutures();

  const sportTourFutures: SportTourFutures | undefined = queryClient.getQueryData([
    keys.futures.fetchSportTourFutures,
    sportType,
    tourYearId,
    'SportTourFutures',
  ]);

  const isNotificationEnabled = React.useMemo(
    () => sportTourFutures?.isOpen,
    [sportTourFutures, sportType, tourYearId],
  );

  const toggleNotificationHandler = () => {
    setIsToggling(true);
    toggleNotification(
      { sportType, tourYearId, isEnabled: !isNotificationEnabled },
      {
        onSuccess: () => {
          setIsToggling(false);
          invalidateCachedSportFutures({ queryClient });
          invalidateCachedSportTourFutures({
            queryClient,
            sportType,
            tourYearId,
            fromComponent: 'SportTourFutures',
          });
        },
        onError: () => {
          setIsToggling(false);
        },
      },
    );
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        marginTop: '0.3rem',
      }}
    >
      <AntSwitch
        id="mySwitch"
        checked={isNotificationEnabled}
        disabled={isToggling}
        onChange={toggleNotificationHandler}
      />
      {isNotificationEnabled ? toggleText.on : toggleText.off}
    </Stack>
  );
};

export default FutureMarketToggle;
