import { Chip } from '@mui/material';

import { MASLStatusValues } from '@/constants/masl';

export const EVENT_STATUS_COLORS = {
  [MASLStatusValues.LIVE]: '#1AA179',
  [MASLStatusValues.UPCOMING]: '#DDA109',
  [MASLStatusValues.COMPLETED]: '#0AA2C0',
  [MASLStatusValues.FORFEIT]: '#846358',
};

export const processMASLEventStatusBadges = (eventStatus: number) => {
  let status: any = '';
  const bgColor = EVENT_STATUS_COLORS[eventStatus as keyof {}] || EVENT_STATUS_COLORS[3];
  switch (eventStatus) {
    case 1:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: bgColor,
          }}
          label="Live"
        />
      );
      break;

    case 2:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: bgColor,
          }}
          label="Upcoming"
        />
      );
      break;

    case 3:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: bgColor,
          }}
        />
      );
      break;

    case 8:
      status = (
        <Chip
          size="small"
          label="Forfeit"
          sx={{
            backgroundColor: bgColor,
          }}
        />
      );
      break;

    default:
      status = (
        <Chip
          size="small"
          label="Completed"
          sx={{
            backgroundColor: bgColor,
          }}
        />
      );
      break;
  }
  return status;
};
