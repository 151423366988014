import { useMutation } from '@tanstack/react-query';

import { TraderPodiumOddAddDTO } from '@/types/traderOddUpload';

import { authenticatedAxios } from '../..';

interface TraderPodiumOdds {
  sport: string;
  requestPayload: {
    items: TraderPodiumOddAddDTO;
  };
}

const addTraderPodiumOdds = async ({ sport, requestPayload }: TraderPodiumOdds) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/odds/podiums`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderPodiumOdds() {
  return useMutation(({ sport, requestPayload }: TraderPodiumOdds) =>
    addTraderPodiumOdds({ sport, requestPayload }),
  );
}
