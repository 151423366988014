import { formatInTimeZone, utcToZonedTime } from 'date-fns-tz';
import { isValid } from 'date-fns';

import { DEFAULT_TIMEZONE } from '@/constants/misc';

export const DEFAULT_START_OF_LAP_TIME = '00:00.000';

// Format time in SS.MS to MM:SS.MS, ex: 121.1 -> 02:01.100
export const formatSecondsToMMSS = (lapTimeInSeconds: string) => {
  const convertedLapTime = +lapTimeInSeconds;
  if (!convertedLapTime) {
    return DEFAULT_START_OF_LAP_TIME;
  }
  return new Date(convertedLapTime * 1000).toISOString().substring(14, 23);
};

// Format time in MM:SS.MS to SS.MS, ex: 02:01.100 -> 121.1
export const formatMMSSToSeconds = (lapTimeInMMSS: string) => {
  if (!lapTimeInMMSS) return 0;
  if (!lapTimeInMMSS.includes(':')) {
    return lapTimeInMMSS.includes('.') ? lapTimeInMMSS : 0;
  }
  const arr = lapTimeInMMSS.split(':');
  const seconds = arr.length > 1 ? +arr[0] * 60 + +arr[1] : 0;
  return +seconds.toFixed(3);
};

export const modifyMinutesAndSeconds = (currentTime = '', modifiedTime = '') => {
  if (currentTime) {
    const currentDate = new Date(currentTime);
    const modHrs = modifiedTime ? new Date(modifiedTime)?.getHours() || 0 : 0;
    const modMin = modifiedTime ? new Date(modifiedTime)?.getMinutes() || 0 : 0;
    currentDate.setHours(modHrs);
    currentDate.setMinutes(modMin);
    return currentDate;
  } else {
    return modifiedTime;
  }
};

export const displayInPT = (
  isoDateTime: Date | string,
  dateFormat: string = 'MMM d, yyyy',
  returnDate: boolean = false,
): Date | string => {
  const isValidDate = isValid(new Date(isoDateTime));

  if (!isoDateTime || !isValidDate)
    if (returnDate) return new Date();
    else return '';

  if (!returnDate) return formatInTimeZone(isoDateTime, DEFAULT_TIMEZONE, dateFormat);
  return utcToZonedTime(new Date(isoDateTime), DEFAULT_TIMEZONE);
};

export const getUserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone || DEFAULT_TIMEZONE;
