import * as React from 'react';

import { Box } from '@mui/material';

import { MarketTypesToggle } from './MarketTypesToggle';
import { MarketTypesDetails } from './MarketTypesDetails';
import { MarketTypes } from '@/constants/masl';

const Markets = ({ formik, updateOdds, handlePublishOdds }: any) => {
  const [disabledMarkets, setDisabledMarkets] = React.useState<{ [key: string]: boolean }>(
    MarketTypes.reduce((o, val) => ({ ...o, [val]: false }), {}),
  );

  const { values } = formik;

  return (
    <Box>
      {values?.odds && values?.odds?.length > 0 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: 'minmax(0,1fr)', md: 'minmax(0, 20fr) minmax(0, 80fr)' },
            gridTemplateAreas: {
              xs: `"marketTypesToggleSec"
          "marketTypesDetailSec"
          `,
              md: '"marketTypesToggleSec marketTypesDetailSec"',
            },
            gap: '1rem',
          }}
        >
          <Box sx={{ gridArea: 'marketTypesToggleSec' }}>
            <MarketTypesToggle
              formik={formik}
              disabledMarkets={disabledMarkets}
              setDisabledMarkets={setDisabledMarkets}
            />
          </Box>
          <Box sx={{ gridArea: 'marketTypesDetailSec' }}>
            <MarketTypesDetails
              formik={formik}
              disabledMarkets={disabledMarkets}
              setDisabledMarkets={setDisabledMarkets}
              updateOdds={updateOdds}
              handlePublishOdds={handlePublishOdds}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Markets;
