import { include } from 'named-urls';

const routes = {
  public: include('/', {
    login: 'login',
  }),
  dashboard: include('/', {
    home: '',
    sports: 'sports',
    years: 'years',
    locations: 'locations',
    locationGroups: 'location-groups',
  }),
  // Upload Routes
  traders: include('/traders', {
    home: '',
    scores: include('scores/', {
      upload: 'upload',
    }),
    seeds: include('seeds/', {
      upload: 'upload',
    }),
    points: include('points/', {
      upload: 'upload',
    }),
    odds: include('odds/', {
      event: include('event/', {
        upload: 'upload',
      }),
      heat: include('heat/', {
        upload: 'upload',
      }),
      headToHead: include('headToHead/', {
        upload: 'upload',
      }),
      shows: include('shows/', {
        upload: 'upload',
      }),
      podiums: include('podiums/', {
        upload: 'upload',
      }),
      propBets: include('propBets/', {
        upload: 'upload',
      }),
    }),
  }),
  toursLeagues: include('/tours-leagues', {
    home: '',
    upload: 'upload',
  }),
  rounds: include('/rounds', {
    home: '',
    upload: 'upload',
  }),
  heats: include('/heats', {
    home: '',
    upload: 'upload',
  }),
  // ---------------------------
  events: include('/events', {
    home: '',
    upload: 'upload',
    add: 'add',
  }),
  futures: include('/futures', {
    home: '',
  }),
  sportType: include(':sportType', {
    futures: include('futures/', {
      tourYearId: ':tourYearId',
    }),
    event: include('event/', {
      eventId: ':eventId',
    }),
    eventGames: include('event-games/', {
      gameDateId: ':gameDateId',
    }),
  }),
  userManagement: '/user-management',
  // login: '/login/',
  fourOhFour: '*',
};

export default routes;
