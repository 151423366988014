import * as React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { ReactComponent as JokerCheckedIcon } from '@/images/icons/joker-checked.svg';
import { ReactComponent as JokerUnCheckedIcon } from '@/images/icons/joker-unchecked.svg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Collapse, IconButton, Tooltip, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LapTimeField } from '@/components/LapTimeFormat';
import StartEndTimes from '@/components/StartEndTimes';

import { processHeatStatusBadges } from '@/helpers/events';
import {
  checkIfJokerLapTaken,
  formatNRXHeatScores,
  getUpdatedNRXRanksInScores,
} from '@/helpers/heats';
import { DEFAULT_START_OF_LAP_TIME, formatMMSSToSeconds } from '@/helpers/timeConverters';

import { HeatStatus } from '@/constants/heats';
import {
  NRX_HEAT_SCORES_STATUS_OPTIONS,
  INITIAL_NRX_HEAT_SCORES_STATUS_OPTION,
  NRX_HEAT_SCORES_STATUS,
} from '@/constants/nrxHeatScoresStatus';

import { AthleteDTO } from '@/types/athlete';
import { HeatDTO } from '@/types/heat';
import orderBy from 'lodash.orderby';

interface NRXHeatScoreProps {
  heat: HeatDTO;
  players: AthleteDTO[];
  saveHeatScore: (values: any, heatId: string) => void;
  generatePayload: Function;
  roundNo: number;
}

const HeatTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: '500',
  color: theme.palette.info.main,
  lineHeight: '1rem',
}));

const Container = styled(Paper)(({ theme }) => ({
  padding: '1rem 0',
  borderBottom: '0.12rem solid #DEE2E6',
  borderRadius: '0',
  boxShadow: 'none',
  ':first-of-type': {
    borderTop: '0.12rem solid #DEE2E6',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0.06rem solid #CED4DA',
  },
  '& .MuiInputBase-input': {
    padding: '0.5rem 0.625rem !important',
    fontSize: '0.875rem',
    fontWeight: '400',
    color: theme.palette.info.main,
  },
  '& .MuiAutocomplete-inputRoot': {
    padding: '0 !important',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.625rem',
    margin: '0.125rem 0.25rem 0 0.25rem',
    lineHeight: '1rem',
  },
  '& .MuiChip-root': {
    fontSize: '0.687rem',
    fontWeight: '600',
    maxHeight: '1.125rem',
  },
  '& .MuiChip-label': {
    padding: '0 0.25rem',
  },
}));

const ScoresWrapper = styled(Box)(({ theme }) => {
  return {
    '& .times-wrapper': {
      marginBottom: '2rem',
    },
    '& .display-flex': {
      display: 'flex',
    },
    '& .justify-center': {
      justifyContent: 'center',
    },
    '& .align-center': {
      alignItems: 'center',
    },
    '& .time-text': {
      root: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      input: {
        textAlign: 'left',
        paddingLeft: '14px!important',
        paddingTop: '12px!important',
        paddingBottom: '12px!important',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)!important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .start-time': {
      '& fieldset': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderRight: 'none',
      },
    },
    '& .end-time': {
      '& fieldset': {
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '0',
        borderLeft: 'none',
      },
    },
    '& .time-apply-btn': {
      [theme.breakpoints.up('xs')]: {
        marginLeft: '0',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '0.5rem',
      },
      height: '100%',
    },
    '& .custom-table': {
      display: 'grid',
      borderCollapse: 'collapse',
      minWidth: '100%',
      gap: '0.15rem',
      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
    },
    '& .collapse': {
      display: 'grid',
      borderCollapse: 'collapse',
      minWidth: '100%',
      gridTemplateColumns: '1fr 1fr',
      gap: 1,
      [theme.breakpoints.up('md')]: {
        padding: '2rem 6rem',
      },
      [theme.breakpoints.up('xs')]: {
        padding: '2rem 1rem',
      },
    },
    '& .chevron-icon': {
      border: '2px solid #EEF0F2',
      borderRadius: '50%',
      fontSize: 40,
      padding: '0.4rem',
    },
  };
});

const initialAthleteOption = { id: '', label: '' };
const FIRST_ROUND_NO = 1;

const NRXHeatScores = (props: NRXHeatScoreProps) => {
  const { heat, players, saveHeatScore, roundNo, generatePayload } = props;
  const theme = useTheme();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [processedHeat, setProcessedHeat] = React.useState<any>({ ...heat, scores: [] });
  const [lapsCountArr, setLapsCountArr] = React.useState<number[]>([]);

  React.useEffect(() => {
    // The scores data from the GET request comes with each entry representing info about a lap for each athlete,
    // The below code formats individual lap data into objects for each athlete that contains all of their lap/s data.
    const { sortedHeatScores, lapsArr } = formatNRXHeatScores(heat?.scores);
    setLapsCountArr(lapsArr);
    setProcessedHeat({
      ...heat,
      scores: sortedHeatScores && JSON.parse(JSON.stringify(sortedHeatScores)),
    });
  }, [heat]);

  /* Adding ranks & sorting heat scores by "status" and "roundSeed" (without "DSQs"), followed by "DSQs" */
  const initialSortedHeatScores = React.useMemo(() => {
    const rankedHeatScores = getUpdatedNRXRanksInScores(
      processedHeat.scores,
      lapsCountArr,
      roundNo,
      FIRST_ROUND_NO,
    );
    const activeHeatScores = rankedHeatScores.filter(
      (score) => score.status.value !== NRX_HEAT_SCORES_STATUS.DSQ,
    );
    const DSQHeatScores = rankedHeatScores.filter(
      (score) => score.status.value === NRX_HEAT_SCORES_STATUS.DSQ,
    );
    return [
      ...orderBy(activeHeatScores, ['status.value', 'roundSeed'], ['asc', 'asc']),
      ...DSQHeatScores,
    ];
  }, [processedHeat.scores]);

  const { values, setFieldValue, submitForm, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      heatScores: initialSortedHeatScores,
      detailExpandedRow: -1,
    },
    validationSchema: Yup.object().shape({
      heatScores: Yup.array().of(
        Yup.object().shape({
          toDelete: Yup.boolean().required(),
          athlete: Yup.object().when('toDelete', {
            is: false,
            then: (s) =>
              s.shape({
                id: Yup.string().nullable().defined().required(),
              }),
          }),
        }),
      ),
    }),
    onSubmit: (formValues, { setSubmitting }) => {
      const rankedHeatScores = getUpdatedNRXRanksInScores(
        formValues.heatScores,
        lapsCountArr,
        roundNo,
        FIRST_ROUND_NO,
      );
      const payload = generatePayload(
        { heatScores: rankedHeatScores },
        processedHeat,
        lapsCountArr,
      );
      saveHeatScore(payload, processedHeat.id);
      setSubmitting(false);
    },
  });

  const canUseJokerLap = React.useMemo(() => {
    return checkIfJokerLapTaken(values.heatScores, players, lapsCountArr);
  }, [JSON.stringify(values.heatScores)]);

  const LapColumns = lapsCountArr.map((key) => {
    const index = key;
    const formValueHeatScores: any[] = JSON.parse(JSON.stringify(values.heatScores));
    const lapNumberKey = `lap_${index}`;

    const column = {
      title: `Lap ${index}`,
      hidden: isMediumAndDown,
      component: (idx: number) => {
        const formattedLapTime: number = formValueHeatScores[idx][lapNumberKey]?.lapTime || 0;
        const unformattedLapTime: number = formatMMSSToSeconds(formattedLapTime.toString());
        return (
          <LapTimeField
            id={`heatScores[${idx}].lap_${index}`}
            name={`heatScores[${idx}].lap_${index}`}
            disabled={formValueHeatScores[idx].toDelete}
            fullWidth
            value={unformattedLapTime === 0 ? '' : formattedLapTime.toString()}
            mask="MM{:}SS{.}MS"
            blocks={{
              MM: {
                mask: '0[0]',
              },
              SS: {
                mask: '0[0]',
              },
              MS: {
                mask: '0[0][0]',
              },
            }}
            definitions={{
              $: /[0-9]/,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingRight: 0,
              },
              '& .MuiInputAdornment-root': {
                padding: '0px',
                marginLeft: 0,
              },
              '&.MuiTextField-root': {
                backgroundColor:
                  processedHeat.heatStatus === HeatStatus.COMPLETED ||
                  (unformattedLapTime && unformattedLapTime > 0)
                    ? '#E9ECEF'
                    : '',
              },
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const newVal = event.target.value;
              const unformattedNewLapTime: number = formatMMSSToSeconds(newVal);
              if (unformattedNewLapTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
              const formattedNewLapTime: string = newVal;
              if (unformattedNewLapTime !== unformattedLapTime) {
                formValueHeatScores[idx][lapNumberKey].lapTime = formattedNewLapTime;
                if (unformattedNewLapTime >= 0) {
                  setFieldValue(
                    'heatScores',
                    getUpdatedNRXRanksInScores(
                      formValueHeatScores,
                      lapsCountArr,
                      roundNo,
                      FIRST_ROUND_NO,
                    ),
                  );
                } else {
                  setFieldValue('heatScores', formValueHeatScores);
                }
              }
            }}
            InputProps={{
              endAdornment:
                roundNo === FIRST_ROUND_NO ? undefined : (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={
                        !!canUseJokerLap[values.heatScores[idx]?.athlete?.id || ''] &&
                        !values.heatScores[idx][lapNumberKey]?.isJoker
                          ? true
                          : processedHeat.heatStatus === HeatStatus.COMPLETED
                          ? true
                          : false
                      }
                      onClick={() => {
                        const scrs = JSON.parse(JSON.stringify(values.heatScores));
                        scrs[idx][lapNumberKey].isJoker = !scrs[idx][lapNumberKey].isJoker;
                        setFieldValue(
                          'heatScores',
                          getUpdatedNRXRanksInScores(scrs, lapsCountArr, roundNo, FIRST_ROUND_NO),
                        );
                      }}
                      disableRipple
                      sx={{
                        padding: '0 !important',
                        paddingRight: '0.375rem !important',
                        fontSize: '1rem',
                      }}
                    >
                      {values.heatScores[idx][lapNumberKey]?.isJoker ? (
                        <JokerCheckedIcon />
                      ) : (
                        <JokerUnCheckedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
            }}
          />
        );
      },
    };
    return column;
  });

  const columns = [
    {
      title: '',
      hidden: isMediumAndDown ? false : true,
      component: (idx: number) => (
        <Tooltip title={'Open details'}>
          <IconButton
            color="inherit"
            size="large"
            onClick={(event) => {
              const newIndex = values.detailExpandedRow === idx ? -1 : idx;
              setFieldValue('detailExpandedRow', newIndex);
              event.stopPropagation();
            }}
          >
            {<ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      width: isMediumAndDown ? '10%' : '0.8fr',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <Autocomplete
          disabled={values.heatScores[idx].toDelete}
          defaultValue={INITIAL_NRX_HEAT_SCORES_STATUS_OPTION}
          disableClearable
          options={NRX_HEAT_SCORES_STATUS_OPTIONS}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          renderOption={(props, option) => (
            <Box
              sx={{ fontSize: '0.8rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              {...props}
            >
              {option.label}
            </Box>
          )}
          value={values.heatScores[idx]?.status || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION}
          onChange={(event: any, newValue: any | null) => {
            const newHeatScores = JSON.parse(JSON.stringify(values.heatScores));
            newHeatScores[idx].status = newValue ? newValue : null;
            setFieldValue('heatScores', newHeatScores);
          }}
          fullWidth
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: 'calc(50% - 0.7rem)',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].status`}
              fullWidth
              error={
                errors.heatScores && touched.heatScores && errors?.heatScores[idx]?.status
                  ? true
                  : false
              }
              helperText={
                errors.heatScores && touched.heatScores
                  ? errors?.heatScores[idx]?.status && 'Status is required'
                  : ''
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0.6rem 0.375rem',
                  paddingRight: '1.25rem !important',
                },
                '& .MuiIconButton-root': {
                  padding: 0,
                  marginRight: '-0.375rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                },
              }}
            />
          )}
        />
      ),
    },
    {
      title: 'Athlete',
      width: isMediumAndDown ? (isSmallAndDown ? '80%' : '30%') : '1fr',
      component: (idx: number) => (
        <Autocomplete
          disabled={values.heatScores[idx].toDelete}
          disableClearable
          options={players}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          renderOption={(props, option) => (
            <Box
              sx={{ fontSize: '0.8rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              {...props}
            >
              {option.label}
            </Box>
          )}
          value={values.heatScores[idx]?.athlete || ''}
          onChange={(_: React.BaseSyntheticEvent, newValue: any | null) => {
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              athlete: newValue ? newValue : initialAthleteOption,
            };
            setFieldValue('heatScores', newHeatScores);
          }}
          fullWidth
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: 'calc(50% - 0.7rem)',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].athlete`}
              fullWidth
              error={
                errors.heatScores && touched.heatScores && errors?.heatScores[idx]?.athlete
                  ? true
                  : false
              }
              helperText={
                errors.heatScores && touched.heatScores
                  ? errors?.heatScores[idx]?.athlete && 'Athlete is required'
                  : ''
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0.6rem 0.375rem',
                  paddingRight: '1.25rem !important',
                  textAlign: 'left',
                },
                '& .MuiOutlinedInput-input': {
                  textAlign: 'left',
                },
                '& .MuiIconButton-root': {
                  padding: 0,
                  marginRight: '-0.375rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                },
              }}
            />
          )}
        />
      ),
    },
    ...LapColumns,
    {
      title: 'Penalties',
      field: 'penaltyTime',
      width: isMediumAndDown ? '1fr' : '0.8fr',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const formattedPenaltyTime: string =
          values.heatScores[idx].penaltyTime || DEFAULT_START_OF_LAP_TIME;
        const unformattedPenaltyTime: number = formatMMSSToSeconds(formattedPenaltyTime);
        const formValueHeatScores: any[] = JSON.parse(JSON.stringify(values.heatScores));
        return (
          <LapTimeField
            id={`heatScores[${idx}].penaltyTime`}
            name={`heatScores[${idx}].penaltyTime`}
            disabled={values.heatScores[idx].toDelete}
            fullWidth
            value={unformattedPenaltyTime === 0 ? '' : formattedPenaltyTime}
            mask="MM{:}SS{.}MS"
            blocks={{
              MM: {
                mask: '0[0]',
              },
              SS: {
                mask: '0[0]',
              },
              MS: {
                mask: '0[0][0]',
              },
            }}
            definitions={{
              $: /[0-9]/,
            }}
            sx={
              processedHeat.heatStatus === HeatStatus.COMPLETED ||
              (unformattedPenaltyTime && unformattedPenaltyTime > 0)
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const newVal = event.target.value;
              const unformattedNewPenaltyTime: number = formatMMSSToSeconds(newVal);
              if (unformattedNewPenaltyTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
              const formattedNewLapTime: string = newVal;
              if (unformattedNewPenaltyTime !== unformattedPenaltyTime) {
                formValueHeatScores[idx].penaltyTime = formattedNewLapTime;
                if (unformattedNewPenaltyTime >= 0) {
                  setFieldValue(
                    'heatScores',
                    getUpdatedNRXRanksInScores(
                      formValueHeatScores,
                      lapsCountArr,
                      roundNo,
                      FIRST_ROUND_NO,
                    ),
                  );
                } else {
                  setFieldValue('heatScores', formValueHeatScores);
                }
              }
            }}
          />
        );
      },
    },
    {
      title: roundNo === FIRST_ROUND_NO ? 'Best Lap Time' : 'Total Time',
      field: 'totalTime',
      width: isMediumAndDown ? '1fr' : '0.8fr',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const formattedTotalTime = values.heatScores[idx].totalTime || DEFAULT_START_OF_LAP_TIME;
        const unformattedTotalTime: number = formatMMSSToSeconds(`${formattedTotalTime}`);
        return (
          <TextField
            disabled={values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].totalTime`}
            value={unformattedTotalTime === 0 ? '' : formattedTotalTime}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={
              processedHeat.heatStatus === HeatStatus.COMPLETED || unformattedTotalTime > 0
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
          />
        );
      },
    },
    {
      title: 'Place',
      field: 'heatPosition',
      width: isMediumAndDown ? '1fr' : '0.1fr',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <TextField
          disabled={values.heatScores[idx].toDelete}
          name={`heatScores[${idx}].heatPosition`}
          value={
            values.heatScores[idx].heatPosition === 0 ? '' : values.heatScores[idx].heatPosition
          }
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          sx={
            processedHeat.heatStatus === HeatStatus.COMPLETED ||
            values.heatScores[idx].heatPosition > 0
              ? { input: { backgroundColor: '#E9ECEF' } }
              : undefined
          }
        />
      ),
    },
  ];

  const filteredColumns = columns.filter((column) => !column?.hidden);
  const filteredHiddenColumns = columns.filter((column) => column?.hidden === true);
  const columnWidths = filteredColumns.map((column) => column?.width || '1fr');
  const generatedGridTemplateColumns = columnWidths.join(' ');

  return (
    <>
      <Container>
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '0.875rem', marginBottom: '0.375rem' }}
        >
          <HeatTitle>{`${processedHeat.heatName} ${processedHeat.heatNo}`}</HeatTitle>
          <Box>{processHeatStatusBadges(processedHeat.heatStatus, processedHeat.heatNo)}</Box>
        </Box>
        <StartEndTimes startDate={processedHeat?.startDate} endDate={processedHeat?.endDate} />

        <Box sx={{ marginTop: '1rem' }}>
          <form>
            <ScoresWrapper>
              {values.heatScores.length > 0 && (
                <Box sx={{ display: 'block' }}>
                  <Box
                    className="custom-table"
                    sx={{
                      gridTemplateColumns: generatedGridTemplateColumns,
                    }}
                  >
                    <Box
                      data-role="thead"
                      sx={{
                        display: 'contents',
                      }}
                    >
                      <Box
                        data-role="tr"
                        sx={{
                          display: 'contents',
                        }}
                      >
                        {filteredColumns.map((column, idx) => (
                          <Box
                            key={`title-${idx}`}
                            sx={{ display: 'flex', alignItems: 'flex-end' }}
                          >
                            <Typography
                              sx={{
                                color: 'info.main',
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                lineHeight: '1rem',
                              }}
                              variant="caption"
                            >
                              {column?.title || ''}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <Box
                      data-role="tbody"
                      sx={{
                        display: 'contents',
                      }}
                    >
                      <Box sx={{ display: 'contents' }}>
                        {values.heatScores.map((_: any, idx: number) => (
                          <Box sx={{ display: 'contents' }} key={`tbody-${idx}`}>
                            <Box
                              data-role="tr"
                              sx={{
                                display: 'contents',
                              }}
                            >
                              {filteredColumns.map((column, colIdx) => (
                                <Box
                                  className="display-flex justify-center align-center"
                                  key={`title-${colIdx}`}
                                >
                                  {column?.component(idx) || ''}
                                </Box>
                              ))}
                            </Box>
                            {values.detailExpandedRow === idx && (
                              <Collapse
                                in={values.detailExpandedRow === idx ? true : false}
                                sx={{ gridColumn: `1 / span ${filteredColumns.length}` }}
                              >
                                <Box className="collapse">
                                  {filteredHiddenColumns.map((column, index) => {
                                    return (
                                      <Box sx={{ display: 'contents' }} key={`cell-${index}`}>
                                        <Box>
                                          <Stack
                                            sx={{ height: '100%' }}
                                            direction="column"
                                            justifyContent="center"
                                          >
                                            <Typography>{column.title}</Typography>
                                          </Stack>
                                        </Box>
                                        <Box>
                                          <Box key={`title-${index}`}>
                                            {column?.component(idx) || ''}
                                          </Box>
                                        </Box>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </Collapse>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                alignContent="center"
                sx={{ marginTop: '0.5rem' }}
              >
                {values.heatScores.length > 0 && (
                  <Grid item>
                    <Button
                      size="large"
                      color="primary"
                      variant="contained"
                      disableElevation
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: '400',
                        padding: '0.375rem 2.125rem',
                      }}
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      Save
                    </Button>
                  </Grid>
                )}
              </Grid>
            </ScoresWrapper>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default NRXHeatScores;
