// import SPORT_TYPES from '@/constants/sportTypes';

export const allowedTableHeaders = [
  { title: 'Event', field: 'event' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Tour Year', field: 'year' },
  { title: 'Athlete 1', field: 'athlete1' },
  { title: 'Athlete 2', field: 'athlete2' },
  { title: 'Athlete Winner', field: 'athleteWinner' },
  { title: 'Athlete 1 Position', field: 'athlete1Position' },
  { title: 'Athlete 2 Position', field: 'athlete2Position' },
  { title: 'Athlete 1 Odds', field: 'athlete1AmericanOdds' },
  { title: 'Athlete 2 Odds', field: 'athlete2AmericanOdds' },
  { title: 'Athlete 1 Probability', field: 'athlete1Probability' },
  { title: 'Athlete 2 Probability', field: 'athlete2Probability' },
  { title: 'Voided', field: 'voided' },
  { title: 'Draw', field: 'draw' },
];

// export const allowedTableHeaders = {
//   [SPORT_TYPES.SPR]: allowedSPRHeaders,
// };

interface CommonTraderEventOddRow {
  event: string;
  tour_name: string;
  gender: string;
  year: number;
  athlete1: string;
  athlete2: string;
  athlete1_american_odds: number;
  athlete2_american_odds: number;

  athlete1_position?: number;
  athlete2_position?: number;
  athlete_winner?: string;
  voided?: string;
  draw?: string;
}

// interface SPRTraderEventOddRow extends CommonTraderEventOddRow {}

export type TraderEventOddsRow = CommonTraderEventOddRow;

const commonRequiredKeys = [
  'event',
  'tour_name',
  'gender',
  'year',
  'athlete1',
  'athlete2',
  'athlete1_american_odds',
  'athlete2_american_odds',
];

// export const SPRRequiredKeys = [...commonRequiredKeys];

export const requiredPayloadKeys = [...commonRequiredKeys];
// {
//   [SPORT_TYPES.SPR]: SPRRequiredKeys,
// };
