import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { HIDDEN_SPORTS } from '@/constants/sportTypes';

const getSportsFutures = async () => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/sports/futures`,
  });

  // return data;
  // only return sports that are not hidden
  const filteredSports = data.filter((row: any) => !HIDDEN_SPORTS.includes(row.sportType));

  return filteredSports;
};

export default function useFetchSportsFutures() {
  return useQuery([keys.futures.sports.fetchSportsFutures], () => getSportsFutures(), {
    staleTime: Infinity,
  });
}
