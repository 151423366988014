import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { EventScoresSkeleton } from '@/components/PageSkeletons';
import Head from '@/components/Head';
import EventDetails from '@/components/EventDetails';
import EventTabs from '@/components/EventTabs';

import { Odds } from '@/features/odds';

import {
  SLSEventScores,
  WSLEventScores,
  NRXEventScores,
  HLRSEventScores,
  BYBEventScores,
  BKFCEventScores,
  POWERSLAPEventScores,
  NHRAEventScores,
  USACEventScores,
  FDRIFTEventScores,
  // SPRMTCRSEventScores,
} from '@/features/scores';

import {
  EventSeeds,
  SLSTableEventSeedsColumns,
  WSLTableEventSeedsColumns,
  NRXTableEventSeedsColumns,
  FightEventSeeds,
  TableFightEventSeedsColumns,
} from '@/features/seeds';

import { FightParticipantDatabase, TableFightParticipantColumns } from '@/features/database';

import { FightResults } from '@/features/results';

import routes from '@/constants/routes';
import SPORT_TYPES from '@/constants/sportTypes';

import useSportEvent from './useSportEvent';
import { EventStatusValues, EventStatusNames } from '@/constants/events';
import { EVENT_TAB_QUERY_PARAMS } from '@/constants/misc';

const EVENT_SCORES = {
  [SPORT_TYPES.SLS]: SLSEventScores,
  [SPORT_TYPES.WSL]: WSLEventScores,
  [SPORT_TYPES.NRX]: NRXEventScores,
  [SPORT_TYPES.HLRS]: HLRSEventScores,
  [SPORT_TYPES.BYB]: BYBEventScores,
  [SPORT_TYPES.BKFC]: BKFCEventScores,
  [SPORT_TYPES.POWERSLAP]: POWERSLAPEventScores,
  [SPORT_TYPES.NHRA]: NHRAEventScores,
  [SPORT_TYPES.USAC]: USACEventScores,
  [SPORT_TYPES.FDRIFT]: FDRIFTEventScores,
  // [SPORT_TYPES.SPRMTCRS]: SPRMTCRSEventScores,
};

const EVENT_SEED_COLUMNS = {
  [SPORT_TYPES.SLS]: SLSTableEventSeedsColumns,
  [SPORT_TYPES.WSL]: WSLTableEventSeedsColumns,
  [SPORT_TYPES.NRX]: NRXTableEventSeedsColumns,
  [SPORT_TYPES.BYB]: TableFightEventSeedsColumns,
  [SPORT_TYPES.POWERSLAP]: TableFightEventSeedsColumns,
};

const SportEvent = () => {
  const { sportType = '' } = useParams();
  const { event, isLoading, currentTabValue, setNextTabValue } = useSportEvent();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const eventTab = queryParams.get(EVENT_TAB_QUERY_PARAMS.eventTab);

  // TODO: Add Power slap to sports type array whose default tab is scores tab
  const DEFAULT_TAB = React.useMemo(
    () =>
      eventTab
        ? eventTab
        : event?.eventStatus === EventStatusValues[EventStatusNames.LIVE] &&
          [
            SPORT_TYPES.WSL,
            SPORT_TYPES.SLS,
            SPORT_TYPES.NRX,
            SPORT_TYPES.BKFC,
            SPORT_TYPES.BYB,
            SPORT_TYPES.POWERSLAP,
            SPORT_TYPES.NHRA,
            SPORT_TYPES.USAC,
            // SPORT_TYPES.SPRMTCRS,
          ].includes(sportType)
        ? '2'
        : '3',
    [eventTab, sportType, event],
  );

  React.useEffect(() => {
    if (!eventTab && DEFAULT_TAB) {
      const redirectParams = new URLSearchParams();
      redirectParams.set(EVENT_TAB_QUERY_PARAMS.eventTab, DEFAULT_TAB);
      navigate(
        {
          pathname: location.pathname,
          search: redirectParams.toString(),
        },
        { replace: true },
      );
    }
  }, [eventTab, DEFAULT_TAB]);

  const Tabs = React.useMemo(() => {
    const EventScores = EVENT_SCORES[sportType];
    const EventSeedColumns = EVENT_SEED_COLUMNS[sportType];

    return (
      <EventTabs
        sportType={sportType}
        defaultTabValue={DEFAULT_TAB}
        currentTabValue={eventTab as string}
        setNextTabValue={setNextTabValue}
        DatabaseElement={
          [SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP].includes(sportType)
            ? () => (
                <FightParticipantDatabase
                  sportType={sportType}
                  columns={TableFightParticipantColumns}
                />
              )
            : undefined
        }
        SeedsElement={
          [SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP].includes(sportType)
            ? () => <FightEventSeeds sportType={sportType} columns={EventSeedColumns} />
            : EventSeedColumns &&
              (() => <EventSeeds sportType={sportType} columns={EventSeedColumns} />)
        }
        ScoresElement={EventScores && (() => <EventScores />)}
        OddsElement={() => <Odds sport={sportType} event={event} />}
        ReportsElement={
          [SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP].includes(sportType)
            ? () => <FightResults sportType={sportType} />
            : undefined
        }
      />
    );
  }, [currentTabValue, event, DEFAULT_TAB, eventTab]);

  if (isLoading)
    return (
      <Box>
        <Head title={event?.name || ''} canonicalUrl={routes.sportType.event.eventId} />
        <EventScoresSkeleton />{' '}
      </Box>
    );

  return (
    <Box>
      <Head title={event?.name || ''} canonicalUrl={routes.sportType.event.eventId} />
      {event && <EventDetails event={event} sportType={sportType} />}
      {Tabs}
    </Box>
  );
};

export default SportEvent;
