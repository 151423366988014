import React from 'react';
import v from 'voca';

import { Box, styled, Typography, IconButton, Paper } from '@mui/material';

import { parseISO } from 'date-fns';

import DateRangeIcon from '@mui/icons-material/DateRange';

import { processJaiAlaiEventStatusBadges } from '@/helpers/jaiAlai';
import { displayInPT as format } from '@/helpers/timeConverters';

import { CounterButton } from '../Markets/components';
import {
  externalMarketOddsPayload,
  findMode,
  getFirstUnlockedOrLockedSubMkt,
  marketOddsPayload,
} from '../Markets/marketHelper';

import { EventStatusKeys } from '@/constants/events';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { JAI_ALAI_GAME_QUERY_PARAMS } from '@/constants/misc';
import { INITIAL_JAI_ALAI_VALUE } from '../..';
import SPORT_TYPES from '@/constants/sportTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import DebugLabelDisplay from '../Markets/DebugLabelDisplay';
import { JaiAlaiStatusNames, JaiAlaiStatusValues } from '@/constants/jaialai';
import { BORDER_COLOR, TEXT_COLOR } from '../Markets/constants';

const KeyboardArrowRight = styled(KeyboardArrowRightIcon)({
  border: '2px solid #EEF0F2',
  borderRadius: '50%',
  fontSize: 40,
  padding: '0.4rem',
});
const KeyboardArrowLeft = styled(KeyboardArrowLeftIcon)({
  border: '2px solid #EEF0F2',
  borderRadius: '50%',
  fontSize: 40,
  padding: '0.4rem',
});

const MatchCardHeading = styled(Typography)<{ component?: string }>({
  fontWeight: 500,
  fontSize: '1.5rem',
  lineHeight: '120%',
});

const CellText = styled(Typography)({
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
});

const GridCell = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.8rem',
  boxShadow: `0 0 0 1px ${BORDER_COLOR.ACTIVE}`,
});

const NavLabel = styled(Typography)({
  weight: 500,
  size: '0.875rem',
  lineHeight: '1.25rem',
  color: '#212529',
});

const NavText = styled(Typography)({
  size: '0.8125rem',
  lineHeight: '1.25rem',
  color: '#A1A1AA',
});

const DateLabel = styled(Typography)({
  color: '#212529',
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '150%',
});

export default function MatchCardWithSlider({
  selectedMatch,
  nextMatch,
  prevMatch,
  formik,
  externalUpdateOdds,
  gameDateId,
}: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);

  const { homeTeam, awayTeam } = React.useMemo(() => {
    if (selectedMatch) {
      const { teams = [] } = selectedMatch;
      const homeTeam = teams.find((team: any) => team.isHomeTeam);
      const awayTeam = teams.find((team: any) => !team.isHomeTeam);

      return { homeTeam, awayTeam };
    }
    return {
      homeTeam: undefined,
      awayTeam: undefined,
    };
  }, [selectedMatch]);

  const { values, setFieldValue } = formik;

  const { homeTeam: homeTeamDtls, awayTeam: awayTeamDtls } = React.useMemo(
    () => getFirstUnlockedOrLockedSubMkt(values?.odds),
    [values?.odds],
  );

  const startDate: string = React.useMemo(() => {
    return selectedMatch?.startDate
      ? `${format(parseISO(selectedMatch?.startDate || ''), 'MMM dd, yyyy')} at ${format(
          parseISO(selectedMatch?.startDate || ''),
          'HH:mm',
        )}`
      : 'N.A';
  }, [selectedMatch?.startDate]);

  const handleExternalUpdateOdds = ({
    playerLean,
    isHomeTeam = true,
  }: {
    playerLean: number;
    isHomeTeam: boolean;
  }) => {
    const odds = values?.odds || [];
    const formattedData = externalMarketOddsPayload({ odds });

    // Filter the data into home and away teams
    const homeTeamData = formattedData.filter((data: any) => data.isHomeTeam);
    const awayTeamData = formattedData.filter((data: any) => !data.isHomeTeam);

    // Find the mode for playerLean in home and away teams
    const homeTeamLean = findMode(homeTeamData, 'playerLean');
    const awayTeamLean = findMode(awayTeamData, 'playerLean');

    const finalPayload = formattedData.map((data: any) => {
      return {
        ...data,
        ...(!data?.isSubMarketLocked
          ? {
              playerLean:
                data.isHomeTeam === isHomeTeam
                  ? playerLean
                  : data.isHomeTeam
                  ? +homeTeamLean
                  : +awayTeamLean,
            }
          : {}),
      };
    });

    externalUpdateOdds({ odds: finalPayload, originalOdds: odds });
  };

  const matchName = React.useMemo(
    () => v.kebabCase(selectedMatch?.matchName || '') || '',
    [selectedMatch],
  );

  const handleChangeMatch = (matchId: string) => {
    if (selectedMatch?.id !== matchId) {
      const redirectParams = new URLSearchParams();
      redirectParams.set(JAI_ALAI_GAME_QUERY_PARAMS.matchSelected, matchId || '');
      setFieldValue('odds', INITIAL_JAI_ALAI_VALUE.odds, false);
      setFieldValue('clientUpdatedAtDate', INITIAL_JAI_ALAI_VALUE.clientUpdatedAtDate, false);
      setFieldValue('traderUpdatedAtDate', INITIAL_JAI_ALAI_VALUE.traderUpdatedAtDate, false);
      queryClient.invalidateQueries([keys.games.fetchGame, SPORT_TYPES.JAIALAI, gameDateId]);
      queryClient.invalidateQueries([keys.odds.fetchGameOdds, SPORT_TYPES.JAIALAI, matchId]);
      navigate(
        {
          pathname: location.pathname,
          search: redirectParams.toString(),
        },
        { replace: true },
      );
    }
  };

  const isDisallowedMatch = React.useMemo(
    () =>
      [
        JaiAlaiStatusValues[JaiAlaiStatusNames.CANCELED],
        JaiAlaiStatusValues[JaiAlaiStatusNames.FORFEIT],
      ].includes(+selectedMatch?.eventStatus),
    [selectedMatch],
  );

  const isEverySubMarketLocked = React.useMemo(
    () => marketOddsPayload({ odds: values?.odds })?.every((odd) => odd?.isSubMarketLocked),
    [values?.odds],
  );

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateAreas: `"prevNav matchCard nextNav"`,
        gridTemplateColumns: 'minmax(0, 15fr) minmax(0, 70fr) minmax(0, 15fr)',
        mt: 4,
      }}
      id={`${matchName}`}
    >
      <Box
        sx={{
          gridArea: 'prevNav',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
          pr: 4,
        }}
      >
        {prevMatch && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              onClick={() => handleChangeMatch(prevMatch?.id)}
              disableRipple
              id={'prev-match'}
            >
              <KeyboardArrowLeft sx={{ backgroundColor: TEXT_COLOR.WHITE }} />
            </IconButton>
            <NavLabel id={`prev-${v.kebabCase(prevMatch?.matchName || '') || ''}-matchName`}>
              {prevMatch?.matchName || ''}
            </NavLabel>
            <NavText
              id={`prev-${
                v.kebabCase(EventStatusKeys[prevMatch?.eventStatus] || '') || ''
              }-matchStatus`}
            >
              {v.titleCase(EventStatusKeys[prevMatch?.eventStatus] || '')}
            </NavText>
          </Box>
        )}
      </Box>
      <Box sx={{ gridArea: 'matchCard', px: 4, py: 4 }} component={Paper}>
        <Box
          sx={{
            mb: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <MatchCardHeading
            component="h4"
            sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}
            id={`${matchName}-matchName-and-matchStatus`}
          >
            {`${selectedMatch?.matchName || ''}`}
            &nbsp;&nbsp;
            {processJaiAlaiEventStatusBadges(selectedMatch?.eventStatus)}
          </MatchCardHeading>

          {!isDisallowedMatch && (
            <Box
              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '4px' }}
            >
              <DateRangeIcon />
              <DateLabel id={`${matchName}-startDate`}>{startDate}</DateLabel>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"teamLabel ${selectedMatch?.rounds
              ?.map((_: any, mId: number) => {
                return `set${mId + 1}Label`;
              })
              .flat()
              .join(' ')} finalScrLabel leanLabel"
                "homeTeamName ${selectedMatch?.rounds
                  ?.map((_: any, mId: number) => {
                    return `homeTeamSet${mId + 1}Scr`;
                  })
                  .flat()
                  .join(' ')} homeTeamFinalScr homeTeamLean"
                  "awayTeamName ${selectedMatch?.rounds
                    ?.map((_: any, mId: number) => {
                      return `awayTeamSet${mId + 1}Scr`;
                    })
                    .flat()
                    .join(' ')} awayTeamFinalScr awayTeamLean"
                `,
            gridTemplateColumns: `minmax(0,1fr) repeat(${selectedMatch?.rounds?.length}, minmax(0, 8%)) minmax(0, 14%) minmax(8ch, 16ch)`,
            gap: '1px',
          }}
        >
          <GridCell
            sx={{
              gridArea: 'teamLabel',
              justifyContent: 'flex-start',
              backgroundColor: '#F8F9FA',
            }}
          >
            <CellText sx={{ fontWeight: 500 }} id={`${matchName}-player-label`}>
              Player
            </CellText>
          </GridCell>

          {React.Children.toArray(
            selectedMatch?.rounds?.map((_: any, rId: number) => (
              <GridCell
                sx={{
                  gridArea: `set${rId + 1}Label`,
                  backgroundColor: '#F8F9FA',
                }}
              >
                <CellText sx={{ fontWeight: 500 }} id={`${matchName}-set-${rId + 1}-label`}>{`Set ${
                  rId + 1
                }`}</CellText>
              </GridCell>
            )),
          )}

          <GridCell
            sx={{
              gridArea: 'finalScrLabel',
              backgroundColor: '#F8F9FA',
            }}
          >
            <CellText sx={{ fontWeight: 500 }} id={`${matchName}-score-label`}>
              Score
            </CellText>
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'leanLabel',
              backgroundColor: '#F8F9FA',
            }}
          >
            <CellText sx={{ fontWeight: 500 }} id={`${matchName}-lean-label`}>
              Lean
            </CellText>
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'homeTeamName',
              justifyContent: 'flex-start',
            }}
          >
            <CellText id={`${matchName}-homeTeamName`}>{selectedMatch?.homeTeam || ''}</CellText>
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'awayTeamName',
              justifyContent: 'flex-start',
            }}
          >
            <CellText id={`${matchName}-awayTeamName`}>{selectedMatch?.awayTeam || ''}</CellText>
          </GridCell>

          {React.Children.toArray(
            selectedMatch?.rounds?.map((rnd: any, rId: number) => {
              const homeTeamScr = rnd?.scores?.find((scr: any) => scr?.teamId === homeTeam?.id);
              const awayTeamScr = rnd?.scores?.find((scr: any) => scr?.teamId === awayTeam?.id);
              return (
                <>
                  <GridCell
                    sx={{
                      gridArea: `homeTeamSet${rId + 1}Scr`,
                    }}
                  >
                    <CellText id={`${matchName}-homeTeam-set-${rId + 1}-score`}>
                      {homeTeamScr?.score || '-'}
                    </CellText>
                  </GridCell>
                  <GridCell
                    sx={{
                      gridArea: `awayTeamSet${rId + 1}Scr`,
                    }}
                    id={`${matchName}-awayTeam-set-${rId + 1}-score`}
                  >
                    <CellText>{awayTeamScr?.score || '-'}</CellText>
                  </GridCell>
                </>
              );
            }),
          )}

          <GridCell
            sx={{
              gridArea: 'homeTeamFinalScr',
            }}
            id={`${matchName}-homeTeam-finalScore`}
          >
            <CellText>{selectedMatch?.homeTeamMatchScore || '0'}</CellText>
          </GridCell>

          <GridCell
            sx={{
              gridArea: 'awayTeamFinalScr',
            }}
            id={`${matchName}-awayTeam-finalScore`}
          >
            <CellText>{selectedMatch?.awayTeamMatchScore || '0'}</CellText>
          </GridCell>

          <GridCell sx={{ gridArea: 'homeTeamLean' }}>
            <DebugLabelDisplay row={homeTeamDtls} />
            <CounterButton
              incrId={`${matchName}-homeTeamLeanIncrBtn`}
              decId={`${matchName}-homeTeamLeanDecBtn`}
              valId={`${matchName}-homeTeamLeanVal`}
              value={homeTeamDtls?.playerLean || 0}
              changeHandler={(val: number) =>
                handleExternalUpdateOdds({ playerLean: val, isHomeTeam: true })
              }
              disabled={isDisabledMatch || isEverySubMarketLocked}
            />
          </GridCell>
          <GridCell sx={{ gridArea: 'awayTeamLean' }}>
            <DebugLabelDisplay row={awayTeamDtls} />
            <CounterButton
              incrId={`${matchName}-awayTeamLeanIncrBtn`}
              decId={`${matchName}-awayTeamLeanDecBtn`}
              valId={`${matchName}-awayTeamLeanVal`}
              value={awayTeamDtls?.playerLean || 0}
              changeHandler={(val: number) =>
                handleExternalUpdateOdds({ playerLean: val, isHomeTeam: false })
              }
              disabled={isDisabledMatch || isEverySubMarketLocked}
            />
          </GridCell>
        </Box>
      </Box>
      <Box
        sx={{
          gridArea: 'nextNav',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          pl: 4,
        }}
      >
        {nextMatch && (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton
              onClick={() => handleChangeMatch(nextMatch?.id)}
              disableRipple
              id={'next-match'}
            >
              <KeyboardArrowRight sx={{ backgroundColor: TEXT_COLOR.WHITE }} />
            </IconButton>
            <NavLabel id={`next-${v.kebabCase(nextMatch?.matchName || '') || ''}-matchName`}>
              {nextMatch?.matchName || ''}
            </NavLabel>
            <NavText
              id={`next-${
                v.kebabCase(EventStatusKeys[nextMatch?.eventStatus] || '') || ''
              }-matchStatus`}
            >
              {v.titleCase(EventStatusKeys[nextMatch?.eventStatus] || '')}
            </NavText>
          </Box>
        )}
      </Box>
    </Box>
  );
}
