import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import v from 'voca';

import { sportsType } from '@/atoms/sportsType';

import Dropzone from '@/components/Dropzone';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';

import useAddTraderHeadToHeadOdds from '@/hooks/traders/odds/useAddTraderHeadToHeadOdds';

import routes from '@/constants/routes';
import { sportOptions } from '@/constants/sportTypes';
import {
  TraderEventOddsRow,
  allowedTableHeaders,
  requiredPayloadKeys,
} from '@/pages/trader/odds/headToHead/upload/constants';

import { TraderHeadToHeadOdd } from '@/types/traderOddUpload';

import { requiredPropertyChecker, invalidRowErrorMsgHelper } from '@/helpers/uploadHelpers';
import * as oddsHelpers from '@/helpers/odds';
import ODDS_TYPES from '@/constants/oddsTypes';

export default function TraderHeadToHeadOddsUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const { mutate: addTraderHeadToHeadOdds } = useAddTraderHeadToHeadOdds();
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<TraderHeadToHeadOdd[]>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        addTraderHeadToHeadOdds(
          {
            sport: selectedSport,
            requestPayload: {
              items: modSheet.map((data: any) => {
                delete data.athlete1AmericanOdds;
                delete data.athlete2AmericanOdds;
                return data;
              }),
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Head To Head Odds Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders || [];
      setAllowedHeaders(headers);
    }
  }, [selectedSport]);

  const handleChange = () => setSheetData([]);

  const tableRowChecker = (rows: TraderEventOddsRow[]) => {
    let message = '';
    const requiredKeys = requiredPayloadKeys;
    const requiredKeysSet = new Set(requiredKeys);

    rows.find((row, index) => {
      const isValidRow = requiredPropertyChecker(requiredKeys, row);
      if (!isValidRow) {
        const missingColumns = invalidRowErrorMsgHelper(requiredKeysSet, new Set(Object.keys(row)));
        message = `Error on row { ${index + 2} }, Missing { ${missingColumns} }`;
      }
      // To loop through all rows
      return !isValidRow;
    });

    return message;
  };
  const YES = 'yes';
  const tableDataModifierFn = (data: TraderEventOddsRow) => {
    const {
      event,
      tour_name,
      gender,
      year,
      athlete1,
      athlete2,
      athlete1_american_odds,
      athlete2_american_odds,

      athlete1_position,
      athlete2_position,
      athlete_winner,
      voided,
      draw,
    } = data;
    const athlete1Odds = oddsHelpers.getDecimalOddsFromOtherTypes(
      ODDS_TYPES.AMERICAN,
      athlete1_american_odds,
    );
    const athlete2Odds = oddsHelpers.getDecimalOddsFromOtherTypes(
      ODDS_TYPES.AMERICAN,
      athlete2_american_odds,
    );
    const athlete1Probability = oddsHelpers.getProbabilityFromDecimalOdds(athlete1Odds);
    const athlete2Probability = oddsHelpers.getProbabilityFromDecimalOdds(athlete2Odds);
    return {
      event,
      tourName: tour_name,
      gender,
      year,
      athlete1,
      athlete2,
      athlete1Odds,
      athlete2Odds,
      athlete1Probability,
      athlete2Probability,

      athlete1AmericanOdds: athlete1_american_odds,
      athlete2AmericanOdds: athlete2_american_odds,
      athleteWinner: athlete_winner,
      athlete1Position: athlete1_position,
      athlete2Position: athlete2_position,
      voided: voided && voided.toLowerCase() === YES ? true : false,
      draw: draw && draw.toLowerCase() === YES ? true : false,
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.traders.odds.event.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Head To Head Odds
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Head to Head Odds"
        sheetName="Head to Head Odds"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />

      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button variant="contained" disabled={disableBtn} onClick={handleToggleConfirmDialog}>
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} HEAD TO HEAD OUTCOME ODDS?`}
          body={'This will upload the head to head outcome odds.'}
        />
      )}
    </Box>
  );
}
