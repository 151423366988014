import React from 'react';
import { useRecoilState } from 'recoil';
import { Box, styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SelectChangeEvent } from '@mui/material/Select';

import { oddsType } from '@/atoms/oddsType';
import ODDS_TYPES from '@/constants/oddsTypes';

const ModSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiSelect-select': {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: 'info.dark',
  },
  '& .MuiMenuItem-root': {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: 'info.dark',
  },
});

const ModMenuItem = styled(MenuItem)({
  fontSize: '0.875rem',
  fontWeight: '400',
  color: 'info.dark',
  '& .Mui-selected': {
    backgroundColor: 'gray',
  },
});

const OddTypeSwitcher = () => {
  const [oddType, setOddType] = useRecoilState(oddsType);
  const handleOddTypeChange = (event: SelectChangeEvent<unknown>) => {
    setOddType(event.target.value as string);
  };
  return (
    <Box>
      <FormControl sx={{ m: 1 }} size="small">
        <ModSelect
          labelId="odd-type-selection"
          id="odd-type-selection"
          value={oddType}
          onChange={handleOddTypeChange}
        >
          <ModMenuItem value={ODDS_TYPES.DECIMAL}>Decimal</ModMenuItem>
          <ModMenuItem value={ODDS_TYPES.FRACTIONAL}>Fractional</ModMenuItem>
          <ModMenuItem value={ODDS_TYPES.AMERICAN}>American</ModMenuItem>
        </ModSelect>
      </FormControl>
    </Box>
  );
};

export default OddTypeSwitcher;
