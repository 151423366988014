import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getRoundResults = async (sportType: string): Promise<{ id: string; judge: string }[]> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/traders/${sportType}/round-results`,
  });

  return data;
};

export default function useFetchRoundResults(sportType: string) {
  return useQuery([keys.results.fetchRoundResults, sportType], () => getRoundResults(sportType), {
    staleTime: Infinity,
  });
}
