import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getAthletes = async (sportType: string): Promise<{ id: string; judge: string }[]> => {
  const query = new URLSearchParams();
  query.append('sportType', sportType);
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/traders/community?${query.toString()}`,
  });

  return data;
};

export default function useFetchAthletes(sportType: string, fromComponent: string) {
  return useQuery(
    [keys.fightSportMisc.fetchAthletes, sportType, fromComponent],
    () => getAthletes(sportType),
    {
      staleTime: Infinity,
    },
  );
}
