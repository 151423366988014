import * as React from 'react';

import { Box, Button } from '@mui/material';

import { getKeysWithObjectValues } from '@/helpers/misc';

import { teamTypeToKey, TEXT_COLOR } from '../constants';

import MarketToggleOption from './MarketToggleOption';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';

const MarketTypesToggle = ({
  formik,
  disabledMarkets,
}: {
  formik: any;
  disabledMarkets: { [key: string]: boolean };
  setDisabledMarkets: any;
}) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const { values, setFieldValue } = formik;
  const toggleIsMarketActive = ({
    value,
    oddId,
    isMarketActive,
  }: {
    value: any;
    oddId: number;
    isMarketActive: boolean;
  }) => {
    value?.bets?.forEach((_: any, id: number) => {
      getKeysWithObjectValues(value.bets[id])?.forEach((subMarketVal) => {
        const currentSubMarket = value?.bets[id]?.[subMarketVal];
        if (currentSubMarket) {
          [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam]?.forEach((team) => {
            const currentTeam = currentSubMarket[team];
            if (currentTeam) {
              setFieldValue(
                `odds[${oddId}].bets[${id}].${subMarketVal}.${team}.isMarketActive`,
                isMarketActive,
              );
            }
          });
        }
      });
    });
  };
  const toggleAllMarkets = (isMarketActive: boolean) => {
    values?.odds?.forEach((value: any, oddId: number) => {
      toggleIsMarketActive({ value, oddId, isMarketActive });
    });
  };

  const isSomeMarketDisabled =
    Object.keys(disabledMarkets).some((key) => disabledMarkets[key]) || false;

  return (
    <Box sx={{ display: 'grid', gap: '0.1rem' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '0.5rem', mb: 2 }}>
        <Button
          variant="contained"
          disableElevation
          sx={{
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor: '#15AA65',
            color: TEXT_COLOR.WHITE,
            '&:hover': {
              color: TEXT_COLOR.WHITE,
              backgroundColor: '#15AA65',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            toggleAllMarkets(true);
          }}
          disabled={isDisabledMatch || isSomeMarketDisabled}
          id="activate-all-markets-btn"
        >
          ACTIVATE ALL
        </Button>
        <Button
          variant="contained"
          disableElevation
          sx={{
            paddingTop: '4px',
            paddingBottom: '4px',
            backgroundColor: '#DC3545',
            color: TEXT_COLOR.WHITE,
            '&:hover': {
              color: TEXT_COLOR.WHITE,
              backgroundColor: '#DC3545',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            toggleAllMarkets(false);
          }}
          disabled={isDisabledMatch || isSomeMarketDisabled}
          id="suspend-all-markets-btn"
        >
          SUSPEND ALL
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
        {React.Children.toArray(
          values.odds.map((value: any, oddId: number) => {
            return (
              <MarketToggleOption
                value={value}
                oddId={oddId}
                toggleIsMarketActive={toggleIsMarketActive}
                disabledMarkets={disabledMarkets}
              />
            );
          }),
        )}
      </Box>
    </Box>
  );
};

export default MarketTypesToggle;
