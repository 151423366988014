import React from 'react';
import { useRecoilState } from 'recoil';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { sportsType } from '@/atoms/sportsType';
import { sportOptions } from '@/constants/sportTypes';

const FilterSport = ({ handleChange }: { handleChange: () => void }) => {
  const [sportType, setSportsType] = useRecoilState(sportsType);

  const handleSportsChange = (event: SelectChangeEvent) => {
    handleChange();
    setSportsType(event.target.value);
  };

  return (
    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, marginRight: 0 }} size="small">
      <InputLabel id="sport" sx={{ left: 'unset', right: '0' }}>
        Sport Options
      </InputLabel>
      <Select
        disableUnderline
        labelId="sport"
        id="sport"
        value={sportType}
        label="Sport"
        onChange={handleSportsChange}
      >
        {sportOptions.map((option, idx) => (
          <MenuItem value={option.value} key={`sport-option-${idx}`}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterSport;
