import { QueryClient } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

export const invalidateCachedSports = (queryClient: QueryClient) => {
  queryClient.invalidateQueries([keys.sports.fetchSports]);
};

export const invalidateCachedTours = (
  queryClient: QueryClient,
  sportType: string,
  fromComponent: string,
) => {
  queryClient.invalidateQueries([keys.events.fetchTours, sportType, fromComponent]);
};

export const invalidateCachedEventLocationGroup = (
  queryClient: QueryClient,
  sportType: string,
  fromComponent: string,
) => {
  queryClient.invalidateQueries([keys.events.fetchEventLocationGroup, sportType, fromComponent]);
};

export const invalidateCachedEvent = (
  queryClient: QueryClient,
  sportType: string,
  eventId: string,
  fromComponent: string,
) => {
  queryClient.invalidateQueries([keys.events.fetchEvent, sportType, eventId, fromComponent]);
};

export const invalidateCachedHeat = (
  queryClient: QueryClient,
  sportType: string,
  heatId: string,
) => {
  queryClient.invalidateQueries([keys.traders.fetchHeat, sportType, heatId]);
};

export const invalidateAllCachedHeats = (
  queryClient: QueryClient,
  sportType: string,
  eventId: string,
  roundId: string,
) => {
  queryClient.invalidateQueries([keys.traders.fetchAllHeats, sportType, eventId, roundId]);
};

export const invalidateParticipants = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
  archived = 0,
) => {
  queryClient.removeQueries([keys.athletes.fetchParticipants, sport, eventId, archived]);
};

export const invalidatePositionalOdds = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
  position?: number,
) => {
  queryClient.invalidateQueries([
    keys.odds.fetchPositionalOdds,
    sport,
    eventId,
    { position: position },
  ]);
};

export const invalidateHeatWinnerOdds = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
) => {
  queryClient.invalidateQueries([keys.odds.fetchHeatWinnerOdds, sport, eventId]);
};

export const invalidateHeadToHeadOdds = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
  params?: { query?: string; page?: number; sortColumn?: string; sortOrder?: string },
) => {
  const headToHeadOddsKey = [keys.odds.fetchHeadToHeadOdds, sport, eventId];
  if (params) {
    headToHeadOddsKey.push(JSON.stringify(params));
  }
  queryClient.invalidateQueries(headToHeadOddsKey);
};

export const invalidateExactasEventOdds = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
  exactasType: number,
  params?: { query?: string; page?: number; sortColumn?: string; sortOrder?: string },
) => {
  const exactasEventOddsKey = [
    keys.odds.exactas.fetchExactasEventOdds,
    sport,
    eventId,
    exactasType,
  ];
  if (params) {
    exactasEventOddsKey.push(JSON.stringify(params));
  }
  queryClient.invalidateQueries(exactasEventOddsKey);
};

export const invalidateExactasHeatOdds = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
  exactasType: number,
  params?: { query?: string; page?: number; sortColumn?: string; sortOrder?: string },
) => {
  const exactasHeatOddsKey = [keys.odds.exactas.fetchExactasHeatOdds, sport, eventId, exactasType];
  if (params) {
    exactasHeatOddsKey.push(JSON.stringify(params));
  }
  queryClient.invalidateQueries(exactasHeatOddsKey);
};

export const invalidateDreamTeamOdds = (
  queryClient: QueryClient,
  sport: string,
  eventId: string,
  params?: { query?: string; page?: number; sortColumn?: string; sortOrder?: string },
) => {
  const headToHeadOddsKey = [keys.odds.fetchDreamTeamOdds, sport, eventId];
  if (params) {
    headToHeadOddsKey.push(JSON.stringify(params));
  }
  queryClient.invalidateQueries(headToHeadOddsKey);
};

export const invalidatePropBetOdds = (queryClient: QueryClient, sport: string, eventId: string) => {
  queryClient.invalidateQueries([keys.odds.fetchPropBetOdds, sport, eventId]);
};

export const invalidateShowOdds = (queryClient: QueryClient, sport: string, eventId: string) => {
  queryClient.invalidateQueries([keys.odds.fetchShowOdds, sport, eventId]);
};

export const invalidatePodiumOdds = (queryClient: QueryClient, sport: string, eventId: string) => {
  queryClient.invalidateQueries([keys.odds.fetchPodiumOdds, sport, eventId]);
};

export const invalidateGame = (queryClient: QueryClient, sport: string, gameDateId: string) => {
  queryClient.invalidateQueries([keys.games.fetchGame, sport, gameDateId]);
};

export const invalidateGameHeatScores = (
  queryClient: QueryClient,
  sport: string,
  gameDateId: string,
) => {
  queryClient.invalidateQueries([keys.games.fetchGameHeatScores, sport, gameDateId]);
};

//////// Futures

export const invalidateCachedSportFutures = ({ queryClient }: { queryClient: QueryClient }) => {
  queryClient.invalidateQueries([keys.futures.sports.fetchSportsFutures]);
};

export const invalidateCachedSportTourFutures = ({
  queryClient,
  sportType,
  tourYearId,
  fromComponent,
}: {
  queryClient: QueryClient;
  sportType: string;
  tourYearId: string;
  fromComponent: string;
}) => {
  queryClient.invalidateQueries([
    keys.futures.fetchSportTourFutures,
    sportType,
    tourYearId,
    fromComponent,
  ]);
};

export const invalidateFutureOdds = ({
  queryClient,
  sportType,
  tourYearId,
  futureType,
}: {
  queryClient: QueryClient;
  sportType: string;
  tourYearId: string;
  futureType: string;
}) => {
  queryClient.invalidateQueries([keys.futures.fetchFutureOdds, sportType, tourYearId, futureType]);
};
