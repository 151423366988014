import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { Typography, Stack } from '@mui/material';

import { AntSwitch } from '@/components/Switch';

import useUpdateEvent from '@/hooks/events/useUpdateEvent';

import { invalidateCachedEvent } from '@/helpers/cachedQueries';

import { IEvent, ITour } from '@/types/newTypes/event';

import keys from '@/constants/queryKeys';
import SPORT_TYPES from '@/constants/sportTypes';
import { EventStatusValues, EventStatusNames } from '@/constants/events';

const SimulationToggle = ({ sportType }: { sportType: string }) => {
  const { eventId = '' } = useParams();
  const queryClient = useQueryClient();
  const event: IEvent | undefined = queryClient.getQueryData([
    keys.events.fetchEvent,
    sportType,
    eventId,
    'Event',
  ]);
  const tours: ITour[] | undefined = queryClient.getQueryData([
    keys.events.fetchTours,
    sportType,
    'Event',
  ]);

  const [isToggling, setIsToggling] = React.useState(false);
  const { mutateAsync: toggleSimulation } = useUpdateEvent();

  const toggleSimulationHandler = () => {
    setIsToggling(true);
    toggleSimulation(
      {
        sport: sportType,
        eventId,
        requestPayload: {
          name: event?.name,
          startDate: event?.startDate,
          endDate: event?.endDate || null,
          eventLocation: event?.eventLocation,
          eventLocationGroup: event?.eventLocationGroup,
          eventStatus: event?.eventStatus,
          ...(sportType === SPORT_TYPES.SLS
            ? {
                leagueYearId:
                  tours
                    ?.find((tour) => tour?.id === event?.league?.id)
                    ?.years?.find((eventYear) => eventYear.year === event?.year)?.id || '',
              }
            : {
                tourYearId:
                  tours
                    ?.find((tour) => tour?.id === event?.tour?.id)
                    ?.years?.find((eventYear) => eventYear.year === event?.year)?.id || '',
                eventNumber: event?.eventNumber,
              }),
          isSimulationEnabled: !event?.isSimulationEnabled,
        },
      },
      {
        onSuccess: () => {
          setIsToggling(false);
          invalidateCachedEvent(queryClient, sportType, eventId, 'Event');
        },
        onError: () => {
          setIsToggling(false);
        },
      },
    );
  };

  const toggleText = {
    on: (
      <Typography>
        Simulation <strong>running</strong>
      </Typography>
    ),
    off: (
      <Typography>
        Simulation <strong>paused</strong>
      </Typography>
    ),
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        marginTop: '0.3rem',
      }}
    >
      <AntSwitch
        checked={event?.isSimulationEnabled}
        disabled={
          isToggling || event?.eventStatus === EventStatusValues[EventStatusNames.COMPLETED]
        }
        onChange={toggleSimulationHandler}
      />
      {event?.isSimulationEnabled ? toggleText.on : toggleText.off}
    </Stack>
  );
};

export default SimulationToggle;
