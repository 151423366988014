import { Column } from '@material-table/core';
import { tableCellStyle } from '@/utils/TableMisc';

const TableMatchResultsColumns: Column<any>[] = [
  { title: 'Date', field: 'date', cellStyle: tableCellStyle },
  {
    title: 'Event',
    field: 'event',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Match Order',
    field: 'matchOrder',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Weight Class',
    field: 'weightClass',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Winner Name',
    field: 'winnerName',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Loser Name',
    field: 'loserName',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Method',
    field: 'method',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Round',
    field: 'round',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Match Slaps',
    field: 'matchSlaps',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Judges Scores',
    field: 'judgeScores',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Referee',
    field: 'referee',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Coin Toss Winner',
    field: 'coinTossWinner',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Coin Toss Loser',
    field: 'coinTossLoser',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Coin Toss Winner Wins Match',
    field: 'coinTossWinnerWinsMatch',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Coin Toss Winner Wins by 1st Round KO',
    field: 'coinTossWinnerWinsByFirstRound',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Judge #1',
    field: 'judge1',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Judge #2',
    field: 'judge2',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Judge #3',
    field: 'judge3',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Notes',
    field: 'notes',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  // {
  //   title: 'Sportsbook Messaging',
  //   field: 'sportsbookMessaging',
  //   cellStyle: tableCellStyle,
  //   editable: 'never',
  // },
  // {
  //   title: 'DFS Messaging',
  //   field: 'dfsMessaging',
  //   cellStyle: tableCellStyle,
  //   editable: 'never',
  // },
];

export default TableMatchResultsColumns;
