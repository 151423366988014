import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';

import { sportsType } from '@/atoms/sportsType';
import { WSLTour } from '@/types/wsl/tour';
import { NRXTour } from '@/types/nrx/tour';
import Dropzone from '@/components/Dropzone';
import { SLSLeague } from '@/types/sls/league';
import { SPRLeague } from '@/types/spr/league';
import useAddToursOrLeagues from '@/hooks/tours-leagues/useAddToursOrLeagues';
import { TourRow, requiredKeys, allowedHeaders } from './constants';
import SPORT_TYPES, { sportOptions } from '@/constants/sportTypes';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';
import routes from '@/constants/routes';

type TOUR_OR_LEAGUES = WSLTour[] | NRXTour[] | SLSLeague[] | SPRLeague[];

export default function ToursLeaguesUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addToursOrLeagues } = useAddToursOrLeagues();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<TOUR_OR_LEAGUES>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        addToursOrLeagues(
          {
            sport: selectedSport,
            requestPayload: {
              items: modSheet,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Tours Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  const tableRowChecker = (rows: TourRow[]) => {
    let message = '';
    const index = rows.findIndex(
      (row: TourRow) =>
        !row.hasOwnProperty('name') || !row.hasOwnProperty('gender') || !row.hasOwnProperty('year'),
    );
    if (index !== -1) {
      const row = rows[index];
      let missing_columns = '';
      requiredKeys.map((key) => {
        if (!row[key as keyof TourRow]) missing_columns += `${key} `;
        return key;
      });
      missing_columns = missing_columns.split(' ').join(', ').slice(0, -2);
      message = `Error on row { ${index + 2} }, Missing { ${missing_columns} }`;
    }

    return message;
  };

  const tableDataModifierFn = (data: TourRow) => {
    const { name, year, gender } = data;
    return { name, year, gender };
  };

  const handleChange = () => {
    setSheetData([]);
  };

  const MAIN_EVENTS = {
    [SPORT_TYPES.WSL]: 'Tours',
    [SPORT_TYPES.SLS]: 'Leagues',
    [SPORT_TYPES.NRX]: 'Tours',
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.toursLeagues.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Tours/Leagues
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Tours"
        sheetName="Tours"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />
      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
          >
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} ${v.upperCase(MAIN_EVENTS[selectedSport])}?`}
          body={`This will upload the ${v.lowerCase(MAIN_EVENTS[selectedSport])}.`}
        />
      )}
    </Box>
  );
}
