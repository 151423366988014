import * as React from 'react';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { AthleteDTO } from '@/types/athlete';

const processAthleteStatusBadges = (athleteStatus: number) => {
  let status: any = '';
  switch (athleteStatus) {
    case 1:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#198754',
          }}
          label="Active"
        />
      );
      break;
    case 2:
      status = (
        <Chip
          size="small"
          label="Inactive"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
        />
      );
      break;
    case 3:
      status = (
        <Chip
          size="small"
          label="Injured"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;

    case 4:
      status = (
        <Chip
          size="small"
          label="Banned"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;
    case 5:
      status = (
        <Chip
          size="small"
          label="Disqualified"
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;

    case 6:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#DC3545',
          }}
          label="Suspended"
        />
      );
      break;

    case 7:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#DC3545',
          }}
          label="Parental Leave"
        />
      );
      break;

    case 8:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#F89B2B',
          }}
          label="Replacement"
        />
      );
      break;

    default:
      status = <Typography variant="caption">N.A</Typography>;
      break;
  }
  return status;
};

const ATHLETE_STATUS_OPTIONS = [
  { label: 'Active', value: 1 },
  { label: 'Inactive', value: 2 },
  { label: 'Injured', value: 3 },
  { label: 'Banned', value: 4 },
  { label: 'Disqualified', value: 5 },
  { label: 'Suspended', value: 6 },
  { label: 'Parental Leave', value: 7 },
  { label: 'Replacement', value: 8 },
];

export { processAthleteStatusBadges, ATHLETE_STATUS_OPTIONS };

export const addFullNameLabelProp = (obj: AthleteDTO[]) => {
  return obj
    ? obj.map((player: any) => {
        return {
          label: [player.athlete.firstName, player.athlete.middleName, player.athlete.lastName]
            .filter(Boolean)
            .join(' '),
          ...player.athlete,
        };
      })
    : [];
};
