// import SPORT_TYPES from '@/constants/sportTypes';

export const allowedTableHeaders = [
  { title: 'Event', field: 'event' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Tour Year', field: 'year' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Odds', field: 'americanOdds' },
];

// export const allowedTableHeaders = {
//   [SPORT_TYPES.SPR]: allowedSPRHeaders,
// };

interface CommonTraderShowOddRow {
  event: string;
  tour_name: string;
  gender: string;
  year: number;
  athlete: string;
  american_odds: number;
}

export type TraderShowOddsRow = CommonTraderShowOddRow;

const commonRequiredKeys = ['event', 'tour_name', 'gender', 'year', 'athlete', 'american_odds'];

// export const SPRRequiredKeys = [...commonRequiredKeys];

export const requiredPayloadKeys = commonRequiredKeys;

// {
//   [SPORT_TYPES.SPR]: SPRRequiredKeys,
// };
