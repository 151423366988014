import * as React from 'react';
import {
  styled,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  Typography,
  Chip,
  Box,
  IconButton,
  Select,
  TextField,
  MenuItem,
  Switch,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import UnstyledDecimalField from '@/components/DecimalField';
import { BLUE_CORNER_COLOR, RED_CORNER_COLOR } from '.';
import { tableCellStyle } from '@/utils/TableMisc';

type AutocompleteOptionProp = { value?: string; label: string; inputValue?: string };

const filter = createFilterOptions<AutocompleteOptionProp>();

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))((props) => ({
  width: '100%',
  marginBottom: '1rem',
  backgroundColor: props?.expanded ? '#ffffff' : '#F8F9FA',
  borderRadius: '0.5rem',
  border: `1px solid #CED4DA`,
  '&:before': {
    opacity: 0,
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowDropDownIcon />} {...props} />
))(() => ({
  width: '100%',
  // padding: '0.4rem',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  padding: '0.4rem',
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  width: '100%',
}));

export const AccordionSummaryText = styled(Typography)<{ component?: string }>({
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
});

export const AccordionSummaryTextLg = styled(Typography)({
  fontSize: '1.375rem',
  lineHeight: '1.625rem',
});

export const AccordionDtlsLabelText = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 500,
});

export const ModSelect = styled(Select)({
  height: 40,
  color: '#212529',
  fontSize: '0.812rem',
  fontWeight: '400',
  padding: '0.375rem 0.5rem',
  backgroundColor: '#F8F9FA',
});

export const ModTextField = styled(TextField)({
  fontWeight: '400',
  '& .MuiInputBase-input': {
    padding: '0.25rem',
    fontSize: '0.812rem',
  },
  '& .MuiInputBase-root': {
    padding: '0.35rem 0.6rem',
  },
});

export const ModDecimalField = styled(UnstyledDecimalField)({
  fontWeight: '400',
  '& .MuiInputBase-input': {
    padding: '0.25rem',
    fontSize: '0.812rem',
  },
  '& .MuiInputBase-root': {
    padding: '0.40rem 0.6rem',
  },
});

export const ModTextFieldAutocomplete = styled(TextField)({
  fontWeight: '400',
  '& .MuiInputBase-input': {
    padding: '0',
    fontSize: '0.812rem',
  },
  '& .MuiInputBase-root': {
    padding: '0.25rem 0.5rem',
  },
});

// Autocomplete
export const ModAutocomplete = ({ value, options = [], onChange }: any) => (
  <Autocomplete
    value={value}
    onChange={onChange}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          label: `Add "${inputValue}"`,
        });
      }

      return filtered;
    }}
    // getOptionLabel={(option) => {
    //   // Value selected with enter, right from the input
    //   if (typeof option === 'string') {
    //     return option;
    //   }
    //   // Add "xxx" option created dynamically
    //   if (option.inputValue) {
    //     return option.inputValue;
    //   }
    //   // Regular option
    //   return option.label;
    // }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    options={options}
    freeSolo
    renderInput={(params) => <ModTextFieldAutocomplete {...params} fullWidth />}
  />
);

export const ModAutocompleteMultiple = ({ value, options = [], onChange }: any) => (
  <Autocomplete
    multiple
    value={value}
    onChange={onChange}
    filterOptions={(options, params) => {
      const filtered = filter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some((option) => inputValue === option.label);
      if (inputValue !== '' && !isExisting) {
        filtered.push({
          inputValue,
          label: `Add "${inputValue}"`,
        });
      }

      return filtered;
    }}
    selectOnFocus
    clearOnBlur
    handleHomeEndKeys
    options={options}
    freeSolo
    renderInput={(params) => <ModTextFieldAutocomplete {...params} fullWidth />}
  />
);

export const CustomMultilineInput = ({ handleBlur, value }: any) => {
  const [text, setText] = React.useState(value);

  React.useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <ModTextField
      value={text}
      multiline
      rows={4}
      maxRows={8}
      fullWidth
      onBlur={handleBlur}
      onChange={({ target }) => {
        setText(target.value);
      }}
    />
  );
};

export const CustomAthleteSelect = ({
  ATHLETE_OPTIONS,
  athleteColor,
  skipAthlete,
  handleBlur,
  value,
}: any) => {
  const [selectedAthlete, setSelectedAthlete] = React.useState(value);

  React.useEffect(() => {
    setSelectedAthlete(value);
  }, [value]);

  const renderValue = (selected: any) => {
    const selectedAthlete = ATHLETE_OPTIONS.find((athlete: any) => athlete === selected);
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.4rem',
        }}
      >
        {selectedAthlete?.label && (
          <Box
            sx={{
              display: 'inline-flex',
              borderRadius: '50%',
              height: '0.5rem',
              width: '0.5rem',
              backgroundColor: athleteColor,
            }}
          />
        )}
        <AccordionSummaryText>
          {selectedAthlete?.label || 'Please select athlete'}
        </AccordionSummaryText>
      </Box>
    );
  };
  return (
    <ModSelect
      value={selectedAthlete}
      MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
      fullWidth
      onChange={({ target }) => {
        setSelectedAthlete(target.value);
        handleBlur(target.value);
      }}
      // onClose={() => handleBlur(selectedAthlete)}
      renderValue={renderValue}
    >
      {React.Children.toArray(
        ATHLETE_OPTIONS?.filter((athlete: any) => athlete !== skipAthlete).map((athlete: any) => (
          <MenuItem value={athlete}>{athlete?.label}</MenuItem>
        )),
      )}
    </ModSelect>
  );
};

export const CustomRoundAthleteSelect = ({
  name,
  ATHLETE_OPTIONS,
  handleBlur,
  value,
  redCornerAthlete,
  blueCornerAthlete,
  disabled,
}: any) => {
  const [selectedAthlete, setSelectedAthlete] = React.useState('');

  React.useEffect(() => {
    setSelectedAthlete(value);
  }, [value, name, ATHLETE_OPTIONS]);

  return (
    <ModSelect
      name={name}
      value={selectedAthlete}
      MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
      sx={tableCellStyle}
      fullWidth
      onChange={({ target }) => {
        setSelectedAthlete(target.value as unknown as string);
        handleBlur(target.value);
      }}
      disabled={disabled}
      // onClose={() => handleBlur(selectedAthlete)}
      renderValue={(selected) => {
        if (!selected) {
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
              <Typography>Please select athlete</Typography>
            </Box>
          );
        }

        const getColorIcon = (id: string) => {
          let color = null;
          if (id === redCornerAthlete?.id) color = RED_CORNER_COLOR;
          if (id === blueCornerAthlete?.id) color = BLUE_CORNER_COLOR;
          return color ? (
            <Box
              sx={{
                display: 'inline-flex',
                borderRadius: '50%',
                height: '0.5rem',
                width: '0.5rem',
                backgroundColor: color,
              }}
            ></Box>
          ) : null;
        };

        const colorIcon = getColorIcon(selected?.id);
        const label = selected?.label || '';
        const displayLabel = label.length > 22 ? `${label.slice(0, 22)}...` : label;

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
            {colorIcon}
            <Typography sx={tableCellStyle}>{displayLabel}</Typography>
          </Box>
        );
      }}
    >
      {React.Children.toArray(
        ATHLETE_OPTIONS?.map((athlete: any) => (
          <MenuItem value={athlete}>{athlete?.label}</MenuItem>
        )),
      )}
    </ModSelect>
  );
};

// Counter Button -------------

const CounterInput = styled(UnstyledDecimalField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    '& input': {
      textAlign: 'center',
      padding: 0,
    },
    '& fieldset': { border: 'none' },
  },
  '& .MuiInputBase-input': {
    padding: '0.25rem 0.75rem',
    fontSize: '0.812rem',
  },
});

const CounterIconButton = styled(IconButton)({
  padding: '0.3rem',
});

export const CounterButton = ({
  value = 0,
  changeHandler,
  bgColor,
  disabled = false,
}: {
  value: number;
  changeHandler?: (arg0: number) => void;
  bgColor?: string;
  disabled?: boolean;
}) => {
  const [count, setCount] = React.useState<number>(value || 0);
  const handleChange = (e: any) => {
    setCount(+e.target.value || 0);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (changeHandler) {
      changeHandler(+e.target.value || 0);
    }
  };
  React.useEffect(() => {
    setCount(value);
  }, [value]);
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: bgColor ? bgColor : '#FFFFFF',
        borderRadius: '0.25rem',
        border: '1px solid #CED4DA',
        padding: '0.3rem',
      }}
    >
      <CounterIconButton
        disableRipple
        onClick={() => {
          setCount((prev) => prev - 1);
          if (changeHandler) {
            changeHandler(count - 1);
          }
        }}
        disabled={count <= 1}
      >
        <RemoveIcon fontSize="small" />
      </CounterIconButton>
      <CounterInput
        size="small"
        onChange={handleChange}
        onBlur={handleBlur}
        value={count}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: bgColor ? bgColor : '#ffffff',
          },
        }}
        // allowNegative
        disabled={disabled}
        fullWidth
      />
      <CounterIconButton
        disableRipple
        onClick={() => {
          setCount((prev) => prev + 1);
          if (changeHandler) {
            changeHandler(count + 1);
          }
        }}
        disabled={disabled}
      >
        <AddIcon fontSize="small" />
      </CounterIconButton>
    </Box>
  );
};

export const RedPlayerLabel = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.6875rem',
      lineHeight: '1.25rem',
      color: '#ffff',
      backgroundColor: '#ED4C47',
      borderRadius: '0.5rem',
    }}
  >
    Red
  </Box>
);

export const BluePlayerLabel = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.6875rem',
      lineHeight: '1.25rem',
      color: '#ffff',
      backgroundColor: '#3071E8',
      borderRadius: '0.5rem',
    }}
  >
    Blue
  </Box>
);

export const ScoreLabels = () => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 50fr)',
      alignItems: 'center',
      // borderRadius: '0.25rem',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.6875rem',
        lineHeight: '1.25rem',
        color: '#ffff',
        backgroundColor: '#ED4C47',
        borderTopLeftRadius: '0.25rem',
        borderBottomLeftRadius: '0.25rem',
      }}
    >
      Red
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '0.6875rem',
        lineHeight: '1.25rem',
        color: '#ffff',
        backgroundColor: '#3071E8',
        borderTopRightRadius: '0.25rem',
        borderBottomRightRadius: '0.25rem',
      }}
    >
      Blue
    </Box>
  </Box>
);

export const JudgeScores = ({
  bgColor,
  disabled,
  score1,
  score2,
  handleChangeScr1,
  handleChangeScr2,
}: any) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: bgColor ? bgColor : '#FFFFFF',
        borderRadius: '0.25rem',
        border: '1px solid #CED4DA',
      }}
    >
      <CounterInput
        size="small"
        onChange={handleChangeScr2}
        value={score2}
        allowNegative
        disabled={disabled}
        fullWidth
      />
      <Box sx={{ height: '100%', borderRight: '1px solid #CED4DA' }} />
      <CounterInput
        size="small"
        onChange={handleChangeScr1}
        value={score1}
        allowNegative
        disabled={disabled}
        fullWidth
      />
    </Box>
  );
};

export const processMatchStatusBadges = (eventStatus: number) => {
  let status: any = '';
  switch (eventStatus) {
    case 1:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#198754',
          }}
          label={<AccordionSummaryText component="span">Live</AccordionSummaryText>}
        />
      );
      break;

    case 2:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label={<AccordionSummaryText component="span">Upcoming</AccordionSummaryText>}
        />
      );
      break;

    case 3:
      status = (
        <Chip
          size="small"
          label={<AccordionSummaryText component="span">Completed</AccordionSummaryText>}
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
    case 4:
      status = (
        <Chip
          size="small"
          label={<AccordionSummaryText component="span">Canceled</AccordionSummaryText>}
          sx={{
            backgroundColor: '#DC3545',
          }}
        />
      );
      break;
    case 5:
      status = (
        <Chip
          size="small"
          label={<AccordionSummaryText component="span">Postponed</AccordionSummaryText>}
          sx={{
            backgroundColor: '#DE5C9D',
          }}
        />
      );
      break;

    case 6:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#8C68CD',
          }}
          label={<AccordionSummaryText component="span">Next</AccordionSummaryText>}
        />
      );
      break;

    case 7:
      status = (
        <Chip
          size="small"
          sx={{
            backgroundColor: '#9FA7AE',
          }}
          label={<AccordionSummaryText component="span">In Window</AccordionSummaryText>}
        />
      );
      break;

    default:
      status = (
        <Chip
          size="small"
          label={<AccordionSummaryText component="span">Completed</AccordionSummaryText>}
          sx={{
            backgroundColor: '#0DCAF0',
          }}
        />
      );
      break;
  }
  return status;
};

export const RedBlueSwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    color: '#ad1611',
    '&.Mui-checked': {
      color: '#0e5382',
      '& + .MuiSwitch-track': {
        backgroundColor: BLUE_CORNER_COLOR,
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: RED_CORNER_COLOR,
  },
}));
