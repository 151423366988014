import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, GoogleAuthProvider, signOut } from 'firebase/auth';

import { setAuthToken, removeAuthToken, PLAYER_URL } from '@/constants/localStorageKeys';

import config from '@/config/vars';
import { queryClient } from '@/react-query/queryClient';

const instance = initializeApp(config.firebaseConfig);
const auth = getAuth(instance);

export const fetchCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (user: any) => {
        unsubscribe();
        resolve(user);
      },
      reject,
    );
  });
};

export const fetchToken = (currentUser: any) => currentUser.getIdToken(true);

export const getInstance = () => instance;
export const getInstanceAuth = () => auth;

export const logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error || new Error('Error logging out');
  }
};

export const googleAuthProvider = () => {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
  provider.addScope('https://www.googleapis.com/auth/user.addresses.read');
  provider.addScope('https://www.googleapis.com/auth/user.gender.read');
  return provider;
};

export const refreshAuthToken = async () => {
  try {
    const user = await fetchCurrentUser();

    if (!user) {
      return Promise.reject(new Error('User not found!'));
    }

    const token = await fetchToken(user);
    setAuthToken(token);

    return Promise.resolve();
  } catch (error) {
    localStorage.removeItem(PLAYER_URL);
    removeAuthToken();
    logout();
    queryClient.clear();
    window.location.href = window.location.host;
    return Promise.reject(error);
  }
};
