import React from 'react';
import { Button, Box } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ExportBtn = ({
  handleOnClick,
  btnText = '',
  disabled = false,
}: {
  handleOnClick: Function;
  btnText?: string;
  disabled?: boolean;
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <Button
        id="exportBtn"
        variant="contained"
        startIcon={<CloudDownloadIcon />}
        onClick={() => handleOnClick()}
        disableElevation
        style={{ width: '15rem' }}
        disabled={disabled}
      >
        {btnText || 'Export'}
      </Button>
    </Box>
  );
};

export default ExportBtn;
