import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material';

import StartEndTimes from '@/components/StartEndTimes';

import { RoundDTO } from '@/types/round';
import { TYPES_OF_START_AND_END_TIMES } from '@/constants/misc';

const RoundName = styled(Typography)(({ theme }) => ({
  marginTop: '1.25rem',
  marginBottom: '0.375rem',
  color: theme.palette.info.main,
  fontSize: '1.5rem',
  fontWeight: '500',
}));

const RoundDetails = ({
  selectedRound,
  typeOfStartAndEndTime = TYPES_OF_START_AND_END_TIMES.round,
  showName = true,
}: {
  selectedRound: RoundDTO;
  typeOfStartAndEndTime?: string;
  showName?: boolean;
}) => {
  const { name: roundName } = selectedRound || {};

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {showName && roundName && <RoundName>{roundName}</RoundName>}
        <StartEndTimes
          startDate={selectedRound?.startDate}
          endDate={selectedRound?.endDate}
          typeOfTime={typeOfStartAndEndTime}
        />
      </Box>
    </>
  );
};

export default RoundDetails;
