import React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { EventListingSkeleton } from '@/components/PageSkeletons';
import ScoreManage from './ScoreManage';
import HeatViewToggle from '@/components/HeatViewToggle';
import RoundDetails from '@/components/RoundDetails';
import Slider from '@/components/Slider';
import HeatListView from '@/components/HeatListView';

import { sortHeatsByHeatNo } from '@/helpers/heats';

import { HEAT_VIEW_MODES } from '@/constants/heats';
import { scoreManagementTheme } from '@/constants/theme';
import SPORT_TYPES from '@/constants/sportTypes';

import useHLRSScore from './useHLRSScore';

const sportType = SPORT_TYPES.HLRS;

const HLRSEventScores = () => {
  const {
    isLoadingEvent,
    isLoadingHeatScore,
    isFetchingHeatScore,
    processedHeatScores,
    lapsCountArr,
    heatViewMode,
    setHeatViewMode,
    prevHeat,
    nextHeat,
    heatScores,
    playerDropdownOptions,
    saveHeatScore,
    endHeat,
    handleCurrentRound,
    handleCurrentHeat,
    roundList,
    selectedRound,
    event,
    selectedHeat,
  } = useHLRSScore();

  if (isLoadingEvent)
    return (
      <Box>
        <EventListingSkeleton />
      </Box>
    );

  return (
    <Grid container direction="column">
      <HeatViewToggle heatViewMode={heatViewMode} setHeatViewMode={setHeatViewMode} />
      <Slider
        sliderItems={roundList}
        selectedItem={selectedRound}
        handleItemSelect={handleCurrentRound}
        itemType={'rounds'}
      />

      {selectedRound && (
        <>
          <RoundDetails selectedRound={selectedRound} />
          {heatViewMode === HEAT_VIEW_MODES.default && (
            <Slider
              sliderItems={sortHeatsByHeatNo(selectedRound.heats)}
              selectedItem={selectedHeat}
              handleItemSelect={handleCurrentHeat}
              itemType={'heats'}
            />
          )}
        </>
      )}
      {isLoadingHeatScore && heatViewMode === HEAT_VIEW_MODES.default ? (
        <EventListingSkeleton />
      ) : (
        heatViewMode === HEAT_VIEW_MODES.default &&
        selectedHeat &&
        processedHeatScores && (
          <ThemeProvider theme={scoreManagementTheme}>
            <ScoreManage
              selectedHeat={heatScores.heat || {}}
              heatScores={processedHeatScores}
              players={playerDropdownOptions}
              prevHeat={prevHeat}
              nextHeat={nextHeat}
              handleCurrentHeat={handleCurrentHeat}
              saveHeatScore={saveHeatScore}
              endHeat={endHeat}
              lapsCountArr={lapsCountArr}
              roundNo={Number(selectedRound?.roundNo)}
              isFetchingHeatScore={isFetchingHeatScore}
            />
          </ThemeProvider>
        )
      )}
      {heatViewMode === HEAT_VIEW_MODES.list && (
        <HeatListView
          eventId={event?.id || ''}
          roundId={selectedRound?.id || ''}
          playerDropdownOptions={playerDropdownOptions}
          sportType={sportType}
          lapsCountArr={lapsCountArr}
          roundNo={Number(selectedRound?.roundNo)}
        />
      )}
    </Grid>
  );
};

export default HLRSEventScores;
