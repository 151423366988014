import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Typography,
  TextField,
  IconButton,
  Box,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { parsePhoneNumber } from 'libphonenumber-js';

import { editCellTextFieldStyle } from '@/utils/TableMisc';

import useAddOrUpdateClient from '@/hooks/user/useAddOrUpdateClient';

import { useQueryClient } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
// import keys from '@/constants/queryKeys';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '1.5rem 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1.5rem 1.5rem 3rem',
  },
}));

const FormLabel = styled(Typography)<{ component?: string }>({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

interface IClient {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  companyName: string;
  id?: string;
}

type AddOrEditClientModalProps = {
  open: boolean;
  handleClose: () => void;
  client?: Partial<IClient>;
};

const AddOrEditClientModal = (props: AddOrEditClientModalProps) => {
  const { open, handleClose, client } = props;

  const queryClient = useQueryClient();

  const { mutateAsync: addOrUpdateClient } = useAddOrUpdateClient();

  const initialValues = {
    firstName: client?.firstName || '',
    lastName: client?.lastName || '',
    phone: client?.phone || '',
    email: client?.email || '',
    companyName: client?.companyName || '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      phone: Yup.string()
        .test('is-valid-phone-number', 'Invalid phone number', (value) => {
          if (!value) return false;
          const phoneNumber = parsePhoneNumber(value);
          return phoneNumber ? phoneNumber.isPossible() : false;
        })
        .required('Phone No is required'),
    }),
    onSubmit: (_, { setSubmitting }) => {
      try {
        const requestPayload = formatPayload();
        addOrUpdateClient(
          { data: requestPayload, id: client?.id },
          {
            onSettled: (_, err) => {
              if (!err) {
                enqueueSnackbar(`${client ? 'Updated' : 'Added'} client successfully!`);
                queryClient.invalidateQueries([keys.userManagement.fetchClientList]);
              }
              handleClose();
            },
          },
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const { values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting } = formik;

  const formatPayload = () => {
    const payload = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      phone: values?.phone,
      email: values?.email,
      companyName: values?.companyName,
    };
    return payload;
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            px: '1.3rem',
            py: 0.5,
            borderBottom: '1px solid #DEE2E6',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,

              fontSize: '1.25rem',
              lineHeight: '1.5rem',
            }}
            component="h2"
          >
            {client ? 'Edit client' : 'Add client'}
          </Typography>
          <IconButton onClick={handleClose} disableRipple disabled={isSubmitting}>
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container sx={{ gap: '1rem' }}>
            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  First Name{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.firstName && touched?.firstName)}
                  helperText={errors?.firstName && touched?.firstName ? errors?.firstName : ''}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Last Name{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.lastName && touched?.lastName)}
                  helperText={errors?.lastName && touched?.lastName ? errors?.lastName : ''}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Phone Number{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.phone && touched?.phone)}
                  helperText={errors?.phone && touched?.phone ? errors?.phone : ''}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Email{' '}
                  <FormLabel component={'span'} sx={{ color: '#6C757D' }}>
                    (optional)
                  </FormLabel>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.email && touched?.email)}
                  helperText={errors?.email && touched?.email ? errors?.email : ''}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Company{' '}
                  <FormLabel component={'span'} sx={{ color: '#6C757D' }}>
                    (optional)
                  </FormLabel>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.companyName && touched?.companyName)}
                  helperText={
                    errors?.companyName && touched?.companyName ? errors?.companyName : ''
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                }}
                fullWidth
                disableElevation
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disableElevation
                disabled={isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default AddOrEditClientModal;
