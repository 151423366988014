import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import ODDS_TYPES from '@/constants/oddsTypes';
import { NON_ATHLETE_PROP } from '@/constants/misc';
import { EXCEL_DOC_TITLES_BASED_ON_POS } from '@/constants/oddTabs';

const CSV_HEADERS = {
  [ODD_DATA_TYPES.SHOWS]: 'Shows',
  [ODD_DATA_TYPES.PODIUMS]: 'Podiums',
};

const DEFAULT_RESPONSE: {
  title: string;
  headers: any[];
  csvData: any[];
} = { title: '', headers: [], csvData: [] };

const convertOddsToCSV = ({
  data,
  oddDataType,
  oddType,
  position,
}: {
  data: any[];
  oddDataType: string;
  oddType?: string;
  position?: number | null;
}) => {
  const title = CSV_HEADERS[oddDataType]
    ? CSV_HEADERS[oddDataType]
    : EXCEL_DOC_TITLES_BASED_ON_POS[position as keyof {}];

  if ((data && data.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const headers = [
    // 'Event Seed',
    'Athlete',
    // 'Nationality',
    // 'Stance',
    'Probability',
  ];

  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds'
      : 'American Odds',
  );

  const csvData = data.map((rowData) => {
    const newModData: any = {};
    // newModData.eventSeed = rowData?.athlete?.seedNo || '';
    newModData.name = [
      rowData?.athlete?.firstName,
      rowData?.athlete?.middleName,
      rowData?.athlete?.lastName,
    ]
      .filter(Boolean)
      .join(' ');
    // newModData.nationality = rowData?.athlete?.nationality || '';
    // newModData.stance = rowData?.athlete?.stance || '';
    newModData.probability = rowData?.probability;
    if (ODDS_TYPES.DECIMAL === oddType) {
      newModData.decimalOdds = rowData?.decimalOdds || 0;
    }
    if (ODDS_TYPES.FRACTIONAL === oddType) {
      newModData.fractionalOdds = rowData?.fractionalOdds || 0;
    }
    if (ODDS_TYPES.AMERICAN === oddType) {
      newModData.americanOdds = rowData?.americanOdds || 0;
    }

    return newModData;
  });

  return { title, headers, csvData };
};

const convertHeatOddsToCSV = ({ data, oddType }: { data: any; oddType?: string }) => {
  const title = 'Heat Winner';

  if ((data && data?.heats?.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const headers = [
    // 'Round Number',
    // 'Round Name',
    // 'Heat Number',
    // 'Heat Name',
    // 'Event Seed',
    'Athlete',
    // 'Nationality',
    // 'Stance',
    'Probability',
  ];
  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds'
      : 'American Odds',
  );
  const csvData: any[] = [];

  data?.heats?.forEach((heat: any) => {
    const heatDetails = ['Heat Name', heat?.name || ''];
    csvData.push(heatDetails);
    csvData.push(headers);
    heat.athletes.forEach((athlete: any) => {
      const row: any = [];
      row.push(
        [athlete?.firstName, athlete?.middleName, athlete?.lastName].filter(Boolean).join(' '),
      );
      row.push(athlete?.probability);
      if (ODDS_TYPES.DECIMAL === oddType) {
        row.push(athlete?.decimalOdds || 0);
      }
      if (ODDS_TYPES.FRACTIONAL === oddType) {
        row.push(athlete?.fractionalOdds || 0);
      }
      if (ODDS_TYPES.AMERICAN === oddType) {
        row.push(athlete?.americanOdds || 0);
      }
      csvData.push(row);
    });
    csvData.push([]);
  });

  // data.map((rowData: any) => {
  //   const newModData: any = {};
  //   // newModData.roundNo = round?.roundNo || '';
  //   // newModData.heatNo = heat?.heatNo || '';
  //   // newModData.heatName = heat?.name || '';
  //   // newModData.eventSeed = rowData?.seedNo || '';
  //   newModData.name = [rowData?.firstName, rowData?.middleName, rowData?.lastName]
  //     .filter(Boolean)
  //     .join(' ');
  //   // newModData.nationality = rowData?.nationality || '';
  //   // newModData.stance = rowData?.stance || '';
  //   newModData.probability = rowData?.probability;
  //   if (ODDS_TYPES.DECIMAL === oddType) {
  //     newModData.decimalOdds = rowData?.decimalOdds || 0;
  //   }
  //   if (ODDS_TYPES.FRACTIONAL === oddType) {
  //     newModData.fractionalOdds = rowData?.fractionalOdds || 0;
  //   }
  //   if (ODDS_TYPES.AMERICAN === oddType) {
  //     newModData.americanOdds = rowData?.americanOdds || 0;
  //   }
  //   csvData.push(newModData);
  //   return newModData;
  // });
  return { title, headers: [], csvData };
};

const convertFightOddsToCSV = ({ data, oddType }: { data: any; oddType?: string }) => {
  const title = 'Fight Winner';

  if ((data && data?.heats?.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const headers = [
    // 'Round Number',
    // 'Round Name',
    // 'Heat Number',
    // 'Heat Name',
    // 'Event Seed',
    'Athlete',
    // 'Nationality',
    // 'Stance',
    'Probability',
  ];
  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds'
      : 'American Odds',
  );
  const csvData: any[] = [];

  data?.heats?.forEach((heat: any) => {
    const heatDetails = ['Fight Name', heat?.name || ''];
    csvData.push(heatDetails);
    csvData.push(headers);
    heat.athletes.forEach((athlete: any) => {
      const row: any = [];
      row.push(
        [athlete?.firstName, athlete?.middleName, athlete?.lastName].filter(Boolean).join(' '),
      );
      row.push(athlete?.probability);
      if (ODDS_TYPES.DECIMAL === oddType) {
        row.push(athlete?.decimalOdds || 0);
      }
      if (ODDS_TYPES.FRACTIONAL === oddType) {
        row.push(athlete?.fractionalOdds || 0);
      }
      if (ODDS_TYPES.AMERICAN === oddType) {
        row.push(athlete?.americanOdds || 0);
      }
      csvData.push(row);
    });
    csvData.push([]);
  });

  // data.map((rowData: any) => {
  //   const newModData: any = {};
  //   // newModData.roundNo = round?.roundNo || '';
  //   // newModData.heatNo = heat?.heatNo || '';
  //   // newModData.heatName = heat?.name || '';
  //   // newModData.eventSeed = rowData?.seedNo || '';
  //   newModData.name = [rowData?.firstName, rowData?.middleName, rowData?.lastName]
  //     .filter(Boolean)
  //     .join(' ');
  //   // newModData.nationality = rowData?.nationality || '';
  //   // newModData.stance = rowData?.stance || '';
  //   newModData.probability = rowData?.probability;
  //   if (ODDS_TYPES.DECIMAL === oddType) {
  //     newModData.decimalOdds = rowData?.decimalOdds || 0;
  //   }
  //   if (ODDS_TYPES.FRACTIONAL === oddType) {
  //     newModData.fractionalOdds = rowData?.fractionalOdds || 0;
  //   }
  //   if (ODDS_TYPES.AMERICAN === oddType) {
  //     newModData.americanOdds = rowData?.americanOdds || 0;
  //   }
  //   csvData.push(newModData);
  //   return newModData;
  // });
  return { title, headers: [], csvData };
};

const convertHeadToHeadOddsToCSV = ({ data, oddType }: { data: any[]; oddType?: string }) => {
  const title = 'Player Head To Head';

  if ((data && data.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const headers = [
    'Hold Percentage',
    'Margin',
    // 'Event Seed Athlete 1',
    // 'Stance Athlete 1',
    'Probability Athlete 1',
    'Winner Athlete 1',
    'Place Athlete 1',
  ];
  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds Athlete 1'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds Athlete 1'
      : 'American Odds Athlete 1',
  );
  headers.push(...['Athlete 1', 'Athlete 2']);
  // '',
  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds Athlete 2'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds Athlete 2'
      : 'American Odds Athlete 2',
  );
  headers.push(
    ...[
      'Place Athlete 2',
      'Winner Athlete 2',
      // 'Event Seed Athlete 2',
      // 'Stance Athlete 2',
      'Probability Athlete 2',
      'Voided',
    ],
  );

  const csvData = data.map((rowData) => {
    const newModData: any = {};
    newModData.holdingPercentage = rowData?.holdingPercentage;
    newModData.margin = `${rowData?.holdingPercentage - 100}%`;

    // newModData.eventSeedParticipant1 = rowData?.eventParticipant1?.athlete?.seedNo || '';
    // newModData.stanceParticipant1 = rowData?.eventParticipant1?.athlete?.stance || '';
    newModData.probabilityParticipant1 = rowData?.eventParticipant1?.probability;
    newModData.winnerParticipant1 =
      rowData?.winnerParticipantId === rowData?.eventParticipant1?.id ? 'Yes' : 'No';
    newModData.positionParticipant1 = rowData?.eventParticipant1?.position;
    if (ODDS_TYPES.DECIMAL === oddType) {
      newModData.decimalOddsParticipant1 = rowData?.eventParticipant1?.decimalOdds || 0;
    }
    if (ODDS_TYPES.FRACTIONAL === oddType) {
      newModData.fractionalOddsParticipant1 = rowData?.eventParticipant1?.fractionalOdds || 0;
    }
    if (ODDS_TYPES.AMERICAN === oddType) {
      newModData.americanOddsParticipant1 = rowData?.eventParticipant1?.americanOdds || 0;
    }

    newModData.nameParticipant1 = `${rowData?.eventParticipant1?.label || ''} `;

    // newModData.vs = 'VS';

    ////////////////////////////////////////////////////////////

    newModData.nameParticipant2 = `${rowData?.eventParticipant2?.label || ''} `;
    if (ODDS_TYPES.DECIMAL === oddType) {
      newModData.decimalOddsParticipant2 = rowData?.eventParticipant2?.decimalOdds || 0;
    }
    if (ODDS_TYPES.FRACTIONAL === oddType) {
      newModData.fractionalOddsParticipant2 = rowData?.eventParticipant2?.fractionalOdds || 0;
    }
    if (ODDS_TYPES.AMERICAN === oddType) {
      newModData.americanOddsParticipant2 = rowData?.eventParticipant2?.americanOdds || 0;
    }
    newModData.positionParticipant2 = rowData?.eventParticipant2?.position;
    newModData.winnerParticipant2 =
      rowData?.winnerParticipantId === rowData?.eventParticipant2?.id ? 'Yes' : 'No';
    // newModData.eventSeedParticipant2 = rowData?.eventParticipant2?.athlete?.seedNo || '';
    // newModData.stanceParticipant2 = rowData?.eventParticipant2?.athlete?.stance || '';
    newModData.probabilityParticipant2 = rowData?.eventParticipant2?.probability;
    newModData.voided = rowData?.voided ? 'Yes' : 'No';

    return newModData;
  });

  return { title, headers, csvData };
};

const convertPropBetOddsToCSV = ({ data, oddType }: { data: any[]; oddType?: string }) => {
  const title = 'Prop Bets';

  if ((data && data.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const headers = ['Athlete', 'Proposition'];
  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds'
      : 'American Odds',
  );
  headers.push(...['Payout', 'Voided']);

  const csvData = data.map((rowData) => {
    const newModData: any = {};
    newModData.athlete = rowData?.athlete ? rowData?.athlete.athleteName : NON_ATHLETE_PROP;
    newModData.proposition = rowData?.proposition;

    if (ODDS_TYPES.DECIMAL === oddType) {
      newModData.decimalOdds = rowData?.decimalOdds || 0;
    }
    if (ODDS_TYPES.FRACTIONAL === oddType) {
      newModData.fractionalOdds = rowData?.fractionalOdds || 0;
    }
    if (ODDS_TYPES.AMERICAN === oddType) {
      newModData.americanOdds = rowData?.americanOdds || 0;
    }

    newModData.payout = rowData?.payout ? 'Yes' : 'No';
    newModData.voided = rowData?.voided ? 'Yes' : 'No';

    return newModData;
  });

  return { title, headers, csvData };
};

const convertExactasEventOddsToCSV = ({ data, oddType }: { data: any[]; oddType?: string }) => {
  const title = 'Prop Bets';

  if ((data && data.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const headers = ['1st Place', '2nd Place', '3rd Place', 'Probability'];

  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds'
      : 'American Odds',
  );
  headers.push(...['Payout', 'Voided']);

  const csvData = data.map((rowData) => {
    const newModData: any = {};
    newModData.firstParticipant = rowData.firstParticipant;
    newModData.secondParticipant = rowData.secondParticipant;
    newModData.thirdParticipant = rowData.thirdParticipant;

    newModData.probability = rowData?.probability;

    if (ODDS_TYPES.DECIMAL === oddType) {
      newModData.decimalOdds = rowData?.decimalOdds || 0;
    }
    if (ODDS_TYPES.FRACTIONAL === oddType) {
      newModData.fractionalOdds = rowData?.fractionalOdds || 0;
    }
    if (ODDS_TYPES.AMERICAN === oddType) {
      newModData.americanOdds = rowData?.americanOdds || 0;
    }

    newModData.payout = rowData?.payout ? 'Yes' : 'No';
    newModData.voided = rowData?.voided ? 'Yes' : 'No';

    return newModData;
  });

  return { title, headers, csvData };
};

export const oddsToCSVFormatModifier = ({
  data,
  oddDataType,
  position,
  oddType,
}: {
  data: any[];
  oddDataType: string;
  position?: number | null;
  oddType?: string;
}) => {
  //
  let result = DEFAULT_RESPONSE;
  if ([ODD_DATA_TYPES.ODDS, ODD_DATA_TYPES.SHOWS, ODD_DATA_TYPES.PODIUMS].includes(oddDataType)) {
    result = convertOddsToCSV({ data, oddDataType, position, oddType });
  }
  if (oddDataType === ODD_DATA_TYPES.HEATS) {
    result = convertHeatOddsToCSV({ data, oddType });
  }
  if (oddDataType === ODD_DATA_TYPES.FIGHTS) {
    result = convertFightOddsToCSV({ data, oddType });
  }
  if (oddDataType === ODD_DATA_TYPES.PROP_BETS) {
    result = convertPropBetOddsToCSV({ data, oddType });
  }
  if (oddDataType === ODD_DATA_TYPES.HEAD_TO_HEAD) {
    result = convertHeadToHeadOddsToCSV({ data, oddType });
  }

  if ([ODD_DATA_TYPES.EXACTAS_EVENT, ODD_DATA_TYPES.EXACTAS_HEAT].includes(oddDataType)) {
    result = convertExactasEventOddsToCSV({ data, oddType });
  }

  return result;
};
