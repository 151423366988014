import { useQuery } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import keys from '@/constants/queryKeys';
import { SHOW_OR_PODIUM } from '@/constants/showOrPodium';

import { ShowOddsDTO } from '@/types/odds/getOdds';

interface FETCH_SHOW_ODDS {
  sport: string;
  eventId: string;
  showOrPodium?: string;
}

const getShowOdds = async ({ sport, eventId }: FETCH_SHOW_ODDS): Promise<ShowOddsDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/shows/odds`,
  });
  return data;
};

export default function useFetchShowOdds({ sport, eventId, showOrPodium }: FETCH_SHOW_ODDS) {
  return useQuery(
    [keys.odds.fetchShowOdds, sport, eventId],
    () => getShowOdds({ sport, eventId }),
    {
      staleTime: Infinity,
      enabled: !!eventId && showOrPodium === SHOW_OR_PODIUM.SHOW,
    },
  );
}
