export const FUTURE_ODD_TAB_LABELS = {
  winner: 'Winner',
  top2: 'Finalists',
  top3: 'Top 3',
  top5: 'Top 5',
  top10: 'Top 10',
  makeCut: 'Make Cut',
  makePlayOdds: 'Make Play Odds',
};

export const FUTURE_TYPES = {
  winner: 'winner',
  top2: 'top2',
  top3: 'top3',
  top5: 'top5',
  top10: 'top10',
  makeCut: 'makeCut',
  makePlayOdds: 'makePlayOdds',
};

const FUTURE_TYPES_MAP = new Map([
  [FUTURE_TYPES.winner, FUTURE_TYPES.winner],
  [FUTURE_TYPES.top2, FUTURE_TYPES.top2],
  [FUTURE_TYPES.top3, FUTURE_TYPES.top3],
  [FUTURE_TYPES.top5, FUTURE_TYPES.top5],
  [FUTURE_TYPES.top10, FUTURE_TYPES.top10],
  [FUTURE_TYPES.makeCut, FUTURE_TYPES.makeCut],
  [FUTURE_TYPES.makePlayOdds, FUTURE_TYPES.makePlayOdds],
]);

export const FUTURE_TYPES_ORDER = Array.from(FUTURE_TYPES_MAP.keys());

export const FUTURE_STATUS = {
  LIVE: 1,
  UPCOMING: 2,
  COMPLETED: 3,
  CANCELED: 4,
  POSTPONED: 5,
  NEXT: 6,
  IN_WINDOW: 7,
};

export const FUTURE_STATUS_VALUES = {
  1: 'LIVE',
  2: 'UPCOMING',
  3: 'COMPLETED',
  4: 'CANCELED',
  5: 'POSTPONED',
  6: 'NEXT',
  7: 'IN_WINDOW',
};
