import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DialogActions, Button } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
  invalidateCachedSportTourFutures,
  invalidateCachedSportFutures,
} from '@/helpers/cachedQueries';
import useUpdateFuturesEventDate from '@/hooks/futures/sports/useUpdateFuturesEventDate';

import keys from '@/constants/queryKeys';
import { DEFAULT_TIMEZONE } from '@/constants/misc';

import { SportTourFutures } from '@/types/futures';

import { displayInPT as format } from '@/helpers/timeConverters';

interface FutureMarketToggleProps {
  sportType: string;
  tourYearId: string;
}

function CustomActionBar(
  props: PickersActionBarProps & {
    updateFuturesEventDateHandler: () => void;
  },
) {
  const { onAccept, onCancel, actions, className, updateFuturesEventDateHandler } = props;

  if (actions == null || actions.length === 0) {
    return null;
  }

  return (
    <DialogActions className={className}>
      <Button id="cancelBtn" onClick={onCancel}>
        {' '}
        Cancel{' '}
      </Button>
      <Button
        id="saveEventDate"
        onClick={async () => {
          onAccept();
          updateFuturesEventDateHandler();
        }}
      >
        Save
      </Button>
    </DialogActions>
  );
}

export default function FutureMarketDateTimePicker({
  sportType,
  tourYearId,
}: FutureMarketToggleProps) {
  const queryClient = useQueryClient();
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [eventDate, setEventDate] = React.useState<Date | null>(null);

  const { mutate: updateFuturesEventDate } = useUpdateFuturesEventDate();

  const sportTourFutures: SportTourFutures | undefined = queryClient.getQueryData([
    keys.futures.fetchSportTourFutures,
    sportType,
    tourYearId,
    'SportTourFutures',
  ]);

  React.useEffect(() => {
    if (sportTourFutures?.nextEventStartDate) {
      // using PT here because the date returned by the server is always in UTC
      // and the user wants to see it in PT
      setEventDate(format(sportTourFutures?.nextEventStartDate, undefined, true) as Date);
    }
  }, [sportTourFutures]);

  const updateFuturesEventDateHandler = () => {
    setIsUpdating(true);
    updateFuturesEventDate(
      {
        sportType,
        tourYearId,
        // converting it to UTC because when the date picker returns the date, it returns it in the local usertimezone
        // but in this case, we expect the user to enter the date in the PST time zone so we tell date fns that the date
        // receievd is in PST and then convert it to UTC so when the date is received back from the server, we get UTC and reverse it
        eventDate: eventDate
          ? (zonedTimeToUtc(eventDate, DEFAULT_TIMEZONE) as unknown as string)
          : null,
      },
      {
        onSettled: (_, err) => {
          setIsUpdating(false);
          if (!err) {
            setIsUpdating(false);
            invalidateCachedSportFutures({ queryClient });
            invalidateCachedSportTourFutures({
              queryClient,
              sportType,
              tourYearId,
              fromComponent: 'SportTourFutures',
            });
          }
        },
      },
    );
  };

  return (
    <DateTimePicker
      label="Event Date"
      value={eventDate}
      disabled={isUpdating}
      onChange={(newValue) => setEventDate(newValue)}
      slots={{
        actionBar: (props) => (
          <CustomActionBar
            {...props}
            updateFuturesEventDateHandler={updateFuturesEventDateHandler}
          />
        ),
      }}
      slotProps={{
        textField: {
          id: 'chooseDateButton',
          onBlur: () => updateFuturesEventDateHandler(),
        },
      }}
    />
  );
}
