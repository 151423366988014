import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';
import ODDS_TYPES from '@/constants/oddsTypes';
import { PlayerHeadToHeadOddRow } from '@/types/playerHeadToHeadTab';

export const playerMatchUpOddsModifier = ({
  playerMatchUp,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  playerMatchUp: PlayerHeadToHeadOddRow;
  newHoldPercentage: number;
  oldHoldPercentage: number;
  defaultHoldPercentage?: number;
}) => {
  // if there are no odds then return an empty array
  if (!playerMatchUp) return {};

  // Get new probability for player 1
  const newProbabilityPlayer1 = oddsHelpers.getNewProbabilityAfterEdit({
    probability: playerMatchUp?.eventParticipant1?.probability,
    newHoldPercentage,
    oldHoldPercentage,
    defaultHoldPercentage,
  });
  // get new odds for  player 1
  const newOddsPlayer1 = oddsHelpers.getDecimalOddsFromProbability(+newProbabilityPlayer1);

  // Get new probability for player 2
  const newProbabilityPlayer2 = oddsHelpers.getNewProbabilityAfterEdit({
    probability: playerMatchUp?.eventParticipant2?.probability,
    newHoldPercentage,
    oldHoldPercentage,
    defaultHoldPercentage,
  });
  // get new odds for  player 2
  const newOddsPlayer2 = oddsHelpers.getDecimalOddsFromProbability(+newProbabilityPlayer2);

  return {
    ...playerMatchUp,
    // newWinnerId: playerMatchUp.winnerParticipantId || '',
    // editedVoid: playerMatchUp.voided || false,
    holdingPercentage: +newHoldPercentage,
    eventParticipant1: {
      ...playerMatchUp?.eventParticipant1,
      position: playerMatchUp?.eventParticipant1?.position || '--',
      eventParticipantId: playerMatchUp?.eventParticipant1?.id || '',
      athleteId: playerMatchUp?.eventParticipant1?.athlete?.id || '',
      label: [
        playerMatchUp?.eventParticipant1?.athlete?.firstName,
        playerMatchUp?.eventParticipant1?.athlete?.middleName,
        playerMatchUp?.eventParticipant1?.athlete?.lastName,
      ]
        .filter(Boolean)
        .join(' '),
      decimalOdds: oddsHelpers.getDecimalOdds(newOddsPlayer1).toString(),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOddsPlayer1).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(newProbabilityPlayer1) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbabilityPlayer1)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(newProbabilityPlayer1)}`,
      probability: round(newProbabilityPlayer1, 2),
      odds: newOddsPlayer1,
      // hasModifiedProbability: false,
    },
    eventParticipant2: {
      ...playerMatchUp?.eventParticipant2,
      position: playerMatchUp?.eventParticipant2?.position || '--',
      eventParticipantId: playerMatchUp?.eventParticipant2?.id || '',
      athleteId: playerMatchUp?.eventParticipant2?.athlete?.id || '',
      label: [
        playerMatchUp?.eventParticipant2?.athlete?.firstName,
        playerMatchUp?.eventParticipant2?.athlete?.middleName,
        playerMatchUp?.eventParticipant2?.athlete?.lastName,
      ]
        .filter(Boolean)
        .join(' '),
      decimalOdds: oddsHelpers.getDecimalOdds(newOddsPlayer2).toString(),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOddsPlayer2).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(newProbabilityPlayer2) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbabilityPlayer2)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(newProbabilityPlayer2)}`,
      probability: round(newProbabilityPlayer2, 2),
      odds: newOddsPlayer2,
      // hasModifiedProbability: false,
    },
  };
};

export const editMatchUpRowProbabilityFormatter = (
  newProbability: number,
  matchUp: any,
  {
    isPlayer1Modified = false,
    isPlayer2Modified = false,
  }: { isPlayer1Modified?: boolean; isPlayer2Modified?: boolean },
) => {
  // Get Player 1 probability
  const newPlayer1Probability = isPlayer1Modified
    ? +newProbability
    : +matchUp.holdingPercentage - +newProbability;
  // Get Player 2 probability
  const newPlayer2Probability = isPlayer2Modified
    ? +newProbability
    : +matchUp.holdingPercentage - +newProbability;

  // Get Player 1 normalized probability
  const normalizedProbabilityPlayer1 =
    newPlayer1Probability <= 0 || newPlayer1Probability === +matchUp.holdingPercentage
      ? 0
      : newPlayer1Probability;
  // Get Player 2 normalized probability
  const normalizedProbabilityPlayer2 =
    newPlayer2Probability <= 0 || newPlayer2Probability === +matchUp.holdingPercentage
      ? 0
      : newPlayer2Probability;

  const newOddsPlayer1 = oddsHelpers.getDecimalOddsFromProbability(normalizedProbabilityPlayer1);
  const newOddsPlayer2 = oddsHelpers.getDecimalOddsFromProbability(normalizedProbabilityPlayer2);

  return {
    ...matchUp,
    newWinnerId: matchUp.winnerParticipantId || '',
    editedVoid: matchUp.voided || false,
    eventParticipant1: {
      ...matchUp?.eventParticipant1,
      decimalOdds: oddsHelpers.getDecimalOdds(newOddsPlayer1).toString(),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOddsPlayer1).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer1) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer1)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer1)}`,
      probability: round(normalizedProbabilityPlayer1, 2),
      odds: newOddsPlayer1,
      hasModifiedProbability: true,
    },
    eventParticipant2: {
      ...matchUp?.eventParticipant2,
      decimalOdds: oddsHelpers.getDecimalOdds(newOddsPlayer2).toString(),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOddsPlayer2).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer2) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer2)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer2)}`,
      probability: round(normalizedProbabilityPlayer2, 2),
      odds: newOddsPlayer2,
      hasModifiedProbability: true,
    },
  };
};

export const editMatchUpRowOddsFormatter = (
  newOdd: number,
  matchUp: any,
  {
    isPlayer1Modified = false,
    isPlayer2Modified = false,
    oddsType = ODDS_TYPES.DECIMAL,
  }: { isPlayer1Modified?: boolean; isPlayer2Modified?: boolean; oddsType: string },
) => {
  // Convert to decimal odds
  const convertedOdds =
    oddsType === ODDS_TYPES.DECIMAL
      ? oddsHelpers.getDecimalOdds(newOdd)
      : oddsHelpers.getDecimalOddsFromOtherTypes(oddsType, newOdd);
  // Get american decimal odds
  // const americanDecimalOdds = Math.trunc(oddsHelpers.getProbabilityFromDecimalOdds(convertedOdds));
  // Get probability from decimal odds
  const newProbability =
    oddsType === ODDS_TYPES.AMERICAN
      ? round(oddsHelpers.getProbabilityFromDecimalOdds(convertedOdds), 1)
      : oddsHelpers.getProbabilityFromDecimalOdds(convertedOdds);
  //  oddsType === ODDS_TYPES.AMERICAN
  //   ? americanDecimalOdds
  //   :
  const convertedOddTypes = {
    decimalOdds: oddsType === ODDS_TYPES.DECIMAL ? newOdd : convertedOdds,
    fractionalOdds:
      oddsType === ODDS_TYPES.FRACTIONAL
        ? newOdd.toString()
        : oddsHelpers.getFractionalOdds(convertedOdds).toString(),
    americanOdds:
      oddsType !== ODDS_TYPES.AMERICAN
        ? oddsHelpers.getAmericanOddsFromProbability(newProbability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`
          : oddsHelpers.getAmericanOddsFromProbability(newProbability)
        : newOdd > 0
        ? `+${newOdd}`
        : `${newOdd}`,
    odds: convertedOdds,
  };

  // Get Player 1 probability
  const newPlayer1Probability = isPlayer1Modified
    ? +newProbability
    : +matchUp.holdingPercentage - +newProbability;
  // Get Player 2 probability
  const newPlayer2Probability = isPlayer2Modified
    ? +newProbability
    : +matchUp.holdingPercentage - +newProbability;

  // Get Player 1 normalized probability
  const normalizedProbabilityPlayer1 =
    newPlayer1Probability < 0
      ? 0
      : newPlayer1Probability === Infinity
      ? +matchUp.holdingPercentage
      : newPlayer1Probability;
  // Get Player 2 normalized probability
  const normalizedProbabilityPlayer2 =
    newPlayer2Probability < 0
      ? 0
      : newPlayer2Probability === Infinity
      ? +matchUp.holdingPercentage
      : newPlayer2Probability;

  const newOddsPlayer1 = oddsHelpers.getDecimalOddsFromProbability(normalizedProbabilityPlayer1);
  const newOddsPlayer2 = oddsHelpers.getDecimalOddsFromProbability(normalizedProbabilityPlayer2);

  return {
    ...matchUp,
    // newWinnerId: matchUp.winnerParticipantId || '',
    // editedVoid: matchUp.voided || false,
    eventParticipant1: {
      ...matchUp?.eventParticipant1,
      probability: round(normalizedProbabilityPlayer1, 2),
      ...(isPlayer1Modified
        ? convertedOddTypes
        : {
            decimalOdds: oddsHelpers.getDecimalOdds(newOddsPlayer1).toString(),
            fractionalOdds: oddsHelpers.getFractionalOdds(newOddsPlayer1).toString(),
            americanOdds:
              oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer1) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer1)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer1)}`,
            probability: round(normalizedProbabilityPlayer1, 2),
            odds: newOddsPlayer1,
          }),
      hasModifiedProbability: true,
    },
    eventParticipant2: {
      ...matchUp?.eventParticipant2,
      probability: round(normalizedProbabilityPlayer2, 2),
      ...(isPlayer2Modified
        ? convertedOddTypes
        : {
            decimalOdds: oddsHelpers.getDecimalOdds(newOddsPlayer2).toString(),
            fractionalOdds: oddsHelpers.getFractionalOdds(newOddsPlayer2).toString(),
            americanOdds:
              oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer2) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer2)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityPlayer2)}`,
            probability: round(normalizedProbabilityPlayer2, 2),
            odds: newOddsPlayer2,
          }),
      hasModifiedProbability: true,
    },
  };
};
