import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface SportTourFuturesToggleProps {
  sportType: string;
  tourYearId: string;
  isEnabled: boolean;
}

const toggleSportTourFutures = async (requestPayload: SportTourFuturesToggleProps) => {
  const { sportType, tourYearId, isEnabled } = requestPayload;
  await authenticatedAxios({
    method: 'PUT',
    url: `/admin/${sportType}/futures/tour/${tourYearId}/open`,
    data: { isMarketOpen: isEnabled },
  });

  return {};
};

export default function useToggleSportTourFutures() {
  return useMutation((requestPayload: SportTourFuturesToggleProps) =>
    toggleSportTourFutures(requestPayload),
  );
}
