import parseISO from 'date-fns/parseISO';

import round from 'lodash.round';

import {
  exportToExcel,
  customFileNameFormatter,
  FORMAT_TYPE,
  excelOddsConverter,
  //   getSortedPublishTimesArr,
  DEFAULT_PROBABILITY,
} from './excelOddHelpers';

import { SPORT_NAMES } from '@/constants/sportTypes';

import { EventStatusKeys, EventStatusNames } from '@/constants/events';

import ODDS_TYPES from '@/constants/oddsTypes';

import { teamTypeToKey } from '@/features/scores/JAIALAI/components/Markets/constants';

import { MASLBetTypes, MASLBetTypesValues, MASLSubMarketTypes } from '@/constants/masl';
// import { JABetTypes, JABetTypesValues, JAMarketTypes, JASubMarketTypes } from '@/constants/jaialai';

import { displayInPT as format } from './timeConverters';

const getExcelExportFileNameGenerator = ({ oddsExportData, eventInfo, title }: any) => {
  const eventYear = eventInfo?.year || '';
  const sportName = SPORT_NAMES[eventInfo?.sportType]
    ? customFileNameFormatter(SPORT_NAMES[eventInfo.sportType], FORMAT_TYPE._START)
    : '';
  const tourOrLeagueName = customFileNameFormatter(
    eventInfo?.tour?.name || eventInfo?.league?.name || '',
    FORMAT_TYPE._START,
  );

  const eventName = customFileNameFormatter(oddsExportData?.name, FORMAT_TYPE._START);
  const oddTabName = title ? customFileNameFormatter(title, FORMAT_TYPE._START) : '';

  const publishTime = customFileNameFormatter(eventInfo.traderUpdatedAtDate, FORMAT_TYPE._START);
  // Concatenate non-empty values
  return `${[eventYear, sportName, tourOrLeagueName, eventName, oddTabName, publishTime]
    .filter(Boolean)
    .join('')}.xlsx`;
};

/* Returns the event info table section of an excel export as an array of arrays */
const getExcelEventInfoTable = ({ eventInfo }: any) => {
  const {
    id: eventId,
    startDate,
    endDate,
    sportType,
    tour,
    year,
    eventNumber,
    eventStatus,
  } = eventInfo;

  const formattedStartDate = startDate ? format(parseISO(startDate), 'MM-dd-yyyy hh:mm a') : '';
  const formattedEndDate = endDate ? format(parseISO(endDate), 'MM-dd-yyyy hh:mm a') : '';

  const eventInfoTable = [
    ['Event ID:', eventId],
    ['Sport:', SPORT_NAMES[sportType]],
    ['Tour/League:', tour?.name || ''],
    ['Season:', year?.toString()],
    ['Stop Number:', eventNumber?.toString() || ''],
    ['Start Date:', formattedStartDate],
    ['End Date:', formattedEndDate],
    ['Event Status:', EventStatusKeys[eventStatus]?.replaceAll('_', ' ') || ''],
    [''],
  ];

  return eventInfoTable;
};

const getBetTypeHeaders = ({ betType, oddsLabel }: { betType: number; oddsLabel: string }) => {
  switch (betType) {
    case MASLBetTypes.EVENT_WINNER:
      return [
        `Moneyline ${oddsLabel}`,
        'Spread',
        `Spread ${oddsLabel}`,
        'Total',
        `Total ${oddsLabel}`,
      ];
    case MASLBetTypes.TOTALS:
      return ['Home Total', `Home Total ${oddsLabel}`, 'Away Total', `Away Total ${oddsLabel}`];
    default:
      return [
        `Moneyline ${oddsLabel}`,
        'Spread',
        `Spread ${oddsLabel}`,
        'Total',
        `Total ${oddsLabel}`,
      ];
  }
};

const getSubMarketOdds = ({ betType, subMarkets, teamType, oddTypeSelected }: any) => {
  switch (betType) {
    case MASLBetTypes.EVENT_WINNER:
      return [
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.MONEY_LINE]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.MONEY_LINE]?.[teamType]?.probability,
        }) || '0',
        `${subMarkets?.[MASLSubMarketTypes.SPREAD]?.[teamType]?.calculatedValue || '0'}`,
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.SPREAD]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.SPREAD]?.[teamType]?.probability,
        }) || '',
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[MASLSubMarketTypes.TOTAL]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.TOTAL]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.TOTAL]?.[teamType]?.probability,
        }) || '',
      ];
    case MASLBetTypes.TOTALS:
      return [
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[MASLSubMarketTypes.HOME_TOTAL]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.HOME_TOTAL]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.HOME_TOTAL]?.[teamType]?.probability,
        }) || '',
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[MASLSubMarketTypes.AWAY_TOTAL]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.AWAY_TOTAL]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.AWAY_TOTAL]?.[teamType]?.probability,
        }) || '',
      ];

    default:
      return [
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.MONEY_LINE]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.MONEY_LINE]?.[teamType]?.probability,
        }) || '0',
        `${subMarkets?.[MASLSubMarketTypes.SPREAD]?.[teamType]?.calculatedValue || '0'}`,
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.SPREAD]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.SPREAD]?.[teamType]?.probability,
        }) || '',
        teamType === teamTypeToKey.homeTeam
          ? `${subMarkets?.[MASLSubMarketTypes.TOTAL]?.[teamType]?.calculatedValue || '0'}`
          : '',
        excelOddsConverter({
          oddTypeSelected,
          odds: subMarkets?.[MASLSubMarketTypes.TOTAL]?.[teamType]?.odds,
          probability: subMarkets?.[MASLSubMarketTypes.TOTAL]?.[teamType]?.probability,
        }) || '',
      ];
  }
};

const getOddData = ({
  oddsLabel,
  oddTypeSelected,
  subMarkets,
  currentSubMarket,
  betTypeKey,
  homeTeam,
  awayTeam,
}: any) => {
  const headers: any = [];
  const homeTeamOdds: any = [];
  const awayTeamOdds: any = [];

  headers.push(...['Team', ...getBetTypeHeaders({ betType: +betTypeKey, oddsLabel })]);
  homeTeamOdds.push(
    ...[
      homeTeam?.team?.name || '',
      ...getSubMarketOdds({
        betType: +betTypeKey,
        subMarkets,
        teamType: teamTypeToKey.homeTeam,
        oddTypeSelected,
        currentSubMarket,
      }),
    ],
  );
  awayTeamOdds.push(
    ...[
      awayTeam?.team?.name || '',
      ...getSubMarketOdds({
        betType: +betTypeKey,
        subMarkets,
        teamType: teamTypeToKey.awayTeam,
        oddTypeSelected,
        currentSubMarket,
      }),
    ],
  );

  return { headers, homeTeamOdds, awayTeamOdds };
};

export const allMarketOdds = ({
  oddTypeSelected = ODDS_TYPES.AMERICAN,
  oddsExportData,
  matchData,
}: any) => {
  const MARKETS_ARRAY: any = [];
  const MARKET_DATA: any = {};

  const oddsLabel =
    oddTypeSelected === ODDS_TYPES.DECIMAL
      ? 'Decimal Odds'
      : oddTypeSelected === ODDS_TYPES.FRACTIONAL
      ? 'Fractional Odds'
      : 'American Odds';

  const gradeStatus =
    EventStatusKeys[matchData.eventStatus] === EventStatusNames.COMPLETED ? 'Settled' : 'Ungraded';

  MARKETS_ARRAY.push(['']);
  MARKETS_ARRAY.push(['Match Number:', `${matchData.eventNumber || 0}`]);
  MARKETS_ARRAY.push(['Grading Status:', gradeStatus]);
  MARKETS_ARRAY.push(['']);

  oddsExportData.forEach((row: any) => {
    const { marketType, betType, subMarketType, team } = row;

    const teamKey = team?.isHomeTeam ? teamTypeToKey.homeTeam : teamTypeToKey.awayTeam;
    const otherTeamKey =
      teamKey === teamTypeToKey.homeTeam ? teamTypeToKey.awayTeam : teamTypeToKey.homeTeam;

    MARKET_DATA[marketType] ??= {};

    MARKET_DATA[marketType][betType] ??= {};

    MARKET_DATA[marketType][betType][subMarketType] ??= {};
    if (
      !MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey] &&
      !MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]
    ) {
      MARKET_DATA[marketType][betType][subMarketType][teamKey] = row;
    }
    if (
      MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey] &&
      !MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]
    ) {
      const otherTeamSubMarketType =
        MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey]?.subMarketType;
      if (otherTeamSubMarketType === subMarketType) {
        MARKET_DATA[marketType][betType][subMarketType][teamKey] = row;
      }
    }

    if (
      MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey] &&
      MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]
    ) {
      if (!MARKET_DATA[marketType][betType].margin) {
        const currentTeamProbability = round(
          MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[teamKey]?.probability || 0,
          2,
        );
        const otherTeamProbability = round(
          MARKET_DATA?.[marketType]?.[betType]?.[subMarketType]?.[otherTeamKey]?.probability || 0,
          2,
        );
        MARKET_DATA[marketType][betType].margin = currentTeamProbability + otherTeamProbability;
      }
    }
  });

  Object.values(MARKET_DATA).forEach((betTypeVal: any) => {
    Object.keys(betTypeVal).forEach((betTypeKey) => {
      const { margin, ...subMarkets } = betTypeVal[betTypeKey];
      MARKETS_ARRAY.push([
        'Match Market:',
        betTypeKey.includes('MATCH')
          ? betTypeKey
          : MASLBetTypesValues[betTypeKey as keyof {}] || '',
      ]);
      MARKETS_ARRAY.push(['Grading Status:', 'Ungraded']);

      MARKETS_ARRAY.push(['Margin:', `${round(margin - DEFAULT_PROBABILITY || 0, 2)}%`]);

      const [firstSubMarket]: any = Object.keys(subMarkets);
      const [firstSubMarketVal]: any = Object.values(subMarkets);
      const { headers, homeTeamOdds, awayTeamOdds } = getOddData({
        oddsLabel,
        oddTypeSelected,
        subMarkets,
        betTypeKey: +betTypeKey,
        currentSubMarket: firstSubMarket,
        homeTeam: firstSubMarketVal.homeTeam,
        awayTeam: firstSubMarketVal.awayTeam,
      });

      MARKETS_ARRAY.push(headers);
      MARKETS_ARRAY.push(homeTeamOdds);
      MARKETS_ARRAY.push(awayTeamOdds);

      MARKETS_ARRAY.push(['']);
    });
  });

  return MARKETS_ARRAY;
};

/* Excel downloader for  MASL */
export const maslExcelDownloader = ({
  matchData,
  oddsExportData,
  oddTypeSelected,
  eventInfo,
  title,
}: any) => {
  const excelFileName = getExcelExportFileNameGenerator({
    oddsExportData: matchData,
    eventInfo,
    title,
  });
  const excelEventTable = getExcelEventInfoTable({ eventInfo });

  const allMarketData = allMarketOdds({ matchData, oddsExportData, oddTypeSelected });

  const excelAoA = [...excelEventTable, ...allMarketData];

  exportToExcel({ excelAoA, excelFileName, title });
};
