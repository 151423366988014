import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface DELETE_EVENT {
  sport: string;
  eventId: string;
}

const deleteEvent = async ({ sport, eventId }: DELETE_EVENT) => {
  const { data } = await authenticatedAxios({
    method: 'DELETE',
    url: `admin/traders/${sport}/event/${eventId}/new`,
  });

  return data;
};

export default function useDeleteEvent() {
  return useMutation(({ sport, eventId }: DELETE_EVENT) => deleteEvent({ sport, eventId }));
}
