import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { UpdateFutureOddsPayloadDTO } from '@/types/helpers/futureOdds';

interface UPDATE_FUTURE_ODDS {
  sportType: string;
  tourYearId: string;
  futureType: string;
  payload: UpdateFutureOddsPayloadDTO;
}

const updateFutureOdds = async ({
  sportType,
  tourYearId,
  // futureType,
  payload,
}: UPDATE_FUTURE_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sportType}/futures/${tourYearId}/odds`,
    data: payload,
  });
  return data;
};

export default function useUpdateFutureOdds() {
  return useMutation(({ sportType, tourYearId, futureType, payload }: UPDATE_FUTURE_ODDS) =>
    updateFutureOdds({ sportType, tourYearId, futureType, payload }),
  );
}
