import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  IconButton,
  Box,
  Autocomplete,
  LinearProgress,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { enqueueSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';
// import { parsePhoneNumber } from 'libphonenumber-js';

import { editCellTextFieldStyle, headToHeadTableStyles, tableCellStyle } from '@/utils/TableMisc';

import useAddOrUpdateUser from '@/hooks/user/useAddOrUpdateUser';

import { useQueryClient } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { ROLE_OPTIONS } from '@/constants/user';
import { SPORT_NAMES, sportOptions } from '@/constants/sportTypes';
// import keys from '@/constants/queryKeys';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '1.5rem 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1.5rem 1.5rem 3rem',
  },
}));

const FormLabel = styled(Typography)<{ component?: string }>({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

interface CustomLinearProgressProps {
  strength: number;
}

const CustomLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'strength',
})<CustomLinearProgressProps>(({ strength }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: '#d8d8d8',
  '& .MuiLinearProgress-bar': {
    borderRadius: 5,
    backgroundColor: strength >= 4 ? '#4caf50' : strength >= 3 ? '#ff9800' : '#f44336',
  },
}));

interface IUser {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  password: string;
  phone: string;
  userType: string;
  userRestrictedSports: string[];
  id?: string;
}

type AddOrEditUserModalProps = {
  open: boolean;
  handleClose: () => void;
  user?: Partial<IUser>;
};

const ALL = 'all';
const ALL_OPTION = { label: 'All', value: ALL };

const passwordStrength = (password: string) => {
  let strength = 0;
  if (password.length >= 8) strength += 1;
  if (/[A-Z]/.test(password)) strength += 1;
  if (/[a-z]/.test(password)) strength += 1;
  if (/[0-9]/.test(password)) strength += 1;
  if (/[@$!%*?&]/.test(password)) strength += 1;
  return strength;
};

const AddOrEditUserModal = (props: AddOrEditUserModalProps) => {
  const { open, handleClose, user } = props;

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const queryClient = useQueryClient();

  const { mutateAsync: addOrUpdateUser } = useAddOrUpdateUser();

  const initialValues = {
    firstName: user?.firstName || '',
    // middleName: user?.middleName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
    phone: user?.phone || '',
    password: user?.password || '',
    userType: user?.userType || null,
    userRestrictedSports: user?.userRestrictedSports
      ? [
          ...(user.userRestrictedSports.length === sportOptions.length ? [ALL_OPTION] : []),
          ...user.userRestrictedSports.map((userRestrictedSportsOpt) => ({
            label: SPORT_NAMES[userRestrictedSportsOpt] || '',
            value: userRestrictedSportsOpt,
          })),
        ]
      : [],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().required('Email is required').email('Invalid email address'),
      password: user
        ? Yup.string()
        : Yup.string()
            .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
            .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[@$!%*?&]/, 'Password must contain at least one special character')
            .min(8, 'Password must be at least 8 characters long')
            .test(
              'strength',
              'Password is too weak',
              (value) => passwordStrength(value as string) >= 3,
            )
            .required('Password is required'),
      userType: Yup.string().required('Role is required'),
      // phone: Yup.string().test('is-valid-phone-number', 'Invalid phone number', (value) => {
      //   if (!value) return false;
      //   const phoneNumber = parsePhoneNumber(value);
      //   return phoneNumber ? phoneNumber.isPossible() : false;
      // }),
    }),
    onSubmit: async (_, { setSubmitting }) => {
      try {
        const requestPayload = formatPayload();
        addOrUpdateUser(
          { data: requestPayload, id: user?.id || '' },
          {
            onSettled: (_, err) => {
              if (!err) {
                enqueueSnackbar(`${user ? 'Updated' : 'Added'} user successfully!`);
                queryClient.invalidateQueries([keys.userManagement.fetchUserList]);
              }
              handleClose();
            },
          },
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = formik;

  const formatPayload = () => {
    const payload = {
      firstName: values?.firstName,
      // middleName: values?.middleName,
      lastName: values?.lastName,
      email: values?.email,
      password: values?.password,
      phone: values?.phone,
      userType: values?.userType,
      userRestrictedSports: values?.userRestrictedSports
        ?.filter((sport: any) => sport?.value != ALL)
        ?.map((sport: any) => sport?.value),
    };
    return payload;
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            px: '1.3rem',
            py: 0.5,
            borderBottom: '1px solid #DEE2E6',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,

              fontSize: '1.25rem',
              lineHeight: '1.5rem',
            }}
            component="h2"
          >
            {user ? 'Edit user' : 'Add user'}
          </Typography>
          <IconButton onClick={handleClose} disableRipple disabled={isSubmitting}>
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container sx={{ gap: '1rem' }}>
            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  First Name{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.firstName && touched?.firstName)}
                  helperText={errors?.firstName && touched?.firstName ? errors?.firstName : ''}
                />
              </Grid>
            </Grid>

            {/* <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Middle Name{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="middleName"
                  value={values.middleName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  // error={!!(errors?.middleName && touched?.middleName)}
                  // helperText={errors?.middleName && touched?.middleName ? errors?.middleName : ''}
                />
              </Grid>
            </Grid> */}

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Last Name{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.lastName && touched?.lastName)}
                  helperText={errors?.lastName && touched?.lastName ? errors?.lastName : ''}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Email{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.email && touched?.email)}
                  helperText={errors?.email && touched?.email ? errors?.email : ''}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Password{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.password && touched?.password)}
                  helperText={errors?.password && touched?.password ? errors?.password : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box mt={1}>
                  <Typography mb={0.5} sx={tableCellStyle}>
                    Password Strength:
                  </Typography>
                  <CustomLinearProgress
                    variant="determinate"
                    value={(passwordStrength(values.password) / 5) * 100}
                    strength={passwordStrength(values.password)}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Phone Number{' '}
                  <FormLabel component={'span'} sx={{ color: '#6C757D' }}>
                    (optional)
                  </FormLabel>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.phone && touched?.phone)}
                  helperText={errors?.phone && touched?.phone ? errors?.phone : ''}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Role{' '}
                  <Typography component={'span'} color="red">
                    *
                  </Typography>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    name="userType"
                    value={values.userType}
                    onChange={(event: SelectChangeEvent<typeof values.userType>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('userType', value);
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                  >
                    {React.Children.toArray(
                      ROLE_OPTIONS?.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item xs={12} gap={1}>
              <Grid container alignItems={'center'} item xs={12}>
                <FormLabel>
                  Assigned sports{' '}
                  <FormLabel component={'span'} sx={{ color: '#6C757D' }}>
                    (optional)
                  </FormLabel>{' '}
                </FormLabel>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  value={values.userRestrictedSports}
                  multiple
                  options={[ALL_OPTION, ...sportOptions]}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onChange={(_, value) => {
                    const hasAllOption = Boolean(value.find((item) => item.value === ALL));
                    const isAllOptionAlreadySelected = Boolean(
                      values.userRestrictedSports.find((item) => item.value === ALL),
                    );
                    if (hasAllOption) {
                      // Remove select all option if one of the sports is unselected when select all option is selected
                      if (isAllOptionAlreadySelected) {
                        return setFieldValue(
                          'userRestrictedSports',
                          value.filter((sportOpt) => sportOpt?.value !== ALL),
                        );
                      }
                      // If "All" is selected, select all options
                      return setFieldValue('userRestrictedSports', [ALL_OPTION, ...sportOptions]);
                    }
                    // Handle Unselect All
                    if (value.length === sportOptions.length && isAllOptionAlreadySelected) {
                      return setFieldValue('userRestrictedSports', []);
                    }
                    // Handle Manual select All when all sports are selected
                    if (value.length === sportOptions.length && !hasAllOption) {
                      return setFieldValue('userRestrictedSports', [ALL_OPTION, ...sportOptions]);
                    }
                    return setFieldValue('userRestrictedSports', value);
                  }}
                  fullWidth
                  sx={{
                    ...editCellTextFieldStyle,
                    '& .MuiInputBase-input': {
                      padding: '0.32rem 0.75rem',
                      fontSize: '0.812rem',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // error={!!errors?.userRestrictedSports && touched?.userRestrictedSports}
                      // helperText={errors?.userRestrictedSports && touched?.userRestrictedSports ? errors?.userRestrictedSports : undefined}
                    />
                  )}
                />
                <FormHelperText>{'Leave blank for all sports'}</FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                }}
                fullWidth
                disableElevation
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                fullWidth
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default AddOrEditUserModal;
