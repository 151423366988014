import React, { forwardRef } from 'react';
import { Icons } from '@material-table/core';
import { Box } from '@mui/material';

import Editicon from '@/images/icons/table-edit-icon.png';
import Deleteicon from '@/images/icons/table-delete-icon.png';
import Checkicon from '@/images/icons/table-check-icon.png';
import Closeicon from '@/images/icons/table-close-icon.png';

// styles for each table cell
export const tableCellStyle = {
  color: '#212529',
  // padding: '0.375rem 0.625rem',
  fontSize: '0.812rem',
  fontWeight: '400',
  // lineHeight: '2.38rem',
};

// styles for a textfield element when editing a row
export const editCellTextFieldStyle = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '0.25rem',
    '& fieldset': {
      border: '1px solid #CED4DA',
    },
    '&.Mui-focused fieldset': {
      borderWidth: '0.1rem',
      borderColor: '#333',
    },
    '&.Mui-error fieldset': {
      borderColor: '#f44336',
    },
  },
  '& .MuiInputBase-input': {
    padding: '0.542rem 0.75rem',
    fontSize: '0.812rem',
  },
};

// Styles for head to head table elements.
export const headToHeadTableStyles = {
  eventSeedColHeaderStyle: {
    minWidth: '7.3rem',
  },
  probabilityColHeaderStyle: {
    maxWidth: '5.8rem',
  },
  placeColHeaderStyle: {
    minWidth: '5.62rem',
    maxWidth: '5.62rem',
  },
  oddsColHeaderStyle: {
    maxWidth: '9rem',
    minWidth: '9rem',
  },
  athleteNameStyle: {
    color: '#212529',
    fontSize: '0.812rem',
    fontWeight: '400',
    padding: '0.375rem 0.5rem',
  },
  winnerIconsStyle: {
    width: '0.75rem',
    imageRendering: '-webkit-optimize-contrast',
  },
  serveIconsStyle: {
    width: '1.8rem',
    imageRendering: '-webkit-optimize-contrast',
  },
  athleteNameColHeaderStyle: {
    maxWidth: '14.53rem',
    minWidth: '14.53rem',
  },
  vsColHeaderStyle: {
    maxWidth: '4rem',
    minWidth: '4rem',
  },
  vsStyle: {
    borderRadius: '50%',
    backgroundColor: '#DEE2E6',
    fontWeight: '500',
    fontSize: '0.75rem',
    padding: '0.4rem',
    color: '#495057',
  },
  editCellTextFieldStyle: {
    border: '1px solid #CED4DA',
    borderRadius: '0.25rem',
    '& .MuiInputBase-input': {
      padding: '0.542rem 0.75rem',
      fontSize: '0.812rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
};

// Overriding table icons for edit, delete, check & clear with custom images to match design
export const tableIcons: Icons = {
  // eslint-disable-next-line react/display-name
  Edit: forwardRef((props, ref) => (
    <Box
      {...props}
      ref={ref}
      component="img"
      alt="edit icon"
      src={Editicon}
      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
    />
  )), // eslint-disable-next-line react/display-name
  Delete: forwardRef((props, ref) => (
    <Box
      {...props}
      ref={ref}
      component="img"
      alt="delete icon"
      src={Deleteicon}
      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
    />
  )), // eslint-disable-next-line react/display-name
  Check: forwardRef((props, ref) => (
    <Box
      {...props}
      ref={ref}
      component="img"
      alt="check icon"
      src={Checkicon}
      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
    />
  )), // eslint-disable-next-line react/display-name
  Clear: forwardRef((props, ref) => (
    <Box
      {...props}
      ref={ref}
      component="img"
      alt="close icon"
      src={Closeicon}
      sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
    />
  )),
};

export const createTablePageOptions = (listLength = 0, showAllRows = false) => {
  if (listLength <= 0) {
    return defaultTableOptions;
  }
  let pageSize = defaultTableOptions.pageSize;
  let pageSizeOptions = defaultTableOptions.pageSizeOptions;

  if (showAllRows) {
    pageSize = listLength;
    pageSizeOptions = [listLength, listLength * 2];
  } else if (listLength <= 3) {
    pageSize = 3;
    pageSizeOptions = [3, 6, 9];
  } else if (listLength > 3 && listLength <= 15) {
    pageSize = listLength;
    pageSizeOptions = [listLength, listLength * 2];
  } else if (listLength > 15 && listLength <= 50) {
    pageSize = listLength;
    pageSizeOptions = [listLength, listLength * 2, listLength * 3];
  } else if (listLength > 50 && listLength <= 100) {
    pageSize = 50;
    pageSizeOptions = [50, 75, 100];
  } else {
    pageSize = listLength;
    const midway = Math.round(listLength / 2);
    pageSizeOptions = [midway * 2, midway * 3, midway * 4, midway * 4];
  }
  return {
    pageSize,
    pageSizeOptions,
  };
};

export const defaultTableOptions = {
  pageSize: 5,
  pageSizeOptions: [5, 10, 15, 30, 50],
};
