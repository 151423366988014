import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface FuturesEventDateProps {
  sportType: string;
  tourYearId: string;
  eventDate: string | null;
}

const updateFuturesEventDate = async (requestPayload: FuturesEventDateProps) => {
  const { sportType, tourYearId, eventDate } = requestPayload;
  await authenticatedAxios({
    method: 'PUT',
    url: `/admin/${sportType}/futures/tour/${tourYearId}/eventDate`,
    data: { eventDate },
  });

  return {};
};

export default function useUpdateFuturesEventDate() {
  return useMutation((requestPayload: FuturesEventDateProps) =>
    updateFuturesEventDate(requestPayload),
  );
}
