import { HeatStatus } from '@/constants/heats';
import { RoundStatus } from '@/constants/rounds';
import { SKIP_SCORE_FORM_ROUND_STATUSES } from '@/constants/skipStatuses';
import { HeatDTO } from '@/types/heat';
import { RoundDTO } from '@/types/round';
import { sortHeatsByHeatNo } from './heats';

export const sortRoundsByRoundNo = (rounds: RoundDTO[]) => {
  if (!rounds || (rounds && rounds.length === 0)) return [];
  const modRounds = rounds.sort(
    (a: any, b: any) => Number(a?.roundNo || 0) - Number(b?.roundNo || 0),
  );
  return modRounds;
};

export const getRoundFromId = (roundId: string, rounds: RoundDTO[]) => {
  if (!roundId || rounds.length === 0) return null;
  return rounds.find((round) => round.id === roundId) || null;
};

export const getSelectedRound = (rounds: RoundDTO[]) => {
  const firstRound = rounds.find(
    (round: any) => !SKIP_SCORE_FORM_ROUND_STATUSES.includes(round.roundStatus),
  );
  if (firstRound) {
    return firstRound;
  } else {
    const [alternateFirstRound] = rounds;
    if (alternateFirstRound) {
      return alternateFirstRound;
    } else return null;
  }
};

export const getUpdatedRounds = (
  roundId: string,
  heatId: string,
  isSavingHeat: boolean,
  rounds: RoundDTO[],
  nextHeat: HeatDTO | null,
) => {
  // copy the existing rounds
  const newRoundList = rounds.slice();
  // Check if round exist
  const roundIdx = newRoundList.findIndex((round: any) => round?.id == roundId);
  if (roundIdx !== -1) {
    const round = newRoundList[roundIdx];
    // Check if heat exist
    const heatIdx = round.heats.findIndex((heat: any) => heat?.id == heatId);
    if (heatIdx !== -1) {
      const heat = round.heats[heatIdx];
      if (isSavingHeat) {
        if (round.roundStatus === RoundStatus.UPCOMING) round.roundStatus = RoundStatus.LIVE;
        if (heat.heatStatus === HeatStatus.UPCOMING) heat.heatStatus = HeatStatus.LIVE;
      } else {
        // assign newly updated athletes odds
        heat.heatStatus = HeatStatus.COMPLETED;
        if (!nextHeat) round.roundStatus = RoundStatus.COMPLETED;
        else if (round.roundStatus === RoundStatus.UPCOMING) round.roundStatus = RoundStatus.LIVE;
      }
      round.heats[heatIdx] = heat;
      const heats = round.heats;
      // sort the heats
      const sortedHeats = sortHeatsByHeatNo(heats);
      // assign to current round heats
      round.heats = sortedHeats;
      newRoundList[roundIdx] = round;
      // sort the rounds
      const sortedRounds = sortRoundsByRoundNo(newRoundList);
      return {
        updatedRounds: sortedRounds,
        updatedHeats: sortedHeats,
      };
    }
  }
  return {
    updatedRounds: null,
    updatedHeats: null,
  };
};
