import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface EventSeeds {
  sportType: string;
  payload: any;
}

const addOrUpdateEventSeeds = async (requestPayload: EventSeeds) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/traders/${requestPayload.sportType}/events/seeds`,
    data: requestPayload.payload,
  });

  return data;
};

export default function useAddOrUpdateEventSeeds() {
  return useMutation((requestPayload: EventSeeds) => addOrUpdateEventSeeds(requestPayload));
}
