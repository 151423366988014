import { useMutation } from '@tanstack/react-query';

import { WSLRoundAddDTO } from '@/types/wsl/round';
import { SLSRoundAddDTO } from '@/types/sls/round';
import { NRXRoundAddDTO } from '@/types/nrx/round';
import { SPRRoundAddDTO } from '@/types/spr/round';

import { authenticatedAxios } from '../';

interface ADD_ROUNDS {
  sport: string;
  requestPayload: {
    items: WSLRoundAddDTO | SLSRoundAddDTO | NRXRoundAddDTO | SPRRoundAddDTO;
  };
}

const addRounds = async ({ sport, requestPayload }: ADD_ROUNDS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/rounds`,
    data: requestPayload,
  });

  return data;
};

export default function useAddRounds() {
  return useMutation(({ sport, requestPayload }: ADD_ROUNDS) =>
    addRounds({ sport, requestPayload }),
  );
}
