import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Grid,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { zonedTimeToUtc } from 'date-fns-tz';
import { format as normalFormat } from 'date-fns';

// import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';

import useFetchEventScores from '@/hooks/events/useFetchEventScores';
import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';
import useUpdateBKFCScores from '@/hooks/bkfc/useUpdateBKFCScores';

import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';

import CustomEndFightDialog from '@/components/Dialogs/CustomEndFightDialog';

import { FightStatusKeys, FightStatusNames, FightStatusValues } from '@/constants/fights';

// import JudgeIcon from '@/images/icons/judge-icon.svg';

// import { data, athletesArray, weightOptions, decisionOptions } from './data';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionSummaryText,
  AccordionSummaryTextLg,
  processMatchStatusBadges,
  AccordionDtlsLabelText,
  ModSelect,
  ModTextField,
  // ModAutocomplete,
  CounterButton,
  CustomAthleteSelect,
  // JudgeScores,
  // ScoreLabels,
} from './components';
import SPORT_TYPES from '@/constants/sportTypes';
import { useParams } from 'react-router-dom';
import { DEFAULT_TIMEZONE, WIN_BY_OPTIONS_BKFC } from '@/constants/misc';

import { displayInPT as format } from '@/helpers/timeConverters';

import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { getFirstLettersCombined } from '@/helpers/misc';
import { headToHeadTableStyles } from '@/utils/TableMisc';
// import useFetchWeightClasses from '@/hooks/fightSportMisc/useFetchWeightClasses';

const ARCHIVED = 1;

const BLUE_CORNER_COLOR = '#3071E9';
const RED_CORNER_COLOR = '#ED4C47';

const BLUE_CORNER_WIN_BY = '#5E6BDC';
const BLUE_CORNER_TEXT_WIN_BY = '#5E6BDC';

const RED_CORNER_WIN_BY = '#AD1827';
const RED_CORNER_TEXT_WIN_BY = '#AD1827';

// const DEFAULT_JUDGE_OBJ = {
//   score1: 0,
//   score2: 0,
//   name: null,
// };

// const DEFAULT_FIGHT = {
//   id: '1',
//   blueCornerAthlete: null,
//   redCornerAthlete: null,
//   scheduledRounds: 0,
//   startDate: null,
//   endDate: null,
//   heatStatus: 2,
//   winner: null,
//   referee: null,
//   winBy: '',
//   notes: '',
//   weightClass: '',
//   judge1: DEFAULT_JUDGE_OBJ,
//   judge2: DEFAULT_JUDGE_OBJ,
//   judge3: DEFAULT_JUDGE_OBJ,
// };

const fightValidationSchema = Yup.object().shape({
  blueCornerAthlete: Yup.object()
    .shape({
      id: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .required('Blue Corner Athlete is required'),
  redCornerAthlete: Yup.object()
    .shape({
      id: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .required('Red Corner Athlete is required'),
});

const FightContainer = ({
  scr,
  idx,
  values,
  setFieldValue,
  errors,
  ATHLETE_OPTIONS,
  // WEIGHT_CLASSES,
  WIN_BY_OPTIONS_BKFC,
  FIGHT_STATUS_OPTIONS,
  handleBlur,
  handleSaveOrEndFight,
  accordion,
  setAccordion,
  matchSummaryDateFormatter,
  toggleCustomStartFightDialog,
  toggleCustomEndFightDialog,
}: any) => {
  const { redCornerAthlete, blueCornerAthlete } = React.useMemo(() => {
    const { redCornerAthlete: _redCornerAthlete, blueCornerAthlete: _blueCornerAthlete } =
      values?.scores[idx] || {};

    return { redCornerAthlete: _redCornerAthlete, blueCornerAthlete: _blueCornerAthlete };
  }, [values?.scores[idx]]);

  const WINNER_OPTIONS = React.useMemo(
    () => [blueCornerAthlete, redCornerAthlete].filter(Boolean),
    [redCornerAthlete, blueCornerAthlete],
  );
  return (
    <Accordion
      key={scr?.id || scr?.roundHeatId}
      expanded={!!accordion[scr?.id || scr?.roundHeatId]}
      onChange={() => {
        setAccordion((prevValue) => ({
          ...prevValue,
          [scr?.id || scr?.roundHeatId]: !prevValue[scr?.id || scr?.roundHeatId],
        }));
      }}
    >
      <FightSummary
        scr={scr}
        idx={idx}
        accordion={accordion}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
        matchSummaryDateFormatter={matchSummaryDateFormatter}
      />
      <FightDetails
        scr={scr}
        idx={idx}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        ATHLETE_OPTIONS={ATHLETE_OPTIONS}
        // WEIGHT_CLASSES={WEIGHT_CLASSES}
        WINNER_OPTIONS={WINNER_OPTIONS}
        WIN_BY_OPTIONS_BKFC={WIN_BY_OPTIONS_BKFC}
        FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
        handleBlur={handleBlur}
        handleSaveOrEndFight={handleSaveOrEndFight}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
        toggleCustomStartFightDialog={toggleCustomStartFightDialog}
        toggleCustomEndFightDialog={toggleCustomEndFightDialog}
      />
    </Accordion>
  );
};

const WinnerIcon = () => (
  <Box
    component="img"
    alt="winner icon"
    src={Winnerenabledicon}
    sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
  />
);

const FightSummary = ({
  scr,
  accordion,
  redCornerAthlete,
  blueCornerAthlete,
  matchSummaryDateFormatter,
}: any) => {
  const isAccordionExpanded = accordion[scr?.id || scr?.roundHeatId];
  const blueCorner = blueCornerAthlete && (
    <Box
      sx={{
        width: '100%',
        gridArea: 'blueCornerAthlete',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns:
          blueCornerAthlete?.id === scr?.winnerAthleteId ? '10fr 80fr 10fr' : '10fr 90fr',
        gap: '0.3rem',
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'inline-flex',
            borderRadius: '50%',
            height: '0.5rem',
            width: '0.5rem',
            backgroundColor: BLUE_CORNER_COLOR,
          }}
        />
      </Box>
      <AccordionSummaryText>{`${blueCornerAthlete?.label || '--'}`}</AccordionSummaryText>
      {blueCornerAthlete?.id === scr?.winnerAthleteId ? <WinnerIcon /> : <></>}
    </Box>
  );

  const redCorner = redCornerAthlete && (
    <Box
      sx={{
        width: '100%',
        gridArea: 'redCornerAthlete',
        justifyContent: 'end',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns:
          redCornerAthlete?.id === scr?.winnerAthleteId ? '10fr 80fr 10fr' : '90fr 10fr',
        gap: '0.3rem',
      }}
    >
      {redCornerAthlete?.id === scr?.winnerAthleteId ? <WinnerIcon /> : <></>}
      <AccordionSummaryText sx={{ textAlign: 'end' }}>
        {redCornerAthlete?.label || '--'}
      </AccordionSummaryText>
      <Box sx={{ display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'inline-flex',
            borderRadius: '50%',
            height: '0.5rem',
            width: '0.5rem',
            backgroundColor: RED_CORNER_COLOR,
          }}
        />
      </Box>
    </Box>
  );

  return (
    <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.2rem' }} />} sx={{ p: 2 }}>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateAreas: `"fightNo playerAndScrDtls matchDtls"`,
          gridTemplateColumns: isAccordionExpanded ? '30fr 20fr 50fr' : '10fr 40fr 50fr',
          gap: '1rem',
        }}
      >
        <Box sx={{ gridArea: 'fightNo', alignSelf: 'center' }}>
          {isAccordionExpanded ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
              <AccordionSummaryTextLg>{scr?.heatName || ''}</AccordionSummaryTextLg>{' '}
              {processMatchStatusBadges(scr?.heatStatus)}
            </Box>
          ) : (
            <AccordionSummaryText>{scr?.heatName || ''}</AccordionSummaryText>
          )}
        </Box>

        {!isAccordionExpanded && (blueCornerAthlete || redCornerAthlete) && (
          <Box
            sx={{
              gridArea: 'playerAndScrDtls',
              display: 'grid',
              gridTemplateAreas: `"blueCornerAthlete score redCornerAthlete"`,
              alignItems: 'center',
              gridTemplateColumns: 'repeat(3, minmax(0, 33fr))',
            }}
          >
            {blueCorner}
            {blueCorner && redCornerAthlete && (
              <Box
                sx={{
                  gridArea: 'score',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AccordionSummaryText
                  sx={{
                    backgroundColor:
                      scr?.winner && scr?.winBy
                        ? scr?.winner?.id === blueCornerAthlete?.id
                          ? `${BLUE_CORNER_WIN_BY}16`
                          : `${RED_CORNER_WIN_BY}16`
                        : '#6C757D29',
                    p: 0.7,
                    fontWeight: 500,
                    borderRadius: '0.45rem',
                    textAlign: 'center',
                    color:
                      scr?.winner && scr?.winBy
                        ? scr?.winner?.id === blueCornerAthlete?.id
                          ? BLUE_CORNER_TEXT_WIN_BY
                          : RED_CORNER_TEXT_WIN_BY
                        : 'inherit',
                  }}
                >
                  {scr?.winBy
                    ? WIN_BY_OPTIONS_BKFC.find((winOpt) => winOpt.value === scr?.winBy)?.label ||
                      '-'
                    : 'vs'}
                </AccordionSummaryText>
              </Box>
            )}
            {redCorner}
          </Box>
        )}

        <Box
          sx={{
            gridArea: 'matchDtls',
            display: 'grid',
            gridTemplateAreas: `"blank matchTime matchStatus"`,
            alignItems: 'center',
            gridTemplateColumns: 'repeat(3, 33%)',
          }}
        >
          <Box
            sx={{
              gridArea: 'matchTime',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AccordionSummaryText>
              {`${scr?.endDate ? 'End' : 'Start'}`} Time:&nbsp;
              <strong>
                {matchSummaryDateFormatter(scr?.endDate ? scr?.endDate : scr?.startDate) || '--:--'}
              </strong>
            </AccordionSummaryText>
          </Box>

          {!isAccordionExpanded && (
            <Box
              sx={{
                gridArea: 'matchStatus',
                justifySelf: 'end',
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
              }}
            >
              {processMatchStatusBadges(scr?.heatStatus)}
            </Box>
          )}
        </Box>
      </Box>
    </AccordionSummary>
  );
};

const FightDetails = ({
  scr,
  idx,
  setFieldValue,
  values,
  errors,
  ATHLETE_OPTIONS,
  WINNER_OPTIONS,
  WIN_BY_OPTIONS_BKFC,
  FIGHT_STATUS_OPTIONS,
  handleBlur,
  handleSaveOrEndFight,
  redCornerAthlete,
  blueCornerAthlete,
  toggleCustomStartFightDialog,
  toggleCustomEndFightDialog,
}: any) => {
  return (
    <AccordionDetails>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            mb: 2,
            display: 'grid',
            gridTemplateAreas: `"startDate endDate heatStatus "`,
            gridTemplateColumns: 'repeat(2, 40fr) 20fr',
            gap: '1rem',
          }}
        >
          <Box sx={{ gridArea: 'startDate' }}>
            <AccordionDtlsLabelText mb={1}>Start Time</AccordionDtlsLabelText>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
              <DateTimePicker
                ampm={false}
                value={values.scores[idx].startDate || null}
                onChange={(newValue) => {
                  setFieldValue(`scores[${idx}].startDate`, newValue);
                }}
                // onClose={() => {
                //   if (
                //     values.scores[idx].heatStatus === FightStatusValues[FightStatusNames.UPCOMING]
                //   ) {
                //     setFieldValue(
                //       `scores[${idx}].heatStatus`,
                //       FightStatusValues[FightStatusNames.LIVE],
                //     );
                //   }
                // }}
                // disabled
                slotProps={{
                  textField: {
                    color: 'secondary',
                    fullWidth: true,
                    placeholder: 'Start Time',
                    sx: {
                      fontWeight: '400',
                      '& .MuiInputBase-input': {
                        padding: '0.6rem 1.5rem',
                        fontSize: '0.812rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          borderLeft: '1px solid #C4C4C4',
                          borderTop: '1px solid #C4C4C4',
                          borderBottom: '1px solid #C4C4C4',
                          borderRadius: 0,
                          borderTopLeftRadius: '0.25rem',
                          borderBottomLeftRadius: '0.25rem',
                          '&:hover': {
                            borderColor: '#000000',
                          },
                        },
                      },
                    },
                    // onBlur: () => {
                    //   if (
                    //     values.scores[idx].heatStatus ===
                    //     FightStatusValues[FightStatusNames.UPCOMING]
                    //   ) {
                    //     setFieldValue(
                    //       `scores[${idx}].heatStatus`,
                    //       FightStatusValues[FightStatusNames.LIVE],
                    //     );
                    //   }
                    // },
                  },
                }}
              />
              <Button
                variant="contained"
                disableElevation
                // disabled={!scr.startDate || !scr.winner}
                onClick={() => {
                  // setFieldValue(`scores[${idx}].startDate`, new Date());
                  // setFieldValue(`scores[${idx}].heatStatus`, EventStatusValues[EventStatusNames.LIVE]);
                  toggleCustomStartFightDialog(scr);
                }}
                sx={{
                  borderRadius: 0,
                  borderTopRightRadius: '0.25rem',
                  borderBottomRightRadius: '0.25rem',
                }}
              >
                Start Now
              </Button>
            </Box>
          </Box>
          <Box sx={{ gridArea: 'endDate' }}>
            <AccordionDtlsLabelText mb={1}>End Time</AccordionDtlsLabelText>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
              <DateTimePicker
                ampm={false}
                value={values.scores[idx].endDate || null}
                onChange={(newValue) => {
                  setFieldValue(`scores[${idx}].endDate`, newValue);
                }}
                onClose={() => {
                  if (
                    values.scores[idx].heatStatus !== FightStatusValues[FightStatusNames.COMPLETED]
                  ) {
                    setFieldValue(
                      `scores[${idx}].heatStatus`,
                      FightStatusValues[FightStatusNames.COMPLETED],
                    );
                  }
                }}
                disabled
                slotProps={{
                  textField: {
                    color: 'secondary',
                    fullWidth: true,
                    placeholder: 'End Time',
                    sx: {
                      fontWeight: '400',
                      '& .MuiInputBase-input': {
                        padding: '0.6rem 1.5rem',
                        fontSize: '0.812rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          borderLeft: '1px solid #C4C4C4',
                          borderTop: '1px solid #C4C4C4',
                          borderBottom: '1px solid #C4C4C4',
                          borderRadius: 0,
                          borderTopLeftRadius: '0.25rem',
                          borderBottomLeftRadius: '0.25rem',
                          '&:hover': {
                            borderColor: '#000000',
                          },
                        },
                      },
                    },
                    onBlur: () => {
                      if (
                        values.scores[idx].heatStatus !==
                        FightStatusValues[FightStatusNames.COMPLETED]
                      ) {
                        setFieldValue(
                          `scores[${idx}].heatStatus`,
                          FightStatusValues[FightStatusNames.COMPLETED],
                        );
                      }
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                disableElevation
                // disabled={!scr.startDate || !scr.winner}
                onClick={() => {
                  // setFieldValue(`scores[${idx}].startDate`, new Date());
                  // setFieldValue(`scores[${idx}].heatStatus`, EventStatusValues[EventStatusNames.LIVE]);
                  toggleCustomEndFightDialog(scr);
                }}
                sx={{
                  borderRadius: 0,
                  borderTopRightRadius: '0.25rem',
                  borderBottomRightRadius: '0.25rem',
                }}
              >
                End Now
              </Button>
            </Box>
          </Box>
          <Box sx={{ gridArea: 'heatStatus' }}>
            <AccordionDtlsLabelText mb={1}>Fight Status</AccordionDtlsLabelText>
            <ModSelect
              // name={`scores[${idx}].weightClass`}
              value={scr?.heatStatus}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              fullWidth
              onChange={({ target }) => {
                setFieldValue(`scores[${idx}].heatStatus`, +target?.value as unknown as number);
              }}
            >
              {React.Children.toArray(
                FIGHT_STATUS_OPTIONS.map((status: any) => (
                  <MenuItem
                    value={status[0]}
                    disabled={
                      values.scores[idx].startDate &&
                      +status[0] === FightStatusValues[FightStatusNames.UPCOMING]
                        ? true
                        : values.scores[idx].endDate &&
                          (+status[0] === FightStatusValues[FightStatusNames.LIVE] ||
                            +status[0] === FightStatusValues[FightStatusNames.UPCOMING])
                        ? true
                        : false
                    }
                  >
                    {status[1]}
                  </MenuItem>
                )),
              )}
            </ModSelect>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"blueCornerAthlete redCornerAthlete scheduledRounds"`,
            gridTemplateColumns: '45fr 45fr 10fr',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Box sx={{ gridArea: 'blueCornerAthlete' }}>
            <FormControl error={errors?.scores?.[idx]?.blueCornerAthlete} fullWidth>
              <AccordionDtlsLabelText mb={1}>Blue Corner</AccordionDtlsLabelText>
              <CustomAthleteSelect
                value={values?.scores[idx]?.blueCornerAthlete}
                athleteColor={BLUE_CORNER_COLOR}
                handleBlur={(val) => {
                  const currentAthlete = val;
                  const prevAthlete = values?.scores[idx]?.blueCornerAthlete;
                  const currentWinner = values?.scores[idx]?.winner;
                  const isPrevAthleteWinner =
                    currentWinner && prevAthlete?.id === currentWinner?.id;

                  setFieldValue(`scores[${idx}].blueCornerAthlete`, currentAthlete);

                  if (isPrevAthleteWinner) {
                    setFieldValue(`scores[${idx}].winner`, currentAthlete);
                  }
                }}
                ATHLETE_OPTIONS={ATHLETE_OPTIONS}
                skipAthlete={values?.scores[idx]?.redCornerAthlete}
              />
              {errors?.scores?.[idx]?.blueCornerAthlete && (
                <FormHelperText>{errors?.scores?.[idx]?.blueCornerAthlete}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ gridArea: 'redCornerAthlete' }}>
            <FormControl error={errors?.scores?.[idx]?.redCornerAthlete} fullWidth>
              <AccordionDtlsLabelText mb={1}>Red Corner</AccordionDtlsLabelText>
              <CustomAthleteSelect
                value={values?.scores[idx]?.redCornerAthlete}
                athleteColor={RED_CORNER_COLOR}
                handleBlur={(val) => {
                  const currentAthlete = val;
                  const prevAthlete = values?.scores[idx]?.redCornerAthlete;
                  const currentWinner = values?.scores[idx]?.winner;
                  const isPrevAthleteWinner =
                    currentWinner && prevAthlete?.id === currentWinner?.id;

                  setFieldValue(`scores[${idx}].redCornerAthlete`, currentAthlete);

                  if (isPrevAthleteWinner) {
                    setFieldValue(`scores[${idx}].winner`, currentAthlete);
                  }
                }}
                ATHLETE_OPTIONS={ATHLETE_OPTIONS}
                skipAthlete={values?.scores[idx]?.blueCornerAthlete}
              />
              {errors?.scores?.[idx]?.redCornerAthlete && (
                <FormHelperText>{errors?.scores?.[idx]?.redCornerAthlete}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ gridArea: 'scheduledRounds' }}>
            <AccordionDtlsLabelText mb={1}>Scheduled Rounds</AccordionDtlsLabelText>
            <CounterButton
              value={scr?.scheduledRounds || 0}
              changeHandler={(val: number) => {
                setFieldValue(`scores[${idx}].scheduledRounds`, val);
              }}
              bgColor="#F8F9FA"
            />
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            mt: 2,
            mb: 1,
            width: '100%',
          }}
        >
          End of the fight
        </Typography>

        <AccordionDtlsLabelText width="100%" mb={1}>
          Winner
        </AccordionDtlsLabelText>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '20fr 4ch 10fr 60fr',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <ModSelect
            value={scr?.winner}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            fullWidth
            // disabled={
            //   ![
            //     EventStatusValues[EventStatusNames.LIVE],
            //     EventStatusValues[EventStatusNames.COMPLETED],
            //   ].includes(scr?.heatStatus)
            // }
            onChange={({ target }) => {
              setFieldValue(`scores[${idx}].winner`, target.value);
              setFieldValue(`scores[${idx}].winnerAthleteId`, target?.value?.id || null);
            }}
            sx={{ backgroundColor: '#ffffff' }}
            renderValue={(selected) => {
              const selectedAthlete = ATHLETE_OPTIONS.find((athlete: any) => athlete === selected);
              let colorIcon = null;

              if (redCornerAthlete === selected) {
                colorIcon = (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      borderRadius: '50%',
                      height: '0.5rem',
                      width: '0.5rem',
                      backgroundColor: RED_CORNER_COLOR,
                    }}
                  ></Box>
                );
              }

              if (blueCornerAthlete === selected) {
                colorIcon = (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      borderRadius: '50%',
                      height: '0.5rem',
                      width: '0.5rem',
                      backgroundColor: BLUE_CORNER_COLOR,
                    }}
                  ></Box>
                );
              }
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.4rem',
                  }}
                >
                  {colorIcon && colorIcon}
                  <AccordionSummaryText>
                    {selectedAthlete?.label || 'Please select winner'}
                  </AccordionSummaryText>
                </Box>
              );
            }}
          >
            {React.Children.toArray(
              WINNER_OPTIONS.map((athlete: any) => (
                <MenuItem value={athlete}>{athlete?.label}</MenuItem>
              )),
            )}
          </ModSelect>
          <AccordionDtlsLabelText sx={{ textAlign: 'center' }}>by</AccordionDtlsLabelText>
          <ModSelect
            value={scr?.winBy}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            fullWidth
            sx={{ backgroundColor: '#ffffff' }}
            onChange={({ target }) => {
              setFieldValue(`scores[${idx}].winBy`, target.value);
            }}
            // disabled={
            //   ![
            //     EventStatusValues[EventStatusNames.LIVE],
            //     EventStatusValues[EventStatusNames.COMPLETED],
            //   ].includes(scr?.heatStatus)
            // }
          >
            {React.Children.toArray(
              WIN_BY_OPTIONS_BKFC.map((dec) => (
                <MenuItem value={dec?.value}>{dec?.label}</MenuItem>
              )),
            )}
          </ModSelect>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '35fr 65fr',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box>
            <AccordionDtlsLabelText mb={1}>Notes</AccordionDtlsLabelText>
            <ModTextField
              name={`scores[${idx}].notes`}
              value={values?.scores[idx]?.notes}
              multiline
              rows={4}
              maxRows={8}
              fullWidth
              onBlur={handleBlur}
              onChange={({ target }) => {
                setFieldValue(`scores[${idx}].notes`, target.value);
              }}
            />
          </Box>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" gap="0.5rem">
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            // disabled={scr.winner}
            onClick={() => handleSaveOrEndFight(scr, idx)}
          >
            Save
          </Button>

          <Button
            variant="contained"
            disableElevation
            // disabled={!scr.startDate || !scr.winner}
            onClick={() => {
              toggleCustomEndFightDialog(scr);
            }}
          >
            End this fight
          </Button>
        </Grid>
      </Box>
    </AccordionDetails>
  );
};

export default function BKFCEventScores() {
  const { eventId = '' } = useParams();
  const queryClient = useQueryClient();
  const [fightData, setFightData] = React.useState(undefined);
  const [showStartFightDialog, setShowStartFightDialog] = React.useState(false);
  const [showEndFightDialog, setShowEndFightDialog] = React.useState(false);

  const toggleCustomEndFightDialog = (data: any) => {
    setShowEndFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const toggleCustomStartFightDialog = (data: any) => {
    setShowStartFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const { data: eventScores } = useFetchEventScores(SPORT_TYPES.BKFC, eventId);
  const { data: eventSeeds } = useFetchParticipants(SPORT_TYPES.BKFC, eventId, ARCHIVED);
  // const { data: weightClasses } = useFetchWeightClasses(SPORT_TYPES.BKFC, 'Scores');

  // const WEIGHT_CLASSES = React.useMemo(
  //   () =>
  //     weightClasses ? weightClasses?.map((wgtClass) => ({ label: wgtClass, value: wgtClass })) : [],
  //   [weightClasses],
  // );

  const { mutateAsync: updateBKFCScores } = useUpdateBKFCScores();

  const ATHLETE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds?.map((seed: any) => {
        const { athlete } = seed;
        const fullName = [athlete?.firstName, athlete?.middleName, athlete?.lastName]
          .filter(Boolean)
          .join(' ');
        const nickName = athlete?.nickName ? ` (${athlete.nickName})` : '';
        const weightClass = athlete?.weightClass
          ? ` - ${getFirstLettersCombined(athlete?.weightClass || '')}`
          : '';

        return {
          ...athlete,
          label: `${fullName}${nickName}${weightClass}`,
          value: athlete?.id,
          firstName: athlete?.firstName,
          middleName: athlete?.middleName,
          lastName: athlete?.lastName,
          nickName: athlete?.nickName,
          weightClass: athlete?.weightClass,
        };
      }) || []
    );
  }, [eventSeeds]);

  const FIGHT_STATUS_OPTIONS = React.useMemo(
    () =>
      Object.entries(FightStatusKeys).filter((status) =>
        [
          FightStatusNames.UPCOMING,
          FightStatusNames.LIVE,
          FightStatusNames.COMPLETED,
          FightStatusNames.CANCELED,
          FightStatusNames.MEDICAL_STOPPAGE,
        ].includes(status[1]),
      ),
    [],
  );

  const refreshScores = () => {
    queryClient.invalidateQueries([keys.events.fetchEventScores, SPORT_TYPES.BKFC, eventId]);
    if (fightData) {
      setFightData(undefined);
    }
  };

  const initialScoresFormatter = (fights: any) => {
    if (!fights) return [];

    return fights.map((fight: any) => {
      const blueCornerAthlete = ATHLETE_OPTIONS.find(
        (athlete: any) =>
          athlete.id === (fight?.athletes?.find((scr: any) => scr.typeBlue) || {}).athleteId,
      );
      const redCornerAthlete = ATHLETE_OPTIONS.find(
        (athlete: any) =>
          athlete.id === (fight?.athletes?.find((scr: any) => !scr.typeBlue) || {}).athleteId,
      );
      const winner = ATHLETE_OPTIONS.find((athlete: any) => athlete.id === fight.winnerAthleteId);

      return {
        ...fight,
        blueCornerAthlete: blueCornerAthlete || null,
        redCornerAthlete: redCornerAthlete || null,
        startDate: fight.startDate ? format(fight.startDate, undefined, true) : null,
        endDate: fight.endDate ? format(fight.endDate, undefined, true) : null,
        winner: winner || null,
      };
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scores: initialScoresFormatter(eventScores || []),
    },
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const { values, errors, setFieldValue, setFieldError, handleBlur } = formik;

  const [accordion, setAccordion] = React.useState<{ [key: string]: boolean }>(
    values?.scores?.reduce(
      (o: any, val: { id: string; heatStatus: number; roundHeatId: string }) => ({
        ...o,
        [val?.id || val?.roundHeatId]:
          FightStatusKeys[val?.heatStatus] === FightStatusNames.LIVE ? true : false,
      }),
      {},
    ),
  );

  React.useEffect(() => {
    if (!values || !values.scores) return;

    setAccordion((prev: { [key: string]: boolean }) => {
      const updatedAccordion: { [key: string]: boolean } = values.scores.reduce(
        (
          acc: { [key: string]: boolean },
          val: { id: string | undefined; roundHeatId: string; heatStatus: number },
        ) => {
          const id: string = val.id || val.roundHeatId;
          if (prev[id] !== undefined) return { ...acc, [id]: prev[id] };
          const heatStatus: string = FightStatusKeys[val.heatStatus];
          return { ...acc, [id]: heatStatus === FightStatusNames.LIVE };
        },
        {},
      );

      return updatedAccordion;
    });
  }, [values?.scores]);

  const payloadFormatter = (data: any) => {
    const athletes = [
      { athleteId: data?.blueCornerAthlete?.id, typeBlue: true },
      { athleteId: data?.redCornerAthlete?.id, typeBlue: false },
    ];

    const payload = {
      startDate: data?.startDate ? zonedTimeToUtc(data?.startDate, DEFAULT_TIMEZONE) : null,
      endDate: data?.endDate ? zonedTimeToUtc(data?.endDate, DEFAULT_TIMEZONE) : null,
      winBy: data?.winBy || null,
      notes: data?.notes || null,
      scheduledRounds: data?.scheduledRounds || 0,
      winnerAthleteId: data?.winner ? data?.winner?.id : null,
      athletes,
      heatStatus: data?.heatStatus,
    };
    return payload;
  };

  const handleSaveOrEndFight = async (data: any, idx: number) => {
    try {
      await fightValidationSchema.validate(data, { abortEarly: false });
      if (data?.roundHeatId) {
        updateBKFCScores(
          {
            sport: SPORT_TYPES.BKFC,
            eventId,
            roundHeatId: data?.roundHeatId,
            requestPayload: payloadFormatter(data),
          },
          {
            onSettled: (_, err) => {
              if (!err) {
                enqueueSnackbar('Updated fight successfully!');
                refreshScores();
              }
            },
          },
        );
      }
    } catch (err: any) {
      if (err?.inner) {
        err?.inner?.forEach((error: any) => {
          setFieldError(`scores[${idx}].${error.path}`, error?.message);
        });
      }
    }
  };

  const matchSummaryDateFormatter = (matchTime: Date) => {
    try {
      if (!matchTime || isNaN(matchTime.getTime())) {
        return '';
      }

      const formattedTime = normalFormat(matchTime, 'HH:mm');
      return `${formattedTime} PST`;
    } catch (error) {
      return '';
    }
  };

  return (
    <Box>
      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          disableElevation
          onClick={() =>
            setFieldValue('scores', [
              ...(values?.scores || []),
              {
                ...DEFAULT_FIGHT,
                id: values?.scores?.length + 1,
                name: `FIGHT ${values?.scores?.length + 1}`,
              },
            ])
          }
        >
          Add fight
        </Button>
      </Box> */}
      {React.Children.toArray(
        values?.scores?.map((scr: any, idx: number) => (
          <FightContainer
            scr={scr}
            idx={idx}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            ATHLETE_OPTIONS={ATHLETE_OPTIONS}
            // WEIGHT_CLASSES={WEIGHT_CLASSES}
            WIN_BY_OPTIONS_BKFC={WIN_BY_OPTIONS_BKFC}
            FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
            handleBlur={handleBlur}
            handleSaveOrEndFight={handleSaveOrEndFight}
            accordion={accordion}
            setAccordion={setAccordion}
            matchSummaryDateFormatter={matchSummaryDateFormatter}
            toggleCustomStartFightDialog={toggleCustomStartFightDialog}
            toggleCustomEndFightDialog={toggleCustomEndFightDialog}
          />
        )),
      )}

      <CustomEndFightDialog
        open={showStartFightDialog}
        handleClose={() => toggleCustomStartFightDialog(undefined)}
        handleConfirm={(newVal: any) => {
          const idx = values.scores.findIndex(
            (scr: any) =>
              scr?.roundHeatId === fightData?.roundHeatId && scr?.name === fightData?.name,
          );
          toggleCustomStartFightDialog(undefined);
          handleSaveOrEndFight(
            {
              ...(fightData as unknown as any),
              startDate: newVal,
              ...(fightData?.heatStatus === FightStatusValues[FightStatusNames.UPCOMING] && {
                heatStatus: FightStatusValues[FightStatusNames.LIVE],
              }),
            },
            idx,
          );
        }}
        title={'ARE YOU SURE YOU WANT TO START THIS FIGHT?'}
        body={'This will set the Start Time. You can come back to make edits if needed.'}
        isStartTime
      />

      <CustomEndFightDialog
        open={showEndFightDialog}
        handleClose={() => toggleCustomEndFightDialog(undefined)}
        handleConfirm={(newVal: any) => {
          const idx = values.scores.findIndex(
            (scr: any) =>
              scr?.roundHeatId === fightData?.roundHeatId && scr?.name === fightData?.name,
          );
          toggleCustomEndFightDialog(undefined);
          handleSaveOrEndFight(
            {
              ...(fightData as unknown as any),
              endDate: newVal,
              heatStatus: FightStatusValues[FightStatusNames.COMPLETED],
            },
            idx,
          );
        }}
        title={'ARE YOU SURE YOU WANT TO END THIS FIGHT?'}
        body={'This will set the scores and End Time. You can come back to make edits if needed.'}
      />
    </Box>
  );
}
