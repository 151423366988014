import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

const addHeatScores = async (heatId: string, payload: any, sportType: string) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sportType}/traders/scores/heats/${heatId}`,
    data: payload,
  });

  return data;
};

export default function useAddHeatScores(sportType: string) {
  return useMutation(({ heatId, payload }: { heatId: string; payload: any }) =>
    addHeatScores(heatId, payload, sportType),
  );
}
