import { RoundStatus } from '@/constants/rounds';
import { HeatStatus } from '@/constants/heats';

export const SKIP_SCORE_FORM_ROUND_STATUSES = [
  RoundStatus.CANCELED,
  RoundStatus.POSTPONED,
  RoundStatus.COMPLETED,
];

export const SKIP_SCORE_FORM_HEAT_STATUSES = [
  HeatStatus.CANCELED,
  HeatStatus.POSTPONED,
  HeatStatus.COMPLETED,
];
