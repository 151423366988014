import * as React from 'react';
import { useRecoilState } from 'recoil';
import round from 'lodash.round';
import { enqueueSnackbar, useSnackbar } from 'notistack';

import {
  marketOddsPayload,
  editSubMarketOddsFormatter,
  externalMarketOddsPayload,
  externalMarketOddModifiers,
  getBetCommonValue,
  processBets,
  getFirstUnlockedOrLockedSubMkt,
  isFieldConsistentAcrossTeams,
  processBet,
  calcHoldPercentageOfSubMkt,
  modSubMarket,
} from '../marketHelper';
import { BACKGROUND_COLOR, BORDER_COLOR, EXTERNAL_UPDATE_TRIGGER_FIELDS } from '../constants';
import { oddsType } from '@/atoms/oddsType';

import { MarketTypes } from '@/constants/masl';
import { getKeysWithObjectValues } from '@/helpers/misc';

import {
  // Styled Components
  Accordion,
} from '../components';
import { teamTypeToKey, fieldsThatTriggerExternalUpdates } from '../constants';
import AccordionSummaryWithMarketHeader from './AccordionSummaryWithMarketHeader';
import BetTypeHeaders from './BetTypeHeaders';
import MarketAccordionDetails from './MarketAccordionDetails';

import { MASLGameContext } from '@/contexts/maslGame.context';
import { MASL_NAMESPACE } from '../../..';
import { WEB_SOCKET_EMIT_EVENT_TYPES } from '@/constants/socket';
import { sendEvent } from '@/utils/socketService';
import cloneDeep from 'lodash.clonedeep';
// import PublishOdds from '@/components/PublishOdds';
// import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';

const MIN_HOLD_PERCENTAGE = 100;
const MAX_HOLD_PERCENTAGE = 200;

const BetRow = ({
  value,
  bet,
  betId,
  oddId,
  oddType,
  setFieldValue,
  externalUpdateOdds,
  externalUpdateOddsOnUnlock,
  holdPercentage,
  disabledMarkets,
  values,
}: any) => {
  const BET_COMMON_VALUE = getBetCommonValue(bet, 'probability');

  const updateSubMarketTeam = (row: any, subMarketType: number, teamType: string) => {
    if (!row?.id) {
      return; // Exit early if row.id is not available
    }

    const isCommonOdds = false;

    // Get current team data
    const currentTeamDataHome = bet?.[subMarketType]?.[teamTypeToKey.homeTeam];
    const currentTeamDataAway = bet?.[subMarketType]?.[teamTypeToKey.awayTeam];

    // Apply edits to the team data using editSubMarketOddsFormatter
    const { homeTeam, awayTeam } = editSubMarketOddsFormatter({
      isHomeTeamModified: teamType === teamTypeToKey.homeTeam,
      homeTeam: teamType === teamTypeToKey.homeTeam ? row : currentTeamDataHome,
      awayTeam: teamType === teamTypeToKey.awayTeam ? row : currentTeamDataAway,
      holdPercentage,
      commonOdds: isCommonOdds,
    });

    if (homeTeam) {
      setFieldValue(`odds[${oddId}].bets[${betId}].${subMarketType}.${teamTypeToKey.homeTeam}`, {
        ...homeTeam,
      });
    }
    if (awayTeam) {
      setFieldValue(`odds[${oddId}].bets[${betId}].${subMarketType}.${teamTypeToKey.awayTeam}`, {
        ...awayTeam,
      });
    }
  };

  const updateSubMarketTeamKey = ({
    subMarketType,
    teamType,
    key,
    val,
    allowExternalUpdate = true,
  }: any) => {
    if (fieldsThatTriggerExternalUpdates.includes(key)) {
      setFieldValue(
        `odds[${oddId}].bets[${betId}].${subMarketType}.${teamType}.${key}`,
        val,
        false,
      );

      if (allowExternalUpdate) {
        const currentVal = { ...value };

        const currentBets = currentVal.bets || [];
        currentBets[betId][subMarketType][teamType][key] = val;
        currentVal.bets = currentBets;
        const currentBetType = currentBets[betId][subMarketType][teamType]?.betType;
        const currentMarketType = currentBets[betId][subMarketType][teamType]?.marketType;

        externalUpdateOdds({
          odds: [currentVal],
          oddId,
          autoSave: key === EXTERNAL_UPDATE_TRIGGER_FIELDS.bias,
          isHomeTeam: teamType === teamTypeToKey.homeTeam,
          betType: Number(currentBetType),
          marketType: Number(currentMarketType),
          field: key,
          val: val,
        });
      }
    } else {
      setFieldValue(
        `odds[${oddId}].bets[${betId}].${subMarketType}.${teamType}.${key}`,
        val,
        false,
      );
    }
  };

  const toggleSubMarketLock = (subMarketType: number) => {
    const betCpy = cloneDeep(bet);

    const currentSubMarket = betCpy[subMarketType];
    if (!currentSubMarket) return;

    const unlockedBet = processBet(betCpy, false);
    const lockedBet = processBet(betCpy, true);
    const currentBet = Object.keys(unlockedBet).length > 1 ? unlockedBet : lockedBet;
    const BET_COMMON_VALUE_CPY: any = getBetCommonValue(currentBet);

    const valueCpy = cloneDeep(value);
    const unlockedLean = getFirstUnlockedOrLockedSubMkt([valueCpy]);

    const currentPayload = externalMarketOddsPayload({ odds: [{ bets: [betCpy] }] });
    const canBypassExternalOddsUpdate = ['lean', 'bias'].every((field) => {
      const { isHomeTeamFieldConsistent, isAwayTeamFieldConsistent } = isFieldConsistentAcrossTeams(
        currentPayload,
        field as keyof {},
        field === 'lean' ? unlockedLean : undefined,
      );
      return isHomeTeamFieldConsistent && isAwayTeamFieldConsistent;
    });

    const currentSubMktHoldPercentage = calcHoldPercentageOfSubMkt(currentSubMarket);
    const isHoldPercentageDifferent = +holdPercentage !== currentSubMktHoldPercentage;

    const { homeTeam, awayTeam } = currentSubMarket;
    const isLocked = homeTeam?.isSubMarketLocked && awayTeam?.isSubMarketLocked;

    if (!isLocked || canBypassExternalOddsUpdate) {
      [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
        if (isHoldPercentageDifferent) {
          const teamData = currentSubMarket[team];
          const modifiedSubMkt = modSubMarket({
            subMarketKey: team,
            currentValSubMarket: { ...teamData, isSubMarketLocked: !isLocked },
            newHoldPercentage: holdPercentage,
            oldHoldPercentage: currentSubMktHoldPercentage,
          });

          setFieldValue(
            `odds[${oddId}].bets[${betId}].${subMarketType}.${team}`,
            modifiedSubMkt[team],
            false,
          );
        } else {
          updateSubMarketTeamKey({
            subMarketType,
            teamType: team,
            key: 'isSubMarketLocked',
            val: !isLocked,
          });
        }
      });
      return;
    }

    // External odds update is required
    enqueueSnackbar('Unlocking Sub Market!');

    const unlockedHomeTeam = unlockedLean?.[teamTypeToKey.homeTeam];
    const unlockedAwayTeam = unlockedLean?.[teamTypeToKey.awayTeam];
    const currentMarketType = Number(homeTeam?.marketType);
    const currentBetType = Number(homeTeam?.betType);
    const unlockedBias = +BET_COMMON_VALUE_CPY?.[teamTypeToKey.homeTeam]?.bias || 0;

    if (isHoldPercentageDifferent) {
      [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
        const teamData = valueCpy.bets[betId][subMarketType][team];
        const modifiedSubMkt = modSubMarket({
          subMarketKey: team,
          currentValSubMarket: { ...teamData, isSubMarketLocked: !isLocked },
          newHoldPercentage: holdPercentage,
          oldHoldPercentage: currentSubMktHoldPercentage,
        });
        valueCpy.bets[betId][subMarketType][team] = modifiedSubMkt[team];
      });
    }

    externalUpdateOddsOnUnlock({
      odds: [valueCpy],
      oddId,
      betId,
      isHomeTeam: true,
      marketType: currentMarketType,
      subMarketType,
      betType: currentBetType,
      bias: unlockedBias,
      homeTeamLean: +unlockedHomeTeam?.lean,
      awayTeamLean: +unlockedAwayTeam?.lean,
      isSubMarketUnlocked: true,
    });
  };

  const updateBias = (team: string, val: number) => {
    getKeysWithObjectValues(bet)?.forEach((subMarketVal, idx, arr) => {
      const currentSubMarket = bet[subMarketVal];
      if (currentSubMarket) {
        const currentTeam = currentSubMarket[team];
        if (currentTeam) {
          // Get the current teamPath.bias value
          const newBias = +val; // Assuming 'val' is already defined

          // Update the fields using a single setFieldValue call
          updateSubMarketTeamKey({
            subMarketType: subMarketVal,
            teamType: team,
            key: 'bias',
            val: newBias,
            allowExternalUpdate: arr.length - 1 === idx ? true : false,
          });

          if (!currentTeam?.hasModifiedProbability) {
            updateSubMarketTeamKey({
              subMarketType: subMarketVal,
              teamType: team,
              key: 'hasModifiedProbability',
              val: true,
              allowExternalUpdate: arr.length - 1 === idx ? true : false,
            });
          }
        }
      }
    });
  };

  const commonCellHeaderStyles = {
    backgroundColor: marketOddsPayload({ odds: [value] })?.every((odd) => odd?.isMarketActive)
      ? BACKGROUND_COLOR.ACTIVE
      : BACKGROUND_COLOR.SUSPENDED,
    boxShadow: `0 0 0 1px ${
      marketOddsPayload({ odds: [value] })?.every((odd) => odd?.isMarketActive)
        ? BORDER_COLOR.ACTIVE
        : BORDER_COLOR.SUSPENDED
    }`,
  };

  return (
    <>
      <BetTypeHeaders
        betType={+BET_COMMON_VALUE?.homeTeam?.betType}
        commonCellHeaderStyles={commonCellHeaderStyles}
        // value={value}
        // bet={bet}
        // holdPercentagesOptions={holdPercentagesOptions}
        // setFieldValue={setFieldValue}
        // oddId={oddId}
      />
      <MarketAccordionDetails
        key={betId}
        value={value}
        bet={bet}
        oddType={oddType}
        toggleSubMarketLock={toggleSubMarketLock}
        updateSubMarketTeam={updateSubMarketTeam}
        updateSubMarketTeamKey={updateSubMarketTeamKey}
        updateBias={updateBias}
        disabledMarkets={disabledMarkets}
        oddId={oddId}
        betId={betId}
        setFieldValue={setFieldValue}
        values={values}
        externalUpdateOddsOnUnlock={externalUpdateOddsOnUnlock}
        holdPercentage={holdPercentage}
      />
    </>
  );
};

const MarketTypesDetails = ({
  formik,
  disabledMarkets,
  updateOdds, // handlePublishOdds,setDisabledMarkets,
}: {
  formik: any;
  disabledMarkets: { [key: string]: boolean };
  setDisabledMarkets: any;
  updateOdds: any;
  handlePublishOdds: (val: string) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { handleDisableMatch } = React.useContext(MASLGameContext);
  const { values, setFieldValue } = formik;
  const [accordion, setAccordion] = React.useState<{ [key: string]: boolean }>(
    MarketTypes.reduce((o, val) => ({ ...o, [val]: true }), {}),
  );

  const [oddType] = useRecoilState(oddsType);
  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  const externalUpdateOdds = ({
    odds,
    oddId,
    autoSave = false,
    isHomeTeam = false,
    betType,
    marketType,
    field,
    val,
  }: {
    odds: any;
    oddId: number;
    autoSave?: boolean;
    betType: number;
    marketType: number;
    isHomeTeam: boolean;
    field: string;
    val: number;
  }) => {
    const [firstOdd] = odds;
    const finalPayload = externalMarketOddsPayload({ odds });
    if (field === EXTERNAL_UPDATE_TRIGGER_FIELDS.bias) {
      enqueueSnackbar('Updating Market Odds Calc!');
      handleDisableMatch();
      // Apply bias change only for unlocked odds
      finalPayload.forEach((data: any) => {
        if (
          !data?.isSubMarketLocked &&
          Number(data?.betType) === Number(betType) &&
          Number(data?.marketType) === Number(marketType)
        ) {
          data.bias = data.isHomeTeam === isHomeTeam ? val : val * -1;
        }
      });
    }

    sendEvent(
      MASL_NAMESPACE,
      WEB_SOCKET_EMIT_EVENT_TYPES.FETCH_GAME_MARKET_ODDS,
      finalPayload,
      ({ data }) => {
        if (typeof data === 'object') {
          const oddsCopy = [...values.odds];
          const currentModOdd = externalMarketOddModifiers({
            marketOdds: firstOdd,
            externalOdds: data?.data || [],
          });
          oddsCopy[oddId] = currentModOdd;

          setFieldValue(`odds[${oddId}]`, currentModOdd);

          if (autoSave) {
            updateOdds({ odds: oddsCopy });
          }
        }
      },
    );
  };

  const externalUpdateOddsOnUnlock = ({
    odds,
    oddId,
    betId,
    isHomeTeam = false,
    betType,
    marketType,
    subMarketType,
    bias,
    homeTeamLean,
    awayTeamLean,
    isMarketUnlocked = false,
    isBetUnlocked = false,
    isSubMarketUnlocked = false,
  }: {
    odds: any;
    oddId: number;
    betId: number;
    betType: number;
    marketType: number;
    subMarketType: number;
    isHomeTeam: boolean;
    bias: number;
    homeTeamLean: number;
    awayTeamLean: number;
    isMarketUnlocked?: boolean;
    isBetUnlocked?: boolean;
    isSubMarketUnlocked?: boolean;
  }) => {
    const [firstOdd] = odds;
    const finalPayload = externalMarketOddsPayload({ odds });

    const marketTypeBias: any = {};

    if (isMarketUnlocked) {
      firstOdd.bets.forEach((currentBet: any) => {
        const BET_COMMON_VALUE: any = getBetCommonValue(currentBet);
        const currentHomeTeam = BET_COMMON_VALUE[teamTypeToKey.homeTeam];
        marketTypeBias[currentHomeTeam?.betType] = +currentHomeTeam?.bias;
      });
    }

    // Apply bias and player lean change only for unlocked odds
    const updateTeamData = (data: any, biasValue: number) => {
      const leanValue = data.isHomeTeam ? homeTeamLean : awayTeamLean;
      data.bias = data.isHomeTeam === isHomeTeam ? biasValue : biasValue * -1;
      data.lean = leanValue;
      data.isSubMarketLocked = false;
    };

    finalPayload.forEach((data: any) => {
      const dataMarketType = Number(data?.marketType);
      const dataSubMarketType = Number(data?.subMarketType);
      const dataBetType = Number(data?.betType);

      if (isSubMarketUnlocked) {
        const isMatchingSubMarketType =
          dataMarketType === marketType &&
          dataBetType === betType &&
          dataSubMarketType === subMarketType;

        if (isMatchingSubMarketType) {
          updateTeamData(data, bias);
        }
      }

      if (isBetUnlocked) {
        const isMatchingBetType = dataMarketType === marketType && dataBetType === betType;

        if (isMatchingBetType) {
          updateTeamData(data, bias);
        }
      }

      if (isMarketUnlocked) {
        const isMatchingMarketType = dataMarketType === marketType;

        if (isMatchingMarketType) {
          const currentBias = marketTypeBias[data?.betType];
          updateTeamData(data, currentBias);
        }
      }
    });

    const unlockCurrentSubMarket = (currentBetData: any, currentSubMarketType: number) => {
      currentBetData[currentSubMarketType][teamTypeToKey.homeTeam].isSubMarketLocked = false;
      currentBetData[currentSubMarketType][teamTypeToKey.awayTeam].isSubMarketLocked = false;
    };

    const unlockAllCurrentSubMarkets = (currentBetData: any) => {
      Object.keys(currentBetData).forEach((currentSubMarketType) => {
        if (typeof currentBetData[currentSubMarketType] === 'object') {
          unlockCurrentSubMarket(currentBetData, +currentSubMarketType as number);
        }
      });
    };

    const unlockAllCurrentBetsAndSubMarkets = (currentBetsCopy: any) => {
      currentBetsCopy.forEach((currentBetData: any) => {
        unlockAllCurrentSubMarkets(currentBetData);
      });
    };

    sendEvent(
      MASL_NAMESPACE,
      WEB_SOCKET_EMIT_EVENT_TYPES.FETCH_GAME_MARKET_ODDS,
      finalPayload,
      ({ data }) => {
        if (typeof data === 'object') {
          const currentOddsCopy = [...values.odds];

          if (isSubMarketUnlocked) {
            unlockCurrentSubMarket(firstOdd.bets[betId], subMarketType);
          }

          if (isBetUnlocked) {
            unlockAllCurrentSubMarkets(firstOdd.bets[betId]);
          }

          if (isMarketUnlocked) {
            unlockAllCurrentBetsAndSubMarkets(firstOdd.bets);
          }

          const modifiedOdds = externalMarketOddModifiers({
            marketOdds: firstOdd,
            externalOdds: data?.data || [],
          });

          currentOddsCopy[oddId] = modifiedOdds;
          setFieldValue(`odds[${oddId}]`, modifiedOdds);

          updateOdds({ odds: currentOddsCopy });
        }
      },
    );
  };

  return React.Children.toArray(
    values.odds.map((value: any, oddId: number) => {
      const unlockedBets = processBets(value.bets, false);
      const lockedBets = processBets(value.bets, true);

      const currentBets = unlockedBets.length > 0 ? unlockedBets : lockedBets;

      const [firstBet = {}] = currentBets || [];

      const COMMON_VALUE = getBetCommonValue(firstBet, 'probability');

      const calcProbability =
        Number(COMMON_VALUE?.[teamTypeToKey.homeTeam]?.probability) +
        Number(COMMON_VALUE?.[teamTypeToKey.awayTeam]?.probability);

      const holdPercentage = round(calcProbability as number) || MIN_HOLD_PERCENTAGE;

      const toggleIsMarketActive = (isMarketActive: boolean) => {
        value.bets.forEach((_: any, id: number) => {
          getKeysWithObjectValues(value.bets[id])?.forEach((subMarketVal) => {
            const currentSubMarket = value.bets[id]?.[subMarketVal];
            if (currentSubMarket) {
              [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                const currentTeam = currentSubMarket[team];
                if (currentTeam) {
                  setFieldValue(
                    `odds[${oddId}].bets[${id}].${subMarketVal}.${team}.isMarketActive`,
                    isMarketActive,
                  );
                }
              });
            }
          });
        });
      };

      const toggleMarketLock = (isSubMarketLocked: boolean) => {
        const oddsCopy = cloneDeep(values?.odds || []);
        const valueCpy = cloneDeep(value);
        const betsCpy = cloneDeep(value?.bets || []);

        const unlockedLean = getFirstUnlockedOrLockedSubMkt(oddsCopy);

        const canBypassExternalOddsUpdate = betsCpy.every((currentBet: any) => {
          const currentPayload = externalMarketOddsPayload({ odds: [{ bets: [currentBet] }] });
          const currentCanBypassExternalOddsUpdate = ['lean', 'bias'].every((field) => {
            const { isHomeTeamFieldConsistent, isAwayTeamFieldConsistent } =
              isFieldConsistentAcrossTeams(
                currentPayload,
                field as keyof {},
                field === 'lean' ? unlockedLean : undefined,
              );
            return isHomeTeamFieldConsistent && isAwayTeamFieldConsistent;
          });
          return currentCanBypassExternalOddsUpdate;
        });

        const updateBets = (bets: any[], callback: Function) => {
          return bets.map((bet) => {
            const newBet = { ...bet };
            Object.keys(newBet).forEach((subMarketVal) => {
              if (typeof newBet[subMarketVal] === 'object') {
                const currentSubMarket = newBet[subMarketVal];
                if (currentSubMarket) {
                  const currentSubMktHoldPercentage = calcHoldPercentageOfSubMkt(currentSubMarket);
                  const isHoldPercentageDifferent = +holdPercentage !== currentSubMktHoldPercentage;
                  [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                    if (isHoldPercentageDifferent) {
                      const teamData = currentSubMarket[team];
                      const modifiedSubMkt = modSubMarket({
                        subMarketKey: team,
                        currentValSubMarket: {
                          ...teamData,
                          isSubMarketLocked: !isSubMarketLocked,
                        },
                        newHoldPercentage: holdPercentage,
                        oldHoldPercentage: currentSubMktHoldPercentage,
                      });
                      currentSubMarket[team] = modifiedSubMkt[team];
                    } else callback(team, currentSubMarket);
                  });
                }
              }
            });
            return newBet;
          });
        };

        if (!isSubMarketLocked || canBypassExternalOddsUpdate) {
          // Update bets for isSubMarketLocked and lean
          const modifiedBets = updateBets(betsCpy, (team: string, currentSubMarket: any) => {
            const currentTeam = currentSubMarket[team];
            if (!currentTeam) return;

            currentTeam.isSubMarketLocked = !isSubMarketLocked;
          });

          setFieldValue(`odds[${oddId}].bets`, modifiedBets, false);

          return;
        }

        // External odds update is required
        enqueueSnackbar('Unlocking Market!');
        const unlockedHomeTeam = unlockedLean?.[teamTypeToKey.homeTeam];
        const unlockedAwayTeam = unlockedLean?.[teamTypeToKey.awayTeam];
        const currentMarketType = Number(valueCpy?.marketType);

        valueCpy.bets.forEach((currentBet: any, currentBetId: number) => {
          getKeysWithObjectValues(currentBet)?.forEach((subMarketVal) => {
            const currentSubMarket = currentBet[subMarketVal];
            if (!currentSubMarket) return;

            const currentSubMktHoldPercentage = calcHoldPercentageOfSubMkt(currentSubMarket);
            const isHoldPercentageDifferent = +holdPercentage !== currentSubMktHoldPercentage;
            [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
              if (isHoldPercentageDifferent) {
                const teamData = valueCpy.bets[currentBetId][subMarketVal][team];
                const modifiedSubMkt = modSubMarket({
                  subMarketKey: team,
                  currentValSubMarket: {
                    ...teamData,
                    isSubMarketLocked: !isSubMarketLocked,
                  },
                  newHoldPercentage: holdPercentage,
                  oldHoldPercentage: currentSubMktHoldPercentage,
                });
                valueCpy.bets[currentBetId][subMarketVal][team] = modifiedSubMkt[team];
              }
            });
          });
        });

        externalUpdateOddsOnUnlock({
          odds: [valueCpy],
          oddId,
          homeTeamLean: +unlockedHomeTeam?.lean,
          awayTeamLean: +unlockedAwayTeam?.lean,
          isMarketUnlocked: true,
          // Default values ignored
          betId: 0,
          isHomeTeam: true,
          marketType: currentMarketType,
          subMarketType: 0,
          betType: 0,
          bias: 0,
        });
      };
      return (
        <Accordion
          expanded={accordion[value?.marketType]}
          onChange={() => {
            setAccordion((prevValue) => ({
              ...prevValue,
              [value?.marketType]: !accordion[value?.marketType],
            }));
          }}
        >
          <AccordionSummaryWithMarketHeader
            value={value}
            holdPercentage={holdPercentage}
            holdPercentagesOptions={holdPercentagesOptions}
            oddId={oddId}
            setFieldValue={setFieldValue}
            toggleIsMarketActive={toggleIsMarketActive}
            toggleMarketLock={toggleMarketLock}
            disabledMarkets={disabledMarkets}
            bet={COMMON_VALUE?.[teamTypeToKey.homeTeam]}
          />
          {React.Children.toArray(
            value.bets.map((bet: any, betId: number) => (
              <BetRow
                value={value}
                bet={bet}
                betId={betId}
                oddId={oddId}
                oddType={oddType}
                setFieldValue={setFieldValue}
                externalUpdateOdds={externalUpdateOdds}
                externalUpdateOddsOnUnlock={externalUpdateOddsOnUnlock}
                holdPercentage={holdPercentage}
                disabledMarkets={disabledMarkets}
                formik={formik}
                values={values}
              />
            )),
          )}
        </Accordion>
      );
    }),
  );
};

export default MarketTypesDetails;
