import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';

interface UPDATE_HEAD_TO_HEAD_ODDS {
  sport: string;
  eventId: string;
  payload: UpdateOddsPayloadDTO;
}

const updateHeadToHeadOdds = async ({ sport, eventId, payload }: UPDATE_HEAD_TO_HEAD_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/headToHead/odds`,
    data: payload,
  });

  return data;
};

export default function useUpdateHeadToHeadOdds() {
  return useMutation(({ sport, eventId, payload }: UPDATE_HEAD_TO_HEAD_ODDS) =>
    updateHeadToHeadOdds({ sport, eventId, payload }),
  );
}
