import * as React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';

import routes from '@/constants/routes';

import { SportTourFuturesDetails } from '@/types/futures';

const BreadcrumbLink = styled(Link)(({ theme }) => ({
  color: theme.palette.info.contrastText,
  fontSize: '0.812rem',
  fontWeight: '400',
  '&.eventName': {
    color: theme.palette.info.main,
  },
}));

const SportFuturesBreadcrumbs = ({
  sportTourFutures,
}: {
  sportTourFutures: SportTourFuturesDetails;
}) => {
  const { name = '' } = sportTourFutures;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadcrumbLink underline="hover" color="inherit" href={routes.futures.home}>
        Futures
      </BreadcrumbLink>
      <BreadcrumbLink className="eventName" underline="none">
        {name}
      </BreadcrumbLink>
    </Breadcrumbs>
  );
};

export default SportFuturesBreadcrumbs;
