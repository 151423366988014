import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  IconButton,
  Box,
  Autocomplete,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { enqueueSnackbar } from 'notistack';
import { zonedTimeToUtc } from 'date-fns-tz';

import CloseIcon from '@mui/icons-material/Close';

import { editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';

import useAddOrUpdateEventSeeds from '@/hooks/fights/useAddOrUpdateEventSeeds';

import { IEvent } from '@/types/newTypes/event';
import { FightMatchMemberTypes, FIGHT_MEMBER_TYPES_OPTIONS } from '@/constants/fights';
import { DEFAULT_TIMEZONE } from '@/constants/misc';
// import keys from '@/constants/queryKeys';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '1.5rem 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1.5rem 1.5rem 3rem',
  },
}));

const FormLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

interface ParticipantInfo {
  firstName: string;
  lastName: string;
  nickName: string;
  dateOfBirth: string;
  homeTown: string;
  eventName: string;
  notes: string;
  height: number;
  weight: number;
  federalId: string;
  weightClass: string;
  id: string;
  role: number;
}

type AddExistingParticipantModalProps = {
  open: boolean;
  handleClose: () => void;
  title?: string;
  sportType: string;
  isEditParticipant?: boolean;
  refetchParticipants?: VoidFunction;
  event: Partial<IEvent>;
  participant?: Partial<ParticipantInfo>;
  options: any;
};

const PARTICIPANT_ID_TYPE = {
  [FightMatchMemberTypes.ATHLETE]: 'athleteId',
  [FightMatchMemberTypes.JUDGE]: 'judgeId',
  [FightMatchMemberTypes.REFEREE]: 'refereeId',
};

const AddExistingParticipantModal = (props: AddExistingParticipantModalProps) => {
  const {
    open,
    handleClose,
    // title = 'THIS WILL UPDATE THE EVENT DETAILS THROUGHOUT THE SYSTEM. ',
    sportType,
    refetchParticipants,
    participant,
    event,
    options,
  } = props;

  const { mutateAsync: addOrUpdateEventSeeds } = useAddOrUpdateEventSeeds();

  const initialValues = {
    role: FightMatchMemberTypes.ATHLETE,
    members: [],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      members: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string().required('Label is required'),
          }),
        )
        .min(1, 'Please add at least one participant')
        .required('Required'),
    }),
    onSubmit: () => {
      const requestPayload = formatPayload();
      addOrUpdateEventSeeds(
        { sportType, payload: { items: requestPayload } },
        {
          onSettled: (_, err) => {
            if (!err) {
              enqueueSnackbar(`${participant ? 'Updated' : 'Added'} participants successfully!`);
              if (refetchParticipants) {
                refetchParticipants();
              }
            }
            handleClose();
          },
        },
      );
    },
  });

  const { values, setFieldValue, handleSubmit, errors, touched, setFieldTouched } = formik;

  const formatPayload = () => {
    const payload = values.members.map((member: any) => ({
      [PARTICIPANT_ID_TYPE[values.role]]: member.id,
      firstName: member?.firstName,
      lastName: member?.lastName,
      nickName: member?.nickName,
      dateOfBirth: member?.dateOfBirth
        ? zonedTimeToUtc(member?.dateOfBirth as unknown as Date, DEFAULT_TIMEZONE)
        : null,
      homeTown: member?.homeTown || '',
      federalId: member?.federalId || '',
      height: +member?.height || 0,
      weight: +member?.weight || 0,
      weightClass: member?.weightClass || '',
      role: +member?.role,
      eventName: event?.name || '',
    }));

    return payload;
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <form>
        <Box
          sx={{
            px: '1.3rem',
            py: 0.5,
            borderBottom: '1px solid #DEE2E6',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,

              fontSize: '1.25rem',
              lineHeight: '1.5rem',
            }}
            component="h2"
          >
            {'Add existing participant'}
          </Typography>
          <IconButton onClick={handleClose} disableRipple>
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container sx={{ gap: '0.6rem' }}>
            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Member Type</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <Select
                    name="role"
                    value={values.role}
                    onChange={(event: SelectChangeEvent<typeof values.role>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('role', value);
                      setFieldValue('members', []);
                      if (touched.members) {
                        setFieldTouched('members', false);
                      }
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                  >
                    {React.Children.toArray(
                      FIGHT_MEMBER_TYPES_OPTIONS?.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Members</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <Autocomplete
                  value={values.members}
                  multiple
                  options={options.filter((opt: any) => +opt.role === +values?.role)}
                  onChange={(_, value) => {
                    setFieldValue('members', [...value]);
                    if (!touched.members) {
                      setFieldTouched('members', true);
                    }
                  }}
                  fullWidth
                  sx={{
                    ...editCellTextFieldStyle,
                    '& .MuiInputBase-input': {
                      padding: '0.32rem 0.75rem',
                      fontSize: '0.812rem',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors?.members && touched?.members}
                      helperText={errors?.members && touched?.members ? errors?.members : undefined}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                }}
                fullWidth
                disableElevation
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button variant="contained" onClick={() => handleSubmit()} fullWidth disableElevation>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default AddExistingParticipantModal;
