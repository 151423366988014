// This util is to allow using notistack outside of functional components,
// this hacky way is from a thread (https://bit.ly/3tGejhX) and could get native support by notistack in the future,
// at which point this should be removed in favor of the stable approach.
import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';
import React from 'react';

let useSnackbarRef: WithSnackbarProps;
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export default {
  success(msg: string) {
    this.toast(msg, 'success');
  },
  warning(msg: string) {
    this.toast(msg, 'warning');
  },
  info(msg: string) {
    this.toast(msg, 'info');
  },
  error(msg: string) {
    this.toast(msg, 'error');
  },
  toast(msg: string, variant: VariantType = 'default') {
    useSnackbarRef.enqueueSnackbar(msg, { variant });
  },
};
