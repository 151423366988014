import SPORT_TYPES from '@/constants/sportTypes';

export const allowedWSLHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Rank', field: 'eventRank' },
  { title: 'Points', field: 'eventPoints' },
  { title: 'Event', field: 'eventName' },
  { title: 'Tour Year', field: 'eventYear' },
];

export const allowedSLSHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Rank', field: 'eventRank' },
  { title: 'Points', field: 'eventPoints' },
  { title: 'Event', field: 'eventName' },
  { title: 'Tour Year', field: 'eventYear' },
];

export const allowedNRXHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Rank', field: 'eventRank' },
  { title: 'Points', field: 'eventPoints' },
  { title: 'Event', field: 'eventName' },
  { title: 'Tour Year', field: 'eventYear' },
];

export const allowedTableHeaders = {
  [SPORT_TYPES.WSL]: allowedWSLHeaders,
  [SPORT_TYPES.SLS]: allowedSLSHeaders,
  [SPORT_TYPES.NRX]: allowedNRXHeaders,
};

interface CommonEventPointsRow {
  athlete: string;
  rank: number;
  points: number;
  event: string;
  tour_year: number;
}

type WSLEventPointsRow = CommonEventPointsRow;

type SLSEventPointsRow = CommonEventPointsRow;

type NRXEventPointsRow = CommonEventPointsRow;

export type EventPointsRow = WSLEventPointsRow & SLSEventPointsRow & NRXEventPointsRow;

const commonRequiredKeys = ['athlete', 'rank', 'points', 'event', 'tour_year'];

export const WSLRequiredKeys = [...commonRequiredKeys];

export const SLSRequiredKeys = [...commonRequiredKeys];

export const NRXRequiredKeys = [...commonRequiredKeys];

export const requiredPayloadKeys = {
  [SPORT_TYPES.WSL]: WSLRequiredKeys,
  [SPORT_TYPES.SLS]: SLSRequiredKeys,
  [SPORT_TYPES.NRX]: NRXRequiredKeys,
};
