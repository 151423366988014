import React from 'react';

import UserManagement from '@/features/user-management';
import Head from '@/components/Head';

const UserManagementPage = () => {
  return (
    <>
      <Head title="User Management" />
      <UserManagement />
    </>
  );
};

export default UserManagementPage;
