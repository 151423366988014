import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import useFetchFightParticipants from '@/hooks/fights/useFetchFightParticipants';
import useFetchAthletes from '@/hooks/fightSportMisc/useFetchAthletes';

import SPORT_TYPES from '@/constants/sportTypes';

import { displayInPT as format } from '@/helpers/timeConverters';

import { cmToFeetAndInches, kgToLbs } from '@/constants/misc';
import keys from '@/constants/queryKeys';

const updateDataFormatter = (seed: any) => {
  return {
    ...seed,
    name: seed?.athlete
      ? `${[seed?.athlete?.firstName, seed?.athlete?.middleName, seed?.athlete?.lastName]
          .filter(Boolean)
          .join(' ')}${seed?.athlete?.nickName ? ` (${seed?.athlete?.nickName})` : ''}`
      : seed?.judge
      ? `${[seed?.judge?.firstName, seed?.judge?.middleName, seed?.judge?.lastName]
          .filter(Boolean)
          .join(' ')}`
      : seed?.referee
      ? `${[seed?.referee?.firstName, seed?.referee?.middleName, seed?.referee?.lastName]
          .filter(Boolean)
          .join(' ')}`
      : '',
    homeTown: seed?.athlete?.homeTown || '',
    dateOfBirth: seed?.athlete?.dateOfBirth
      ? format(new Date(seed?.athlete?.dateOfBirth), 'MM/dd/yyyy')
      : '',
    federalId: seed?.athlete?.federalId || '',
    weightClass: seed?.athlete?.weightClass || '',
    weight: +seed?.athlete?.weight || '',
    height: +seed?.athlete?.height || '',
    feet: cmToFeetAndInches(Number(+seed?.athlete?.height || null) as number)?.feet || 0,
    inches: cmToFeetAndInches(Number(+seed?.athlete?.height || null) as number)?.inches || 0,
    lbs: kgToLbs(Number(+seed?.athlete?.weight || null) as number) || 0,
  };
};

const updateDatabaseDataFormatter = (seed: any) => {
  return {
    ...seed,
    name: `${[seed?.firstName, seed?.middleName, seed?.lastName].filter(Boolean).join(' ')}${
      seed?.nickName ? ` (${seed?.nickName})` : ''
    }`,
    label: `${[seed?.firstName, seed?.middleName, seed?.lastName].filter(Boolean).join(' ')}${
      seed?.nickName ? ` (${seed?.nickName})` : ''
    }`,
    value: seed.id,
    homeTown: seed?.homeTown || '',
    dateOfBirth: seed?.dateOfBirth ? format(new Date(seed?.dateOfBirth), 'MM/dd/yyyy') : '',
    federalId: seed?.federalId || '',
    weightClass: seed?.weightClass || '',
    weight: +seed?.weight || '',
    height: +seed?.height || '',
    feet: cmToFeetAndInches(Number(+seed?.height || null) as number)?.feet || 0,
    inches: cmToFeetAndInches(Number(+seed?.height || null) as number)?.inches || 0,
    lbs: kgToLbs(Number(+seed?.weight || null) as number) || 0,
  };
};

const initialFormatters = {
  [SPORT_TYPES.BYB]: updateDataFormatter,
  [SPORT_TYPES.POWERSLAP]: updateDataFormatter,
};

const ARCHIVED = 1;

const useFightEventSeeds = ({ sportType }: { sportType: string }) => {
  const queryClient = useQueryClient();
  const { eventId = '' } = useParams();
  const { data: eventSeeds, isFetching } = useFetchFightParticipants({
    sport: sportType,
    eventId,
    archived: ARCHIVED,
  });
  const { data: communityMembers } = useFetchAthletes(sportType, 'Event');

  const [tableData, setTableData] = useState<any[]>([]);
  const [modCommunityData, setModCommunityData] = useState<any[]>([]);

  const initialDataFormatter = initialFormatters[sportType];

  const initialDataLoader = (seeds: any[]) => {
    if (seeds && seeds?.length === 0) {
      setTableData([]);
    }
    const seedsCopy = JSON.parse(JSON.stringify(seeds));
    const formattedSeeds = seedsCopy.map((seed: any) => {
      return initialDataFormatter(seed);
    });
    setTableData(formattedSeeds);
  };

  useEffect(() => {
    if (eventSeeds && eventSeeds.length > 0) initialDataLoader(eventSeeds);
  }, [JSON.stringify(eventSeeds)]);

  const initialDatabaseDataLoader = (databaseSeeds: any[]) => {
    if (databaseSeeds && databaseSeeds?.length === 0) {
      setModCommunityData([]);
    }
    const databaseSeedsCopy = JSON.parse(JSON.stringify(databaseSeeds));
    const formattedDatabaseSeeds = databaseSeedsCopy.map(updateDatabaseDataFormatter);
    setModCommunityData(formattedDatabaseSeeds);
  };

  useEffect(() => {
    if (communityMembers) initialDatabaseDataLoader([...(communityMembers || [])]);
  }, [JSON.stringify(communityMembers)]);

  const refetchSeeds = () => {
    queryClient.invalidateQueries([
      keys.fights.fetchFightParticipants,
      sportType,
      eventId,
      ARCHIVED,
    ]);
  };

  useEffect(() => {
    return () => {
      refetchSeeds();
    };
  }, []);

  return {
    isFetching,
    eventSeeds,
    tableData,
    refetchSeeds,
    modCommunityData,
  };
};

export default useFightEventSeeds;
