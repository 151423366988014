import * as React from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import Box from '@mui/material/Box';

import WSLHeatScores from '@/components/WSLHeatScores';
import SLSHeatScores from '@/components/SLSHeatScores';
import NRXHeatScores from '@/components/NRXHeatScores';
import { EventListingSkeleton } from '@/components/PageSkeletons';

import useAddHeatScores from '@/hooks/heats/useAddHeatScores';
import usefetchAllHeats from '@/hooks/heats/useFetchAllHeats';

import { DEFAULT_START_OF_LAP_TIME, formatMMSSToSeconds } from '@/helpers/timeConverters';
import {
  invalidateAllCachedHeats,
  invalidateCachedEvent,
  invalidateCachedHeat,
} from '@/helpers/cachedQueries';

import SPORT_TYPES from '@/constants/sportTypes';
import { NRX_HEAT_SCORES_STATUS_OPTIONS } from '@/constants/nrxHeatScoresStatus';

import { AthleteDTO } from '@/types/athlete';
import { HeatDTO } from '@/types/heat';

interface HeatListViewProps {
  eventId: string;
  roundId: string;
  playerDropdownOptions: AthleteDTO[];
  sportType: string;
  fieldCount?: number;
  lineScoreCount?: number;
  roundNo?: number;
  lapsCountArr?: number[];
}

const HeatListView = (props: HeatListViewProps) => {
  const {
    eventId,
    roundId,
    playerDropdownOptions,
    sportType,
    fieldCount,
    lineScoreCount,
    roundNo,
  } = props;
  const { data: heats = [], isLoading, isFetching } = usefetchAllHeats(eventId, roundId, sportType);
  const [sortedHeats, setSortedHeats] = React.useState<HeatDTO[]>([]);
  const { mutate: addHeatScores } = useAddHeatScores(sportType);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    invalidateAllCachedHeats(queryClient, sportType, eventId, roundId);
  }, []);

  React.useEffect(() => {
    if (heats.length > 0) {
      const sortedHts = heats.sort((a: HeatDTO, b: HeatDTO) => a.heatNo - b.heatNo);
      setSortedHeats(sortedHts);
    }
  }, [heats]);

  const generatePayload = (finalValues: any, heat: HeatDTO, lapsCountArr: any[] = []) => {
    const scores = JSON.parse(JSON.stringify(finalValues.heatScores));
    const validScores = scores.filter((score: any) => score.athlete !== undefined);
    const data = validScores.map((rowData: any) => {
      const athlete = playerDropdownOptions.find(
        (athlete: any) => athlete.id === rowData.athlete.id,
      );

      const commonData = {
        seed: +rowData?.roundSeed,
        athleteId: athlete?.id || 0,
        heatPosition: +rowData.heatPosition,
      };

      // Payload athlete structure:
      // Delete row - id only
      // Update row - id, seed, ...
      // New row (no id) - seed, ...
      switch (sportType) {
        case SPORT_TYPES.WSL: {
          return {
            ...commonData,
            ...(rowData.id && { id: rowData.id }),
            ...(!rowData.toDelete && {
              heatScore: rowData.heatScore !== null ? +rowData.heatScore : null,
            }),
          };
        }
        case SPORT_TYPES.SLS: {
          const trickScr = Array(6 - 1 + 1)
            .fill(0)
            .map((_, idx) => 1 + idx)
            .reduce(
              (prev, key) => ({
                ...prev,
                [`trick${key}Score`]:
                  rowData[`trick${key}Score`] !== null ? +rowData[`trick${key}Score`] : null,
              }),
              {},
            );
          return {
            ...commonData,
            ...(rowData.id && { id: rowData.id }),
            ...(!rowData.toDelete && {
              roundScore: rowData.roundScore !== null ? +rowData.roundScore : null,
              lineScore1: rowData.lineScore1 !== null ? +rowData.lineScore1 : null,
              lineScore2: rowData.lineScore2 !== null ? +rowData.lineScore2 : null,
              ...trickScr,
            }),
          };
        }
        case SPORT_TYPES.NRX: {
          const status = NRX_HEAT_SCORES_STATUS_OPTIONS.find(
            (scoreStatus: any) => scoreStatus.label === rowData?.status?.label,
          );
          const FIRST_ROUND_NO = 1;
          return lapsCountArr?.map((lap) => {
            return {
              ...commonData,
              ...(rowData['lap_' + lap]?.storedId && {
                id: rowData['lap_' + lap]?.storedId,
              }),
              ...(!rowData.toDelete && {
                lapTime: formatMMSSToSeconds(rowData['lap_' + lap].lapTime),
                lapNumber: lap,
                status: status?.value || null,
                penaltyTime: formatMMSSToSeconds(rowData?.penaltyTime || DEFAULT_START_OF_LAP_TIME),
                ...(roundNo !== FIRST_ROUND_NO && {
                  isJoker: rowData['lap_' + lap]?.isJoker || false,
                }),
              }),
            };
          });
        }
        default:
          break;
      }
    });
    const athletes = sportType === SPORT_TYPES.NRX ? data.flat() : data;
    const payload = {
      startDate: heat.startDate,
      endDate: heat.endDate,
      athletes: athletes,
    };
    return payload;
  };

  const saveHeatScore = (requestedPayload: any, heatId: string) => {
    const payload = {
      eventId,
      ...requestedPayload,
    };
    addHeatScores(
      {
        heatId: heatId,
        payload: payload,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Added Heat Score Successfully');
          invalidateAllCachedHeats(queryClient, sportType, eventId, roundId);
          invalidateCachedHeat(queryClient, sportType, heatId);
          invalidateCachedEvent(queryClient, sportType, eventId, 'EventScores');
        },
      },
    );
  };

  return (
    <Box sx={{ marginTop: '1rem' }}>
      {isLoading || (isFetching && sortedHeats.length === 0) ? (
        <EventListingSkeleton />
      ) : (
        sortedHeats.map((heat: HeatDTO) => {
          return (
            (sportType === SPORT_TYPES.WSL && (
              <WSLHeatScores
                key={heat.id}
                heat={heat}
                players={playerDropdownOptions}
                generatePayload={generatePayload}
                saveHeatScore={saveHeatScore}
              />
            )) ||
            (sportType === SPORT_TYPES.SLS && (
              <SLSHeatScores
                key={heat.id}
                heat={heat}
                players={playerDropdownOptions}
                generatePayload={generatePayload}
                saveHeatScore={saveHeatScore}
                fieldCount={fieldCount}
                lineScoreCount={lineScoreCount}
                roundNo={roundNo as number}
                allHeats={heats || []}
              />
            )) ||
            (sportType === SPORT_TYPES.NRX && (
              <NRXHeatScores
                key={heat.id}
                heat={heat}
                players={playerDropdownOptions}
                generatePayload={generatePayload}
                saveHeatScore={saveHeatScore}
                roundNo={roundNo as number}
              />
            ))
          );
        })
      )}
    </Box>
  );
};

export default HeatListView;
