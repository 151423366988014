import React, { useEffect, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import useFetchEvent from '@/hooks/events/useFetchEvent';
import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';
import useFetchHeat from '@/hooks/heats/useFetchHeat';
import useAddHeatScores from '@/hooks/heats/useAddHeatScores';

import { sortRoundsByRoundNo, getSelectedRound, getRoundFromId } from '@/helpers/rounds';
import { addFullNameLabelProp } from '@/helpers/athletes';
import { invalidateParticipants } from '@/helpers/cachedQueries';
import {
  invalidateAllCachedHeats,
  invalidateCachedEvent,
  invalidateCachedHeat,
  invalidateCachedSports,
} from '@/helpers/cachedQueries';
import {
  formatUSACHeatScores,
  getSelectedHeatId,
  getPreviousAndNextHeat,
  getHeatFromId,
  getPreviousAndNextRound,
} from '@/helpers/heats';

import { HEAT_VIEW_MODES } from '@/constants/heats';
import SPORT_TYPES from '@/constants/sportTypes';

import { HeatDTO } from '@/types/heat';
import { RoundDTO } from '@/types/round';

const sportType = SPORT_TYPES.USAC;

const useUSACScore = () => {
  const { eventId = '' } = useParams();
  const [heatViewMode, setHeatViewMode] = useState<number>(HEAT_VIEW_MODES.default);
  const [selectedRoundId, setSelectedRoundId] = useState<string>('');
  const [selectedHeatId, setSelectedHeatId] = useState<string>('');
  const { data: event, isLoading: isLoadingEvent } = useFetchEvent(
    sportType,
    eventId,
    'EventScores',
  );
  const { data: players } = useFetchParticipants(sportType, eventId);
  const {
    data: heatScores,
    isLoading: isLoadingHeatScore,
    isFetching: isFetchingHeatScore,
  } = useFetchHeat(selectedHeatId, sportType);
  const { mutate: addHeatScores } = useAddHeatScores(sportType);
  const [processedHeatScores, setProcessedHeatScores] = useState({ heat: [], scores: [] });
  const [lapsCountArr, setLapsCountArr] = useState<number[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  /* Refetch data when event (cached) is visited again */
  useEffect(() => {
    return () => invalidateCachedEvent(queryClient, sportType, eventId, 'EventScores');
  }, [eventId]);

  /* Refetch data when event is changed */
  useEffect(() => {
    return () => invalidateParticipants(queryClient, sportType, eventId);
  }, [eventId]);

  /* Refetch heat when heat is changed/revisted */
  useEffect(() => {
    return () => invalidateCachedHeat(queryClient, sportType, selectedHeatId);
  }, [selectedHeatId]);

  /* Array of rounds of type RoundDTO[] for the current event */
  const roundList = React.useMemo(() => {
    if (!event) return [];
    return sortRoundsByRoundNo(event?.rounds);
  }, [event]);

  /* Selecting the appropriate round and heat, when component mounted */
  useEffect(() => {
    if (roundList.length > 0 && !selectedHeatId) configureRound(roundList);
  }, [roundList]);

  /* Selected round object */
  const selectedRound = useMemo(() => {
    return getRoundFromId(selectedRoundId, roundList);
  }, [selectedRoundId, roundList]);

  /* Selected heat object */
  const selectedHeat = useMemo(() => {
    return getHeatFromId(selectedHeatId, selectedRound);
  }, [selectedHeatId, selectedRound]);

  /* Previous and next heat objects */
  const { prevHeat, nextHeat } = useMemo(() => {
    return getPreviousAndNextHeat(selectedRound, selectedHeatId);
  }, [selectedHeat]);

  /* Previous and next round objects */
  const { nextRound } = useMemo(() => {
    return getPreviousAndNextRound(roundList, selectedRoundId);
  }, [selectedRound]);

  const playerDropdownOptions = React.useMemo(() => addFullNameLabelProp(players), [players]);

  useEffect(() => {
    // The scores data from the GET request comes with each entry representing info about a lap for each athlete,
    // The below code formats individual lap data into objects for each athlete that contains all of their lap/s data.
    const { sortedHeatScores, lapsArr } = formatUSACHeatScores(heatScores?.scores);

    setLapsCountArr(lapsArr);
    setProcessedHeatScores({
      heat: heatScores?.heat && JSON.parse(JSON.stringify(heatScores?.heat)),
      scores: sortedHeatScores && JSON.parse(JSON.stringify(sortedHeatScores)),
    });
  }, [heatScores]);

  /* To auto advance to next heat or round, when a heat was ended */
  const autoAdvanceRoundOrHeat = () => {
    // Auto advance to next heat if it exists.
    if (nextHeat) {
      configureHeats(selectedRound?.heats || [], nextHeat.id);
    } // Auto advance to next round if there's no next heat and there is a next round.
    else if (!nextHeat && nextRound) {
      configureRound(roundList, nextRound);
    }
  };

  const heatScoreRequestHandler = (
    requestedPayload: any,
    {
      message = 'Added Heat Score Successfully',
      isEndHeatHandler = false,
    }: {
      message?: string;
      isEndHeatHandler?: boolean;
    },
  ) => {
    addHeatScores(
      {
        heatId: selectedHeatId,
        payload: {
          eventId,
          ...requestedPayload,
        },
      },
      {
        onSuccess: () => {
          const firstHeatId = selectedRound?.heats[0].id;
          const firstRoundId = roundList[0].id;
          const finalRoundId = roundList[roundList.length - 1].id;
          const isFinalRound = selectedRoundId === finalRoundId;
          const isFirstRoundFirstHeat =
            selectedRoundId === firstRoundId && selectedHeatId === firstHeatId;
          enqueueSnackbar(message);
          invalidateCachedHeat(queryClient, sportType, selectedHeatId);
          invalidateAllCachedHeats(queryClient, sportType, eventId, selectedRoundId);
          if (isEndHeatHandler) autoAdvanceRoundOrHeat();
          if ((!nextHeat && isFinalRound) || (isFirstRoundFirstHeat && !isEndHeatHandler)) {
            invalidateCachedEvent(queryClient, sportType, eventId, 'Event');
            invalidateCachedSports(queryClient);
          }
          invalidateCachedEvent(queryClient, sportType, eventId, 'EventScores');
        },
      },
    );
  };

  const saveHeatScore = (requestedPayload: any) => {
    const payload = {
      eventId,
      ...requestedPayload,
    };
    heatScoreRequestHandler(payload, {});
  };

  const endHeat = (requestedPayload: any) => {
    const payload = {
      eventId,
      hasHeatEnded: true,
      ...requestedPayload,
    };
    heatScoreRequestHandler(payload, {
      message: 'Ended Heat Successfully',
      isEndHeatHandler: true,
    });
  };

  const configureHeats = (heats: HeatDTO[], currentlySelectedHeatId: string) => {
    const newHeatId = getSelectedHeatId(heats, currentlySelectedHeatId);
    if (newHeatId) setSelectedHeatId(newHeatId);
  };

  const configureRound = (rounds: RoundDTO[], nextRound: RoundDTO | null = null) => {
    if (!nextRound) {
      nextRound = getSelectedRound(rounds);
    }
    if (nextRound) {
      setSelectedRoundId(nextRound.id);
      configureHeats(nextRound.heats || [], '');
    }
  };

  const handleCurrentRound = (round: any) => {
    setSelectedRoundId(round.id);
    configureHeats(round?.heats || [], '');
  };

  const handleCurrentHeat = (heat: any) => {
    configureHeats(selectedRound?.heats || [], heat?.id || '');
  };

  return {
    isLoadingEvent,
    isLoadingHeatScore,
    isFetchingHeatScore,
    processedHeatScores,
    lapsCountArr,
    heatViewMode,
    setHeatViewMode,
    prevHeat,
    nextHeat,
    heatScores,
    playerDropdownOptions,
    saveHeatScore,
    endHeat,
    handleCurrentRound,
    handleCurrentHeat,
    roundList,
    selectedRound,
    event,
    selectedHeat,
  };
};

export default useUSACScore;
