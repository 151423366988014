import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import MaterialTable, { Column, EditComponentProps } from '@material-table/core';
import {
  Typography,
  Box,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  Button,
} from '@mui/material';
import round from 'lodash.round';
import { useParams } from 'react-router-dom';

// import FirstPageIcon from '@mui/icons-material/FirstPage';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import LastPageIcon from '@mui/icons-material/LastPage';

// import { styled } from '@mui/material/styles';
// import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';

import { oddsType } from '@/atoms/oddsType';

import DecimalField from '@/components/DecimalField';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
// import ResetOddsBtn from '@/components/ResetOddsBtn';
// import SaveOddsBtn from '@/components/SaveOddsBtn';
// import ExportBtn from '@/components/ExportBtn';
// import PublishOdds from '@/components/PublishOdds';

// import useFetchDreamTeamOdds from '@/hooks/odds/headToHeadOdds/useFetchDreamTeamOdds';
// import useAddDreamTeamOdds from '@/hooks/odds/headToHeadOdds/useAddDreamTeamOdds';
// import useUpdateDreamTeamOdds from '@/hooks/odds/headToHeadOdds/useUpdateDreamTeamOdds';
// import useUpdateDreamTeamOddsPayout from '@/hooks/odds/headToHeadOdds/useUpdateDreamTeamOddsPayout';

// import { initialDataLoader, headToHeadFreshWinnerDrawCount } from '@/helpers/playerDreamTeam';
// import { excelDownloader } from '@/helpers/fileDownloader';
// import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidateDreamTeamOdds } from '@/helpers/cachedQueries';

// import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import ODDS_TYPES from '@/constants/oddsTypes';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';
import { fractionPattern } from '@/constants/misc';
// import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import * as httpStatusCodes from '@/constants/httpStatus';
// import { SPORT_NAMES } from '@/constants/sportTypes';
// import keys from '@/constants/queryKeys';

import useUpdateDreamTeamOdds from '@/hooks/odds/dreamTeamOdds/useUpdateDreamTeamOdds';
import useUpdateDreamTeamOddsPayout from '@/hooks/odds/dreamTeamOdds/useUpdateDreamTeamOddsPayout';

// import { PlayerDreamTeamOddRow } from '@/types/playerDreamTeamTab';
// import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';
// import { UpdateDreamTeamOddsPayoutPayloadDTO } from '@/types/odds/updateOddsPayout';
// import { AddOddsPayloadDTO } from '@/types/odds/addOdds';
import * as oddsHelpers from '@/helpers/odds';

import {
  tableIcons,
  tableCellStyle,
  editCellTextFieldStyle,
  defaultTableOptions,
  headToHeadTableStyles,
} from '@/utils/TableMisc';
import {
  dreamTeamFormatter,
  dreamTeamHoldPercentageModifier,
  findTotalMatchupPercentage,
  matchupEditRowFormatter,
  dreamTeamMatchupPayloadFormatter,
} from '@/helpers/dreamTeam';

import { DreamTeamOddRow, Team } from '@/types/odds/getOdds';
import { UpdateDreamTeamOddsPayoutPayloadDTO } from '@/types/odds/updateOddsPayout';

const DreamTeamMatchup = (props: { dreamTeam: DreamTeamOddRow; idx: number; sport: string }) => {
  const { eventId = '' } = useParams();
  const { dreamTeam, idx, sport } = props;
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [holdPercentage, setHoldPercentage] = useState(
    oddsHelpers.getTotalProbability(dreamTeam?.teams || []),
  );
  const [matchupData, setMatchupData] = useState<DreamTeamOddRow | null>(null);

  const { mutateAsync: updateDreamTeamOdds } = useUpdateDreamTeamOdds();
  const { mutateAsync: updateDreamTeamOddsPayout } = useUpdateDreamTeamOddsPayout();

  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited((prev) => !prev);

  const [hasPayoutBeenClicked, setHasPayoutBeenClicked] = useState(false);
  const handleToggleHasPayoutBeenClicked = () => setHasPayoutBeenClicked((prev) => !prev);

  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const handleToggleConfirmDialog = () => setShowOddsUpdateDialog((prev) => !prev);

  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const handleToggleResetDialog = () => setShowResetOddsDialog((prev) => !prev);

  const [isConfirmVoidDialogOpen, setIsConfirmVoidDialogOpen] = useState(false);
  const handleToggleConfirmVoidDialog = () => setIsConfirmVoidDialogOpen((prev) => !prev);

  const [isConfirmDrawDialogOpen, setIsConfirmDrawDialogOpen] = useState(false);
  const handleToggleConfirmDrawDialog = () => setIsConfirmDrawDialogOpen((prev) => !prev);

  const [oddType] = useRecoilState(oddsType);

  const setInitialMatchupData = (dTeam: DreamTeamOddRow) => {
    setMatchupData(dreamTeamFormatter(dTeam));
    // total probability value increases cause of odds modification
    const calculatedProbability = oddsHelpers.getTotalProbability(dTeam?.teams || []);
    setHoldPercentage(calculatedProbability);
    if (hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  React.useEffect(() => {
    if (dreamTeam) {
      setInitialMatchupData(dreamTeam);
    }
  }, [JSON.stringify(dreamTeam)]);

  const columns: Column<Team>[] = [
    {
      title: 'Team',
      field: 'team',
      editable: 'never',
      width: '5%',
      defaultSort: 'asc',
      render: (row: Team) => (
        <Typography component="span" sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
          {row?.team || ''}
        </Typography>
      ),
    },
    {
      title: 'Participants',
      field: 'participantNames',
      editable: 'never',
      width: '75%',
      sorting: false,
      render: (row: Team) => (
        <Typography component="span" sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
          {row?.participantNames || '-'}
        </Typography>
      ),
    },
    {
      title: 'Decimal Odds',
      field: 'decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      width: '15%',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: Team) => {
        const newValue = row?.decimalOdds ? +row?.decimalOdds : oddRanges.MIN_DECIMAL_ODDS;
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : newValue > oddRanges.MAX_DECIMAL_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: EditComponentProps<Team> & { helperText?: string }) => (
        <DecimalField
          value={props.value}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            const odds = +e.target.value;
            const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              odds: +e.target.value,
              decimalOdds: e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          thousandSeparator={false}
          decimalScale={2}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Fractional Odds',
      field: 'fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      width: '15%',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: Team) => {
        const newValue = (row?.fractionalOdds || oddRanges.MIN_FRACTION_ODDS).toString();
        const convertedOdd = oddsHelpers.decimalFromFraction(newValue);
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : convertedOdd > oddRanges.MAX_FRACTION_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: EditComponentProps<Team> & { helperText?: string }) => (
        <TextField
          value={props.value}
          onBlur={(e) => {
            const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, e.target.value);
            const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
            const fractionalOdds = e.target.value;
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              fractionalOdds: e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'American Odds',
      field: 'americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      width: '15%',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: Team) => {
        const newValue = Number(row?.americanOdds);
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS &&
          newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG,
            }
          : newValue < oddRanges.MIN_NEGATIVE_AMERICAN_ODDS ||
            newValue > oddRanges.MAX_AMERICAN_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: EditComponentProps<Team> & { helperText?: string }) => (
        <DecimalField
          value={props.value}
          onBlur={(e: any) => {
            const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, +e.target.value);
            const probability = round(oddsHelpers.getProbabilityFromDecimalOdds(odds), 1);
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          thousandSeparator={false}
          decimalScale={0}
          allowNegative={true}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
    {
      title: 'Probability',
      field: 'probability',
      width: '15%',
      align: 'left',
      // customSort: (a: Team, b: Team) => a.probability - b.probability,
      validate: (row: Team) => {
        const totalPercentageModified = findTotalMatchupPercentage(
          matchupData?.teams || [],
          row?.team as string,
          {
            modifiedRowsOnly: true,
          },
        );
        const newRemainingTotalProbability = holdPercentage - totalPercentageModified;
        return +row?.probability < 0
          ? 'Enter Probability'
          : +row?.probability > newRemainingTotalProbability ||
            +row?.probability > oddRanges.MAX_PROBABILITY
          ? `Probability can't be more than ${
              newRemainingTotalProbability < oddRanges.MAX_PROBABILITY
                ? round(newRemainingTotalProbability, 2)
                : oddRanges.MAX_PROBABILITY
            }`
          : true;
      },
      editComponent: (props: EditComponentProps<Team> & { helperText?: string }) => (
        <TextField
          variant="outlined"
          value={round(+props?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            const probability = +e.target.value;
            const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
            const decimalOdds = odds.toString();
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onRowDataChange({
              ...props.rowData,
              probability: +e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
      cellStyle: tableCellStyle,
    },
  ];

  const refreshDreamTeamOdds = () => {
    invalidateDreamTeamOdds(queryClient, sport, eventId);
  };

  const saveDreamTeamOddsData = async (
    payload: { teams: Team[] },
    message = 'Successfully updated odds',
  ) => {
    const response = await updateDreamTeamOdds(
      { sport, eventId, dreamTeamId: dreamTeam?.id, payload },
      {
        onSettled: (_, err) => {
          if (!err) {
            enqueueSnackbar(message);
            refreshDreamTeamOdds();
            if (hasOddsBeenEdited) {
              handleToggleHasOddsBeenEdited();
            }
          }
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const updateOddsData = async () => {
    const payload = dreamTeamMatchupPayloadFormatter(matchupData?.teams || []);
    await saveDreamTeamOddsData({ teams: payload });
  };

  const handleDreamTeamConfirmPayout = async ({
    payload,
    message = 'Voided Successfully!',
  }: {
    payload: UpdateDreamTeamOddsPayoutPayloadDTO;
    message?: string;
  }) => {
    const response = await updateDreamTeamOddsPayout(
      { sport, eventId, payload },
      {
        onSettled: (_, err) => {
          if (!err) {
            enqueueSnackbar(message);
            refreshDreamTeamOdds();
          }
          if (hasPayoutBeenClicked) {
            handleToggleHasPayoutBeenClicked();
          }
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleConfirmPayout = async ({
    voided,
    draw,
    message = 'Voided Successfully!',
  }: {
    voided?: boolean;
    draw?: boolean;
    message?: string;
  }) => {
    if (voided) {
      handleToggleConfirmVoidDialog();
    }
    if (draw) {
      handleToggleConfirmDrawDialog();
    }
    const payload = {
      items: [
        {
          id: dreamTeam.id,
          ...(voided ? { voided: !!voided } : {}),
          ...(draw ? { draw: !!draw } : {}),
        },
      ],
    };
    await handleDreamTeamConfirmPayout({
      payload: { items: payload.items },
      message,
    });
  };

  const MIN_HOLD_PERCENTAGE: number = React.useMemo(() => {
    if (matchupData && matchupData.teams && matchupData.teams.length > 0) {
      let total = 0;
      matchupData.teams.map((data: any) => {
        total += +data?.trueProbability;
        return data;
      });
      return round(total);
    }
    return 100;
  }, [matchupData]);

  // Max limit value from the default hold percentage
  const MAX_PERCENTAGE_LIMIT_VALUE = 1.25;
  const MAX_ALLOWED_HOLD_PERCENTAGE_VALUE = MIN_HOLD_PERCENTAGE * MAX_PERCENTAGE_LIMIT_VALUE;

  const MAX_HOLD_PERCENTAGE = MIN_HOLD_PERCENTAGE * 2;

  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography
          sx={{ fontSize: '1.25rem', fontWeight: 500, lineHeight: '120%' }}
        >{`Dream Team Matchup ${idx + 1}`}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: { xs: 'flex-start', sm: 'center' },
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'info.main' }}>
            HOLD PERCENTAGE:
          </Typography>
          &nbsp;
          <Select
            id="selectHoldPercentage"
            value={holdPercentage}
            onChange={(event: SelectChangeEvent<typeof holdPercentage>) => {
              const {
                target: { value },
              } = event;
              const newHoldPercentage = Number(value);
              if (newHoldPercentage !== holdPercentage) {
                setMatchupData({
                  ...dreamTeam,
                  teams: dreamTeamHoldPercentageModifier({
                    teams: matchupData?.teams || [],
                    newHoldPercentage,
                    oldHoldPercentage: holdPercentage,
                    defaultHoldPercentage: MIN_HOLD_PERCENTAGE,
                  }),
                });
                setHoldPercentage(newHoldPercentage);
                if (!hasOddsBeenEdited) {
                  handleToggleHasOddsBeenEdited();
                }
              }
            }}
            sx={{ height: 30 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            disabled={dreamTeam?.voided || dreamTeam?.draw}
          >
            {React.Children.toArray(
              holdPercentagesOptions.map((percent) => (
                <MenuItem
                  value={percent}
                  disabled={
                    percent > MAX_ALLOWED_HOLD_PERCENTAGE_VALUE
                      ? // || !canSelectHoldPercentage[percent]
                        true
                      : false
                  }
                >
                  {percent}
                </MenuItem>
              )),
            )}
          </Select>
          &nbsp;&nbsp;
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              color: 'neutral.main',
              marginLeft: '0.75rem',
            }}
          >
            MARGIN:
          </Typography>
          &nbsp;
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'neutral.main' }}>
            {holdPercentage - MIN_HOLD_PERCENTAGE}%
          </Typography>
          &nbsp;
        </Box>
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '0.5rem' }}
        >
          <Button
            variant="contained"
            color="secondary"
            disabled={!hasOddsBeenEdited}
            onClick={() => {
              handleToggleResetDialog();
            }}
            disableElevation
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            disabled={hasPayoutBeenClicked || dreamTeam?.voided || dreamTeam?.draw}
            onClick={() => {
              handleToggleConfirmVoidDialog();
              handleToggleHasPayoutBeenClicked();
            }}
            disableElevation
          >
            Void
          </Button>
          <Button
            variant="outlined"
            disabled={hasPayoutBeenClicked || dreamTeam?.voided || dreamTeam?.draw}
            onClick={() => {
              handleToggleConfirmDrawDialog();
              handleToggleHasPayoutBeenClicked();
            }}
            disableElevation
          >
            Draw
          </Button>
          <Button
            variant="contained"
            disabled={!hasOddsBeenEdited}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
            disableElevation
          >
            Save
          </Button>
        </Box>
      </Box>
      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={matchupData?.teams || []}
        options={{
          rowStyle: () => {
            return { backgroundColor: dreamTeam.voided ? grey[300] : '' };
          },
          actionsColumnIndex: -1,
          ...defaultTableOptions,
          sorting: true,
          thirdSortClick: false,
          toolbar: false,
          paging: false,
        }}
        editable={{
          isDeleteHidden: () => true,
          isEditable: () => !dreamTeam?.voided && !dreamTeam?.draw,
          onRowUpdate:
            dreamTeam?.voided || dreamTeam?.draw
              ? undefined
              : (team: Team) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      const result = matchupEditRowFormatter(
                        { ...team, hasModifiedProbability: true },
                        matchupData?.teams || [],
                      );
                      setMatchupData({ ...(matchupData as DreamTeamOddRow), teams: result });
                      if (!hasOddsBeenEdited) {
                        handleToggleHasOddsBeenEdited();
                      }
                      resolve('success');
                    }, 1000);
                  }),
          onRowDelete: () =>
            new Promise(() => {
              //
            }),
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            updateOddsData();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            setInitialMatchupData(dreamTeam);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
      {isConfirmVoidDialogOpen && (
        <ConfirmDialog
          open={isConfirmVoidDialogOpen}
          handleClose={() => {
            handleToggleConfirmVoidDialog();
          }}
          handleConfirm={() => handleConfirmPayout({ voided: true })}
          title={'ARE YOU SURE YOU WANT TO VOID DREAM TEAM MATCHUP?'}
          body={'This will void the dream team matchup.'}
        />
      )}
      {isConfirmDrawDialogOpen && (
        <ConfirmDialog
          open={isConfirmDrawDialogOpen}
          handleClose={() => {
            handleToggleConfirmDrawDialog();
          }}
          handleConfirm={() => handleConfirmPayout({ draw: true, message: 'Draw Successfully!' })}
          title={'ARE YOU SURE YOU WANT TO DRAW DREAM TEAM MATCHUP?'}
          body={'This will draw the dream team matchup.'}
        />
      )}
    </Box>
  );
};

export default DreamTeamMatchup;
