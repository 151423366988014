export const HEADER_BACKGROUND_COLOR = {
  ACTIVE: '#E8F6F0',
  SUSPENDED: '#FBEBEC',
};

export const BACKGROUND_COLOR = {
  ACTIVE: '#FAFAFA',
  SUSPENDED: '#FAF2F3',
};

export const BORDER_COLOR = {
  ACTIVE: '#EFEFF0',
  SUSPENDED: '#FFE3E5',
};

export const impossibleOutcomeBoxShadowStyles = {
  boxShadow: `0 0 0 1px ${BORDER_COLOR.SUSPENDED}`,
};

export const BTN_BG_COLOR = {
  ACTIVE: '#37AF55',
  SUSPENDED: '#D3393E',
};

export const TEXT_COLOR = {
  GRAY: '#61666B',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
};

export const teamTypeToKey = {
  homeTeam: 'homeTeam',
  awayTeam: 'awayTeam',
};

export const EXTERNAL_UPDATE_TRIGGER_FIELDS = {
  lean: 'lean',
  bias: 'bias',
  calculatedValue: 'calculatedValue',
};

export const fieldsThatTriggerExternalUpdates = [
  EXTERNAL_UPDATE_TRIGGER_FIELDS.lean,
  EXTERNAL_UPDATE_TRIGGER_FIELDS.bias,
  EXTERNAL_UPDATE_TRIGGER_FIELDS.calculatedValue,
];

export const FIELD_TO_DEBUG = {
  id: 'id',
  playerLean: 'playerLean',
  isSubMarketLocked: 'isSubMarketLocked',
  betType: 'betType',
  marketType: 'marketType',
  subMarketType: 'subMarketType',
  bias: 'bias',
  lean: 'lean',
  probability: 'probability',
  trueProbability: 'trueProbability',
};
