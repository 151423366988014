import * as React from 'react';
import { Column } from '@material-table/core';
import { tableCellStyle } from '@/utils/TableMisc';
import { Box, styled, Typography } from '@mui/material';
import { ROLES } from '@/constants/user';

const TAG_BG_COLOR = {
  DEFAULT: '#E9ECEF',
  TRADER: 'rgba(5, 164, 250, 0.16)',
  SCHEDULE_MANAGER: 'rgba(167, 43, 225, 0.16)',
  DATA_LOGGER: 'rgba(3, 186, 140, 0.16)',
};

const TAG_TEXT_COLOR = {
  DEFAULT: '#212529',
  TRADER: '#05A4FA',
  SCHEDULE_MANAGER: '#A72BE1',
  DATA_LOGGER: '#03BA8C',
};

const Tag = styled(Typography)<{ component?: string }>({
  fontSize: '0.6875rem',
  lineHeight: '1rem',
  borderRadius: '4px',
  padding: '0.0625rem 0.25rem',
});

const fetchRoleTagColor = (role: number) => {
  switch (role) {
    case ROLES.TRADER:
    case ROLES.HEAD_TRADER:
      return { backgroundColor: TAG_BG_COLOR.TRADER, color: TAG_TEXT_COLOR.TRADER };
    case ROLES.SCHEDULE_MANAGER:
      return {
        backgroundColor: TAG_BG_COLOR.SCHEDULE_MANAGER,
        color: TAG_TEXT_COLOR.SCHEDULE_MANAGER,
      };
    case ROLES.DATA_LOGGER:
      return { backgroundColor: TAG_BG_COLOR.DATA_LOGGER, color: TAG_TEXT_COLOR.DATA_LOGGER };
    default:
      return { backgroundColor: TAG_BG_COLOR.DEFAULT, color: TAG_TEXT_COLOR.DEFAULT };
  }
};

const TableMatchResultsColumns: Column<any>[] = [
  {
    title: 'Name',
    field: 'name',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Email',
    field: 'email',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Phone number',
    field: 'phone',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Role',
    field: 'userTypeMod',
    cellStyle: tableCellStyle,
    render: (rowData: any) => (
      <Tag sx={{ ...fetchRoleTagColor(rowData?.userType) }} component="span">
        {rowData?.userTypeMod}
      </Tag>
    ),
    editable: 'never',
  },
  {
    title: 'Restricted Sports',
    field: 'userRestrictedSports',
    cellStyle: tableCellStyle,
    render: (rowData: any) => (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        {React.Children.toArray(
          rowData?.userRestrictedSportsMod
            ?.slice(0, 3)
            ?.map((sport: string) => (
              <Tag sx={{ backgroundColor: TAG_BG_COLOR.DEFAULT, color: TAG_TEXT_COLOR.DEFAULT }}>
                {`${sport} ${rowData?.userRestrictedSportsMod > 3 ? '...' : ''}`}
              </Tag>
            )),
        )}
        {rowData?.userRestrictedSportsMod.length > 3 ? (
          <Tag sx={{ backgroundColor: TAG_BG_COLOR.DEFAULT, color: TAG_TEXT_COLOR.DEFAULT }}>
            +{rowData?.userRestrictedSportsMod.length - 3}&nbsp;sports
          </Tag>
        ) : (
          ''
        )}
      </Box>
    ),
    editable: 'never',
  },
];

export default TableMatchResultsColumns;
