import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface DELETE_BYB_SCORES {
  sport: string;
  eventId: string;
  roundHeatId: string;
}

const deleteBYBScores = async ({ sport, eventId, roundHeatId }: DELETE_BYB_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/traders/events/${eventId}/${roundHeatId}/cancel`,
  });

  return data;
};

export default function useDeleteBYBScores() {
  return useMutation(({ sport, eventId, roundHeatId }: DELETE_BYB_SCORES) =>
    deleteBYBScores({ sport, eventId, roundHeatId }),
  );
}
