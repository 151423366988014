import SPORT_TYPES from '@/constants/sportTypes';

export const allowedWSLHeaders = [
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Tour Year', field: 'year' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Stop Number', field: 'stopNumber' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Heat Number', field: 'heatNumber' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Heat Score', field: 'heatScoreMod' },
];

export const allowedSLSHeaders = [
  { title: 'Event Name', field: 'eventName' },
  { title: 'League Name', field: 'leagueName' },
  { title: 'League Year', field: 'leagueYear' },
  { title: 'League Gender', field: 'leagueGender' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Heat Number', field: 'heatNumber' },
  { title: 'Line Score 1', field: 'lineScore1' },
  { title: 'Line Score 2', field: 'lineScore2' },
  { title: 'Round Score', field: 'roundScore' },
  { title: 'Trick 1 Score', field: 'trick1Score' },
  { title: 'Trick 2 Score', field: 'trick2Score' },
  { title: 'Trick 3 Score', field: 'trick3Score' },
  { title: 'Trick 4 Score', field: 'trick4Score' },
  { title: 'Trick 5 Score', field: 'trick5Score' },
  { title: 'Trick 6 Score', field: 'trick6Score' },
];

export const allowedNRXHeaders = [
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Tour Year', field: 'year' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Stop Number', field: 'stopNumber' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Heat Number', field: 'heatNumber' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Heat Score', field: 'lapTimeMod' },
  { title: 'Lap Number', field: 'lapNumber' },
  { title: 'Is Joker', field: 'isJoker' },
  { title: 'Is Bye', field: 'isBye' },
  { title: 'Status', field: 'status' },
];

export const allowedSPRHeaders = [
  { title: 'Event Name', field: 'eventName' },
  { title: 'League Year', field: 'year' },
  { title: 'League Gender', field: 'gender' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Heat Number', field: 'heatNumber' },
  { title: 'Stop Number', field: 'stopNumber' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Heat Score', field: 'lapTimeMod' },
  { title: 'Lap Number', field: 'lapNumber' },
  { title: 'Notes', field: 'notes' },
];

export const allowedTableHeaders = {
  [SPORT_TYPES.WSL]: allowedWSLHeaders,
  [SPORT_TYPES.SLS]: allowedSLSHeaders,
  [SPORT_TYPES.NRX]: allowedNRXHeaders,
  [SPORT_TYPES.SPR]: allowedSPRHeaders,
};

interface CommonTraderScoreRow {
  event_name: string;
  year: number;
  gender: string;
  round: string;
  athlete: string;
  heat_number: number;
  notes?: string;
}

interface WSLTraderScoreRow extends CommonTraderScoreRow {
  stop_number?: number;
  tour_name?: string;
  heat_score?: number;
}

export interface SLSRoundScoreRow extends CommonTraderScoreRow {
  league?: string;
  event_location?: string;
  // run_score?: number;
  round_score?: number;
}

export interface SLSLineScoreRow extends SLSRoundScoreRow {
  line_score?: number;
  line_number?: number;
}

interface NRXTraderScoreRow extends CommonTraderScoreRow {
  stop_number?: number;
  tour_name?: string;
  heat_score?: string;
  lap_number?: number;
  is_joker?: string;
  is_bye?: string;
  status?: string;
}

interface SPRTraderScoreRow extends CommonTraderScoreRow {
  // league?: string;
  // event_location?: string;
  stop_number?: number;
  tour_name?: string;
  heat_score?: string;
  lap_number?: number;
}

export type TraderScoreRow = WSLTraderScoreRow & NRXTraderScoreRow & SPRTraderScoreRow;

interface SLSTraderTrickScoreRow extends SLSRoundScoreRow {
  trick_score?: number;
  trick_number?: number;
}

export type TraderTrickScoreRow = SLSTraderTrickScoreRow;

const commonRequiredKeys = ['athlete', 'gender', 'year', 'event_name', 'round', 'heat_number'];

export const WSLRequiredKeys = [...commonRequiredKeys, 'tour_name', 'heat_score', 'stop_number'];

export const SLSRoundScoreRequiredKeys = [
  ...commonRequiredKeys,
  'league',
  'event_location',
  'round_score',
];

export const SLSLineScoreRequiredKeys = [
  ...commonRequiredKeys,
  'league',
  'event_location',
  'line_number',
];

export const SLSTrickScoreRequiredKeys = [...commonRequiredKeys, 'trick_score', 'trick_number'];

export const NRXRequiredKeys = [
  ...commonRequiredKeys,
  'tour_name',
  'stop_number',
  'heat_score',
  'lap_number',
];

export const SPRRequiredKeys = [
  ...commonRequiredKeys,
  'tour_name',
  'stop_number',
  'heat_score',
  'lap_number',
];

export const requiredPayloadKeys = {
  [SPORT_TYPES.WSL]: WSLRequiredKeys,
  // [SPORT_TYPES.SLS]: SLSRequiredKeys,
  [SPORT_TYPES.NRX]: NRXRequiredKeys,
  [SPORT_TYPES.SPR]: SPRRequiredKeys,
};
