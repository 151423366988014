import * as React from 'react';
import * as Yup from 'yup';
import round from 'lodash.round';
import { useFormik } from 'formik';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Collapse, IconButton, Tooltip, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { styled } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import DecimalField from '@/components/DecimalField';
import StartEndTimes from '@/components/StartEndTimes';

import { processHeatStatusBadges } from '@/helpers/events';
import {
  addFullnameLabelAndDeleteProps,
  calcSLSRoundScores,
  getUpdatedSLSRanksInScores,
} from '@/helpers/heats';

import { HeatStatus } from '@/constants/heats';
import { AthleteDTO } from '@/types/athlete';
import { HeatDTO, HeatScoresDTO } from '@/types/heat';
import orderBy from 'lodash.orderby';

interface SLSHeatScoresProps {
  heat: HeatDTO;
  players: AthleteDTO[];
  fieldCount?: number;
  lineScoreCount?: number;
  saveHeatScore: (values: any, heatId: string) => void;
  generatePayload: Function;
  roundNo: number;
  allHeats: HeatDTO[];
}

const HeatTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: '500',
  color: theme.palette.info.main,
  lineHeight: '1rem',
}));

const Container = styled(Paper)(({ theme }) => ({
  padding: '1rem 0',
  borderBottom: '0.12rem solid #DEE2E6',
  borderRadius: '0',
  boxShadow: 'none',
  ':first-of-type': {
    borderTop: '0.12rem solid #DEE2E6',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '0.06rem solid #CED4DA',
  },
  '& .MuiInputBase-input': {
    padding: '0.5rem 0.625rem !important',
    fontSize: '0.875rem',
    fontWeight: '400',
    color: theme.palette.info.main,
  },
  '& .MuiAutocomplete-inputRoot': {
    padding: '0 !important',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '0.625rem',
    margin: '0.125rem 0.25rem 0 0.25rem',
    lineHeight: '1rem',
  },
  '& .MuiChip-root': {
    fontSize: '0.687rem',
    fontWeight: '600',
    maxHeight: '1.125rem',
  },
  '& .MuiChip-label': {
    padding: '0 0.25rem',
  },
}));

const initialAthleteOption = { id: '', label: '' };

const SLSHeatScores = (props: SLSHeatScoresProps) => {
  const {
    heat,
    players,
    fieldCount = 6,
    lineScoreCount = 2,
    saveHeatScore,
    generatePayload,
    roundNo,
    allHeats,
  } = props;
  const theme = useTheme();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const heatScores = React.useMemo(() => {
    return heat.scores || [];
  }, [heat]);

  const otherHeatsScores = React.useMemo(() => {
    const allRoundScores: number[] = [];
    if (allHeats.length === 1) return allRoundScores;
    allHeats.forEach((currentHeat: HeatDTO) => {
      if (currentHeat.id !== heat.id)
        currentHeat.scores.map((score: any) => {
          allRoundScores.push(round(+score?.roundScore, 2));
          return score;
        });
    });
    return allRoundScores;
  }, [allHeats, heat]);

  /* Adding ranks & sorting heat scores by "roundSeed" */
  const initialSortedHeatScores = React.useMemo(() => {
    const rankedHeatScores = getUpdatedSLSRanksInScores(heatScores);
    const sortedHeatScores = [...orderBy(rankedHeatScores, ['roundSeed'], ['asc'])];
    return addFullnameLabelAndDeleteProps(sortedHeatScores);
  }, [heatScores]);

  const { values, setFieldValue, submitForm, errors, handleBlur, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      heatScores: initialSortedHeatScores,
      detailExpandedRow: -1,
    },
    validationSchema: Yup.object().shape({
      heatScores: Yup.array().of(
        Yup.object().shape({
          toDelete: Yup.boolean().required(),
          roundSeed: Yup.number().when('toDelete', {
            is: false,
            then: () => Yup.number().required(),
          }),
          // roundScore: Yup.number()
          //   .nullable(true)
          //   .when('toDelete', {
          //     is: false,
          //     then: () => Yup.number().required(),
          //   }),
          // heatPosition: Yup.string()
          //   .nullable(true)
          //   .when('toDelete', {
          //     is: false,
          //     then: () => Yup.string().required(),
          //   }),
          athlete: Yup.object().when('toDelete', {
            is: false,
            then: (s) =>
              s.shape({
                id: Yup.string().required('Required'),
              }),
          }),
        }),
      ),
    }),
    onSubmit: (formValues, { setSubmitting }) => {
      const updatedRankedHeatScores = getUpdatedSLSRanksInScores(scores);
      const payload = generatePayload({ ...formValues, heatScores: updatedRankedHeatScores }, heat);
      saveHeatScore(payload, heat.id);
      setSubmitting(false);
    },
  });

  const scores: HeatScoresDTO[] = React.useMemo(() => {
    return values.heatScores;
  }, [values?.heatScores]);

  const roundScores = React.useMemo(() => {
    return scores.map((rowData) => {
      const roundScore = !rowData?.toDelete ? calcSLSRoundScores(rowData) : -1;
      return roundScore;
    });
  }, [scores]);

  React.useEffect(() => {
    scores.map((rowData, rowIndex) => {
      const roundScore = calcSLSRoundScores(rowData);
      const fieldName = `heatScores.${rowIndex}.roundScore`;
      setFieldValue(fieldName, roundScore);
      return rowData;
    });
  }, [JSON.stringify(roundScores)]);

  React.useEffect(() => {
    const sorted = [...roundScores, ...otherHeatsScores].slice().sort(function (a, b) {
      return b - a;
    });
    const ranks = roundScores.map(function (v) {
      return v === -1 ? -1 : sorted.indexOf(v) + 1;
    });

    ranks.forEach((rank, rowIndex) => {
      const fieldName = `heatScores.${rowIndex}.heatPosition`;
      setFieldValue(fieldName, rank);
    });
  }, [JSON.stringify([...roundScores, ...otherHeatsScores])]);

  const TSColumns = [...Array(fieldCount).keys()].map((key, ind) => {
    const index = ind + 1;
    const column = {
      title: `Trick Score ${index}`,
      hidden: isMediumAndDown,
      component: (idx: number) => {
        const rowValue: string = values.heatScores[idx][`trick${index}Score`];
        let score: number | null = null;
        if (rowValue) score = +rowValue;

        return (
          <DecimalField
            disabled={values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].trick${index}Score`}
            id={`heatScores[${idx}].trick${index}Score`}
            value={score?.toFixed(2)}
            fullWidth
            sx={
              (roundNo === 1 && [5, 6].includes(Number(index))) ||
              heat.heatStatus === HeatStatus.COMPLETED ||
              (score && score >= 0)
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
            onBlur={(event: React.BaseSyntheticEvent) => {
              handleBlur(event);
              // Deep clone so that nested properties' refs are not linked to formik values.
              const newHeatScores = JSON.parse(JSON.stringify(values.heatScores));
              newHeatScores[idx] = {
                ...newHeatScores[idx],
                [`trick${index}Score`]: parseFloat(event.target.value),
              };
              setFieldValue('heatScores', newHeatScores);
            }}
          />
        );
      },
    };
    return column;
  });

  const LineScoreColumns = [...Array(lineScoreCount).keys()].map((key, ind) => {
    const index = ind + 1;
    const column = {
      title: `Line Score ${index}`,
      hidden: isMediumAndDown,
      component: (idx: number) => {
        const rowValue: string = values.heatScores[idx][`lineScore${index}`];
        let score: number | null = null;
        if (rowValue) score = +rowValue;

        return (
          <DecimalField
            disabled={values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].lineScore${index}`}
            id={`heatScores[${idx}].lineScore${index}`}
            value={score?.toFixed(2)}
            fullWidth
            sx={
              (roundNo === 1 && [5, 6].includes(Number(index))) ||
              heat.heatStatus === HeatStatus.COMPLETED ||
              (score && score >= 0)
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
            onBlur={(event: React.BaseSyntheticEvent) => {
              handleBlur(event);
              // Deep clone so that nested properties' refs are not linked to formik values.
              const newHeatScores = JSON.parse(JSON.stringify(values.heatScores));
              newHeatScores[idx] = {
                ...newHeatScores[idx],
                [`lineScore${index}`]: parseFloat(event.target.value),
              };
              setFieldValue('heatScores', newHeatScores);
            }}
          />
        );
      },
    };
    return column;
  });

  const columns = [
    {
      title: '',
      width: '10%',
      hidden: isMediumAndDown ? false : true,
      component: (idx: number) => (
        <Tooltip title={'Open details'}>
          <IconButton
            color="inherit"
            size="large"
            onClick={(event) => {
              const newIndex = values.detailExpandedRow === idx ? -1 : idx;
              setFieldValue('detailExpandedRow', newIndex);
              event.stopPropagation();
            }}
          >
            {<ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Seed',
      width: isMediumAndDown ? '10%' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <TextField
          type="number"
          disabled={values.heatScores[idx].toDelete}
          name={`heatScores[${idx}].roundSeed`}
          defaultValue={values.heatScores[idx].roundSeed}
          error={
            errors.heatScores && touched.heatScores && errors?.heatScores[idx]?.roundSeed
              ? true
              : false
          }
          helperText={
            errors.heatScores && touched.heatScores
              ? touched.heatScores[idx]?.roundSeed &&
                errors.heatScores[idx]?.roundSeed &&
                'Round Seed is required'
              : ''
          }
          fullWidth
          onBlur={(event: React.BaseSyntheticEvent) => {
            handleBlur(event);
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              roundSeed: +event.target.value,
            };
            setFieldValue('heatScores', newHeatScores);
          }}
        />
      ),
    },
    {
      title: 'Athlete',
      width: isMediumAndDown ? (isSmallAndDown ? '70%' : '50%') : '25%',
      component: (idx: number) => (
        <Autocomplete
          disableClearable
          disabled={values.heatScores[idx].toDelete}
          // defaultValue={null}
          options={players}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          value={values.heatScores[idx]?.athlete || ''}
          onChange={(_: React.BaseSyntheticEvent, newValue: any | null) => {
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              athlete: newValue ? newValue : initialAthleteOption,
            };
            setFieldValue('heatScores', newHeatScores);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].athlete`}
              onBlur={handleBlur}
              fullWidth
              error={
                errors.heatScores && touched.heatScores && errors?.heatScores[idx]?.athlete
                  ? true
                  : false
              }
              helperText={
                errors.heatScores && touched.heatScores
                  ? errors?.heatScores[idx]?.athlete && 'Athlete is required'
                  : ''
              }
            />
          )}
        />
      ),
    },
    ...LineScoreColumns,
    ...TSColumns,
    {
      title: 'Round Score',
      field: 'roundScore',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const rowValue: string = values.heatScores[idx].roundScore;
        let score: number | null = null;
        if (rowValue) score = +rowValue;

        return (
          <TextField
            disabled={values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].roundScore`}
            value={score?.toFixed(2)}
            fullWidth
            sx={{ input: { backgroundColor: '#E9ECEF' } }}
            InputProps={{
              readOnly: true,
            }}
          />
        );
      },
    },
    {
      title: 'Round Place',
      field: 'heatPosition',
      width: isMediumAndDown ? '1fr' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <TextField
          disabled={values.heatScores[idx].toDelete}
          name={`heatScores[${idx}].heatPosition`}
          value={values.heatScores[idx].heatPosition}
          fullWidth
          sx={{ input: { backgroundColor: '#E9ECEF' } }}
          InputProps={{
            readOnly: true,
          }}
        />
      ),
    },
    {
      title: 'Action',
      width: isMediumAndDown ? '1fr' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <Tooltip title="Delete Row" placement="top" arrow>
          <>
            <IconButton
              disabled={heat.heatStatus === HeatStatus.COMPLETED}
              color={values.heatScores[idx].toDelete ? 'warning' : 'default'}
              onClick={() =>
                setFieldValue(`heatScores.${idx}.toDelete`, !values.heatScores[idx].toDelete)
              }
            >
              <DeleteIcon
                sx={{
                  fontSize: '1.65rem',
                  padding: '0.2rem',
                }}
              />
            </IconButton>
          </>
        </Tooltip>
      ),
    },
  ];

  const filteredColumns = columns.filter((column) => !column?.hidden);
  const filteredHiddenColumns = columns.filter((column) => column?.hidden === true);
  const columnWidths = filteredColumns.map((column) => column?.width || '1fr');
  const generatedGridTemplateColumns = columnWidths.join(' ');

  return (
    <>
      <Container>
        <Box
          sx={{ display: 'flex', alignItems: 'center', gap: '0.875rem', marginBottom: '0.375rem' }}
        >
          <HeatTitle>{`${heat?.heatName} ${heat?.heatNo}`}</HeatTitle>
          <Box>{processHeatStatusBadges(heat?.heatStatus, heat?.heatNo)}</Box>
        </Box>
        <StartEndTimes startDate={heat.startDate} endDate={heat.endDate} />

        <Box sx={{ marginTop: '1rem' }}>
          <form>
            {values.heatScores.length > 0 && (
              <Box sx={{ display: 'block' }}>
                <Box
                  sx={{
                    display: 'grid',
                    borderCollapse: 'collapse',
                    minWidth: '100%',
                    gridTemplateColumns: generatedGridTemplateColumns,
                    gap: 1,
                  }}
                >
                  <Box
                    data-role="thead"
                    sx={{
                      display: 'contents',
                    }}
                  >
                    <Box
                      data-role="tr"
                      sx={{
                        display: 'contents',
                      }}
                    >
                      {filteredColumns.map((column, idx) => (
                        <Box key={`title-${idx}`} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <Typography
                            sx={{
                              color: 'info.main',
                              fontSize: '0.875rem',
                              fontWeight: 500,
                              lineHeight: '1rem',
                            }}
                            variant="caption"
                          >
                            {column?.title || ''}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  <Box
                    data-role="tbody"
                    sx={{
                      display: 'contents',
                    }}
                  >
                    <Box sx={{ display: 'contents' }}>
                      {values.heatScores.map((heatScore, idx: number) => (
                        <Box sx={{ display: 'contents' }} key={`tbody-${idx}`}>
                          <Box
                            data-role="tr"
                            sx={{
                              display: 'contents',
                            }}
                          >
                            {filteredColumns.map((column, colIdx) => (
                              <Box
                                key={`title-${colIdx}`}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {column?.component(idx) || ''}
                              </Box>
                            ))}
                          </Box>
                          {values.detailExpandedRow === idx && (
                            <Collapse
                              in={values.detailExpandedRow === idx ? true : false}
                              sx={{ gridColumn: `1 / span ${filteredColumns.length}` }}
                            >
                              <Box
                                sx={{
                                  display: 'grid',
                                  borderCollapse: 'collapse',
                                  minWidth: '100%',
                                  gridTemplateColumns: '1fr 1fr',
                                  gap: 1,
                                  padding: { md: '2rem 6rem', xs: '2rem 1rem' },
                                }}
                              >
                                {filteredHiddenColumns.map((column, index) => {
                                  return (
                                    <Box sx={{ display: 'contents' }} key={`cell-${index}`}>
                                      <Box>
                                        <Stack
                                          sx={{ height: '100%' }}
                                          direction="column"
                                          justifyContent="center"
                                        >
                                          <Typography>{column.title}</Typography>
                                        </Stack>
                                      </Box>
                                      <Box>
                                        <Box key={`title-${index}`}>
                                          {column?.component(idx) || ''}
                                        </Box>
                                      </Box>
                                    </Box>
                                  );
                                })}
                              </Box>
                            </Collapse>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignContent="center"
              sx={{ marginTop: '0.5rem' }}
            >
              {values.heatScores.length > 0 && (
                <Grid item>
                  <Button
                    disabled={heat.heatStatus === HeatStatus.COMPLETED}
                    size="large"
                    color="primary"
                    variant="contained"
                    disableElevation
                    sx={{
                      fontSize: '0.875rem',
                      fontWeight: '400',
                      padding: '0.375rem 2.125rem',
                    }}
                    onClick={() => {
                      submitForm();
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              )}
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default SLSHeatScores;
