import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import { getUpdatedFDRIFTRanksInScores } from '@/helpers/heats';
import { DEFAULT_START_OF_LAP_TIME, formatMMSSToSeconds } from '@/helpers/timeConverters';

import { HeatStatus } from '@/constants/heats';
import {
  FDRIFT_HEAT_SCORES_STATUS_OPTIONS,
  INITIAL_FDRIFT_HEAT_SCORES_STATUS_OPTION,
  FDRIFT_HEAT_SCORES_STATUS,
} from '@/constants/fdriftHeatScoresStatus';

import { LapTimeField } from '@/components/LapTimeFormat';

import { ReactComponent as JokerCheckedIcon } from '@/images/icons/joker-checked.svg';
import { ReactComponent as JokerUnCheckedIcon } from '@/images/icons/joker-unchecked.svg';

import { HeatDTO, HeatScoresDTO } from '@/types/heat';
import { AthleteDTO } from '@/types/athlete';

import { Athlete } from '@/types/athlete';

import { initialAthleteOption } from '@/constants/misc';

const FIRST_ROUND_NO = 1;

const tableColumns = ({
  formik,
  isSmallAndDown,
  isMediumAndDown,
  selectedHeat,
  heatScores,
  players,
  roundNo,
  lapsCountArr,
  canUseJokerLap,
}: {
  formik: any;
  isSmallAndDown: boolean;
  isMediumAndDown: boolean;
  selectedHeat: HeatDTO;
  heatScores: { heat: any; scores: HeatScoresDTO[] };
  players: Athlete[] | AthleteDTO[];
  roundNo: number;
  lapsCountArr: number[];
  canUseJokerLap: { [key: string]: boolean }[];
}) => {
  // Deep clone so that nested properties' refs are not linked to formik values.
  const formValueHeatScores: any[] = JSON.parse(JSON.stringify(formik.values.heatScores));
  const LapColumns = lapsCountArr.map((key) => {
    const index = key;
    const lapNumberKey = `lap_${index}`;

    const column = {
      title: `Lap ${index + 1}`,
      hidden: isMediumAndDown,
      // width: '9%',
      component: (idx: number) => {
        const formattedLapTime: number = formValueHeatScores[idx][lapNumberKey]?.lapTime || 0;
        const unformattedLapTime: number | string = formatMMSSToSeconds(
          formattedLapTime.toString(),
        );
        return (
          <LapTimeField
            key={`${formValueHeatScores[idx].id}_lap_${index}`}
            id={`${formValueHeatScores[idx].id}_lap_${index}`}
            name={`heatScores[${idx}].lap_${index}`}
            disabled={formValueHeatScores[idx].toDelete}
            fullWidth
            value={unformattedLapTime === 0 ? '' : formattedLapTime.toString()}
            mask="MM{:}SS{.}MS"
            blocks={{
              MM: {
                mask: '0[0]',
              },
              SS: {
                mask: '0[0]',
              },
              MS: {
                mask: '0[0][0]',
              },
            }}
            definitions={{
              $: /[0-9]/,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingRight: 0,
              },
              '& .MuiInputAdornment-root': {
                padding: '0px',
                marginLeft: 0,
              },
              '&.MuiTextField-root': {
                backgroundColor:
                  heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
                  (unformattedLapTime && unformattedLapTime > 0)
                    ? '#E9ECEF'
                    : '',
              },
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const newVal = event.target.value;
              const unformattedNewLapTime: number | string = formatMMSSToSeconds(newVal);
              if (unformattedNewLapTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
              const formattedNewLapTime: string = newVal;
              if (unformattedNewLapTime !== unformattedLapTime) {
                formValueHeatScores[idx][lapNumberKey].lapTime = formattedNewLapTime;
                if (unformattedNewLapTime >= 0) {
                  formik.setFieldValue(
                    'heatScores',
                    getUpdatedFDRIFTRanksInScores(
                      formValueHeatScores,
                      lapsCountArr,
                      roundNo,
                      FIRST_ROUND_NO,
                    ),
                  );
                } else {
                  formik.setFieldValue('heatScores', formValueHeatScores);
                }
              }
            }}
            InputProps={{
              endAdornment:
                roundNo === FIRST_ROUND_NO ? undefined : (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={
                        !!canUseJokerLap[formik.values.heatScores[idx]?.athlete?.id || ''] &&
                        !formik.values.heatScores[idx][lapNumberKey]?.isJoker
                          ? true
                          : selectedHeat?.heatStatus === HeatStatus.COMPLETED
                          ? true
                          : false
                      }
                      onClick={() => {
                        // Deep clone so that nested properties' refs are not linked to formik values.
                        const scrs = JSON.parse(JSON.stringify(formik.values.heatScores));
                        scrs[idx][lapNumberKey].isJoker = !scrs[idx][lapNumberKey].isJoker;
                        formik.setFieldValue(
                          'heatScores',
                          getUpdatedFDRIFTRanksInScores(
                            scrs,
                            lapsCountArr,
                            roundNo,
                            FIRST_ROUND_NO,
                          ),
                        );
                      }}
                      disableRipple
                      sx={{
                        // '& .MuiIconButton-root': {
                        padding: '0 !important',
                        paddingRight: '0.375rem !important',
                        // },
                        fontSize: '1rem',
                      }}
                    >
                      {formik.values.heatScores[idx][lapNumberKey]?.isJoker ? (
                        <JokerCheckedIcon />
                      ) : (
                        <JokerUnCheckedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
            }}
          />
        );
      },
    };
    return column;
  });
  return [
    {
      title: '',
      // width: '10%',
      hidden: isMediumAndDown ? false : true,
      component: (idx: number) => (
        <Tooltip key={`${formik.values.heatScores[idx].id}_expand`} title={'Open details'}>
          <IconButton
            color="inherit"
            size="large"
            onClick={(event) => {
              const newIndex = formik.values.detailExpandedRow === idx ? -1 : idx;
              formik.setFieldValue('detailExpandedRow', newIndex);
              event.stopPropagation();
            }}
          >
            {<ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      width: isMediumAndDown ? '10%' : '0.8fr',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <Autocomplete
          key={`${formik.values.heatScores[idx].id}_status`}
          disabled={formik.values.heatScores[idx].toDelete}
          defaultValue={INITIAL_FDRIFT_HEAT_SCORES_STATUS_OPTION}
          disableClearable
          options={FDRIFT_HEAT_SCORES_STATUS_OPTIONS}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          renderOption={(props, option) => (
            <Box
              sx={{ fontSize: '0.8rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              {...props}
            >
              {option.label}
            </Box>
          )}
          value={formik.values.heatScores[idx]?.status || INITIAL_FDRIFT_HEAT_SCORES_STATUS_OPTION}
          onChange={(event: any, newValue: any | null) => {
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
            newHeatScores[idx].status = newValue ? newValue : null;
            formik.setFieldValue(
              'heatScores',
              getUpdatedFDRIFTRanksInScores(newHeatScores, lapsCountArr, roundNo, FIRST_ROUND_NO),
            );
          }}
          fullWidth
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: 'calc(50% - 0.7rem)',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].status`}
              fullWidth
              error={
                formik.errors.heatScores &&
                formik.touched.heatScores &&
                formik.errors?.heatScores[idx]?.status
                  ? true
                  : false
              }
              helperText={
                formik.errors.heatScores && formik.touched.heatScores
                  ? formik.errors?.heatScores[idx]?.status && 'Status is required'
                  : ''
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0.6rem 0.375rem',
                  paddingRight: '1.25rem !important',
                },
                '& .MuiIconButton-root': {
                  padding: 0,
                  marginRight: '-0.375rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                },
              }}
            />
          )}
        />
      ),
    },
    {
      title: 'Athlete',
      width: isMediumAndDown ? (isSmallAndDown ? '80%' : '30%') : '1fr',
      component: (idx: number) => (
        <Autocomplete
          key={`${formik.values.heatScores[idx].id}_athlete`}
          disabled={formik.values.heatScores[idx].toDelete}
          disableClearable
          options={players}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          renderOption={(props, option) => (
            <Box
              sx={{ fontSize: '0.8rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              {...props}
            >
              {option.label}
            </Box>
          )}
          value={formik.values.heatScores[idx]?.athlete}
          onChange={(_: React.BaseSyntheticEvent, newValue: any | null) => {
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              athlete: newValue ? newValue : initialAthleteOption,
            };
            formik.setFieldValue('heatScores', newHeatScores);
          }}
          fullWidth
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: 'calc(50% - 0.7rem)',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].athlete`}
              fullWidth
              error={
                formik.errors.heatScores &&
                formik.touched.heatScores &&
                formik.errors?.heatScores[idx]?.athlete?.id
                  ? true
                  : false
              }
              helperText={
                formik.errors.heatScores && formik.touched.heatScores
                  ? formik.errors?.heatScores[idx]?.athlete && 'Athlete is required'
                  : ''
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0.6rem 0.375rem',
                  paddingRight: '1.25rem !important',
                  textAlign: 'left',
                },
                '& .MuiOutlinedInput-input': {
                  textAlign: 'left',
                },
                '& .MuiIconButton-root': {
                  padding: 0,
                  marginRight: '-0.375rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                },
              }}
            />
          )}
        />
      ),
    },
    ...LapColumns,
    {
      title: 'Penalties',
      field: 'penaltyTime',
      width: isMediumAndDown ? '1fr' : '0.8fr',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const formattedPenaltyTime: string =
          formik.values.heatScores[idx].penaltyTime || DEFAULT_START_OF_LAP_TIME;
        const unformattedPenaltyTime: number | string = formatMMSSToSeconds(formattedPenaltyTime);
        // Deep clone so that nested properties' refs are not linked to formik values.
        const formValueHeatScores: any[] = JSON.parse(JSON.stringify(formik.values.heatScores));
        return (
          <LapTimeField
            key={`${formik.values.heatScores[idx].id}_penalties`}
            id={`${formik.values.heatScores[idx].id}_penalties`}
            name={`heatScores[${idx}].penaltyTime`}
            disabled={formik.values.heatScores[idx].toDelete}
            fullWidth
            value={unformattedPenaltyTime === 0 ? '' : formattedPenaltyTime}
            mask="MM{:}SS{.}MS"
            blocks={{
              MM: {
                mask: '0[0]',
              },
              SS: {
                mask: '0[0]',
              },
              MS: {
                mask: '0[0][0]',
              },
            }}
            definitions={{
              $: /[0-9]/,
            }}
            sx={
              heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
              (unformattedPenaltyTime && unformattedPenaltyTime > 0)
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const newVal = event.target.value;
              const unformattedNewPenaltyTime: number | string = formatMMSSToSeconds(newVal);
              if (unformattedNewPenaltyTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
              const formattedNewLapTime: string = newVal;
              if (unformattedNewPenaltyTime !== unformattedPenaltyTime) {
                formValueHeatScores[idx].penaltyTime = formattedNewLapTime;
                if (unformattedNewPenaltyTime >= 0) {
                  formik.setFieldValue(
                    'heatScores',
                    getUpdatedFDRIFTRanksInScores(
                      formValueHeatScores,
                      lapsCountArr,
                      roundNo,
                      FIRST_ROUND_NO,
                    ),
                  );
                } else {
                  formik.setFieldValue('heatScores', formValueHeatScores);
                }
              }
            }}
          />
        );
      },
    },
    {
      title: roundNo === FIRST_ROUND_NO ? 'Best Lap Time' : 'Total Time',
      field: 'totalTime',
      width: isMediumAndDown ? '1fr' : '0.8fr',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const formattedTotalTime =
          formik.values.heatScores[idx].totalTime || DEFAULT_START_OF_LAP_TIME;
        const unformattedTotalTime: number | string = formatMMSSToSeconds(`${formattedTotalTime}`);
        return (
          <TextField
            key={`${formik.values.heatScores[idx].id}_best_total`}
            disabled={formik.values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].totalTime`}
            value={unformattedTotalTime === 0 ? '' : formattedTotalTime}
            fullWidth
            InputProps={{
              readOnly: true,
            }}
            sx={
              heatScores?.heat?.heatStatus === HeatStatus.COMPLETED || unformattedTotalTime > 0
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
          />
        );
      },
    },
    {
      title: 'Place',
      field: 'heatPosition',
      width: isMediumAndDown ? '1fr' : '0.1fr',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <TextField
          key={`${formik.values.heatScores[idx].id}_place`}
          disabled={formik.values.heatScores[idx].toDelete}
          name={`heatScores[${idx}].heatPosition`}
          value={
            formik.values.heatScores[idx].heatPosition === 0
              ? ''
              : formik.values.heatScores[idx].heatPosition
          }
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          // error={
          //   formik.errors.heatScores &&
          //   formik.touched.heatScores &&
          //   formik.errors?.heatScores[idx]?.heatPosition
          //     ? true
          //     : false
          // }
          // helperText={
          //   formik.errors.heatScores && formik.touched.heatScores
          //     ? formik.errors?.heatScores[idx]?.heatPosition && 'Position is required'
          //     : ''
          // }
          sx={
            heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
            formik.values.heatScores[idx].heatPosition > 0 ||
            (formik.values.heatScores[idx].status &&
              formik.values.heatScores[idx].status.value === FDRIFT_HEAT_SCORES_STATUS.DSQ)
              ? { input: { backgroundColor: '#E9ECEF' } }
              : undefined
          }
        />
      ),
    },
    {
      title: 'Action',
      width: isMediumAndDown ? '1fr' : '0.1fr',
      hidden: isSmallAndDown,
      component: (idx: number) => (
        <Tooltip
          key={`${formik.values.heatScores[idx].id}_action`}
          title="Delete Row"
          placement="top"
          arrow
        >
          {/* Tooltip needs a child that isn't disabled, hence fragment */}
          <>
            <IconButton
              disabled={selectedHeat?.heatStatus === HeatStatus.COMPLETED}
              color={formik.values.heatScores[idx].toDelete ? 'warning' : 'default'}
              onClick={() => {
                // Deep clone so that nested properties' refs are not linked to formik values.
                const scrs = JSON.parse(JSON.stringify(formik.values.heatScores));
                scrs[idx].toDelete = !scrs[idx].toDelete;
                formik.setFieldValue(
                  'heatScores',
                  getUpdatedFDRIFTRanksInScores(scrs, lapsCountArr, roundNo, FIRST_ROUND_NO),
                );
              }}
            >
              <DeleteIcon
                sx={{
                  fontSize: '1.65rem',
                  padding: '0.2rem',
                }}
              />
            </IconButton>
          </>
        </Tooltip>
      ),
    },
  ];
};

export default tableColumns;
