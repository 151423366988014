import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { FormikProps, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { TextField } from '@mui/material';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDebouncedCallback } from 'use-debounce';

import { navbarState } from '@/atoms/misc';
import Copyright from '@/components/Copyright';
// import { SignInSchema } from '@/schemas/authentication';
import { SignInDTO } from '@/types/authentication';
import useLogIn from '@/hooks/authentication/useLogIn';
import routes from '@/constants/routes';
import MuiTextField from '@/components/MuiCustomComponents/Textfield';
import Head from '@/components/Head';

import config from '@/config/vars';

const Login = () => {
  const [, setMenu] = useRecoilState(navbarState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutate: logIn } = useLogIn();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const initialValues: SignInDTO = {
    username: '',
    password: '',
  };

  interface FieldErrors {
    username?: string;
    password?: string;
  }

  const toggleLoggingIn = () => setIsLoggingIn((prev) => !prev);

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    touched,
    setFieldError,
  }: FormikProps<SignInDTO> = useFormik<SignInDTO>({
    initialValues,
    validate: (formValues: SignInDTO) => {
      const fieldErrors: FieldErrors = {};
      if (!validator.isEmail(formValues.username)) {
        fieldErrors['username'] = 'Enter a valid username address';
      }

      return fieldErrors;
    },
    onSubmit: () => {
      //
    },
  });

  const handleLogin = useDebouncedCallback(async () => {
    if (!executeRecaptcha) {
      enqueueSnackbar('Execute recaptcha not yet available');
      return;
    }

    toggleLoggingIn();

    const captchaToken = await executeRecaptcha('login');

    enqueueSnackbar('Logging in...');
    logIn(
      { ...values, captchaToken },
      {
        onSuccess: () => {
          setMenu({ top: 'Events', parent: '', child: '' });
          closeSnackbar();
          enqueueSnackbar('Successfully Logged In');
          navigate(routes.events.home);
          toggleLoggingIn();
        },
        onError: () => {
          toggleLoggingIn();
          setFieldError('username', 'Invalid username or password');
        },
      },
    );
  }, 800);

  return (
    <React.Fragment>
      <Head title={'Altsportsdata - Login'} canonicalUrl={routes.events.home} />
      <Container maxWidth="xs">
        <form style={{ width: '100%' }}>
          <Stack direction="column" alignItems="center" spacing={3}>
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <Box sx={{ width: '100%' }}>
              <Stack direction="column" spacing={3}>
                {/*<Box>
                  <Typography variant="caption" sx={{ marginBottom: '1rem' }}>
                    User Type
                  </Typography>
                  <TextField
                    select
                    name="userType"
                    value={values.userType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  >
                    {[
                      {
                        value: 'admin',
                        label: 'Admin',
                      },
                      {
                        value: 'trader',
                        label: 'Trader',
                      },
                      {
                        value: 'client',
                        label: 'Client',
                      },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                    </Box>*/}
                <Box>
                  <Typography variant="caption" sx={{ marginBottom: '1rem' }}>
                    Email
                  </Typography>
                  <TextField
                    name="username"
                    value={values.username}
                    type="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={(errors.username && touched.username) || false}
                    helperText={touched.username && errors.username}
                  />
                </Box>

                <Box>
                  <Typography variant="caption" sx={{ marginBottom: '1rem' }}>
                    Password
                  </Typography>
                  <MuiTextField
                    type="password"
                    value={values.password}
                    name="password"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={(errors.password && touched.password) || false}
                    helperText={touched.password && errors.password}
                  />
                </Box>

                {/* <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="remember"
                    Label={{ label: 'Remember me' }}
                  /> */}
                <Button
                  disabled={isLoggingIn}
                  onClick={handleLogin}
                  fullWidth
                  variant="contained"
                  disableElevation
                  sx={{ mt: 3, mb: 2 }}
                  size="large"
                >
                  Sign In
                </Button>
                {/* <Grid container>
                    <Grid item xs>
                      <Link variant="body2">Forgot password?</Link>
                    </Grid>
                    <Grid item>
                      <Link variant="body2">{"Don't have an account? Sign Up"}</Link>
                    </Grid>
                  </Grid> */}
              </Stack>
            </Box>
          </Stack>
        </form>
      </Container>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </React.Fragment>
  );
};

const LoginWrapper = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={config.googleRecaptchaKey}
      scriptProps={{
        async: false,
        defer: false,
      }}
    >
      <Login />
    </GoogleReCaptchaProvider>
  );
};

export default LoginWrapper;
