import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';
import { PlayerHeadToHeadOddRow } from '@/types/playerHeadToHeadTab';

export const initialDataLoader = ({
  newHeadToHeads,
  currentHeadToHeads,
  resetToggleValues,
}: {
  newHeadToHeads: PlayerHeadToHeadOddRow[];
  currentHeadToHeads: PlayerHeadToHeadOddRow[];
  resetToggleValues: boolean;
}) => {
  const getExistingRowData = (head: PlayerHeadToHeadOddRow) => {
    return (
      currentHeadToHeads.find((headToHead: PlayerHeadToHeadOddRow) => head.id === headToHead.id) ||
      null
    );
  };

  const formatParticipant = (participant: any) => {
    if (!participant) return {};
    const { athlete } = participant;
    return {
      ...participant,
      position: participant.position || '--',
      eventParticipantId: participant.id || '',
      athleteId: athlete?.id || '',
      label: [athlete?.firstName, athlete?.middleName, athlete?.lastName].filter(Boolean).join(' '),
      decimalOdds: round(participant.odds, 2),
      fractionalOdds: oddsHelpers.getFractionalOdds(participant.odds).toString(),
      americanOdds: oddsHelpers.getAmericanOddsFromProbability(participant.probability).toString(),
      probability: round(participant.probability, 2),
    };
  };

  const initialData = newHeadToHeads.map((head: PlayerHeadToHeadOddRow) => {
    const existingRow = getExistingRowData(head);

    const currentData = !resetToggleValues ? existingRow : null;

    return {
      ...head,
      ...(resetToggleValues
        ? {
            newWinnerId: head.winnerParticipantId || '',
            editedVoid: head.voided || false,
            editedDraw: head?.draw || false,
          }
        : {
            newWinnerId: currentData?.newWinnerId || '',
            editedVoid: currentData?.editedVoid || false,
            editedDraw: currentData?.editedDraw || false,
          }),
      ...(currentData
        ? {
            eventParticipant1: existingRow?.eventParticipant1,
            eventParticipant2: existingRow?.eventParticipant2,
            holdingPercentage: round(
              currentData?.eventParticipant1?.probability +
                currentData?.eventParticipant2?.probability,
            ),
          }
        : {
            eventParticipant1: formatParticipant(head.eventParticipant1),
            eventParticipant2: formatParticipant(head.eventParticipant2),
            holdingPercentage: round(head.holdingPercentage),
          }),
    };
  });

  return initialData;
};

export const sortPlayerHeadToHeads = (headToHeads: PlayerHeadToHeadOddRow[]) => {
  return headToHeads.sort((a: any, b: any) => {
    const rowACanEdit = !a.voided && !a.winnerParticipantId;
    const rowBCanEdit = !b.voided && !b.winnerParticipantId;
    return rowACanEdit === rowBCanEdit ? 0 : rowACanEdit ? -1 : 1;
  });
};

export const headToHeadFreshWinnerDrawCount = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return 0;
  const payoutCount = rows.reduce((prevValue: number, row: any) => {
    if ((!row?.winnerParticipantId && row.newWinnerId) || (!row?.draw && row.editedDraw))
      return ++prevValue;
    else return prevValue;
  }, 0);
  return payoutCount;
};
