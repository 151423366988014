import { useMutation } from '@tanstack/react-query';

import { TraderEventOddAddDTO } from '@/types/traderOddUpload';

import { authenticatedAxios } from '../..';

interface TraderEventOdds {
  sport: string;
  requestPayload: {
    items: TraderEventOddAddDTO;
  };
}

const addTraderEventOdds = async ({ sport, requestPayload }: TraderEventOdds) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/odds/event`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderEventOdds() {
  return useMutation(({ sport, requestPayload }: TraderEventOdds) =>
    addTraderEventOdds({ sport, requestPayload }),
  );
}
