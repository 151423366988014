export const allowedHeaders = [
  { title: 'Tour Name', field: 'name' },
  { title: 'Year', field: 'year' },
  { title: 'Gender', field: 'gender' },
];

export type TourRow = {
  name: string;
  year: number;
  gender: string;
};

export const requiredKeys = ['name', 'gender', 'year'];
