import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DateRangeIcon from '@mui/icons-material/DateRange';

import { processHeatStatusBadges } from '@/helpers/events';
import { getHeatDate } from '@/helpers/heats';

import { HeatDTO } from '@/types/heat';

const ScoreManageHeader = ({ selectedHeat }: { selectedHeat: HeatDTO }) => {
  const heatDate = React.useMemo(() => {
    return getHeatDate(selectedHeat.startDate);
  }, [selectedHeat.startDate]);
  return (
    <Grid container justifyContent="space-between" sx={{ marginBottom: '2rem' }}>
      <Grid item xs container alignContent="center">
        <Typography variant="h4">
          {`${selectedHeat?.heatName} ${selectedHeat?.heatNo}`}
          &nbsp;&nbsp;
          {processHeatStatusBadges(selectedHeat?.heatStatus, selectedHeat?.heatNo)}
        </Typography>
      </Grid>
      <Grid item xs container justifyContent="flex-end">
        <List dense>
          <ListItem>
            <ListItemIcon>
              <DateRangeIcon />
            </ListItemIcon>
            <ListItemText primary={heatDate} />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default ScoreManageHeader;
