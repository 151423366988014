import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  autocompleteClasses,
  Box,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import * as httpStatusCodes from '@/constants/httpStatus';

export type AddExactasEventOddsDialogProps = {
  open: boolean;
  handleClose: Function;
  playerDropdownOptions: any[];
  addExactasEventOddsData: Function;
  roundHeatId?: string;
};

export type FormValues = {
  eventParticipant1: { eventParticipantId: string } | null;
  eventParticipant2: { eventParticipantId: string } | null;
  eventParticipant3: { eventParticipantId: string } | null;
};

const initialValues: FormValues = {
  eventParticipant1: null,
  eventParticipant2: null,
  eventParticipant3: null,
};

const AddExactasEventOddsDialog = (props: AddExactasEventOddsDialogProps) => {
  const {
    open,
    handleClose,
    playerDropdownOptions: players,
    addExactasEventOddsData,
    roundHeatId,
  } = props;

  // temporary solution and does not fix the actual problem that is causing the duplicates
  // remove any duplicates
  const playerDropdownOptions: any[] = [];
  const seenAthletes = new Set();
  players.forEach((athleteRow) => {
    if (!seenAthletes.has(athleteRow.athleteId)) {
      playerDropdownOptions.push(athleteRow);
      seenAthletes.add(athleteRow.athleteId);
    }
  });

  const findPlayer = (playerId: string) => {
    const player = playerDropdownOptions.find((player) => player.eventParticipantId === playerId);

    return player
      ? {
          firstName: player?.firstName,
          middleName: player?.middleName,
          lastName: player?.lastName,
          eventParticipantId: player?.eventParticipantId,
        }
      : player;
  };
  const {
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
  }: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      eventParticipant1: Yup.object().shape({
        eventParticipantId: Yup.string().required(),
      }),
      eventParticipant2: Yup.object().shape({
        eventParticipantId: Yup.string().required(),
      }),
    }),
    onSubmit: async (formValues, { resetForm }) => {
      const payload = {
        participants: [
          findPlayer(formValues?.eventParticipant1?.eventParticipantId || ''),
          findPlayer(formValues?.eventParticipant2?.eventParticipantId || ''),
          findPlayer(formValues?.eventParticipant3?.eventParticipantId || ''),
        ].filter(Boolean),
        ...(roundHeatId && { roundHeatId }),
      };
      const responseStatus = await addExactasEventOddsData(payload);
      if (responseStatus === httpStatusCodes.OK) {
        resetForm();
      }
    },
  });

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '1.5rem' }}>
          <strong>Add Exacta</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container direction="column">
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>1st Place (required)</Typography>
              <Autocomplete
                disableClearable
                value={values.eventParticipant1}
                options={
                  values.eventParticipant2 || values.eventParticipant3
                    ? playerDropdownOptions.filter(
                        (data: any) =>
                          ![
                            values.eventParticipant2?.eventParticipantId,
                            values.eventParticipant3?.eventParticipantId,
                          ].includes(data?.eventParticipantId),
                      )
                    : playerDropdownOptions
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                          padding: '8px',
                        },
                      }}
                      component="li"
                      {...props}
                      key={option.eventParticipantId}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(_: any, newValue: any) => {
                  setFieldValue('eventParticipant1', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="eventParticipant1"
                    onBlur={handleBlur}
                    helperText={
                      touched.eventParticipant1 &&
                      errors.eventParticipant1 &&
                      'Event Participant 1 is Required'
                    }
                    error={errors.eventParticipant1 ? true : false}
                    InputProps={{
                      ...params.InputProps,
                      // disableUnderline: true,
                      endAdornment: null,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>2nd Place (required)</Typography>
              <Autocomplete
                disableClearable
                value={values.eventParticipant2}
                options={
                  values.eventParticipant1 || values.eventParticipant3
                    ? playerDropdownOptions.filter(
                        (data: any) =>
                          ![
                            values.eventParticipant1?.eventParticipantId,
                            values.eventParticipant3?.eventParticipantId,
                          ].includes(data?.eventParticipantId),
                      )
                    : playerDropdownOptions
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                          padding: '8px',
                        },
                      }}
                      component="li"
                      {...props}
                      key={option.eventParticipantId}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(_: any, newValue: any) => {
                  setFieldValue('eventParticipant2', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="eventParticipant2Id"
                    onBlur={handleBlur}
                    helperText={
                      touched.eventParticipant2 &&
                      errors.eventParticipant2 &&
                      'Event Participant 2 is Required'
                    }
                    error={errors.eventParticipant2 ? true : false}
                    InputProps={{
                      ...params.InputProps,
                      // disableUnderline: true,
                      endAdornment: null,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>3rd Place (optional, will change type to trifecta)</Typography>
              <Autocomplete
                disableClearable
                value={values.eventParticipant3}
                options={
                  values.eventParticipant1 || values.eventParticipant2
                    ? playerDropdownOptions.filter(
                        (data: any) =>
                          ![
                            values.eventParticipant1?.eventParticipantId,
                            values.eventParticipant2?.eventParticipantId,
                          ].includes(data?.eventParticipantId),
                      )
                    : playerDropdownOptions
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                          padding: '8px',
                        },
                      }}
                      component="li"
                      {...props}
                      key={option.eventParticipantId}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(_: any, newValue: any) => {
                  setFieldValue('eventParticipant3', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="eventParticipant3"
                    onBlur={handleBlur}
                    // helperText={
                    //   touched.eventParticipant3Id &&
                    //   errors.eventParticipant3Id &&
                    //   'Event Participant 2 is Required'
                    // }
                    // error={errors.eventParticipant3Id ? true : false}
                    InputProps={{
                      ...params.InputProps,
                      // disableUnderline: true,
                      endAdornment: null,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} spacing={1} sx={{ marginBottom: '1rem' }}>
          <Grid item sm={4}>
            <Button fullWidth variant="outlined" onClick={() => handleClose()} disableElevation>
              Close
            </Button>
          </Grid>

          <Grid item sm={4}>
            <Button
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              disableElevation
              fullWidth
            >
              Add Exactas
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddExactasEventOddsDialog;
