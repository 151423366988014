import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';

import { invalidateCachedHeat } from '@/helpers/cachedQueries';
import {
  addFullnameLabelAndDeleteProps,
  getNewRow,
  getUpdatedSLSRanksInScores,
} from '@/helpers/heats';

import SPORT_TYPES from '@/constants/sportTypes';

import { AthleteDTO } from '@/types/athlete';
import { HeatDTO, HeatScoresDTO } from '@/types/heat';

import tableColumns from './ScoreManageColumns';

import {
  ScoreManageHeader,
  ScoreManageTimeline,
  ScoreManageFooter,
  ScoreManageBody,
} from '@/features/scores/ScoreManage';
import orderBy from 'lodash.orderby';
import { displayInPT } from '@/helpers/timeConverters';

interface HeatCardProps {
  // hidden: boolean;
  heatScores: { heat: any; scores: HeatScoresDTO[] };
  prevHeat: HeatDTO | null;
  nextHeat: HeatDTO | null;
  selectedHeat: HeatDTO;
  players: AthleteDTO[];
  fieldCount?: number;
  lineScoreCount?: number;
  handleCurrentHeat: (nextHeat: HeatDTO) => void;
  saveHeatScore: (values: any, message?: string) => void;
  endHeat: (values: any) => void;
  processHeatScore: Function;
  roundNo: number;
  isFetchingHeatScore: boolean;
}

const sportType = SPORT_TYPES.SLS;

const ScoreManage = (props: HeatCardProps) => {
  const theme = useTheme();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    heatScores,
    players,
    fieldCount = 6,
    lineScoreCount = 2,
    selectedHeat,
    prevHeat,
    nextHeat,
    handleCurrentHeat,
    saveHeatScore,
    endHeat,
    processHeatScore,
    roundNo,
    isFetchingHeatScore,
  } = props;

  /* Adding ranks & sorting heat scores by "roundSeed" */
  const initialSortedHeatScores = React.useMemo(() => {
    const rankedHeatScores = getUpdatedSLSRanksInScores(heatScores.scores);
    const sortedHeatScores = [...orderBy(rankedHeatScores, ['roundSeed'], ['asc'])];
    return addFullnameLabelAndDeleteProps(sortedHeatScores);
  }, [heatScores.scores]);

  React.useEffect(() => {
    invalidateCachedHeat(queryClient, sportType, selectedHeat.id);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startTime: heatScores?.heat?.startDate
        ? displayInPT(heatScores?.heat?.startDate, undefined, true)
        : null,
      endTime: heatScores?.heat?.endDate
        ? displayInPT(heatScores?.heat?.endDate, undefined, true)
        : null,
      heatStatus: +heatScores.heat?.heatStatus,
      heatScores: initialSortedHeatScores,
      detailExpandedRow: -1,
    },
    validationSchema: Yup.object().shape({
      heatScores: Yup.array().of(
        Yup.object().shape({
          toDelete: Yup.boolean().required(),
          roundSeed: Yup.number().when('toDelete', {
            is: false,
            then: () => Yup.number().required(),
          }),
          athlete: Yup.object().when('toDelete', {
            is: false,
            then: (s) =>
              s.shape({
                id: Yup.string().required('Required'),
              }),
          }),
        }),
      ),
    }),
    onSubmit: (formValues, { setSubmitting }) => {
      const payload = processHeatScore(formValues);
      saveHeatScore(payload);
      setSubmitting(false);
    },
  });
  const { values, setFieldValue } = formik;

  const columns = tableColumns({
    formik,
    isSmallAndDown,
    isMediumAndDown,
    selectedHeat,
    heatScores,
    players,
    fieldCount,
    lineScoreCount,
    roundNo,
  });

  // Add a new row to the table that persists after clicking "Save".
  const addNewRow = () => {
    const lastSeed = values.heatScores[values.heatScores.length - 1]?.roundSeed || 0;
    const newRow = getNewRow(sportType, lastSeed);
    const updatedRows = values.heatScores.slice();
    updatedRows.push(newRow);
    setFieldValue(`heatScores`, updatedRows);
  };

  const endHeatHandler = (finalValues: any) => {
    const athleteData = processHeatScore(finalValues);
    endHeat(athleteData);
  };

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <Box sx={{ paddingY: '2rem' }}>
      <Box sx={{ display: 'block' }}>
        <ScoreManageHeader selectedHeat={selectedHeat} />
        <form>
          {values.heatScores.length > 0 && (
            <>
              <ScoreManageTimeline
                selectedHeat={selectedHeat}
                formik={formik}
                saveHeatScore={saveHeatScore}
                processHeatScore={processHeatScore}
              />
              <ScoreManageBody formik={formik} columns={columns} />
            </>
          )}
          <ScoreManageFooter
            {...{
              formik,
              selectedHeat,
              handleCurrentHeat,
              prevHeat,
              nextHeat,
              finalHeatScores: initialSortedHeatScores,
              handleToggleConfirmDialog,
              addNewRow,
              isFetchingHeatScore,
            }}
          />
        </form>
      </Box>
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            endHeatHandler(values);
          }}
          title={'ARE YOU SURE YOU WANT TO END THIS HEAT?'}
          body={'This will set the scores and End Time. You can come back to make edits if needed.'}
          formik={formik}
          hasEndTimeHandler={true}
        />
      )}
    </Box>
  );
};

export default ScoreManage;
