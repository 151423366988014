export const SOCKET_NAMESPACES = {
  JAI_ALAI: 'JAI_ALAI',
  MASL: 'MASL',
};

export const WEB_SOCKET_EVENT_TYPES = {
  AUTHORIZATION_ERROR: 'authorizationError',
  RESPONSE: 'response',
  ERRORS: 'errors',
  VALIDATION_ERRORS: 'validationErrors',
  EVENT_REFRESH: 'eventRefresh',
  ODDS_REFRESH: 'oddRefresh',
};

export const WEB_SOCKET_EMIT_EVENT_TYPES = {
  UPDATE_ODDS: 'updateOdds',
  FETCH_GAME_MARKET_ODDS: 'fetchGameMarketOdds',
  // Only for testing
  TRIGGER_ERROR: 'triggerError',
};

export const WEB_SOCKET_EMIT_ERROR_EVENT_TYPES_PAYLOAD = {
  AUTHORIZATION_ERROR: 'authorizationError',
};
