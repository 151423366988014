import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';

import DecimalField from '@/components/DecimalField';

import { HeatStatus } from '@/constants/heats';

import { Athlete } from '@/types/athlete';
import { HeatDTO, HeatScoresDTO } from '@/types/heat';
import { getUpdatedWSLRanksInScores } from '@/helpers/heats';

const HeatManageTextField = styled(TextField)({ input: { backgroundColor: '#E9ECEF' } });
const DeleteIconStyled = styled(DeleteIcon)({ fontSize: '1.65rem', padding: '0.2rem' });

const initialAthleteOption = { id: '', label: '' };

const tableColumns = ({
  formik,
  isSmallAndDown,
  isMediumAndDown,
  selectedHeat,
  heatScores,
  players,
}: {
  formik: any;
  isSmallAndDown: boolean;
  isMediumAndDown: boolean;
  selectedHeat: HeatDTO;
  heatScores: { heat: any; scores: HeatScoresDTO[] };
  players: Athlete[];
}) => {
  return [
    {
      title: '',
      width: '10%',
      hidden: isSmallAndDown ? false : true,
      component: (idx: number) => {
        const mobExpandHandler = (event: React.BaseSyntheticEvent) => {
          const newIndex = formik.values.detailExpandedRow === idx ? -1 : idx;
          formik.setFieldValue('detailExpandedRow', newIndex);
          event.stopPropagation();
        };
        return (
          <Tooltip key={`${formik.values.heatScores[idx].id}_expand`} title={'Open details'}>
            <IconButton color="inherit" size="large" onClick={mobExpandHandler}>
              {<ExpandMoreIcon />}
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      title: 'Seed',
      width: '10%',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const isSeedDisabled = formik.values.heatScores[idx].toDelete;
        const seedHasErr =
          formik.errors.heatScores &&
          formik.touched.heatScores &&
          formik.errors?.heatScores[idx]?.roundSeed
            ? true
            : false;
        const seedHelperText =
          formik.errors.heatScores && formik.touched.heatScores
            ? formik.touched.heatScores[idx]?.roundSeed &&
              formik.errors.heatScores[idx]?.roundSeed &&
              'Round Seed is required'
            : '';
        const seedOnBlurHandler = (event: React.BaseSyntheticEvent) => {
          formik.handleBlur(event);
          // Deep clone so that nested properties' refs are not linked to formik values.
          const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
          newHeatScores[idx] = {
            ...newHeatScores[idx],
            roundSeed: +event.target.value,
          };
          formik.setFieldValue('heatScores', newHeatScores);
        };
        return (
          <TextField
            key={`${formik.values.heatScores[idx].id}_seed`}
            type="number"
            disabled={isSeedDisabled}
            name={`heatScores[${idx}].roundSeed`}
            defaultValue={formik.values.heatScores[idx].roundSeed}
            fullWidth
            error={seedHasErr}
            helperText={seedHelperText}
            onBlur={seedOnBlurHandler}
          />
        );
      },
    },
    {
      title: 'Athlete',
      width: isMediumAndDown ? (isSmallAndDown ? '70%' : '50%') : '1fr',
      component: (idx: number) => {
        const isAthleteDisabled = formik.values.heatScores[idx].toDelete;
        const athleteComparator = (option: any, value: any) => option.label === value.label;
        const athleteOnChangeHandler = (_: React.BaseSyntheticEvent, newValue: any | null) => {
          // Deep clone so that nested properties' refs are not linked to formik values.
          const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
          newHeatScores[idx] = {
            ...newHeatScores[idx],
            athlete: newValue ? newValue : initialAthleteOption,
          };
          formik.setFieldValue('heatScores', newHeatScores);
        };
        const athleteHasErr =
          formik.errors.heatScores &&
          formik.touched.heatScores &&
          formik.errors?.heatScores[idx]?.athlete
            ? true
            : false;
        const athleteHelperText =
          formik.errors.heatScores && formik.touched.heatScores
            ? formik.errors?.heatScores[idx]?.athlete && 'Athlete is required'
            : '';
        return (
          <Autocomplete
            key={`${formik.values.heatScores[idx].id}_athlete`}
            disableClearable
            disabled={isAthleteDisabled}
            // defaultValue={null}
            options={players}
            isOptionEqualToValue={athleteComparator}
            value={formik.values.heatScores[idx]?.athlete || ''}
            onChange={athleteOnChangeHandler}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                name={`heatScores[${idx}].athlete`}
                onBlur={formik.handleBlur}
                fullWidth
                error={athleteHasErr}
                helperText={athleteHelperText}
              />
            )}
          />
        );
      },
    },
    {
      title: 'Heat Score',
      field: 'runScore',
      width: isMediumAndDown ? '1fr' : '15%',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const rowValue: string = formik.values.heatScores[idx].heatScore;
        let score: number | null = null;
        if (rowValue) score = +rowValue;
        const isHeatScoreDisabled = formik.values.heatScores[idx].toDelete;
        const heatScoreHasErr =
          formik.errors.heatScores &&
          formik.touched.heatScores &&
          formik.errors.heatScores[idx]?.heatScore
            ? true
            : false;
        const heatScoreHelperText =
          formik.errors.heatScores && formik.touched.heatScores
            ? formik.errors.heatScores[idx]?.heatScore && 'Heat Score is required'
            : '';
        const heatScoreStyling =
          (score && score >= 0) || heatScores?.heat?.heatStatus === HeatStatus.COMPLETED
            ? { input: { backgroundColor: '#E9ECEF' } }
            : undefined;
        const heatScoreOnBlurHandler = (event: React.BaseSyntheticEvent) => {
          formik.handleBlur(event);
          // Deep clone so that nested properties' refs are not linked to formik values.
          const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
          newHeatScores[idx] = {
            ...newHeatScores[idx],
            heatScore: isNaN(parseFloat(event.target.value)) ? '0' : parseFloat(event.target.value),
          };
          formik.setFieldValue('heatScores', getUpdatedWSLRanksInScores(newHeatScores));
        };
        return (
          <DecimalField
            key={`${formik.values.heatScores[idx].id}_score`}
            disabled={isHeatScoreDisabled}
            name={`heatScores[${idx}].heatScore`}
            id={`${formik.values.heatScores[idx].id}_score`}
            value={score?.toFixed(2)}
            fullWidth
            error={heatScoreHasErr}
            helperText={heatScoreHelperText}
            sx={heatScoreStyling}
            onBlur={heatScoreOnBlurHandler}
            decimalScale={2}
          />
        );
      },
    },
    {
      title: 'Heat Place',
      field: 'heatPosition',
      width: isMediumAndDown ? '1fr' : '10%',
      // hidden: isSmallAndDown,
      component: (idx: number) => {
        const isHeatPositionDisabled = formik.values.heatScores[idx].toDelete;
        const heatPositionHasErr =
          formik.errors.heatScores &&
          formik.touched.heatScores &&
          formik.errors.heatScores[idx]?.heatPosition
            ? true
            : false;
        const heatPositionHelperText =
          formik.errors.heatScores && formik.touched.heatScores
            ? formik.errors.heatScores[idx]?.heatPosition && 'Heat Position is required'
            : '';
        return (
          <HeatManageTextField
            key={`${formik.values.heatScores[idx].id}_place`}
            disabled={isHeatPositionDisabled}
            name={`heatScores[${idx}].heatPosition`}
            value={formik.values.heatScores[idx].heatPosition}
            fullWidth
            error={heatPositionHasErr}
            helperText={heatPositionHelperText}
            onBlur={formik.handleBlur}
          />
        );
      },
    },
    {
      title: 'Action',
      width: isMediumAndDown ? '1fr' : '5%',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const isActionDisabled = selectedHeat?.heatStatus === HeatStatus.COMPLETED;
        const actionIconClr = formik.values.heatScores[idx].toDelete ? 'warning' : 'default';
        const onClickActionHandler = () =>
          formik.setFieldValue(
            `heatScores.${idx}.toDelete`,
            !formik.values.heatScores[idx].toDelete,
          );

        return (
          <Tooltip
            key={`${formik.values.heatScores[idx].id}_action`}
            title="Delete Row"
            placement="top"
            arrow
          >
            {/* Tooltip needs a child that isn't disabled, hence fragment */}
            <>
              <IconButton
                disabled={isActionDisabled}
                color={actionIconClr}
                onClick={onClickActionHandler}
              >
                <DeleteIconStyled />
              </IconButton>
            </>
          </Tooltip>
        );
      },
    },
  ];
};

export default tableColumns;
