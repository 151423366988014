import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { HIDDEN_SPORTS } from '@/constants/sportTypes';

import { authenticatedAxios } from '..';

const getSports = async () => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/sports`,
  });

  // return data;

  const filteredSports = data.filter((row: any) => !HIDDEN_SPORTS.includes(row.sportType));

  return filteredSports;
};

export default function useFetchSports() {
  return useQuery([keys.sports.fetchSports], () => getSports(), {
    staleTime: Infinity,
  });
}
