import SPORT_TYPES from '@/constants/sportTypes';

export const allowedSLSHeaders = [
  { title: 'Heat Name', field: 'heatName' },
  { title: 'Heat Number', field: 'heatNo' },
  { title: 'Heat Status', field: 'heatStatusMod' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Gender', field: 'gender' },
];

export const allowedWSLHeaders = [
  { title: 'Heat Name', field: 'heatName' },
  { title: 'Heat Number', field: 'heatNo' },
  { title: 'Heat Status', field: 'heatStatusMod' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Gender', field: 'gender' },
];

export const allowedNRXHeaders = [
  { title: 'Heat Name', field: 'heatName' },
  { title: 'Heat Number', field: 'heatNo' },
  { title: 'Heat Status', field: 'heatStatusMod' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
];
export const allowedSPRHeaders = [
  { title: 'Heat Name', field: 'heatName' },
  { title: 'Heat Number', field: 'heatNo' },
  { title: 'Heat Status', field: 'heatStatusMod' },
  { title: 'Round Name', field: 'roundName' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
];

export type HeatRow = {
  name: string;
  round: string;
  event: string;
  tour_name?: string;
  year: number;
  heat_status: string;
  gender: string;
};

export const allowedTableHeaders = {
  [SPORT_TYPES.WSL]: allowedWSLHeaders,
  [SPORT_TYPES.SLS]: allowedSLSHeaders,
  [SPORT_TYPES.NRX]: allowedNRXHeaders,
  [SPORT_TYPES.SPR]: allowedSPRHeaders,
};

export const commonRequiredKeys = ['name', 'event', 'round', 'year', 'heat_status', 'gender'];

export const WSLRequiredKeys = [...commonRequiredKeys];

export const SLSRequiredKeys = [...commonRequiredKeys];

export const NRXRequiredKeys = [...commonRequiredKeys, 'tour_name'];

export const SPRRequiredKeys = [...commonRequiredKeys, 'tour_name'];

export const requiredPayloadKeys = {
  [SPORT_TYPES.WSL]: WSLRequiredKeys,
  [SPORT_TYPES.SLS]: SLSRequiredKeys,
  [SPORT_TYPES.NRX]: NRXRequiredKeys,
  [SPORT_TYPES.SPR]: SPRRequiredKeys,
};
