import { useQuery } from '@tanstack/react-query';

import { authenticatedAxios } from '..';
import keys from '@/constants/queryKeys';
import { GameDTO } from '@/types/game';
import SPORT_TYPES from '@/constants/sportTypes';

interface FETCH_GAME {
  sportType: string;
  gameDateId?: string;
  params?: any;
}

const getGame = async ({ sportType, gameDateId, params }: FETCH_GAME): Promise<GameDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/traders/scores/games/${gameDateId}`,
    params: params,
  });

  return data;
};

export default function useFetchGame({ sportType, gameDateId, params }: FETCH_GAME) {
  return useQuery(
    [keys.games.fetchGame, sportType, gameDateId],
    () => getGame({ sportType, gameDateId, params }),
    {
      staleTime: Infinity,
      enabled: !!gameDateId,
      ...(sportType !== SPORT_TYPES.JAIALAI
        ? { refetchInterval: 5 * (60 * 1000), refetchIntervalInBackground: true }
        : {}),
    },
  );
}
