import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { ComponentProps } from 'react';
import { IMaskMixin } from 'react-imask';

const InternalMaskTextField = IMaskMixin(({ id, ...otherProps }) => (
  <TextField
    {...(otherProps as any)}
    inputProps={{
      'data-testid': id,
    }}
  />
));

type MaskProps = ComponentProps<typeof InternalMaskTextField>;

export const LapTimeField = (props: TextFieldProps & MaskProps) => {
  return <InternalMaskTextField {...props} />;
};
