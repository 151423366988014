import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { ExactaEventOddsDTO } from '@/types/odds/getOdds';
import { filterObj } from '@/constants/misc';

const getExactasEventOdds = async ({
  sport,
  eventId,
  exactasType,
  params,
}: {
  sport: string;
  eventId: string;
  exactasType: number;
  params?: { query: string; page: number; sortColumn: string; sortOrder: string };
}): Promise<ExactaEventOddsDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/exactas/odds/${exactasType}`,
    params: params ? params : {},
  });
  return data;
};

export default function useFetchExactasEventOdds({
  sport,
  eventId,
  exactasType,
  params,
}: {
  sport: string;
  eventId: string;
  exactasType: number;
  params?: { query: string; page: number; sortColumn: string; sortOrder: string };
}) {
  const exactasEventOddsKey = [
    keys.odds.exactas.fetchExactasEventOdds,
    sport,
    eventId,
    exactasType,
  ];

  const filteredParams: { [key: string]: number | string } | undefined = params
    ? filterObj(params)
    : undefined;
  if (params) {
    exactasEventOddsKey.push(JSON.stringify(filteredParams));
  }

  return useQuery(
    exactasEventOddsKey,
    () =>
      getExactasEventOdds({
        sport,
        eventId,
        exactasType,
        params,
      }),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
