import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface UPDATE_BYB_SCORES {
  sport: string;
  eventId: string;
  roundHeatId: string;
  requestPayload: any;
}

const updateBYBScores = async ({
  sport,
  eventId,
  roundHeatId,
  requestPayload,
}: UPDATE_BYB_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/traders/events/${eventId}/scores/${roundHeatId}`,
    data: requestPayload,
  });

  return data;
};

export default function useUpdateBYBScores() {
  return useMutation(({ sport, eventId, roundHeatId, requestPayload }: UPDATE_BYB_SCORES) =>
    updateBYBScores({ sport, eventId, roundHeatId, requestPayload }),
  );
}
