import React from 'react';
import { Grid, Typography, styled } from '@mui/material';

import config from '@/config/vars';

const BannerBg = styled(Grid)({
  backgroundColor: config.isStaging ? '#FFF7AF' : '#EBC9C9',
  position: 'sticky',
  top: 0,
  zIndex: '1500',
  height: '1.875rem',
  maxHeight: '1.875rem',
});

const BannerText = styled(Typography)({
  fontWeight: 500,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  color: '#212529',
  textAlign: 'center',
});

const Banner = () => {
  if (config.isProd) return null;
  return (
    <BannerBg container alignItems={'center'}>
      <Grid item xs={12}>
        <BannerText>{`ADMIN ${config.isStaging ? 'STAGE' : 'DEV'}`}</BannerText>
      </Grid>
    </BannerBg>
  );
};

export default Banner;
