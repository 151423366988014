import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

import config from '@/config/vars';

import routes from '@/constants/routes';

// Layouts
import BaseLayout from '@/layouts/BaseLayout';
import DashboardLayout from '@/layouts/DashboardLayout';

// Route Guards
import ProtectedRoute from '@/routes/ProtectedRoute';
import UnProtectedRoute from '@/routes/UnProtectedRoute';

// Auth Pages
import Login from '@/pages/Login';

// Event Pages
import AddEventPage from './pages/Events/add';
import EventsListingPage from './pages/Events';
import SportEvent from './pages/[sportsType]/event/[eventId]';
import SportEventGames from './pages/[sportsType]/eventGames/[gameDateId]';

// Event Management Components
import SeedsUpload from './pages/trader/seeds/upload';
import ScoresUpload from './pages/trader/scores/upload';
import PointsUpload from './pages/trader/points/upload';
import TraderEventOddsUpload from './pages/trader/odds/event/upload';
import TraderHeatOddsUpload from './pages/trader/odds/heat/upload';
import TraderHeadToHeadOddsUpload from './pages/trader/odds/headToHead/upload';
import TraderPropBetsOddsUpload from './pages/trader/odds/propBets/upload';
import TraderShowOddsUpload from './pages/trader/odds/show/upload';
import TraderPodiumOddsUpload from './pages/trader/odds/podium/upload';

import EventsUpload from './pages/Events/Upload';
import ToursLeaguesUpload from './pages/Tours-Leagues/Upload';
import RoundsUpload from './pages/Rounds/Upload';
import HeatsUpload from './pages/Heats/Upload';

import PageNotFound from './pages/PageNotFound';

// Future Pages
import FuturesListingPage from './pages/futures';
import SportTourFutures from './pages/[sportsType]/futures/[tourYearId]';

// User Management
import UserManagementPage from '@/pages/user-management';

import { getAuthToken } from './constants/localStorageKeys';

// import { sportsType } from '@/atoms/sportsType';
// import { SPORT_TYPE_DEFAULT_ROUTE } from '@/constants/allRoutes';

const PROTECTED_ROUTE_PAGES = {
  // ----- Futures Routes -----
  [routes.futures.home]: <DashboardLayout component={FuturesListingPage} />,
  [routes.sportType.futures.tourYearId]: <DashboardLayout component={SportTourFutures} />,

  // ----- Event Routes -----
  [routes.events.home]: <DashboardLayout component={EventsListingPage} />,
  [routes.events.add]: <DashboardLayout component={AddEventPage} />,
  [routes.sportType.event.eventId]: <DashboardLayout component={SportEvent} />,
  [routes.sportType.eventGames.gameDateId]: <DashboardLayout component={SportEventGames} />,

  // ----- Event Management Routes -----
  [routes.events.upload]: <DashboardLayout component={EventsUpload} />,
  [routes.traders.seeds.upload]: <DashboardLayout component={SeedsUpload} />,
  [routes.traders.scores.upload]: <DashboardLayout component={ScoresUpload} />,
  [routes.traders.points.upload]: <DashboardLayout component={PointsUpload} />,
  [routes.traders.odds.event.upload]: <DashboardLayout component={TraderEventOddsUpload} />,
  [routes.traders.odds.heat.upload]: <DashboardLayout component={TraderHeatOddsUpload} />,
  [routes.traders.odds.headToHead.upload]: (
    <DashboardLayout component={TraderHeadToHeadOddsUpload} />
  ),
  [routes.traders.odds.propBets.upload]: <DashboardLayout component={TraderPropBetsOddsUpload} />,
  [routes.traders.odds.shows.upload]: <DashboardLayout component={TraderShowOddsUpload} />,
  [routes.traders.odds.podiums.upload]: <DashboardLayout component={TraderPodiumOddsUpload} />,
  [routes.toursLeagues.upload]: <DashboardLayout component={ToursLeaguesUpload} />,
  [routes.rounds.upload]: <DashboardLayout component={RoundsUpload} />,
  [routes.heats.upload]: <DashboardLayout component={HeatsUpload} />,

  // ----- User Management Routes -----
  [routes.userManagement]: <DashboardLayout component={UserManagementPage} />,
};

const UNPROTECTED_ROUTE_PAGES = {
  [routes.dashboard.home]: <Navigate to={routes.events.home} replace />,
  [routes.public.login]: <BaseLayout component={Login} />,
};

const Router = () => {
  // const [sportType] = useRecoilState(sportsType);
  const token = getAuthToken();

  if (config.isAnalyticsEnabled) {
    const history = useLocation();
    React.useEffect(() => {
      ReactGA.pageview(history.pathname);
    }, [history.pathname]);
  }

  return (
    <Routes>
      {/* ************************* SLS ********************************** */}
      {React.Children.toArray(
        Object.keys(PROTECTED_ROUTE_PAGES).map((route) => (
          <Route
            path={route}
            element={<ProtectedRoute>{PROTECTED_ROUTE_PAGES[route]}</ProtectedRoute>}
          />
        )),
      )}

      {React.Children.toArray(
        Object.keys(UNPROTECTED_ROUTE_PAGES).map((route) => (
          <Route
            path={route}
            element={<UnProtectedRoute>{UNPROTECTED_ROUTE_PAGES[route]}</UnProtectedRoute>}
          />
        )),
      )}

      <Route
        path={routes.fourOhFour}
        element={
          token ? (
            <ProtectedRoute>
              <DashboardLayout component={PageNotFound} />
            </ProtectedRoute>
          ) : (
            <UnProtectedRoute>
              <BaseLayout component={PageNotFound} />
            </UnProtectedRoute>
          )
        }
      />
    </Routes>
  );
};

export default Router;
