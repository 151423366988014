import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  styled,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  IconButton,
  Box,
  InputAdornment,
  Autocomplete,
  createFilterOptions,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { enqueueSnackbar } from 'notistack';
import { zonedTimeToUtc } from 'date-fns-tz';

import CloseIcon from '@mui/icons-material/Close';

import { editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import UnstyledDecimalField from '@/components/DecimalField';

import useAddOrUpdateEventSeeds from '@/hooks/fights/useAddOrUpdateEventSeeds';

import {
  DEFAULT_TIMEZONE,
  HEIGHT_UNITS,
  // HEIGHT_UNIT_OPTIONS,
  WEIGHT_UNITS,
  // WEIGHT_UNIT_OPTIONS,
  cmToFeetAndInches,
  feetAndInchesToCm,
  kgToLbs,
  lbsToKg,
} from '@/constants/misc';
import { IEvent } from '@/types/newTypes/event';
import { FightMatchMemberTypes, FIGHT_MEMBER_TYPES_OPTIONS } from '@/constants/fights';
import { displayInPT } from '@/helpers/timeConverters';
import useFetchWeightClasses from '@/hooks/fightSportMisc/useFetchWeightClasses';
import { useQueryClient } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
// import keys from '@/constants/queryKeys';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '1.5rem 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1.5rem 1.5rem 3rem',
  },
}));

const FormLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

interface ParticipantInfo {
  firstName: string;
  lastName: string;
  nickName: string;
  dateOfBirth: string;
  homeTown: string;
  eventName: string;
  notes: string;
  height: number;
  weight: number;
  federalId: string;
  weightClass: string;
  id: string;
  role: number;
}

type AddOrEditParticipantModalProps = {
  open: boolean;
  handleClose: () => void;
  title?: string;
  sportType: string;
  isEditParticipant?: boolean;
  refetchParticipants?: VoidFunction;
  event: Partial<IEvent>;
  participant?: Partial<ParticipantInfo>;
};

const CounterInput = styled(UnstyledDecimalField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#ffffff',
    '& input': {
      textAlign: 'center',
      // width: 30,
      height: 40,
      padding: 0,
    },
    '& fieldset': { border: 'none' },
  },
  '& .MuiInputBase-input': {
    padding: '0.25rem 0.75rem',
    fontSize: '0.812rem',
  },
});

const PARTICIPANT_ID_TYPE = {
  [FightMatchMemberTypes.ATHLETE]: 'athleteId',
  [FightMatchMemberTypes.JUDGE]: 'judgeId',
  [FightMatchMemberTypes.REFEREE]: 'refereeId',
};

type WeightClassProp = { label: string; inputValue?: string };

const filter = createFilterOptions<WeightClassProp>();

const fromComponent = 'Participants';

const AddOrEditParticipantModal = (props: AddOrEditParticipantModalProps) => {
  const {
    open,
    handleClose,
    // title = 'THIS WILL UPDATE THE EVENT DETAILS THROUGHOUT THE SYSTEM. ',
    sportType,
    refetchParticipants,
    participant,
    event,
  } = props;

  const queryClient = useQueryClient();

  const { data: weightClassArr } = useFetchWeightClasses(sportType, fromComponent);

  const WEIGHT_CLASSES = React.useMemo(
    () => weightClassArr?.map((wgtClass) => ({ label: wgtClass })),
    [weightClassArr],
  );

  const { mutateAsync: addOrUpdateEventSeeds } = useAddOrUpdateEventSeeds();

  const initialValues = {
    role: participant?.role || FightMatchMemberTypes.ATHLETE,
    firstName: participant?.firstName || '',
    lastName: participant?.lastName || '',
    nickName: participant?.nickName || '',
    dateOfBirth: participant?.dateOfBirth
      ? displayInPT(participant?.dateOfBirth, undefined, true)
      : null,
    homeTown: participant?.homeTown || '',
    eventName: event?.name || '',
    // notes: '',
    height: participant?.height || 0,
    weight: participant?.weight || 0,
    federalId: participant?.federalId || '',
    weightClass: participant?.weightClass ? { label: participant?.weightClass } : null,
    heightUnitType: 'feet',
    feet: cmToFeetAndInches(Number(participant?.height || null) as number)?.feet || 0,
    inches: cmToFeetAndInches(Number(participant?.height || null) as number)?.inches || 0,
    weightUnitType: 'lbs',
    lbs: kgToLbs(Number(participant?.weight || null) as number) || 0,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: (values: any) => {
      let schema = Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
      });

      if (values?.role === FightMatchMemberTypes.ATHLETE) {
        schema = Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          nickName: Yup.string().required('Nickname is required'),
          dateOfBirth: Yup.date().required('Date of birth is required'),
          homeTown: Yup.string().required('Home town is required'),
          // weightClass: Yup.string().required('Weight Class is required'),
          // lbs: Yup.number().positive('Weight must be positive').required('Weight is required'),
          // federalId: Yup.string().required('Federal ID is required'),
          feet: Yup.number()
            .required('Feet is required')
            .integer('Feet must be an integer')
            .min(4, 'Feet must be greater than or equal to 4')
            .max(7, 'Inches must be less than 8'),
          inches: Yup.number()
            .required('Inches is required')
            .integer('Inches must be an integer')
            .min(0, 'Inches must be greater than or equal to 0')
            .max(11, 'Inches must be less than 12'),
        });
      }

      return schema;
    },
    onSubmit: () => {
      const requestPayload = formatPayload();
      addOrUpdateEventSeeds(
        { sportType, payload: { items: [requestPayload] } },
        {
          onSettled: (_, err) => {
            if (!err) {
              enqueueSnackbar(`${participant ? 'Updated' : 'Added'} participant successfully!`);
              if (refetchParticipants) {
                refetchParticipants();
              }
              queryClient.invalidateQueries([
                keys.fightSportMisc.fetchWeightClasses,
                sportType,
                fromComponent,
              ]);
            }
            handleClose();
          },
        },
      );
    },
  });

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldTouched,
  } = formik;

  const timeHandler = (newValue: unknown, dateType: string) => {
    setFieldValue(dateType, newValue || null);
  };

  const formatPayload = () => {
    const payload = {
      ...(participant ? { [PARTICIPANT_ID_TYPE[values.role]]: participant?.id } : {}),
      firstName: values?.firstName,
      lastName: values?.lastName,
      nickName: values?.nickName,
      dateOfBirth: values?.dateOfBirth
        ? zonedTimeToUtc(values?.dateOfBirth as unknown as Date, DEFAULT_TIMEZONE)
        : null,
      homeTown: values?.homeTown,
      federalId: values?.federalId || '',
      height: +values?.height || 0,
      weight: +values?.weight || 0,
      weightClass:
        (values?.weightClass && values?.weightClass?.inputValue
          ? values?.weightClass?.inputValue
          : values?.weightClass?.label) || '',
      role: +values?.role,
      eventName: event?.name || '',
    };
    return payload;
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <form>
        <Box
          sx={{
            px: '1.3rem',
            py: 0.5,
            borderBottom: '1px solid #DEE2E6',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,

              fontSize: '1.25rem',
              lineHeight: '1.5rem',
            }}
            component="h2"
          >
            {participant ? 'Edit participant' : 'Create new participant'}
          </Typography>
          <IconButton onClick={handleClose} disableRipple>
            <CloseIcon sx={{ fontSize: '1.25rem' }} />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container sx={{ gap: '0.6rem' }}>
            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Member Type</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl fullWidth>
                  <Select
                    name="role"
                    value={values.role}
                    onChange={(event: SelectChangeEvent<typeof values.role>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('role', value);
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                  >
                    {React.Children.toArray(
                      FIGHT_MEMBER_TYPES_OPTIONS?.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>First Name</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.firstName && touched?.firstName)}
                  helperText={errors?.firstName && touched?.firstName ? errors?.firstName : ''}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Last Name</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={!!(errors?.lastName && touched?.lastName)}
                  helperText={errors?.lastName && touched?.lastName ? errors?.lastName : ''}
                />
              </Grid>
            </Grid>
            {values.role === FightMatchMemberTypes.ATHLETE && (
              <>
                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Nickname</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name="nickName"
                      value={values.nickName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{ ...editCellTextFieldStyle }}
                      error={!!(errors?.nickName && touched?.nickName)}
                      helperText={errors?.nickName && touched?.nickName ? errors?.nickName : ''}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Hometown</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name="homeTown"
                      value={values.homeTown}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{ ...editCellTextFieldStyle }}
                      error={!!(errors?.homeTown && touched?.homeTown)}
                      helperText={errors?.homeTown && touched?.homeTown ? errors?.homeTown : ''}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Date Of Birth</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <DatePicker
                      format="MM/dd/yyyy"
                      value={values.dateOfBirth || null}
                      onChange={(newValue: unknown) => {
                        setFieldTouched('dateOfBirth', true);
                        timeHandler(newValue, 'dateOfBirth');
                      }}
                      sx={{ ...editCellTextFieldStyle }}
                      slotProps={{
                        textField: {
                          name: 'dateOfBirth',
                          error: !!(errors?.dateOfBirth && touched?.dateOfBirth),
                          helperText:
                            errors?.dateOfBirth && touched?.dateOfBirth ? errors?.dateOfBirth : '',
                          fullWidth: true,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Federal ID</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField
                      name="federalId"
                      value={values.federalId}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{ ...editCellTextFieldStyle }}
                      error={!!(errors?.federalId && touched?.federalId)}
                      helperText={errors?.federalId && touched?.federalId ? errors?.federalId : ''}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Weight Class</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Autocomplete
                      value={values.weightClass}
                      onChange={(_, newValue) => {
                        if (typeof newValue === 'string') {
                          setFieldValue('weightClass', {
                            label: newValue,
                          });
                        } else if (newValue && newValue?.inputValue) {
                          // Create a new value from the user input
                          setFieldValue('weightClass', {
                            label: newValue?.inputValue || '',
                            inputValue: newValue?.inputValue || '',
                          });
                        } else {
                          setFieldValue('weightClass', newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue = '' } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.label);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            label: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      options={WEIGHT_CLASSES as WeightClassProp[]}
                      freeSolo
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          padding: '0.442rem 0.5625rem',
                        },
                        '& .MuiInputBase-input': {
                          height: '0.41rem !important',
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="weightClass"
                          sx={{ ...editCellTextFieldStyle }}
                          fullWidth
                        />
                      )}
                    />
                    {/* <FormControl
                      error={errors?.weightClass && touched?.weightClass ? true : false}
                      fullWidth
                    >
                      <Select
                        name="weightClass"
                        value={values.weightClass}
                        onChange={(event: SelectChangeEvent<typeof values.weightClass>) => {
                          const {
                            target: { value },
                          } = event;
                          setFieldValue('weightClass', value);
                        }}
                        sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                        fullWidth
                        error={!!(errors?.weightClass && touched?.weightClass)}
                      >
                        {React.Children.toArray(
                          WEIGHT_CLASSES?.map((option) => (
                            <MenuItem value={option.value}>{option.label}</MenuItem>
                          )),
                        )}
                      </Select>
                      {errors?.weightClass && touched?.weightClass && (
                        <FormHelperText>Weight Class is required</FormHelperText>
                      )}
                    </FormControl> */}
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Height</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Box
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '0.25rem',
                        border: '1px solid #CED4DA',
                        gridTemplateColumns: 'repeat(2, 50fr)',
                        // gridTemplateColumns:
                        //   values?.heightUnitType === HEIGHT_UNITS.cm
                        //     ? `repeat(2, 50fr)`
                        //     : `30fr 20fr 50fr`,
                      }}
                    >
                      {/*
                  {values?.heightUnitType === HEIGHT_UNITS.cm && (
                    <CounterInput
                      size="small"
                      onChange={handleChange}
                      onBlur={({ target }: React.FocusEvent<HTMLInputElement>) => {
                        setFieldTouched('height', true);
                        setFieldValue('height', +target.value);
                        const { feet, inches } = cmToFeetAndInches(+target.value);
                        setFieldValue('feet', feet);
                        setFieldValue('inches', inches);
                      }}
                      name="height"
                      value={values?.height}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#ffffff',
                          '& fieldset': { borderRight: '1px solid #CED4DA', borderRadius: 0 },
                        },
                      }}
                      decimalScale={0}
                      // helperText={touched?.height && errors?.height}
                    />
                  )}
                    */}
                      {values?.heightUnitType === HEIGHT_UNITS.feet && (
                        <CounterInput
                          size="small"
                          onChange={handleChange}
                          onBlur={({ target }: React.FocusEvent<HTMLInputElement>) => {
                            setFieldTouched('feet', true);
                            setFieldValue('feet', +target.value);
                            const cm = feetAndInchesToCm(+target.value, +values?.inches);
                            setFieldValue('height', cm);
                          }}
                          name="feet"
                          value={values?.feet}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: '#ffffff',
                              '& fieldset': { borderRight: '1px solid #CED4DA', borderRadius: 0 },
                            },
                          }}
                          decimalScale={0}
                          // helperText={touched?.feet && errors?.feet}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                          }}
                        />
                      )}
                      {values?.heightUnitType === HEIGHT_UNITS.feet && (
                        <CounterInput
                          size="small"
                          onChange={handleChange}
                          onBlur={({ target }: React.FocusEvent<HTMLInputElement>) => {
                            setFieldTouched('inches', true);
                            setFieldValue('inches', +target.value);
                            const cm = feetAndInchesToCm(values?.feet, +target.value);
                            setFieldValue('height', cm);
                          }}
                          name="inches"
                          value={values?.inches}
                          // sx={{
                          //   '& .MuiOutlinedInput-root': {
                          //     backgroundColor: '#ffffff',
                          //     '& fieldset': { borderRight: '1px solid #CED4DA', borderRadius: 0 },
                          //   },
                          // }}
                          decimalScale={0}
                          // helperText={touched?.inches && errors?.inches}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">in</InputAdornment>,
                          }}
                        />
                      )}
                      {/* <Select
                    name="heightUnitType"
                    value={values.heightUnitType}
                    onChange={(event: SelectChangeEvent<typeof values.heightUnitType>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('heightUnitType', value);
                    }}
                    sx={{
                      height: 38,
                      ...headToHeadTableStyles.athleteNameStyle,
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                    error={errors?.heightUnitType && touched?.heightUnitType ? true : false}
                  >
                    {React.Children.toArray(
                      HEIGHT_UNIT_OPTIONS?.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select> */}
                    </Box>
                    {/* {values?.heightUnitType === HEIGHT_UNITS.cm ? (
                  <>
                    {touched?.height && errors?.height && (
                      <FormHelperText error>{errors?.height}</FormHelperText>
                    )}
                  </>
                ) : ( */}
                    <>
                      {touched?.feet && errors?.feet && (
                        <FormHelperText error>{errors?.feet}</FormHelperText>
                      )}
                      {touched?.inches && errors?.inches && (
                        <FormHelperText error>{errors?.inches}</FormHelperText>
                      )}
                    </>
                    {/* )} */}
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid container alignItems={'center'} item xs={3}>
                    <FormLabel>Weight</FormLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Box
                      sx={{
                        display: 'grid',
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '0.25rem',
                        border: '1px solid #CED4DA',
                        // gridTemplateColumns: 'repeat(2, 50fr)',
                      }}
                    >
                      {/* {values?.weightUnitType === WEIGHT_UNITS.kg && (
                    <CounterInput
                      size="small"
                      onChange={handleChange}
                      onBlur={({ target }: React.FocusEvent<HTMLInputElement>) => {
                        setFieldTouched('weight', true);
                        setFieldValue('weight', +target.value);
                        const lbs = kgToLbs(+target.value);
                        setFieldValue('lbs', lbs);
                      }}
                      name="weight"
                      value={values?.weight}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: '#ffffff',
                          '& fieldset': { borderRight: '1px solid #CED4DA', borderRadius: 0 },
                        },
                      }}
                      decimalScale={0}
                      // helperText={touched?.weight && errors?.weight}
                    />
                  )} */}
                      {values?.weightUnitType === WEIGHT_UNITS.lbs && (
                        <CounterInput
                          size="small"
                          onChange={handleChange}
                          onBlur={({ target }: React.FocusEvent<HTMLInputElement>) => {
                            setFieldTouched('lbs', true);
                            setFieldValue('lbs', +target.value);
                            const kg = lbsToKg(+target.value);
                            setFieldValue('weight', kg);
                          }}
                          name="lbs"
                          value={values?.lbs}
                          // sx={{
                          //   '& .MuiOutlinedInput-root': {
                          //     backgroundColor: '#ffffff',
                          //     '& fieldset': { borderRight: '1px solid #CED4DA', borderRadius: 0 },
                          //   },
                          // }}
                          decimalScale={2}
                          fullWidth
                          InputProps={{
                            endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                          }}
                          // helperText={touched?.lbs && errors?.lbs}
                        />
                      )}
                      {/* <Select
                    name="weightUnitType"
                    value={values.weightUnitType}
                    onChange={(event: SelectChangeEvent<typeof values.weightUnitType>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('weightUnitType', value);
                    }}
                    sx={{
                      height: 38,
                      ...headToHeadTableStyles.athleteNameStyle,
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                    error={errors?.weightUnitType && touched?.weightUnitType ? true : false}
                  >
                    {React.Children.toArray(
                      WEIGHT_UNIT_OPTIONS?.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select> */}
                    </Box>
                    {/* {values?.weightUnitType === WEIGHT_UNITS.kg ? (
                  <>
                    {touched?.weight && errors?.weight && (
                      <FormHelperText error>{errors?.weight}</FormHelperText>
                    )}
                  </>
                ) : ( */}
                    <>
                      {touched?.lbs && errors?.lbs && (
                        <FormHelperText error>{errors?.lbs}</FormHelperText>
                      )}
                    </>
                    {/* )} */}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                }}
                fullWidth
                disableElevation
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button variant="contained" onClick={() => handleSubmit()} fullWidth disableElevation>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default AddOrEditParticipantModal;
