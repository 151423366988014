import React from 'react';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { FormHelperText, MenuItem, Select, styled } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { HeatDTO } from '@/types/heat';
import { HeatStatusKeys, HeatStatusNames } from '@/constants/heats';

const TimePicker = styled(MobileTimePicker)({
  '& .MuiPickersToolbar-penIconButton': { display: 'none' },
});

// const TimePickerTextFieldStart = styled(TextField)({
//   root: {
//     borderColor: 'rgba(0, 0, 0, 0.23)',
//   },
//   input: {
//     textAlign: 'left',
//     paddingLeft: '14px!important',
//     paddingTop: '12px!important',
//     paddingBottom: '12px!important',
//   },
//   '& fieldset': {
//     borderTopRightRadius: '0',
//     borderBottomRightRadius: '0',
//     borderRight: 'none',
//   },
//   '&:hover fieldset': {
//     borderColor: 'rgba(0, 0, 0, 0.23)!important',
//   },
//   '&.Mui-focused fieldset': {
//     borderColor: 'rgba(0, 0, 0, 0.23)',
//   },
// });

// const TimePickerTextFieldEnd = styled(TextField)({
//   root: {
//     borderColor: 'rgba(0, 0, 0, 0.23)',
//   },
//   input: {
//     textAlign: 'left',
//     paddingLeft: '14px!important',
//     paddingTop: '12px!important',
//     paddingBottom: '12px!important',
//   },
//   '& fieldset': {
//     borderBottomLeftRadius: '0',
//     borderTopLeftRadius: '0',
//     borderLeft: 'none',
//   },
//   '&:hover fieldset': {
//     borderColor: 'rgba(0, 0, 0, 0.23)!important',
//   },
//   '&.Mui-focused fieldset': {
//     borderColor: 'rgba(0, 0, 0, 0.23)',
//   },
// });

export const TIME_TYPE = {
  START_TIME: 'startTime',
  END_TIME: 'endTime',
};

const ScoreManageTimeline = ({
  selectedHeat,
  formik,
  saveHeatScore,
  processHeatScore,
}: {
  formik: any;
  selectedHeat: HeatDTO;
  saveHeatScore: (values: any, message?: string) => void;
  processHeatScore: Function;
}) => {
  const timeHandler = (newValue: unknown, timeType: string) => {
    formik.setFieldValue(timeType, newValue || null);
  };
  const applyTimeAndStatusHandler = () => {
    const payload = processHeatScore(formik.values);
    saveHeatScore(payload, 'Applied Time & Status Successfully');
  };

  return (
    <Box sx={{ marginY: '2rem' }}>
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          columnGap={1}
          rowGap={{ xs: '1rem', md: '0' }}
        >
          <Grid item xs={12} md={6} lg={5}>
            <Typography variant="h6">Start and end times</Typography>
            <Grid container direction={'row'}>
              <Grid item xs={6}>
                <TimePicker
                  ampm={false}
                  value={formik.values.startTime || null}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  // mask="__:__"
                  onChange={(newValue) => timeHandler(newValue, TIME_TYPE.START_TIME)}
                  slotProps={{
                    textField: {
                      color: 'secondary',
                      fullWidth: true,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="start">
                            <ArrowForwardIcon />
                          </InputAdornment>
                        ),
                      },
                      error: formik.errors.startTime && formik.touched.startTime ? true : false,
                      helperText:
                        formik.errors.startTime &&
                        formik.touched.startTime &&
                        'Start Time is required',
                      inputProps: { 'data-testid': `${selectedHeat?.heatNo}_heatStartTime` },
                      placeholder: 'Start Time',
                      sx: {
                        root: {
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        input: {
                          textAlign: 'left',
                          paddingLeft: '14px!important',
                          paddingTop: '12px!important',
                          paddingBottom: '12px!important',
                        },
                        '& fieldset': {
                          borderTopRightRadius: '0',
                          borderBottomRightRadius: '0',
                          borderRight: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)!important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                      },
                    },
                  }}
                  // renderInput={({ inputProps, ...params }) => (
                  //   <TimePickerTextFieldStart
                  //     {...params}
                  //     inputProps={{
                  //       ...inputProps,
                  //       'data-testid': `${selectedHeat?.heatNo}_heatStartTime`,
                  //       placeholder: 'Start Time',
                  //     }}
                  //     InputProps={{
                  //       endAdornment: (
                  //         <InputAdornment position="start">
                  //           <ArrowForwardIcon />
                  //         </InputAdornment>
                  //       ),
                  //     }}
                  //     fullWidth
                  //   />
                  // )}
                />
              </Grid>
              <Grid item xs={6}>
                <TimePicker
                  ampm={false}
                  value={formik.values.endTime || null}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  // mask="__:__"
                  onChange={(newValue) => timeHandler(newValue, TIME_TYPE.END_TIME)}
                  // disableOpenPicker
                  slotProps={{
                    textField: {
                      color: 'secondary',
                      fullWidth: true,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="start">
                            <AccessTimeIcon />
                          </InputAdornment>
                        ),
                      },
                      error: formik.errors.endTime && formik.touched.endTime ? true : false,
                      helperText:
                        formik.errors.endTime && formik.touched.endTime && 'End Time is required',
                      inputProps: { 'data-testid': `${selectedHeat?.heatNo}_heatEndTime` },
                      placeholder: 'End Time',
                      sx: {
                        root: {
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        input: {
                          textAlign: 'left',
                          paddingLeft: '14px!important',
                          paddingTop: '12px!important',
                          paddingBottom: '12px!important',
                        },
                        '& fieldset': {
                          borderBottomLeftRadius: '0',
                          borderTopLeftRadius: '0',
                          borderLeft: 'none',
                        },
                        '&:hover fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)!important',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                      },
                    },
                  }}
                  // renderInput={({ inputProps, ...params }) => (
                  //   <TimePickerTextFieldEnd
                  //     {...params}
                  //     color="secondary"
                  //     inputProps={{
                  //       ...inputProps,
                  //       'data-testid': `${selectedHeat?.heatNo}_heatEndTime`,
                  //       placeholder: 'End Time',
                  //     }}
                  //     InputProps={{
                  //       endAdornment: (
                  //         <InputAdornment position="start">
                  //           <AccessTimeIcon />
                  //         </InputAdornment>
                  //       ),
                  //     }}
                  //     fullWidth
                  //   />
                  // )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} lg={4}>
            {/* <FormControl
              error={formik.errors.heatStatus && formik.touched.heatStatus ? true : false}
              fullWidth
            > */}
            <Typography variant="h6">Heat Status</Typography>
            <Select
              id="heatStatus"
              value={formik.values.heatStatus}
              onChange={(e) => formik.setFieldValue('heatStatus', +e.target.value)}
              fullWidth
              sx={{
                '.MuiInputBase-input': {
                  padding: '0.75rem 1rem !important',
                  textAlign: 'left',
                },
              }}
              error={formik.errors.heatStatus && formik.touched.heatStatus ? true : false}
            >
              {Object.entries(HeatStatusKeys)
                .filter(
                  (status) =>
                    status[1] === HeatStatusNames.UPCOMING ||
                    status[1] === HeatStatusNames.LIVE ||
                    status[1] === HeatStatusNames.COMPLETED,
                )
                .map((status) => (
                  <MenuItem value={status[0]} key={`heat_status_opt_${status[0]}`}>
                    {status[1]}
                  </MenuItem>
                ))}
            </Select>
            {formik.errors.heatStatus && formik.touched.heatStatus && (
              <FormHelperText>{'Heat Status is required'}</FormHelperText>
            )}
            {/* </FormControl> */}
          </Grid>
          <Grid item xs={12} md={2} lg={2}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              disableElevation
              onClick={applyTimeAndStatusHandler}
              // sx={{ marginX: { xs: '0', md: '0.5rem' }, height: '100%' }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};

export default ScoreManageTimeline;
