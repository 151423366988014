import Box from '@mui/material/Box';
import { blue } from '@mui/material/colors';
import MaterialTable from '@material-table/core';
import { useSnackbar } from 'notistack';
import { useMemo } from 'react';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import v from 'voca';
import * as xlsx from 'xlsx';
import Papa from 'papaparse';

import { filterHiddenFieldsOfFile } from '@/helpers/excel';

const focusColor = blue[500];

const mimeTypes = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: focusColor,
};

type AllowedHeader = {
  title: string;
  field: string;
};

interface DropzoneProps {
  title: string;
  sheetName?: string;
  sheetData: any[];
  setSheetData: Function;
  allowedHeaders?: AllowedHeader[];
  tableRowChecker?: Function | undefined;
  tableDataModifierFn?: Function | undefined;
  customReadExcel?: Function | undefined;
  dropZoneSize?: string;
}

const MultiSheetDropzone = (props: DropzoneProps) => {
  const {
    title,
    sheetName,
    sheetData,
    setSheetData,
    allowedHeaders,
    tableRowChecker,
    tableDataModifierFn,
    customReadExcel,
    dropZoneSize,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  // const onDragEnterHandler = (event: any) => {
  // };

  const readExcel = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const workbook = xlsx.read(e.target.result, {
        type: 'array',
        cellDates: true,
      });
      const worksheet = workbook.Sheets[sheetName || ''];

      if (!worksheet) {
        enqueueSnackbar(`File does not contain a sheet named "${sheetName}"`);
      }
      const json = xlsx.utils.sheet_to_json(worksheet);

      const filteredJSON = filterHiddenFieldsOfFile(json);

      if (tableRowChecker && tableRowChecker(filteredJSON)) {
        const message = tableRowChecker(filteredJSON);
        enqueueSnackbar(message);
      } else {
        const tableData = tableDataModifierFn
          ? filteredJSON.map((data) => tableDataModifierFn(data))
          : filteredJSON;
        setSheetData(tableData);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // Parse CSV file
  const readCSV = (file: File) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true, // To preserve types for booleans and numbers, else they become string.
      complete: (results: any) => {
        const json = results.data;

        const filteredJSON = filterHiddenFieldsOfFile(json);

        if (tableRowChecker && tableRowChecker(filteredJSON)) {
          const message = tableRowChecker(filteredJSON);
          enqueueSnackbar(message);
        } else {
          const tableData = tableDataModifierFn
            ? filteredJSON.map((data) => tableDataModifierFn(data))
            : filteredJSON;
          setSheetData(tableData);
        }
      },
    });
  };

  const { getRootProps, getInputProps, isFocused, isDragActive } = useDropzone({
    onDrop: (files: File[]) => {
      const [file] = files;
      if (mimeTypes.includes(file.type)) {
        if (customReadExcel) {
          customReadExcel(file);
        } else if (file.type === 'text/csv') {
          readCSV(file);
        } else {
          readExcel(file);
        }
      } else {
        enqueueSnackbar(`Incorrect file type. Please upload an Excel or CSV file`);
      }
    },
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragActive ? focusedStyle : {}),
      ...(dropZoneSize ? { minHeight: dropZoneSize } : {}),
    }),
    [isFocused, isDragActive],
  );

  const keys = Object.keys(sheetData[0] || {});
  const headers = allowedHeaders
    ? allowedHeaders
    : keys
    ? keys?.map((key) => {
        return { title: v.titleCase(key), field: key };
      })
    : [];

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{`Drag 'n' drop the Alt Sports Data upload file, or click to select the file`}</p>
      </div>

      {sheetData.length > 0 ? (
        <Box sx={{ mt: '1rem' }}>
          <MaterialTable
            columns={headers}
            data={JSON.parse(JSON.stringify(sheetData))}
            options={{
              pageSize: 15,
              pageSizeOptions: [15, 25, 50],
            }}
            title={`Imported ${title}`}
          />
        </Box>
      ) : (
        ''
      )}
    </section>
  );
};

export default MultiSheetDropzone;
