export enum RoundStatus {
  LIVE = 1,
  UPCOMING = 2,
  COMPLETED = 3,
  CANCELED = 4,
  POSTPONED = 5,
  NEXT = 6,
  IN_WINDOW = 7,
}

type RoundStatusKeysType = {
  [key: number]: string;
};

export const RoundStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
};

export const RoundStatusKeys: RoundStatusKeysType = {
  1: RoundStatusNames.LIVE,
  2: RoundStatusNames.UPCOMING,
  3: RoundStatusNames.COMPLETED,
  4: RoundStatusNames.CANCELED,
  5: RoundStatusNames.POSTPONED,
  6: RoundStatusNames.NEXT,
  7: RoundStatusNames.IN_WINDOW,
};

type RoundStatusValuesType = {
  [key: string]: number;
};

export const RoundStatusValues: RoundStatusValuesType = {
  [RoundStatusNames.LIVE]: 1,
  [RoundStatusNames.UPCOMING]: 2,
  [RoundStatusNames.COMPLETED]: 3,
  [RoundStatusNames.CANCELED]: 4,
  [RoundStatusNames.POSTPONED]: 5,
  [RoundStatusNames.NEXT]: 6,
  [RoundStatusNames.IN_WINDOW]: 7,
};
