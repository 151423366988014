import SPORT_TYPES from '@/constants/sportTypes';

export const allowedWSLHeaders = [
  { title: 'Round Name', field: 'name' },
  { title: 'Round Number', field: 'roundNo' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Round Status', field: 'roundStatusMod' },
  { title: 'Year', field: 'year' },
  { title: 'Gender', field: 'gender' },
];

export const allowedSLSHeaders = [
  { title: 'Round Name', field: 'name' },
  { title: 'Round Number', field: 'roundNo' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Round Status', field: 'roundStatusMod' },
  { title: 'Year', field: 'year' },
  { title: 'Gender', field: 'gender' },
];
export const allowedNRXHeaders = [
  { title: 'Round Name', field: 'name' },
  { title: 'Round Number', field: 'roundNo' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Round Status', field: 'roundStatusMod' },
  { title: 'Round Type', field: 'roundType' },
  { title: 'Year', field: 'year' },
  { title: 'Gender', field: 'gender' },
];

export const allowedSPRHeaders = [
  { title: 'Round Name', field: 'name' },
  { title: 'Round Number', field: 'roundNo' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Round Status', field: 'roundStatusMod' },
  { title: 'Round Type', field: 'roundType' },
  { title: 'Year', field: 'year' },
  { title: 'Gender', field: 'gender' },
];

export type RoundRow = {
  name: string;
  round_number: number;
  event: string;
  tour_name?: string;
  year: number;
  round_status: number;
  gender: number;
  round_type?: string;
};

export const allowedTableHeaders = {
  [SPORT_TYPES.WSL]: allowedWSLHeaders,
  [SPORT_TYPES.SLS]: allowedSLSHeaders,
  [SPORT_TYPES.NRX]: allowedNRXHeaders,
  [SPORT_TYPES.SPR]: allowedSPRHeaders,
};

export const commonRequiredKeys = [
  'name',
  'round_number',
  'event',
  'year',
  'round_status',
  'gender',
];

export const WSLRequiredKeys = [...commonRequiredKeys, 'stop'];

export const SLSRequiredKeys = [...commonRequiredKeys];

export const NRXRequiredKeys = [...commonRequiredKeys, 'round_type', 'tour_name'];

export const SPRRequiredKeys = [...commonRequiredKeys, 'round_type', 'tour_name'];

export const requiredPayloadKeys = {
  [SPORT_TYPES.WSL]: WSLRequiredKeys,
  [SPORT_TYPES.SLS]: SLSRequiredKeys,
  [SPORT_TYPES.NRX]: NRXRequiredKeys,
  [SPORT_TYPES.SPR]: SPRRequiredKeys,
};
