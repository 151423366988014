import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getAllHeats = async (eventId: string, roundId: string, sport: string) => {
  const {
    data: { data = [] },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/traders/events/${eventId}/rounds/${roundId}/heats`,
  });

  return data;
};

export default function usefetchAllHeats(eventId: string, roundId: string, sport: string) {
  return useQuery(
    [keys.traders.fetchAllHeats, sport, eventId, roundId],
    () => getAllHeats(eventId, roundId, sport),
    {
      staleTime: Infinity,
      refetchInterval: 3 * (60 * 1000),
      refetchIntervalInBackground: true,
      enabled: !!eventId && !!roundId,
    },
  );
}
