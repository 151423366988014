import React from 'react';
import round from 'lodash.round';
import { Column } from '@material-table/core';

import { Box, Typography, TextField } from '@mui/material';

import DecimalField from '@/components/DecimalField';

import * as oddsHelpers from '@/helpers/odds';

import ODDS_TYPES from '@/constants/oddsTypes';
import { fractionPattern } from '@/constants/misc';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';

import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';

import { tableCellStyle, editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import SPORT_TYPES from '@/constants/sportTypes';

export const generateFutureOddsColumns = ({
  oddType,
  eventWinnerAthleteId,
  holdPercentage,
  modifiedFutureOdds,
  sportType,
}: any) => {
  const isTeam = [SPORT_TYPES.MASL].includes(sportType);
  const headers: Column<any>[] = [
    {
      title: !isTeam ? 'Athlete' : 'Team',
      field: 'name',
      editable: 'never',
      render: (rowData: any) => (
        <>
          <Typography component={'span'} sx={{ ...headToHeadTableStyles.athleteNameStyle }}>
            {[rowData?.athlete?.firstName, rowData?.athlete?.middleName, rowData?.athlete?.lastName]
              .filter(Boolean)
              .join(' ')}
          </Typography>
          {rowData.id === eventWinnerAthleteId ? (
            <Box
              component="img"
              alt="winner icon"
              src={Winnerenabledicon}
              sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
            ></Box>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: 'Decimal Odds',
      field: 'decimalOdds',
      hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
      cellStyle: tableCellStyle,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.decimalOdds;
        return newValue < oddRanges.MIN_DECIMAL_ODDS && newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_DECIMAL_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <DecimalField
          value={props.value}
          onBlur={(e: any) => {
            const odds = +e.target.value;
            const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              odds: e.target.value,
              decimalOdds: e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          thousandSeparator={false}
          decimalScale={2}
          sx={editCellTextFieldStyle}
        />
      ),
    },
    {
      title: 'Fractional Odds',
      field: 'fractionalOdds',
      hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
      cellStyle: tableCellStyle,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = row?.fractionalOdds;
        const convertedOdd = oddsHelpers.decimalFromFraction(newValue);
        return !newValue.match(fractionPattern) && newValue !== '0'
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : convertedOdd > oddRanges.MAX_FRACTION_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <TextField
          value={props.value}
          onBlur={(e: any) => {
            const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, e.target.value);
            const probability = oddsHelpers.getProbabilityFromDecimalOdds(odds);
            const fractionalOdds = e.target.value;
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              fractionalOdds: e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
    },
    {
      title: 'American Odds',
      field: 'americanOdds',
      hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
      cellStyle: tableCellStyle,
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      validate: (row: any) => {
        const newValue = +row?.americanOdds;
        return newValue > oddRanges.MAX_NEGATIVE_AMERICAN_ODDS &&
          newValue < oddRanges.MIN_AMERICAN_ODDS &&
          newValue !== 0
          ? {
              isValid: false,
              helperText: validationMessages.ODD_WINNERS_AMERICAN_ODDS_VALIDATION_MSG,
            }
          : newValue < oddRanges.MIN_NEGATIVE_AMERICAN_ODDS ||
            newValue > oddRanges.MAX_AMERICAN_ODDS
          ? {
              isValid: false,
              helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
            }
          : true;
      },
      editComponent: (props: any) => (
        <DecimalField
          value={props.value}
          onBlur={(e: any) => {
            const odds = oddsHelpers.getDecimalOddsFromOtherTypes(oddType, +e.target.value);
            const probability = round(oddsHelpers.getProbabilityFromDecimalOdds(odds), 1);
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();

            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds: odds,
              fractionalOdds,
              americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              americanOdds: +e.target.value > 0 ? `+${e.target.value}` : e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          thousandSeparator={false}
          decimalScale={0}
          allowNegative={true}
          sx={editCellTextFieldStyle}
        />
      ),
    },
    {
      title: 'Probability',
      field: 'probability',
      defaultSort: 'desc',
      customSort: (a: any, b: any) => +a?.probability - +b?.probability,
      align: 'left',
      validate: (row: any) => {
        const totalPercentageModified = oddsHelpers.findTotalPercentage(
          modifiedFutureOdds,
          row?.id,
          {
            modifiedRowsOnly: true,
          },
        );
        const newRemainingTotalProbability = holdPercentage - totalPercentageModified;
        return +row?.probability < 0
          ? 'Enter Probability'
          : +row?.probability > newRemainingTotalProbability ||
            +row?.probability > oddRanges.MAX_PROBABILITY
          ? `Probability can't be more than ${
              newRemainingTotalProbability < oddRanges.MAX_PROBABILITY
                ? round(newRemainingTotalProbability, 2)
                : oddRanges.MAX_PROBABILITY
            }`
          : true;
      },
      render: (rowData: any) => (
        <Typography sx={tableCellStyle}>{round(+rowData?.probability, 2)}</Typography>
      ),
      editComponent: (props: any) => (
        <TextField
          variant="outlined"
          value={round(+props?.value, 2)}
          type="number"
          inputProps={{
            step: '0.01',
          }}
          onBlur={(e) => {
            const probability = +e.target.value;
            const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
            const decimalOdds = odds.toString();
            const fractionalOdds = oddsHelpers.getFractionalOdds(odds).toString();
            const americanOdds =
              oddsHelpers.getAmericanOddsFromProbability(probability) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`;
            props.onRowDataChange({
              ...props.rowData,
              probability,
              odds,
              decimalOdds,
              fractionalOdds,
              americanOdds,
            });
          }}
          onChange={(e: any) => {
            props.onRowDataChange({
              ...props.rowData,
              probability: +e.target.value,
            });
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
          sx={editCellTextFieldStyle}
        />
      ),
    },
  ];

  return headers;
};
