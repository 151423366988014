import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface DELETE_USER {
  userId: string;
}

const deleteUser = async ({ userId }: DELETE_USER) => {
  const { data } = await authenticatedAxios({
    method: 'DELETE',
    url: `admin/users/${userId}`,
  });

  return data;
};

export default function useDeleteUser() {
  return useMutation(({ userId }: DELETE_USER) => deleteUser({ userId }));
}
