import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import v from 'voca';

import { sportsType } from '@/atoms/sportsType';

import Dropzone from '@/components/Dropzone';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';

import useAddTraderEventPoints from '@/hooks/traders/points/useAddTraderEventPoints';

import routes from '@/constants/routes';
import { sportOptions } from '@/constants/sportTypes';
import {
  EventPointsRow,
  allowedTableHeaders,
  requiredPayloadKeys,
} from '@/pages/trader/points/upload/constants';

import { WSLTraderEventPoints } from '@/types/wsl/trader';
import { SLSTraderEventPoints } from '@/types/sls/trader';
import { NRXTraderEventPoints } from '@/types/nrx/trader';

export default function PointsUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const { mutate: addTraderEventPoints } = useAddTraderEventPoints();
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<
    WSLTraderEventPoints[] | SLSTraderEventPoints[] | NRXTraderEventPoints[]
  >([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        addTraderEventPoints(
          {
            sport: selectedSport.toLowerCase(),
            requestPayload: {
              items: modSheet,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Points Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders[selectedSport] || [];
      setAllowedHeaders(headers);
    }
  }, [selectedSport]);

  const handleChange = () => setSheetData([]);

  const tableRowChecker = (rows: EventPointsRow[]) => {
    let message = '';
    const index = rows.findIndex((row: EventPointsRow) => {
      if (
        !row.hasOwnProperty('athlete') ||
        !row.hasOwnProperty('rank') ||
        !row.hasOwnProperty('points') ||
        !row.hasOwnProperty('event') ||
        !row.hasOwnProperty('tour_year')
      )
        return true;
    });

    if (index !== -1) {
      const row = rows[index];
      let missing_columns = '';
      const requiredKeys = requiredPayloadKeys[selectedSport];
      requiredKeys.map((key) => {
        if (!row[key as keyof EventPointsRow]) missing_columns += `${key} `;
        return key;
      });
      missing_columns = missing_columns.split(' ').join(', ').slice(0, -2);
      message = `Error on row { ${index + 2} }, Missing { ${missing_columns} }`;
    }

    return message;
  };

  const tableDataModifierFn = (data: EventPointsRow) => {
    const { athlete, rank, points, event, tour_year } = data;

    const nameSplit = athlete?.trim().split(' ');
    const athleteFirstName = nameSplit?.[0]?.trim();
    const athleteMiddleName = nameSplit.slice(1, -1).join(' ');
    // To avoid having the first name also as the last name
    const athleteLastName = nameSplit.length > 1 ? nameSplit?.[nameSplit.length - 1]?.trim() : '';

    return {
      firstName: athleteFirstName,
      middleName: athleteMiddleName,
      lastName: athleteLastName,
      eventRank: rank,
      eventPoints: points,
      eventName: event,
      eventYear: tour_year,
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.traders.points.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Points
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Points"
        sheetName="Points"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />

      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button variant="contained" disabled={disableBtn} onClick={handleToggleConfirmDialog}>
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} POINTS?`}
          body={'This will upload the points.'}
        />
      )}
    </Box>
  );
}
