import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';

import { sportsType } from '@/atoms/sportsType';
import Dropzone from '@/components/Dropzone';
import useAddRounds from '@/hooks/rounds/useAddRounds';
import { WSLRound } from '@/types/wsl/round';
import { SLSRound } from '@/types/sls/round';
import { NRXRound } from '@/types/nrx/round';
import { SPRRound } from '@/types/spr/round';
import SPORT_TYPES, { sportOptions } from '@/constants/sportTypes';
import { RoundStatusValues } from '@/constants/rounds';
import { processRoundStatusBadges } from '@/helpers/events';
import { RoundRow, requiredPayloadKeys, allowedTableHeaders } from './constants';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';
import routes from '@/constants/routes';

type ROUND = WSLRound | SLSRound | NRXRound | SPRRound;
type ROUNDS = WSLRound[] | SLSRound[] | NRXRound[] | SPRRound[];

export default function RoundsUpload() {
  const [sheetData, setSheetData] = useState<ROUNDS>([]);
  const { mutate: addRounds } = useAddRounds();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const [selectedSport] = useRecoilState(sportsType);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        const modSheetDTO = modSheet.map((data: ROUND) => {
          const roundStatus = RoundStatusValues[data?.roundStatusMod?.toUpperCase() || ''];
          delete data.roundStatusMod;
          const finalData = {
            ...data,
            roundStatus,
          };
          return finalData;
        });
        addRounds(
          {
            sport: selectedSport.toLowerCase(),
            requestPayload: {
              items: modSheetDTO,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Rounds Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders[selectedSport] || [];

      const formattedAllowedHeaders = headers.map((header: any) => {
        if (header.title === 'Round Status') {
          header.render = (rowData: ROUND) =>
            processRoundStatusBadges(
              RoundStatusValues[String(rowData?.roundStatusMod || '').toUpperCase()],
            );
        }

        return header;
      });

      setAllowedHeaders(formattedAllowedHeaders);
    }
  }, [selectedSport]);

  const handleChange = () => {
    setSheetData([]);
  };

  const tableRowChecker = (rows: RoundRow[]) => {
    let message = '';
    const index = rows.findIndex(
      (row: RoundRow) =>
        !row.hasOwnProperty('name') ||
        !row.hasOwnProperty('round_number') ||
        !row.hasOwnProperty('event') ||
        !row.hasOwnProperty('year') ||
        !row.hasOwnProperty('round_status') ||
        !row.hasOwnProperty('gender') ||
        ([SPORT_TYPES.NRX, SPORT_TYPES.SPR].includes(selectedSport) &&
          !row.hasOwnProperty('tour_name')),
    );

    if (index !== -1) {
      const row = rows[index];
      let missing_columns = '';
      const requiredKeys = requiredPayloadKeys[selectedSport];
      requiredKeys.map((key) => {
        if (!row[key as keyof RoundRow]) missing_columns += `${key} `;
        return key;
      });
      missing_columns = missing_columns.split(' ').join(', ').slice(0, -2);
      message = `Error on row { ${index + 2} }, Missing { ${missing_columns} }`;
    }

    return message;
  };

  const tableDataModifierFn = (data: RoundRow) => {
    const { name, round_number, event, year, round_status, gender, round_type, tour_name } = data;

    return {
      name,
      roundNo: round_number,
      eventName: event,
      year,
      roundStatusMod: round_status,
      gender,
      ...([SPORT_TYPES.NRX, SPORT_TYPES.SPR].includes(selectedSport) && {
        roundType: round_type,
        tourName: tour_name,
      }),
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.rounds.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Rounds
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Rounds"
        sheetName="Rounds"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />
      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
          >
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} ROUNDS?`}
          body={'This will upload the rounds.'}
        />
      )}
    </Box>
  );
}
