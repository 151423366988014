import React from 'react';
import clsx from 'clsx';
import { Card, CardActions, Typography } from '@mui/material';
import { SnackbarContent, SnackbarKey, SnackbarMessage } from 'notistack';

interface CustomSnackbarProps {
  snackbarId: SnackbarKey;
  message: SnackbarMessage;
  action?: any;
  sx?: Object;
}

const CustomSnackbar = React.forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  const { snackbarId, message, action, sx = {}, ...other } = props;
  return (
    <SnackbarContent
      ref={ref}
      role="alert"
      {...other}
      className={clsx({
        '@media (min-width:600px)': {
          minWidth: '344px !important',
        },
      })}
    >
      <Card
        sx={{
          width: '100%',
          ...sx,
        }}
      >
        <CardActions sx={{ padding: '8px 8px 8px 16px', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              color: '#ffffff',
              fontWeight: '500',
              fontSize: '0.875rem',
              lineHeight: '1.43',
              letterSpacing: '0.01071em',
            }}
          >
            {message}
          </Typography>
          {action && action(snackbarId)}
        </CardActions>
      </Card>
    </SnackbarContent>
  );
});

CustomSnackbar.displayName = 'CustomSnackbar';

export default CustomSnackbar;
