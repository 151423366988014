import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

const getHeadToHeadPossibleMatchups = async (
  sport: string,
  eventId: string,
): Promise<{ matchupId: string; athleteIds: string[] }[]> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/playerHeadToHead/${sport}/event/${eventId}/headToHead/odds/matchups`,
  });
  return data;
};

export default function useFetchHeadToHeadPossibleMatchups({
  sport,
  eventId,
}: {
  sport: string;
  eventId: string;
}) {
  const headToHeadPossibleMatchupsKey = [keys.h2h.fetchHeadToHeadPossibleMatchups, sport, eventId];

  return useQuery(
    headToHeadPossibleMatchupsKey,
    () => getHeadToHeadPossibleMatchups(sport, eventId),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
