import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';
// import { IEvent } from '@/types/newTypes/event';

const getEventScores = async (sportType: string, eventId: string): Promise<any> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/traders/events/${eventId}/scores`,
  });

  return data;
};

export default function useFetchEventScores(sportType: string, eventId: string) {
  return useQuery(
    [keys.events.fetchEventScores, sportType, eventId],
    () => getEventScores(sportType, eventId),
    {
      staleTime: Infinity,
      //   refetchInterval: 10 * (60 * 1000),
      //   refetchIntervalInBackground: true,
      enabled: !!eventId,
    },
  );
}
