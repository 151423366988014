import * as React from 'react';
import v from 'voca';
// , FormikProps

import { Box, IconButton, Select, MenuItem } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { marketOddModifiers, marketOddsPayload } from '../marketHelper';

import { MASLMarketTypesValue } from '@/constants/masl';

import {
  // Styled Components
  MarketTypeToggleLabel,
  MarketToggleIcon,
  UnLockedIcon,
  LockedIcon,
} from '../components';
import { MASLGameContext } from '@/contexts/maslGame.context';
import DebugLabelDisplay from '../DebugLabelDisplay';
import { BTN_BG_COLOR, FIELD_TO_DEBUG, TEXT_COLOR } from '../constants';

const MIN_HOLD_PERCENTAGE = 100;

// Accordion Header Start

const MarketHeaderContent = ({
  value,
  holdPercentage,
  holdPercentagesOptions,
  oddId,
  setFieldValue,
  toggleIsMarketActive,
  toggleMarketLock,
  bet,
}: {
  value: any;
  holdPercentage: number;
  holdPercentagesOptions: number[];
  oddId: number;
  setFieldValue: any;
  toggleIsMarketActive: Function;
  toggleMarketLock: Function;
  disabledMarkets: { [key: string]: boolean };
  bet: any;
}) => {
  const { isDisabledMatch } = React.useContext(MASLGameContext);
  const isMarketActive = marketOddsPayload({ odds: [value] })?.every((odd) => odd?.isMarketActive);

  const isSubMarketLocked = marketOddsPayload({ odds: [value] })?.every(
    (odd) => odd?.isSubMarketLocked,
  );

  const handleToggleMarketLock = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    toggleMarketLock(isSubMarketLocked);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: '1rem',
        width: '100%',
      }}
    >
      <IconButton
        disableRipple
        onClick={handleToggleMarketLock}
        id={`toggle-${v.kebabCase(
          MASLMarketTypesValue[value?.marketType as keyof {}] || '',
        )}-market-lock`}
        disabled={isDisabledMatch}
        {...{
          [`data-${v.kebabCase(
            MASLMarketTypesValue[value?.marketType as keyof {}] || '',
          )}-market-lock`]: isSubMarketLocked,
        }}
      >
        {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
      </IconButton>
      <Box
        sx={{
          width: { xs: '100%', sm: '40%' },
          display: 'flex',
          alignItems: 'center',
          justifyContent: { xs: 'center', sm: 'flex-start' },
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: TEXT_COLOR.WHITE,
            paddingRight: '0.8rem',
            paddingLeft: '0.8rem',
            paddingTop: '0.4rem',
            paddingBottom: '0.4rem',
            gap: '0.5rem',
            borderRadius: '0.25rem',
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MarketTypeToggleLabel>
            {v.upperCase(MASLMarketTypesValue[value?.marketType as keyof {}] || '')}
          </MarketTypeToggleLabel>
          &nbsp;&nbsp;
          <Box>
            <MarketToggleIcon
              sx={{
                backgroundColor: isMarketActive ? BTN_BG_COLOR.ACTIVE : TEXT_COLOR.WHITE,
                '&:hover': {
                  backgroundColor: isMarketActive ? BTN_BG_COLOR.ACTIVE : TEXT_COLOR.WHITE,
                },
                '&:disabled': {
                  backgroundColor: isMarketActive ? BTN_BG_COLOR.ACTIVE : TEXT_COLOR.WHITE,
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleIsMarketActive(true);
              }}
              id={`activate-main-${v.kebabCase(
                MASLMarketTypesValue[value?.marketType as keyof {}] || '',
              )}-btn`}
              disabled={isDisabledMatch}
              {...{
                [`data-activate-main-${v.kebabCase(
                  MASLMarketTypesValue[value?.marketType as keyof {}] || '',
                )}-btn`]: isMarketActive,
              }}
            >
              <CheckIcon
                sx={{
                  fontSize: '1.2rem',
                  color: isMarketActive ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
                  '&:hover': {
                    color: isMarketActive ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
                  },
                  '&:disabled': {
                    color: isMarketActive ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
                  },
                }}
              />
            </MarketToggleIcon>
            <MarketToggleIcon
              sx={{
                backgroundColor: !isMarketActive ? BTN_BG_COLOR.SUSPENDED : TEXT_COLOR.WHITE,
                '&:hover': {
                  backgroundColor: !isMarketActive ? BTN_BG_COLOR.SUSPENDED : TEXT_COLOR.WHITE,
                },
                '&:disabled': {
                  backgroundColor: !isMarketActive ? BTN_BG_COLOR.SUSPENDED : TEXT_COLOR.WHITE,
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleIsMarketActive(false);
              }}
              id={`suspend-main-${v.kebabCase(
                MASLMarketTypesValue[value?.marketType as keyof {}] || '',
              )}-btn`}
              disabled={isDisabledMatch}
              {...{
                [`data-suspend-main-${v.kebabCase(
                  MASLMarketTypesValue[value?.marketType as keyof {}] || '',
                )}-btn`]: !isMarketActive,
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: '1.2rem',
                  color: !isMarketActive ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
                  '&:hover': {
                    color: !isMarketActive ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
                  },
                  '&:disabled': {
                    color: !isMarketActive ? TEXT_COLOR.WHITE : TEXT_COLOR.GRAY,
                  },
                }}
              />
            </MarketToggleIcon>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '60%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MarketTypeToggleLabel sx={{ fontWeight: 600 }}>HOLD PERCENTAGE:</MarketTypeToggleLabel>
          <DebugLabelDisplay row={bet} showFields={[FIELD_TO_DEBUG.betType]} />
          &nbsp;
          <Select
            id={`select-${v.kebabCase(
              MASLMarketTypesValue[value?.marketType as keyof {}] || '',
            )}-holdPercentage`}
            value={holdPercentage}
            onClick={(event) => {
              event.stopPropagation();
            }}
            onChange={(event) => {
              event.stopPropagation();
              const {
                target: { value: val },
              } = event;
              const newHoldPercentage = Number(val);
              if (newHoldPercentage !== holdPercentage) {
                setFieldValue(
                  `odds[${oddId}]`,
                  marketOddModifiers({
                    marketOdds: value,
                    newHoldPercentage,
                    oldHoldPercentage: holdPercentage,
                    defaultHoldPercentage: MIN_HOLD_PERCENTAGE,
                  }),
                );
              }
            }}
            sx={{ height: 30, backgroundColor: TEXT_COLOR.WHITE }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            disabled={isDisabledMatch || isSubMarketLocked}
          >
            {React.Children.toArray(
              holdPercentagesOptions.map((percent: number) => (
                <MenuItem value={percent}>{percent}</MenuItem>
              )),
            )}
          </Select>{' '}
          &nbsp;%
        </Box>
        <Box>
          <MarketTypeToggleLabel
            id={`${v.kebabCase(
              MASLMarketTypesValue[value?.marketType as keyof {}] || '',
            )}-margin-label`}
            sx={{ fontWeight: 600, color: '#72727E' }}
          >
            MARGIN: {holdPercentage - MIN_HOLD_PERCENTAGE} %
          </MarketTypeToggleLabel>
        </Box>
      </Box>
    </Box>
  );
};

export default MarketHeaderContent;
