import { utils, writeFile, AOA2SheetOpts } from 'xlsx';

// CSV Export
export const csvDownloader = ({
  title,
  headers,
  csvData,
  year,
  sport,
  tour,
  league,
  eventName,
  roundName,
  updatedAt,
}: {
  title: string;
  headers: any[];
  csvData: any[];
  year: string;
  sport: string;
  tour: string;
  league: string;
  eventName: string;
  roundName?: string;
  updatedAt: string;
}) => {
  const yearString = year ? `${year}` : '';
  const sportString = sport ? `_${sport}` : '';
  const tourOrLeague = tour || league ? `_${tour || league}` : '';
  const eventNameString = eventName ? `_${eventName}_` : '';
  const roundNameString = roundName ? `_${roundName}_` : '';
  const titleString = title ? `_${title}` : '';
  const publishTime = updatedAt ? `_${updatedAt}` : '';
  const wb = utils.book_new();
  // creating sheet and adding data from 2nd row of column A.
  // Leaving first row to add Heading
  const ws = utils.json_to_sheet(csvData, { origin: 'A2', skipHeader: true });

  // adding heading to the first row of the created sheet.
  // sheet already have contents from above statement.
  utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

  utils.book_append_sheet(wb, ws, title);

  writeFile(
    wb,
    `${yearString}${sportString}${tourOrLeague}${eventNameString}${roundNameString}${titleString}${publishTime}.csv`,
    {
      bookType: 'csv',
    },
  );
};

// Excel Export
export const excelDownloader = ({
  title,
  headers,
  csvData,
  year,
  sport,
  tour,
  league,
  eventName,
  roundName,
  updatedAt,
}: {
  title: string;
  headers: any[];
  csvData: any[];
  year: string;
  sport: string;
  tour: string;
  league: string;
  eventName: string;
  roundName?: string;
  updatedAt: string;
}) => {
  const yearString = year ? `${year}` : '';
  const sportString = sport ? `_${sport}` : '';
  const tourOrLeague = tour || league ? `_${tour || league}` : '';
  const eventNameString = eventName ? `_${eventName}_` : '';
  const roundNameString = roundName ? `_${roundName}_` : '';
  const titleString = title ? `_${title}` : '';
  const publishTime = updatedAt ? `_${updatedAt}` : '';
  const wb = utils.book_new();

  if (headers.length > 0) {
    // Creating sheet and adding data from 2nd row of column A.
    // Leaving the first row to add Heading
    const ws = utils.json_to_sheet(csvData, { origin: 'A2', skipHeader: true });

    // Adding heading to the first row of the created sheet.
    // Sheet already has contents from the above statement.
    utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

    utils.book_append_sheet(wb, ws, title);

    writeFile(
      wb,
      `${yearString}${sportString}${tourOrLeague}${eventNameString}${roundNameString}${titleString}${publishTime}.xlsx`,
    );
  } else {
    const ws = utils.json_to_sheet(csvData, { origin: 'A1', skipHeader: true });

    utils.book_append_sheet(wb, ws, title);

    writeFile(
      wb,
      `${yearString}${sportString}${tourOrLeague}${eventNameString}${roundNameString}${titleString}${publishTime}.xlsx`,
    );
  }
};

// Future Excel Export
export const futuresExcelDownloader = ({
  title,
  headers,
  csvData,
  year,
  sport,
  name,
  updatedAt,
}: {
  title: string;
  headers: any[];
  csvData: any[];
  year: number;
  sport: string;
  name: string;
  updatedAt: string;
}) => {
  const yearString = year ? `${year}` : '';
  const sportString = sport ? `_${sport}` : '';
  const nameString = name ? `_${name}_` : '';
  const titleString = title ? `_${title}` : '';
  const publishTime = updatedAt ? `_${updatedAt}` : '';
  const wb = utils.book_new();
  if (headers.length > 0) {
    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = utils.json_to_sheet(csvData, { origin: 'A2', skipHeader: true });

    // adding heading to the first row of the created sheet.
    // sheet already have contents from above statement.
    utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

    utils.book_append_sheet(wb, ws, title);

    writeFile(wb, `${yearString}${sportString}${nameString}${titleString}${publishTime}.xlsx`);
  }
  if (headers.length === 0) {
    const ws = utils.json_to_sheet(csvData, { origin: 'A1', skipHeader: true });

    utils.book_append_sheet(wb, ws, title);

    writeFile(wb, `${yearString}${sportString}${nameString}${titleString}${publishTime}.xlsx`);
  }
};

export const excelFileDownloader = ({
  fileName,
  fileType = 'xlsx',
  headers = [],
  rows = [],
  sheetName = '',
}: {
  fileName: string;
  fileType: string;
  headers: string[];
  rows: (string | number)[][];
  sheetName?: string;
}) => {
  const wb = utils.book_new();
  // creating sheet and adding data from 2nd row of column A.
  // Leaving first row to add Heading
  const ws = utils.aoa_to_sheet(
    rows as unknown[][],
    { origin: 'A2', skipHeader: true } as AOA2SheetOpts,
  );

  // adding heading to the first row of the created sheet.
  // sheet already have contents from above statement.
  utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });

  // Ensure that the sheet name does not exceed 31 characters
  const safeSheetName = (sheetName || fileName).substring(0, 31).replace(/[/:?*[\]]/g, '_');

  utils.book_append_sheet(wb, ws, safeSheetName);

  // Ensure that the file name does not exceed 250 characters. Max limit 255 char including file extension
  const safeFileName = fileName.substring(0, 250).replace(/[/:?*[\]]/g, '_');

  writeFile(wb, `${safeFileName}.${fileType}`);
};
