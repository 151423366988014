import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface SELECT_WINNER {
  sport: string;
  eventId: string;
  payload: {
    roundHeatId?: string | null | undefined;
    eventRoundId?: string | null | undefined;
    athleteId?: string;
    position?: number | string | null;
  };
}

const selectWinner = async ({ sport, eventId, payload }: SELECT_WINNER) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/sports/${sport}/events/${eventId}/WinnerAthlete`,
    data: payload,
  });

  return data;
};

export default function useSelectWinner() {
  return useMutation(({ sport, eventId, payload }: SELECT_WINNER) =>
    selectWinner({ sport, eventId, payload }),
  );
}
