import { Manager, Socket } from 'socket.io-client';

import config from '@/config/vars';
import { WEB_SOCKET_EVENT_TYPES } from '@/constants/socket';
import * as firebaseHelpers from '@/hooks/firebase';
import { PLAYER_URL, removeAuthToken, setAuthToken } from '@/constants/localStorageKeys';

interface SocketMap {
  [key: string]: Socket | null;
}

let manager: Manager | null = null;
const socketMap: SocketMap = {};

export const getSocketManager = (url: string, token: string): Manager => {
  if (!manager) {
    manager = new Manager(url, {
      // transports: ['websocket'],
      ...(config.isDevelop ? { reconnectionAttempts: 3 } : {}),
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return manager;
};

export const initiateSocketConnection = (namespace: string, token: string | undefined): void => {
  if (socketMap[namespace]) {
    if (config.isDevelop)
      console.log(`Socket connection to namespace ${namespace} already established.`);
    return;
  }

  if (!token) {
    console.error('User is not authenticated, socket connection not established.');
    return;
  }

  const manager = getSocketManager(config.socketBaseUrl, token);
  const socket = manager.socket(`/${namespace}`);

  socket.on('connect', () => {
    if (config.isDevelop) console.log('Connected to socket with namespace:', namespace);
  });

  socket.on('connect_error', async (err) => {
    console.error('Connection failed:', err?.message);
    if (err?.message === WEB_SOCKET_EVENT_TYPES.AUTHORIZATION_ERROR) {
      await handleAuthError();
    }
  });

  socket.on('error', async (error) => {
    console.error('Error', error);
    if (error?.message === WEB_SOCKET_EVENT_TYPES.AUTHORIZATION_ERROR) {
      await handleAuthError();
    }
  });

  socketMap[namespace] = socket;
};

export const disconnectSocket = (namespace: string): void => {
  const socket = socketMap[namespace];
  if (socket) {
    socket.disconnect();
    if (config.isDevelop) console.log(`Socket disconnected from namespace ${namespace}`);
    socketMap[namespace] = null; // Reset the socket instance
  }
};

export const subscribeToEvent = (
  namespace: string,
  event: string,
  callback: (data: any) => void,
): void => {
  const socket = socketMap[namespace];
  if (!socket) return;
  socket.on(event, (data) => {
    if (config.isDevelop) console.log(`Received event ${event} from ${namespace}:`, data);
    callback(data);
  });
};

// Subscribe to all events in WEB_SOCKET_EVENT_TYPES
export const subscribeToAllEvents = (
  namespace: string,
  callback: (event: string, data: any) => void,
): void => {
  const socket = socketMap[namespace];
  if (!socket) return;

  Object.values(WEB_SOCKET_EVENT_TYPES).forEach((eventType) => {
    socket.on(eventType, (data) => {
      if (config.isDevelop) console.log(`Received event ${eventType} from ${namespace}:`, data);
      callback(eventType, data);
    });
  });
};

export const sendEvent = (
  namespace: string,
  event: string,
  data: any,
  callback?: (response: any) => void,
): void => {
  const socket = socketMap[namespace];
  if (!socket) return;
  if (config.isDevelop) console.log(`Sent event ${event} from ${namespace}:`, data);
  if (callback) {
    socket.emit(event, data, (response: any) => {
      callback(response); // execute the callback if provided
    });
  } else {
    socket.emit(event, data); // normal emit if no callback is provided
  }
};

export const updateSocketAuthToken = (newToken: string): void => {
  if (manager) {
    manager.opts.extraHeaders = { authorization: `Bearer ${newToken}` };
    Object.values(socketMap).forEach((socket) => {
      if (socket) {
        socket.disconnect();
        socket.connect();
      }
    });
    if (config.isDevelop) console.log('Updated socket authentication with new token');
  }
};

// Update hooks/index.ts as well
export const refreshToken = async (): Promise<string | null> => {
  try {
    const user = await firebaseHelpers.fetchCurrentUser();

    const token = await firebaseHelpers.fetchToken(user);
    setAuthToken(token);

    return token;
  } catch (error) {
    localStorage.removeItem(PLAYER_URL);
    removeAuthToken();
    firebaseHelpers.logout();
    window.location.href = window.location.host;
    return null;
  }
};

export const handleAuthError = async () => {
  const newToken = await refreshToken();
  if (newToken) updateSocketAuthToken(newToken as unknown as string);
};
