import React from 'react';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';

import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  autocompleteClasses,
  Box,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import WarningIcon from '@mui/icons-material/Warning';

import * as httpStatusCodes from '@/constants/httpStatus';

import { AddEventHeadToHeadDialogProps, FormValues } from '@/types/playerHeadToHeadTab';
import { MODEL_GENERATED_TEXT_AND_COLOR, MODEL_GENERATED_TYPES } from './constants';

const initialValues: FormValues = {
  eventParticipant1Id: null,
  eventParticipant2Id: null,
};

const AddEventHeadToHeadDialog = (props: AddEventHeadToHeadDialogProps) => {
  const { open, handleClose, playerDropdownOptions, addHeadToHeadOddsData, possibleMatchups } =
    props;
  const {
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
  }: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      eventParticipant1Id: Yup.object().shape({
        eventParticipantId: Yup.string().required(),
        athleteId: Yup.string(),
      }),
      eventParticipant2Id: Yup.object().shape({
        eventParticipantId: Yup.string().required(),
        athleteId: Yup.string(),
      }),
    }),
    onSubmit: async (formValues, { resetForm }) => {
      const payload = {
        eventParticipant1Id: formValues?.eventParticipant1Id?.eventParticipantId || '',
        eventParticipant2Id: formValues?.eventParticipant2Id?.eventParticipantId || '',
      };
      const responseStatus = await addHeadToHeadOddsData(payload);
      if (responseStatus === httpStatusCodes.OK) {
        resetForm();
      }
    },
  });

  const matchupExist = React.useMemo(() => {
    if (values.eventParticipant1Id && values.eventParticipant2Id) {
      if (
        possibleMatchups.get(
          `${values.eventParticipant1Id.athleteId}-${values.eventParticipant2Id.athleteId}`,
        )
      ) {
        return true;
      } else return false;
    }
    return true;
  }, [values]);

  const { color, tooltipText } = MODEL_GENERATED_TEXT_AND_COLOR[MODEL_GENERATED_TYPES.CUSTOM];

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        <Typography sx={{ fontSize: '1.5rem' }}>
          <strong>Add Event Head to Head</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form>
          <Grid container direction="column">
            {!matchupExist && (
              <Grid
                item
                xs={12}
                sx={{
                  marginBottom: '1rem',
                  display: 'flex',
                  gap: '0.2rem',
                  alignItems: 'center',
                  backgroundColor: '#FFF4D3',
                  p: 1.5,
                }}
              >
                <WarningIcon sx={{ color: color, fontSize: 30 }} />
                &nbsp;
                <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.3125rem' }}>
                  {tooltipText}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>Event Participant 1</Typography>
              <Autocomplete
                disableClearable
                value={values.eventParticipant1Id}
                options={
                  values.eventParticipant2Id
                    ? playerDropdownOptions.filter(
                        (data: any) =>
                          data?.eventParticipantId !==
                          values.eventParticipant2Id?.eventParticipantId,
                      )
                    : playerDropdownOptions
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                          padding: '8px',
                        },
                      }}
                      component="li"
                      {...props}
                      key={option.eventParticipantId}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(_: any, newValue: any) => {
                  setFieldValue('eventParticipant1Id', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="eventParticipant1Id"
                    onBlur={handleBlur}
                    helperText={
                      touched.eventParticipant1Id &&
                      errors.eventParticipant1Id &&
                      'Event Participant 1 is Required'
                    }
                    error={errors.eventParticipant1Id ? true : false}
                    InputProps={{
                      ...params.InputProps,
                      // disableUnderline: true,
                      endAdornment: null,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>Event Participant 2</Typography>
              <Autocomplete
                disableClearable
                value={values.eventParticipant2Id}
                options={
                  values.eventParticipant1Id
                    ? playerDropdownOptions.filter(
                        (data: any) =>
                          data?.eventParticipantId !==
                          values.eventParticipant1Id?.eventParticipantId,
                      )
                    : playerDropdownOptions
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                          padding: '8px',
                        },
                      }}
                      component="li"
                      {...props}
                      key={option.eventParticipantId}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(_: any, newValue: any) => {
                  setFieldValue('eventParticipant2Id', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="eventParticipant2Id"
                    onBlur={handleBlur}
                    helperText={
                      touched.eventParticipant2Id &&
                      errors.eventParticipant2Id &&
                      'Event Participant 2 is Required'
                    }
                    error={errors.eventParticipant2Id ? true : false}
                    InputProps={{
                      ...params.InputProps,
                      // disableUnderline: true,
                      endAdornment: null,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} spacing={1} sx={{ marginBottom: '1rem' }}>
          <Grid item sm={4}>
            <Button fullWidth variant="outlined" onClick={() => handleClose()} disableElevation>
              Close
            </Button>
          </Grid>

          <Grid item sm={4}>
            <Button
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              disableElevation
              fullWidth
            >
              Add Head to Head
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventHeadToHeadDialog;
