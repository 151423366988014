import React, { useEffect, useState } from 'react';
import MaterialTable from '@material-table/core';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import v from 'voca';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import calendarImg from '@/images/sports/Calendar.png';

import Filters from './Filters';
// import { EventListingSkeleton } from '@/components/PageSkeletons';
import Head from '@/components/Head';

import useFetchFutures from '@/hooks/futures/useFetchFutures';

import { sortFuturesByStatusAndStartDate, sortFuturesByStartDate } from '@/helpers/futures';
import { displayInPT as format } from '@/helpers/timeConverters';

import { theme } from '@/constants/theme';
import keys from '@/constants/queryKeys';
import routes from '@/constants/routes';
import { tableRowsWithGapsTheme } from '@/constants/theme';
import { EventStatusNames, EventStatusValues } from '@/constants/events';
import { SPORT_LOGOS } from '@/constants/misc';
import SPORT_TYPES from '@/constants/sportTypes';

import { SportTourFutures } from '@/types/futures';

const ListingWrapper = styled(Grid)(
  ({ theme }) => {
    return {
      MuiTypography: {
        root: {
          color: theme.palette.info.main,
          display: 'inline-block',
          fontWeight: '500',
          fontSize: '2rem',
        },
      },
      MuiChip: {
        root: {
          backgroundColor: theme.palette.info.light,
        },
      },
      '& .sport-logo': {
        width: '3.125rem',
        imageRendering: '-webkit-optimize-contrast',
        verticalAlign: 'middle',
      },
    };
  },
  { defaultTheme: tableRowsWithGapsTheme },
);

let allFutures: SportTourFutures[] = [];

export default function FuturesListing() {
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [filteredFutures, setFilteredFutures] = useState<any[]>([]);
  const [futureStatus, setFutureStatus] = useState<number[]>([]);
  const [queryParam, setQueryParam] = useState<string>('');

  const { data: wslFutures, isFetching: wslIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.WSL,
    queryParam,
    futureStatus,
  });
  const { data: slsFutures, isFetching: slsIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.SLS,
    queryParam,
    futureStatus,
  });
  const { data: nrxFutures, isFetching: nrxIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.NRX,
    queryParam,
    futureStatus,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: maslGames, isFetching: maslIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.MASL,
    queryParam,
    futureStatus,
  });
  const { data: fdriftFutures, isFetching: fdriftIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.FDRIFT,
    queryParam,
    futureStatus,
  });
  const { data: motocrsFutures, isFetching: motocrsIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.MOTOCRS,
    queryParam,
    futureStatus,
  });
  const { data: motoGPFutures, isFetching: motoGPIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.MOTOGP,
    queryParam,
    futureStatus,
  });
  const { data: f1Futures, isFetching: f1IsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.F1,
    queryParam,
    futureStatus,
  });
  const { data: mxgpFutures, isFetching: mxgpIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.MXGP,
    queryParam,
    futureStatus,
  });
  const { data: jaialaiFutures, isFetching: jaialaiIsFetching } = useFetchFutures({
    sportType: SPORT_TYPES.JAIALAI,
    queryParam,
    futureStatus,
  });

  // isLoading,
  const refreshList = () => {
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.WSL, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.SLS, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.NRX, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.MASL, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.FDRIFT, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.MOTOCRS, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.MOTOGP, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.F1, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.MXGP, futureStatus]);
    queryClient.invalidateQueries([keys.futures.fetchFutures, SPORT_TYPES.JAIALAI, futureStatus]);
  };

  /* Refetch events when page revisted */
  useEffect(() => {
    return () => refreshList();
  }, []);

  useEffect(() => {
    // Merge different sport events.
    allFutures = [
      ...(wslFutures || []),
      ...(slsFutures || []),
      ...(nrxFutures || []),
      ...(maslGames || []),
      ...(fdriftFutures || []),
      ...(motocrsFutures || []),
      ...(motoGPFutures || []),
      ...(f1Futures || []),
      ...(mxgpFutures || []),
      ...(jaialaiFutures || []),
    ];
    const sortedFutures =
      futureStatus.length > 0
        ? sortFuturesByStartDate(allFutures)
        : sortFuturesByStatusAndStartDate(allFutures);
    setFilteredFutures(sortedFutures);
  }, [
    wslFutures,
    slsFutures,
    nrxFutures,
    maslGames,
    fdriftFutures,
    motocrsFutures,
    motoGPFutures,
    f1Futures,
    mxgpFutures,
    jaialaiFutures,
    futureStatus,
  ]);

  return (
    <Box>
      <Head title={'Altsportsdata - Home'} canonicalUrl={routes.events.home} />
      {/* {isLoading ? (
        <EventListingSkeleton />
      ) : ( */}
      <ListingWrapper>
        <Filters
          allFutures={allFutures}
          setFilteredFutures={setFilteredFutures}
          setFutureStatus={setFutureStatus}
          setQueryParam={setQueryParam}
        />
        <Grid
          container
          justifyContent="space-between"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <Grid item sx={{ display: 'flex', alignItems: 'center' }} className={'listing-head'}>
            {futureStatus?.includes(EventStatusValues[EventStatusNames.COMPLETED as keyof {}]) ? (
              <>
                <Typography variant="h5">{`${format(new Date(), 'MMMM')}`}&nbsp;</Typography>
                <Typography variant="h5">{` ${format(new Date(), ' yyyy')}`}&nbsp;</Typography>
              </>
            ) : (
              <Typography variant="h5">{v.titleCase(EventStatusNames.UPCOMING)}&nbsp;</Typography>
            )}

            <Chip label={`${filteredFutures?.length} events`} size="small" />
          </Grid>
          <Grid item>
            <Tooltip title="Upload Futures">
              <IconButton aria-label="upload" onClick={() => navigate(routes.events.upload)}>
                <UploadFileIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh Futures">
              <IconButton
                disabled={
                  wslIsFetching ||
                  slsIsFetching ||
                  nrxIsFetching ||
                  maslIsFetching ||
                  fdriftIsFetching ||
                  motocrsIsFetching ||
                  motoGPIsFetching ||
                  f1IsFetching ||
                  mxgpIsFetching ||
                  jaialaiIsFetching
                }
                aria-label="refresh"
                onClick={() => refreshList()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <ThemeProvider theme={tableRowsWithGapsTheme}>
          <MaterialTable
            components={{
              Container: (props) => (
                <div style={{ background: 'transparent' }}>{props.children}</div>
              ),
            }}
            columns={[
              { title: 'Id', field: 'id', hidden: true, width: '5%' },
              {
                title: 'Future Type',
                field: 'name',
                cellStyle: {
                  // Custom column size
                  width: 85,
                  textAlign: 'center',
                  paddingRight: 0,
                },
                headerStyle: {
                  // Custom column size
                  width: 85,
                  textAlign: 'center',
                  paddingRight: 0,
                },
                render: (rowData: SportTourFutures) => (
                  <Box
                    component="img"
                    className="sport-logo"
                    src={SPORT_LOGOS[rowData?.sportType || '']}
                    alt="Sport logo"
                  />
                ),
              },
              {
                title: 'Future Name',
                field: 'name',
                cellStyle: {
                  // Custom column size
                  width: 650,
                },
                headerStyle: {
                  // Custom column size
                  width: 650,
                },
                render: (rowData: SportTourFutures) => (
                  <Box>
                    <Typography color="primary" sx={{ fontWeight: '500' }}>
                      {rowData.name}
                    </Typography>
                    {/* {!isSmallAndDown && (
                      <Typography
                        color="info.main"
                        sx={{ display: 'inline-block', fontSize: '0.87rem' }}
                      >
                        {[
                          rowData?.eventLocation,
                          rowData?.tour?.name,
                          rowData?.league?.name,
                          rowData?.eventNumber ? `Stop #${rowData?.eventNumber}` : '',
                        ]
                          .filter(Boolean)
                          .join(' • ')}
                      </Typography>
                    )} */}
                  </Box>
                ),
              },
              {
                title: 'Future Date',
                field: 'startDate',
                hidden: isMediumAndDown,
                cellStyle: {
                  // Custom column size
                  width: 250,
                },
                headerStyle: {
                  // Custom column size
                  width: 250,
                },
                render: (rowData: SportTourFutures) => (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                      component="img"
                      src={calendarImg}
                      alt="calendar"
                      sx={{
                        width: '1rem',
                        imageRendering: '-webkit-optimize-contrast',
                        marginRight: '0.62rem',
                      }}
                    />
                    <Typography
                      color="info.main"
                      sx={{
                        fontWeight: '500',
                        lineHeight: '1',
                        fontSize: '0.87rem',
                        padding: '0.12em 0',
                        height: ' 1.03em',
                      }}
                    >
                      {`${
                        rowData.startDate ? format(parseISO(rowData.startDate), 'MMM dd') : 'TBD'
                      } ${rowData.startDate && rowData.endDate ? '-' : ''} ${
                        rowData.endDate ? format(parseISO(rowData.endDate), 'MMM dd') : 'TBD'
                      }`}
                    </Typography>
                  </Box>
                ),
              },
              {
                title: 'Status',
                field: 'futureStatus',
                cellStyle: {
                  // Custom column size
                  width: 110,
                },
                headerStyle: {
                  // Custom column size
                  width: 110,
                },
                render: (rowData: SportTourFutures) => (
                  <Typography
                    sx={{
                      flex: '1 0 15%',
                      textAlign: 'right',
                      fontSize: '0.625rem',
                      fontWeight: '700',
                      color: rowData.isOpen ? 'success.main' : 'info.dark',
                    }}
                    component="span"
                  >
                    {rowData.isOpen ? '• OPEN' : '• CLOSED'}
                  </Typography>
                ),
              },
            ]}
            data={filteredFutures}
            options={{
              pageSize: 15,
              pageSizeOptions: [15, 25, 50],
              toolbar: false,
              header: false,
              actionsColumnIndex: -1,
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
            actions={
              [
                /* {
                  icon: 'more_horiz',
                  tooltip: `Last Synced ${events?.fetchedTime}`,
                  onClick: () => {
                    //
                  },
                }, */
              ]
            }
            onRowClick={(event, rowData) => {
              event?.stopPropagation();
              navigate(`/${rowData?.sportType}/futures/${rowData?.id}`, { replace: true });
            }}
            title="Futures"
          />
        </ThemeProvider>
      </ListingWrapper>
      {/* )} */}
    </Box>
  );
}
