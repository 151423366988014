import React from 'react';
import { Column } from '@material-table/core';
import { Typography, TextField, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { processAthleteStatusBadges, ATHLETE_STATUS_OPTIONS } from '@/helpers/athletes';
import { tableCellStyle, editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import DecimalField from '@/components/DecimalField';

type EventSeed = {
  id: string;
  seedNo: number;
  status: number;
  athlete: {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    nationality: string;
    stance: string;
  };
  event: {
    id: string;
    eventStatus: number;
    name: string;
  };
  baseRunScore: string;
  baseTrickScore: string;
  trickCompletion: string;
  notes: string;
};

const SLSTableEventSeedsColumns: Column<any>[] = [
  { title: 'Athlete Id', field: 'athlete.id', hidden: true, cellStyle: tableCellStyle },
  {
    title: 'Event Seed',
    field: 'seedNo',
    cellStyle: tableCellStyle,
    customSort: (a: EventSeed, b: EventSeed) => a.seedNo - b.seedNo,
    editable: 'never',
  },
  {
    title: 'Name',
    field: 'name',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>
        {[rowData?.athlete?.firstName, rowData?.athlete?.middleName, rowData?.athlete?.lastName]
          .filter(Boolean)
          .join(' ')}
      </Typography>
    ),
    editable: 'never',
  },
  {
    title: 'Nationality',
    field: 'athlete.nationality',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  { title: 'Stance', field: 'athlete.stance', cellStyle: tableCellStyle, editable: 'never' },
  {
    title: 'Status',
    field: 'status',
    render: (rowData: EventSeed) => processAthleteStatusBadges(rowData?.status),
    editComponent: (props: any) => {
      return (
        <Select
          value={props.value}
          onChange={(event: SelectChangeEvent<typeof props.value>) => {
            const {
              target: { value },
            } = event;
            props.onChange(value);
          }}
          sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
        >
          {ATHLETE_STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    title: 'Base Run',
    field: 'baseRunScore',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{Number(rowData?.baseRunScore || 0).toFixed(2)}</Typography>
    ),
    editComponent: (props: any) => (
      <DecimalField
        value={props.value}
        onBlur={(e: any) => {
          const newVal = +e.target.value;
          props.onChange(newVal);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
        thousandSeparator={false}
        decimalScale={2}
        sx={editCellTextFieldStyle}
      />
    ),
  },
  {
    title: 'Base Trick',
    field: 'baseTrickScore',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{Number(rowData?.baseTrickScore || 0).toFixed(2)}</Typography>
    ),
    editComponent: (props: any) => (
      <DecimalField
        value={props.value}
        onBlur={(e: any) => {
          const newVal = +e.target.value;
          props.onChange(newVal);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
        thousandSeparator={false}
        decimalScale={2}
        sx={editCellTextFieldStyle}
      />
    ),
  },
  {
    title: 'Trick Completion Rate',
    field: 'trickCompletion',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>
        {Number(rowData?.trickCompletion || 0).toFixed(2)}
      </Typography>
    ),
    editComponent: (props: any) => (
      <DecimalField
        value={props.value}
        onBlur={(e: any) => {
          const newVal = +e.target.value;
          props.onChange(newVal);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
        thousandSeparator={false}
        decimalScale={2}
        sx={editCellTextFieldStyle}
      />
    ),
  },
  {
    title: 'Notes',
    field: 'notes',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{rowData?.notes || '-'}</Typography>
    ),
    editComponent: (props: any) => (
      <TextField
        sx={{ ...editCellTextFieldStyle }}
        value={props.value}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
      />
    ),
  },
];

export default SLSTableEventSeedsColumns;
