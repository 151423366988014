import { useQuery } from '@tanstack/react-query';

import { authenticatedAxios } from '..';
import keys from '@/constants/queryKeys';
import SPORT_TYPES from '@/constants/sportTypes';

interface FETCH_GAME_ODDS {
  sportType: string;
  eventId: string;
}

const getGameOdds = async ({ sportType, eventId }: FETCH_GAME_ODDS): Promise<any> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/traders/${eventId}/odds`,
  });

  return data;
};

export default function useFetchGameOdds({ sportType, eventId }: FETCH_GAME_ODDS) {
  return useQuery(
    [keys.odds.fetchGameOdds, sportType, eventId],
    () => getGameOdds({ sportType, eventId }),
    {
      staleTime: Infinity,
      enabled: !!eventId,
      ...(sportType !== SPORT_TYPES.JAIALAI
        ? { refetchInterval: 5 * (60 * 1000), refetchIntervalInBackground: true }
        : {}),
    },
  );
}
