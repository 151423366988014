import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getClientList = async (): Promise<{ id: string; judge: string }[]> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/clients`,
  });

  return data;
};

export default function useFetchClientList() {
  return useQuery([keys.userManagement.fetchClientList], () => getClientList(), {
    staleTime: Infinity,
  });
}
