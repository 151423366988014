import React from 'react';

import { IconButton, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DeleteIcon from '@mui/icons-material/Delete';

import {
  DEFAULT_START_OF_LAP_TIME,
  formatMMSSToSeconds,
  formatSecondsToMMSS,
} from '@/helpers/timeConverters';

import { HeatStatus } from '@/constants/heats';

import { LapTimeField } from '@/components/LapTimeFormat';

import { HeatDTO, HeatScoresDTO } from '@/types/heat';
import { AthleteDTO } from '@/types/athlete';

import { Athlete } from '@/types/athlete';

import { initialAthleteOption } from '@/constants/misc';
import { getUpdatedNHRARanksInScores } from '@/helpers/heats';

const tableColumns = ({
  formik,
  isSmallAndDown,
  isMediumAndDown,
  // selectedHeat,
  heatScores,
  players,
}: {
  formik: any;
  isSmallAndDown: boolean;
  isMediumAndDown: boolean;
  selectedHeat: HeatDTO;
  heatScores: { heat: any; scores: HeatScoresDTO[] };
  players: Athlete[] | AthleteDTO[];
  roundNo: number;
  lapsCountArr: number[];
}) => {
  // Deep clone so that nested properties' refs are not linked to formik values.
  const formValueHeatScores: any[] = JSON.parse(JSON.stringify(formik.values.heatScores));
  return [
    {
      title: '',
      // width: '10%',
      hidden: isMediumAndDown ? false : true,
      component: (idx: number) => (
        <Tooltip key={`${formik.values.heatScores[idx].id}_expand`} title={'Open details'}>
          <IconButton
            color="inherit"
            size="large"
            onClick={(event) => {
              const newIndex = formik.values.detailExpandedRow === idx ? -1 : idx;
              formik.setFieldValue('detailExpandedRow', newIndex);
              event.stopPropagation();
            }}
          >
            {<ExpandMoreIcon />}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      title: 'Seed',
      width: '10%',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        const isSeedDisabled = formik.values.heatScores[idx].toDelete;
        const seedHasErr =
          formik.errors.heatScores &&
          formik.touched.heatScores &&
          formik.errors?.heatScores[idx]?.roundSeed
            ? true
            : false;
        const seedHelperText =
          formik.errors.heatScores && formik.touched.heatScores
            ? formik.touched.heatScores[idx]?.roundSeed &&
              formik.errors.heatScores[idx]?.roundSeed &&
              'Round Seed is required'
            : '';

        const seedOnBlurHandler = (event: React.BaseSyntheticEvent) => {
          formik.handleBlur(event);
          // Deep clone so that nested properties' refs are not linked to formik values.
          const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
          newHeatScores[idx] = {
            ...newHeatScores[idx],
            roundSeed: +event.target.value,
          };
          formik.setFieldValue('heatScores', newHeatScores);
        };
        return (
          <TextField
            key={`${formik.values.heatScores[idx].id}_seed`}
            type="number"
            disabled={isSeedDisabled}
            name={`heatScores[${idx}].roundSeed`}
            defaultValue={formik.values.heatScores[idx].roundSeed}
            fullWidth
            error={seedHasErr}
            helperText={seedHelperText}
            onBlur={seedOnBlurHandler}
          />
        );
      },
    },
    {
      title: 'Athlete',
      width: isMediumAndDown ? (isSmallAndDown ? '80%' : '30%') : '1fr',
      component: (idx: number) => (
        <Autocomplete
          key={`${formik.values.heatScores[idx].id}_athlete`}
          disabled={formik.values.heatScores[idx].toDelete}
          disableClearable
          options={players}
          isOptionEqualToValue={(option: any, value: any) => option.label === value.label}
          renderOption={(props, option) => (
            <Box
              sx={{ fontSize: '0.8rem', paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
              {...props}
            >
              {option.label}
            </Box>
          )}
          value={formik.values.heatScores[idx]?.athlete}
          onChange={(_: React.BaseSyntheticEvent, newValue: any | null) => {
            // Deep clone so that nested properties' refs are not linked to formik values.
            const newHeatScores = JSON.parse(JSON.stringify(formik.values.heatScores));
            newHeatScores[idx] = {
              ...newHeatScores[idx],
              athlete: newValue ? newValue : initialAthleteOption,
            };
            formik.setFieldValue('heatScores', newHeatScores);
          }}
          fullWidth
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: 'calc(50% - 0.7rem)',
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`heatScores[${idx}].athlete`}
              fullWidth
              error={
                formik.errors.heatScores &&
                formik.touched.heatScores &&
                formik.errors?.heatScores[idx]?.athlete.id
                  ? true
                  : false
              }
              helperText={
                formik.errors.heatScores && formik.touched.heatScores
                  ? formik.errors?.heatScores[idx]?.athlete && 'Athlete is required'
                  : ''
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  padding: '0.6rem 0.375rem',
                  paddingRight: '1.25rem !important',
                  textAlign: 'left',
                },
                '& .MuiOutlinedInput-input': {
                  textAlign: 'left',
                },
                '& .MuiIconButton-root': {
                  padding: 0,
                  marginRight: '-0.375rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1rem',
                },
              }}
            />
          )}
        />
      ),
    },
    {
      title: `Lap Time`,
      hidden: isMediumAndDown,
      // width: '9%',
      component: (idx: number) => {
        const formattedLapTime: number = formValueHeatScores[idx].lapTempTime?.lapTime || 0;
        const unformattedLapTime: number = Number(formatMMSSToSeconds(formattedLapTime.toString()));
        return (
          <LapTimeField
            key={`${formValueHeatScores[idx].id}lapTempTime`}
            id={`${formValueHeatScores[idx].id}lapTempTime`}
            name={`heatScores[${idx}].lapTempTime`}
            disabled={formValueHeatScores[idx].toDelete}
            fullWidth
            value={unformattedLapTime === 0 ? '' : formattedLapTime.toString()}
            mask="MM{:}SS{.}MS"
            blocks={{
              MM: {
                mask: '0[0]',
              },
              SS: {
                mask: '0[0]',
              },
              MS: {
                mask: '0[0][0]',
              },
            }}
            definitions={{
              $: /[0-9]/,
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingRight: 0,
              },
              '& .MuiInputAdornment-root': {
                padding: '0px',
                marginLeft: 0,
              },
              '&.MuiTextField-root': {
                backgroundColor:
                  heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
                  (unformattedLapTime && unformattedLapTime > 0)
                    ? '#E9ECEF'
                    : '',
              },
            }}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              const newVal = event.target.value;
              const unformattedNewLapTime: number | string = Number(formatMMSSToSeconds(newVal));
              if (unformattedNewLapTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;

              if (unformattedNewLapTime !== unformattedLapTime) {
                formValueHeatScores[idx].lapTime = unformattedNewLapTime;

                if (unformattedNewLapTime >= 0) {
                  formValueHeatScores[idx].lapTempTime = {
                    ...formValueHeatScores[idx].lapTempTime,
                    lapTime: formatSecondsToMMSS(String(unformattedNewLapTime)),
                  };
                  formik.setFieldValue(
                    'heatScores',
                    getUpdatedNHRARanksInScores(formValueHeatScores),
                  );
                } else {
                  formik.setFieldValue('heatScores', formValueHeatScores);
                }
              }
            }}
          />
        );
      },
    },

    // {
    //   title: roundNo === FIRST_ROUND_NO ? 'Best Lap Time' : 'Total Time',
    //   field: 'totalTime',
    //   width: isMediumAndDown ? '1fr' : '0.8fr',
    //   hidden: isSmallAndDown,
    //   component: (idx: number) => {
    //     const formattedTotalTime =
    //       formik.values.heatScores[idx].totalTime || DEFAULT_START_OF_LAP_TIME;
    //     const unformattedTotalTime: number | string = formatMMSSToSeconds(`${formattedTotalTime}`);
    //     return (
    //       <TextField
    //         key={`${formik.values.heatScores[idx].id}_best_total`}
    //         disabled={formik.values.heatScores[idx].toDelete}
    //         name={`heatScores[${idx}].totalTime`}
    //         value={unformattedTotalTime === 0 ? '' : formattedTotalTime}
    //         fullWidth
    //         InputProps={{
    //           readOnly: true,
    //         }}
    //         sx={
    //           heatScores?.heat?.heatStatus === HeatStatus.COMPLETED || unformattedTotalTime > 0
    //             ? { input: { backgroundColor: '#E9ECEF' } }
    //             : undefined
    //         }
    //       />
    //     );
    //   },
    // },
    {
      title: 'Place',
      field: 'heatPosition',
      width: isMediumAndDown ? '1fr' : '0.1fr',
      hidden: isSmallAndDown,
      component: (idx: number) => {
        return (
          <TextField
            key={`${formik.values.heatScores[idx].id}_place`}
            disabled={formik.values.heatScores[idx].toDelete}
            name={`heatScores[${idx}].heatPosition`}
            value={
              formik.values.heatScores[idx].heatPosition === 0
                ? ''
                : formik.values.heatScores[idx].heatPosition
            }
            fullWidth
            inputProps={{ readOnly: true }}
            sx={
              heatScores?.heat?.heatStatus === HeatStatus.COMPLETED ||
              formik.values.heatScores[idx].heatPosition > 0
                ? { input: { backgroundColor: '#E9ECEF' } }
                : undefined
            }
          />
        );
      },
    },
    // {
    //   title: 'Action',
    //   width: isMediumAndDown ? '1fr' : '0.1fr',
    //   hidden: isSmallAndDown,
    //   component: (idx: number) => (
    //     <Tooltip
    //       key={`${formik.values.heatScores[idx].id}_action`}
    //       title="Delete Row"
    //       placement="top"
    //       arrow
    //     >
    //       {/* Tooltip needs a child that isn't disabled, hence fragment */}
    //       <>
    //         <IconButton
    //           disabled={selectedHeat?.heatStatus === HeatStatus.COMPLETED}
    //           color={formik.values.heatScores[idx].toDelete ? 'warning' : 'default'}
    //           onClick={() => {
    //             // Deep clone so that nested properties' refs are not linked to formik values.
    //             const scrs = JSON.parse(JSON.stringify(formik.values.heatScores));
    //             scrs[idx].toDelete = !scrs[idx].toDelete;
    //             formik.setFieldValue(
    //               'heatScores',
    //               getUpdatedNHRARanksInScores(scrs, lapsCountArr, roundNo, FIRST_ROUND_NO),
    //             );
    //           }}
    //         >
    //           <DeleteIcon
    //             sx={{
    //               fontSize: '1.65rem',
    //               padding: '0.2rem',
    //             }}
    //           />
    //         </IconButton>
    //       </>
    //     </Tooltip>
    //   ),
    // },
  ];
};

export default tableColumns;
