import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { EventWinnerOddsDTO, SecondPlaceOddsDTO } from '@/types/odds/getOdds';

interface FETCH_POSITIONAL_ODDS {
  sport: string;
  eventId: string;
  position: number;
}

const getPositionalOdds = async ({
  sport,
  eventId,
  position,
}: FETCH_POSITIONAL_ODDS): Promise<EventWinnerOddsDTO | SecondPlaceOddsDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/odds`,
    params: { position },
  });

  return data;
};

export default function useFetchPositionalOdds({
  sport,
  eventId,
  position,
}: FETCH_POSITIONAL_ODDS) {
  return useQuery(
    [keys.odds.fetchPositionalOdds, sport, eventId, { position }],
    () => getPositionalOdds({ sport, eventId, position }),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
