import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { zonedTimeToUtc } from 'date-fns-tz';

import { invalidateCachedHeat } from '@/helpers/cachedQueries';
import {
  addFullnameLabelAndDeleteProps,
  getNewRow,
  getUpdatedNHRARanksInScores,
} from '@/helpers/heats';
import {
  displayInPT,
  formatMMSSToSeconds,
  modifyMinutesAndSeconds,
} from '@/helpers/timeConverters';

import SPORT_TYPES from '@/constants/sportTypes';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';

import { HeatDTO, HeatScoresDTO } from '@/types/heat';
import { AthleteDTO } from '@/types/athlete';

import tableColumns from './ScoreManageColumns';

import {
  ScoreManageHeader,
  ScoreManageTimeline,
  ScoreManageFooter,
  ScoreManageBody,
} from '@/features/scores/ScoreManage';
import orderBy from 'lodash.orderby';
import { DEFAULT_TIMEZONE } from '@/constants/misc';

interface HeatCardProps {
  // hidden: boolean;
  heatScores: { heat: any; scores: HeatScoresDTO[] };
  prevHeat: HeatDTO | null;
  nextHeat: HeatDTO | null;
  selectedHeat: HeatDTO;
  players: AthleteDTO[];
  lapsCountArr: number[];
  handleCurrentHeat: (nextHeat: HeatDTO) => void;
  saveHeatScore: (values: any, message?: string) => void;
  endHeat: (values: any) => void;
  roundNo: number;
  isFetchingHeatScore: boolean;
}

const ScoresWrapper = styled(Box)(({ theme }) => {
  return {
    '& .times-wrapper': {
      marginBottom: '2rem',
    },
    '& .display-flex': {
      display: 'flex',
    },
    '& .justify-center': {
      justifyContent: 'center',
    },
    '& .align-center': {
      alignItems: 'center',
    },
    '& .time-text': {
      root: {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      input: {
        textAlign: 'left',
        paddingLeft: '14px!important',
        paddingTop: '12px!important',
        paddingBottom: '12px!important',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)!important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .start-time': {
      '& fieldset': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderRight: 'none',
      },
    },
    '& .end-time': {
      '& fieldset': {
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '0',
        borderLeft: 'none',
      },
    },
    '& .time-apply-btn': {
      [theme.breakpoints.up('xs')]: {
        marginLeft: '0',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '0.5rem',
      },
      height: '100%',
    },
    '& .custom-table': {
      display: 'grid',
      borderCollapse: 'collapse',
      minWidth: '100%',
      gap: '0.15rem',
      'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: '0',
      },
    },
    '& .collapse': {
      display: 'grid',
      borderCollapse: 'collapse',
      minWidth: '100%',
      gridTemplateColumns: '1fr 1fr',
      gap: 1,
      [theme.breakpoints.up('md')]: {
        padding: '2rem 6rem',
      },
      [theme.breakpoints.up('xs')]: {
        padding: '2rem 1rem',
      },
    },
    '& .chevron-icon': {
      border: '2px solid #EEF0F2',
      borderRadius: '50%',
      fontSize: 40,
      padding: '0.4rem',
    },
  };
});

const sportType = SPORT_TYPES.NHRA;
// const FIRST_ROUND_NO = 1;

const HeatManage = (props: HeatCardProps) => {
  const theme = useTheme();
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const {
    heatScores,
    players,
    selectedHeat,
    prevHeat,
    nextHeat,
    handleCurrentHeat,
    lapsCountArr,
    saveHeatScore,
    endHeat,
    roundNo,
    isFetchingHeatScore,
  } = props;

  React.useEffect(() => {
    invalidateCachedHeat(queryClient, sportType, selectedHeat.id);
  }, []);

  /* Adding ranks & sorting heat scores by "status" and "roundSeed" (without "DSQs"), followed by "DSQs" */
  const initialSortedHeatScores = React.useMemo(() => {
    const rankedHeatScores = getUpdatedNHRARanksInScores(heatScores.scores);

    const sortedHeatScores = [...orderBy(rankedHeatScores, ['roundSeed'], ['asc'])];
    return addFullnameLabelAndDeleteProps(sortedHeatScores);
  }, [heatScores.scores]);

  const processHeatScore = (finalValues: any) => {
    const scores = JSON.parse(JSON.stringify(finalValues.heatScores));
    const validScores = scores.filter((score: any) => score.athlete !== undefined);

    // score data mapping
    const scoresData = validScores.map((rowData: any) => {
      const athlete = players.find((athlete: any) => {
        return athlete.id === rowData.athlete.id;
      });
      // Payload athlete structure:
      // Delete row - id only
      // Update row - id, seed, ...
      // New row (no id) - seed, ...

      return {
        ...(rowData.lapTempTime?.storedId && {
          id: rowData.lapTempTime?.storedId,
        }),
        ...(!rowData.toDelete && {
          id: rowData.id,
          seed: rowData.roundSeed,
          athleteId: athlete?.id,
          lapTime: formatMMSSToSeconds(rowData.lapTempTime?.lapTime),
          heatPosition: rowData.heatPosition,
        }),
      };
    });

    const payload = {
      heatStatus: finalValues.heatStatus,
      startDate: zonedTimeToUtc(
        modifyMinutesAndSeconds(selectedHeat?.startTime || '', finalValues.startTime),
        DEFAULT_TIMEZONE,
      ),
      endDate: zonedTimeToUtc(
        modifyMinutesAndSeconds(selectedHeat?.endTime || '', finalValues.endTime),
        DEFAULT_TIMEZONE,
      ),
      athletes: scoresData.flat(),
    };
    return payload;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startTime: heatScores?.heat?.startDate
        ? displayInPT(heatScores?.heat?.startDate, undefined, true)
        : null,
      endTime: heatScores?.heat?.endDate
        ? displayInPT(heatScores?.heat?.endDate, undefined, true)
        : null,
      heatStatus: +heatScores.heat?.heatStatus,
      heatScores: initialSortedHeatScores,
      detailExpandedRow: -1,
    },
    validationSchema: Yup.object().shape({
      heatScores: Yup.array().of(
        Yup.object().shape({
          toDelete: Yup.boolean().required(),
          athlete: Yup.object().when('toDelete', {
            is: false,
            then: (s) =>
              s.shape({
                id: Yup.string().nullable().defined().required(),
              }),
          }),
        }),
      ),
    }),
    onSubmit: (formValues) => {
      const payload = processHeatScore(formValues);
      saveHeatScore(payload);
    },
  });

  const { values, setFieldValue } = formik;

  // Get the lowest/best lap time among all the laps in a row.
  // const getBestLapTime = (rowData: any) => {
  //   let lowestLapTime = 0;
  //   lapsCountArr.forEach((key) => {
  //     const lapNumberKey = `lap_${key}`;
  //     const unformattedLapTime: number = formatMMSSToSeconds(rowData[lapNumberKey].lapTime);

  //     if ((unformattedLapTime < lowestLapTime && unformattedLapTime !== 0) || lowestLapTime === 0)
  //       lowestLapTime = unformattedLapTime;
  //   });
  //   return formatSecondsToMMSS(lowestLapTime.toString());
  // };

  // Add a new row to the table that persists after clicking "Save".
  const addNewRow = () => {
    const lastSeed = values.heatScores[values.heatScores.length - 1]?.roundSeed || 0;
    const newRow = getNewRow(sportType, lastSeed, lapsCountArr);
    const updatedRows = values.heatScores.slice();
    updatedRows.push(newRow);
    setFieldValue(`heatScores`, updatedRows);
  };

  const endHeatHandler = (finalValues: any) => {
    const athleteData = processHeatScore(finalValues);
    endHeat(athleteData);
  };

  const columns = tableColumns({
    formik,
    isSmallAndDown,
    isMediumAndDown,
    selectedHeat,
    heatScores,
    players,
    roundNo,
    lapsCountArr,
  });

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  return (
    <Box
      // hidden={hidden}
      sx={{ paddingY: '1rem' }}
    >
      <Box sx={{ display: 'block' }}>
        <ScoreManageHeader selectedHeat={selectedHeat} />
        <form>
          <ScoresWrapper>
            {values.heatScores.length > 0 && (
              <>
                <ScoreManageTimeline
                  selectedHeat={selectedHeat}
                  formik={formik}
                  saveHeatScore={saveHeatScore}
                  processHeatScore={processHeatScore}
                />
                <ScoreManageBody formik={formik} columns={columns} />
              </>
            )}
            <ScoreManageFooter
              {...{
                formik,
                selectedHeat,
                handleCurrentHeat,
                prevHeat,
                nextHeat,
                finalHeatScores: initialSortedHeatScores,
                handleToggleConfirmDialog,
                addNewRow,
                isFetchingHeatScore,
              }}
            />
          </ScoresWrapper>
        </form>
      </Box>
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            endHeatHandler(values);
          }}
          title={'ARE YOU SURE YOU WANT TO END THIS HEAT?'}
          body={'This will set the scores and End Time. You can come back to make edits if needed.'}
          formik={formik}
          hasEndTimeHandler={true}
        />
      )}
    </Box>
  );
};

export default HeatManage;
