import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { HeadToHeadOddsDTO } from '@/types/odds/getOdds';
import { HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS, filterObj } from '@/constants/misc';

const getHeadToHeadOdds = async (
  sport: string,
  eventId: string,
  params?: { [key: string]: number | string },
): Promise<HeadToHeadOddsDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/headToHead/odds${
      HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport) ? '/page' : ''
    }`,
    params: params ? params : {},
  });
  return data;
};

export default function useFetchHeadToHeadOdds({
  sport,
  eventId,
  params,
}: {
  sport: string;
  eventId: string;
  params?: { query: string; page: number; sortColumn: string; sortOrder: string };
}) {
  const headToHeadOddsKey = [keys.odds.fetchHeadToHeadOdds, sport, eventId];

  const filteredParams: { [key: string]: number | string } | undefined = params
    ? filterObj(params)
    : undefined;
  if (params) {
    headToHeadOddsKey.push(JSON.stringify(filteredParams));
  }

  return useQuery(headToHeadOddsKey, () => getHeadToHeadOdds(sport, eventId, filteredParams), {
    staleTime: Infinity,
    enabled: !!eventId,
  });
}
