import React from 'react';
import { Helmet } from 'react-helmet';
import config from '@/config/vars';

const siteUrl = `https://${config.host}`;

const Head = ({
  title = 'Alt Sports Data Admin',
  description = 'Alt Sports Data Admin',
  canonicalUrl = siteUrl,
}) => {
  const finalUrl = canonicalUrl === siteUrl ? siteUrl : `${siteUrl}${canonicalUrl}`;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`${finalUrl}`} />
    </Helmet>
  );
};

export default Head;
