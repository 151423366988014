const keys = {
  user: {
    fetchProfile: 'fetchProfile',
  },
  sports: {
    fetchSports: 'fetchSports',
  },
  posts: {
    fetchAll: 'fetchAll',
  },
  events: {
    fetchEvent: 'fetchEvent',
    fetchEventScores: 'fetchEventScores',
    fetchEvents: 'fetchEvents',
    fetchTours: 'fetchTours',
    fetchEventLocationGroup: 'fetchEventLocationGroup',
    fetchWslEvents: 'fetchWslEvents',
    fetchSlsEvents: 'fetchSlsEvents',
    fetchNrxEvents: 'fetchNrxEvents',
    fetchMaslEvents: 'fetchMaslEvents',
    fetchMotocrsEvents: 'fetchMotocrsEvents',
    fetchFdriftEvents: 'fetchFdriftEvents',
    fetchMotoGPEvents: 'fetchMotoGPEvents',
    fetchF1Events: 'fetchF1Events',
    fetchMxgpEvents: 'fetchMxgpEvents',
  },
  futures: {
    sports: {
      fetchSportsFutures: 'fetchSportsFutures',
    },
    fetchSportTourFutures: 'fetchSportTourFutures',
    fetchFutureOdds: 'fetchFutureOdds',
    fetchFutures: 'fetchFutures',
  },
  games: {
    fetchGame: 'fetchGame',
    fetchGameScores: 'fetchGameScores',
    fetchGameHeatScores: 'fetchGameHeatScores',
    fetchH2HFixtures: 'fetchH2HFixtures',
  },
  athletes: {
    fetchParticipants: 'fetchParticipants',
  },
  h2h: {
    fetchHeadToHeadPossibleMatchups: 'fetchHeadToHeadPossibleMatchups',
  },
  odds: {
    fetchPositionalOdds: 'fetchPositionalOdds',
    fetchHeatWinnerOdds: 'fetchHeatWinnerOdds',
    fetchHeadToHeadOdds: 'fetchHeadToHeadOdds',
    fetchPropBetOdds: 'fetchPropBetOdds',
    fetchShowOdds: 'fetchShowOdds',
    fetchPodiumOdds: 'fetchPodiumOdds',
    fetchDreamTeamOdds: 'fetchDreamTeamOdds',
    exactas: {
      fetchExactasEventOdds: 'fetchExactasEventOdds',
      fetchExactasHeatOdds: 'fetchExactasHeatOdds',
    },
    fetchGameOdds: 'fetchGameOdds',
  },
  traders: {
    fetchHeat: 'fetchHeat',
    fetchAllHeats: 'fetchAllHeats',
  },
  wsl: {
    athletes: {
      fetchWSLAthletes: 'fetchWSLAthletes',
    },
    events: {
      fetchWSLEvents: 'fetchWSLEvents',
    },
  },
  sls: {
    athletes: {
      fetchAllSLSAthletes: 'fetchAllSLSAthletes',
    },
    events: {
      fetchSLSEvents: 'fetchSLSEvents',
    },
  },
  fightSportMisc: {
    fetchAthletes: 'fetchAthletes',
    fetchJudges: 'fetchJudges',
    fetchReferees: 'fetchReferees',
    fetchWeightClasses: 'fetchWeightClasses',
  },
  fights: {
    fetchFightParticipants: 'fetchFightParticipants',
  },
  results: {
    fetchMatchResults: 'fetchMatchResults',
    fetchRoundResults: 'fetchRoundResults',
  },
  misc: {
    fetchSystemHealth: 'fetchSystemHealth',
  },
  userManagement: {
    fetchUserList: 'fetchUserList',
    fetchClientList: 'fetchClientList',
  },
};

export default keys;
