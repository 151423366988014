import { useEffect, useState } from 'react';
import { Typography, CircularProgress, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { green, red, grey } from '@mui/material/colors';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

import { displayInPT as format } from '@/helpers/timeConverters';

const DISABLED_TEXT_COLOR = grey[500];
const ENABLED_TEXT_COLOR = '#000';

const PublishOdds = ({
  clientUpdatedAtDate,
  traderUpdatedAtDate,
  traderUpdatedAtDates,
  handlePublishOdds,
  isVoided = false,
  disablePublish = false,
}: {
  clientUpdatedAtDate: any;
  traderUpdatedAtDate: any;
  traderUpdatedAtDates?: any;

  handlePublishOdds: () => void;
  isVoided?: boolean;
  disablePublish?: boolean;
}) => {
  const theme = useTheme();

  const _traderUpdatedAtDate = traderUpdatedAtDate ? new Date(traderUpdatedAtDate).getTime() : 0;
  const _clientUpdatedAtDate = clientUpdatedAtDate ? new Date(clientUpdatedAtDate).getTime() : 0;

  const isOddsPublished = _traderUpdatedAtDate > _clientUpdatedAtDate ? true : false;
  const [loading, setLoading] = useState(false);
  const [oddsOutOfSync, setOddsOutOfSync] = useState(
    isVoided || !isOddsPublished
      ? false
      : _traderUpdatedAtDate > _clientUpdatedAtDate ||
          _traderUpdatedAtDate === 0 ||
          _clientUpdatedAtDate === 0,
  );

  useEffect(() => {
    const updatedOddsOutOfSync =
      isVoided || !isOddsPublished
        ? false
        : _traderUpdatedAtDate > _clientUpdatedAtDate ||
          _traderUpdatedAtDate === 0 ||
          _clientUpdatedAtDate === 0;
    setOddsOutOfSync(updatedOddsOutOfSync);
  }, [clientUpdatedAtDate, traderUpdatedAtDate, isVoided]);

  const handleClick = async () => {
    setLoading(true);

    try {
      await handlePublishOdds();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setOddsOutOfSync(false);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container alignItems="center" sx={{ marginTop: '0.7rem' }}>
      <Typography
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '7px',
          color: disablePublish ? DISABLED_TEXT_COLOR : ENABLED_TEXT_COLOR,
        }}
      >
        {loading ? (
          <>
            <PendingOutlinedIcon
              sx={{
                color: disablePublish ? DISABLED_TEXT_COLOR : theme.palette.primary.main,
                marginRight: '0.2rem',
              }}
            />
            <Typography
              component="span"
              sx={{
                marginTop: '2px',
                marginLeft: '2px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                color: disablePublish ? DISABLED_TEXT_COLOR : ENABLED_TEXT_COLOR,
              }}
            >
              Recent updates have not been published.
            </Typography>
          </>
        ) : oddsOutOfSync ? (
          <>
            <ErrorOutlineOutlinedIcon
              sx={{ color: disablePublish ? DISABLED_TEXT_COLOR : red[500], marginRight: '0.2rem' }}
            />
            <Typography
              component="span"
              sx={{
                marginTop: '2px',
                marginLeft: '2px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                color: disablePublish ? DISABLED_TEXT_COLOR : ENABLED_TEXT_COLOR,
              }}
            >
              Recent updates have not been published.
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleOutlineRoundedIcon
              sx={{
                color: disablePublish ? DISABLED_TEXT_COLOR : green[700],
                marginRight: '0.2rem',
              }}
            />
            <Typography
              component="span"
              sx={{
                marginTop: '2px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
                color: disablePublish ? DISABLED_TEXT_COLOR : ENABLED_TEXT_COLOR,
              }}
            >
              All changes are synchronized.
            </Typography>
          </>
        )}
      </Typography>
      <Grid item>
        {traderUpdatedAtDates && (
          <Typography variant="caption">
            <span>Last Published:</span>&nbsp;
            {`${
              traderUpdatedAtDate
                ? format(new Date(traderUpdatedAtDate), 'MM-dd-yyyy hh:mm:ss a')
                : ''
            } PST`}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Button
          id="publishBtn"
          sx={{
            display: 'flex',
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          }}
          disabled={!oddsOutOfSync || loading || disablePublish}
          onClick={handleClick}
        >
          <Typography
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '0.875rem',
              fontWeight: 500,
              color: disablePublish
                ? DISABLED_TEXT_COLOR
                : oddsOutOfSync
                ? theme.palette.primary.main
                : 'info.main',
              textDecoration: oddsOutOfSync ? 'underline' : 'none',
            }}
          >
            {loading ? (
              <>
                <CircularProgress
                  sx={{
                    color: disablePublish ? DISABLED_TEXT_COLOR : 'inherit',
                    marginRight: '0.2rem',
                  }}
                  size={20}
                />
                <Typography
                  component="span"
                  sx={{
                    marginTop: '2px',
                    marginLeft: '5px',
                    color: disablePublish ? DISABLED_TEXT_COLOR : theme.palette.primary.main,
                  }}
                >
                  Publishing...
                </Typography>
              </>
            ) : oddsOutOfSync ? (
              <>
                <PublishedWithChangesOutlinedIcon
                  sx={{
                    color: disablePublish ? DISABLED_TEXT_COLOR : theme.palette.primary.main,
                    marginRight: '0.2rem',
                  }}
                />
                <Typography
                  component="span"
                  sx={{
                    marginTop: '2px',
                    marginLeft: '2px',
                    color: disablePublish ? DISABLED_TEXT_COLOR : theme.palette.primary.main,
                  }}
                >
                  Publish now
                </Typography>
              </>
            ) : (
              <>{''}</>
            )}
          </Typography>
        </Button>
      </Grid>
      <Grid container xs={12} item>
        {traderUpdatedAtDates && (
          <Typography
            sx={{
              fontSize: '0.9rem',
              color: disablePublish ? DISABLED_TEXT_COLOR : ENABLED_TEXT_COLOR,
            }}
          >
            <strong>
              <span>Last Updated:</span>
            </strong>
            &nbsp;
            {`${
              traderUpdatedAtDate
                ? format(new Date(traderUpdatedAtDate), 'MM-dd-yyyy hh:mm:ss a')
                : ''
            } PST`}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PublishOdds;
