import React, { useRef, useState, useMemo } from 'react';

// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
// import SearchIcon from '@mui/icons-material/Search';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import Badge from '@mui/material/Badge';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import InputAdornment from '@mui/material/InputAdornment';
// import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

import { useNavigate } from 'react-router-dom';

import {
  API_PROVIDER_HEALTH_ICONS,
  SERVER_HEALTH_ICONS,
  SERVER_HEALTH_STATUS_LABELS,
  drawerWidth,
} from '@/constants/misc';
import routes from '@/constants/routes';
import PlayLiveStream from '@/components/PlayLiveStream';
import { PLAYER_URL, removeAuthToken } from '@/constants/localStorageKeys';
import Tooltip from '@mui/material/Tooltip';

import useWidth from '@/hooks/mui/useWidth';
import useFetchProfile from '@/hooks/user/useFetchProfile';
import useFetchSystemHealth from '@/hooks/misc/useFetchSystemHealth';

import config from '@/config/vars';
import { formattedPhoneText } from '@/helpers/phoneNumber';
import { logout } from '@/hooks/firebase';
import { ROLE_OPTIONS, ROLES } from '@/constants/user';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  toggleDrawer?: () => void;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarText = styled(Typography)<{ component?: string }>({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  color: '#1E2123',
});

const AppBar = ({ open, toggleDrawer }: AppBarProps) => {
  const navigate = useNavigate();
  const liveYTButton = useRef();
  const { data: profile = {} } = useFetchProfile();
  const { data: systemHealth = {} } = useFetchSystemHealth();
  const [openLiveStream, setOpenLiveStream] = useState<boolean>(false);
  const [showYTStream, setShowYTStream] = useState<boolean>(false);
  const width = useWidth();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if ((config.isProd || config.isStaging) && profile) {
      Sentry.setUser({
        userId: profile?.id,
        userType: profile?.userType,
        adminType: profile?.adminType,
      });
    }
  }, [profile]);

  const handleLogout = () => {
    localStorage.removeItem(PLAYER_URL);
    logout();
    removeAuthToken();
    queryClient.clear();
    navigate(routes.public.login);
  };

  const handleClick = () => {
    setOpenLiveStream(!openLiveStream);
  };

  const roleName = useMemo(
    () => ROLE_OPTIONS?.find((roleOpt) => roleOpt.value === profile?.adminType)?.label,
    [profile?.adminType],
  );

  return (
    <StyledAppBar
      position="absolute"
      open={open}
      elevation={1}
      sx={{
        backgroundColor: 'white',
        boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.125)',
        top: !config.isProd ? '1.875rem' : 0,
      }}
    >
      <Toolbar
        sx={{
          minHeight: '3.5rem!important',
          pl: '1rem!important',
          pr: '1rem!important', // keep right padding when drawer closed
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ...(width === 'xs' ? { pt: '1rem!important', pb: '1rem!important' } : undefined),
        }}
      >
        <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Tooltip title="Open Sidebar" placement="bottom">
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                // marginRight: '36px',
                margin: 'unset!important',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography
            sx={{
              display: 'grid',
              gap: width === 'xs' ? '0.5rem' : '3rem',
              gridTemplateAreas:
                width === 'xs'
                  ? `
              "trader role"
              "mobile email"
              `
                  : `"trader role mobile email"`,
              alignItems: 'center',
            }}
          >
            <AppBarText
              sx={{
                gridArea: 'trader',
                gap: ['xs', 'sm', 'md'].includes(width) ? '0' : '0.2rem',
                display: 'flex',
                flexDirection: ['xs', 'sm', 'md'].includes(width) ? 'column' : 'row',
              }}
              component="span"
            >
              Trader:
              <AppBarText component="span" sx={{ fontWeight: 500 }}>
                {profile && (profile?.firstName || profile?.lastName)
                  ? `${profile?.firstName || ''} ${profile?.lastName || ''}`
                  : 'N.A.'}
              </AppBarText>
            </AppBarText>
            <AppBarText
              sx={{
                gridArea: 'role',
                gap: ['xs', 'sm', 'md'].includes(width) ? '0' : '0.2rem',
                display: 'flex',
                flexDirection: ['xs', 'sm', 'md'].includes(width) ? 'column' : 'row',
              }}
              component="span"
            >
              Role:
              <AppBarText component="span" sx={{ fontWeight: 500 }}>
                {profile && roleName ? `${roleName || ''}` : 'N.A.'}
              </AppBarText>
            </AppBarText>
            <AppBarText
              sx={{
                gridArea: 'mobile',
                gap: ['xs', 'sm', 'md'].includes(width) ? '0' : '0.2rem',
                display: 'flex',
                flexDirection: ['xs', 'sm', 'md'].includes(width) ? 'column' : 'row',
              }}
              component="span"
            >
              Mobile:
              <AppBarText component="span" sx={{ fontWeight: 500 }}>
                {profile && profile?.phone
                  ? `${formattedPhoneText(profile?.phone || '', profile?.country || null)}`
                  : 'N.A.'}
              </AppBarText>
            </AppBarText>
            <AppBarText
              sx={{
                gridArea: 'email',
                gap: ['xs', 'sm', 'md'].includes(width) ? '0' : '0.2rem',
                display: 'flex',
                flexDirection: ['xs', 'sm', 'md'].includes(width) ? 'column' : 'row',
              }}
              component="span"
            >
              Email:
              <AppBarText component="span" sx={{ fontWeight: 500 }}>
                {profile && profile?.email ? `${profile?.email || ''}` : 'N.A.'}
              </AppBarText>
            </AppBarText>
          </Typography>
        </Box>
        {/* <InputBase
          placeholder="Search sports, athletes or dates"
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        /> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '1rem',
          }}
        >
          {systemHealth?.server && (
            <Tooltip
              title={`Alt Sports Data servers are ${
                SERVER_HEALTH_STATUS_LABELS[systemHealth?.server]
              }`}
              placement="bottom"
            >
              <Box
                component="img"
                src={SERVER_HEALTH_ICONS[systemHealth?.server]}
                alt="System Health"
                sx={{ width: '1.25rem' }}
              />
            </Tooltip>
          )}
          {systemHealth?.apiProviders && (
            <Tooltip
              title={`Alt Sports Data API is ${
                SERVER_HEALTH_STATUS_LABELS[systemHealth?.apiProviders]
              }`}
              placement="bottom"
            >
              <Box
                component="img"
                src={API_PROVIDER_HEALTH_ICONS[systemHealth?.apiProviders]}
                alt="API Provider Health"
                sx={{ width: '1.25rem' }}
              />
            </Tooltip>
          )}

          <Box ref={liveYTButton}>
            <Tooltip title="Live Stream" placement="bottom">
              <IconButton color="inherit" sx={{ p: 0 }} onClick={() => handleClick()}>
                <OndemandVideoIcon
                  sx={(theme) => {
                    return {
                      color: showYTStream ? theme.palette.primary.main : 'info.contrastText',
                      fontSize: '1.75rem',
                    };
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          {[ROLES.ADMIN, ROLES.HEAD_TRADER].includes(profile?.adminType) && (
            <Tooltip title="User Management" placement="bottom">
              <IconButton
                color="inherit"
                sx={{ p: 0 }}
                onClick={() => navigate(routes.userManagement)}
              >
                <ManageAccountsIcon
                  sx={(theme) => {
                    return {
                      color: showYTStream ? theme.palette.primary.main : 'info.contrastText',
                      fontSize: '1.75rem',
                    };
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {/* <IconButton color="inherit">
            <Badge badgeContent={4} color="primary">
              <NotificationsOutlinedIcon sx={{ color: 'text.disabled' }} />
            </Badge>
          </IconButton> */}
          <Tooltip title="Logout" placement="bottom">
            <IconButton color="inherit" sx={{ p: 0 }} onClick={handleLogout}>
              <PowerSettingsNewIcon sx={{ color: 'info.contrastText', fontSize: '1.75rem' }} />
            </IconButton>
          </Tooltip>
        </Box>
        <Popper open={openLiveStream || false} anchorEl={liveYTButton.current}>
          <Box
            sx={{
              mt: '2rem',
              mr: '2rem',
              // margin: '2rem',
              width: { xl: '30vw', lg: '30vw', sm: '40vw', xs: '85vw' },
              // height: { xl: '30vw', lg: '30vw', sm: '40vw', xs: '85vw' },
            }}
          >
            <PlayLiveStream
              showYTStream={showYTStream}
              setShowYTStream={setShowYTStream}
              openLiveStream={openLiveStream}
            />
          </Box>
        </Popper>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppBar;
