import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { BaseFutureOddsContainerDTO } from '@/types/helpers/futureOdds';

interface FETCH_FUTURE_ODDS {
  sportType: string;
  tourYearId: string;
  futureType: string;
}

const getFutureOdds = async ({
  sportType,
  tourYearId,
  futureType,
}: FETCH_FUTURE_ODDS): Promise<BaseFutureOddsContainerDTO> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/futures/tour/${tourYearId}/odds/${futureType}`,
  });

  return data;
};

export default function useFetchFutureOdds({
  sportType,
  tourYearId,
  futureType,
}: FETCH_FUTURE_ODDS) {
  return useQuery(
    [keys.futures.fetchFutureOdds, sportType, tourYearId, futureType],
    () => getFutureOdds({ sportType, tourYearId, futureType }),
    {
      staleTime: Infinity,
      enabled: !!tourYearId,
    },
  );
}
