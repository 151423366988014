import { useMutation } from '@tanstack/react-query';

import { TraderShowOddAddDTO } from '@/types/traderOddUpload';

import { authenticatedAxios } from '../..';

interface TraderShowOdds {
  sport: string;
  requestPayload: {
    items: TraderShowOddAddDTO;
  };
}

const addTraderShowOdds = async ({ sport, requestPayload }: TraderShowOdds) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/odds/shows`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderShowOdds() {
  return useMutation(({ sport, requestPayload }: TraderShowOdds) =>
    addTraderShowOdds({ sport, requestPayload }),
  );
}
