import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, styled, IconButton } from '@mui/material';
import { zonedTimeToUtc } from 'date-fns-tz';

import CloseIcon from '@mui/icons-material/Close';

import Dropzone from '@/components/Dropzone';
// import  { sportOptions } from '@/constants/sportTypes';
// import { ParticipantStatusValues } from '@/constants/athletes';
import useAddOrUpdateEventSeeds from '@/hooks/fights/useAddOrUpdateEventSeeds';

import { displayInPT as format } from '@/helpers/timeConverters';

// import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';

import { DEFAULT_TIMEZONE, feetAndInchesToCm, lbsToKg, splitFeetAndInches } from '@/constants/misc';
import { IEvent } from '@/types/newTypes/event';
import { FightMatchMemberTypes } from '@/constants/fights';

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '1rem 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1.5rem 1.5rem 3rem',
  },
}));

const ALLOWED_BYB_HEADERS = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Nick Name', field: 'nickName' },
  { title: 'Hometown', field: 'homeTown' },
  { title: 'Date Of Birth', field: 'dateOfBirth' },
  { title: 'Federal Id', field: 'federalId' },
  { title: 'Weight Class', field: 'weightClass' },
  { title: 'Height(Ft, Inch)', field: 'height' },
  { title: 'Weight(Lbs)', field: 'weight' },
];

type UploadParticipantsModalProps = {
  open: boolean;
  handleClose: () => void;
  sportType: string;
  event: IEvent;
  refetchParticipants?: VoidFunction;
};

export default function UploadParticipantsModal(props: UploadParticipantsModalProps) {
  const { open, handleClose, sportType, refetchParticipants, event } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  //   const [isDialogOpen, setIsDialogOpen] = useState(false);
  //   const [allowedHeaders, setAllowedHeaders] = useState([]);
  const { mutateAsync: addOrUpdateEventSeeds } = useAddOrUpdateEventSeeds();

  const [sheetData, setSheetData] = useState<any>([]);

  //   const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      const uploadData = async () => {
        try {
          const modSheet = JSON.parse(JSON.stringify(sheetData));

          const modSheetDTO = modSheet.map((data: any) => {
            const { feet, inches } = splitFeetAndInches(data?.height || '');
            const height = feetAndInchesToCm(feet, inches);
            const weight = lbsToKg(data?.weight || 0);
            return {
              ...data,
              dateOfBirth: data?.dateOfBirth
                ? zonedTimeToUtc(new Date(data?.dateOfBirth), DEFAULT_TIMEZONE)
                : '',
              height,
              weight,
              weightClass: data?.weightClass || '',
              role: +data?.role,
              eventName: event?.name || '',
            };
          });

          await addOrUpdateEventSeeds(
            { sportType, payload: { items: [...modSheetDTO] } },
            {
              onSettled: (_, err) => {
                if (!err) {
                  enqueueSnackbar(`Uploaded athlete successfully!`);
                  if (refetchParticipants) {
                    refetchParticipants();
                  }
                }
                setSheetData([]);
                setDisableBtn(false);
                handleClose();
              },
            },
          );
        } catch (error) {
          // Handle error accordingly
        }
      };

      const timeout = setTimeout(uploadData, 600);
      return () => clearTimeout(timeout);
    }
  }, [disableBtn]);

  const tableRowChecker = (rows: any[]) => {
    let message = '';
    const commonRequiredKeys = ['firstName', 'lastName'];
    rows.forEach((row: any) => {
      const requiredKeys = [...commonRequiredKeys];
      if (!row?.role) {
        requiredKeys.push('role');
      } else if (row?.role === FightMatchMemberTypes.ATHLETE) {
        requiredKeys.push(
          'nickName',
          'homeTown',
          'dateOfBirth',
          'weightClass',
          'height',
          'weight',
          'role',
        );
      }

      const missingColumns = requiredKeys.filter((key) => !row.hasOwnProperty(key));
      if (missingColumns.length > 0) {
        message = `Error on row { ${rows.indexOf(row) + 2} }, Missing { ${missingColumns.join(
          ', ',
        )} }`;
        return true;
      }
      return false;
    });

    return message;
  };

  const tableDataModifierFn = (data: any) => {
    const modifiedData = {
      ...data,
      dateOfBirth: data.dateOfBirth ? format(new Date(data.dateOfBirth), 'MM/dd/yyyy') : '',
      weightClass: data?.weightClass || '',
    };

    return modifiedData;
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <Box
        sx={{
          px: '1.3rem',
          py: 0.5,
          borderBottom: '1px solid #DEE2E6',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,

            fontSize: '1.25rem',
            lineHeight: '1.5rem',
          }}
          component="h2"
        >
          {'Manage athletes'}
        </Typography>
        <IconButton onClick={handleClose} disableRipple>
          <CloseIcon sx={{ fontSize: '1.25rem' }} />
        </IconButton>
      </Box>
      <DialogContent sx={{ minHeight: '50vh' }}>
        <Dropzone
          title="Seeds"
          sheetName={`${sportType?.toUpperCase() || ''} Upload Sheet`}
          sheetData={sheetData}
          setSheetData={setSheetData}
          allowedHeaders={ALLOWED_BYB_HEADERS}
          tableRowChecker={tableRowChecker}
          tableDataModifierFn={tableDataModifierFn}
          dropZoneSize={'50vh'}
        />
        {sheetData.length > 0 && (
          <DialogActions>
            <Grid
              container
              justifyContent={'flex-end'}
              alignItems="center"
              columnSpacing={{ xs: 1 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    handleClose();
                  }}
                  fullWidth
                  disableElevation
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
              >
                <Button
                  variant="contained"
                  disabled={disableBtn}
                  onClick={() => {
                    setDisableBtn(true);
                  }}
                  fullWidth
                  disableElevation
                >
                  Upload Data
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
}
