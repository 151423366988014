import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface UPDATE_BKFC_SCORES {
  sport: string;
  eventId: string;
  roundHeatId: string;
  requestPayload: any;
}

const updateBKFCScores = async ({
  sport,
  eventId,
  roundHeatId,
  requestPayload,
}: UPDATE_BKFC_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/traders/events/${eventId}/scores/${roundHeatId}`,
    data: requestPayload,
  });

  return data;
};

export default function useUpdateBKFCScores() {
  return useMutation(({ sport, eventId, roundHeatId, requestPayload }: UPDATE_BKFC_SCORES) =>
    updateBKFCScores({ sport, eventId, roundHeatId, requestPayload }),
  );
}
