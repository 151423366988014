import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';

import { sportsType } from '@/atoms/sportsType';
import { WSLHeat } from '@/types/wsl/heat';
import { SLSHeat } from '@/types/sls/heat';
import { NRXHeat } from '@/types/nrx/heat';
import { SPRHeat } from '@/types/spr/heat';
import Dropzone from '@/components/Dropzone';
import { sportOptions } from '@/constants/sportTypes';
import { HeatStatusValues } from '@/constants/heats';
import { processHeatStatusBadges } from '@/helpers/events';
import useAddHeats from '@/hooks/heats/useAddHeats';
import { HeatRow, requiredPayloadKeys, allowedTableHeaders } from './constants';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';
import routes from '@/constants/routes';
import SPORT_TYPES from '@/constants/sportTypes';

type HEAT = WSLHeat | SLSHeat | NRXHeat | SPRHeat;
type HEATS = WSLHeat[] | SLSHeat[] | NRXHeat[] | SPRHeat[];

export default function HeatsUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addHeats } = useAddHeats();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<HEATS>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        const modSheetDTO = modSheet.map((data: HEAT) => {
          const heatStatus = HeatStatusValues[data?.heatStatusMod?.toUpperCase() || ''];
          delete data.heatStatusMod;
          const finalData = {
            ...data,
            heatStatus,
          };
          return finalData;
        });
        addHeats(
          {
            sport: selectedSport.toLowerCase(),
            requestPayload: {
              items: modSheetDTO,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Heats Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders[selectedSport] || [];

      const formattedAllowedHeaders = headers.map((header: any) => {
        if (header.title === 'Heat Status') {
          header.render = (rowData: HEAT) =>
            processHeatStatusBadges(
              HeatStatusValues[String(rowData?.heatStatusMod || '').toUpperCase()],
            );
        }

        return header;
      });

      setAllowedHeaders(formattedAllowedHeaders);
    }
  }, [selectedSport]);

  const handleChange = () => {
    setSheetData([]);
  };

  const tableRowChecker = (rows: HeatRow[]) => {
    let message = '';
    const index = rows.findIndex(
      (row: HeatRow) =>
        !row.hasOwnProperty('name') ||
        !row.hasOwnProperty('event') ||
        !row.hasOwnProperty('round') ||
        !row.hasOwnProperty('year') ||
        !row.hasOwnProperty('heat_status') ||
        !row.hasOwnProperty('gender') ||
        ([SPORT_TYPES.NRX, SPORT_TYPES.SPR].includes(selectedSport) &&
          !row.hasOwnProperty('tour_name')),
    );

    if (index !== -1) {
      const row = rows[index];
      let missing_columns = '';
      const requiredKeys = requiredPayloadKeys[selectedSport];
      requiredKeys.map((key) => {
        if (!row[key as keyof HeatRow]) missing_columns += `${key} `;
        return key;
      });
      missing_columns = missing_columns.split(' ').join(', ').slice(0, -2);
      message = `Error on row { ${index + 2} }, Missing { ${missing_columns} }`;
    }

    return message;
  };

  const tableDataModifierFn = (data: HeatRow) => {
    const { name, round, event, year, heat_status, gender, tour_name } = data;
    const nameSplitter = name.split(' ');
    const [heatName = '', heatNo] = nameSplitter;
    return {
      heatName: heatName,
      heatNo: Number(heatNo),
      heatStatusMod: heat_status,
      roundName: round,
      eventName: event,
      tourName: tour_name,
      year,
      gender,
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.heats.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Heats
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Heats"
        sheetName="Heats"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />
      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
          >
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} HEATS?`}
          body={'This will upload the heats.'}
        />
      )}
    </Box>
  );
}
