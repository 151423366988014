import { useState, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import format from 'date-fns/format';

import useFetchAthletes from '@/hooks/fightSportMisc/useFetchAthletes';

import SPORT_TYPES from '@/constants/sportTypes';

import { cmToFeetAndInches, kgToLbs } from '@/constants/misc';
import keys from '@/constants/queryKeys';

const updateDataFormatter = (seed: any) => {
  return {
    ...seed,
    name: `${[seed?.firstName, seed?.middleName, seed?.lastName].filter(Boolean).join(' ')}${
      seed?.nickName ? ` (${seed?.nickName})` : ''
    }`,
    homeTown: seed?.homeTown || '',
    dateOfBirth: seed?.dateOfBirth ? format(new Date(seed?.dateOfBirth), 'MM/dd/yyyy') : '',
    federalId: seed?.federalId || '',
    weightClass: seed?.weightClass || '',
    weight: +seed?.weight || '',
    height: +seed?.height || '',
    feet: cmToFeetAndInches(Number(+seed?.height || null) as number)?.feet || 0,
    inches: cmToFeetAndInches(Number(+seed?.height || null) as number)?.inches || 0,
    lbs: kgToLbs(Number(+seed?.weight || null) as number) || 0,
  };
};

const initialFormatters = {
  [SPORT_TYPES.BYB]: updateDataFormatter,
  [SPORT_TYPES.POWERSLAP]: updateDataFormatter,
};

const useFightParticipants = ({ sportType }: { sportType: string }) => {
  const queryClient = useQueryClient();
  const { data: communityMembers } = useFetchAthletes(sportType, 'Event');

  const [tableData, setTableData] = useState<any[]>([]);

  const initialDataFormatter = initialFormatters[sportType];

  const initialDataLoader = (seeds: any[]) => {
    if (seeds && seeds?.length === 0) {
      setTableData([]);
    }
    const seedsCopy = JSON.parse(JSON.stringify(seeds));
    const formattedSeeds = seedsCopy.map((seed: any) => {
      return initialDataFormatter(seed);
    });
    setTableData(formattedSeeds);
  };

  useEffect(() => {
    if (communityMembers) initialDataLoader([...(communityMembers || [])]);
  }, [JSON.stringify(communityMembers)]);

  const refetchSeeds = () => {
    queryClient.removeQueries([keys.fightSportMisc.fetchAthletes, sportType, 'Event']);
  };

  useEffect(() => {
    return () => {
      refetchSeeds();
    };
  }, []);

  return {
    tableData,
    refetchSeeds,
  };
};

export default useFightParticipants;
