import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface PUBLISH_NOTES {
  sport: string;
  marketType?: string;
  eventId?: string;
  futureType?: string;
  tourYearId?: string;
  payload: { note: string };
}

const publishNotes = async ({
  sport,
  marketType,
  eventId,
  futureType,
  tourYearId,
  payload,
}: PUBLISH_NOTES) => {
  const notesURL = eventId
    ? `admin/traders/${sport}/${eventId}/notes/${marketType}`
    : `admin/${sport}/futures/${futureType}/tour/${tourYearId}/notes`;
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: notesURL,
    data: payload,
  });

  return data;
};

export default function usePublishNotes() {
  return useMutation((reqObj: PUBLISH_NOTES) => publishNotes(reqObj));
}
