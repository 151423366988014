import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import { ReactComponent as DefaultViewIcon } from '@/images/icons/default-view-icon.svg';
import { ReactComponent as ListViewIcon } from '@/images/icons/list-view-icon.svg';

import { HEAT_VIEW_MODES } from '@/constants/heats';

interface HeatViewToggleProps {
  heatViewMode: number;
  setHeatViewMode: (heatViewMode: number) => void;
  skipTab?: number;
}

const StyledTabs = styled(Tabs)({
  minHeight: 0,
  marginBottom: '0.375rem',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled((props: TabProps) => <Tab component="span" disableRipple {...props} />)(
  ({ theme }) => ({
    minHeight: 'unset',
    textTransform: 'none',
    color: theme.palette.info.contrastText,
    fontWeight: 400,
    lineHeight: '1.5rem',
    padding: 0,
    margin: '0 0.625rem',
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'white',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  }),
);

const HeatViewToggle: React.FC<HeatViewToggleProps> = ({
  heatViewMode,
  setHeatViewMode,
  skipTab,
}) => {
  const handleViewChange = (_: React.SyntheticEvent, newValue: number) => {
    setHeatViewMode(newValue);
  };

  return (
    <StyledTabs
      value={heatViewMode}
      onChange={handleViewChange}
      aria-label="heat view mode selection"
    >
      {skipTab !== HEAT_VIEW_MODES.default && (
        <StyledTab
          icon={
            <DefaultViewIcon
              fontSize="1.25rem"
              fill={heatViewMode === HEAT_VIEW_MODES.default ? '#0D6EFD' : '#6C757D'}
            />
          }
          iconPosition="start"
          label="Default View"
        />
      )}
      {skipTab !== HEAT_VIEW_MODES.list && (
        <StyledTab
          icon={
            <ListViewIcon
              fontSize="1.25rem"
              fill={heatViewMode === HEAT_VIEW_MODES.list ? '#0D6EFD' : '#6C757D'}
            />
          }
          iconPosition="start"
          label="List View"
        />
      )}
    </StyledTabs>
  );
};

export default HeatViewToggle;
