import round from 'lodash.round';

import AMALogo from '@/images/sports/AMA.png';
import SLSLogo from '@/images/sports/SLS.png';
import WSLLogo from '@/images/sports/WSL.png';
import MASLLogo from '@/images/sports/MASL.png';
import FORMULA_DRIFT from '@/images/sports/FORMULA_DRIFT_LOGO.png';
import MOTOCROSSLogo from '@/images/sports/MOTOCROSS_LOGO.png';
import NITRO_RALLYCROSSLogo from '@/images/sports/NITRO_RALLYCROSS.png';
import FORMULA1 from '@/images/sports/FORMULA1.png';
import MOTOGPLogo from '@/images/sports/MOTOGP.png';
import MXGPLogo from '@/images/sports/MXGP.png';
import JAIALAILogo from '@/images/sports/JAIALAI.png';
import NHRALogo from '@/images/sports/NHRA.png';
import MOTOAMERICALogo from '@/images/sports/MOTOAMERICA.png';
import BYBLogo from '@/images/sports/BYB.png';
import BKFCLogo from '@/images/sports/BKFC.png';
import USACLogo from '@/images/sports/USAC.png';
import PowerSlap from '@/images/sports/PowerSlap.png';
// import SPRMTCRSLogo from '@/images/sports/SPRMTCRS.png';
import WORLDOUTLAWSLogo from '@/images/sports/WORLDOUTLAWS.png';
import HLRSLogo from '@/images/sports/HLRS.png';
import XGAMELogo from '@/images/sports/XGAME.png';

import ServerUpIcon from '@/images/icons/system/server-up.svg';
import ServerFailingIcon from '@/images/icons/system/server-failing.svg';
import ServerDownIcon from '@/images/icons/system/server-down.svg';

import APIProviderUpIcon from '@/images/icons/system/api-provider-up.svg';
import APIProviderFailingIcon from '@/images/icons/system/api-provider-failing.svg';
import APIProviderDownIcon from '@/images/icons/system/api-provider-down.svg';

import SPORT_TYPES from '@/constants/sportTypes';

export const SPORT_LOGOS = {
  [SPORT_TYPES.WSL]: WSLLogo,
  [SPORT_TYPES.SLS]: SLSLogo,
  [SPORT_TYPES.NRX]: NITRO_RALLYCROSSLogo,
  [SPORT_TYPES.SPR]: AMALogo,
  [SPORT_TYPES.MASL]: MASLLogo,
  [SPORT_TYPES.FDRIFT]: FORMULA_DRIFT,
  [SPORT_TYPES.MOTOCRS]: MOTOCROSSLogo,
  [SPORT_TYPES.F1]: FORMULA1,
  [SPORT_TYPES.MOTOGP]: MOTOGPLogo,
  [SPORT_TYPES.MXGP]: MXGPLogo,
  [SPORT_TYPES.JAIALAI]: JAIALAILogo,
  [SPORT_TYPES.NHRA]: NHRALogo,
  [SPORT_TYPES.MOTOAMERICA]: MOTOAMERICALogo,
  [SPORT_TYPES.BYB]: BYBLogo,
  [SPORT_TYPES.BKFC]: BKFCLogo,
  [SPORT_TYPES.USAC]: USACLogo,
  [SPORT_TYPES.POWERSLAP]: PowerSlap,
  [SPORT_TYPES.SPRMTCRS]: MOTOCROSSLogo,
  [SPORT_TYPES.WORLDOUTLAWS]: WORLDOUTLAWSLogo,
  [SPORT_TYPES.HLRS]: HLRSLogo,
  [SPORT_TYPES.XGAME]: XGAMELogo,
  // [SPORT_TYPES.SPRMTCRS]: SPRMTCRSLogo,
  // [SPORT_TYPES.HLRS]: HLRSLogo,
};

export const drawerWidth = 300;

export const fractionPattern = /^(?:[+-]?\d*\.?[\d*][\d*]?\/[1](?:|$))+$/g;

export const initialAthleteOption = { id: '', label: '' };

export const TYPES_OF_START_AND_END_TIMES = {
  heat: 'Heat',
  round: 'Round',
  fight: 'Fight',
};

export const DEFAULT_HOLD_PERCENTAGE = 100;

export const NON_ATHLETE_PROP = '[Non Athlete Prop]';

export const HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS: string[] = [
  // SPORT_TYPES.FDRIFT,
  // SPORT_TYPES.MOTOCRS,
  // SPORT_TYPES.F1,
  // SPORT_TYPES.MOTOGP,
];

export const filterObj = (params: { [key: string]: string | number }) =>
  Object.keys(params)
    .filter((key: string) => params[key as keyof {}] !== '')
    .reduce((a, key: string) => ({ ...a, [key]: params[key as keyof {}] }), {});

export const SPORTS_EVENT_SEEDS_WITH_WEIGHT = [SPORT_TYPES.WSL];

export const EVENT_SEED_WEIGHT_TYPES = {
  year: 'year',
  location: 'location',
};

export const gridCustomTemplateAreaBuilder = ({
  areas,
  noOfColumns,
}: {
  areas: string[];
  noOfColumns: number;
}) => {
  let customGridArea = '';
  areas
    .map((_, i) => i % noOfColumns === 0 && areas.slice(i, i + noOfColumns))
    .filter((x) => x)
    .forEach((x) => (customGridArea += `"${x}" `.replaceAll(',', ' ')));

  return customGridArea;
};

export const WEIGHT_CLASS_LABEL_OBJ: { [key: string]: string } = {
  'HW - Heavyweight': 'heavyweight',
  'CW - Cruiserweight': 'cruiserweight',
  'LHW - Light Heavyweight': 'light-heavyweight',
  'MW - Middleweight': 'middleweight',
  'WW - Welterweight': 'welterweight',
  'LW - Lightweight': 'lightweight',
  'FW - Featherweight': 'featherweight',
  'SFW - Super Featherweight': 'super-featherweight',
  'SMW - Super Middleweight': 'super-middleweight',
  'SLW - Super Lightweight': 'super-lightweight',
  'CW - Catch Weight': 'catch-weight',
  'SWW - Super Welterweight': 'super-welterweight',
  'BW - Bantamweight': 'bantamweight',
  'FLW - Flyweight': 'flyweight',
  'SFL - Super Flyweight': 'super-flyweight',
  'SW - Strawweight': 'strawweight',
};

export const WEIGHT_CLASS_OBJ: { [key: string]: string } = {
  heavyweight: 'HW - Heavyweight',
  cruiserweight: 'CW - Cruiserweight',
  'light-heavyweight': 'LHW - Light Heavyweight',
  middleweight: 'MW - Middleweight',
  welterweight: 'WW - Welterweight',
  lightweight: 'LW - Lightweight',
  featherweight: 'FW - Featherweight',
  'super-featherweight': 'SFW - Super Featherweight',
  'super-middleweight': 'SMW - Super Middleweight',
  'super-lightweight': 'SLW - Super Lightweight',
  'catch-weight': 'CW - Catch Weight',
  'super-welterweight': 'SWW - Super Welterweight',
  bantamweight: 'BW - Bantamweight',
  flyweight: 'FLW - Flyweight',
  'super-flyweight': 'SFL - Super Flyweight',
  strawweight: 'SW - Strawweight',
};

export const WEIGHT_CLASSES = [
  { label: 'HW - Heavyweight', value: 'heavyweight' },
  { label: 'CW - Cruiserweight', value: 'cruiserweight' },
  { label: 'LHW - Light Heavyweight', value: 'light-heavyweight' },
  { label: 'MW - Middleweight', value: 'middleweight' },
  { label: 'WW - Welterweight', value: 'welterweight' },
  { label: 'LW - Lightweight', value: 'lightweight' },
  { label: 'FW - Featherweight', value: 'featherweight' },
  { label: 'SFW - Super Featherweight', value: 'super-featherweight' },
  { label: 'SMW - Super Middleweight', value: 'super-middleweight' },
  { label: 'SLW - Super Lightweight', value: 'super-lightweight' },
  { label: 'CW - Catch Weight', value: 'catch-weight' },
  { label: 'SWW - Super Welterweight', value: 'super-welterweight' },
  { label: 'BW - Bantamweight', value: 'bantamweight' },
  { label: 'FLW - Flyweight', value: 'flyweight' },
  { label: 'SFL - Super Flyweight', value: 'super-flyweight' },
  { label: 'SW - Strawweight', value: 'strawweight' },
];

export const HEIGHT_UNITS = {
  cm: 'cm',
  feet: 'feet',
};

export const HEIGHT_UNIT_OPTIONS = [
  { label: 'Centimeters', value: HEIGHT_UNITS.cm },
  { label: 'Feet', value: HEIGHT_UNITS.feet },
];

export const WEIGHT_UNITS = {
  kg: 'kg',
  lbs: 'lbs',
};
export const WEIGHT_UNIT_OPTIONS = [
  { label: 'KG', value: WEIGHT_UNITS.kg },
  { label: 'LBS', value: WEIGHT_UNITS.lbs },
];

export const cmToFeetAndInches = (cm: number) => {
  const inches = cm / 2.54;
  let feet = Math.floor(inches / 12);
  let remainingInches = round(inches % 12);

  if (remainingInches >= 12) {
    feet += 1;
    remainingInches -= 12;
  } else {
    remainingInches = Math.round(remainingInches);
  }

  return { feet, inches: remainingInches };
};

export const feetAndInchesToCm = (feet: number, inches: number) => {
  const totalInches = feet * 12 + inches;
  return round(totalInches * 2.54);
};

export const kgToLbs = (kg: number) => round(kg * 2.20462, 2);

export const lbsToKg = (lbs: number) => round(lbs / 2.20462, 2);

export const splitFeetAndInches = (height: string) => {
  const parts = height.split(/['′″]/);

  if (parts.length > 0) {
    const feet = parseInt(parts[0]) || 0;
    const inches = parseInt(parts[1]) || 0;
    return { feet, inches };
  } else {
    return { feet: 0, inches: 0 };
  }
};

export const WIN_BY_OPTIONS = [
  { label: 'Knockout (KO)', value: 'Knockout' },
  { label: 'Technical Knockout (TKO)', value: 'Technical Knockout' },
  { label: 'Decision', value: 'Decision' },
  { label: 'Technical Decision', value: 'Technical Decision' },
  { label: 'Disqualification (DQ)', value: 'Disqualification' },
  { label: 'Forfeit', value: 'Forfeit' },
  { label: 'No Contest', value: 'No Contest' },
];

export const WIN_BY_OPTIONS_BKFC = [
  { label: 'Unanimous Decision', value: 'Unanimous Decision' },
  { label: 'TKO', value: 'TKO' },
  { label: 'KO', value: 'KO' },
  { label: 'Split Decision', value: 'Split Decision' },
  { label: 'Unanimous Draw', value: 'Unanimous Draw' },
];

export const WIN_BY_OPTIONS_POWERSLAP = [
  { label: 'Knockout (KO)', value: 'Knockout' },
  { label: 'Technical Knockout (TKO)', value: 'Technical Knockout' },
  { label: 'Decision', value: 'Decision' },
  { label: 'Technical Decision', value: 'Technical Decision' },
  { label: 'Disqualification (DQ)', value: 'Disqualification' },
  { label: 'Forfeit', value: 'Forfeit' },
  { label: 'No Contest', value: 'No Contest' },
  { label: 'Split Decision', value: 'Split Decision' },
  { label: 'Majority Draw', value: 'Majority Draw' },
  { label: 'Unanimous Decision', value: 'Unanimous Decision' },
  { label: 'Draw', value: 'Draw' },
];

export const POWERSLAP_FOULS = ['Stepping', 'Clubbing', 'Illegal Wind-Up', 'Flinching', 'Blocking'];

export const SYSTEM_HEALTH_STATUS = {
  UP: 'up',
  FAILING: 'failing',
  DOWN: 'down',
};

export const SERVER_HEALTH_STATUS_LABELS = {
  [SYSTEM_HEALTH_STATUS.UP]: `Connected`,
  [SYSTEM_HEALTH_STATUS.FAILING]: `Queuing`,
  [SYSTEM_HEALTH_STATUS.DOWN]: `Down`,
};

export const SERVER_HEALTH_ICONS = {
  [SYSTEM_HEALTH_STATUS.UP]: ServerUpIcon,
  [SYSTEM_HEALTH_STATUS.FAILING]: ServerFailingIcon,
  [SYSTEM_HEALTH_STATUS.DOWN]: ServerDownIcon,
};

export const API_PROVIDER_HEALTH_ICONS = {
  [SYSTEM_HEALTH_STATUS.UP]: APIProviderUpIcon,
  [SYSTEM_HEALTH_STATUS.FAILING]: APIProviderFailingIcon,
  [SYSTEM_HEALTH_STATUS.DOWN]: APIProviderDownIcon,
};

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';

export const ODD_TAB_QUERY_PARAMS = {
  fastestLap: 'fastestLap',
  eventWinner: 'eventWinner',
  secondPlace: 'secondPlace',
  heatWinner: 'heatWinner',
  fightWinner: 'fightWinner',
  headToHead: 'headToHead',
  propBets: 'propBets',
  showOdds: 'shows',
  podiumOdds: 'podiums',
  dreamTeam: 'dreamTeam',
  eventExacta: 'eventExacta',
  heatExacta: 'heatExacta',
};

export const EVENT_TAB_QUERY_PARAMS = {
  eventTab: 'eventTab',
  odd: 'odd',
};

export const JAI_ALAI_GAME_QUERY_PARAMS = {
  matchSelected: 'matchSelected',
};

export const MASL_GAME_QUERY_PARAMS = {
  matchSelected: 'matchSelected',
};
