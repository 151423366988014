import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

const getParticipants = async (sport: string, eventId: string, archived: number) => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/traders/events/${eventId}/participants?archived=${archived}`,
  });
  return data;
};

export default function useFetchParticipants(sport: string, eventId: string, archived = 0) {
  return useQuery(
    [keys.athletes.fetchParticipants, sport, eventId, archived],
    () => getParticipants(sport, eventId, archived),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
