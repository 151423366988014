import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';

import { unsavedChangesState } from '@/atoms/unsavedChanges';

import useFetchEvent from '@/hooks/events/useFetchEvent';
import useFetchTours from '@/hooks/events/useFetchTours';
import useFetchEventLocationGroup from '@/hooks/events/useFetchEventLocationGroup';

import {
  invalidateCachedEvent,
  invalidateCachedTours,
  invalidateCachedEventLocationGroup,
} from '@/helpers/cachedQueries';

const useSportEvent = () => {
  const { sportType = '', eventId = '' } = useParams();

  const queryClient = useQueryClient();

  const [currentTabValue, setCurrentTabValue] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useRecoilState(unsavedChangesState);
  const [nextTabValue, setNextTabValue] = useState<string | null>(null);

  const handleTabChange = () => {
    if (unsavedChanges.isChangeUnsaved && nextTabValue) {
      setUnsavedChanges({
        ...unsavedChanges,
        isTabNavigation: true,
      });
    }
  };

  // If another tab is clicked.
  useEffect(() => {
    handleTabChange();
  }, [nextTabValue]);

  // If next tab was clicked while having unsaved changes and 'Stay Here'/'Cancel' was clicked.
  // Then set nextTabValue back to null.
  useEffect(() => {
    const { isChangeUnsaved, isTabNavigation } = unsavedChanges;
    if (isChangeUnsaved && !isTabNavigation && nextTabValue) {
      setNextTabValue(null);
    }
  }, [unsavedChanges]);

  // If next tab was clicked while having unsaved changes and 'Confirm' was clicked.
  // Go to next tab if changes saved or disregarded.
  useEffect(() => {
    const { isChangeUnsaved } = unsavedChanges;
    const isNextTabPending = nextTabValue && currentTabValue !== nextTabValue;

    if (!isChangeUnsaved && isNextTabPending) {
      setCurrentTabValue(nextTabValue);
      setNextTabValue(null);
    }
  }, [unsavedChanges, nextTabValue]);

  const { data: event, isLoading } = useFetchEvent(sportType, eventId, 'Event');
  useFetchTours(sportType, 'Event');
  useFetchEventLocationGroup(sportType, 'Event');

  /* To refetch data when event (cached) is visited again */
  useEffect(() => {
    if (event) {
      invalidateCachedEvent(queryClient, sportType, eventId, 'Event');
      invalidateCachedTours(queryClient, sportType, 'Event');
      invalidateCachedEventLocationGroup(queryClient, sportType, 'Event');
    }
  }, [eventId]);

  return { event, isLoading, currentTabValue, setNextTabValue };
};

export default useSportEvent;
