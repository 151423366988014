import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';

export const resetHeatWinnerHeat = (heat: any) => {
  if (heat.athletes && heat.athletes.length > 0) {
    const athletes = heat.athletes.map((athlete: any) => {
      return {
        ...athlete,
        decimalOdds: round(oddsHelpers.getDecimalOdds(athlete?.odds) || 0, 2),
        fractionalOdds: oddsHelpers.getFractionalOdds(athlete?.odds).toString(),
        americanOdds:
          oddsHelpers.getAmericanOddsFromProbability(athlete?.probability) > 0
            ? `+${oddsHelpers.getAmericanOddsFromProbability(athlete?.probability)}`
            : `${oddsHelpers.getAmericanOddsFromProbability(athlete?.probability)}`,
      };
    });
    return { ...heat, hasBeenEdited: false, athletes };
  }
  return { ...heat, hasBeenEdited: false };
};

export const heatWinnerDataLoader = (heatWinners: any[]) => {
  // if there are no odds then return an empty array
  if (!heatWinners || (heatWinners && heatWinners.length === 0)) return [];

  const toBeModHeatWinners = JSON.parse(JSON.stringify(heatWinners));

  const modHeatWinners = toBeModHeatWinners.map((round: any) => {
    const heats = round.heats.map((heat: any) => resetHeatWinnerHeat(heat));
    return { ...round, heats };
  });

  return modHeatWinners;
};

export const heatWinnerOddsPayloadFormatter = (eventOddsPositions: any[] = []) => {
  if (!eventOddsPositions || (eventOddsPositions && eventOddsPositions?.length === 0)) return [];

  const payload: any[] = [];

  eventOddsPositions.map((data) => {
    const eventOddsPosition = {
      id: data?.heatOddId,
      probability: round(+data?.probability, 2),
      hasModifiedProbability: data?.hasModifiedProbability,
      odds: round(+data?.odds, 2),
    };
    payload.push(eventOddsPosition);
    return data;
  });

  return payload;
};
