import React, { useState } from 'react';

import { Tab, Box, styled, Typography } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { ThemeProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { oddsTablesTheme } from '@/constants/theme';

import FutureOddsTab from './FutureOdds';
import OddTypeSwitcher from '../OddTypeSwitcher';

import usePublishFutureOdds from '@/hooks/futures/odds/usePublishFutureOdds';

import { FUTURE_ODD_TAB_LABELS, FUTURE_TYPES, FUTURE_TYPES_ORDER } from '@/constants/futures';

import { invalidateFutureOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import { Market, SportTourFutures } from '@/types/futures';

const MuiTabs = styled(TabList)({
  '& .MuiTabs-root': {
    backgroundColor: 'red',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#ffff',
  },
});

const MuiTab = styled(Tab)({
  '&.MuiTab-root': {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    color: '#6C757D',
    backgroundColor: '#F1F2F4',
    height: '3.5rem',
  },
  '&.Mui-selected': {
    color: `#0D6EFD`,
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 20,
    border: '5px solid #F1F2F4',
    borderRadius: '4px',
  },
  '&.MuiTab-wrapped': {
    textTransform: 'none',
  },
});

const MuiTabLabelText = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 500,
});
const MuiTabLabelDate = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

const MuiTabPanel = styled(TabPanel)({
  padding: '1rem 0',
  maxWidth: '100%',
});

interface FUTURE_ODDS_CONTAINER_PROPS {
  sportType: string;
  tourYearId: string;
  markets: Market[];
  sportTourFutures: SportTourFutures;
}

export default function FutureOddsContainer({
  sportType,
  tourYearId,
  markets,
  sportTourFutures,
}: FUTURE_ODDS_CONTAINER_PROPS) {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: publishFutureOdds } = usePublishFutureOdds();
  const [value, setValue] = useState(
    markets.find((mkt) => mkt.name === FUTURE_TYPES.winner)
      ? FUTURE_TYPES.winner
      : markets[0]?.name || '',
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const formattedFutureStartDate = sportTourFutures.nextEventStartDate
    ? format(new Date(sportTourFutures.nextEventStartDate), `yyyy-MM-dd HH:mm 'PST'`)
    : 'Time TBD ';

  const OddsTabs = React.useMemo(() => {
    if (!markets || markets?.length === 0) {
      return [];
    }

    const validMarkets: Market[] = FUTURE_TYPES_ORDER.map((name) =>
      markets.find((obj) => obj.name === name),
    ).filter(Boolean);

    return validMarkets.map((market) => {
      const label = (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 0.2,
          }}
        >
          <MuiTabLabelText>
            {FUTURE_ODD_TAB_LABELS[market.name as keyof typeof FUTURE_ODD_TAB_LABELS]}
          </MuiTabLabelText>
          <MuiTabLabelDate>{formattedFutureStartDate}</MuiTabLabelDate>
        </Box>
      );
      return <MuiTab key={`${sportType}_${market.name}`} value={market.name} label={label} />;
    });
  }, [sportType, markets, formattedFutureStartDate]);

  const handlePublishFutureOdds = (
    projectionType: string,
    // roundHeatId?: string | null | undefined,
    // roundId?: string | null | undefined,
    // additionalParams?: { [key: string]: string | number },
  ) => {
    if (!sportType || !tourYearId) return;

    const futureType = projectionType;

    publishFutureOdds(
      {
        sportType,
        tourYearId,
        futureType,
        payload: { futureType },
      },
      {
        onSettled: (_, error) => {
          if (!error) {
            enqueueSnackbar('Published odds');
            invalidateFutureOdds({ queryClient, sportType, tourYearId, futureType });
          }
        },
      },
    );
  };

  const OddsTabPanels = React.useMemo(() => {
    if (!sportType || !tourYearId) return;
    return markets && markets.length > 0
      ? markets.map((market) => (
          <MuiTabPanel key={`${sportType}_${market.name}`} value={market.name}>
            <Box>
              <FutureOddsTab
                sportType={sportType}
                tourYearId={tourYearId}
                futureType={market.name}
                handlePublishFutureOdds={handlePublishFutureOdds}
                sportTourFutures={sportTourFutures}
              />
            </Box>
          </MuiTabPanel>
        ))
      : [];
  }, [sportType, tourYearId, markets, sportTourFutures]);

  return (
    <Box sx={{ mt: 6 }}>
      <TabContext value={value}>
        <Box
          sx={{
            display: { xs: 'flex', sm: 'none' },
            flexDirection: { xs: 'column' },
            gap: { xs: '1rem', sm: 0 },
          }}
        >
          <MuiTabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Odd Tabs"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {OddsTabs}
          </MuiTabs>
          <OddTypeSwitcher />
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between' }}>
          <MuiTabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Odd Tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {OddsTabs}
          </MuiTabs>

          <OddTypeSwitcher />
        </Box>
        <ThemeProvider theme={oddsTablesTheme}>{OddsTabPanels}</ThemeProvider>
      </TabContext>
    </Box>
  );
}
