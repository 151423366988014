import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface DELETE_CLIENT {
  clientId: string;
}

const deleteClient = async ({ clientId }: DELETE_CLIENT) => {
  const { data } = await authenticatedAxios({
    method: 'DELETE',
    url: `admin/clients/${clientId}`,
  });

  return data;
};

export default function useDeleteClient() {
  return useMutation(({ clientId }: DELETE_CLIENT) => deleteClient({ clientId }));
}
