import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface OddMarketToggleProps {
  sport: string;
  marketType: string;
  eventId: string;
  heatId?: string;
  playerHeadToHeadId?: string;
  isMarketOpen: boolean;
}

const toggleOddMarkets = async (requestPayload: OddMarketToggleProps) => {
  const { sport, eventId, marketType, heatId, isMarketOpen } = requestPayload;

  await authenticatedAxios({
    method: 'PUT',
    url: `/admin/traders/${sport}/${eventId}/market/${marketType}`,
    data: { isMarketOpen, ...(heatId && { heatId }) },
  });

  return {};
};

export default function useToggleOddMarkets() {
  return useMutation((requestPayload: OddMarketToggleProps) => toggleOddMarkets(requestPayload));
}
