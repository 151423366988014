import React from 'react';
import v from 'voca';
import round from 'lodash.round';

import { Box, styled, Typography } from '@mui/material';

// import { CounterButton } from '../Markets/components';
// import { externalMarketOddsPayload } from '../Markets/marketHelper';
import { GameEvent, Summary } from '@/types/game';

import { JaiAlaiStatusValues } from '@/constants/jaialai';
import { BORDER_COLOR } from '../Markets/constants';
// JASubMarketTypes

// import { teamTypeToKey } from '../Markets/constants';

const ScoresLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
});

const CellText = styled(Typography)({
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
});

const GridCell = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.8rem',
  boxShadow: `0 0 0 1px ${BORDER_COLOR.ACTIVE}`,
});

export default function GameScores({ summary, events }: { summary: Summary; events: GameEvent }) {
  const { matches = [] } = summary || {};

  const { homeTeam, awayTeam, finalScore } = React.useMemo(() => {
    if (events) {
      const [firstEvent = {}] = events || [];
      const { teams = [] } = firstEvent;
      const homeTeam = teams.find((team: any) => team.isHomeTeam);
      const awayTeam = teams.find((team: any) => !team.isHomeTeam);

      return { homeTeam, awayTeam, finalScore: firstEvent };
    }
    return {
      homeTeam: undefined,
      awayTeam: undefined,
      finalScore: undefined,
    };
  }, [events]);

  // const { values } = formik;
  // const [firstMarketType = {}] = values?.odds || [];
  // const [firstBet = {}] = firstMarketType?.bets || [];

  // const { MONEY_LINE, SPREAD, TOTAL, OUTCOME_1, OUTCOME_2, OUTCOME_3, OUTCOME_4 } =
  //   JASubMarketTypes;

  // const COMMON_VALUE =
  //   firstBet[MONEY_LINE] ||
  //   firstBet[SPREAD] ||
  //   firstBet[TOTAL] ||
  //   firstBet[OUTCOME_1] ||
  //   firstBet[OUTCOME_2] ||
  //   firstBet[OUTCOME_3] ||
  //   firstBet[OUTCOME_4];

  // const handleExternalUpdateOdds = ({
  //   lean,
  //   isHomeTeam = true,
  // }: {
  //   lean: number;
  //   isHomeTeam: boolean;
  // }) => {
  //   const odds = values?.odds || [];
  //   const formattedData = externalMarketOddsPayload({ odds });

  //   const finalPayload = formattedData.map((data: any) => {
  //     return { ...data, lean: data.isHomeTeam === isHomeTeam ? lean : data.lean };
  //   });
  //   externalUpdateOdds({ odds: finalPayload, originalOdds: odds });
  // };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `"indicator teamNameLabel teamNameLabel scoreLabel ${matches
            ?.map((_: any, idx: number) => `event${idx + 1}`)
            .join(' ')}"
            "homeTeamIndicator  homeTeamName homeTeamName homeTeamScore ${matches
              ?.map((_: any, mId: number) => {
                return `match${mId + 1}HomeScore`;
              })
              .flat()
              .join(' ')}"
          "awayTeamIndicator awayTeamName awayTeamName awayTeamScore ${matches
            ?.map((_: any, mId: number) => {
              return `match${mId + 1}AwayScore`;
            })
            .flat()
            .join(' ')}"
           
          `,
          gridTemplateColumns: `6ch repeat(3, minmax(0, 8%))  repeat(${matches?.length}, minmax(0,12ch)) minmax(8ch, 16ch)`,
          gap: '1px',
          mb: 4,
        }}
      >
        {/* -------------- */}
        <GridCell
          sx={{
            gridArea: 'indicator',
            justifyContent: 'flex-start',
            backgroundColor: '#F8F9FA',
          }}
        ></GridCell>
        <GridCell sx={{ gridArea: 'homeTeamIndicator', justifyContent: 'flex-start' }}>
          <CellText>{v.titleCase('Home')}</CellText>
        </GridCell>
        <GridCell sx={{ gridArea: 'awayTeamIndicator', justifyContent: 'flex-start' }}>
          <CellText>{v.titleCase('Away')}</CellText>
        </GridCell>
        {/* -------------- */}

        {/* -------------- */}
        <GridCell
          sx={{
            gridArea: 'teamNameLabel',
            justifyContent: 'flex-start',
            backgroundColor: '#F8F9FA',
          }}
        >
          <ScoresLabel sx={{ color: '#02A2F2' }}>Team</ScoresLabel>
        </GridCell>
        <GridCell sx={{ gridArea: 'homeTeamName', justifyContent: 'flex-start' }}>
          <CellText sx={{ color: '#02A2F2' }}>{v.titleCase(homeTeam?.name || '')}</CellText>
        </GridCell>
        <GridCell sx={{ gridArea: 'awayTeamName', justifyContent: 'flex-start' }}>
          <CellText sx={{ color: '#02A2F2' }}>{v.titleCase(awayTeam?.name || '')}</CellText>
        </GridCell>

        {/* -------------- */}

        {/* -------------- */}
        <GridCell
          sx={{
            gridArea: 'scoreLabel',
            backgroundColor: '#F8F9FA',
          }}
        >
          <ScoresLabel sx={{ color: '#02A2F2' }}>Score</ScoresLabel>
        </GridCell>
        <GridCell sx={{ gridArea: 'homeTeamScore' }}>
          <CellText sx={{ color: '#02A2F2' }}>
            {' '}
            {round(finalScore?.homeTeamFinalScore || 0, 1).toFixed(1)}
          </CellText>
        </GridCell>
        <GridCell sx={{ gridArea: 'awayTeamScore' }}>
          <CellText sx={{ color: '#02A2F2' }}>
            {round(finalScore?.awayTeamFinalScore || 0, 1).toFixed(1)}
          </CellText>
        </GridCell>
        {/* -------------- */}

        {/* -------------- */}
        {events &&
          React.Children.toArray(
            events.map((event, idx) => (
              <GridCell
                sx={{
                  gridArea: `event${idx + 1}`,
                  //  padding: 0,
                  backgroundColor: '#F8F9FA',
                  textAlign: 'center',
                }}
              >
                <ScoresLabel>{`Match ${event?.eventNumber || 0}`}</ScoresLabel>
              </GridCell>
            )),
          )}

        {matches &&
          React.Children.toArray(
            matches?.map((match: any, mId: number) => (
              <>
                <GridCell
                  sx={{
                    gridArea: `match${mId + 1}HomeScore`,
                  }}
                >
                  <CellText>
                    {[JaiAlaiStatusValues.COMPLETED, JaiAlaiStatusValues.FORFEIT].includes(
                      match.status,
                    )
                      ? round(match?.homeTeamPoints || 0, 1).toFixed(1)
                      : '-'}
                  </CellText>
                </GridCell>

                <GridCell
                  sx={{
                    gridArea: `match${mId + 1}AwayScore`,
                  }}
                >
                  <CellText>
                    {[JaiAlaiStatusValues.COMPLETED, JaiAlaiStatusValues.FORFEIT].includes(
                      match.status,
                    )
                      ? round(match?.awayTeamPoints || 0, 1).toFixed(1)
                      : '-'}
                  </CellText>
                </GridCell>
              </>
            )),
          )}
        {/* -------------- */}

        {/* -------------- */}
        {/* <GridCell sx={{ gridArea: 'leanLabel', backgroundColor: '#F8F9FA' }}>
          <ScoresLabel>Lean</ScoresLabel>
        </GridCell>
        <GridCell sx={{ gridArea: 'leanHome' }}>
          <CounterButton
            value={Number(COMMON_VALUE?.[teamTypeToKey.homeTeam]?.lean || 0)}
            changeHandler={(val: number) =>
              handleExternalUpdateOdds({ lean: val, isHomeTeam: true })
            }
          />
        </GridCell>
        <GridCell sx={{ gridArea: 'leanAway' }}>
          <CounterButton
            value={Number(COMMON_VALUE?.[teamTypeToKey.awayTeam]?.lean || 0)}
            changeHandler={(val: number) =>
              handleExternalUpdateOdds({ lean: val, isHomeTeam: false })
            }
          />
        </GridCell> */}

        {/* -------------- */}
      </Box>
    </>
  );
}
