import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { UpdateExactasOddsPayloadDTO } from '@/types/odds/updateOdds';

interface UPDATE_EXACTAS_EVENT_ODDS {
  sport: string;
  eventId: string;
  payload: UpdateExactasOddsPayloadDTO;
}

const updateExactasEventOdds = async ({ sport, eventId, payload }: UPDATE_EXACTAS_EVENT_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/exactas/odds`,
    data: payload,
  });

  return data;
};

export default function useUpdateExactasEventOdds() {
  return useMutation(({ sport, eventId, payload }: UPDATE_EXACTAS_EVENT_ODDS) =>
    updateExactasEventOdds({ sport, eventId, payload }),
  );
}
