import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { processHeatStatus } from '@/helpers/events';

import { RoundDTO } from '@/types/round';
import { HeatDTO } from '@/types/heat';

interface HeatCardProps {
  ref: any;
  idx: number;
  heat: {
    id: string;
    name?: string;
    heatName?: string;
    heatStatus: number;
    heatNo?: number;
  };
  handleCurrentHeat: Function;
  selectedHeat: RoundDTO | HeatDTO | null;
}

const HeatCard = React.forwardRef((props: HeatCardProps, ref) => {
  const { idx, heat, handleCurrentHeat, selectedHeat } = props;
  const { status, color, icon } = processHeatStatus(heat.heatStatus);

  return (
    <Box
      ref={ref}
      sx={{
        display: 'inline-block',
        height: 'auto',
        width: '11.875rem',
        marginRight: '0.5rem',
      }}
      key={heat.id || idx}
    >
      <Paper
        onClick={() => {
          handleCurrentHeat(heat);
        }}
        sx={{
          border:
            selectedHeat && selectedHeat?.id === heat.id
              ? '0.062rem solid #6EA8FE'
              : '0.062rem solid #DEE2E6',
          backgroundColor: selectedHeat && selectedHeat?.id === heat.id ? '#E7F1FF' : '#ffffff',
          cursor: 'pointer',
          padding: '0.625rem 0.75rem',
          display: 'flex',
          gap: '0.75rem',
          boxShadow: 'none',
          borderRadius: '0.25rem',
          ':hover': {
            border: '0.062rem solid #6EA8FE',
          },
          transition: 'border 0.2s; backgroundColor 0.2s',
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
            },
          }}
        >
          {icon}
        </Box>
        <Box
          style={{
            width: '70%',
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Typography sx={{ color: 'info.main', fontSize: '0.875rem', fontWeight: '500' }}>
            {heat.name ? heat.name : `${heat?.heatName || ''} ${heat?.heatNo || ''}`}
          </Typography>
          <Typography color={color} fontSize={'0.812rem'}>
            {status}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
});
HeatCard.displayName = 'HeatCard';

export default HeatCard;
