import * as React from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

import { processRoundStatus } from '@/helpers/events';

import { RoundDTO } from '@/types/round';
import { HeatDTO } from '@/types/heat';
import { useBoolean } from '@/hooks/custom/useBoolean';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

interface RoundCardWithSwitchProps {
  ref: any;
  idx: number;
  round: {
    id: string;
    heats: [
      {
        id: string;
      },
    ];
    name: string;
    roundNo: number;
    roundStatus: number;
  };
  selectedRound: RoundDTO | HeatDTO | null;
  handleCurrentRound: Function;
  handleRoundPublish: Function;
}

const RoundCardWithSwitch = React.forwardRef((props: RoundCardWithSwitchProps, ref) => {
  const { idx, round, selectedRound, handleCurrentRound, handleRoundPublish } = props;
  const { status, color, icon } = processRoundStatus(round.roundStatus);
  const dialog = useBoolean();

  return (
    <>
      <Box
        ref={ref}
        data-testid={`${round.name}_carouselCard`}
        sx={{
          display: 'inline-block',
          height: 'auto',
          width: '11.875rem',
          marginRight: '0.5rem',
        }}
        key={round.id || idx}
      >
        <Paper
          onClick={() => handleCurrentRound(round)}
          sx={{
            border:
              selectedRound && selectedRound?.id === round.id
                ? '0.062rem solid #6EA8FE'
                : '0.062rem solid #DEE2E6',
            backgroundColor:
              selectedRound && selectedRound?.id === round.id ? '#E7F1FF' : '#ffffff',
            cursor: 'pointer',
            padding: '0.625rem 0.75rem',
            display: 'flex',
            gap: '0.75rem',
            boxShadow: 'none',
            borderRadius: '0.25rem',
            ':hover': {
              border: '0.062rem solid #6EA8FE',
            },
            transition: 'border 0.2s; backgroundColor 0.2s',
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              '& .MuiSvgIcon-root': {
                fontSize: '1.25rem',
              },
            }}
          >
            {icon}
          </Box>
          <Box
            style={{
              display: 'inline-flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography sx={{ color: 'info.main', fontSize: '0.875rem', fontWeight: '500' }}>
              {round.name}
            </Typography>
            <Typography color={color} fontSize={'0.812rem'}>
              {status}
            </Typography>
          </Box>
        </Paper>
        <Box
          sx={{
            marginTop: '0.3rem',
            paddingX: 0,
          }}
        >
          <Button
            onClick={dialog.onTrue}
            variant="text"
            disableElevation
            fullWidth
            size="small"
            color="primary"
            sx={
              [1, 2].includes(round.roundStatus)
                ? {}
                : {
                    // visibility: 'hidden',
                    display: 'none',
                  }
            }
          >
            NOTIFY
          </Button>
        </Box>
      </Box>
      <ConfirmDialog
        open={dialog.value}
        handleClose={dialog.onFalse}
        handleConfirm={() => {
          handleRoundPublish(round.id);
          dialog.onFalse();
        }}
        title={`ARE YOU SURE YOU WANT TO NOTIFY ABOUT THE ROUND?`}
        body={'This will notify about the round.'}
        cancelBtnText={'Stay Here'}
        confirmBtnText={'Confirm'}
      />
    </>
  );
});
RoundCardWithSwitch.displayName = 'RoundCardWithSwitch';

export default RoundCardWithSwitch;
