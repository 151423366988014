import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { EventScoresSkeleton } from '@/components/PageSkeletons';
import Head from '@/components/Head';
import EventBreadcrumbs from '@/components/EventBreadcrumbs';
import EventDetails from '@/components/EventDetails';

import { MASLGameScores, JAIALAIGameScores } from '@/features/scores';

import routes from '@/constants/routes';
import SPORT_TYPES from '@/constants/sportTypes';

import useSportEventGames from './useSportEventGames';

import { MASLGameProvider } from '@/contexts/maslGame.context';
import { JAIALAIGameProvider } from '@/contexts/jaialaiGame.context';
import { getAuthToken } from '@/constants/localStorageKeys';
import { disconnectSocket, initiateSocketConnection } from '@/utils/socketService';

const DEFAULT_GAME_OBJ = {
  summary: { name: '', dayStatus: 0, year: undefined, startDate: '', endDate: '', tourName: '' },
};

const SportEventGames = () => {
  const { sportType = '', gameDateId = '' } = useParams();
  // , currentTabValue, setNextTabValue
  const { game, isLoading, isFetching } = useSportEventGames();

  const token = getAuthToken();

  React.useEffect(() => {
    const namespace = sportType;
    if (token) {
      initiateSocketConnection(namespace, token);
    }

    // Cleanup on component unmount
    return () => {
      disconnectSocket(namespace);
    };
  }, [token, sportType]);

  const summary = game?.summary || DEFAULT_GAME_OBJ;
  const {
    name = '',
    dayStatus = 0,
    year = null,
    startDate = '',
    endDate = '',
    tourName = '',
  } = summary;

  if (isLoading)
    return (
      <Box>
        <Head title={name || ''} canonicalUrl={routes.sportType.eventGames.gameDateId} />
        <EventScoresSkeleton />{' '}
      </Box>
    );

  return (
    <Box>
      <Head title={name || ''} canonicalUrl={routes.sportType.eventGames.gameDateId} />
      {sportType === SPORT_TYPES.JAIALAI && (
        <JAIALAIGameProvider>
          {game && (
            <EventDetails
              event={{
                name,
                eventStatus: dayStatus,
                sportType,
                year,
                startDate,
                endDate,
                tour: { name: tourName },
              }}
              sportType={sportType}
            />
          )}
          {game && (
            <JAIALAIGameScores
              game={game}
              event={{
                id: gameDateId,
                name,
                eventStatus: dayStatus,
                sportType,
                year,
                startDate,
                endDate,
                tour: { name: tourName },
              }}
              isFetchingGame={isFetching}
            />
          )}
        </JAIALAIGameProvider>
      )}
      {sportType === SPORT_TYPES.MASL && (
        <MASLGameProvider>
          {game && (
            <EventBreadcrumbs
              event={{
                name,
                eventStatus: dayStatus,
                sportType,
                year,
                // startDate,
                // endDate,
                tour: { name: tourName },
              }}
              // sportType={sportType}
            />
          )}
          {game && (
            <MASLGameScores
              game={game}
              isFetchingGame={isFetching}
              event={{
                name,
                eventStatus: dayStatus,
                sportType,
                year,
                // startDate,
                // endDate,
                tour: { name: tourName },
              }}
            />
          )}
        </MASLGameProvider>
      )}
    </Box>
  );
};

export default SportEventGames;
