import SPORT_TYPES from '@/constants/sportTypes';

export const allowedWSLHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Stance', field: 'stance' },
  { title: 'Nationality', field: 'nationality' },
  { title: 'Gender', field: 'gender' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Tour Year', field: 'tourYear' },
  { title: 'Seed', field: 'seed' },
  { title: 'Base Projection', field: 'baseProjection' },
  { title: 'Player Status', field: 'playerStatusMod' },
  { title: 'Tier', field: 'tier' },
  { title: 'Tier Seed', field: 'tierSeed' },
  { title: 'Notes', field: 'notes' },
];

export const allowedSLSHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Stance', field: 'stance' },
  { title: 'Tour Year', field: 'leagueYear' },
  { title: 'Seed', field: 'seed' },
  { title: 'Round Score', field: 'baseRoundScore' },
  { title: 'Run Score', field: 'baseRunScore' },
  { title: 'Trick Score', field: 'baseTrickScore' },
  { title: 'Trick Completion', field: 'trickCompletion' },
  { title: 'Player Status', field: 'playerStatusMod' },
  { title: 'Notes', field: 'notes' },
];

export const allowedNRXHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Stance', field: 'stance' },
  { title: 'Nationality', field: 'nationality' },
  { title: 'Gender', field: 'gender' },
  { title: 'Seed', field: 'seed' },
  { title: 'Base Solo', field: 'baseProjection' },
  { title: 'Base H2H', field: 'baseHeadLapTime' },
  { title: 'Base Race', field: 'baseNonJokerLapTime' },
  { title: 'H2H crash rate', field: 'headCrashRate' },
  { title: 'Race crash rate', field: 'raceCrashRate' },
  { title: 'Solo crash rate', field: 'soloCrashRate' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Tour Year', field: 'tourYear' },
  { title: 'Notes', field: 'notes' },
];

export const allowedSPRHeaders = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Middle Name', field: 'middleName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Event Name', field: 'eventName' },
  { title: 'Stance', field: 'stance' },
  { title: 'Tour Year', field: 'leagueYear' },
  { title: 'Seed', field: 'seed' },
  { title: 'Round Score', field: 'baseRoundScore' },
  { title: 'Run Score', field: 'baseRunScore' },
  { title: 'Trick Score', field: 'baseTrickScore' },
  { title: 'Trick Completion', field: 'trickCompletion' },
  { title: 'Player Status', field: 'playerStatusMod' },
  { title: 'Notes', field: 'notes' },
];

export const allowedTableHeaders = {
  [SPORT_TYPES.WSL]: allowedWSLHeaders,
  [SPORT_TYPES.SLS]: allowedSLSHeaders,
  [SPORT_TYPES.NRX]: allowedNRXHeaders,
  [SPORT_TYPES.SPR]: allowedSPRHeaders,
};

interface CommonEventSeedRow {
  athlete: string;
  stance: string;
  nationality: string;
  gender: string;
  event: string;
  status: string;
  notes: string;
  seed_number: number;
}

interface WSLEventSeedRow extends CommonEventSeedRow {
  tour_year?: never;
  base_projection?: never;
  tier?: string;
  tier_seed?: string;
}

interface SLSEventSeedRow extends CommonEventSeedRow {
  base_run_score?: number;
  base_round_score?: number;
  base_trick_score?: number;
  trick_completion?: number;
  tier?: never;
  tier_seed?: never;
}

interface NRXEventSeedRow extends CommonEventSeedRow {
  tour_year?: never;
  base_solo?: number;
  base_head_to_head?: number;
  base_race?: number;
  head_to_head_crash_rate?: number;
  race_crash_rate?: number;
  solo_crash_rate?: number;
  tier?: never;
  tier_seed?: never;
}

interface SPREventSeedRow extends CommonEventSeedRow {
  test?: string;
  tier?: never;
  tier_seed?: never;
}

export type EventSeedRow = WSLEventSeedRow & SLSEventSeedRow & NRXEventSeedRow & SPREventSeedRow;

const commonRequiredKeys = [
  'athlete',
  'stance',
  'gender',
  'nationality',
  'event',
  'status',
  'seed_number',
  'tour_year',
];

export const WSLRequiredKeys = [...commonRequiredKeys, 'base_projection', 'tier', 'tier_seed'];

export const SLSRequiredKeys = [
  ...commonRequiredKeys,
  'base_run_score',
  'base_trick_score',
  'trick_completion',
];

export const NRXRequiredKeys = [
  ...commonRequiredKeys,
  'base_solo',
  'base_head_to_head',
  'base_race',
  // 'head_to_head_crash_rate',
  // 'race_crash_rate',
  // 'solo_crash_rate',
];

export const SPRRequiredKeys = [...commonRequiredKeys];

export const requiredPayloadKeys = {
  [SPORT_TYPES.WSL]: WSLRequiredKeys,
  [SPORT_TYPES.SLS]: SLSRequiredKeys,
  [SPORT_TYPES.NRX]: NRXRequiredKeys,
  [SPORT_TYPES.SPR]: SPRRequiredKeys,
};
