export enum AthleteStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  INJURED = 3,
  BANNED = 4,
  DISQUALIFIED = 5,
  SUSPENDED = 6,
  PARENTAL_LEAVE = 7,
}

type AthleteStatusKeysType = {
  [key: number]: string;
};

export const AthleteStatusKeys: AthleteStatusKeysType = {
  1: 'ACTIVE',
  2: 'INACTIVE',
  3: 'INJURED',
  4: 'BANNED',
  5: 'DISQUALIFIED',
  6: 'SUSPENDED',
  7: 'PARENTAL_LEAVE',
  8: 'REPLACEMENT',
};

type AthleteStatusValuesType = {
  [key: string]: number;
};

export const AthleteStatusValues: AthleteStatusValuesType = {
  ACTIVE: 1,
  INACTIVE: 2,
  INJURED: 3,
  BANNED: 4,
  DISQUALIFIED: 5,
  SUSPENDED: 6,
  PARENTAL_LEAVE: 7,
  REPLACEMENT: 8,
};
