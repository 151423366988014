import * as oddsHelpers from '@/helpers/odds';
import round from 'lodash.round';
import { ExactaOddRow, ExactasHeat } from '@/types/odds/getOdds';

export const initialExactasEventOddsDataLoader = ({
  newExactasEventOdds = [],
  currentExactasEventOdds = [],
  resetToggleValues = true,
  maxHoldPercentage = 200,
}: {
  newExactasEventOdds: ExactaOddRow[];
  currentExactasEventOdds?: ExactaOddRow[];
  resetToggleValues?: boolean;
  maxHoldPercentage?: number;
}) => {
  const initialData = newExactasEventOdds.map((exactasEventOdd: ExactaOddRow) => {
    let currentDataCopy = null;
    if (!resetToggleValues) {
      const currentDataCopyIndex = currentExactasEventOdds.findIndex(
        (exEventOdd: ExactaOddRow) => exactasEventOdd.id === exEventOdd.id,
      );
      if (currentDataCopyIndex !== null && currentDataCopyIndex !== -1)
        currentDataCopy = currentExactasEventOdds[currentDataCopyIndex];
    }

    exactasEventOdd?.participants?.forEach((participant) => {
      participant.label = [participant?.firstName, participant?.middleName, participant?.lastName]
        .filter(Boolean)
        .join(' ');
    });

    const [firstParticipant, secondParticipant, thirdParticipant] =
      exactasEventOdd?.participants || [];

    const maxProbability = Number(exactasEventOdd?.trueProbability) * (maxHoldPercentage / 100);

    const minDecimalOdds = oddsHelpers.getDecimalOddsFromProbability(maxProbability);
    const maxDecimalOdds = oddsHelpers.getDecimalOddsFromProbability(
      Number(exactasEventOdd?.trueProbability),
    );

    return {
      ...exactasEventOdd,
      ...(resetToggleValues && {
        editedVoid: exactasEventOdd.voided || false,
        editedDraw: exactasEventOdd?.draw || false,
      }),
      ...(!resetToggleValues && {
        editedVoid: currentDataCopy?.editedVoid || false,
        editedDraw: currentDataCopy?.editedDraw || false,
      }),
      decimalOdds: round(oddsHelpers.getDecimalOdds(Number(exactasEventOdd.odds)) || 0, 2),
      fractionalOdds: oddsHelpers.getFractionalOdds(Number(exactasEventOdd.odds)).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(exactasEventOdd?.probability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(exactasEventOdd?.probability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(exactasEventOdd?.probability)}`,
      probability: round(Number(exactasEventOdd?.probability), 2),
      maxProbability,

      firstParticipant: firstParticipant?.label || '',
      secondParticipant: secondParticipant?.label || '',
      thirdParticipant: thirdParticipant?.label || '',

      minDecimalOdds,
      maxDecimalOdds,

      minFractionalOdds: oddsHelpers.getFractionalOdds(minDecimalOdds).toString(),
      maxFractionalOdds: oddsHelpers.getFractionalOdds(maxDecimalOdds).toString(),

      minAmericanOdds:
        oddsHelpers.getAmericanOddsFromProbability(maxProbability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(maxProbability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(maxProbability)}`,
      maxAmericanOdds:
        oddsHelpers.getAmericanOddsFromProbability(Number(exactasEventOdd?.trueProbability)) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(
              Number(exactasEventOdd?.trueProbability),
            )}`
          : `${oddsHelpers.getAmericanOddsFromProbability(
              Number(exactasEventOdd?.trueProbability),
            )}`,
    };
  });
  return initialData;
};

export const sortExactasEventOdds = (headToHeads: ExactaOddRow[]) => {
  return headToHeads.sort((a: any, b: any) => {
    const rowACanEdit = !a.voided;
    const rowBCanEdit = !b.voided;
    return rowACanEdit === rowBCanEdit ? 0 : rowACanEdit ? -1 : 1;
  });
};

export const exactasEventOddsFreshWinnerDrawCount = (rows: any) => {
  if (!rows || (rows && rows.length === 0)) return 0;
  const payoutCount = rows.reduce((prevValue: number, row: any) => {
    if (!row?.draw && row.editedDraw) return ++prevValue;
    else return prevValue;
  }, 0);
  return payoutCount;
};

export const exactasEventOddsModifier = ({
  exactasEventOdd,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  exactasEventOdd: any;
  newHoldPercentage: number;
  oldHoldPercentage: number;
  defaultHoldPercentage: number;
}) => {
  // if there are no odds then return an empty array
  if (!exactasEventOdd) return {};

  // Get new probability
  const newExactasEventProbability = oddsHelpers.getNewProbabilityAfterEdit({
    probability: Number(exactasEventOdd?.probability),
    newHoldPercentage,
    oldHoldPercentage,
    defaultHoldPercentage,
  });
  // get new odds
  const newExactasEventOdd = oddsHelpers.getDecimalOddsFromProbability(+newExactasEventProbability);

  return {
    ...exactasEventOdd,
    holdingPercentage: +newHoldPercentage,
    decimalOdds: oddsHelpers.getDecimalOdds(newExactasEventOdd).toString(),
    fractionalOdds: oddsHelpers.getFractionalOdds(newExactasEventOdd).toString(),
    americanOdds:
      oddsHelpers.getAmericanOddsFromProbability(newExactasEventProbability) > 0
        ? `+${oddsHelpers.getAmericanOddsFromProbability(newExactasEventProbability)}`
        : `${oddsHelpers.getAmericanOddsFromProbability(newExactasEventProbability)}`,
    probability: round(newExactasEventProbability, 2),
    odds: newExactasEventOdd,
    // hasModifiedProbability: false,
  };
};

export const exactaEventOddsPayloadFormatter = (exactasEventOdds: ExactaOddRow[]) => {
  if (!exactasEventOdds || (exactasEventOdds && exactasEventOdds?.length === 0)) return [];
  const payload: ExactaOddRow[] = [];
  exactasEventOdds.map((data) => {
    const exactaOdd = {
      id: data?.id,
      probability: round(Number(data?.probability), 2),
      trueProbability: data?.trueProbability,
      hasModifiedProbability: data?.hasModifiedProbability,
      holdingPercentage: data?.holdingPercentage,
      odds: round(Number(data?.odds), 2),
    };
    payload.push(exactaOdd);
    return data;
  });
  return payload;
};

export const resetExactasHeat = (heat: ExactasHeat) => {
  if (heat.athletes && heat.athletes.length > 0) {
    const athletes = initialExactasEventOddsDataLoader({
      newExactasEventOdds: heat.athletes,
    });
    return { ...heat, hasBeenEdited: false, athletes };
  }
  return { ...heat, hasBeenEdited: false };
};

export const exactasHeatDataLoader = (exactasHeatOdds: any[]) => {
  // if there are no odds then return an empty array
  if (!exactasHeatOdds || (exactasHeatOdds && exactasHeatOdds.length === 0)) return [];
  const toBeModHeatWinners = JSON.parse(JSON.stringify(exactasHeatOdds));
  const modHeatWinners = toBeModHeatWinners.map((rnd: any) => {
    const heats = rnd.heats.map((heat: any) => resetExactasHeat(heat));
    return { ...rnd, heats };
  });
  return modHeatWinners;
};

export const compareExactasTypePublishTime = ({
  publishTime1,
  publishTime2,
}: {
  publishTime1?: string | null;
  publishTime2?: string | null;
}) => {
  if (!publishTime1 || !publishTime2) {
    return '';
  }
  if (publishTime1 && publishTime2) {
    return new Date(publishTime1).getTime() < new Date(publishTime2).getTime()
      ? publishTime1
      : publishTime2;
  }
};
