import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';

interface UPDATE_POSITIONAL_ODDS {
  sport: string;
  eventId: string;
  payload: UpdateOddsPayloadDTO;
}

const updatePositionalOdds = async ({ sport, eventId, payload }: UPDATE_POSITIONAL_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/odds`,
    data: payload,
  });
  return data;
};

export default function useUpdatePositionalOdds() {
  return useMutation(({ sport, eventId, payload }: UPDATE_POSITIONAL_ODDS) =>
    updatePositionalOdds({ sport, eventId, payload }),
  );
}
