import { Column } from '@material-table/core';
import { tableCellStyle } from '@/utils/TableMisc';

const TableRoundResultsColumns: Column<any>[] = [
  {
    title: 'Name',
    field: 'name',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Email',
    field: 'email',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Phone number',
    field: 'phone',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
  {
    title: 'Company',
    field: 'companyName',
    cellStyle: tableCellStyle,
    editable: 'never',
  },
];

export default TableRoundResultsColumns;
