import ODDS_TYPES from '@/constants/oddsTypes';
import { utils, writeFile } from 'xlsx';

import * as oddsHelpers from '@/helpers/odds';

export const FORMAT_TYPE = {
  _START: '_START',
  END_: 'END_',
  _BOTH_: '_BOTH_',
};

export const DEFAULT_PROBABILITY = 100;

export const customFileNameFormatter = (str: string, formatType = FORMAT_TYPE._START) => {
  if (formatType === FORMAT_TYPE._START) {
    return `_${str}`;
  }
  if (formatType === FORMAT_TYPE.END_) {
    return `${str}_`;
  }
  if (formatType === FORMAT_TYPE._BOTH_) {
    return `${str}_`;
  }
};

const americanOddsFormatter = (odds: number) => {
  return odds > 0 ? `+${odds}` : `${odds}`;
};

export const excelOddsConverter = ({ oddTypeSelected, odds, probability = 0 }: any) => {
  let convertedOdds: string | number = 0;
  switch (oddTypeSelected) {
    case ODDS_TYPES.DECIMAL:
      convertedOdds = isNaN(oddsHelpers.getDecimalOdds(odds))
        ? '0'
        : oddsHelpers.getDecimalOdds(odds).toString();
      break;
    case ODDS_TYPES.FRACTIONAL:
      convertedOdds = oddsHelpers.getFractionalOdds(odds).toString().replaceAll(' ', '');
      break;
    case ODDS_TYPES.AMERICAN:
      convertedOdds = !probability
        ? americanOddsFormatter(oddsHelpers.getAmericanOdds(odds))
        : americanOddsFormatter(oddsHelpers.getAmericanOddsFromProbability(probability));
      break;
    default:
      break;
  }

  return convertedOdds;
};

export const exportToExcel = ({
  excelAoA,
  excelFileName,
  title,
}: {
  excelAoA: any;
  excelFileName: string;
  title: string;
}) => {
  const ws = utils.aoa_to_sheet(excelAoA);
  const noOfColumns = utils.decode_range(ws['!ref']).e.c + 1; // Total number of cols.
  const wsColWidths = Array(noOfColumns)
    .fill(0)
    .map(() => ({ wch: 20 })); // Cell width for each col.
  ws['!cols'] = wsColWidths; // Assign cell widths to sheet.
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, title);
  writeFile(wb, excelFileName.replace(/ /g, '_').replace(/[ ,]/g, '_'));
};
