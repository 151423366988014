import { atom } from 'recoil';

const unsavedChangesState = atom({
  key: 'unsavedChangesState',
  default: {
    isChangeUnsaved: false,
    isTabNavigation: false,
  },
});

export { unsavedChangesState };
