import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface UPDATE_POWERSLAP_ROUNDS {
  sport: string;
  eventId: string;
  roundId: string;
  requestPayload: any;
}

const updatePOWERSLAPRounds = async ({
  sport,
  eventId,
  roundId,
  requestPayload,
}: UPDATE_POWERSLAP_ROUNDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/traders/${sport}/${eventId}/round/${roundId}`,
    data: requestPayload,
  });

  return data;
};

export default function useUpdatePOWERSLAPRounds() {
  return useMutation(({ sport, eventId, roundId, requestPayload }: UPDATE_POWERSLAP_ROUNDS) =>
    updatePOWERSLAPRounds({ sport, eventId, roundId, requestPayload }),
  );
}
