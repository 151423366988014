import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { PublishFutureOddsPayloadDTO } from '@/types/helpers/futureOdds';

interface PUBLISH_ODDS {
  sportType: string;
  tourYearId: string;
  futureType: string;
  payload: PublishFutureOddsPayloadDTO;
}

const publishFutureOdds = async ({ sportType, tourYearId, futureType, payload }: PUBLISH_ODDS) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sportType}/futures/tour/${tourYearId}/${futureType}/odds/public`,
    data: payload,
  });

  return data;
};

export default function usePublishFutureOdds() {
  return useMutation(({ sportType, tourYearId, futureType, payload }: PUBLISH_ODDS) =>
    publishFutureOdds({ sportType, tourYearId, futureType, payload }),
  );
}
