import { useMutation } from '@tanstack/react-query';

import { WSLTraderEventPointsAddDTO } from '@/types/wsl/trader';
import { SLSTraderEventPointsAddDTO } from '@/types/sls/trader';
import { NRXTraderEventPointsAddDTO } from '@/types/nrx/trader';

import { authenticatedAxios } from '../../';

const addTraderEventPoints = async ({
  sport,
  requestPayload,
}: {
  sport: string;
  requestPayload: {
    items: WSLTraderEventPointsAddDTO | SLSTraderEventPointsAddDTO | NRXTraderEventPointsAddDTO;
  };
}) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/results`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderEventPoints() {
  return useMutation(
    ({
      sport,
      requestPayload,
    }: {
      sport: string;
      requestPayload: {
        items: WSLTraderEventPointsAddDTO | SLSTraderEventPointsAddDTO | NRXTraderEventPointsAddDTO;
      };
    }) => addTraderEventPoints({ sport, requestPayload }),
  );
}
