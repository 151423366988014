export const styles = {
  contentProps: {
    padding: '0.345rem 0.25rem',
    borderRadius: '0.25rem',
  },
  sportsContentProps: {
    paddingLeft: '0.625rem',
  },
  treeItem: {
    '.Mui-focused': {
      backgroundColor: 'transparent',
    },
    '.MuiTreeItem-content:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  sportsTreeItem: {
    margin: '0 0.5rem',
    '.MuiTreeItem-group': {
      marginLeft: '0.625rem',
      borderLeft: 'dashed 1px #9b9b9b',
    },
    '.MuiTreeItem-content:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  eventsTreeItem: {
    '.MuiTreeItem-iconContainer': {
      display: 'none',
    },
  },
  treeItemSelected: {
    '.Mui-selected': {
      backgroundColor: 'transparent !important',
    },
  },
  sportsLabel: {
    padding: '0.345rem 0.5rem',
    paddingLeft: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  toursOrLeaguesLabel: {
    fontSize: '0.812rem',
    fontWeight: '400',
    color: 'info.main',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  eventYearLabel: {
    fontSize: '0.812rem',
    fontWeight: '400',
    color: 'info.main',
  },
  eventLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  eventNameLabel: {
    fontSize: '0.812rem',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  eventStatusLabel: {
    flex: '1 0 15%',
    textAlign: 'right',
    fontSize: '0.625rem',
    fontWeight: '700',
  },
  sportsItemIcon: {
    width: '1.5rem',
    imageRendering: '-webkit-optimize-contrast',
  },
};
