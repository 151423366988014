import React, { useState, useEffect, useMemo } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { oddsType } from '@/atoms/oddsType';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
// import { EventListingSkeleton } from '@/components/PageSkeletons';
import useFetchExactaHeatOdds from '@/hooks/odds/exactas/exactasHeatOdds/useFetchExactasHeatOdds';
import useUpdateExactasEventOdds from '@/hooks/odds/exactas/exactasEventOdds/useUpdateExactasEventOdds';
import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';
import useUpdateExactaHeatOddsVoid from '@/hooks/odds/exactas/exactasHeatOdds/useUpdateExactasHeatOddsVoid';

import { sortRoundsByRoundNo } from '@/helpers/rounds';
import { sortHeatsByHeatNo } from '@/helpers/heats';
import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import { invalidateExactasHeatOdds } from '@/helpers/cachedQueries';
import { displayInPT as format } from '@/helpers/timeConverters';

import {
  exactasHeatDataLoader,
  resetExactasHeat,
  exactaEventOddsPayloadFormatter,
  compareExactasTypePublishTime,
} from '@/helpers/exactas';
import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import * as httpStatusCodes from '@/constants/httpStatus';
import {
  SKIP_SCORE_FORM_ROUND_STATUSES,
  SKIP_SCORE_FORM_HEAT_STATUSES,
} from '@/constants/skipStatuses';
import { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';
import { UpdateExactasOddsPayloadDTO } from '@/types/odds/updateOdds';
import { HEAT_VIEW_MODES } from '@/constants/heats';
import ExactasHeatOddsDefaultView from '@/features/odds/Tabs/Exactas/ExactasHeatTab/ExactasHeatOddsDefaultView';
import { EXACTAS_TYPE } from '@/constants/oddTabs';
import ExactasHeatOddsListView from '@/features/odds/Tabs/Exactas/ExactasHeatTab/ExactasHeatOddsListView';
import HeatViewToggle from '@/components/HeatViewToggle';
import RoundDetails from '@/components/RoundDetails';
import Slider from '@/components/Slider';
interface ExactaHeatTabProps {
  sport: string;
  eventId: string;
  handlePublishOdds: (
    oddsProjectionType: string,
    roundHeatId: string | null | undefined,
    roundId?: string | null | undefined,
  ) => void;
}
const ExactasHeatTab = ({ sport, eventId, handlePublishOdds }: ExactaHeatTabProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [heatViewMode, setHeatViewMode] = useState<number>(HEAT_VIEW_MODES.default);
  const exactasHeatOddsKey: {
    sport: string;
    eventId: string;
    exactasType: number;
    params?: { query: string; page: number; sortColumn: string; sortOrder: string };
  } = { sport, eventId, exactasType: EXACTAS_TYPE[2] };
  // if (HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)) {
  //   exactasEventOddsKey.params = { page, query, ...sort };
  // }
  const { data: oddsData } = useFetchExactaHeatOdds(exactasHeatOddsKey);
  const exactasHeatTrifectaOddsKey: {
    sport: string;
    eventId: string;
    exactasType: number;
    params?: { query: string; page: number; sortColumn: string; sortOrder: string };
  } = { sport, eventId, exactasType: EXACTAS_TYPE[3] };
  // if (HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)) {
  //   exactasEventOddsKey.params = { page, query, ...sort };
  // }
  const { data: oddsTrifectaData } = useFetchExactaHeatOdds(exactasHeatTrifectaOddsKey);
  const { data: athletes } = useFetchParticipants(sport, eventId);
  const [oddType] = useRecoilState(oddsType);
  const [roundList, setRoundList] = useState<any[]>([]);
  const [selectedRoundId, setSelectedRoundId] = useState<string>('');
  const [selectedHeatId, setSelectedHeatId] = useState<string>('');
  const [selectedToBeVoidedHeatId, setSelectedToBeVoidedHeatId] = useState<string>('');
  const [selectedToBeResetHeatId, setSelectedToBeResetHeatId] = useState<string>('');
  const [currentHeatSelection, setCurrentHeatSelection] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const { mutateAsync: updateExactasEventOdds } = useUpdateExactasEventOdds();
  const { mutate: updateExactasHeatOddsVoid } = useUpdateExactaHeatOddsVoid();
  const queryClient = useQueryClient();
  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);
  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);
  const handleToggleEditConfirmDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);
  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);

  const playerDropdownOptions = useMemo(() => {
    const modifiedAthletes = athletes
      ? athletes?.map((player: any) => {
          return {
            label: [
              player?.athlete?.firstName,
              player?.athlete?.middleName,
              player?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
            eventParticipantId: player?.id || '',
            athleteId: player?.athlete?.id || '',
            ...player.athlete,
          };
        })
      : [];
    return modifiedAthletes;
  }, [JSON.stringify(athletes)]);

  const refreshExactasHeatOdds = () => {
    invalidateExactasHeatOdds(
      queryClient,
      sport,
      eventId,
      EXACTAS_TYPE[2],
      undefined,
      // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
      //   ? filterObj({ page, query, ...sort })
      //   : undefined,
    );

    invalidateExactasHeatOdds(
      queryClient,
      sport,
      eventId,
      EXACTAS_TYPE[3],
      undefined,
      // HEAD_TO_HEAD_ODDS_ALLOWED_PAGINATION_SPORTS.includes(sport)
      //   ? filterObj({ page, query, ...sort })
      //   : undefined,
    );
  };
  const findRound = (roundId: string, rounds: any[]) => {
    if (!roundId || rounds.length === 0) return null;
    if (roundId && rounds.length > 0) {
      return rounds.find((round) => round.id === roundId) || null;
    }
  };
  const selectedRound = useMemo(
    () => findRound(selectedRoundId, roundList),
    [selectedRoundId, roundList],
  );
  const findHeat = (heatId: string, round: any) => {
    if (!heatId || !round) return null;
    if (heatId && round) {
      const heats = round.heats;
      if (heats.length === 0) return null;
      return heats.find((heat: any) => heat.id === heatId) || null;
    }
  };
  const selectedHeat = useMemo(
    () => findHeat(selectedHeatId, selectedRound),
    [selectedHeatId, selectedRound],
  );
  const toBeVoidedHeat = useMemo(() => {
    if (!selectedToBeVoidedHeatId) return null;
    if (selectedToBeVoidedHeatId) {
      const heats = selectedRound.heats;
      if (heats.length === 0) return null;
      return heats.find((heat: any) => heat.id === selectedToBeVoidedHeatId) || null;
    }
  }, [selectedToBeVoidedHeatId]);
  const configureHeats = (heats: any[], currentlySelectedHeatId: string) => {
    if (currentlySelectedHeatId) {
      setSelectedHeatId(currentlySelectedHeatId);
      return;
    }
    // Filter heats
    const modHeats = sortHeatsByHeatNo(heats);
    const firstHeat = modHeats.find(
      (heat: any) => !SKIP_SCORE_FORM_HEAT_STATUSES.includes(heat.heatStatus),
    );

    if (firstHeat) {
      setSelectedHeatId(firstHeat?.id);
    } else {
      const [alternateFirstHeat] = modHeats;
      if (alternateFirstHeat) {
        setSelectedHeatId(alternateFirstHeat?.id);
      }
    }
  };
  const configureRound = (rounds: any[]) => {
    const firstRound = rounds.find(
      (round: any) => !SKIP_SCORE_FORM_ROUND_STATUSES.includes(round.roundStatus),
    );

    if (firstRound) {
      setSelectedRoundId(firstRound?.id);
      if (firstRound?.heats) {
        configureHeats(firstRound?.heats || [], '');
      }
    } else {
      const [alternateFirstRound] = rounds;
      if (alternateFirstRound) {
        setSelectedRoundId(alternateFirstRound.id);
        if (alternateFirstRound?.heats) {
          configureHeats(alternateFirstRound?.heats || [], '');
        }
      }
    }
  };

  useEffect(() => {
    if (eventId) {
      refreshExactasHeatOdds();
      if (selectedRoundId) setSelectedRoundId('');
      if (selectedHeatId) setSelectedHeatId('');
    }
  }, [eventId]);
  useEffect(() => {
    if (oddsData || oddsTrifectaData) {
      const allExactas = oddsData?.odds?.map((rnd, rndIdx) => {
        rnd.heats = rnd.heats.map((heat, heatIdx) => {
          const triRnd = oddsTrifectaData?.odds?.[rndIdx];
          const triHeat = triRnd?.heats[heatIdx];
          heat.clientUpdatedAtDate = compareExactasTypePublishTime({
            publishTime1: heat?.clientUpdatedAtDate as string,
            publishTime2: triHeat?.clientUpdatedAtDate as string,
          });
          heat.traderUpdatedAtDate = compareExactasTypePublishTime({
            publishTime1: heat?.traderUpdatedAtDate as string,
            publishTime2: triHeat?.traderUpdatedAtDate as string,
          });
          heat.athletes.push(...(triHeat?.athletes || []));
          return heat;
        });
        return rnd;
      });
      const sortedRounds = sortRoundsByRoundNo(allExactas as any[]);
      const modRounds = exactasHeatDataLoader(sortedRounds);
      setRoundList(modRounds);
    }
  }, [JSON.stringify(oddsData), JSON.stringify(oddsTrifectaData)]);
  useEffect(() => {
    if (roundList.length > 0 && !selectedHeatId) configureRound(roundList);
  }, [JSON.stringify(roundList), selectedHeatId]);
  const saveExactaHeatsOddsData = async (
    payload: UpdateExactasOddsPayloadDTO,
    message = 'Successfully updated odds',
  ) => {
    const response = await updateExactasEventOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          refreshExactasHeatOdds();
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };
  const markHeatAsVoid = (heatId = '', message = 'Successfully voided heat') => {
    updateExactasHeatOddsVoid(
      { sport, eventId, heatId },
      {
        onSettled: (_, error) => {
          if (!error) {
            enqueueSnackbar(message);
            refreshExactasHeatOdds();
          }
        },
      },
    );
  };
  const handleCurrentRound = (round: any) => {
    setSelectedRoundId(round?.id);
    configureHeats(round?.heats || [], '');
  };
  const handleCurrentHeat = (heat: any) => {
    configureHeats(selectedRound?.heats, heat?.id || '');
  };
  // Function for updating local heat winner odds
  const changeExactasHeat = (
    roundId: string,
    heatId: string,
    athletes: any[],
    hasBeenEdited = true,
  ) => {
    // copy the existing rounds
    const newRoundList = JSON.parse(JSON.stringify(roundList));
    // Check if round exist
    const roundIdx = newRoundList.findIndex((round: any) => round?.id == roundId);
    if (roundIdx !== -1) {
      const round = newRoundList[roundIdx];
      // Check if heat exist
      const heatIdx = round.heats.findIndex((heat: any) => heat?.id == heatId);
      if (heatIdx !== -1) {
        const heat = round.heats[heatIdx];
        // assign newly updated athletes odds
        heat.athletes = athletes;
        if (!heat.hasBeenEdited) heat.hasBeenEdited = hasBeenEdited;
        round.heats[heatIdx] = heat;
        const heats = round.heats;
        // sort the heats
        const sortedHeats = sortHeatsByHeatNo(heats);
        // assign to current round heats
        round.heats = sortedHeats;
        newRoundList[roundIdx] = round;
        // sort the rounds
        const sortedRounds = sortRoundsByRoundNo(newRoundList);
        setRoundList(sortedRounds);
      }
    }
  };
  const updateOddsData = async ({ heat }: { heat: any }) => {
    const payload = exactaEventOddsPayloadFormatter(heat.athletes);
    const responseStatus = await saveExactaHeatsOddsData({ items: payload });
    if (responseStatus === httpStatusCodes.OK && heat?.hasBeenEdited) {
      changeExactasHeat(selectedRoundId, heat.id, heat.athletes, false);
    }
  };
  const setInitialOddsData = (heatId: string) => {
    const round = findRound(selectedRoundId, oddsData?.odds || []);
    const heat = findHeat(heatId, round);
    const initialHeat = resetExactasHeat(heat);
    changeExactasHeat(selectedRoundId, heatId, initialHeat.athletes, false);
  };
  const handleVoidHeat = (heatId: string) => {
    setSelectedToBeVoidedHeatId(heatId);
    handleToggleConfirmDialog();
  };
  const handleRoundPublish = (roundId: string) => {
    handlePublishOdds(ODDS_PROJECTION_TYPE.exactasHeatProjections, undefined, roundId);
  };
  const exportHandler = ({ heat }: { heat: any }) => {
    const modData = JSON.parse(JSON.stringify(heat?.athletes || []));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType: ODD_DATA_TYPES.EXACTAS_HEAT,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
      };
      const lastItemUpdatedAt = selectedHeat?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt.toString() as string), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  // if (!oddsData?.odds) return <EventListingSkeleton />;
  return (
    <>
      <HeatViewToggle heatViewMode={heatViewMode} setHeatViewMode={setHeatViewMode} />
      <Slider
        sliderItems={roundList}
        selectedItem={selectedRound}
        handleItemSelect={handleCurrentRound}
        handleRoundPublish={handleRoundPublish}
        itemType={'roundsExactaHeatOdd'}
      />
      {selectedRound && (
        <>
          <RoundDetails selectedRound={selectedRound} />
          {heatViewMode === HEAT_VIEW_MODES.default && (
            <>
              <Slider
                sliderItems={sortHeatsByHeatNo(selectedRound.heats)}
                selectedItem={selectedHeat}
                handleItemSelect={handleCurrentHeat}
                itemType={'heatsExactaHeatOdd'}
                handleVoidHeat={handleVoidHeat}
              />
              <ExactasHeatOddsDefaultView
                selectedRoundId={selectedRoundId}
                selectedHeat={selectedHeat}
                changeExactasHeat={changeExactasHeat}
                handlePublishOdds={handlePublishOdds}
                exportHandler={exportHandler}
                setSelectedToBeResetHeatId={setSelectedToBeResetHeatId}
                setCurrentHeatSelection={setCurrentHeatSelection}
                handleToggleEditConfirmDialog={handleToggleEditConfirmDialog}
                handleToggleResetDialog={handleToggleResetDialog}
                sport={sport}
                eventId={eventId}
                playerDropdownOptions={playerDropdownOptions}
              />
            </>
          )}
          {heatViewMode === HEAT_VIEW_MODES.list && (
            <div>
              {selectedRound.heats &&
                React.Children.toArray(
                  sortHeatsByHeatNo(selectedRound.heats).map((heat: any) => (
                    <ExactasHeatOddsListView
                      selectedRoundId={selectedRoundId}
                      selectedHeat={heat}
                      changeExactasHeat={changeExactasHeat}
                      handlePublishOdds={handlePublishOdds}
                      exportHandler={exportHandler}
                      handleVoidHeat={handleVoidHeat}
                      setSelectedToBeResetHeatId={setSelectedToBeResetHeatId}
                      setCurrentHeatSelection={setCurrentHeatSelection}
                      handleToggleEditConfirmDialog={handleToggleEditConfirmDialog}
                      handleToggleResetDialog={handleToggleResetDialog}
                      sport={sport}
                      eventId={eventId}
                      playerDropdownOptions={playerDropdownOptions}
                    />
                  )),
                )}
            </div>
          )}
        </>
      )}
      <ConfirmDialog
        open={isDialogOpen}
        handleClose={() => {
          handleToggleConfirmDialog();
          setSelectedToBeVoidedHeatId('');
        }}
        handleConfirm={() => {
          handleToggleConfirmDialog();
          markHeatAsVoid(selectedToBeVoidedHeatId);
          setSelectedToBeVoidedHeatId('');
        }}
        title={`ARE YOU SURE YOU WANT TO VOID ${v.upperCase(toBeVoidedHeat?.name)}?`}
        body={`This heat will be voided.`}
      />
      <ConfirmDialog
        open={showOddsUpdateDialog}
        handleClose={() => {
          handleToggleEditConfirmDialog();
          setCurrentHeatSelection(null);
        }}
        handleConfirm={() => {
          updateOddsData({ heat: currentHeatSelection });
          handleToggleEditConfirmDialog();
          setCurrentHeatSelection(null);
        }}
        title={`ARE YOU SURE YOU WANT TO UPDATE THE ${v.upperCase(
          currentHeatSelection?.name || '',
        )} ODDS?`}
        body={'This will update the odds.'}
      />
      <ConfirmDialog
        open={showResetOddsDialog}
        handleClose={() => {
          handleToggleResetDialog();
          setSelectedToBeResetHeatId('');
        }}
        handleConfirm={() => {
          setInitialOddsData(selectedToBeResetHeatId);
          handleToggleResetDialog();
          setSelectedToBeResetHeatId('');
        }}
        title={`ARE YOU SURE YOU WANT TO RESET THE ${v.upperCase(
          (selectedRound &&
            selectedRound?.heats.find((heat: any) => heat.id === selectedToBeResetHeatId)?.name) ||
            '',
        )} ODDS?`}
        body={'This will reset the odds.'}
      />
    </>
  );
};

export default ExactasHeatTab;
