import React from 'react';
import { Button } from '@mui/material';

const ResetOddsBtn = ({
  text,
  disabled,
  resetOddsHandler,
}: {
  text?: string;
  disabled: boolean;
  resetOddsHandler: any;
}) => {
  return (
    <Button
      id="resetOddsBtn"
      color="neutral"
      disableElevation
      variant="contained"
      disabled={disabled}
      onClick={resetOddsHandler}
      sx={{
        fontWeight: 500,
        fontSize: '0.625rem',
        paddingY: '0.516rem',
        minWidth: '3.875rem',
        marginRight: '1.25rem',
      }}
    >
      {text ? text : 'RESET'}
    </Button>
  );
};

export default ResetOddsBtn;
