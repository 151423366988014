import { useState, useEffect } from 'react';
import v from 'voca';

import useFetchMatchResults from '@/hooks/results/useFetchMatchResults';
import useFetchRoundResults from '@/hooks/results/useFetchRoundResults';
import { getNumberFromCharacters } from '@/helpers/misc';
import { format } from 'date-fns';

const matchResultsFormatter = (result: any) => {
  return {
    ...result,
    weightClass: result?.weightClass || '',
    coinTossWinnerWinsMatch: result?.coinTossWinnerWinsMatch ? 'Yes' : 'No',
    coinTossWinnerWinsByFirstRound: result?.coinTossWinnerWinsByFirstRound ? 'Yes' : 'No',
    date: result?.date ? format(new Date(result?.date), 'MM-dd-yyyy') : '',
  };
};

const roundResultsFormatter = (result: any) => {
  return {
    ...result,
    corner: result?.corner ? v.titleCase(result.corner) : '',
    other: result?.other ? v.titleCase(result.other) : '',
    coinTossWinnerWinsByFirstRound: result?.coinTossWinnerWinsByFirstRound ? 'Yes' : 'No',
    kd: result?.kd ? 'Yes' : 'No',
    step: result?.step ? 'Yes' : 'No',
    stagger: result?.stagger ? 'Yes' : 'No',
    round: getNumberFromCharacters(result?.round || ''),
    date: result?.date ? format(new Date(result?.date), 'MM-dd-yyyy') : '',
  };
};

const useResults = ({ sportType }: { sportType: string }) => {
  const [modMatchResults, setModMatchResults] = useState<any[]>([]);
  const [modRoundResults, setModRoundResults] = useState<any[]>([]);

  const { data: matchResults } = useFetchMatchResults(sportType);
  const { data: roundResults } = useFetchRoundResults(sportType);

  useEffect(() => {
    if (matchResults && matchResults.length > 0) {
      setModMatchResults(matchResults.map(matchResultsFormatter));
    }
  }, [matchResults]);

  useEffect(() => {
    if (roundResults && roundResults.length > 0) {
      setModRoundResults(roundResults.map(roundResultsFormatter));
    }
  }, [roundResults]);

  return {
    modMatchResults,
    modRoundResults,
  };
};

export default useResults;
