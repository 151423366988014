import React, { useState } from 'react';
import MaterialTable, { Column } from '@material-table/core';
import voca from 'voca';

import { Tab, Box, styled, Typography, Button } from '@mui/material';
import { TabPanel, TabList, TabContext } from '@mui/lab';
import { ThemeProvider } from '@mui/material/styles';

import DownloadIcon from '@mui/icons-material/Download';

import { oddsTablesTheme } from '@/constants/theme';

import { tableIcons, defaultTableOptions, createTablePageOptions } from '@/utils/TableMisc';

import useResults from './useResults';
import TableMatchResultsColumns from './TableMatchResultsColumns';
import TableRoundResultsColumns from './TableRoundResultsColumns';
import { excelFileDownloader } from '@/helpers/fileDownloader';

const MuiTabs = styled(TabList)({
  '& .MuiTabs-root': {
    backgroundColor: 'red',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#ffff',
  },
});

const MuiTab = styled(Tab)({
  '&.MuiTab-root': {
    fontWeight: 'bold',
    fontSize: '0.8rem',
    color: '#6C757D',
    backgroundColor: '#F1F2F4',
    height: '3.5rem',
    textTransform: 'none',
  },
  '&.Mui-selected': {
    color: `#0D6EFD`,
    backgroundColor: '#ffffff',
    position: 'relative',
    zIndex: 20,
    border: '5px solid #F1F2F4',
    borderRadius: '4px',
  },
  '&.MuiTab-wrapped': {
    textTransform: 'none',
  },
});

const MuiTabPanel = styled(TabPanel)({
  padding: '1rem 0',
  maxWidth: '100%',
});

const MuiTabLabelText = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  fontWeight: 500,
});

interface FIGHT_RESULTS_PROPS {
  sportType: string;
}

const TAB_OPTIONS = [
  { label: 'Match Results', value: '1' },
  { label: 'Round Results', value: '2' },
];

const RESULT_TYPE = {
  match: 'Match',
  round: 'Round',
};

export default function FightResults({ sportType }: FIGHT_RESULTS_PROPS) {
  const [value, setValue] = useState('1');

  const { modMatchResults, modRoundResults } = useResults({ sportType: sportType });

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const OddsTabs = React.useMemo(
    () =>
      TAB_OPTIONS?.map((tab) => {
        const label = (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              gap: 0.2,
            }}
          >
            <MuiTabLabelText>{voca.titleCase(tab.label)}</MuiTabLabelText>
          </Box>
        );
        return <MuiTab key={`${sportType}_${tab.label}`} value={tab.value} label={label} />;
      }),
    [TAB_OPTIONS, sportType],
  );

  const handleDownloadResults = (resultType: string) => {
    let columns: Column<any>[] = [];
    let data: any[] = [];

    if (resultType === RESULT_TYPE.match) {
      columns = TableMatchResultsColumns;
      data = [...modMatchResults];
    } else if (resultType === RESULT_TYPE.round) {
      columns = TableRoundResultsColumns;
      data = [...modRoundResults];
    }

    const headers = columns.map((col) => col?.title ?? '');
    const rows = data.map((row) => columns.map((col) => row[col.field as keyof typeof row] ?? ''));

    excelFileDownloader({
      fileName: `${resultType} Results`,
      fileType: 'xlsx',
      headers,
      rows,
    });
  };

  return (
    <TabContext value={value}>
      <Box
        sx={{
          display: { xs: 'flex', sm: 'none' },
          flexDirection: { xs: 'column' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <MuiTabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="Odd Tabs"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {OddsTabs}
        </MuiTabs>
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'space-between' }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="Odd Tabs"
          variant="scrollable"
          scrollButtons="auto"
        >
          {OddsTabs}
        </MuiTabs>
      </Box>
      <ThemeProvider theme={oddsTablesTheme}>
        <MuiTabPanel value={TAB_OPTIONS[0].value}>
          <Box mb={2}>
            <Button
              startIcon={<DownloadIcon />}
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => handleDownloadResults(RESULT_TYPE.match)}
            >
              {`Download ${TAB_OPTIONS[0].label}`}
            </Button>
          </Box>
          <MaterialTable
            icons={tableIcons}
            columns={TableMatchResultsColumns}
            data={modMatchResults}
            options={{
              toolbar: false,
              // header: false,
              actionsColumnIndex: -1,
              rowStyle: {
                overflowWrap: 'break-word',
                whiteSpace: 'nowrap',
              },
              headerStyle: {
                whiteSpace: 'nowrap',
              },
              thirdSortClick: false,
              sorting: true,
              ...defaultTableOptions,
              pageSize: createTablePageOptions(modMatchResults?.length || 0).pageSize,
              pageSizeOptions: createTablePageOptions(modMatchResults?.length || 0).pageSizeOptions,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
          />
        </MuiTabPanel>
        <MuiTabPanel value={TAB_OPTIONS[1].value}>
          <Box mb={2}>
            <Button
              startIcon={<DownloadIcon />}
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => handleDownloadResults(RESULT_TYPE.round)}
            >
              {`Download ${TAB_OPTIONS[1].label}`}
            </Button>
          </Box>
          <MaterialTable
            icons={tableIcons}
            columns={TableRoundResultsColumns}
            data={modRoundResults}
            options={{
              toolbar: false,
              // header: false,
              actionsColumnIndex: -1,
              rowStyle: {
                overflowWrap: 'break-word',
                whiteSpace: 'nowrap',
              },
              headerStyle: {
                whiteSpace: 'nowrap',
              },
              thirdSortClick: false,
              sorting: true,
              ...defaultTableOptions,
              pageSize: createTablePageOptions(modRoundResults?.length || 0).pageSize,
              pageSizeOptions: createTablePageOptions(modRoundResults?.length || 0).pageSizeOptions,
            }}
            localization={{
              header: {
                actions: '',
              },
            }}
          />
        </MuiTabPanel>
      </ThemeProvider>
    </TabContext>
  );
}
