import React from 'react';
import { Button } from '@mui/material';

const SaveOddsBtn = ({
  disabled,
  saveOddsHandler,
}: {
  disabled: boolean;
  saveOddsHandler: any;
}) => {
  return (
    <Button
      id="saveOddsBtn"
      disableElevation
      variant="contained"
      disabled={disabled}
      onClick={saveOddsHandler}
      sx={{
        fontWeight: 500,
        fontSize: '0.625rem',
        paddingY: '0.516rem',
        minWidth: '3.875rem',
      }}
    >
      SAVE
    </Button>
  );
};

export default SaveOddsBtn;
