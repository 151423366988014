import groupBy from 'lodash.groupby';
import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';

import { teamTypeToKey } from './constants';
import { JABetTypes, JAMarketTypes } from '@/constants/jaialai';
import { MAX_PROBABILITY } from '@/constants/oddRanges';
import cloneDeep from 'lodash.clonedeep';

const formatOddsAndIdentifyTeams = ({ data }: { data: any; maxHoldPercentage: number }) => {
  if (!data || !data?.odds?.length) return [];
  const formattedTeamOdds = data?.odds?.map((odd: any) => {
    const { team } = odd;
    const isHomeTeam = team.isHomeTeam;
    const athlete1 = team?.athlete1?.firstName || null;
    const athlete2 = team?.athlete2?.firstName || null;
    const athleteName = [athlete1, athlete2].filter(Boolean).join(' & ');
    const providerRoundId = odd?.round?.providerRoundId;
    const providerAthlete1Id = team?.athlete1?.providerAthleteId;
    const providerAthlete2Id = team?.athlete2?.providerAthleteId;
    const homeTeamProviderAthlete1Id = isHomeTeam ? providerAthlete1Id : null;
    const homeTeamProviderAthlete2Id = isHomeTeam ? providerAthlete2Id : null;
    const awayTeamProviderAthlete1Id = !isHomeTeam ? providerAthlete1Id : null;
    const awayTeamProviderAthlete2Id = !isHomeTeam ? providerAthlete2Id : null;
    return {
      ...odd,
      isHomeTeam,
      athleteName,
      decimalOdds: round(oddsHelpers.getDecimalOdds(odd.odds) || 0, 2),
      fractionalOdds: oddsHelpers.getFractionalOdds(odd.odds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(odd.probability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(odd.probability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(odd.probability)}`,
      probability: round(odd.probability, 2),
      baseLean: Number(odd?.lean || 0),
      athlete1,
      athlete2,
      providerRoundId,
      homeTeamProviderAthlete1Id,
      homeTeamProviderAthlete2Id,
      awayTeamProviderAthlete1Id,
      awayTeamProviderAthlete2Id,
    };
  });

  return formattedTeamOdds;
};

export const formatMarketData = ({
  data,
  maxHoldPercentage,
}: {
  data: any;
  maxHoldPercentage: number;
}) => {
  if (!data) return [];
  const formattedTeamOdds = formatOddsAndIdentifyTeams({
    data,
    maxHoldPercentage,
  });
  /* Group by market type */
  const groupByMarketType = groupBy(formattedTeamOdds, 'marketType');

  const groupByMarketTypeFiltered = Object.keys(groupByMarketType).map((marketType) => {
    const groupByBetType =
      +marketType === JAMarketTypes.SERVE
        ? groupBy(
            groupByMarketType[marketType]?.sort((a, b) => {
              const roundComparison = +a?.round?.name - +b?.round?.name;
              if (roundComparison !== 0) {
                return roundComparison;
              }

              return a?.points - b?.points;
            }),
            (item) =>
              `MATCH ${+item?.round?.eventNumber || ''}, SET ${
                +item?.round?.name || ''
              }, POINT ${+item?.points}`,
          )
        : groupBy(groupByMarketType[marketType], 'betType');

    const groupByBetTypeFiltered = Object.keys(groupByBetType).map((betType) => {
      const filteredBetType = groupByBetType[betType];

      const groupBySubMarketType = groupBy(filteredBetType, 'subMarketType');

      const groupBySubMarketTypeFiltered = Object.keys(groupBySubMarketType)
        .map((subMarketType) => {
          const filteredSubMarketType = groupBySubMarketType[subMarketType];
          const homeTeam = filteredSubMarketType.find((team: any) => team.isHomeTeam);
          const awayTeam = filteredSubMarketType.find((team: any) => !team.isHomeTeam);
          return {
            subMarketType,
            homeTeam,
            awayTeam,
          };
        })
        .reduce(
          (currentSubMarketType, subMktType) => ({
            ...currentSubMarketType,
            [subMktType.subMarketType]: subMktType,
          }),
          {},
        );
      const firstSubMktKey = Object.keys(groupBySubMarketTypeFiltered)?.[0];
      const currentSubMkt: any = groupBySubMarketTypeFiltered[firstSubMktKey as keyof {}];

      return {
        betType:
          +currentSubMkt?.homeTeam?.marketType === JAMarketTypes.SERVE
            ? currentSubMkt?.homeTeam?.betType
            : betType,
        ...groupBySubMarketTypeFiltered,
      };
    });

    return {
      marketType,
      bets: groupByBetTypeFiltered,
    };
  });

  return groupByMarketTypeFiltered.sort((a, b) => {
    const marketTypeA = Number(a.marketType);
    const marketTypeB = Number(b.marketType);

    // Check if both SERVE (1) and EVENT (2) are present
    if (
      (marketTypeA === JAMarketTypes.SERVE && marketTypeB === JAMarketTypes.EVENT) ||
      (marketTypeA === JAMarketTypes.EVENT && marketTypeB === JAMarketTypes.SERVE)
    ) {
      // Ensure EVENT (2) comes before SERVE (1)
      return marketTypeA === JAMarketTypes.SERVE ? 1 : -1;
    }

    return marketTypeA - marketTypeB;
  });
};

export const calcHoldPercentageOfSubMkt = (currentSubMkt: any) => {
  const { homeTeam, awayTeam } = currentSubMkt;
  return Number(homeTeam?.probability) + Number(awayTeam?.probability);
};

export const calculateNewSubMktValues = ({
  currentValSubMarket,
  newHoldPercentage,
  // oldHoldPercentage,
  defaultHoldPercentage,
}: any) => {
  const currentProbability = currentValSubMarket?.trueProbability;

  const newProbability = oddsHelpers.getNewProbabilityAfterEdit({
    probability: currentProbability,
    newHoldPercentage,
    // oldHoldPercentage,
    defaultHoldPercentage,
  });

  const newOdds = oddsHelpers.getDecimalOddsFromProbability(+newProbability);

  const newCalc = {
    odds: newOdds,
    decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toFixed(2),
    fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
    americanOdds:
      oddsHelpers.getAmericanOddsFromProbability(newProbability) > 0
        ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`
        : `${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`,
    probability: newProbability > 0 ? round(newProbability, 2) : 0,
    hasModifiedProbability: true,
  };

  return newCalc;
};

export const modSubMarket = ({
  subMarketKey,
  currentValSubMarket,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  subMarketKey: string;
  currentValSubMarket: any;
  newHoldPercentage: number;
  oldHoldPercentage: number;
  defaultHoldPercentage?: number;
}) => {
  if (typeof currentValSubMarket !== 'object') {
    return { [subMarketKey]: currentValSubMarket };
  }

  if (currentValSubMarket?.isSubMarketLocked || +oldHoldPercentage === +newHoldPercentage) {
    return {
      [subMarketKey]: {
        ...currentValSubMarket,
      },
    };
  }

  const newCalc = calculateNewSubMktValues({
    currentValSubMarket,
    newHoldPercentage,
    oldHoldPercentage,
    defaultHoldPercentage,
  });

  return {
    [subMarketKey]: {
      ...currentValSubMarket,
      ...newCalc,
    },
  };
};

const reBalanceSubMkt = ({
  currentSubMkt,
  newHoldPercentage,
}: {
  currentSubMkt: any;
  newHoldPercentage: number;
}) => {
  const { homeTeam, awayTeam } = currentSubMkt;

  const currentHomeTeamProbability = Number(homeTeam?.probability) || 0;
  const currentAwayTeamProbability = Number(awayTeam?.probability) || 0;
  const currentTotalProbability = currentHomeTeamProbability + currentAwayTeamProbability;

  if (
    currentTotalProbability >= newHoldPercentage ||
    (homeTeam?.isSubMarketLocked && awayTeam?.isSubMarketLocked)
  )
    return currentSubMkt;

  const isHomeTeamHigher = currentHomeTeamProbability > currentAwayTeamProbability;
  const teamKey = isHomeTeamHigher ? teamTypeToKey.awayTeam : teamTypeToKey.homeTeam;
  const currentTeam = isHomeTeamHigher ? awayTeam : homeTeam;

  const remainingProbability =
    newHoldPercentage -
    (isHomeTeamHigher ? currentHomeTeamProbability : currentAwayTeamProbability);

  return {
    ...currentSubMkt,
    [teamKey]: {
      ...currentTeam,
      ...formatOdds(remainingProbability),
      hasModifiedProbability: true,
    },
  };
};

const getCorrectSetHoldPercentage = (currentBet: any) =>
  Object.values(currentBet).reduce((sum: number, bt: any) => {
    if (typeof bt !== 'string') {
      return sum + (+bt?.[teamTypeToKey.homeTeam]?.probability || 0);
    }
    return round(sum);
  }, 0);

const reBalanceCorrectSets = ({
  currentBet,
  newHoldPercentage,
}: {
  currentBet: any;
  newHoldPercentage: number;
}) => {
  const currentHoldPercentage = getCorrectSetHoldPercentage(currentBet);

  if (currentHoldPercentage >= newHoldPercentage) return currentBet;

  const subMktTypeWithMaxProbability: any = Object.values(currentBet).reduce(
    (currentSubMktWithMaxProbability: any, currentSubMkt: any) => {
      if (typeof currentSubMkt !== 'object') return currentSubMktWithMaxProbability;
      const { homeTeam } = currentSubMkt;
      if (!currentSubMktWithMaxProbability) return homeTeam;

      if (currentSubMkt.probability > currentSubMktWithMaxProbability.probability) return homeTeam;

      return currentSubMktWithMaxProbability;
    },
    null,
  );

  const probabilityDifference =
    newHoldPercentage - Number(subMktTypeWithMaxProbability?.probability);

  // Remove the target submarket
  const updatedBet = cloneDeep(currentBet);
  delete updatedBet[subMktTypeWithMaxProbability?.subMarketType as keyof {}];

  // Calculate the total eligible probability for redistribution
  let totalEligibleProbability = getCorrectSetHoldPercentage(updatedBet);

  let remainingDelta = probabilityDifference;

  // Proportional redistribution
  Object.keys(updatedBet).forEach((subMktKey) => {
    const currentSubMkt = updatedBet[subMktKey];

    if (typeof currentSubMkt !== 'object') return;

    if (currentSubMkt[teamTypeToKey.homeTeam]?.hasModifiedProbability) return;

    const currentProbability = +currentSubMkt[teamTypeToKey.homeTeam]?.probability || 0;

    if (totalEligibleProbability > 0) {
      // Redistribute proportionally
      const proportionalShare = currentProbability / totalEligibleProbability;
      const probabilityAdjustment = round(remainingDelta * proportionalShare, 2);
      const newProbability = Math.max(currentProbability + probabilityAdjustment, MAX_PROBABILITY);

      remainingDelta = remainingDelta - (newProbability - currentProbability);
      totalEligibleProbability -= currentProbability;

      const formattedOdds = formatOdds(newProbability);

      updatedBet[subMktKey] = {
        ...currentSubMkt,
        [teamTypeToKey.homeTeam]: {
          ...currentSubMkt[teamTypeToKey.homeTeam],
          ...formattedOdds,
        },
        [teamTypeToKey.awayTeam]: {
          ...currentSubMkt[teamTypeToKey.awayTeam],
          ...formattedOdds,
        },
      };
    }
  });

  return { ...currentBet, ...updatedBet };
};

export const marketOddModifiers = ({
  marketOdds,
  newHoldPercentage = 100,
  // oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  marketOdds: any;
  newHoldPercentage: number;
  oldHoldPercentage?: number;
  defaultHoldPercentage?: number;
}) => {
  const processBet = (key: string, currentVal: any, currentBet: any) => {
    if (typeof currentVal !== 'object') {
      return { [key]: currentVal };
    }
    const holdPercentageCustom = getCorrectSetHoldPercentage(currentBet);

    const currentHoldPercentage =
      +currentVal[teamTypeToKey.homeTeam].betType === JABetTypes.CORRECT_SETS
        ? holdPercentageCustom
        : calcHoldPercentageOfSubMkt(currentVal);

    const subMktResult = Object.keys(currentVal).reduce(
      (result, subMarketKey) => ({
        ...result,
        ...modSubMarket({
          subMarketKey,
          currentValSubMarket: currentVal[subMarketKey],
          oldHoldPercentage: currentHoldPercentage,
          newHoldPercentage,
          defaultHoldPercentage,
        }),
      }),
      {},
    );

    return {
      [key]:
        +currentVal[teamTypeToKey.homeTeam].betType === JABetTypes.CORRECT_SETS
          ? reBalanceCorrectSets({ currentBet: subMktResult, newHoldPercentage })
          : reBalanceSubMkt({ currentSubMkt: subMktResult, newHoldPercentage }),
    };
  };

  const processMarket = (key: string, currentVal: any) => {
    if (typeof currentVal !== 'object') {
      return { [key]: currentVal };
    }

    if (Array.isArray(currentVal)) {
      const bets = currentVal.map((bet) => {
        const betResult = Object.keys(bet).reduce(
          (result, betKey) => ({
            ...result,
            ...processBet(betKey, bet[betKey], bet),
          }),
          {},
        );
        return betResult;
      });

      return { [key]: bets };
    }
  };

  const marketTypeResult = Object.keys(marketOdds).reduce(
    (result, key) => ({
      ...result,
      ...processMarket(key, marketOdds[key]),
    }),
    {},
  );

  return marketTypeResult;
};

export const marketOddsPayload = ({ odds }: { odds: any }) => {
  if (!odds || odds.length === 0) return [];

  const formattedOdds: any[] = [];

  odds.forEach((marketOdds: any) => {
    Object.values(marketOdds?.bets || []).forEach((currentBet: any) => {
      Object.values(currentBet).forEach((currentVal: any) => {
        if (typeof currentVal === 'object') {
          Object.values(currentVal).forEach((currentValSubMarket: any) => {
            if (typeof currentValSubMarket === 'object' && currentValSubMarket?.id) {
              const obj = {
                id: currentValSubMarket.id,
                odds: Number(currentValSubMarket.decimalOdds) || 0,
                probability: round(+currentValSubMarket.probability || 0, 2),
                trueProbability: round(+currentValSubMarket?.trueProbability || 0, 2),
                hasModifiedProbability: currentValSubMarket.hasModifiedProbability || false,
                bias: +currentValSubMarket.bias || 0,
                lean: +currentValSubMarket.lean || 0,
                playerLean: +currentValSubMarket.playerLean || 0,
                max: +currentValSubMarket.max || 0,
                weights: null,
                calculatedValue: +currentValSubMarket.calculatedValue || 0,
                isMarketActive: currentValSubMarket?.isMarketActive || false,
                isSubMarketLocked: currentValSubMarket?.isSubMarketLocked || false,
                marketType: +currentValSubMarket.marketType || 0,
              };
              formattedOdds.push(obj);
            }
          });
        }
      });
    });
  });

  return formattedOdds;
};

export const marketOddsDownloadPayload = ({ odds }: { odds: any }) => {
  if (!odds || odds.length === 0) return [];

  const formattedOdds: any[] = [];

  odds.forEach((marketOdds: any) => {
    Object.values(marketOdds?.bets || []).forEach((currentBet: any) => {
      Object.values(currentBet).forEach((currentVal: any) => {
        if (typeof currentVal === 'object') {
          Object.values(currentVal).forEach((currentValSubMarket: any) => {
            if (typeof currentValSubMarket === 'object' && currentValSubMarket?.id) {
              const obj = {
                ...currentValSubMarket,
                id: currentValSubMarket.id,
                odds: Number(currentValSubMarket.decimalOdds) || 0,
                probability: round(+currentValSubMarket.probability || 0, 2),
                trueProbability: round(+currentValSubMarket?.trueProbability || 0, 2),
                hasModifiedProbability: currentValSubMarket.hasModifiedProbability || false,
                bias: +currentValSubMarket.bias || 0,
                lean: +currentValSubMarket.lean || 0,
                playerLean: +currentValSubMarket.playerLean || 0,
                max: +currentValSubMarket.max || 0,
                weights: null,
                calculatedValue: +currentValSubMarket.calculatedValue || 0,
                isMarketActive: currentValSubMarket?.isMarketActive || false,
                isSubMarketLocked: currentValSubMarket?.isSubMarketLocked || false,
              };
              formattedOdds.push(obj);
            }
          });
        }
      });
    });
  });

  return formattedOdds;
};

export const externalMarketOddsPayload = ({ odds }: { odds: any }) => {
  if (!odds || odds.length === 0) return [];

  const formattedOdds: any[] = [];

  odds.forEach((marketOdds: any) => {
    Object.values(marketOdds?.bets || []).forEach((currentBet: any) => {
      Object.values(currentBet).forEach((currentVal: any) => {
        if (typeof currentVal === 'object') {
          const homeTeam = currentVal[teamTypeToKey.homeTeam];
          const awayTeam = currentVal[teamTypeToKey.awayTeam];
          const homeTeamName = homeTeam?.team?.name || '';
          const awayTeamName = awayTeam?.team?.name || '';
          Object.values(currentVal).forEach((currentValSubMarket: any) => {
            if (typeof currentValSubMarket === 'object' && currentValSubMarket?.id) {
              const obj = {
                marketType: +currentValSubMarket?.marketType,
                betType:
                  +currentValSubMarket?.marketType === JAMarketTypes.SETS
                    ? homeTeam?.betType
                    : +homeTeam?.betType,
                subMarketType: +currentValSubMarket?.subMarketType,
                odds: Number(currentValSubMarket?.decimalOdds) || 0,
                probability: round(Number(currentValSubMarket?.probability) || 0, 2),
                hasModifiedProbability: currentValSubMarket?.hasModifiedProbability || false,
                trueProbability: round(+currentValSubMarket?.trueProbability || 0, 2),
                bias: +currentValSubMarket?.bias || 0,
                lean: +currentValSubMarket?.lean || 0,
                playerLean: +currentValSubMarket?.playerLean || 0,
                max: +currentValSubMarket?.max || 0,
                calculatedValue: +currentValSubMarket?.calculatedValue || 0,
                isHomeTeam: currentValSubMarket?.team?.isHomeTeam,
                homeTeamName,
                awayTeamName,
                // baseLean: +currentValSubMarket?.lean || 0,
                // basePlayerLean: +currentValSubMarket?.playerLean,
                athlete1: currentValSubMarket?.athlete1,
                athlete2: currentValSubMarket?.athlete2,
                providerMatchId: currentValSubMarket?.providerMatchId,
                providerRoundId: currentValSubMarket?.providerRoundId,
                homeTeamProviderAthlete1Id: currentValSubMarket?.homeTeamProviderAthlete1Id,
                homeTeamProviderAthlete2Id: currentValSubMarket?.homeTeamProviderAthlete2Id,
                awayTeamProviderAthlete1Id: currentValSubMarket?.awayTeamProviderAthlete1Id,
                awayTeamProviderAthlete2Id: currentValSubMarket?.awayTeamProviderAthlete2Id,
                isSubMarketLocked: currentValSubMarket?.isSubMarketLocked,
                eventNumber: currentValSubMarket?.round?.eventNumber || '',
                setName: currentValSubMarket?.round?.name || '',
                points: +currentValSubMarket?.points || 1,
              };
              formattedOdds.push(obj);
            }
          });
        }
      });
    });
  });

  return formattedOdds;
};

export const externalMarketOddModifiers = ({
  marketOdds,
  externalOdds,
}: {
  marketOdds: any;
  externalOdds: any;
}) => {
  const processSubMarket = (subMarketKey: string, currentValSubMarket: any) => {
    if (typeof currentValSubMarket !== 'object') {
      return { [subMarketKey]: currentValSubMarket };
    }

    const externalCurrentValSubMarket = externalOdds?.find(
      (externalOdd: any) =>
        externalOdd.marketType === currentValSubMarket.marketType &&
        externalOdd.betType === currentValSubMarket.betType &&
        externalOdd.subMarketType === currentValSubMarket.subMarketType &&
        externalOdd.isHomeTeam === currentValSubMarket.isHomeTeam &&
        (currentValSubMarket.marketType !== JAMarketTypes.SERVE ||
          (currentValSubMarket.marketType === JAMarketTypes.SERVE &&
            externalOdd.eventNumber === currentValSubMarket?.round?.eventNumber &&
            externalOdd.setName === currentValSubMarket?.round?.name &&
            externalOdd.points === currentValSubMarket?.points)),
    );

    if (!externalCurrentValSubMarket)
      return {
        [subMarketKey]: {
          ...currentValSubMarket,
        },
      };

    const newOdds = round(externalCurrentValSubMarket?.odds, 2);
    const newProbability = round(externalCurrentValSubMarket?.probability, 2);

    const newCalc = {
      odds: newOdds,
      decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toFixed(2),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(newProbability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`,
      probability: newProbability > 0 ? round(newProbability, 2) : 0,
      hasModifiedProbability: true,
    };

    return {
      [subMarketKey]: {
        ...currentValSubMarket,
        ...(!currentValSubMarket?.isSubMarketLocked
          ? { ...externalCurrentValSubMarket, ...newCalc }
          : { externalOdds: { ...externalCurrentValSubMarket, ...newCalc } }),
      },
    };
  };

  const processBet = (key: string, currentVal: any) => {
    if (typeof currentVal !== 'object') {
      return { [key]: currentVal };
    }
    const subMarketResult = Object.keys(currentVal).reduce(
      (result, subMarketKey) => ({
        ...result,
        ...processSubMarket(subMarketKey, currentVal[subMarketKey]),
      }),
      {},
    );
    return { [key]: subMarketResult };
  };

  const processMarket = (key: string, currentVal: any) => {
    if (typeof currentVal !== 'object') {
      return { [key]: currentVal };
    }

    if (Array.isArray(currentVal)) {
      const bets = currentVal.map((bet) => {
        const betResult = Object.keys(bet).reduce(
          (result, betKey) => ({
            ...result,
            ...processBet(betKey, bet[betKey]),
          }),
          {},
        );
        return betResult;
      });

      return { [key]: bets };
    }
  };

  const marketTypeResult = Object.keys(marketOdds).reduce(
    (result, key) => ({
      ...result,
      ...processMarket(key, marketOdds[key]),
    }),
    {},
  );

  return marketTypeResult;
};

export const externalOddModifiersForAllMarkets = ({
  allMarketOdds,
  externalOdds,
}: {
  allMarketOdds: any;
  externalOdds: any;
}) => {
  return allMarketOdds.map((marketOdds: any) =>
    externalMarketOddModifiers({ marketOdds, externalOdds }),
  );
};

export const editSubMarketOddsFormatter = ({
  isHomeTeamModified = false,
  homeTeam,
  awayTeam,
  holdPercentage,
  commonOdds,
}: any) => {
  // Convert to decimal odds
  const modTeam = isHomeTeamModified ? homeTeam : awayTeam;
  const newProbability = modTeam.probability;

  const convertedOddTypes = {
    decimalOdds: modTeam.odds,
    fractionalOdds: modTeam.fractionalOdds,
    americanOdds: modTeam.americanOdds,
    odds: modTeam.odds,
  };

  // Get Home Team probability
  const newHomeTeamProbability = isHomeTeamModified
    ? +newProbability
    : holdPercentage - +newProbability;
  // Get Away Team probability
  const newAwayTeamProbability = commonOdds
    ? holdPercentage - newHomeTeamProbability
    : !isHomeTeamModified
    ? +newProbability
    : holdPercentage - +newProbability;

  // Get Home Team normalized probability
  const normalizedProbabilityHomeTeam =
    newHomeTeamProbability < 0
      ? 0
      : newHomeTeamProbability === Infinity
      ? holdPercentage
      : newHomeTeamProbability;
  // Get Away Team normalized probability
  const normalizedProbabilityAwayTeam =
    newAwayTeamProbability < 0
      ? 0
      : newAwayTeamProbability === Infinity
      ? holdPercentage
      : newAwayTeamProbability;

  const newOddsHomeTeam = oddsHelpers.getDecimalOddsFromProbability(normalizedProbabilityHomeTeam);
  const newOddsAwayTeam = oddsHelpers.getDecimalOddsFromProbability(normalizedProbabilityAwayTeam);

  return {
    homeTeam: {
      ...homeTeam,
      probability: round(normalizedProbabilityHomeTeam, 2),
      ...(isHomeTeamModified
        ? convertedOddTypes
        : {
            decimalOdds: oddsHelpers.getDecimalOdds(newOddsHomeTeam).toString(),
            fractionalOdds: oddsHelpers.getFractionalOdds(newOddsHomeTeam).toString(),
            americanOdds:
              oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityHomeTeam) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityHomeTeam)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityHomeTeam)}`,
            probability: round(normalizedProbabilityHomeTeam, 2),
            odds: newOddsHomeTeam,
          }),
      hasModifiedProbability: true,
    },
    awayTeam: {
      ...awayTeam,
      probability: round(normalizedProbabilityAwayTeam, 2),
      ...(!isHomeTeamModified
        ? convertedOddTypes
        : {
            decimalOdds: oddsHelpers.getDecimalOdds(newOddsAwayTeam).toString(),
            fractionalOdds: oddsHelpers.getFractionalOdds(newOddsAwayTeam).toString(),
            americanOdds:
              oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityAwayTeam) > 0
                ? `+${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityAwayTeam)}`
                : `${oddsHelpers.getAmericanOddsFromProbability(normalizedProbabilityAwayTeam)}`,
            probability: round(normalizedProbabilityAwayTeam, 2),
            odds: newOddsAwayTeam,
          }),
      hasModifiedProbability: true,
    },
  };
};

export const mergeOdds = ({ newOdds = [], oldOdds = [] }: any): any[] => {
  newOdds.forEach((market: any) => {
    const oldMarket = oldOdds.find((old: any) => +old.marketType === +market.marketType);

    if (!oldMarket) return;

    market.bets.forEach((bet: any) => {
      const oldBet = oldMarket.bets.find((old: any) => old?.betType === bet?.betType);

      if (!oldBet) return;

      Object.keys(bet).forEach((key) => {
        const subMarket = bet[key] as any;

        // Skip non-object values (like betType)
        if (typeof subMarket !== 'object') return;

        const oldSubMarket = oldBet[key] as any;

        if (oldSubMarket?.homeTeam?.externalOdds) {
          subMarket.homeTeam.externalOdds = oldSubMarket.homeTeam.externalOdds;
        }
        if (oldSubMarket?.awayTeam?.externalOdds) {
          subMarket.awayTeam.externalOdds = oldSubMarket.awayTeam.externalOdds;
        }
      });
    });
  });

  return newOdds;
};

export const formatOdds = (probability: number) => {
  const currentProbability = probability > MAX_PROBABILITY ? MAX_PROBABILITY : probability;
  const decimalOdds = oddsHelpers.getDecimalOddsFromProbability(currentProbability);
  return {
    decimalOdds: decimalOdds,
    fractionalOdds: oddsHelpers.getFractionalOdds(decimalOdds).toString(),
    americanOdds:
      oddsHelpers.getAmericanOddsFromProbability(currentProbability) > 0
        ? `+${oddsHelpers.getAmericanOddsFromProbability(currentProbability)}`
        : `${oddsHelpers.getAmericanOddsFromProbability(currentProbability)}`,
    probability: round(currentProbability, 2),
    odds: decimalOdds,
  };
};

export const editSubMarketCommonOddsFormatter = ({
  isHomeTeamModified = false,
  homeTeam,
  awayTeam,
  // holdPercentage,
  subMarkets,
  subMarketType,
}: any) => {
  const modTeam = isHomeTeamModified ? homeTeam : awayTeam;
  const targetTeamProbability = modTeam.probability;
  const originalSubMarket = subMarkets[subMarketType];

  // Remove the target submarket
  const updatedSubMarkets = { ...subMarkets };
  delete updatedSubMarkets[subMarketType];

  const probabilityDifference =
    Number(originalSubMarket?.homeTeam?.probability) - Number(targetTeamProbability);

  // Calculate the total eligible probability for redistribution
  let totalEligibleProbability = Object.values(updatedSubMarkets).reduce(
    (sum: number, market: any) =>
      sum +
      (market[teamTypeToKey.homeTeam]?.hasModifiedProbability
        ? 0
        : +market[teamTypeToKey.homeTeam]?.probability || 0),
    0,
  );

  let remainingDelta = probabilityDifference;

  // Proportional redistribution
  Object.keys(updatedSubMarkets).forEach((marketKey) => {
    const currentMarket = updatedSubMarkets[marketKey];

    if (currentMarket[teamTypeToKey.homeTeam]?.hasModifiedProbability) return;

    const currentProbability = +currentMarket[teamTypeToKey.homeTeam]?.probability || 0;

    if (totalEligibleProbability > 0) {
      // Redistribute proportionally
      const proportionalShare = currentProbability / totalEligibleProbability;
      const probabilityAdjustment = round(remainingDelta * proportionalShare, 2);
      const newProbability = Math.max(currentProbability + probabilityAdjustment, MAX_PROBABILITY);

      remainingDelta = remainingDelta - (newProbability - currentProbability);
      totalEligibleProbability -= currentProbability;

      const formattedOdds = formatOdds(newProbability);

      updatedSubMarkets[marketKey] = {
        ...currentMarket,
        [teamTypeToKey.homeTeam]: {
          ...currentMarket[teamTypeToKey.homeTeam],
          ...formattedOdds,
        },
        [teamTypeToKey.awayTeam]: {
          ...currentMarket[teamTypeToKey.awayTeam],
          ...formattedOdds,
        },
      };
    }
  });

  // Lock and update the target team
  const normalizedTargetOdds = formatOdds(Math.max(0, targetTeamProbability));
  const updatedTargetSubMarket = {
    subMarketType,
    [teamTypeToKey.homeTeam]: {
      ...homeTeam,
      ...normalizedTargetOdds,
      hasModifiedProbability: true,
    },
    [teamTypeToKey.awayTeam]: {
      ...awayTeam,
      ...normalizedTargetOdds,
      hasModifiedProbability: true,
    },
  };

  const result = { ...updatedSubMarkets, [subMarketType]: updatedTargetSubMarket };

  return result;
};

// Helper function to find the mode (most frequent value)
export const findMode = (array: any[], key: string) => {
  const frequencyMap = array.reduce((acc: any, item: any) => {
    const value = item[key];
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  return Object.keys(frequencyMap).reduce((a, b) => (frequencyMap[a] > frequencyMap[b] ? a : b));
};

export const checkForSubMarketLocked = (bValue: any, isSubMktLocked = false) => {
  const { homeTeam, awayTeam } = bValue;

  return homeTeam?.isSubMarketLocked === isSubMktLocked &&
    awayTeam?.isSubMarketLocked === isSubMktLocked
    ? bValue
    : null;
};

// Processes individual bet entries
export const processBet = (bT: any, isSubMktLocked: boolean) => {
  const betEntries = Object.entries(bT).map(([bKey, bValue]) => {
    // Directly include strings, numbers, or "null"/null as needed
    if (
      typeof bValue === 'string' ||
      typeof bValue === 'number' ||
      bValue === null ||
      bValue === 'null'
    ) {
      return { [bKey]: bValue };
    }

    const correctBet = checkForSubMarketLocked(bValue, isSubMktLocked);
    return correctBet ? { [bKey]: correctBet } : {};
  });

  return Object.assign({}, ...betEntries);
};

// Processes an array of bets by using processBet for each
export const processBets = (bets: any[], isSubMktLocked: boolean) => {
  return bets
    .map((bT: any) => processBet(bT, isSubMktLocked))
    .filter((bT: any) => Object.keys(bT).length > 1);
};

export const findFirstUnlockedSubMarket = (oddsCpy: any = [{ bets: [] }]) => {
  const unlockedMarket: any = {};
  oddsCpy.find((currentOdd: any) => {
    // Skip if the market type is `EVENT`
    if (+currentOdd?.marketType === JAMarketTypes.EVENT) {
      return false;
    }

    const unlockedBets = processBets(currentOdd?.bets, false);

    if (unlockedBets.length > 0) {
      unlockedMarket.marketType = currentOdd?.marketType;
      unlockedMarket.bets = unlockedBets;
    }

    return unlockedBets.length > 0;
  });
  return Object.keys(unlockedMarket).length > 0 ? unlockedMarket : undefined;
};

export const getFirstUnlockedOrLockedSubMkt = (oddsCpy: any = [{ bets: [] }]) => {
  if (!oddsCpy?.length) {
    return { homeTeam: {}, awayTeam: {} };
  }

  // Helper function to find the first unlocked sub-market
  const unlockedSubMkt = findFirstUnlockedSubMarket(oddsCpy);

  // Retrieve the first market data and unlocked sub-market
  const [firstMarketData = { bets: [] }] = oddsCpy.filter(
    (currentOdd: any) => +currentOdd?.marketType !== JAMarketTypes.EVENT,
  );
  const [firstBet = {}] =
    unlockedSubMkt?.bets?.length > 0 ? unlockedSubMkt.bets : firstMarketData.bets || [];

  // Find the first sub-market key
  const firstSubMarketKey = Object.keys(firstBet).find(
    (key) =>
      !(
        typeof firstBet[key] === 'string' ||
        typeof firstBet[key] === 'number' ||
        firstBet[key] === null ||
        firstBet[key] === 'null'
      ),
  );

  // Return the relevant sub-market or default value
  return firstBet[firstSubMarketKey as keyof {}] || {};
};

export const getBetCommonValue = (currentBet: any, field = 'bias') => {
  return Object.keys(currentBet).reduce((acc: any, currentSubMarketKey) => {
    const currentSubMarket = currentBet[currentSubMarketKey];

    if (typeof currentSubMarket === 'string' || currentSubMarketKey === 'betType') return acc;

    // Initialize acc if it’s the first iteration
    if (!acc) {
      return currentSubMarket;
    }

    if (field === 'bias') {
      // Retrieve max biases for both teams from acc
      const currentHomeTeamMaxBias = +acc[teamTypeToKey.homeTeam]?.bias || 0;
      const currentAwayTeamMaxBias = +acc[teamTypeToKey.awayTeam]?.bias || 0;

      // Retrieve biases for the current sub-market
      const currentHomeTeamBias = +currentSubMarket[teamTypeToKey.homeTeam]?.bias || 0;
      const currentAwayTeamBias = +currentSubMarket[teamTypeToKey.awayTeam]?.bias || 0;

      // Compare and update acc based on home team bias
      if (
        currentHomeTeamBias > currentHomeTeamMaxBias ||
        currentAwayTeamBias > currentAwayTeamMaxBias
      ) {
        return currentSubMarket;
      } else {
        return acc;
      }
    }

    if (field === 'probability') {
      // Retrieve probability for both teams from acc
      const currentHomeTeamMaxProbability = +acc[teamTypeToKey.homeTeam]?.probability || 0;
      const currentAwayTeamMaxProbability =
        +acc[teamTypeToKey.awayTeam]?.betType === JABetTypes.CORRECT_SETS
          ? 0
          : +acc[teamTypeToKey.awayTeam]?.probability || 0;

      // Retrieve probability for the current sub-market
      const currentHomeTeamProbability =
        +currentSubMarket[teamTypeToKey.homeTeam]?.probability || 0;
      const currentAwayTeamProbability =
        +currentSubMarket[teamTypeToKey.awayTeam]?.betType === JABetTypes.CORRECT_SETS
          ? 0
          : +currentSubMarket[teamTypeToKey.awayTeam]?.probability || 0;

      // Compare and update acc based on home team probability
      if (
        currentHomeTeamProbability + currentAwayTeamProbability >
        currentHomeTeamMaxProbability + currentAwayTeamMaxProbability
      ) {
        return currentSubMarket;
      } else {
        return acc;
      }
    }
    return acc;
  }, null);
};

interface TeamData {
  isHomeTeam: boolean;
  playerLean: number;
  bias: number;
}

export const isFieldConsistentAcrossTeams = (
  data: Partial<TeamData>[],
  fieldName: keyof TeamData,
  compareTeams?: { homeTeam: any; awayTeam: any },
): { isHomeTeamFieldConsistent: boolean; isAwayTeamFieldConsistent: boolean } => {
  const homeTeamReferenceValue = compareTeams
    ? compareTeams.homeTeam?.[fieldName]
    : data.find((row) => row.isHomeTeam)?.[fieldName];
  const awayTeamReferenceValue = compareTeams
    ? compareTeams.awayTeam?.[fieldName]
    : data.find((row) => !row.isHomeTeam)?.[fieldName];

  const isHomeTeamFieldConsistent = data.every((row) =>
    row.isHomeTeam ? row[fieldName] === homeTeamReferenceValue : true,
  );
  const isAwayTeamFieldConsistent = data.every((row) =>
    !row.isHomeTeam ? row[fieldName] === awayTeamReferenceValue : true,
  );

  return { isHomeTeamFieldConsistent, isAwayTeamFieldConsistent };
};

// Define impossible outcomes based on current score
export const impossibleCombos: Array<[number, number]> = [
  [1, 1],
  [2, 0],
  [0, 2],
];

interface BetStructure {
  [subMarketType: string]: {
    [key: string]: {
      calculatedValue: number;
    };
  };
}

export const isImpossibleOutcome = ({
  currentBet,
  currentSubMkt,
}: {
  currentBet: BetStructure;
  currentSubMkt: number;
}): boolean => {
  const homeValue = +currentBet[currentSubMkt]?.[teamTypeToKey.homeTeam]?.calculatedValue;
  const awayValue = +currentBet[currentSubMkt]?.[teamTypeToKey.awayTeam]?.calculatedValue;
  return impossibleCombos.some(
    ([homeVal, awayVal]) => homeValue === homeVal && awayValue === awayVal,
  );
};

export const trimId = (id: string) => (id && id?.length > 8 ? id?.slice(0, 8) : id);

export const abbreviateCamelCase = (word: string): string => {
  // Find the index of the first uppercase letter
  const firstUpperIndex = word.search(/[A-Z]/);

  // Get the first "word" (everything before the first uppercase letter)
  const firstWord = firstUpperIndex === -1 ? word : word.slice(0, firstUpperIndex);

  // Get all uppercase letters in the rest of the string
  const initials = word.slice(firstUpperIndex).replace(/[^A-Z]/g, '');

  return firstWord + initials;
};

export const convertToAbbreviation = (input: string): string =>
  input
    .split(/(?=[A-Z])/)
    .map((word) => word[0])
    .join('')
    .toUpperCase();
