import * as React from 'react';

import v from 'voca';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { format as normalFormat } from 'date-fns';

import { POWERSLAPGameContext } from '@/contexts/powerslapGame.context';

import { FightStatusKeys, FightStatusValues } from '@/constants/fights';

import { getFirstLettersCombined, getNumberFromCharacters } from '@/helpers/misc';

// 1: MATCH_STATUS_NAMES.LIVE,
// 2: MATCH_STATUS_NAMES.UPCOMING,
// 3: MATCH_STATUS_NAMES.COMPLETED,

const BLUE_CORNER_COLOR = '#3071E9';
const RED_CORNER_COLOR = '#ED4C47';

const EVENT_STATUS_COLORS = {
  [FightStatusValues.LIVE]: '#1AA179',
  [FightStatusValues.UPCOMING]: '#DDA109',
  [FightStatusValues.COMPLETED]: '#0AA2C0',
};

const MatchCardPOWERSLAP = React.forwardRef(({ match }: { match: any }, ref) => {
  const { selectedGame, setSelectedGame } = React.useContext(POWERSLAPGameContext);
  const { startDate, endDate, roundStatus, redCornerAthlete, blueCornerAthlete } = match;

  return (
    <Box sx={{ display: 'inline-block', mr: 2, minWidth: '20rem' }} ref={ref}>
      <Paper
        sx={{
          cursor: 'pointer',
          padding: '0.6rem',
          //   display: 'flex',
          //   gap: '0.75rem',
          borderRadius: '0.375rem',
          border: `1px solid ${selectedGame === match.id ? '#86B7FE' : '#B3B3B3'}`,
          transition: 'border 0.2s, backgroundColor 0.2s',
          backgroundColor: selectedGame === match.id ? '#FFFFFF' : '#F8F9FA',
          boxShadow: selectedGame === match.id ? '0px 4px 4px 0px rgba(0, 0, 0, 0.15)' : 'none',
          display: 'grid',
          gridTemplateAreas:
            '"matchDetails matchDetails"  "teams teams " "teams teams" "matchStatusAndTime matchStatusAndTime"',
          gap: '0.25rem',
          width: '100%',
          // mr: 1,
        }}
        onClick={() => {
          setSelectedGame(match.id);
        }}
      >
        <Box
          sx={{
            gridArea: 'matchDetails',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '0.8125rem',
              lineHeight: '1.25rem',
            }}
            variant="caption"
            component={'span'}
          >
            {`${
              match?.name && match?.name.toString().toLowerCase().includes('fight')
                ? `${match?.name}`
                : `Fight ${getNumberFromCharacters(match?.name || '') || 0}`
            }`}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '0.8125rem',
              lineHeight: '1.25rem',
            }}
            variant="caption"
            component={'span'}
          >
            WEIGHT CLASS: {`${v.upperCase(getFirstLettersCombined(match?.weightClass || ''))}`}
          </Typography>
        </Box>
        <Box sx={{ gridArea: 'teams', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Box
                sx={{
                  display: 'inline-flex',
                  borderRadius: '50%',
                  height: '0.5rem',
                  width: '0.5rem',
                  backgroundColor: RED_CORNER_COLOR,
                }}
              />
              &nbsp;
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  color: '#212529',
                }}
                variant="caption"
                component={'span'}
              >
                {redCornerAthlete?.label || '-'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {/* {homeTeamScores.map((scr) =>
                React.Children.toArray( */}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  lineHeight: '0.9375rem',
                  backgroundColor:
                    selectedGame === match.id ? 'rgba(13, 110, 253, 0.50)' : '#E4E5E6',
                  paddingY: '0.1875rem',
                  paddingX: '0.625rem',
                  borderRadius: '0.25rem',
                  maxWidth: '1.625rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                variant="caption"
                component={'span'}
              >
                {match?.winnerAthleteId
                  ? match?.winnerAthleteId === redCornerAthlete?.id
                    ? 'W'
                    : 'L'
                  : '-'}
              </Typography>

              {/* ),
             )} */}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box>
              <Box
                sx={{
                  display: 'inline-flex',
                  borderRadius: '50%',
                  height: '0.5rem',
                  width: '0.5rem',
                  backgroundColor: BLUE_CORNER_COLOR,
                }}
              />
              &nbsp;
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  color: '#212529',
                }}
                variant="caption"
                component={'span'}
              >
                {blueCornerAthlete?.label || '-'}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              {/* {awayTeamScores.map((scr) =>
                React.Children.toArray( */}
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '0.75rem',
                  lineHeight: '0.9375rem',
                  backgroundColor:
                    selectedGame === match.id ? 'rgba(13, 110, 253, 0.50)' : '#E4E5E6',
                  paddingY: '0.1875rem',
                  paddingX: '0.625rem',
                  borderRadius: '0.25rem',
                  maxWidth: '1.625rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                variant="caption"
                component={'span'}
              >
                {match?.winnerAthleteId
                  ? match?.winnerAthleteId === blueCornerAthlete?.id
                    ? 'W'
                    : 'L'
                  : '-'}
              </Typography>

              {/* ),
              )} */}
            </Box>
          </Box>
          <Box sx={{ borderBottom: '1px solid #DEE2E6', padding: '1px' }}></Box>
        </Box>
        <Box
          sx={{
            gridArea: 'matchStatusAndTime',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '0.4px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              ontSize: '0.8125rem',
              lineHeight: '1.25rem',
              color: EVENT_STATUS_COLORS[roundStatus as keyof {}],
            }}
            variant="caption"
            component={'span'}
          >
            {FightStatusKeys[roundStatus] || ''}
          </Typography>

          {endDate ? (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '0.8125rem',
                lineHeight: '1.25rem',
              }}
            >
              END {`${normalFormat(endDate, 'hh:mm a')} PST`}
            </Typography>
          ) : startDate ? (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '0.8125rem',
                lineHeight: '1.25rem',
              }}
            >
              START {`${normalFormat(startDate, 'hh:mm a')} PST`}
            </Typography>
          ) : (
            ''
          )}
        </Box>
      </Paper>
    </Box>
  );
});

MatchCardPOWERSLAP.displayName = 'MatchCardPOWERSLAP';

export default MatchCardPOWERSLAP;
