import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { green, red } from '@mui/material/colors';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

import { displayInPT as format } from '@/helpers/timeConverters';

const PublishFutureOdds = ({
  clientUpdatedAtDate,
  traderUpdatedAtDate,
  traderUpdatedAtDates,
  handlePublishFutureOdds,
  isVoided = false,
  isMarketOpen = false,
}: {
  clientUpdatedAtDate: any;
  traderUpdatedAtDate: any;
  traderUpdatedAtDates?: any;
  handlePublishFutureOdds: () => Promise<void>;
  isVoided?: boolean;
  isMarketOpen: boolean;
}) => {
  const theme = useTheme();

  const _traderUpdatedAtDate = traderUpdatedAtDate ? new Date(traderUpdatedAtDate).getTime() : 0;
  const _clientUpdatedAtDate = clientUpdatedAtDate ? new Date(clientUpdatedAtDate).getTime() : 0;
  // indicates if the odds have ever been published
  // const isOddsPublished = _traderUpdatedAtDate !== 0 && _clientUpdatedAtDate !== 0;
  const [loading, setLoading] = useState(false);
  const [oddsOutOfSync, setOddsOutOfSync] = useState(() => {
    let isOutOfSync = false;

    if (
      _traderUpdatedAtDate > _clientUpdatedAtDate ||
      _traderUpdatedAtDate === 0 ||
      _clientUpdatedAtDate === 0
    )
      isOutOfSync = true;

    return isOutOfSync;
  });
  // const [oddsOutOfSync, setOddsOutOfSync] = useState(() =>
  //   !isMarketOpen
  //     ? false
  //     : isVoided || !isOddsPublished
  //     ? false
  //     : _traderUpdatedAtDate > _clientUpdatedAtDate ||
  //       _traderUpdatedAtDate === 0 ||
  //       _clientUpdatedAtDate === 0
  //     ? true
  //     : false,
  // );

  useEffect(() => {
    let isOutOfSync = false;

    if (
      _traderUpdatedAtDate > _clientUpdatedAtDate ||
      _traderUpdatedAtDate === 0 ||
      _clientUpdatedAtDate === 0
    )
      isOutOfSync = true;

    setOddsOutOfSync(isOutOfSync);
    // setOddsOutOfSync(
    //   !isMarketOpen
    //     ? false
    //     : isVoided || !isOddsPublished
    //     ? false
    //     : _traderUpdatedAtDate > _clientUpdatedAtDate ||
    //       _traderUpdatedAtDate === 0 ||
    //       _clientUpdatedAtDate === 0
    //     ? true
    //     : false,
    // );
  }, [clientUpdatedAtDate, traderUpdatedAtDate, isVoided, isMarketOpen]);

  const handleClick = async () => {
    setLoading(true);

    try {
      await handlePublishFutureOdds();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setOddsOutOfSync(false);
    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container alignItems="center" sx={{ marginTop: '0.7rem' }}>
      <Typography
        component="span"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: '7px',
        }}
      >
        {loading ? (
          <>
            <PendingOutlinedIcon
              sx={{ color: theme.palette.primary.main, marginRight: '0.2rem' }}
            />
            <Typography
              component="span"
              sx={{
                marginTop: '2px',
                marginLeft: '2px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
              }}
            >
              Recent updates have not been published.
            </Typography>
          </>
        ) : oddsOutOfSync ? (
          <>
            <ErrorOutlineOutlinedIcon sx={{ color: red[500], marginRight: '0.2rem' }} />
            <Typography
              component="span"
              sx={{
                marginTop: '2px',
                marginLeft: '2px',
                fontSize: '0.9rem',
                fontWeight: 'bold',
              }}
            >
              Recent updates have not been published.
            </Typography>
          </>
        ) : (
          <>
            <CheckCircleOutlineRoundedIcon sx={{ color: green[700], marginRight: '0.2rem' }} />
            <Typography
              component="span"
              sx={{ marginTop: '2px', fontSize: '0.9rem', fontWeight: 'bold' }}
            >
              All changes are synchronized.
            </Typography>
          </>
        )}
      </Typography>
      <Grid item>
        {traderUpdatedAtDates && (
          <Typography variant="caption">
            <span>Last Published:</span>&nbsp;
            {`${
              traderUpdatedAtDate
                ? format(new Date(traderUpdatedAtDate), 'MM-dd-yyyy hh:mm:ss a')
                : ''
            } PST`}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <Button
          id="publishBtn"
          sx={{
            display: 'flex',
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          }}
          disabled={!oddsOutOfSync || loading}
          onClick={handleClick}
        >
          <Typography
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '0.875rem',
              fontWeight: 500,
              color: oddsOutOfSync ? theme.palette.primary.main : 'info.main',
              textDecoration: oddsOutOfSync ? 'underline' : 'none',
            }}
          >
            {loading ? (
              <>
                <CircularProgress sx={{ color: 'inherit', marginRight: '0.2rem' }} size={20} />
                <Typography component="span" sx={{ marginTop: '2px', marginLeft: '5px' }}>
                  Publishing...
                </Typography>
              </>
            ) : oddsOutOfSync ? (
              <>
                <PublishedWithChangesOutlinedIcon
                  sx={{ color: theme.palette.primary.main, marginRight: '0.2rem' }}
                />
                <Typography component="span" sx={{ marginTop: '2px', marginLeft: '2px' }}>
                  Publish now
                </Typography>
              </>
            ) : (
              <>{''}</>
            )}
          </Typography>
        </Button>
      </Grid>
      <Grid container xs={12} item>
        {traderUpdatedAtDates && (
          <Typography sx={{ fontSize: '0.9rem' }}>
            <strong>
              <span>Last Updated:</span>
            </strong>
            &nbsp;
            {`${
              traderUpdatedAtDate
                ? format(new Date(traderUpdatedAtDate), 'MM-dd-yyyy hh:mm:ss a')
                : ''
            } PST`}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default PublishFutureOdds;
