import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

interface ADD_POWERSLAP_SCORES {
  sport: string;
  eventId: string;
  requestPayload: any;
}

const addPOWERSLAPScores = async ({ sport, eventId, requestPayload }: ADD_POWERSLAP_SCORES) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/traders/${sport}/${eventId}/round/score`,
    data: requestPayload,
  });

  return data;
};

export default function useAddPOWERSLAPScores() {
  return useMutation(({ sport, eventId, requestPayload }: ADD_POWERSLAP_SCORES) =>
    addPOWERSLAPScores({ sport, eventId, requestPayload }),
  );
}
