import ODDS_TYPES from '@/constants/oddsTypes';
import { FUTURE_TYPES, FUTURE_ODD_TAB_LABELS } from '@/constants/futures';
import SPORT_TYPES from '@/constants/sportTypes';

const DEFAULT_RESPONSE: {
  title: string;
  headers: any[];
  csvData: any[];
} = { title: '', headers: [], csvData: [] };

const convertFutureOddsToCSV = ({
  data,
  futureType,
  oddType,
  sportType,
}: {
  data: any[];
  futureType: string;
  oddType?: string;
  sportType?: string;
}) => {
  const title: string = FUTURE_ODD_TAB_LABELS[futureType as keyof {}];

  if ((data && data.length === 0) || !data) return { ...DEFAULT_RESPONSE, title };

  const isTeam = [SPORT_TYPES.MASL].includes(sportType || '');
  const headers = [!isTeam ? 'Athlete' : 'Team', 'Probability'];

  headers.push(
    ODDS_TYPES.DECIMAL === oddType
      ? 'Decimal Odds'
      : ODDS_TYPES.FRACTIONAL === oddType
      ? 'Fractional Odds'
      : 'American Odds',
  );

  const csvData = data.map((rowData) => {
    const newModData: any = {};
    newModData.name = [
      rowData?.athlete?.firstName,
      rowData?.athlete?.middleName,
      rowData?.athlete?.lastName,
    ]
      .filter(Boolean)
      .join(' ');
    newModData.probability = rowData?.probability;
    if (ODDS_TYPES.DECIMAL === oddType) {
      newModData.decimalOdds = rowData?.decimalOdds || 0;
    }
    if (ODDS_TYPES.FRACTIONAL === oddType) {
      newModData.fractionalOdds = rowData?.fractionalOdds || 0;
    }
    if (ODDS_TYPES.AMERICAN === oddType) {
      newModData.americanOdds = rowData?.americanOdds || 0;
    }

    return newModData;
  });

  return { title, headers, csvData };
};

export const futureOddsToCSVFormatModifier = ({
  data,
  futureType,
  oddType,
  sportType,
}: {
  data: any[];
  futureType: string;
  oddType?: string;
  sportType?: string;
}) => {
  //
  let result = DEFAULT_RESPONSE;
  const VALID_FUTURE_TYPES = Object.values(FUTURE_TYPES).map((value) => value);

  if (VALID_FUTURE_TYPES.includes(futureType)) {
    result = convertFutureOddsToCSV({ data, futureType, oddType, sportType });
  }

  return result;
};
