import { createContext, ReactNode, useState } from 'react';
import { ScoreField } from '@/types/game';
import { FIELD_TO_DEBUG } from '@/features/scores/JAIALAI/components/Markets/constants';

interface GameEventScores {
  gameEventId?: {
    homeTeamGoals: ScoreField[];
    awayTeamGoals: ScoreField[];
  };
}

export const JAIALAIGameContext = createContext<GameEventScores | any>({
  gameEventScores: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setGameEventScores: (_GameEventScores: GameEventScores) => null,
  selectedGame: '',
  setSelectedGame: () => null,
  isDisabledMatch: false,
  setIsDisabledMatch: () => null,
  handleDisableMatch: () => null,
  handleEnableMatch: () => null,
  isInTestMode: false,
  setIsInTestMode: () => null,
  currentFieldsToDebug: [FIELD_TO_DEBUG.id],
  setCurrentFieldsToDebug: () => null,
  isMatchOutcomeImpossible: false,
  setIsMatchOutcomeImpossible: () => null,
});

export const JAIALAIGameProvider = ({ children }: { children: ReactNode }) => {
  const [gameEventScores, setGameEventScores] = useState<GameEventScores>({});
  const [selectedGame, setSelectedGame] = useState<string>('');
  const [isDisabledMatch, setIsDisabledMatch] = useState<boolean>(false);
  const [isInTestMode, setIsInTestMode] = useState<boolean>(false);
  const [currentFieldsToDebug, setCurrentFieldsToDebug] = useState<string[]>([FIELD_TO_DEBUG.id]);
  const [isMatchOutcomeImpossible, setIsMatchOutcomeImpossible] = useState<boolean>(false);

  const handleDisableMatch = () => {
    setIsDisabledMatch((prevIsDisabled: boolean) => {
      if (!prevIsDisabled) {
        return true;
      }
      return prevIsDisabled;
    });
  };

  const handleEnableMatch = () => {
    setIsDisabledMatch((prevIsDisabled: boolean) => {
      if (prevIsDisabled) {
        return false;
      }
      return prevIsDisabled;
    });
  };

  const value = {
    gameEventScores,
    setGameEventScores,
    selectedGame,
    setSelectedGame,
    isDisabledMatch,
    setIsDisabledMatch,
    handleDisableMatch,
    handleEnableMatch,
    isInTestMode,
    setIsInTestMode,
    currentFieldsToDebug,
    setCurrentFieldsToDebug,
    isMatchOutcomeImpossible,
    setIsMatchOutcomeImpossible,
  };

  return <JAIALAIGameContext.Provider value={value}>{children}</JAIALAIGameContext.Provider>;
};
