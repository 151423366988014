import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Grid, TextField, Typography, Button, IconButton, Box, Paper } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { PLAYER_URL } from '@/constants/localStorageKeys';

interface PlayLiveStreamProps {
  showYTStream: boolean;
  setShowYTStream: Dispatch<SetStateAction<boolean>>;
  openLiveStream: boolean;
}

const PlayLiveStream = ({ showYTStream, setShowYTStream }: PlayLiveStreamProps) => {
  const [link, setLink] = useState<string>('');
  const [localStoredLink, setLocalStoredLink] = useState<string>('');

  useEffect(() => {
    const storedLink = localStorage.getItem(PLAYER_URL) || '';
    setLink(storedLink);
    setLocalStoredLink(storedLink);
    if (storedLink) {
      setShowYTStream(true);
    }
  }, []);

  useEffect(() => {
    if (localStoredLink && !showYTStream) {
      setShowYTStream(true);
    }
  }, [localStoredLink, showYTStream]);

  const resetStream = () => {
    localStorage.removeItem(PLAYER_URL);

    setShowYTStream(false);
    setLocalStoredLink('');
    setLink('');
  };

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLink = event.target.value;
    setLink(newLink);
    localStorage.setItem(PLAYER_URL, newLink);
  };

  const handlePlayClick = () => {
    if (link) {
      setShowYTStream(true);
    }
  };

  const parsedLink = link.includes('https://www.youtube.com/watch?v=')
    ? `https://www.youtube.com/embed/${link.replace('https://www.youtube.com/watch?v=', '')}`
    : link.includes('https://www.youtube.com/live/')
    ? `https://www.youtube.com/embed/${link.replace('https://www.youtube.com/live/', '')}`
    : link;

  if (showYTStream && link) {
    return (
      <Paper>
        <Box sx={{ width: '100%', height: '16rem', position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', top: 0, right: 0, zIndex: 2 }}
            onClick={resetStream}
          >
            <CloseOutlinedIcon sx={{ color: '#ffffff' }} />
          </IconButton>
          <iframe
            width="100%"
            height="100%"
            src={parsedLink}
            title="video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      <Grid container sx={{ padding: '2rem' }}>
        <Grid item xs={12}>
          <TextField
            name="link"
            value={link}
            placeholder="Live stream URL"
            fullWidth
            onChange={handleLinkChange}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingY: '0.5rem' }}>
          <Typography sx={{ color: '#999999' }}>
            Paste the live stream URL to play video if available.
          </Typography>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={12}>
          <Button variant="contained" disableElevation disabled={!link} onClick={handlePlayClick}>
            Play live feed
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PlayLiveStream;
