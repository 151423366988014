import { useMutation } from '@tanstack/react-query';

import { TraderHeatOddAddDTO } from '@/types/traderOddUpload';

import { authenticatedAxios } from '../../';

interface TraderHeatOdds {
  sport: string;
  requestPayload: {
    items: TraderHeatOddAddDTO;
  };
}

const addTraderHeatOdds = async ({ sport, requestPayload }: TraderHeatOdds) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/odds/heat`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderHeatOdds() {
  return useMutation(({ sport, requestPayload }: TraderHeatOdds) =>
    addTraderHeatOdds({ sport, requestPayload }),
  );
}
