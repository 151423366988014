import React, { Fragment } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@/components/AppBar';
import Drawer from '@/components/Drawer';
import Banner from '@/components/Banner';

import config from '@/config/vars';

import { Props } from './interfaces';

const DashboardLayout: React.VFC<Props> = ({ component: Component, ...rest }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setOpen(matches ? false : true);
  }, [matches]);

  return (
    <Fragment>
      <Banner />
      <Box sx={{ display: 'flex', maxHeight: !config.isProd ? 'calc(100vh - 30px)' : '100vh' }}>
        <AppBar toggleDrawer={toggleDrawer} open={open} />
        <Drawer toggleDrawer={toggleDrawer} open={open} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? '#ffffff' : theme.palette.grey[900],
            flexGrow: 1,
            minHeight: !config.isProd ? 'calc(100vh - 30px)' : '100vh',
            overflow: 'auto',
            maxHeight: !config.isProd ? 'calc(100vh - 30px)' : '100vh',
          }}
        >
          <Toolbar />
          <Box
            sx={{
              padding: '1rem 1.75rem',
            }}
          >
            <Component {...rest} />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default DashboardLayout;
