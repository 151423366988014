import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

interface UPDATE_EXACTAS_HEAT_VOID {
  sport: string;
  eventId: string;
  heatId: string;
}

const updateExactasHeatOddsVoid = async ({ sport, eventId, heatId }: UPDATE_EXACTAS_HEAT_VOID) => {
  const { data } = await authenticatedAxios({
    method: 'PUT',
    url: `admin/${sport}/events/${eventId}/heats/${heatId}/odds/void`,
  });

  return data;
};

export default function useUpdateExactasHeatOddsVoid() {
  return useMutation(({ sport, eventId, heatId }: UPDATE_EXACTAS_HEAT_VOID) =>
    updateExactasHeatOddsVoid({ sport, eventId, heatId }),
  );
}
