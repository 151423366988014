const headers = [
  'firstName',
  'lastName',
  'nickName',
  'homeTown',
  'dateOfBirth',
  'federalId',
  'weightClass',
  'height',
  'weight',
  'role',
];

const rows = [
  [
    'Rashad',
    'Coulter',
    'Daywalker',
    'Newton, North Carolina, United States',
    '11-27-1981',
    'QWERTY',
    'Light Heavyweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Charles',
    'Bennett',
    'Felony',
    'Newton, North Carolina, United States',
    '11-27-1982',
    'ASAS',
    'Heavyweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Chris',
    'Lytle',
    'Lights Out',
    'Newton, North Carolina, United States',
    '11-27-1983',
    'DSDS',
    'Cruiserweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'John',
    'Koppenhaver',
    'War Machine',
    'Newton, North Carolina, United States',
    '11-27-1984',
    'SDSDSD',
    'Middleweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Will',
    'Brooks',
    'Big Newton',
    'North Carolina, United States',
    '11-27-1985',
    'SDSDSD',
    'Welterweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Matt',
    'Delanoit',
    'The Mangler',
    'Newton, North Carolina, United States',
    '11-27-1986',
    'SDSDSD',
    'Lightweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'David',
    'Rickels',
    'Caveman',
    'Newton, North Carolina, United States',
    '11-27-1987',
    'DFG',
    'Featherweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Luis',
    'Palomino',
    'Baboon',
    'Newton, North Carolina, United States',
    '11-27-1988',
    'AAAAA',
    'Bantamweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Chase',
    'Sherman',
    'The Beast',
    'Newton, North Carolina, United States',
    '11-27-1989',
    'BBBBB',
    'Flyweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  [
    'Frank',
    'Tate',
    'The Tank',
    'Newton, North Carolina, United States',
    '11-27-1990',
    'CCCCC',
    'Strawweight',
    `5'11"`,
    '160.58',
    '1',
  ],
  ['John', 'Cena', '', '', '', '', '', ``, '', '2'],
  ['Bill', 'Cook', '', '', '', '', '', ``, '', '3'],
];

export default { headers, rows };
