import React from 'react';
import MaterialTable from '@material-table/core';
import { Box, Button, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// import { EventListingSkeleton } from '@/components/PageSkeletons';
import UploadBtn from '@/components/UploadBtn';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { tableIcons, defaultTableOptions, createTablePageOptions } from '@/utils/TableMisc';

import useFightEventSeeds from './useFightEventSeeds';
import AddOrEditParticipantModal from './AddOrEditParticipantModal';
import UploadParticipantsModal from './UploadParticipantsModal';
import AddExistingParticipantModal from './AddExistingParticipantModal';
import keys from '@/constants/queryKeys';
import Editicon from '@/images/icons/table-edit-icon.png';

import { IEvent } from '@/types/newTypes/event';
import { excelFileDownloader } from '@/helpers/fileDownloader';

import SampleUploadData from './SampleUploadData';
import { FightMatchMemberTypes } from '@/constants/fights';

const FightEventSeeds = ({ columns, sportType }: { columns: any[]; sportType: string }) => {
  const { eventId = '' } = useParams();
  const [showAddOrEditParticipantDialog, setShowAddOrEditParticipantDialog] = React.useState(false);
  const toggleShowAddOrEditParticipantDialog = () =>
    setShowAddOrEditParticipantDialog((prev) => !prev);

  const [showUploadParticipantDialog, setShowUploadParticipantDialog] = React.useState(false);
  const toggleShowUploadParticipantDialog = () => setShowUploadParticipantDialog((prev) => !prev);

  const [showAddExistingParticipantDialog, setShowAddExistingParticipantDialog] =
    React.useState(false);
  const toggleShowAddExistingParticipantDialog = () =>
    setShowAddExistingParticipantDialog((prev) => !prev);

  const [selectedParticipant, setSelectedParticipant] = React.useState(undefined);
  const { tableData, refetchSeeds, modCommunityData } = useFightEventSeeds({
    sportType,
  });

  const queryClient = useQueryClient();

  const event: IEvent | undefined = queryClient.getQueryData([
    keys.events.fetchEvent,
    sportType,
    eventId,
    'Event',
  ]);

  const handleDownloadSampleExcelDoc = () => {
    excelFileDownloader({
      fileName: `${sportType.toUpperCase()} Upload Sheet`,
      fileType: 'xlsx',
      headers: SampleUploadData.headers,
      rows: SampleUploadData.rows,
    });
  };

  // if (isFetching) return <EventListingSkeleton />;

  return (
    <Box>
      <>
        <Box
          sx={{
            marginY: '1rem',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          {tableData.length === 0 && (
            <>
              <Button
                startIcon={<FileDownloadIcon />}
                disableElevation
                variant="contained"
                color="primary"
                onClick={handleDownloadSampleExcelDoc}
              >
                Download Sample File Upload
              </Button>
              <UploadBtn handleOnClick={() => toggleShowUploadParticipantDialog()} />
            </>
          )}
          <Button
            disableElevation
            variant="contained"
            startIcon={<AddIcon />}
            onClick={toggleShowAddExistingParticipantDialog}
          >
            Add existing participant
          </Button>
          <Button
            disableElevation
            variant="contained"
            startIcon={<AddIcon />}
            onClick={toggleShowAddOrEditParticipantDialog}
          >
            Create new participant
          </Button>
        </Box>
      </>

      {tableData.length === 0 ? (
        <Box
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '10rem',
            backgroundColor: '#F8F9FA',
            border: '1px dashed #CED4DA',
            borderRadius: '0.25rem',
          }}
        >
          <Typography
            sx={{ textAlign: 'center', fontSize: '0.8125rem', lineHeight: '1.25rem', width: '60%' }}
          >
            There is no information about participants here yet. You can upload a CSV file with
            participants&apos; data to add fights to the event, or add an participant manually.
          </Typography>

          <Box
            sx={{
              marginY: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '0.5rem',
            }}
          >
            <Button
              disableElevation
              startIcon={<UploadFileIcon />}
              onClick={toggleShowUploadParticipantDialog}
            >
              Upload CSV
            </Button>
            <Button
              disableElevation
              startIcon={<AddIcon />}
              onClick={toggleShowAddOrEditParticipantDialog}
            >
              Add participant
            </Button>
          </Box>
        </Box>
      ) : (
        <MaterialTable
          icons={tableIcons}
          columns={columns}
          data={tableData}
          options={{
            toolbar: false,
            // header: false,
            actionsColumnIndex: -1,
            rowStyle: {
              overflowWrap: 'break-word',
            },
            thirdSortClick: false,
            // sorting: true,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(tableData?.length || 0).pageSize,
            pageSizeOptions: createTablePageOptions(tableData?.length || 0).pageSizeOptions,
          }}
          actions={[
            {
              icon: () => (
                <Box
                  component="img"
                  alt="edit icon"
                  src={Editicon}
                  sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
                />
              ),
              onClick: (_, rowData) => {
                toggleShowAddOrEditParticipantDialog();
                const selectedParticipant = rowData?.athlete || rowData?.judge || rowData?.referee;
                const role = rowData?.athlete
                  ? FightMatchMemberTypes.ATHLETE
                  : rowData?.judge
                  ? FightMatchMemberTypes.JUDGE
                  : rowData?.referee && FightMatchMemberTypes.REFEREE;
                setSelectedParticipant({ ...selectedParticipant, role });
              },
            },
          ]}
          // editable={{
          //   onRowUpdate: (newData: any) => rowUpdateHandler(newData),
          // }}
          localization={{
            header: {
              actions: '',
            },
          }}
        />
      )}
      {showAddOrEditParticipantDialog && (
        <AddOrEditParticipantModal
          open={showAddOrEditParticipantDialog}
          handleClose={() => {
            toggleShowAddOrEditParticipantDialog();
            setSelectedParticipant(undefined);
          }}
          sportType={sportType}
          event={event as IEvent}
          participant={selectedParticipant}
          refetchParticipants={refetchSeeds}
        />
      )}
      {showUploadParticipantDialog && (
        <UploadParticipantsModal
          open={showUploadParticipantDialog}
          handleClose={() => {
            toggleShowUploadParticipantDialog();
            setSelectedParticipant(undefined);
          }}
          sportType={sportType}
          event={event as IEvent}
          refetchParticipants={refetchSeeds}
        />
      )}
      {showAddExistingParticipantDialog && (
        <AddExistingParticipantModal
          open={showAddExistingParticipantDialog}
          handleClose={() => {
            toggleShowAddExistingParticipantDialog();
          }}
          sportType={sportType}
          event={event as IEvent}
          refetchParticipants={refetchSeeds}
          options={modCommunityData}
        />
      )}
    </Box>
  );
};

export default FightEventSeeds;
