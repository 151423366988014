import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import { parseISO } from 'date-fns';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import v from 'voca';
import { useRecoilState } from 'recoil';
import { zonedTimeToUtc } from 'date-fns-tz';

import { sportsType } from '@/atoms/sportsType';
import Dropzone from '@/components/Dropzone';
import { SLSEvent } from '@/types/sls/event';
import { WSLEvent } from '@/types/wsl/event';
import { NRXEvent } from '@/types/nrx/event';
import { SPREvent } from '@/types/spr/event';
import SPORT_TYPES, { sportOptions } from '@/constants/sportTypes';
import useAddEvents from '@/hooks/events/useAddEvents';

import { EventStatusValues } from '@/constants/events';
import { processEventStatusBadges } from '@/helpers/events';
import { EventRow, allowedTableHeaders, requiredPayloadKeys } from './constants';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';
import routes from '@/constants/routes';

import { displayInPT as format } from '@/helpers/timeConverters';
import { DEFAULT_TIMEZONE } from '@/constants/misc';

type EVENT = SLSEvent | WSLEvent | NRXEvent | SPREvent;
type EVENTS = SLSEvent[] | WSLEvent[] | NRXEvent[] | SPREvent[];

export default function EventsUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addEvents } = useAddEvents();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<EVENTS>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        const modSheetDTO = modSheet.map((data: EVENT) => {
          const eventStatus = EventStatusValues[data?.eventStatusMod?.toUpperCase() || ''];
          delete data.eventStatusMod;
          const finalData = {
            ...data,
            startDate: data?.startDate ? zonedTimeToUtc(data?.startDate, DEFAULT_TIMEZONE) : null,
            endDate: data?.endDate ? zonedTimeToUtc(data?.startDate, DEFAULT_TIMEZONE) : null,
            eventStatus,
          };
          return finalData;
        });
        addEvents(
          {
            sport: selectedSport.toLowerCase(),
            requestPayload: {
              items: modSheetDTO,
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Events Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders[selectedSport] || [];

      const formattedAllowedHeaders = headers.map((header: any) => {
        if (header.title === 'Event Status') {
          header.render = (rowData: SLSEvent) =>
            processEventStatusBadges(
              EventStatusValues[String(rowData?.eventStatusMod || '').toUpperCase()],
            );
        } else if (header.title === 'Start Date') {
          header.render = (rowData: SLSEvent) =>
            rowData?.startDate && (
              <Typography>{`${format(
                parseISO(rowData?.startDate) || new Date(),
                'MM-dd-yyyy',
              )}`}</Typography>
            );
        } else if (header.title === 'End Date') {
          header.render = (rowData: SLSEvent) =>
            rowData?.endDate && (
              <Typography>{`${format(
                parseISO(rowData?.endDate) || new Date(),
                'MM-dd-yyyy',
              )}`}</Typography>
            );
        }

        return header;
      });

      setAllowedHeaders(formattedAllowedHeaders);
    }
  }, [selectedSport]);

  const handleChange = () => {
    setSheetData([]);
  };

  const tableRowChecker = (rows: EventRow[]) => {
    let message = '';
    const index = rows.findIndex(
      (row: EventRow) =>
        !row.hasOwnProperty('name') ||
        !row.hasOwnProperty('year') ||
        !row.hasOwnProperty('gender') ||
        !row.hasOwnProperty('location') ||
        !row.hasOwnProperty('event_status') ||
        !row.hasOwnProperty('tour') ||
        ([SPORT_TYPES.WSL, SPORT_TYPES.NRX].includes(selectedSport) &&
          (!row.hasOwnProperty('tour') || !row.hasOwnProperty('stop'))) ||
        (selectedSport === SPORT_TYPES.SLS && !row.hasOwnProperty('location_group')) ||
        (selectedSport === SPORT_TYPES.SPR && !row.hasOwnProperty('stop')),
    );
    if (index !== -1) {
      const row = rows[index];
      let missing_columns = '';
      const requiredKeys = requiredPayloadKeys[selectedSport];
      requiredKeys.map((key) => {
        if (!row[key as keyof EventRow]) missing_columns += `${key} `;
        return key;
      });
      missing_columns = missing_columns.split(' ').join(', ').slice(0, -2);
      message = `Error on row { ${index + 2} }, Missing { ${missing_columns} }`;
    }

    return message;
  };

  const tableDataModifierFn = (data: any) => {
    const {
      name,
      year,
      gender,
      location,
      stop,
      event_status,
      start_date = '',
      end_date = '',
      tour = '',
      location_group,
      track_type = '',
      category_name = '',
    } = data;

    return {
      name,
      year,
      gender,
      eventLocation: location,
      startDate: start_date,
      endDate: end_date,
      eventStatusMod: event_status,
      ...([SPORT_TYPES.WSL, SPORT_TYPES.NRX].includes(selectedSport) && {
        tourName: tour,
        eventNumber: stop,
      }),
      ...(selectedSport === SPORT_TYPES.SLS && {
        leagueName: tour,
        eventLocationGroup: location_group,
      }),
      ...(selectedSport === SPORT_TYPES.NRX && {
        trackType: track_type,
      }),
      ...(selectedSport === SPORT_TYPES.SPR && {
        tourName: tour,
        eventLocationGroup: location_group,
        eventNumber: stop,
        trackType: track_type,
        categoryName: category_name,
      }),
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.events.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Events
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Events"
        sheetName="Events"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />
      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button
            variant="contained"
            disabled={disableBtn}
            onClick={() => {
              handleToggleConfirmDialog();
            }}
          >
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} EVENTS?`}
          body={'This will upload the events.'}
        />
      )}
    </Box>
  );
}
