import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Grid, Button } from '@mui/material';

import { getAuthToken } from '@/constants/localStorageKeys';

import routes from '@/constants/routes';

const PageNotFound = () => {
  const token = getAuthToken();
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (token) {
      navigate(routes.events.home);
    } else {
      navigate(routes.public.login);
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100%', paddingY: '10rem' }}
    >
      <Typography variant="h1" sx={{ fontWeight: 600, marginBottom: '1rem' }}>
        404
      </Typography>

      <Typography variant="h4" sx={{ fontWeight: 600 }}>
        Page Not Found
      </Typography>

      <Button
        onClick={handleRedirect}
        sx={{ marginTop: '2rem' }}
        variant="contained"
        disableElevation
      >
        {token ? 'Home' : 'Login'}
      </Button>
    </Grid>
  );
};

export default PageNotFound;
