import { useMutation } from '@tanstack/react-query';

import { TraderHeadToHeadOddAddDTO } from '@/types/traderOddUpload';

import { authenticatedAxios } from '../..';

interface TraderHeadToHeadOdds {
  sport: string;
  requestPayload: {
    items: TraderHeadToHeadOddAddDTO;
  };
}

const addTraderHeadToHeadOdds = async ({ sport, requestPayload }: TraderHeadToHeadOdds) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/traders/odds/headToHead`,
    data: requestPayload,
  });

  return data;
};

export default function useAddTraderHeadToHeadOdds() {
  return useMutation(({ sport, requestPayload }: TraderHeadToHeadOdds) =>
    addTraderHeadToHeadOdds({ sport, requestPayload }),
  );
}
