import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

async function getFutures({ sportType, queryParam }: { sportType: string; queryParam: string }) {
  const {
    data: { data = [] },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/futures?${queryParam}`,
  }).catch(() => {
    return { data: { data: [] } };
  });
  return data;
}

export default function useFetchFutures({
  sportType,
  queryParam,
  futureStatus,
}: {
  sportType: string;
  queryParam: string;
  futureStatus?: number[];
}) {
  return useQuery(
    [keys.futures.fetchFutures, sportType, futureStatus],
    () => getFutures({ sportType, queryParam }),
    {
      staleTime: Infinity,
    },
  );
}
