import * as React from 'react';
import { Paper, Box, Typography, Button } from '@mui/material';

import { processHeatStatus } from '@/helpers/events';
import { HeatStatus } from '@/constants/heats';

import { RoundDTO } from '@/types/round';
import { HeatDTO } from '@/types/heat';

interface FightCardWithSwitchProps {
  ref: any;
  idx: number;
  heat: {
    id: string;
    name?: string;
    heatName?: string;
    heatStatus: number;
    heatNo?: number;
    isHeatWinnerMarketVoided?: boolean;
  };
  handleCurrentHeat: Function;
  selectedHeat: RoundDTO | HeatDTO | null;
  handleVoidHeat: (heatId: string) => void;
}

const FightCardWithSwitch = (props: FightCardWithSwitchProps) => {
  const { idx, heat, handleCurrentHeat, selectedHeat, handleVoidHeat } = props;

  const { status, color, icon } = processHeatStatus(heat.heatStatus);

  return (
    <Box
      sx={{
        display: 'inline-block',
        height: 'auto',
        minWidth: '15rem',
        marginRight: '0.5rem',
      }}
      key={heat.id || idx}
    >
      <Paper
        onClick={() => {
          handleCurrentHeat(heat);
        }}
        sx={{
          border:
            selectedHeat && selectedHeat?.id === heat.id
              ? '0.062rem solid #6EA8FE'
              : '0.062rem solid #DEE2E6',
          backgroundColor: selectedHeat && selectedHeat?.id === heat.id ? '#E7F1FF' : '#ffffff',
          cursor: 'pointer',
          padding: '0.625rem 0.75rem',
          display: 'flex',
          gap: '0.75rem',
          boxShadow: 'none',
          borderRadius: '0.25rem',
          ':hover': {
            border: '0.062rem solid #6EA8FE',
          },
          transition: 'border 0.2s; backgroundColor 0.2s',
          ...(heat?.isHeatWinnerMarketVoided && {
            backgroundColor: '#EEF0F2',
          }),
        }}
      >
        <Box
          sx={{
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
            },
          }}
        >
          {icon}
        </Box>
        <Box
          style={{
            width: '70%',
            display: 'inline-flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              color: 'info.main',
              fontSize: '0.875rem',
              fontWeight: '500',
              ...(heat?.isHeatWinnerMarketVoided && {
                color: '#9FBAD8',
              }),
            }}
          >
            {heat.name ? heat.name : `${heat?.heatName || ''} ${heat?.heatNo || ''}`}
          </Typography>

          <Typography color={color} fontSize={'0.812rem'}>
            {status}
          </Typography>
        </Box>
        <Box>
          <Button
            onClick={() => handleVoidHeat(heat.id)}
            disabled={!!heat?.isHeatWinnerMarketVoided || heat.heatStatus === HeatStatus.COMPLETED}
            variant="contained"
            disableElevation
            size="small"
            color="error"
            sx={{ margin: '0.7rem' }}
          >
            {heat?.isHeatWinnerMarketVoided ? 'Voided' : 'Void'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default FightCardWithSwitch;
