import React from 'react';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import v from 'voca';

import {
  Box,
  Button,
  Grid,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
  // InputAdornment,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import { zonedTimeToUtc } from 'date-fns-tz';

import AddIcon from '@mui/icons-material/Add';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
// import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';

import useFetchFightParticipants from '@/hooks/fights/useFetchFightParticipants';
import useFetchEventFightScores from '@/hooks/events/useFetchEventFightScores';
import useAddPOWERSLAPScores from '@/hooks/powerslap/useAddPOWERSLAPScores';
import useUpdatePOWERSLAPScores from '@/hooks/powerslap/useUpdatePOWERSLAPScores';
// import useDeletePOWERSLAPScores from '@/hooks/POWERSLAP/useDeletePOWERSLAPScores';
import useUpdatePOWERSLAPRounds from '@/hooks/powerslap/useUpdatePOWERSLAPRounds';

import { displayInPT as format } from '@/helpers/timeConverters';

// import DeleteConfirmDialog from '@/components/Dialogs/DeleteConfirmDialog';
import CustomEndFightDialog from '@/components/Dialogs/CustomEndFightDialog';

import { FightStatusKeys, FightStatusNames, FightStatusValues } from '@/constants/fights';
import { POWERSLAPGameContext } from '@/contexts/powerslapGame.context';

import JudgeIcon from '@/images/icons/judge-icon.svg';

// import { data, athletesArray, weightOptions, decisionOptions } from './data';
import {
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  // AccordionSummaryText,
  AccordionSummaryTextLg,
  processMatchStatusBadges,
  AccordionDtlsLabelText,
  ModSelect,
  // ModTextField,
  ModAutocomplete,
  CounterButton,
  JudgeScores,
  ScoreLabels,
  ModDecimalField,
  ModAutocompleteMultiple,
  CustomMultilineInput,
  CustomAthleteSelect,
  ModTextField,
  CustomRoundAthleteSelect,
  RedBlueSwitch,
  RedPlayerLabel,
  BluePlayerLabel,
} from './components';
import SPORT_TYPES from '@/constants/sportTypes';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { WIN_BY_OPTIONS_POWERSLAP, POWERSLAP_FOULS, DEFAULT_TIMEZONE } from '@/constants/misc';

import keys from '@/constants/queryKeys';
import { getFirstLettersCombined, getNumberFromCharacters } from '@/helpers/misc';
import Slider from '@/components/Slider';

import { POWERSLAPGameProvider } from '@/contexts/powerslapGame.context';
import { EventStatusValues } from '@/constants/events';
import orderBy from 'lodash.orderby';
import useFetchWeightClasses from '@/hooks/fightSportMisc/useFetchWeightClasses';
import cloneDeep from 'lodash.clonedeep';

// import { data } from './data';

export const BLUE_CORNER_COLOR = '#3071E9';
export const RED_CORNER_COLOR = '#ED4C47';

const CORNER_COLORS = {
  RED: 'red',
  BLUE: 'blue',
};

const TIME_TYPE = {
  startDate: 'startDate',
  endDate: 'endDate',
};

const CORNER_COLORS_ORDER = {
  [CORNER_COLORS.RED]: 1,
  [CORNER_COLORS.BLUE]: 2,
};

const ARCHIVED = 1;

// const BLUE_CORNER_WIN_BY = '#136E32';
// const BLUE_CORNER_TEXT_WIN_BY = '#136E32';

// const RED_CORNER_WIN_BY = '#AD1827';
// const RED_CORNER_TEXT_WIN_BY = '#AD1827';

const DEFAULT_JUDGE_OBJ = {
  score1: 0,
  score2: 0,
  name: '',
};

const DEFAULT_ROUND_SCORE_OBJ = {
  athleteId: '',
  fouls: [],
  deducted: 0,
  cornerType: CORNER_COLORS.RED,
  roundSlapNo: 0,
  roundSlapName: '0',
};

const DEFAULT_FIGHT_ID = 'POWERSLAP1';

const DEFAULT_ROUND = Array.from({ length: 1 }, (_, tempId: number) => ({
  ...{
    name: `${tempId + 1}`,
    startDate: null,
    endDate: null,
    heatStatus: FightStatusValues[FightStatusNames.UPCOMING],
  },
  playerRoundStats: [
    { ...DEFAULT_ROUND_SCORE_OBJ, roundSlapNo: 1, roundSlapName: `${tempId + 1}` },
    {
      ...DEFAULT_ROUND_SCORE_OBJ,
      roundSlapNo: 1,
      roundSlapName: `${tempId + 1}`,
      cornerType: CORNER_COLORS.BLUE,
    },
  ],
}));

const DEFAULT_FIGHT = {
  id: DEFAULT_FIGHT_ID,
  name: null,
  blueCornerAthlete: null,
  redCornerAthlete: null,
  scheduledRounds: 1,
  lastRound: null,
  startDate: null,
  endDate: null,
  roundStatus: 2,
  winner: null,
  referee: null,
  winBy: '',
  notes: '',
  weightClass: '',
  judges: Array.from({ length: 3 }, () => DEFAULT_JUDGE_OBJ),
  rounds: DEFAULT_ROUND,
  coinTossWinner: CORNER_COLORS.RED,
};

const fightValidationSchema = Yup.object().shape({
  blueCornerAthlete: Yup.object()
    .shape({
      id: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .required('Blue Corner Athlete is required'),
  redCornerAthlete: Yup.object()
    .shape({
      id: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .required('Red Corner Athlete is required'),
});

const FightContainer = ({
  scr,
  idx,
  values,
  setFieldValue,
  errors,
  ATHLETE_OPTIONS,
  WEIGHT_CLASSES,
  JUDGE_OPTIONS,
  REFEREE_OPTIONS,
  WIN_BY_OPTIONS_POWERSLAP,
  FOULS,
  FIGHT_STATUS_OPTIONS,
  handleBlur,
  handleSaveOrEndFight,
  accordion,
  matchSummaryDateFormatter,
  // toggleConfirmDeleteDialog,
  // handleConfirmDelete,
  toggleCustomStartFightDialog,
  toggleCustomEndFightDialog,
  toggleCustomRoundTimeDialog,
}: any) => {
  const { redCornerAthlete, blueCornerAthlete } = React.useMemo(() => {
    const { redCornerAthlete: _redCornerAthlete, blueCornerAthlete: _blueCornerAthlete } =
      values?.fights[idx] || {};

    return { redCornerAthlete: _redCornerAthlete, blueCornerAthlete: _blueCornerAthlete };
  }, [values?.fights[idx]]);

  const WINNER_OPTIONS = React.useMemo(
    () => [redCornerAthlete, blueCornerAthlete].filter(Boolean),
    [redCornerAthlete, blueCornerAthlete],
  );
  return (
    <>
      <FightSummary
        scr={scr}
        idx={idx}
        accordion={accordion}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
        matchSummaryDateFormatter={matchSummaryDateFormatter}
      />
      <FightDetails
        scr={scr}
        idx={idx}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        ATHLETE_OPTIONS={ATHLETE_OPTIONS}
        WEIGHT_CLASSES={WEIGHT_CLASSES}
        JUDGE_OPTIONS={JUDGE_OPTIONS}
        REFEREE_OPTIONS={REFEREE_OPTIONS}
        WINNER_OPTIONS={WINNER_OPTIONS}
        WIN_BY_OPTIONS_POWERSLAP={WIN_BY_OPTIONS_POWERSLAP}
        FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
        handleBlur={handleBlur}
        handleSaveOrEndFight={handleSaveOrEndFight}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
        toggleCustomStartFightDialog={toggleCustomStartFightDialog}
        toggleCustomEndFightDialog={toggleCustomEndFightDialog}
        // toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
        // toggleCustomEndFightDialog={toggleCustomEndFightDialog}
      />
      {React.Children.toArray(
        values.fights[idx].rounds.map((_, id) => (
          <RoundDetails
            scr={scr}
            idx={idx}
            id={id}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            ATHLETE_OPTIONS={ATHLETE_OPTIONS}
            WEIGHT_CLASSES={WEIGHT_CLASSES}
            JUDGE_OPTIONS={JUDGE_OPTIONS}
            REFEREE_OPTIONS={REFEREE_OPTIONS}
            WINNER_OPTIONS={WINNER_OPTIONS}
            WIN_BY_OPTIONS_POWERSLAP={WIN_BY_OPTIONS_POWERSLAP}
            FOULS={FOULS}
            FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
            handleBlur={handleBlur}
            handleSaveOrEndFight={handleSaveOrEndFight}
            redCornerAthlete={redCornerAthlete}
            blueCornerAthlete={blueCornerAthlete}
            toggleCustomRoundTimeDialog={toggleCustomRoundTimeDialog}
          />
        )),
      )}
      <FightScores
        scr={scr}
        idx={idx}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        ATHLETE_OPTIONS={ATHLETE_OPTIONS}
        WEIGHT_CLASSES={WEIGHT_CLASSES}
        JUDGE_OPTIONS={JUDGE_OPTIONS}
        REFEREE_OPTIONS={REFEREE_OPTIONS}
        WINNER_OPTIONS={WINNER_OPTIONS}
        WIN_BY_OPTIONS_POWERSLAP={WIN_BY_OPTIONS_POWERSLAP}
        FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
        handleBlur={handleBlur}
        handleSaveOrEndFight={handleSaveOrEndFight}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
      />

      <Grid container justifyContent="flex-end" alignItems="center" gap="0.5rem" mt={3}>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          // disabled={scr.winner}
          onClick={() => handleSaveOrEndFight(scr, idx)}
        >
          Save
        </Button>

        <Button
          variant="contained"
          disableElevation
          // disabled={!scr.startDate || !scr.winner}
          onClick={() => {
            toggleCustomEndFightDialog(scr);
          }}
        >
          End this fight
        </Button>
        {/* <Button
          variant="contained"
          disableElevation
          color="error"
          // disabled={scr.winner}
          onClick={() => {
            if (!scr?.roundHeatId) {
              handleConfirmDelete(scr);
            } else {
              toggleConfirmDeleteDialog(scr);
            }
          }}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button> */}
      </Grid>
    </>
  );
};

const FightSummary = ({ scr }: any) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', mb: 3 }}>
      <AccordionSummaryTextLg>{`${
        scr?.name && scr?.name.toString().toLowerCase().includes('fight')
          ? `${scr?.name}`
          : `Fight ${getNumberFromCharacters(scr?.name || '') || 0}`
      }`}</AccordionSummaryTextLg>{' '}
      {processMatchStatusBadges(scr?.roundStatus)}
    </Box>
  );
};

const FightDetails = ({
  scr,
  idx,
  setFieldValue,
  values,
  errors,
  ATHLETE_OPTIONS,
  WEIGHT_CLASSES,
  FIGHT_STATUS_OPTIONS,
  toggleCustomStartFightDialog,
  toggleCustomEndFightDialog,
}: any) => {
  return (
    <Box sx={{ p: 4, border: '1px solid #DEE2E6', borderRadius: '0.5rem', mb: 3 }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `"name coinToss"`,
          gridTemplateColumns: 'minmax(0, 40fr) minmax(0, 60fr)',
          mb: 2,
          gap: '1rem',
        }}
      >
        <Box sx={{ gridArea: 'name' }}>
          <AccordionDtlsLabelText mb={1}>Fight No</AccordionDtlsLabelText>
          <ModDecimalField
            name={`fights[${idx}].name`}
            value={values?.fights[idx]?.name}
            fullWidth
            // onBlur={handleBlur}
            onBlur={({ target }) => {
              setFieldValue(`fights[${idx}].name`, target.value);
            }}
            decimalScale={0}
          />
        </Box>
        <Box sx={{ gridArea: 'coinToss' }}>
          <AccordionDtlsLabelText mb={1}>Coin Toss Winner</AccordionDtlsLabelText>
          <Box
            sx={{
              display: 'grid',
              alignItems: 'center',
              gridTemplateColumns: 'minmax(0, 5ch) minmax(0, 5ch) minmax(0, 5ch)',
              gap: '0.4rem',
            }}
          >
            <RedPlayerLabel />
            <RedBlueSwitch
              name={`fights[${idx}].coinTossWinner`}
              checked={values?.fights[idx]?.coinTossWinner === CORNER_COLORS.BLUE}
              onChange={({ target }) =>
                setFieldValue(
                  `fights[${idx}].coinTossWinner`,
                  target.checked ? CORNER_COLORS.BLUE : CORNER_COLORS.RED,
                )
              }
            />
            <BluePlayerLabel />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          mb: 2,
          display: 'grid',
          gridTemplateAreas: `"startDate endDate roundStatus"`,
          gridTemplateColumns: 'repeat(2, 40fr) 20fr',
          gap: '1rem',
        }}
      >
        <Box sx={{ gridArea: 'startDate' }}>
          <AccordionDtlsLabelText mb={1}>Start Time</AccordionDtlsLabelText>
          <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
            <DateTimePicker
              ampm={false}
              value={values.fights[idx].startDate || null}
              onChange={(newValue) => {
                setFieldValue(`fights[${idx}].startDate`, newValue);
              }}
              // disabled
              // onClose={() => {
              //   if (
              //     values.fights[idx].roundStatus === FightStatusValues[FightStatusNames.UPCOMING]
              //   ) {
              //     setFieldValue(
              //       `fights[${idx}].roundStatus`,
              //       FightStatusValues[FightStatusNames.LIVE],
              //     );
              //   }
              // }}
              slotProps={{
                textField: {
                  color: 'secondary',
                  fullWidth: true,
                  placeholder: 'Start Time',
                  sx: {
                    fontWeight: '400',
                    '& .MuiInputBase-input': {
                      padding: '0.6rem 1.5rem',
                      fontSize: '0.812rem',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                        borderLeft: '1px solid #C4C4C4',
                        borderTop: '1px solid #C4C4C4',
                        borderBottom: '1px solid #C4C4C4',
                        borderRadius: 0,
                        borderTopLeftRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                        '&:hover': {
                          borderColor: '#000000',
                        },
                      },
                    },
                  },
                  // onBlur: () => {
                  //   if (
                  //     values.fights[idx].roundStatus ===
                  //     FightStatusValues[FightStatusNames.UPCOMING]
                  //   ) {
                  //     setFieldValue(
                  //       `fights[${idx}].roundStatus`,
                  //       FightStatusValues[FightStatusNames.LIVE],
                  //     );
                  //   }
                  // },
                },
              }}
            />
            <Button
              variant="contained"
              disableElevation
              // disabled={!scr.startDate || !scr.winner}
              onClick={() => {
                toggleCustomStartFightDialog(scr);
              }}
              sx={{
                borderRadius: 0,
                borderTopRightRadius: '0.25rem',
                borderBottomRightRadius: '0.25rem',
              }}
            >
              Start Now
            </Button>
          </Box>
        </Box>
        <Box sx={{ gridArea: 'endDate' }}>
          <AccordionDtlsLabelText mb={1}>End Time</AccordionDtlsLabelText>
          <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
            <DateTimePicker
              ampm={false}
              value={values.fights[idx].endDate || null}
              onChange={(newValue) => {
                setFieldValue(`fights[${idx}].endDate`, newValue);
              }}
              onClose={() => {
                if (
                  values.fights[idx].roundStatus !== FightStatusValues[FightStatusNames.COMPLETED]
                ) {
                  setFieldValue(
                    `fights[${idx}].roundStatus`,
                    FightStatusValues[FightStatusNames.COMPLETED],
                  );
                }
              }}
              disabled
              slotProps={{
                textField: {
                  color: 'secondary',
                  fullWidth: true,
                  placeholder: 'End Time',
                  sx: {
                    fontWeight: '400',
                    '& .MuiInputBase-input': {
                      padding: '0.6rem 1.5rem',
                      fontSize: '0.812rem',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                        borderLeft: '1px solid #C4C4C4',
                        borderTop: '1px solid #C4C4C4',
                        borderBottom: '1px solid #C4C4C4',
                        borderRadius: 0,
                        borderTopLeftRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                        '&:hover': {
                          borderColor: '#000000',
                        },
                      },
                    },
                  },
                  onBlur: () => {
                    if (
                      values.fights[idx].roundStatus !==
                      FightStatusValues[FightStatusNames.COMPLETED]
                    ) {
                      setFieldValue(
                        `fights[${idx}].roundStatus`,
                        FightStatusValues[FightStatusNames.COMPLETED],
                      );
                    }
                  },
                },
              }}
            />
            <Button
              variant="contained"
              disableElevation
              // disabled={!scr.startDate || !scr.winner}
              onClick={() => {
                toggleCustomEndFightDialog(scr);
              }}
              sx={{
                borderRadius: 0,
                borderTopRightRadius: '0.25rem',
                borderBottomRightRadius: '0.25rem',
              }}
            >
              End Now
            </Button>
          </Box>
        </Box>
        <Box sx={{ gridArea: 'roundStatus' }}>
          <AccordionDtlsLabelText mb={1}>Fight Status</AccordionDtlsLabelText>
          <ModSelect
            // name={`fights[${idx}].weightClass`}
            value={scr?.roundStatus}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            fullWidth
            onChange={({ target }) => {
              setFieldValue(`fights[${idx}].roundStatus`, +target?.value as unknown as number);
            }}
          >
            {React.Children.toArray(
              FIGHT_STATUS_OPTIONS.map((status: any) => (
                <MenuItem
                  value={status[0]}
                  disabled={
                    values.fights[idx].startDate &&
                    +status[0] === FightStatusValues[FightStatusNames.UPCOMING]
                      ? true
                      : values.fights[idx].endDate &&
                        (+status[0] === FightStatusValues[FightStatusNames.LIVE] ||
                          +status[0] === FightStatusValues[FightStatusNames.UPCOMING])
                      ? true
                      : false
                  }
                >
                  {status[1]}
                </MenuItem>
              )),
            )}
          </ModSelect>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `"wgtClass redCornerAthlete blueCornerAthlete scheduledRounds"`,
          gridTemplateColumns: '10fr 40fr 40fr 10fr',
          alignItems: 'center',
          gap: '0.5rem',
        }}
      >
        <Box sx={{ gridArea: 'wgtClass' }}>
          <AccordionDtlsLabelText mb={1}>Weight Class</AccordionDtlsLabelText>
          <ModSelect
            // name={`fights[${idx}].weightClass`}
            value={scr?.weightClass}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            fullWidth
            onChange={({ target }) => {
              setFieldValue(`fights[${idx}].weightClass`, target?.value);
            }}
          >
            {React.Children.toArray(
              WEIGHT_CLASSES.map((wgt: any) => (
                <MenuItem value={wgt?.value}>{wgt?.label}</MenuItem>
              )),
            )}
          </ModSelect>
        </Box>
        <Box sx={{ gridArea: 'redCornerAthlete' }}>
          <FormControl error={errors?.fights?.[idx]?.redCornerAthlete ? true : false} fullWidth>
            <AccordionDtlsLabelText mb={1}>Red Corner</AccordionDtlsLabelText>
            <CustomAthleteSelect
              value={values?.fights[idx]?.redCornerAthlete}
              athleteColor={RED_CORNER_COLOR}
              handleBlur={(val) => {
                const currentAthlete = val;
                const prevAthlete = values?.fights[idx]?.redCornerAthlete;
                const currentWinner = values?.fights[idx]?.winner;
                const isPrevAthleteWinner = currentWinner && prevAthlete?.id === currentWinner?.id;
                const currentRounds = [...(values?.fights[idx]?.rounds || [])];
                // Note: Scores Athlete modification is skipped cause current athlete type (red, blue) is taken while submitting
                setFieldValue(`fights[${idx}].redCornerAthlete`, currentAthlete);
                setFieldValue(
                  `fights[${idx}].rounds`,
                  currentRounds?.map((rnd) => {
                    return {
                      ...rnd,
                      playerRoundStats: rnd.playerRoundStats.map((plyRndStats: any) => {
                        if (plyRndStats.cornerType === CORNER_COLORS.RED) {
                          plyRndStats.athlete = currentAthlete;
                          plyRndStats.athleteId = currentAthlete?.id;
                        }
                        return plyRndStats;
                      }),
                    };
                  }),
                );
                if (isPrevAthleteWinner) {
                  setFieldValue(`fights[${idx}].winner`, currentAthlete);
                }
              }}
              ATHLETE_OPTIONS={ATHLETE_OPTIONS}
              skipAthlete={values?.fights[idx]?.blueCornerAthlete}
            />
            {errors?.fights?.[idx]?.redCornerAthlete && (
              <FormHelperText>{errors?.fights?.[idx]?.redCornerAthlete}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box sx={{ gridArea: 'blueCornerAthlete' }}>
          <FormControl error={errors?.fights?.[idx]?.blueCornerAthlete ? true : false} fullWidth>
            <AccordionDtlsLabelText mb={1}>Blue Corner</AccordionDtlsLabelText>
            <CustomAthleteSelect
              value={values?.fights[idx]?.blueCornerAthlete}
              athleteColor={BLUE_CORNER_COLOR}
              handleBlur={(val) => {
                const currentAthlete = val;
                const prevAthlete = values?.fights[idx]?.blueCornerAthlete;
                const currentWinner = values?.fights[idx]?.winner;
                const isPrevAthleteWinner = currentWinner && prevAthlete?.id === currentWinner?.id;
                const currentRounds = [...(values?.fights[idx]?.rounds || [])];
                // Note: Scores Athlete modification is skipped cause current athlete type (red, blue) is taken while submitting
                setFieldValue(`fights[${idx}].blueCornerAthlete`, currentAthlete);
                setFieldValue(
                  `fights[${idx}].rounds`,
                  currentRounds?.map((rnd) => {
                    return {
                      ...rnd,
                      playerRoundStats: rnd.playerRoundStats.map((plyRndStats: any) => {
                        if (plyRndStats.cornerType === CORNER_COLORS.BLUE) {
                          plyRndStats.athlete = currentAthlete;
                          plyRndStats.athleteId = currentAthlete?.id;
                        }
                        return plyRndStats;
                      }),
                    };
                  }),
                );
                if (isPrevAthleteWinner) {
                  setFieldValue(`fights[${idx}].winner`, currentAthlete);
                }
              }}
              ATHLETE_OPTIONS={ATHLETE_OPTIONS}
              skipAthlete={values?.fights[idx]?.redCornerAthlete}
            />
            {errors?.fights?.[idx]?.blueCornerAthlete && (
              <FormHelperText>{errors?.fights?.[idx]?.blueCornerAthlete}</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Box sx={{ gridArea: 'scheduledRounds' }}>
          <AccordionDtlsLabelText mb={1}>Scheduled Rounds</AccordionDtlsLabelText>
          <CounterButton
            value={values.fights[idx].scheduledRounds || 0}
            changeHandler={(val: number) => {
              const currentScheduledRounds = values.fights[idx].scheduledRounds;
              const diffScheduledRounds = val - currentScheduledRounds;
              setFieldValue(`fights[${idx}].scheduledRounds`, val);
              if (diffScheduledRounds > 0) {
                setFieldValue(`fights[${idx}].rounds`, [
                  ...values.fights[idx].rounds,
                  ...Array.from({ length: diffScheduledRounds }, (_, tempId: number) => ({
                    ...{
                      roundSlapNo: 1,
                      name: `${tempId + currentScheduledRounds + 1}`,
                      startDate: null,
                      endDate: null,
                      heatStatus: FightStatusValues[FightStatusNames.UPCOMING],
                    },
                    playerRoundStats: [
                      {
                        ...DEFAULT_ROUND_SCORE_OBJ,
                        roundSlapNo: 1,
                        roundSlapName: `${tempId + currentScheduledRounds + 1}`,
                        athlete: values?.fights[idx]?.redCornerAthlete,
                        athleteId: values?.fights[idx]?.redCornerAthlete?.id,
                      },
                      {
                        ...DEFAULT_ROUND_SCORE_OBJ,
                        roundSlapNo: 1,
                        roundSlapName: `${tempId + currentScheduledRounds + 1}`,
                        cornerType: CORNER_COLORS.BLUE,
                        athlete: values?.fights[idx]?.blueCornerAthlete,
                        athleteId: values?.fights[idx]?.blueCornerAthlete?.id,
                      },
                    ],
                  })),
                ]);
              }

              if (diffScheduledRounds < 0) {
                setFieldValue(`fights[${idx}].rounds`, [
                  ...values.fights[idx].rounds.slice(0, diffScheduledRounds),
                ]);
              }
            }}
            bgColor="#F8F9FA"
          />
        </Box>
      </Box>
    </Box>
  );
};

const RoundDetails = ({
  scr,
  idx,
  id,
  setFieldValue,
  values,
  FIGHT_STATUS_OPTIONS,
  redCornerAthlete,
  blueCornerAthlete,
  toggleCustomRoundTimeDialog,
  WINNER_OPTIONS,
  FOULS,
}: any) => {
  const handleStartTimeChange = (newValue: any) => {
    setFieldValue(`fights[${idx}].rounds[${id}].startDate`, newValue);
    // if (values.fights[idx].heatStatus === FightStatusValues[FightStatusNames.UPCOMING]) {
    //   setFieldValue(
    //     `fights[${idx}].rounds[${id}].heatStatus`,
    //     FightStatusValues[FightStatusNames.LIVE],
    //   );
    // }
  };

  const handleEndTimeChange = (newValue: any) => {
    setFieldValue(`fights[${idx}].rounds[${id}].endDate`, newValue);
    if (values.fights[idx].heatStatus !== FightStatusValues[FightStatusNames.COMPLETED]) {
      setFieldValue(
        `fights[${idx}].rounds[${id}].heatStatus`,
        FightStatusValues[FightStatusNames.COMPLETED],
      );
    }
  };

  const handleHeatStatusChange = (event: any) => {
    setFieldValue(
      `fights[${idx}].rounds[${id}].heatStatus`,
      +event.target?.value as unknown as number,
    );
  };

  const handleAddReslap = () => {
    const lastPlayerRoundStats =
      values.fights[idx].rounds[id].playerRoundStats[
        values.fights[idx].rounds[id].playerRoundStats.length - 1
      ];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { cornerType, ...restDefaultRoundScoreOBJ } = DEFAULT_ROUND_SCORE_OBJ;
    setFieldValue(`fights[${idx}].rounds[${id}].playerRoundStats`, [
      ...values.fights[idx].rounds[id].playerRoundStats,
      {
        ...restDefaultRoundScoreOBJ,
        roundSlapNo: lastPlayerRoundStats.roundSlapNo + 1,
        roundSlapName: null,
      },
    ]);
  };

  const handleRemoveReslap = (deleteId: number) => {
    setFieldValue(
      `fights[${idx}].rounds[${id}].playerRoundStats`,
      [...(values.fights[idx].rounds[id].playerRoundStats || [])].filter(
        (el, index) => index !== deleteId,
      ),
    );
  };

  const currentRoundStats = React.useMemo(() => {
    const playerRoundStatsCpy = [...values.fights[idx].rounds[id].playerRoundStats];
    const [firstPlayerStat, secondPlayerStat] = playerRoundStatsCpy;
    if (firstPlayerStat.cornerType !== values.fights[idx].coinTossWinner) {
      playerRoundStatsCpy[0] = secondPlayerStat;
      playerRoundStatsCpy[1] = firstPlayerStat;
      setFieldValue(`fights[${idx}].rounds[${id}].playerRoundStats`, playerRoundStatsCpy);
    }
    return playerRoundStatsCpy;
  }, [values.fights[idx].rounds[id].playerRoundStats, values.fights[idx].coinTossWinner]);

  return (
    <Box sx={{ p: 4, border: '1px solid #DEE2E6', borderRadius: '0.5rem', mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem', mb: 3 }}>
        <AccordionSummaryTextLg>{`Round ${
          getNumberFromCharacters(values?.fights[idx]?.rounds[id].name || '') || 0
        }`}</AccordionSummaryTextLg>
        {processMatchStatusBadges(values?.fights[idx]?.rounds[id]?.heatStatus || 2)}
      </Box>
      <Box
        sx={{
          mb: 2,
          display: 'grid',
          gridTemplateAreas: `"startDate endDate roundStatus"`,
          gridTemplateColumns: 'repeat(2, 40fr) 20fr',
          gap: '1rem',
        }}
      >
        <Box sx={{ gridArea: 'startDate' }}>
          <AccordionDtlsLabelText mb={1}>Start Time</AccordionDtlsLabelText>
          <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
            <DateTimePicker
              ampm={false}
              value={values.fights[idx].rounds[id].startDate || null}
              onChange={handleStartTimeChange}
              // disabled
              slotProps={{
                textField: {
                  color: 'secondary',
                  fullWidth: true,
                  placeholder: 'Start Time',
                  sx: {
                    fontWeight: '400',
                    '& .MuiInputBase-input': { padding: '0.6rem 1.5rem', fontSize: '0.812rem' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                        borderLeft: '1px solid #C4C4C4',
                        borderTop: '1px solid #C4C4C4',
                        borderBottom: '1px solid #C4C4C4',
                        borderRadius: 0,
                        borderTopLeftRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                        '&:hover': { borderColor: '#000000' },
                      },
                    },
                  },
                  // onBlur: () => {
                  //   if (
                  //     values.fights[idx].rounds[id].heatStatus ===
                  //     FightStatusValues[FightStatusNames.UPCOMING]
                  //   ) {
                  //     setFieldValue(
                  //       `fights[${idx}].rounds[${id}].heatStatus`,
                  //       FightStatusValues[FightStatusNames.LIVE],
                  //     );
                  //   }
                  // },
                },
              }}
            />
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                toggleCustomRoundTimeDialog({
                  fightId: idx,
                  roundId: id,
                  timeType: TIME_TYPE.startDate,
                });
              }}
              sx={{
                borderRadius: 0,
                borderTopRightRadius: '0.25rem',
                borderBottomRightRadius: '0.25rem',
              }}
            >
              Start Now
            </Button>
          </Box>
        </Box>
        <Box sx={{ gridArea: 'endDate' }}>
          <AccordionDtlsLabelText mb={1}>End Time</AccordionDtlsLabelText>
          <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
            <DateTimePicker
              ampm={false}
              value={values.fights[idx].rounds[id].endDate || null}
              onChange={handleEndTimeChange}
              disabled
              slotProps={{
                textField: {
                  color: 'secondary',
                  fullWidth: true,
                  placeholder: 'End Time',
                  sx: {
                    fontWeight: '400',
                    '& .MuiInputBase-input': { padding: '0.6rem 1.5rem', fontSize: '0.812rem' },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        border: 'none',
                        borderLeft: '1px solid #C4C4C4',
                        borderTop: '1px solid #C4C4C4',
                        borderBottom: '1px solid #C4C4C4',
                        borderRadius: 0,
                        borderTopLeftRadius: '0.25rem',
                        borderBottomLeftRadius: '0.25rem',
                        '&:hover': { borderColor: '#000000' },
                      },
                    },
                  },
                },
              }}
            />
            <Button
              variant="contained"
              disableElevation
              onClick={() => {
                toggleCustomRoundTimeDialog({
                  fightId: idx,
                  roundId: id,
                  timeType: TIME_TYPE.endDate,
                });
              }}
              sx={{
                borderRadius: 0,
                borderTopRightRadius: '0.25rem',
                borderBottomRightRadius: '0.25rem',
              }}
            >
              End Now
            </Button>
          </Box>
        </Box>
        <Box sx={{ gridArea: 'roundStatus' }}>
          <AccordionDtlsLabelText mb={1}>Round Status</AccordionDtlsLabelText>
          <ModSelect
            value={values.fights[idx].rounds[id]?.heatStatus}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            fullWidth
            onChange={handleHeatStatusChange}
          >
            {React.Children.toArray(
              FIGHT_STATUS_OPTIONS.map((status: any) => (
                <MenuItem
                  value={status[0]}
                  disabled={
                    (values.fights[idx].startDate &&
                      +status[0] === FightStatusValues[FightStatusNames.UPCOMING]) ||
                    (values.fights[idx].endDate &&
                      (+status[0] === FightStatusValues[FightStatusNames.LIVE] ||
                        +status[0] === FightStatusValues[FightStatusNames.UPCOMING]))
                  }
                >
                  {status[1]}
                </MenuItem>
              )),
            )}
          </ModSelect>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns:
            'minmax(0, 10fr) minmax(0, 20fr) minmax(0, 58fr) minmax(0, 10fr) minmax(0, 2fr)',
          mb: 2,
          gap: '1rem',
        }}
      >
        <AccordionDtlsLabelText mb={1}>Round Slap</AccordionDtlsLabelText>
        <AccordionDtlsLabelText mb={1}>Athletes</AccordionDtlsLabelText>
        <AccordionDtlsLabelText mb={1}>Fouls</AccordionDtlsLabelText>
        <AccordionDtlsLabelText mb={1}>Deducted</AccordionDtlsLabelText>
      </Box>
      {React.Children.toArray(
        currentRoundStats?.map((_, scrId) => (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns:
                'minmax(0, 10fr) minmax(0, 20fr) minmax(0, 58fr) minmax(0, 10fr) minmax(0, 2fr)',
              mb: 2,
              gap: '1rem',
            }}
          >
            <ModTextField
              name={`fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].roundSlapNo`}
              value={scr?.rounds[id].playerRoundStats[scrId].roundSlapNo}
              onChange={({ target }) => {
                // if (!existingSlapNumbers.includes(Number(target.value)))
                //   setFieldValue(
                //     `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].roundSlapNo`,
                //     +target.value,
                //   );
                const currentValue =
                  values?.fights[idx]?.rounds[id].playerRoundStats[scrId].roundSlapNo;
                const newValue = getNumberFromCharacters(target.value);
                const playerStats = values?.fights[idx]?.rounds[id]?.playerRoundStats || [];
                const currentStat = playerStats[scrId];
                const currentCornerType = currentStat?.cornerType;

                const existingSlapNumbers = playerStats
                  .filter((stat: any) =>
                    currentCornerType ? stat?.cornerType === currentCornerType : true,
                  )
                  .map((stat: any) => +stat?.roundSlapNo);

                if (!existingSlapNumbers.includes(newValue)) {
                  setFieldValue(
                    `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].roundSlapNo`,
                    newValue,
                  );
                } else {
                  setFieldValue(
                    `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].roundSlapNo`,
                    currentValue,
                  );
                }
              }}
              disabled={[0, 1].includes(scrId)}
            />
            <CustomRoundAthleteSelect
              name={`fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].athlete`}
              value={values?.fights[idx]?.rounds[id].playerRoundStats[scrId]?.athlete}
              disabled={[0, 1].includes(scrId)}
              handleBlur={(currentAthlete: any) => {
                setFieldValue(
                  `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].athlete`,
                  currentAthlete,
                );
                setFieldValue(
                  `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].cornerType`,
                  currentAthlete?.id === redCornerAthlete?.id
                    ? CORNER_COLORS.RED
                    : currentAthlete?.id === blueCornerAthlete?.id
                    ? CORNER_COLORS.BLUE
                    : null,
                );
              }}
              ATHLETE_OPTIONS={WINNER_OPTIONS}
              redCornerAthlete={redCornerAthlete}
              blueCornerAthlete={blueCornerAthlete}
            />

            <ModAutocompleteMultiple
              value={values?.fights[idx]?.rounds[id].playerRoundStats[scrId].fouls}
              onChange={(_, newValue) => {
                setFieldValue(
                  `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].fouls`,
                  typeof newValue === 'string'
                    ? { label: newValue }
                    : newValue?.inputValue
                    ? { label: newValue.inputValue, inputValue: newValue.inputValue }
                    : newValue.map((newVal: any) =>
                        newVal?.inputValue
                          ? { label: newVal?.inputValue, inputValue: newVal?.inputValue }
                          : newVal,
                      ),
                );
              }}
              options={FOULS}
            />
            <ModDecimalField
              name={`fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].deducted`}
              value={values?.fights[idx]?.rounds[id].playerRoundStats[scrId].deducted}
              fullWidth
              onBlur={({ target }) => {
                setFieldValue(
                  `fights[${idx}].rounds[${id}].playerRoundStats[${scrId}].deducted`,
                  target.value,
                );
              }}
              decimalScale={0}
            />
            <Box>
              {values.fights[idx].rounds[id].playerRoundStats.length > 2 && scrId > 1 && (
                <Button
                  startIcon={<DeleteIcon />}
                  color="error"
                  disableElevation
                  disableRipple
                  onClick={() => handleRemoveReslap(scrId)}
                />
              )}
            </Box>
          </Box>
        )),
      )}

      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Button
          startIcon={<ControlPointIcon />}
          color="primary"
          disableElevation
          disableRipple
          onClick={handleAddReslap}
        >
          Add Reslap
        </Button>
      </Box>
    </Box>
  );
};

const FightScores = ({
  scr,
  idx,
  values,
  setFieldValue,
  JUDGE_OPTIONS,
  REFEREE_OPTIONS,
  WINNER_OPTIONS,
  WIN_BY_OPTIONS_POWERSLAP,
  redCornerAthlete,
  blueCornerAthlete,
}: any) => {
  return (
    <Box sx={{ p: 4, border: '1px solid #DEE2E6', borderRadius: '0.5rem' }}>
      <Typography
        sx={{ fontSize: '1.125rem', lineHeight: '1.5rem', fontWeight: 500, mb: 1, width: '100%' }}
      >
        End of the fight
      </Typography>
      <AccordionDtlsLabelText width="100%" mb={1}>
        Referee
      </AccordionDtlsLabelText>

      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `"refereeBlock"`,
          gridTemplateColumns: 'minmax(0, 33fr) minmax(0, 67fr)',
          alignItems: 'center',
          gap: '0.6rem',
          mb: 2,
        }}
      >
        <Box sx={{ gridArea: 'refereeBlock' }}>
          <ModAutocomplete
            value={values?.fights[idx]?.referee}
            onChange={(_, newValue: any) => {
              setFieldValue(
                `fights[${idx}].referee`,
                typeof newValue === 'string'
                  ? { label: newValue }
                  : newValue?.inputValue
                  ? { label: newValue.inputValue, inputValue: newValue.inputValue }
                  : newValue,
              );
            }}
            options={REFEREE_OPTIONS}
          />
        </Box>
      </Box>
      <AccordionDtlsLabelText width="100%" mb={1}>
        Scores
      </AccordionDtlsLabelText>
      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `"judge1 judge2 judge3"`,
          gridTemplateColumns: 'repeat(3, 33fr)',
          alignItems: 'center',
          gap: '0.6rem',
          mb: 2,
        }}
      >
        {React.Children.toArray(
          values?.fights?.[idx]?.judges.map((_, jdgId) => (
            <Box sx={{ gridArea: `judge${jdgId + 1}` }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }} mb={1}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                  <Box
                    component="img"
                    src={JudgeIcon}
                    alt="Judge Icon"
                    sx={{
                      width: '1rem',
                      height: '1rem',
                      display: 'grid',
                      gridTemplateColumns: '80fr 20fr',
                      gap: '0.5rem',
                    }}
                  />{' '}
                  <AccordionDtlsLabelText>Judge #{jdgId + 1}</AccordionDtlsLabelText>
                </Box>
                <ScoreLabels />
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }}>
                <ModAutocomplete
                  value={values?.fights?.[idx]?.judges[jdgId]?.name}
                  onChange={(_, newValue: any) => {
                    setFieldValue(
                      `fights[${idx}].judges[${jdgId}].name`,
                      typeof newValue === 'string'
                        ? { label: newValue }
                        : newValue?.inputValue
                        ? { label: newValue.inputValue, inputValue: newValue.inputValue }
                        : newValue,
                    );
                  }}
                  options={JUDGE_OPTIONS}
                />
                <JudgeScores
                  score1={values?.fights?.[idx]?.judges[jdgId]?.score1}
                  score2={values?.fights?.[idx]?.judges[jdgId]?.score2}
                  handleChangeScr1={({ target: { value } }: any) =>
                    setFieldValue(`fights[${idx}].judges[${jdgId}].score1`, value)
                  }
                  handleChangeScr2={({ target: { value } }: any) =>
                    setFieldValue(`fights[${idx}].judges[${jdgId}].score2`, value)
                  }
                />
              </Box>
            </Box>
          )),
        )}
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateAreas: `"winner lastRound blank"`,
          gridTemplateColumns: '33fr 10fr 57fr',
          gap: '1rem',
        }}
      >
        <Box sx={{ gridArea: 'winner' }}>
          <AccordionDtlsLabelText width="100%" mb={1}>
            Winner
          </AccordionDtlsLabelText>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50fr 4ch 40fr ',
              alignItems: 'center',
              mb: 2,
            }}
          >
            <ModSelect
              value={scr?.winner}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              fullWidth
              onChange={({ target }) => {
                setFieldValue(`fights[${idx}].winner`, target.value);
                setFieldValue(`fights[${idx}].winnerAthleteId`, target?.value?.id || null);
              }}
              sx={{ backgroundColor: '#ffffff' }}
              renderValue={(selected) => {
                const colorIcon =
                  selected && selected?.id === redCornerAthlete?.id ? (
                    <Box
                      sx={{
                        display: 'inline-flex',
                        borderRadius: '50%',
                        height: '0.5rem',
                        width: '0.5rem',
                        backgroundColor: RED_CORNER_COLOR,
                      }}
                    ></Box>
                  ) : selected && selected?.id === blueCornerAthlete?.id ? (
                    <Box
                      sx={{
                        display: 'inline-flex',
                        borderRadius: '50%',
                        height: '0.5rem',
                        width: '0.5rem',
                        backgroundColor: BLUE_CORNER_COLOR,
                      }}
                    ></Box>
                  ) : null;
                return (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                    {colorIcon && colorIcon}
                    <Typography>{selected?.label || 'Please select winner'}</Typography>
                  </Box>
                );
              }}
            >
              {React.Children.toArray(
                WINNER_OPTIONS.map((athlete: any) => (
                  <MenuItem value={athlete}>{athlete?.label}</MenuItem>
                )),
              )}
            </ModSelect>
            <AccordionDtlsLabelText sx={{ textAlign: 'center' }}>by</AccordionDtlsLabelText>
            <ModSelect
              value={scr?.winBy}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              fullWidth
              sx={{ backgroundColor: '#ffffff' }}
              onChange={({ target }) => {
                setFieldValue(`fights[${idx}].winBy`, target.value);
              }}
            >
              {React.Children.toArray(
                WIN_BY_OPTIONS_POWERSLAP.map((dec: any) => (
                  <MenuItem value={dec?.value}>{dec?.label}</MenuItem>
                )),
              )}
            </ModSelect>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: 'grid', gridTemplateColumns: '35fr 65fr', alignItems: 'center', mb: 2 }}>
        <Box>
          <AccordionDtlsLabelText mb={1}>Notes</AccordionDtlsLabelText>
          <CustomMultilineInput
            value={values?.fights[idx]?.notes || ''}
            handleBlur={({ target }: any) => {
              setFieldValue(`fights[${idx}].notes`, target.value);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const DEFAULT_ROUND_TIME_DETAILS = {
  fightId: -1,
  roundId: -1,
  timeType: undefined,
};

function POWERSLAPEventContainer() {
  // const [showDeleteFightDialog, setShowDeleteFightDialog] = React.useState(false);
  const [fightData, setFightData] = React.useState(undefined);
  const [showStartFightDialog, setShowStartFightDialog] = React.useState(false);
  const [showEndFightDialog, setShowEndFightDialog] = React.useState(false);
  const [showRoundTimeDialog, setShowRoundTimeDialog] = React.useState(false);
  const [roundTimeDtls, setRoundTimeDtls] = React.useState(DEFAULT_ROUND_TIME_DETAILS);
  const { eventId = '' } = useParams();
  const queryClient = useQueryClient();
  const { data: eventScores } = useFetchEventFightScores(SPORT_TYPES.POWERSLAP, eventId);
  const { data: eventSeeds } = useFetchFightParticipants({
    sport: SPORT_TYPES.POWERSLAP,
    eventId,
    archived: ARCHIVED,
  });

  // const event: any = queryClient.getQueryData([
  //   keys.events.fetchEvent,
  //   SPORT_TYPES.POWERSLAP,
  //   eventId,
  //   'Event',
  // ]);

  const { mutateAsync: addPOWERSLAPScores } = useAddPOWERSLAPScores();
  const { mutateAsync: updatePOWERSLAPScores } = useUpdatePOWERSLAPScores();
  // const { mutateAsync: deletePOWERSLAPScores } = useDeletePOWERSLAPScores();
  const { mutateAsync: updatePOWERSLAPRounds } = useUpdatePOWERSLAPRounds();

  const { data: weightClasses } = useFetchWeightClasses(SPORT_TYPES.POWERSLAP, 'Scores');

  const WEIGHT_CLASSES = React.useMemo(
    () =>
      weightClasses ? weightClasses?.map((wgtClass) => ({ label: wgtClass, value: wgtClass })) : [],
    [weightClasses],
  );

  const toggleCustomStartFightDialog = (data: any) => {
    setShowStartFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const toggleCustomEndFightDialog = (data: any) => {
    setShowEndFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const toggleCustomRoundTimeDialog = (data: any) => {
    setShowRoundTimeDialog((prev) => !prev);
    if (data) {
      setRoundTimeDtls(data);
    }
  };

  const ATHLETE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds
        ?.filter((seed: any) => seed?.athlete)
        ?.map((seed: any) => {
          const { athlete } = seed;
          const fullName = [athlete?.firstName, athlete?.middleName, athlete?.lastName]
            .filter(Boolean)
            .join(' ');
          const nickName = athlete?.nickName ? ` (${athlete.nickName})` : '';
          const weightClass = athlete?.weightClass
            ? ` - ${v.upperCase(getFirstLettersCombined(athlete?.weightClass || ''))}`
            : '';

          return {
            ...athlete,
            label: `${fullName}${nickName}${weightClass}`,
            value: athlete?.id,
            firstName: athlete?.firstName,
            middleName: athlete?.middleName,
            lastName: athlete?.lastName,
            nickName: athlete?.nickName,
            weightClass: athlete?.weightClass,
          };
        }) || []
    );
  }, [eventSeeds]);

  const FIGHT_STATUS_OPTIONS = React.useMemo(
    () =>
      Object.entries(FightStatusKeys).filter((status) =>
        [
          FightStatusNames.UPCOMING,
          FightStatusNames.LIVE,
          FightStatusNames.COMPLETED,
          FightStatusNames.CANCELED,
        ].includes(status[1]),
      ),
    [],
  );

  const refreshScores = () => {
    setTimeout(
      () =>
        queryClient.invalidateQueries([
          keys.events.fetchEventScores,
          SPORT_TYPES.POWERSLAP,
          eventId,
        ]),
      2500,
    );
    queryClient.invalidateQueries([
      keys.fights.fetchFightParticipants,
      SPORT_TYPES.POWERSLAP,
      eventId,
      ARCHIVED,
    ]);
    if (fightData) {
      setFightData(undefined);
    }
  };

  const processFight = (fight: any) => {
    // Extract heats data from the fight object
    const { heats: heatsData } = fight;
    // Get the first round of heats data or an empty object if heatsData is undefined
    const [firstRound = {}] = heatsData || [];

    // Find the selected blue corner athlete from either the fight scores or the first round subHeats
    const selectedBlueCornerAthlete =
      fight?.scores?.find((scr: any) => scr?.cornerType === CORNER_COLORS.BLUE) ||
      firstRound?.subHeats?.find((sHeat: any) => sHeat?.cornerType === CORNER_COLORS.BLUE);
    // Find the selected red corner athlete from either the fight scores or the first round subHeats
    const selectedRedCornerAthlete =
      fight?.scores?.find((scr: any) => scr?.cornerType === CORNER_COLORS.RED) ||
      firstRound?.subHeats?.find((sHeat: any) => sHeat?.cornerType === CORNER_COLORS.RED);

    // Find the blue corner athlete details from ATHLETE_OPTIONS using the selected athlete's ID
    const blueCornerAthlete = ATHLETE_OPTIONS.find(
      (athlete: any) => athlete.id === selectedBlueCornerAthlete?.athleteId,
    );
    // Find the red corner athlete details from ATHLETE_OPTIONS using the selected athlete's ID
    const redCornerAthlete = ATHLETE_OPTIONS.find(
      (athlete: any) => athlete.id === selectedRedCornerAthlete?.athleteId,
    );
    // Find the winner athlete details from ATHLETE_OPTIONS using the winner's ID
    const winner = ATHLETE_OPTIONS.find((athlete: any) => athlete.id === fight.winnerAthleteId);

    // Group fight scores by judge number
    const groupedByJudgeNo = Object.values(
      groupBy(sortBy(fight?.scores || [], ['judgeNo']), 'judgeNo'),
    );

    // Map each group of judge scores to an object containing the judge's name and their scores for each corner
    const judges = groupedByJudgeNo.map((fights) => ({
      name: fights[0]?.judge ? { label: fights[0]?.judge } : null,
      score1: fights.find((judgeScr: any) => judgeScr.cornerType === CORNER_COLORS.RED)?.score || 0,
      score2:
        fights.find((judgeScr: any) => judgeScr.cornerType === CORNER_COLORS.BLUE)?.score || 0,
    }));

    // Sort heats by a custom number derived from heatName
    const sortByHeatName = sortBy(
      fight?.heats?.map((ht: any) => ({
        ...ht,
        customNo: getNumberFromCharacters(ht?.heatName),
      })) || [],
      ['customNo'],
    );

    // Map sorted heats to rounds with additional data transformations
    const rounds = sortByHeatName.map((round) => {
      const { subHeats, heatName, startDate, endDate, ...restOfRoundData } = round;
      // Group subHeats by roundSlapNo and order them by corner type
      const groupedSubHeatsByRoundSlapNo = Object.values(groupBy(subHeats || [], 'roundSlapNo'))
        .map((val) => orderBy(val, [(sHt) => CORNER_COLORS_ORDER[sHt?.cornerType]], ['asc']))
        .flat();
      // Map each grouped subHeat to include fouls data
      const playerRoundStats = groupedSubHeatsByRoundSlapNo.map((playerRndStat: any) => {
        const athlete = ATHLETE_OPTIONS.find(
          (athlete: any) => athlete.id === playerRndStat?.athleteId,
        );

        const fouls = playerRndStat.fouls.map((foul: string) => ({ label: foul }));
        return { ...playerRndStat, athlete, fouls };
      });
      // Return the round data with additional formatted fields
      return {
        ...restOfRoundData,
        name: heatName,
        startDate: startDate ? format(startDate, undefined, true) : null,
        endDate: endDate ? format(endDate, undefined, true) : null,
        playerRoundStats,
      };
    });

    const coinTossWinner =
      redCornerAthlete && blueCornerAthlete && fight?.tossWinnerAthleteId
        ? fight?.tossWinnerAthleteId === redCornerAthlete?.id
          ? CORNER_COLORS.RED
          : CORNER_COLORS.BLUE
        : CORNER_COLORS.RED;
    // Return the processed fight data with additional fields and transformations
    return {
      ...fight,
      coinTossWinner,
      name: fight.name || '',
      blueCornerAthlete: blueCornerAthlete || null,
      redCornerAthlete: redCornerAthlete || null,
      startDate: fight.startDate ? format(fight.startDate, undefined, true) : null,
      endDate: fight.endDate ? format(fight.endDate, undefined, true) : null,
      winner: winner || null,
      referee: fight.referee ? { label: fight.referee } : null,
      judges,
      rounds,
    };
  };

  const initialScoresFormatter = (fightArr: any) => {
    if (!fightArr) return [];

    return fightArr.map(processFight);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fights: [],
    },
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const { values, errors, setFieldValue, setFieldError, handleBlur } = formik;

  const mergeFights = ({ originalFights, newFights }: any) => {
    if (originalFights.length === 0) return newFights;

    const newFightsMap = new Map(newFights?.map((fight: any) => [fight?.id, fight]));

    const mergedFights = originalFights?.map((fight: any) => {
      if (newFightsMap.has(fight.id)) {
        const newFight = newFightsMap.get(fight.id);
        return newFight;
      }

      return fight;
    });

    return mergedFights;
  };

  React.useEffect(() => {
    if (eventScores?.length > 0) {
      setFieldValue(
        'fights',
        mergeFights({
          originalFights: initialScoresFormatter(values?.fights),
          newFights: initialScoresFormatter(eventScores),
        }),
      );
    }
  }, [eventScores, setFieldValue, ATHLETE_OPTIONS]);

  const FOULS = React.useMemo(() => {
    const ALL_FOULS = [...POWERSLAP_FOULS];
    values?.fights?.forEach((fgt: any) => {
      fgt?.rounds?.forEach((rnd: any) => {
        rnd?.playerRoundStats?.forEach((stat: any) => {
          stat?.fouls?.forEach((foul: any) => {
            if (!POWERSLAP_FOULS.includes(foul?.inputValue || foul?.label)) {
              ALL_FOULS.push(foul?.inputValue || foul?.label || '');
            }
          });
        });
      });
    });
    return ALL_FOULS.filter(Boolean).map((foul) => ({ label: foul }));
  }, [values?.fights]);

  const JUDGE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds
        ?.filter((seed: any) => seed?.judge)
        ?.map((seed: any) => {
          const { judge } = seed;
          const fullName = [judge?.firstName, judge?.middleName, judge?.lastName]
            .filter(Boolean)
            .join(' ');

          return {
            label: `${fullName}`,
          };
        }) || []
    );
  }, [eventSeeds]);

  const REFEREE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds
        ?.filter((seed: any) => seed?.referee)
        ?.map((seed: any) => {
          const { referee } = seed;
          const fullName = [referee?.firstName, referee?.middleName, referee?.lastName]
            .filter(Boolean)
            .join(' ');

          return {
            label: `${fullName}`,
          };
        }) || []
    );
  }, [eventSeeds]);

  const [accordion, setAccordion] = React.useState<{ [key: string]: boolean }>(
    values?.fights?.reduce(
      (o: any, val: { id: string; roundStatus: number; roundHeatId: string }) => ({
        ...o,
        [val?.id || val?.roundHeatId]:
          FightStatusKeys[val?.roundStatus] === FightStatusNames.LIVE ? true : false,
      }),
      {},
    ),
  );

  React.useEffect(() => {
    if (!values || !values.fights) return;

    setAccordion((prev: { [key: string]: boolean }) => {
      const updatedAccordion: { [key: string]: boolean } = values.fights.reduce(
        (
          acc: { [key: string]: boolean },
          val: { id: string | undefined; roundHeatId: string; roundStatus: number },
        ) => {
          const id: string = val.id || val.roundHeatId;
          if (prev[id] !== undefined) return { ...acc, [id]: prev[id] };
          const roundStatus: string = FightStatusKeys[val.roundStatus];
          return { ...acc, [id]: roundStatus === FightStatusNames.LIVE };
        },
        {},
      );

      return updatedAccordion;
    });
  }, [values?.fights]);

  const payloadFormatter = (data: any) => {
    const scores: any = [];
    data?.judges?.filter(Boolean).forEach((judge: any, idx: number) => {
      const judgeName = judge?.name?.label || '';
      const score1 = Number(judge?.score1 || 0);
      const score2 = Number(judge?.score2 || 0);
      scores.push(
        {
          judge: judgeName,
          score: score1,
          athleteId: data.redCornerAthlete?.id,
          cornerType: CORNER_COLORS.RED,
          judgeNo: idx + 1,
        },
        {
          judge: judgeName,
          score: score2,
          athleteId: data.blueCornerAthlete?.id,
          cornerType: CORNER_COLORS.BLUE,
          judgeNo: idx + 1,
        },
      );
    });

    const rounds: any = [];
    data.rounds.forEach((rnd: any) => {
      const subHeats: any = [];

      rnd.playerRoundStats
        .filter((playerRndStat: any) => playerRndStat.cornerType)
        .forEach((playerRndStat: any) => {
          const athleteId =
            playerRndStat.cornerType === CORNER_COLORS.BLUE
              ? data.blueCornerAthlete?.id
              : data.redCornerAthlete?.id;
          subHeats.push({
            athleteId,
            cornerType: playerRndStat.cornerType,
            roundSlapNo: playerRndStat.roundSlapNo,
            roundSlapName: playerRndStat.roundSlapNo?.toString() || '',
            fouls: playerRndStat.fouls.map((foul: any) => foul?.inputValue || foul?.label),
            deducted: +playerRndStat?.deducted || 0,
          });
        });

      const roundObj = {
        ...(rnd?.id ? { id: rnd?.id } : {}),
        name: `Round ${getNumberFromCharacters(rnd?.name || '')}`,
        startDate: rnd.startDate ? zonedTimeToUtc(rnd.startDate, DEFAULT_TIMEZONE) : null,
        endDate: rnd.endDate ? zonedTimeToUtc(rnd.endDate, DEFAULT_TIMEZONE) : null,
        heatStatus:
          +data.roundStatus === EventStatusValues.COMPLETED
            ? EventStatusValues.COMPLETED
            : rnd.heatStatus,
        subHeats,
      };
      rounds.push(roundObj);
    });

    const scoresPayload = {
      tossWinnerAthleteId:
        data?.coinTossWinner === CORNER_COLORS.RED
          ? data.redCornerAthlete?.id
          : data.blueCornerAthlete?.id,
      name: `Fight ${getNumberFromCharacters(data?.name || '')}`,
      startDate: data.startDate ? zonedTimeToUtc(data.startDate, DEFAULT_TIMEZONE) : null,
      endDate: data.endDate ? zonedTimeToUtc(data.endDate, DEFAULT_TIMEZONE) : null,
      winBy: data.winBy || null,
      notes: data.notes || null,
      weightClass: data.weightClass || null,
      winnerAthleteId: data?.winner?.id || null,
      scheduledRounds: data.scheduledRounds || 0,
      // Note: Only for Powerslap sport
      // lastRound: data.scheduledRounds || 0,
      referee: data?.referee?.label || null,
      roundStatus: +data.roundStatus,
      scores,
    };

    const roundsPayload = {
      items: rounds,
    };

    return {
      scoresPayload,
      roundsPayload,
    };
  };

  const handleSaveOrEndFight = async (data: any, idx: number) => {
    try {
      await fightValidationSchema.validate(data, { abortEarly: false });
      if (data?.id === DEFAULT_FIGHT_ID) {
        addPOWERSLAPScores(
          {
            sport: SPORT_TYPES.POWERSLAP,
            eventId,
            requestPayload: payloadFormatter(data).scoresPayload,
          },
          {
            onSettled: ({ data: dataScr }, err) => {
              if (!err) {
                updatePOWERSLAPRounds(
                  {
                    sport: SPORT_TYPES.POWERSLAP,
                    eventId,
                    roundId: dataScr?.id,
                    requestPayload: payloadFormatter(data).roundsPayload,
                  },
                  {
                    onSettled: ({ data: dataRnd }, errRnd) => {
                      if (!errRnd) {
                        setSelectedGame(dataRnd?.id);
                        setFieldValue(
                          'fights',
                          values.fights.map((fight: any) => {
                            if (fight.id === DEFAULT_FIGHT_ID) {
                              return { ...fight, id: dataScr?.id };
                            }
                            return fight;
                          }),
                        );
                        enqueueSnackbar('Added fight successfully!');
                        refreshScores();
                      }
                    },
                  },
                );
              }
            },
          },
        );
      }
      if (data?.id !== DEFAULT_FIGHT_ID) {
        updatePOWERSLAPScores(
          {
            sport: SPORT_TYPES.POWERSLAP,
            eventId,
            roundHeatId: data?.id,
            requestPayload: payloadFormatter(data).scoresPayload,
          },
          {
            onSettled: (_, err) => {
              if (!err) {
                updatePOWERSLAPRounds(
                  {
                    sport: SPORT_TYPES.POWERSLAP,
                    eventId,
                    roundId: data?.id,
                    requestPayload: payloadFormatter(data).roundsPayload,
                  },
                  {
                    onSettled: (_, errRnd) => {
                      if (!errRnd) {
                        enqueueSnackbar('Updated fight successfully!');
                        refreshScores();
                      }
                    },
                  },
                );
              }
            },
          },
        );
      }
    } catch (err: any) {
      if (err?.inner) {
        err?.inner?.forEach((error: any) => {
          setFieldError(`fights[${idx}].${error.path}`, error?.message);
        });
      }
    }
  };

  const matchSummaryDateFormatter = (matchTime: Date) => {
    try {
      if (!matchTime || isNaN(matchTime.getTime())) {
        return '';
      }

      const formattedTime = format(matchTime, 'HH:mm');
      return `${formattedTime} PST`;
    } catch (error) {
      return '';
    }
  };

  // const handleConfirmDelete = (data: any) => {
  //   if (data?.id === DEFAULT_FIGHT_ID) {
  //     const modScores = values.fights.filter((scrData: any) => scrData?.id !== data?.id);
  //     setFieldValue('fights', modScores);
  //   }

  //   if (data?.id === DEFAULT_FIGHT_ID) {
  //     deletePOWERSLAPScores(
  //       {
  //         sport: SPORT_TYPES.POWERSLAP,
  //         eventId,
  //         roundHeatId: data?.id,
  //       },
  //       {
  //         onSettled: (_, err) => {
  //           if (!err) {
  //             enqueueSnackbar('Deleted fight successfully!');
  //             refreshScores();
  //           }
  //         },
  //       },
  //     );
  //   }
  // };

  const { selectedGame, setSelectedGame } = React.useContext(POWERSLAPGameContext);

  React.useEffect(() => {
    if (values.fights && values.fights.length > 0 && !selectedGame) {
      const LIVE_GAME = values.fights.find(
        (event: any) => event.roundStatus === FightStatusValues.LIVE,
      );
      if (LIVE_GAME) {
        setSelectedGame(LIVE_GAME.id || '');
      } else {
        const UPCOMING_GAME = values.fights.find(
          (event: any) => event.roundStatus === FightStatusValues.UPCOMING,
        );
        setSelectedGame(UPCOMING_GAME?.id || values.fights[0].id);
      }
    }
  }, [values.fights]);

  const SELECTED_FIGHT_ID = React.useMemo(
    () => values.fights.findIndex((fgt: any) => fgt.id === selectedGame),
    [selectedGame, values.fights.length],
  );

  const IS_ROUND_START_TIME = React.useMemo(
    () =>
      roundTimeDtls.timeType !== undefined ? roundTimeDtls.timeType === TIME_TYPE.startDate : false,
    [roundTimeDtls],
  );

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
        }}
      >
        <Slider
          sliderItems={values?.fights}
          itemType={'matchCardPOWERSLAP'}
          selectedItem={values.fights[SELECTED_FIGHT_ID]}
        />
      </Box>
      {selectedGame !== DEFAULT_FIGHT_ID && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
            onClick={() => {
              const lastScoreName = values?.fights?.[values?.fights?.length - 1]?.name || '0';
              const nextName = getNumberFromCharacters(lastScoreName) + 1;

              setFieldValue('fights', [
                ...(values?.fights || []),
                {
                  ...DEFAULT_FIGHT,
                  name: nextName,
                  startDate: null,
                  rounds: cloneDeep(DEFAULT_ROUND),
                },
              ]);
              setSelectedGame(DEFAULT_FIGHT_ID);
            }}
          >
            Add fight
          </Button>
        </Box>
      )}

      {selectedGame === DEFAULT_FIGHT_ID && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={2}>
          <Button
            // variant="contained"
            startIcon={<DeleteIcon />}
            disableElevation
            color="error"
            onClick={() => {
              const filteredFights = cloneDeep(values?.fights || []).filter(
                (fgt) => fgt?.id !== DEFAULT_FIGHT_ID,
              );
              setFieldValue('fights', filteredFights);
              if (filteredFights && filteredFights.length > 0) {
                const LIVE_GAME = filteredFights.find(
                  (event: any) => event.roundStatus === FightStatusValues.LIVE,
                );
                if (LIVE_GAME) {
                  setSelectedGame(LIVE_GAME?.id || '');
                } else {
                  const UPCOMING_GAME = filteredFights.find(
                    (event: any) => event.roundStatus === FightStatusValues.UPCOMING,
                  );
                  setSelectedGame(UPCOMING_GAME?.id || filteredFights?.[0]?.id);
                }
              } else {
                setSelectedGame('');
              }
            }}
          >
            Remove fight
          </Button>
        </Box>
      )}

      {SELECTED_FIGHT_ID > -1 && (
        <FightContainer
          scr={values.fights[SELECTED_FIGHT_ID]}
          idx={SELECTED_FIGHT_ID}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          ATHLETE_OPTIONS={ATHLETE_OPTIONS}
          WEIGHT_CLASSES={WEIGHT_CLASSES}
          JUDGE_OPTIONS={JUDGE_OPTIONS}
          REFEREE_OPTIONS={REFEREE_OPTIONS}
          WIN_BY_OPTIONS_POWERSLAP={WIN_BY_OPTIONS_POWERSLAP}
          FOULS={FOULS}
          FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
          handleBlur={handleBlur}
          handleSaveOrEndFight={handleSaveOrEndFight}
          accordion={accordion}
          setAccordion={setAccordion}
          matchSummaryDateFormatter={matchSummaryDateFormatter}
          // toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
          // handleConfirmDelete={handleConfirmDelete}
          toggleCustomStartFightDialog={toggleCustomStartFightDialog}
          toggleCustomEndFightDialog={toggleCustomEndFightDialog}
          toggleCustomRoundTimeDialog={toggleCustomRoundTimeDialog}
        />
      )}

      {/* <DeleteConfirmDialog
        open={showDeleteFightDialog}
        handleClose={() => toggleConfirmDeleteDialog(undefined)}
        handleConfirm={() => {
          toggleConfirmDeleteDialog(undefined);
          handleConfirmDelete(fightData);
        }}
        title={`Confirm Deletion`}
        body={
          <Typography sx={{ fontSize: '0.9rem' }}>
            Data will be deleted permanently, to confirm deletion, type <strong>DELETE</strong> in
            the text input field.
          </Typography>
        }
      /> */}
      <CustomEndFightDialog
        open={showStartFightDialog}
        handleClose={() => toggleCustomStartFightDialog(undefined)}
        handleConfirm={(newVal: any) => {
          const idx = values.fights.findIndex(
            (scr: any) =>
              scr?.roundHeatId === fightData?.roundHeatId && scr?.name === fightData?.name,
          );
          toggleCustomStartFightDialog(undefined);
          handleSaveOrEndFight(
            {
              ...(fightData as unknown as any),
              startDate: newVal,
              ...(fightData?.roundStatus === FightStatusValues[FightStatusNames.UPCOMING] && {
                roundStatus: FightStatusValues[FightStatusNames.LIVE],
              }),
            },
            idx,
          );
        }}
        title={'ARE YOU SURE YOU WANT TO START THIS FIGHT?'}
        body={'This will set the Start Time. You can come back to make edits if needed.'}
        isStartTime
      />
      <CustomEndFightDialog
        open={showEndFightDialog}
        handleClose={() => toggleCustomEndFightDialog(undefined)}
        handleConfirm={(newVal: any) => {
          const idx = values.fights.findIndex(
            (scr: any) =>
              scr?.roundHeatId === fightData?.roundHeatId && scr?.name === fightData?.name,
          );
          toggleCustomEndFightDialog(undefined);
          handleSaveOrEndFight(
            {
              ...(fightData as unknown as any),
              endDate: newVal,
              roundStatus: FightStatusValues[FightStatusNames.COMPLETED],
            },
            idx,
          );
        }}
        title={'ARE YOU SURE YOU WANT TO END THIS FIGHT?'}
        body={'This will set the fights and End Time. You can come back to make edits if needed.'}
      />
      <CustomEndFightDialog
        open={showRoundTimeDialog}
        handleClose={() => toggleCustomRoundTimeDialog(DEFAULT_ROUND_TIME_DETAILS)}
        handleConfirm={(newVal) => {
          const { fightId, roundId, timeType } = roundTimeDtls;
          const currentFightData = values.fights[fightId] || {};
          const rounds = currentFightData?.rounds ? [...currentFightData.rounds] : [];
          const currentRound = { ...(rounds[roundId] || {}) };

          currentRound[timeType as keyof {}] = newVal;

          if (
            timeType === TIME_TYPE.startDate &&
            currentRound.heatStatus === FightStatusValues[FightStatusNames.UPCOMING]
          ) {
            currentRound.heatStatus = FightStatusValues[FightStatusNames.LIVE];
          }

          if (
            timeType === TIME_TYPE.endDate &&
            currentRound.heatStatus !== FightStatusValues[FightStatusNames.COMPLETED]
          ) {
            currentRound.heatStatus = FightStatusValues[FightStatusNames.COMPLETED];
          }

          rounds[roundId] = currentRound;

          toggleCustomRoundTimeDialog(DEFAULT_ROUND_TIME_DETAILS);
          handleSaveOrEndFight(
            {
              ...currentFightData,
              rounds,
            },
            fightId,
          );
        }}
        title={`ARE YOU SURE YOU WANT TO ${IS_ROUND_START_TIME ? 'START' : 'END'} THIS ROUND?`}
        body={`This will set the Round ${
          IS_ROUND_START_TIME ? 'Start' : 'End'
        } Time. You can come back to make edits if needed.`}
        isStartTime={IS_ROUND_START_TIME}
      />
    </Box>
  );
}

export default function POWERSLAPEventScores() {
  return (
    <POWERSLAPGameProvider>
      <POWERSLAPEventContainer />
    </POWERSLAPGameProvider>
  );
}
