import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '@/hooks/index';

import { AddOddsPayloadDTO } from '@/types/odds/addOdds';

interface ADD_PROP_BETS {
  sport: string;
  eventId: string;
  payload: AddOddsPayloadDTO;
}

const addPropBetOdds = async ({ sport, eventId, payload }: ADD_PROP_BETS) => {
  const { data } = await authenticatedAxios({
    method: 'POST',
    url: `admin/${sport}/events/${eventId}/propBets/odds`,
    data: payload,
  });

  return data;
};

export default function useAddPropBetOdds() {
  return useMutation(({ sport, eventId, payload }: ADD_PROP_BETS) =>
    addPropBetOdds({ sport, eventId, payload }),
  );
}
