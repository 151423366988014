import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

const EventListingSkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" animation="wave" width="100%" height={60} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="20%" height={30} />
      <br />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        animation="wave"
        height={40}
        sx={{ marginBottom: '0.5rem' }}
      />
    </>
  );
};

const EventScoresSkeleton = () => {
  return (
    <>
      <Skeleton variant="rectangular" animation="wave" width="50%" height={20} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="40%" height={40} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="30%" height={30} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="50%" height={40} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="40%" height={40} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="30%" height={30} />
      <br />
      <Skeleton variant="rectangular" animation="wave" width="100%" height={100} />
      <br />
    </>
  );
};

export { EventScoresSkeleton, EventListingSkeleton };
