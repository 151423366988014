import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '..';

const getProfile = async () => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/auth/me`,
  });

  return data;
};

export default function useFetchProfile() {
  return useQuery([keys.user.fetchProfile], () => getProfile(), {
    staleTime: Infinity,
  });
}
