export const filterHiddenFieldsOfFile = (json: any[]) => {
  const emptyKeysRemovedJSON = json.map((obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === '' || key === '__rowNum__' || obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  });

  const filteredJSON = emptyKeysRemovedJSON.filter((value: any) => JSON.stringify(value) !== '{}');

  return filteredJSON;
};
