import { createContext, ReactNode, useState } from 'react';

export const POWERSLAPGameContext = createContext<any>({
  selectedGame: '',
  setSelectedGame: () => null,
});

export const POWERSLAPGameProvider = ({ children }: { children: ReactNode }) => {
  const [selectedGame, setSelectedGame] = useState<string>('');
  const value = {
    selectedGame,
    setSelectedGame,
  };

  return <POWERSLAPGameContext.Provider value={value}>{children}</POWERSLAPGameContext.Provider>;
};
