import React from 'react';
import { useField, Field } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { theme } from '../../constants/theme';
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';

const CustomPaper = (props: any) => {
  return <Paper elevation={2} {...props} sx={{ borderRadius: '0 0 0.25rem 0.25rem' }} />;
};

const FilterSelect = ({ label, ...props }: any) => {
  const [meta]: any = useField(props);
  const { options, name, variant = 'standard', value } = props;

  return (
    <Field
      sx={{
        '& .MuiInputLabel-root': {
          transform: 'translate(0.75rem, 0.5rem) scale(1)',
          color: theme.palette.info.contrastText,
        },
        '& .MuiInputLabel-shrink': {
          transform: 'translate(0.75rem, -0.2rem) scale(0.84)',
          top: '0',
        },
        '& .MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 1.62rem)',
          paddingRight: '0.75rem',
        },
        '& .MuiAutocomplete-input': {
          fontWeight: '500',
          color: theme.palette.info.main,
          paddingLeft: '0.75rem!important',
        },
        '& .MuiInput-root': {
          paddingRight: '4.375rem!important',
        },
        '& .MuiAutocomplete-popupIndicator': {
          '& svg': {
            '& path': {
              d: "path('M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z')",
            },
          },
        },
        '& .MuiAutocomplete-option': {
          color: theme.palette.info.main,
          fontSize: '1rem',
          fontWeight: '400',
          fontFamily: 'Inter, sans-serif',
        },
      }}
      name={name}
      value={value}
      component={Autocomplete}
      PaperComponent={CustomPaper}
      options={options}
      {...props}
      getOptionLabel={(option: any) => option.label}
      getOptionDisabled={(option: any) => option.label === 'Select a sport'}
      renderInput={(params: any) => (
        <TextField
          {...params}
          {...props}
          // We have to manually set the corresponding fields on the input component
          name={name}
          error={meta.touched && !!meta.error}
          helperText={meta.error}
          label={label}
          variant={variant}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default FilterSelect;
