import React, { useState, useMemo, useEffect } from 'react';
import { useFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import round from 'lodash.round';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import MaterialTable from '@material-table/core';
import { parseISO } from 'date-fns';

import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
  autocompleteClasses,
  Box,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Switch from '@mui/material/Switch';

import { oddsType } from '@/atoms/oddsType';

import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import DecimalField from '@/components/DecimalField';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ExportBtn from '@/components/ExportBtn';
import PublishOdds from '@/components/PublishOdds';

import useFetchParticipants from '@/hooks/athletes/useFetchParticipants';
import useFetchPropBetOdds from '@/hooks/odds/propBets/useFetchPropBetOdds';
import useAddPropBetOdds from '@/hooks/odds/propBets/useAddPropBetOdds';
import useUpdatePropBetOdds from '@/hooks/odds/propBets/useUpdatePropBetOdds';
import useUpdatePropBetsOddsPayout from '@/hooks/odds/propBets/useUpdatePropBetOddsPayout';

import { excelDownloader } from '@/helpers/fileDownloader';
import { oddsToCSVFormatModifier } from '@/helpers/oddsToCSVFormatModifier';
import * as oddsHelpers from '@/helpers/odds';
import { invalidatePropBetOdds } from '@/helpers/cachedQueries';
import {
  propBetsPayoutPayloadFormatter,
  initialPropBetsLoader,
  propBetsPayloadFormatter,
  propBetsVoidedPayloadFormatter,
} from '@/helpers/propBets';
import { displayInPT as format } from '@/helpers/timeConverters';

import * as httpStatusCodes from '@/constants/httpStatus';
import ODDS_TYPES from '@/constants/oddsTypes';
import * as oddRanges from '@/constants/oddRanges';
import * as validationMessages from '@/constants/validationMessages';
import { ODD_DATA_TYPES } from '@/constants/oddDataTypes';
import { fractionPattern } from '@/constants/misc';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import { SPORT_NAMES } from '@/constants/sportTypes';
import keys from '@/constants/queryKeys';

import { UpdateOddsPayloadDTO } from '@/types/odds/updateOdds';
import { UpdateOddsPayoutPayloadDTO } from '@/types/odds/updateOddsPayout';
import { AddOddsPayloadDTO } from '@/types/odds/addOdds';

import {
  tableIcons,
  tableCellStyle,
  editCellTextFieldStyle,
  defaultTableOptions,
  createTablePageOptions,
} from '@/utils/TableMisc';
import { NON_ATHLETE_PROP } from '@/constants/misc';

type AddEventPropBetDialogProps = {
  open: boolean;
  handleClose: Function;
  playerDropdownOptions: any[];
  addPropBetOddsData: Function;
};

type FormValues = {
  athlete: { eventParticipantId: string } | null;
  proposition: string;
  odds: string;
};

const initialValues: FormValues = {
  athlete: null,
  proposition: '',
  odds: '',
};

type TOGGLE_OPTIONS_KEYS = 'PAYOUT' | 'VOIDED';
type TOGGLE_OPTIONS_VALUES = 'payout' | 'voided';
const TOGGLE_OPTIONS: Record<TOGGLE_OPTIONS_KEYS, TOGGLE_OPTIONS_VALUES> = {
  PAYOUT: 'payout',
  VOIDED: 'voided',
};

const AddEventPropBetDialog = (props: AddEventPropBetDialogProps) => {
  const { open, handleClose, playerDropdownOptions, addPropBetOddsData } = props;

  const [oddType] = useRecoilState(oddsType);
  const {
    values,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
  }: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      proposition: Yup.string().required(),
    }),
    validate: (formValues) => {
      const fieldErrors: { odds?: string } = {};
      if (ODDS_TYPES.DECIMAL === oddType) {
        const newValue = round(Number(formValues?.odds) || 0, 2);
        if (!(newValue >= oddRanges.MIN_DECIMAL_ODDS && newValue <= oddRanges.MAX_DECIMAL_ODDS)) {
          fieldErrors['odds'] = validationMessages.DECIMAL_ODDS_VALIDATION_MSG;
        }
      }
      if (ODDS_TYPES.FRACTIONAL === oddType) {
        const newValue = formValues?.odds.toString();
        const decimal = oddsHelpers.decimalFromFraction(newValue.toString());
        if (
          !newValue.match(fractionPattern) ||
          newValue === '0' ||
          !(decimal >= oddRanges.MIN_FRACTION_ODDS && decimal <= oddRanges.MAX_FRACTION_ODDS)
        ) {
          fieldErrors['odds'] = validationMessages.FRACTION_ODDS_VALIDATION_MSG;
        }
      }
      if (ODDS_TYPES.AMERICAN === oddType) {
        const newValue = Number(formValues?.odds);
        if (
          !(
            (newValue >= oddRanges.MIN_NEGATIVE_AMERICAN_ODDS &&
              newValue <= oddRanges.MAX_NEGATIVE_AMERICAN_ODDS) ||
            (newValue >= oddRanges.MIN_AMERICAN_ODDS && newValue <= oddRanges.MAX_AMERICAN_ODDS)
          )
        ) {
          fieldErrors['odds'] = validationMessages.AMERICAN_ODDS_VALIDATION_MSG;
        }
      }
      return fieldErrors;
    },
    onSubmit: async (formValues, { resetForm }) => {
      const finalOdds =
        ODDS_TYPES.DECIMAL === oddType
          ? round(+formValues?.odds, 2)
          : oddsHelpers.getDecimalOddsFromOtherTypes(oddType, formValues?.odds);
      const payload = {
        eventParticipantId: formValues?.athlete?.eventParticipantId || null,
        proposition: formValues?.proposition || '',
        odds: finalOdds,
      };
      const responseStatus = await addPropBetOddsData(payload);
      if (responseStatus === httpStatusCodes.OK) {
        resetForm();
      }
    },
  });
  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      maxWidth={'sm'}
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h5">
          <strong>Add Event Prop Bet</strong>
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form autoComplete="off">
          <Grid container direction="column">
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>Athlete</Typography>
              <Autocomplete
                value={values.athlete}
                options={
                  values.athlete
                    ? playerDropdownOptions.filter(
                        (data: any) => data?.eventParticipantId !== values?.athlete,
                      )
                    : playerDropdownOptions
                }
                renderOption={(props, option) => {
                  return (
                    <Box
                      sx={{
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                          padding: '8px',
                        },
                      }}
                      component="li"
                      {...props}
                      key={option.eventParticipantId}
                    >
                      {option.label}
                    </Box>
                  );
                }}
                onChange={(event: any, newValue: any) => {
                  setFieldValue('athlete', newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="athlete"
                    onBlur={handleBlur}
                    helperText={
                      touched.athlete && errors.athlete && 'Event Participant is Required'
                    }
                    error={errors.athlete ? true : false}
                    InputProps={{
                      ...params.InputProps,
                      // disableUnderline: true,
                      endAdornment: null,
                    }}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>Proposition</Typography>
              <TextField
                name="proposition"
                value={values.proposition}
                onBlur={handleBlur}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue('proposition', event.target.value);
                }}
                helperText={touched.proposition && errors.proposition && 'Proposition is Required'}
                error={errors.proposition ? true : false}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
              <Typography>{`${
                ODDS_TYPES.FRACTIONAL === oddType
                  ? 'Fractional'
                  : ODDS_TYPES.DECIMAL === oddType
                  ? 'Decimal'
                  : 'American'
              } Odds`}</Typography>
              {ODDS_TYPES.FRACTIONAL === oddType && (
                <TextField
                  value={values.odds}
                  name="odds"
                  onBlur={handleBlur}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('odds', event.target.value);
                  }}
                  helperText={touched.odds && errors.odds}
                  error={errors.odds ? true : false}
                  fullWidth
                />
              )}
              {ODDS_TYPES.DECIMAL === oddType && (
                <DecimalField
                  name="odds"
                  value={values.odds}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('odds', event.target.value);
                  }}
                  onBlur={handleBlur}
                  helperText={touched.odds && errors.odds}
                  error={errors.odds ? true : false}
                  fullWidth
                  thousandSeparator={false}
                  decimalScale={2}
                />
              )}
              {ODDS_TYPES.AMERICAN === oddType && (
                <DecimalField
                  name="odds"
                  value={values.odds}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFieldValue(
                      'odds',
                      +event.target.value > 0 ? `+${event.target.value}` : `${event.target.value}`,
                    );
                  }}
                  onBlur={handleBlur}
                  helperText={touched.odds && errors.odds}
                  error={errors.odds ? true : false}
                  fullWidth
                  thousandSeparator={false}
                  decimalScale={0}
                  allowNegative={true}
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} spacing={1} sx={{ marginBottom: '1rem' }}>
          <Grid item sm={4}>
            <Button fullWidth variant="outlined" onClick={() => handleClose()} disableElevation>
              Close
            </Button>
          </Grid>
          <Grid item sm={4}>
            <Button
              variant="contained"
              type="submit"
              onClick={() => handleSubmit()}
              disableElevation
              fullWidth
            >
              Add Prop Bet
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const PropBetsOddsTab = (props: any) => {
  const { sport, eventId, handlePublishOdds } = props;
  const { data: athletes } = useFetchParticipants(sport, eventId);
  const { data: oddsData } = useFetchPropBetOdds(sport, eventId);
  const [oddType] = useRecoilState(oddsType);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [modifiedPropBets, setModifiedPropBets] = useState<any[]>([]);
  const [hasOddsBeenEdited, setHasOddsBeenEdited] = useState(false);
  const [showOddsUpdateDialog, setShowOddsUpdateDialog] = useState(false);
  const [showResetOddsDialog, setShowResetOddsDialog] = useState(false);
  const [isConfirmVoidDialogOpen, setIsConfirmVoidDialogOpen] = useState(false);
  const [hasConfirmedAndSettledPropBets, setHasConfirmedAndSettledPropBets] = useState(false);
  const [tempVoidedRowId, setTempVoidedRowId] = useState('');
  const { mutateAsync: addPropBetOdds } = useAddPropBetOdds();
  const { mutateAsync: updatePropBetOdds } = useUpdatePropBetOdds();
  const { mutateAsync: updatePropBetOddsPayout } = useUpdatePropBetsOddsPayout();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const event: any = queryClient.getQueryData([keys.events.fetchEvent, sport, eventId, 'Event']);

  const resetPropBetsOdds = ({
    origPropBets,
    modPropBets,
  }: {
    origPropBets: any[];
    modPropBets: any[];
  }) => {
    const modPropBetsCopy = [...origPropBets].map((bet) => {
      const modBet = modPropBets.find((modifiedBet) => modifiedBet.id === bet.id);
      return {
        ...bet,
        ...(modBet && {
          editedVoid: modBet.editedVoid,
          propBetPayout: modBet.propBetPayout,
        }),
      };
    });
    setModifiedPropBets(modPropBetsCopy);
  };

  const setInitialOddsData = (isReset = false) => {
    const origPropBets = initialPropBetsLoader(oddsData?.odds || []);
    if (!isReset) {
      if (modifiedPropBets.length === 0) {
        setModifiedPropBets(origPropBets);
      } else {
        resetPropBetsOdds({ origPropBets: origPropBets, modPropBets: modifiedPropBets });
      }
    } else {
      resetPropBetsOdds({ origPropBets: origPropBets, modPropBets: modifiedPropBets });
    }

    if (hasOddsBeenEdited) {
      handleToggleHasOddsBeenEdited();
    }
  };

  const handleToggleOddsUpdateConfirmDialog = () => setShowOddsUpdateDialog(!showOddsUpdateDialog);

  const handleToggleResetDialog = () => setShowResetOddsDialog(!showResetOddsDialog);

  const handleToggleHasOddsBeenEdited = () => setHasOddsBeenEdited(!hasOddsBeenEdited);

  const handleToggleConfirmDialog = () => setIsConfirmDialogOpen(!isConfirmDialogOpen);

  const handleToggleConfirmVoidDialog = () => setIsConfirmVoidDialogOpen(!isConfirmVoidDialogOpen);

  const handleResetTempVoidRowId = () => setTempVoidedRowId('');

  const addPropBetOddsData = async (
    payload: AddOddsPayloadDTO,
    message = 'Added Event Matchup Successfully',
  ) => {
    const response = await addPropBetOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          invalidatePropBetOdds(queryClient, sport, eventId);
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const updatePropBetOddsData = async (
    payload: UpdateOddsPayloadDTO,
    message = 'Updated Event Matchup Successfully',
  ) => {
    const response = await updatePropBetOdds(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          invalidatePropBetOdds(queryClient, sport, eventId);
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handlePropBetConfirmVoid = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Voided Successfully!',
  ) => {
    const response = await updatePropBetOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          invalidatePropBetOdds(queryClient, sport, eventId);
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handlePropBetConfirmAndSettle = async (
    payload: UpdateOddsPayoutPayloadDTO,
    message = 'Payouts updated Successfully!',
  ) => {
    const response = await updatePropBetOddsPayout(
      { sport, eventId, payload },
      {
        onSuccess: () => {
          enqueueSnackbar(message);
          invalidatePropBetOdds(queryClient, sport, eventId);
        },
      },
    );
    return response.status || httpStatusCodes.BAD_REQUEST;
  };

  const handleConfirmAndSettle = async () => {
    setHasConfirmedAndSettledPropBets(true);
    handleToggleConfirmDialog();
    const modifiedBets = propBetsPayoutPayloadFormatter(modifiedPropBets);
    const payload = { items: modifiedBets };
    await handlePropBetConfirmAndSettle(payload);
    setHasConfirmedAndSettledPropBets(false);
  };

  const handleConfirmVoid = async () => {
    handleToggleConfirmVoidDialog();
    const modifiedBets = propBetsVoidedPayloadFormatter(modifiedPropBets);
    const payload = { items: modifiedBets };
    const responseStatus = await handlePropBetConfirmVoid(payload);
    if (responseStatus === httpStatusCodes.OK) {
      handleResetTempVoidRowId();
    }
  };

  const updateOddsData = async () => {
    const payload = propBetsPayloadFormatter(modifiedPropBets);
    await updatePropBetOddsData({ items: payload?.items });
  };

  const playerDropdownOptions = useMemo(() => {
    const modifiedAthletes = athletes
      ? athletes?.map((player: any) => {
          return {
            label: [
              player?.athlete?.firstName,
              player?.athlete?.middleName,
              player?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
            eventParticipantId: player?.id || '',
            athleteId: player?.athlete?.id || '',
            ...player.athlete,
          };
        })
      : [];
    return modifiedAthletes;
  }, [JSON.stringify(athletes)]);

  React.useEffect(() => {
    invalidatePropBetOdds(queryClient, sport, eventId);
  }, [eventId]);

  useEffect(() => {
    if (oddsData?.odds && oddsData?.odds.length > 0) {
      setInitialOddsData();
    }
  }, [JSON.stringify(oddsData)]);

  // const tableRef = React.useRef(null);

  // React.useEffect(() => {
  //   if (tableRef) tableRef.current.dataManager.changePageSize(modifiedPropBets?.length || 0);
  // }, [JSON.stringify(modifiedPropBets)]);

  const [showAddBetPopup, setAddBetPopUp] = useState(false);

  const rowUpdateHandler = (newData: any) =>
    new Promise((resolve, reject) => {
      if (!hasOddsBeenEdited) {
        handleToggleHasOddsBeenEdited();
      }

      const modPropBets = JSON.parse(JSON.stringify(modifiedPropBets));
      modPropBets.some((propBet: any, index: number) => {
        if (propBet.id === newData.propBetId) {
          const convertedOdds =
            oddType === ODDS_TYPES.FRACTIONAL
              ? oddsHelpers.getDecimalOddsFromOtherTypes(oddType, newData?.fractionalOdds)
              : oddType === ODDS_TYPES.AMERICAN
              ? oddsHelpers.getDecimalOddsFromOtherTypes(oddType, newData?.americanOdds)
              : round(+newData?.decimalOdds, 2);
          modPropBets[index].decimalOdds =
            oddType === ODDS_TYPES.DECIMAL ? round(+newData?.decimalOdds, 2) : convertedOdds;
          modPropBets[index].fractionalOdds =
            oddType === ODDS_TYPES.FRACTIONAL
              ? newData?.fractionalOdds
              : oddsHelpers.getFractionalOdds(convertedOdds);
          modPropBets[index].americanOdds =
            oddType === ODDS_TYPES.AMERICAN
              ? newData?.americanOdds
              : oddsHelpers.getAmericanOdds(convertedOdds);

          setModifiedPropBets(modPropBets);
          resolve('success');
          return true;
        } else {
          reject('failed');
        }
      });
    });

  const payoutVoidedToggler = ({
    checked = false,
    propsId,
    toggleField = TOGGLE_OPTIONS.PAYOUT,
  }: {
    checked: boolean;
    propsId: string;
    toggleField?: TOGGLE_OPTIONS_VALUES;
  }) => {
    const oldRows = JSON.parse(JSON.stringify(modifiedPropBets));
    const index = oldRows.findIndex((row: any) => row.id === propsId);
    if (index !== -1) {
      const updatedRow = oldRows[index];
      if (toggleField === TOGGLE_OPTIONS.PAYOUT) {
        if (!updatedRow.payout) {
          const row = { ...updatedRow, propBetPayout: checked };
          oldRows[index] = row;
          setModifiedPropBets(oldRows);
        }
      }

      if (toggleField === TOGGLE_OPTIONS.VOIDED) {
        if (!updatedRow.voided) {
          const row = { ...updatedRow, editedVoid: checked };
          oldRows[index] = row;
          setModifiedPropBets(oldRows);
        }
      }
    }
  };

  const exportHandler = () => {
    const modData = JSON.parse(JSON.stringify(modifiedPropBets || []));
    const { title, headers, csvData } = oddsToCSVFormatModifier({
      data: modData,
      oddDataType: ODD_DATA_TYPES.PROP_BETS,
      oddType,
    });

    if (csvData.length > 0) {
      const eventInfo: any = {
        eventName: event?.name,
        year: event?.year,
        sport: SPORT_NAMES[sport],
        ...(event?.tour && { tour: event?.tour.name }),
        ...(event?.league && { league: event?.league.name }),
      };
      const lastItemUpdatedAt = oddsData?.traderUpdatedAtDate;
      const updatedAt = lastItemUpdatedAt
        ? format(parseISO(lastItemUpdatedAt), 'MM_dd_yyyy_HH_mm_aaa')
        : '';
      excelDownloader({ title, headers, csvData, updatedAt, ...eventInfo });
    }

    if (csvData.length === 0) enqueueSnackbar(`No Data Found for ${title}`);
  };

  return (
    <Box>
      <Box sx={{ marginBottom: '0.875rem' }} display="flex" justifyContent="flex-end">
        <ResetOddsBtn
          disabled={!hasOddsBeenEdited}
          resetOddsHandler={() => {
            handleToggleResetDialog();
          }}
        />
        <SaveOddsBtn
          disabled={!hasOddsBeenEdited}
          saveOddsHandler={() => {
            handleToggleOddsUpdateConfirmDialog();
          }}
        />
      </Box>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        item
        xs={12}
        sx={{ marginY: '1rem', gap: { xs: '1rem', sm: 0 } }}
      >
        <Grid container alignItems={'flex-start'} item xs={12} sm={6}>
          <ExportBtn handleOnClick={exportHandler} />
          <PublishOdds
            clientUpdatedAtDate={oddsData?.clientUpdatedAtDate || ''}
            traderUpdatedAtDate={oddsData?.traderUpdatedAtDate || ''}
            traderUpdatedAtDates={oddsData?.traderUpdatedAtDate || null}
            handlePublishOdds={() => {
              handlePublishOdds(ODDS_PROJECTION_TYPE.propBetProjections);
            }}
          />
        </Grid>
        <Button
          variant="contained"
          disableElevation
          disabled={hasConfirmedAndSettledPropBets}
          // disabled={propBetsFreshPayoutCount(modifiedPropBets) === 0 ? true : false}
          onClick={handleToggleConfirmDialog}
        >
          Confirm and settle
        </Button>
      </Grid>
      <Box>
        <MaterialTable
          icons={tableIcons}
          columns={[
            {
              title: 'Athlete',
              field: 'athlete.athleteName',
              editable: 'never',
              cellStyle: tableCellStyle,
              width: '20%',
              render: (row) => (
                <Typography sx={tableCellStyle}>
                  {row.athlete ? row.athlete.athleteName : NON_ATHLETE_PROP}
                </Typography>
              ),
            },
            {
              title: 'Proposition',
              field: 'proposition',
              cellStyle: tableCellStyle,
              width: '50%',
              editable: 'never',
            },
            {
              title: 'Decimal Odds',
              field: 'decimalOdds',
              cellStyle: tableCellStyle,
              width: '15%',
              hidden: ODDS_TYPES.DECIMAL === oddType ? false : true,
              editComponent: (props) => (
                <>
                  <DecimalField
                    sx={{ ...editCellTextFieldStyle }}
                    value={props.value}
                    onChange={(e: any) => {
                      props.onChange(e.target.value);
                    }}
                    error={props?.error}
                    helperText={props?.helperText || ''}
                    thousandSeparator={false}
                    decimalScale={2}
                  />
                </>
              ),
              validate: (row) => {
                const newValue = +row?.decimalOdds;
                return !(
                  newValue >= oddRanges.MIN_DECIMAL_ODDS && newValue <= oddRanges.MAX_DECIMAL_ODDS
                )
                  ? {
                      isValid: false,
                      helperText: validationMessages.DECIMAL_ODDS_VALIDATION_MSG,
                    }
                  : true;
              },
            },
            {
              title: 'Fractional Odds',
              field: 'fractionalOdds',
              cellStyle: tableCellStyle,
              width: '15%',
              hidden: ODDS_TYPES.FRACTIONAL === oddType ? false : true,
              editComponent: (props: any) => (
                <>
                  <TextField
                    sx={{ ...editCellTextFieldStyle }}
                    value={props.value}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                    error={props?.error}
                    helperText={props?.helperText || ''}
                  />
                </>
              ),
              validate: (row) => {
                const newValue = row?.fractionalOdds;
                const decimal = oddsHelpers.decimalFromFraction(newValue.toString());

                return !newValue.match(fractionPattern) ||
                  newValue === '0' ||
                  !(
                    decimal >= oddRanges.MIN_FRACTION_ODDS && decimal <= oddRanges.MAX_FRACTION_ODDS
                  )
                  ? {
                      isValid: false,
                      helperText: validationMessages.FRACTION_ODDS_VALIDATION_MSG,
                    }
                  : true;
              },
            },
            {
              title: 'American Odds',
              field: 'americanOdds',
              cellStyle: tableCellStyle,
              width: '15%',
              hidden: ODDS_TYPES.AMERICAN === oddType ? false : true,
              editComponent: (props: any) => (
                <>
                  <DecimalField
                    sx={{ ...editCellTextFieldStyle }}
                    value={props.value}
                    onChange={(e: any) => {
                      props.onChange(e.target.value > 0 ? `+${e.target.value}` : e.target.value);
                    }}
                    error={props?.error}
                    helperText={props?.helperText || ''}
                    thousandSeparator={false}
                    decimalScale={0}
                    allowNegative={true}
                  />
                </>
              ),
              validate: (row) => {
                const newValue = Number(row?.americanOdds);
                return !(
                  (newValue >= oddRanges.MIN_NEGATIVE_AMERICAN_ODDS &&
                    newValue <= oddRanges.MAX_NEGATIVE_AMERICAN_ODDS) ||
                  (newValue >= oddRanges.MIN_AMERICAN_ODDS &&
                    newValue <= oddRanges.MAX_AMERICAN_ODDS)
                )
                  ? {
                      isValid: false,
                      helperText: validationMessages.AMERICAN_ODDS_VALIDATION_MSG,
                    }
                  : true;
              },
            },
            {
              title: 'Payout',
              field: 'propBetPayout',
              render: (props: any) => (
                <Switch
                  checked={props.propBetPayout}
                  disabled={props.payout || props.voided || props.editedVoid}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    payoutVoidedToggler({ checked: e?.target?.checked, propsId: props?.id })
                  }
                />
              ),
              editable: 'never',
              cellStyle: tableCellStyle,
              width: '10%',
            },
            {
              title: 'Voided',
              field: 'editedVoid',
              align: 'right',
              editable: 'never',
              render: (props: any) => (
                <>
                  <Switch
                    disabled={props.payout || props.voided || props.propBetPayout}
                    checked={props.editedVoid}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      payoutVoidedToggler({
                        checked: e?.target?.checked,
                        propsId: props?.id,
                        toggleField: TOGGLE_OPTIONS.VOIDED,
                      });
                      setTempVoidedRowId(props?.id);
                      handleToggleConfirmVoidDialog();
                    }}
                  />
                </>
              ),
            },
          ]}
          // key={modifiedPropBets.length}
          // tableRef={tableRef}
          data={modifiedPropBets}
          options={{
            rowStyle: (data) => {
              return { backgroundColor: data.voided ? grey[300] : '' };
            },
            toolbar: false,
            actionsColumnIndex: -1,
            ...defaultTableOptions,
            pageSize: createTablePageOptions(modifiedPropBets?.length || 0, true).pageSize,
            pageSizeOptions: createTablePageOptions(modifiedPropBets?.length || 0, true)
              .pageSizeOptions,
          }}
          editable={{
            isEditable: (rowData: any) => !rowData?.payout && !rowData?.voided,
            onRowUpdate: (newData: any) => rowUpdateHandler(newData),
          }}
          localization={{
            header: {
              actions: '',
            },
          }}
          components={{
            Pagination: () => (
              <Grid item xs={12} sx={{ padding: '0.359rem 0.5rem' }}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setAddBetPopUp(true);
                  }}
                  color="primary"
                >
                  Add prop
                </Button>
              </Grid>
            ),
          }}
        />
      </Box>
      {showAddBetPopup && (
        <AddEventPropBetDialog
          open={showAddBetPopup}
          handleClose={() => setAddBetPopUp(false)}
          playerDropdownOptions={playerDropdownOptions}
          addPropBetOddsData={addPropBetOddsData}
        />
      )}
      {isConfirmDialogOpen && (
        <ConfirmDialog
          open={isConfirmDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={handleConfirmAndSettle}
          title={'ARE YOU SURE YOU WANT TO SETTLE ALL PROP BETS?'}
          body={
            'This will payout all bets and will require Voiding if there are any errors. Make sure these are all correct.'
          }
        />
      )}
      {isConfirmVoidDialogOpen && (
        <ConfirmDialog
          open={isConfirmVoidDialogOpen}
          handleClose={() => {
            handleToggleConfirmVoidDialog();
            payoutVoidedToggler({
              checked: false,
              propsId: tempVoidedRowId,
              toggleField: TOGGLE_OPTIONS.VOIDED,
            });
            handleResetTempVoidRowId();
          }}
          handleConfirm={handleConfirmVoid}
          title={'ARE YOU SURE YOU WANT TO VOID PROP BET?'}
          body={'This will void the prop bet.'}
        />
      )}
      {showOddsUpdateDialog && (
        <ConfirmDialog
          open={showOddsUpdateDialog}
          handleClose={handleToggleOddsUpdateConfirmDialog}
          handleConfirm={() => {
            handleToggleOddsUpdateConfirmDialog();
            updateOddsData();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ODDS?`}
          body={'This will update the odds.'}
        />
      )}
      {showResetOddsDialog && (
        <ConfirmDialog
          open={showResetOddsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            setInitialOddsData(true);
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE ODDS?`}
          body={'This will reset the odds.'}
        />
      )}
    </Box>
  );
};

export default PropBetsOddsTab;
