import { Grid } from '@mui/material';
import React, { Fragment } from 'react';

import Banner from '@/components/Banner';

import { Props } from './interfaces';

const BaseLayout: React.VFC<Props> = ({ component: Component, ...rest }) => (
  <Fragment>
    <Banner />
    <Grid
      sx={{
        minHeight: '100vh',
        overflow: 'auto',
      }}
      container
      direction="column"
      justifyContent="center"
      alignContent="center"
    >
      <Component {...rest} />
    </Grid>
  </Fragment>
);

export default BaseLayout;
