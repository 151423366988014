import React, { useCallback, useMemo } from 'react';
import MaterialTable from '@material-table/core';
import { useFormik } from 'formik';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Typography,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  IconButton,
  styled,
  Box,
  Grid,
  Tooltip,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { EventListingSkeleton } from '@/components/PageSkeletons';
import UploadBtn from '@/components/UploadBtn';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import SaveOddsBtn from '@/components/SaveOddsBtn';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import DecimalField from '@/components/DecimalField';
import routes from '@/constants/routes';
import {
  tableIcons,
  defaultTableOptions,
  createTablePageOptions,
  headToHeadTableStyles,
  editCellTextFieldStyle,
} from '@/utils/TableMisc';

import { ReactComponent as EditIconBlue } from '@/images/icons/edit-icon-blue.svg';
import { ReactComponent as CheckedIconGreen } from '@/images/icons/checked-icon-green.svg';
import { ReactComponent as CloseIconRed } from '@/images/icons/close-icon-red.svg';

import useAddEventWeights from '@/hooks/events/useAddEventWeights';
import useTestEventWeights from '@/hooks/events/useTestEventWeights';

import {
  EVENT_SEED_WEIGHT_TYPES,
  gridCustomTemplateAreaBuilder,
  SPORTS_EVENT_SEEDS_WITH_WEIGHT,
} from '@/constants/misc';

import useEventSeeds from './useEventSeeds';
import keys from '@/constants/queryKeys';
import { invalidateCachedEvent } from '@/helpers/cachedQueries';

import { IEvent } from '@/types/newTypes/event';

const LabelText = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.04375rem',
  textTransform: 'uppercase',
});

const ValText = styled(Typography)({
  fontSize: '1rem',
  lineHeight: '150%',
});

type EVENT_WEIGHT = {
  id?: string;
  type: string;
  location?: string | null;
  year?: number | null;
  weight: number;
}[];

const EventSeeds = ({ columns, sportType }: { columns: any[]; sportType: string }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { eventId = '' } = useParams();
  const { mutate: addEventWeights } = useAddEventWeights();
  const { mutate: testEventWeights } = useTestEventWeights();
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [tempTableData, setTempTableData] = React.useState<any[]>([]);
  const {
    navigate,
    isFetching,
    eventSeeds,
    rowUpdateHandler,
    updateSeeds,
    hasEventSeedsBeenEdited,
    handleToggleHasEventSeedsBeenEdited,
    showResetSeedsDialog,
    handleToggleResetDialog,
    showUpdateSeedsDialog,
    handleToggleSaveDialog,
    tableData,
    initialDataLoader,
    WEIGHT_YEAR_OPTIONS,
    WEIGHT_EVENT_LOCATION_OPTIONS,
    refetchSeeds,
  } = useEventSeeds({ sportType });

  const queryClient = useQueryClient();

  const event: IEvent | undefined = queryClient.getQueryData([
    keys.events.fetchEvent,
    sportType,
    eventId,
    'Event',
  ]);

  const filterPayload = useCallback((wgts: EVENT_WEIGHT) => {
    return wgts.map((wgt) => {
      delete wgt?.id;
      return { ...wgt, weight: +wgt?.weight };
    });
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      canEdit: false,
      years: event?.weights?.filter((data) => data.type === EVENT_SEED_WEIGHT_TYPES.year) || [],
      location: event?.weights
        ?.filter((data) => data.type === EVENT_SEED_WEIGHT_TYPES.location)
        ?.at(0) || { location: '', weight: 0, year: null, type: EVENT_SEED_WEIGHT_TYPES.location },
    },
    onSubmit: () => {
      handleUpdateEventWeights();
      updateSeeds();
    },
  });

  const { values, setFieldValue, handleSubmit, resetForm } = formik;

  const payloadFormatter = useCallback(() => {
    const years = filterPayload(values?.years);
    const loc = filterPayload([values?.location]);
    return [...years, ...loc];
  }, [values?.years, values?.location, filterPayload]);

  const handleResetForm = useCallback(() => {
    // Need to reset the table data cause material table doesn't update the latest data properly
    initialDataLoader([]);
    resetForm();
    // Resetting back to original/ re-fetched event seeds data
    setTimeout(() => {
      initialDataLoader(eventSeeds);
    }, 300);
  }, [initialDataLoader, resetForm, eventSeeds]);

  const handleUpdateEventWeights = useCallback(() => {
    addEventWeights(
      {
        sport: sportType,
        eventId,
        requestPayload: {
          items: payloadFormatter(),
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Updated Event Weight Successfully');
          setDisableBtn(false);
          refetchSeeds();
          handleResetForm();
          invalidateCachedEvent(queryClient, sportType, eventId, 'Event');
        },
        onError: () => {
          setDisableBtn(false);
        },
      },
    );
  }, [
    addEventWeights,
    enqueueSnackbar,
    sportType,
    eventId,
    payloadFormatter,
    setDisableBtn,
    refetchSeeds,
    handleResetForm,
    queryClient,
  ]);

  const handleTestEventWeights = useCallback(() => {
    testEventWeights(
      {
        sport: sportType,
        eventId,
        requestPayload: {
          weights: payloadFormatter(),
        },
      },
      {
        onSuccess: (data) => {
          enqueueSnackbar('Fetched Event Seeds Successfully');
          setDisableBtn(false);
          setTempTableData(data);
          initialDataLoader(data);
        },
        onError: () => {
          setDisableBtn(false);
        },
      },
    );
  }, [
    testEventWeights,
    sportType,
    eventId,
    payloadFormatter,
    enqueueSnackbar,
    setDisableBtn,
    setTempTableData,
    initialDataLoader,
  ]);

  const calcWeight = useMemo(() => {
    return [...(values?.years || []), values?.location].reduce(
      (sum, val) => sum + (+val?.weight || 0),
      0,
    );
  }, [values?.years, values?.location]);

  const allSelectedYears = useMemo(() => {
    return [...((values?.years as { year: number }[]) || [])].map((opt) => +opt?.year) || [];
  }, [values?.years]);

  const customGridAreaXS = useMemo(() => {
    const gridStrings = ['seasonLabel', 'seasonPercentLabel'];
    [...((values?.years as { year: number }[]) || [])].map((_, idx) => {
      gridStrings.push(...[`season${idx + 1}Field`, `seasonPercent${idx + 1}Field`]);
    });
    gridStrings.push(
      ...[
        'fillerTop',
        'fillerBottom',
        'locationLabel',
        'locationPercentLabel',
        'locationField',
        'locationPercentField',
        '.',
        '.',
        'actionBtns',
        'actionBtns',
      ],
    );
    return gridCustomTemplateAreaBuilder({ areas: gridStrings, noOfColumns: 2 });
  }, [values?.years]);

  const customGridAreaSM = React.useMemo(() => {
    return `"seasonLabel ${values.years
      ?.map((_, idx) => `season${idx + 1}Field`)
      .toString()
      .replaceAll(',', ' ')} fillerTop locationLabel locationField" 
"seasonPercentLabel ${values.years
      ?.map((_, idx) => `seasonPercent${idx + 1}Field`)
      .toString()
      .replaceAll(
        ',',
        ' ',
      )} fillerBottom locationPercentLabel locationPercentField" ". ${values.years
      ?.map(() => `.`)
      .toString()
      .replaceAll(',', ' ')} . . actionBtns"`;
  }, [values?.years]);

  if (isFetching) return <EventListingSkeleton />;

  const renderEventSeedsForm = (
    <>
      {SPORTS_EVENT_SEEDS_WITH_WEIGHT.includes(sportType) && (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '2rem', mt: 2.5, mb: 2 }}>
            <Typography sx={{ fontSize: '1.125rem', lineHeight: '1.25rem' }}>Weighting</Typography>
            {values?.canEdit && (
              <Box sx={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.5rem',
                    color: calcWeight === 100 ? '#479F76' : '#DC3545',
                  }}
                >
                  {calcWeight}%
                </Typography>
                <Box sx={{ display: 'flex', gap: '0.6rem', alignItems: 'center' }}>
                  <Tooltip title="Test weights" placement="top">
                    <IconButton
                      disableRipple
                      onClick={() => {
                        setDisableBtn((prev) => !prev);
                        handleTestEventWeights();
                      }}
                      disabled={calcWeight !== 100 || disableBtn ? true : false}
                    >
                      <CheckedIconGreen style={{ fontSize: '0.8rem' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Close edit weighting" placement="top">
                    <IconButton disableRipple disabled={disableBtn} onClick={handleResetForm}>
                      <CloseIconRed style={{ fontSize: '0.8rem' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}
            {!values?.canEdit && (
              <IconButton
                disableRipple
                onClick={() => {
                  setFieldValue('canEdit', !values?.canEdit);
                  initialDataLoader([]);
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'info.main',
                    fontSize: '0.875rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  <EditIconBlue style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                  &nbsp;Edit weighting
                </Typography>
              </IconButton>
            )}
          </Box>
          {/*  */}

          <Box
            sx={{
              display: 'grid',
              columnGap: '1rem',
              rowGap: '1rem',
              alignItems: 'center',
              gridTemplateAreas: { xs: customGridAreaXS, lg: customGridAreaSM },
              gridTemplateColumns: {
                xs: 'repeat(2,minmax(0,1fr))',
                lg: `minmax(8ch, 10ch) ${
                  values?.years && values?.years?.length > 0
                    ? `repeat(${values?.years?.length || 0}, minmax(10ch, 10ch))`
                    : ''
                } 5px minmax(8ch, 10ch)  minmax(8ch, 25ch)`,
              },
              mb: values?.canEdit ? 4 : 2,
            }}
          >
            <LabelText sx={{ gridArea: 'seasonLabel' }}>Season</LabelText>
            <LabelText sx={{ gridArea: 'locationLabel' }}>Location</LabelText>
            <LabelText sx={{ gridArea: 'seasonPercentLabel' }}>Percent</LabelText>
            <LabelText sx={{ gridArea: 'locationPercentLabel' }}>Percent</LabelText>
            <span style={{ gridArea: 'fillerTop' }}></span>
            <span style={{ gridArea: 'fillerBottom' }}></span>
            {!values.canEdit && (
              <>
                {values?.years &&
                  values?.years.map((opt, idx) => (
                    <>
                      <ValText sx={{ gridArea: `season${idx + 1}Field` }}>
                        {opt.year || '-'}
                      </ValText>
                      <ValText sx={{ gridArea: `seasonPercent${idx + 1}Field` }}>
                        {opt.weight || 0}%
                      </ValText>
                    </>
                  ))}
                <ValText sx={{ gridArea: `locationField` }}>
                  {values?.location?.location || '-'}
                </ValText>
                <ValText sx={{ gridArea: `locationPercentField` }}>
                  {values?.location?.weight || 0}%
                </ValText>
              </>
            )}
            {values.canEdit && (
              <>
                {values?.years &&
                  values?.years?.map((opt, idx) => (
                    <>
                      <Select
                        value={(values?.years[idx]?.year || 0).toString()}
                        onChange={(evt: SelectChangeEvent) => {
                          const {
                            target: { value },
                          } = evt;
                          const yearsValues = JSON.parse(JSON.stringify(values?.years || []));
                          const currentYear = WEIGHT_YEAR_OPTIONS.find(
                            (opt: { year: number }) => +opt.year === +value,
                          );
                          yearsValues[idx] = currentYear;
                          setFieldValue('years', yearsValues);
                        }}
                        sx={{
                          gridArea: `season${idx + 1}Field`,
                          height: 40,
                          ...headToHeadTableStyles.athleteNameStyle,
                        }}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                      >
                        {WEIGHT_YEAR_OPTIONS.map((opt: { year: number }) => (
                          <MenuItem
                            key={opt.year}
                            value={opt.year}
                            disabled={allSelectedYears.includes(opt.year) ? true : false}
                          >
                            {opt.year}
                          </MenuItem>
                        ))}
                      </Select>
                      <DecimalField
                        name={`years[${idx}].weight}`}
                        value={values?.years[idx]?.weight || 0}
                        sx={{ gridArea: `seasonPercent${idx + 1}Field`, ...editCellTextFieldStyle }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const yearsValues = JSON.parse(JSON.stringify(values?.years || []));
                          const currentYear = { ...(values?.years[idx] || {}) };
                          currentYear.weight = +e?.target?.value || 0;
                          yearsValues[idx] = currentYear;
                          setFieldValue('years', yearsValues);
                        }}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      >
                        {values?.years[idx]?.weight || 0}%
                      </DecimalField>
                    </>
                  ))}
                <Select
                  value={values?.location?.location || ''}
                  onChange={(evt: SelectChangeEvent) => {
                    const {
                      target: { value },
                    } = evt;
                    setFieldValue(
                      'location',
                      WEIGHT_EVENT_LOCATION_OPTIONS.find(
                        (opt: { location: string }) => opt?.location === value,
                      ),
                    );
                  }}
                  sx={{
                    gridArea: `locationField`,
                    height: 40,
                    ...headToHeadTableStyles.athleteNameStyle,
                  }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                >
                  {WEIGHT_EVENT_LOCATION_OPTIONS.map((opt: { location: string }) => (
                    <MenuItem key={opt.location} value={opt.location}>
                      {opt.location}
                    </MenuItem>
                  ))}
                </Select>
                <DecimalField
                  name="location.weight"
                  value={values?.location?.weight || 0}
                  sx={{ gridArea: `locationPercentField`, ...editCellTextFieldStyle }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const loc = { ...(values?.location || {}) };
                    loc.weight = +e?.target?.value || 0;
                    setFieldValue('location', loc);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                >
                  {values?.location?.weight || 0}%
                </DecimalField>
              </>
            )}
            {values.canEdit && (
              <Box
                sx={{
                  gridArea: 'actionBtns',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  disabled={disableBtn ? true : false}
                  onClick={() => {
                    resetForm();
                    setFieldValue('canEdit', true);
                    initialDataLoader([]);
                  }}
                >
                  Revert
                </Button>
                <Button
                  variant="contained"
                  disableElevation
                  disabled={calcWeight !== 100 || disableBtn ? true : false}
                  onClick={() => {
                    setDisableBtn((prev) => !prev);
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <Box>
      {/* <Grid container direction="column"> */}
      {renderEventSeedsForm}
      <>
        <Box sx={{ marginBottom: '0.875rem' }} display="flex" justifyContent="flex-end">
          <ResetOddsBtn
            text={'Reset Seed'}
            disabled={!hasEventSeedsBeenEdited}
            resetOddsHandler={handleToggleResetDialog}
          />
          {!values.canEdit && (
            <SaveOddsBtn
              disabled={!hasEventSeedsBeenEdited}
              saveOddsHandler={handleToggleSaveDialog}
            />
          )}
        </Box>
        <Grid
          container
          // justifyContent={'flex-end'}
          alignItems={'center'}
          item
          xs={12}
          sx={{ marginY: '1rem' }}
        >
          <UploadBtn handleOnClick={() => navigate(routes.traders.seeds.upload)} />
        </Grid>
      </>

      <MaterialTable
        icons={tableIcons}
        columns={columns}
        data={tableData}
        options={{
          toolbar: false,
          // header: false,
          actionsColumnIndex: -1,
          rowStyle: {
            overflowWrap: 'break-word',
          },
          thirdSortClick: false,
          // sorting: true,
          ...defaultTableOptions,
          pageSize: createTablePageOptions(tableData?.length || 0).pageSize,
          pageSizeOptions: createTablePageOptions(tableData?.length || 0).pageSizeOptions,
        }}
        editable={{
          onRowUpdate: (newData: any) => rowUpdateHandler(newData),
        }}
        localization={{
          header: {
            actions: '',
          },
        }}
      />
      {/* <Grid item xs={12} sx={{ overflowY: 'scroll' }}>
          
        </Grid> */}
      {/* </Grid> */}
      {showUpdateSeedsDialog && (
        <ConfirmDialog
          open={showUpdateSeedsDialog}
          handleClose={handleToggleSaveDialog}
          handleConfirm={() => {
            handleToggleSaveDialog();
            if (values?.canEdit) {
              handleUpdateEventWeights();
            }
            updateSeeds();
          }}
          title={`ARE YOU SURE YOU WANT TO UPDATE THE ${
            values?.canEdit ? 'WEIGHTS & SEEDS' : 'SEEDS'
          }?`}
          body={`This will update the ${values?.canEdit ? 'weights & seeds' : 'seeds'}.`}
        />
      )}
      {showResetSeedsDialog && (
        <ConfirmDialog
          open={showResetSeedsDialog}
          handleClose={handleToggleResetDialog}
          handleConfirm={() => {
            handleToggleResetDialog();
            if (values?.canEdit) {
              // Need to reset the table data cause material table doesn't update the latest data properly
              initialDataLoader([]);
              setTimeout(() => {
                initialDataLoader(tempTableData);
              }, 300);
            } else {
              initialDataLoader(eventSeeds);
            }

            handleToggleHasEventSeedsBeenEdited();
          }}
          title={`ARE YOU SURE YOU WANT TO RESET THE SEEDS?`}
          body={'This will reset the seeds.'}
        />
      )}
    </Box>
  );
};

export default EventSeeds;
