import { useQuery } from '@tanstack/react-query';

import keys from '@/constants/queryKeys';

import { authenticatedAxios } from '@/hooks/index';

import { DreamTeamOddRow } from '@/types/odds/getOdds';

interface FETCH_DREAM_TEAM_ODDS {
  sport: string;
  eventId: string;
}

const getDreamTeamOdds = async ({
  sport,
  eventId,
}: FETCH_DREAM_TEAM_ODDS): Promise<DreamTeamOddRow[]> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sport}/events/${eventId}/dreamTeam/odds`,
  });

  return data;
};

export default function useFetchDreamTeamOdds({ sport, eventId }: FETCH_DREAM_TEAM_ODDS) {
  return useQuery(
    [keys.odds.fetchDreamTeamOdds, sport, eventId],
    () => getDreamTeamOdds({ sport, eventId }),
    {
      staleTime: Infinity,
      enabled: !!eventId,
    },
  );
}
