import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';

import { DreamTeamOddRow, Team } from '@/types/odds/getOdds';

export const initialDataLoader = ({ dreamTeams }: { dreamTeams: DreamTeamOddRow[] }) => {
  const initialData = dreamTeams.map((dreamTeam, idx) => ({
    ...dreamTeamFormatter(dreamTeam),
    matchupId: idx + 1,
  }));
  return initialData;
};

export const dreamTeamFormatter = (dreamTeamOdd: DreamTeamOddRow) => {
  const { teams } = dreamTeamOdd;
  const modTeams = teams.map((team) => {
    const participantNames: string[] = [];
    const modParticipants =
      team?.participants &&
      team?.participants.map((participant) => {
        const label =
          [participant?.firstName, participant?.middleName, participant?.lastName]
            .filter(Boolean)
            .join(' ') || '';
        participantNames.push(label);
        return {
          ...participant,
          label,
        };
      });
    return {
      ...team,
      decimalOdds: round(oddsHelpers.getDecimalOdds(team.odds), 2),
      fractionalOdds: oddsHelpers.getFractionalOdds(team.odds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(team.probability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(team.probability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(team.probability)}`,
      probability: round(team.probability, 2),
      participants: modParticipants,
      participantNames: participantNames.toString().replaceAll(',', ', ') || '',
    };
  });

  return { ...dreamTeamOdd, teams: modTeams };
};

export const dreamTeamHoldPercentageModifier = ({
  teams,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  teams: Team[];
  newHoldPercentage: number;
  oldHoldPercentage: number;
  defaultHoldPercentage: number;
}) => {
  // if there are no odds then return an empty array
  if (!teams || (teams && teams.length === 0)) return [];

  const modifiedTeamOdds: Team[] = [];
  // modify event odds position
  teams?.map((team) => {
    // Get new probability
    const newProbability = oddsHelpers.getNewProbabilityAfterEdit({
      probability: Number(team?.probability),
      newHoldPercentage,
      oldHoldPercentage,
      defaultHoldPercentage,
    });
    // get new odds
    const newOdds = oddsHelpers.getDecimalOddsFromProbability(+newProbability);
    const modifiedTeamOdd: Team = {
      ...team,
      odds: newOdds,
      decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toFixed(2),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(newProbability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`,
      probability: newProbability > 0 ? +newProbability.toFixed(2) : 0,
      // hasModifiedProbability: false,
    };

    modifiedTeamOdds.push(modifiedTeamOdd);
    return team;
  });

  const sortedOdds = modifiedTeamOdds.sort((a, b) => Number(b.probability) - Number(a.probability));
  return sortedOdds;
};

export const findTotalMatchupPercentage = (
  teams: Team[],
  modifiedTeam: string,
  {
    modifiedRowsOnly = false,
    excludeModifiedRows = false,
  }: {
    modifiedRowsOnly?: boolean;
    excludeModifiedRows?: boolean;
  },
) => {
  if (!teams || teams?.length === 0) return 0;

  const totalPercentage = teams
    .filter((data) => {
      let result = data.team !== modifiedTeam;

      if (modifiedRowsOnly) result = result && data.hasModifiedProbability;
      if (excludeModifiedRows) result = result && !data.hasModifiedProbability;

      return result;
    })
    .reduce((total, item) => total + round(+item.probability, 2), 0);

  return totalPercentage;
};

export const matchupEditRowFormatter = (modifiedTeam: Team, teams: Team[] | undefined = []) => {
  const oldTeamOdds = teams ? [...teams] : [];

  const oldTeamData = oldTeamOdds.find((data) => data.team === modifiedTeam.team);

  const probabilityChangeDifference =
    Number(oldTeamData?.probability) - Number(modifiedTeam?.probability);

  // finding the modified row and replacing it
  const newTeamOdds = teams.map((obj) => [modifiedTeam].find((o) => o.team === obj.team) || obj);

  const unlockedTeams = getEditableTeamsCount(newTeamOdds);

  // Difference that has to be added/subtracted from every unlocked athlete
  const unlockedTeamsChangeDelta = round(probabilityChangeDifference / unlockedTeams, 2);

  const updatedAthleteOdds = newTeamOdds.map((team) => {
    if (team.hasModifiedProbability) {
      return team;
    } else {
      const newProbability =
        round(+team.probability, 2) > 0
          ? round(+team.probability, 2) + unlockedTeamsChangeDelta
          : 0;
      const normalisedProbability = newProbability < 0 ? 0 : newProbability;
      // const normalisedProbability = newProbability;
      const newOdds = oddsHelpers.getDecimalOddsFromProbability(normalisedProbability);
      const updatedUnlockedOdds = {
        ...team,
        odds: newOdds,
        decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toString(),
        fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
        americanOdds:
          oddsHelpers.getAmericanOddsFromProbability(normalisedProbability) > 0
            ? `+${oddsHelpers.getAmericanOddsFromProbability(normalisedProbability)}`
            : `${oddsHelpers.getAmericanOddsFromProbability(normalisedProbability)}`,
        probability: round(normalisedProbability, 2),
      };
      return updatedUnlockedOdds;
    }
  });

  return updatedAthleteOdds;
};

export const getEditableTeamsCount = (teamList: Team[]) =>
  teamList.reduce((previousValue: number, currentValue: Team) => {
    if (!currentValue.hasModifiedProbability) {
      return previousValue + 1;
    } else {
      return previousValue;
    }
  }, 0);

export const dreamTeamMatchupPayloadFormatter = (teams: Team[]) => {
  if (!teams || (teams && teams?.length === 0)) return [];

  const payload: Team[] = [];

  teams.map((data) => {
    // const participants: Participant[] = [];
    // data.participants.map((partaker) => {
    //   const participant = {
    //     firstName: partaker?.firstName,
    //     middleName: partaker?.middleName,
    //     lastName: partaker?.lastName,
    //     eventParticipantId: partaker?.eventParticipantId,
    //   };
    //   participants.push(participant);
    //   return partaker;
    // });
    const team = {
      id: data?.id,
      // team: data?.team,
      probability: round(+data?.probability, 2),
      hasModifiedProbability: data?.hasModifiedProbability,
      odds: round(+data?.odds, 2),
      // participants,
    };
    payload.push(team);
    return data;
  });

  return payload;
};
