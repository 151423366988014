import React from 'react';
import { Column } from '@material-table/core';
import { Typography, TextField, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import round from 'lodash.round';

import { DEFAULT_START_OF_LAP_TIME, formatMMSSToSeconds } from '@/helpers/timeConverters';

import { processAthleteStatusBadges, ATHLETE_STATUS_OPTIONS } from '@/helpers/athletes';
import { tableCellStyle, editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import DecimalField from '@/components/DecimalField';
import { LapTimeField } from '@/components/LapTimeFormat';

type EventSeed = {
  id: string;
  seedNo: number;
  status: number;
  athlete: {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    nationality: string;
    stance: string;
  };
  event: {
    id: string;
    eventStatus: number;
    name: string;
  };
  baseNonJokerLapTime: number;
  baseProjection: number;
  baseHeadLapTime: number;
  headCrashRate: number;
  raceCrashRate: number;
  soloCrashRate: number;
  notes: string;
};

const NRXTableEventSeedsColumns: Column<any>[] = [
  { title: 'Athlete Id', field: 'athlete.id', hidden: true, cellStyle: tableCellStyle },
  {
    title: 'Event Seed',
    field: 'seedNo',
    cellStyle: tableCellStyle,
    editable: 'never',
    customSort: (a: EventSeed, b: EventSeed) => a.seedNo - b.seedNo,
  },
  {
    title: 'Name',
    field: 'name',
    editable: 'never',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>
        {[rowData?.athlete?.firstName, rowData?.athlete?.middleName, rowData?.athlete?.lastName]
          .filter(Boolean)
          .join(' ')}
      </Typography>
    ),
  },
  {
    title: 'Nationality',
    field: 'athlete.nationality',
    editable: 'never',
    cellStyle: tableCellStyle,
  },
  { title: 'Stance', field: 'athlete.stance', editable: 'never', cellStyle: tableCellStyle },
  {
    title: 'Status',
    field: 'status',
    render: (rowData: EventSeed) => processAthleteStatusBadges(rowData?.status),
    editComponent: (props: any) => {
      return (
        <Select
          value={props.value}
          onChange={(event: SelectChangeEvent<typeof props.value>) => {
            const {
              target: { value },
            } = event;
            props.onChange(value);
          }}
          sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
          MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
        >
          {ATHLETE_STATUS_OPTIONS.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      );
    },
  },
  {
    title: 'Base Solo',
    field: 'baseProjection',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{rowData?.baseProjection || ''}</Typography>
    ),
    editComponent: (props: any) => {
      const formattedPenaltyTime: string = props.value || DEFAULT_START_OF_LAP_TIME;
      const unformattedPenaltyTime: number | string = formatMMSSToSeconds(
        `${formattedPenaltyTime}`,
      );
      return (
        <LapTimeField
          fullWidth
          value={unformattedPenaltyTime === 0 ? '' : formattedPenaltyTime.toString()}
          mask="MM{:}SS{.}MS"
          blocks={{
            MM: {
              mask: '0[0]',
            },
            SS: {
              mask: '0[0]',
            },
            MS: {
              mask: '0[0][0]',
            },
          }}
          definitions={{
            $: /[0-9]/,
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const newVal = event.target.value;
            const unformattedNewPenaltyTime: number | string = formatMMSSToSeconds(`${newVal}`);
            if (unformattedNewPenaltyTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
            const formattedNewLapTime: string = newVal;
            if (unformattedNewPenaltyTime !== unformattedPenaltyTime) {
              props.onChange(formattedNewLapTime);
            }
          }}
          sx={{ ...editCellTextFieldStyle }}
        />
      );
    },
  },
  {
    title: 'Base H2H',
    field: 'baseHeadLapTime',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{rowData?.baseHeadLapTime || ''}</Typography>
    ),
    editComponent: (props: any) => {
      const formattedPenaltyTime: string = props.value || DEFAULT_START_OF_LAP_TIME;
      const unformattedPenaltyTime: number | string = formatMMSSToSeconds(
        `${formattedPenaltyTime}`,
      );
      return (
        <LapTimeField
          fullWidth
          value={unformattedPenaltyTime === 0 ? '' : formattedPenaltyTime}
          mask="MM{:}SS{.}MS"
          blocks={{
            MM: {
              mask: '0[0]',
            },
            SS: {
              mask: '0[0]',
            },
            MS: {
              mask: '0[0][0]',
            },
          }}
          definitions={{
            $: /[0-9]/,
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const newVal = event.target.value;
            const unformattedNewPenaltyTime: number | string = formatMMSSToSeconds(`${newVal}`);
            if (unformattedNewPenaltyTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
            const formattedNewLapTime: string = newVal;
            if (unformattedNewPenaltyTime !== unformattedPenaltyTime) {
              props.onChange(formattedNewLapTime);
            }
          }}
          sx={{ ...editCellTextFieldStyle }}
        />
      );
    },
  },
  {
    title: 'Base Race',
    field: 'baseNonJokerLapTime',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{rowData?.baseNonJokerLapTime || ''}</Typography>
    ),
    editComponent: (props: any) => {
      const formattedPenaltyTime: string = props.value || DEFAULT_START_OF_LAP_TIME;
      const unformattedPenaltyTime: number | string = formatMMSSToSeconds(
        `${formattedPenaltyTime}`,
      );
      return (
        <LapTimeField
          fullWidth
          value={unformattedPenaltyTime === 0 ? '' : formattedPenaltyTime}
          mask="MM{:}SS{.}MS"
          blocks={{
            MM: {
              mask: '0[0]',
            },
            SS: {
              mask: '0[0]',
            },
            MS: {
              mask: '0[0][0]',
            },
          }}
          definitions={{
            $: /[0-9]/,
          }}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            const newVal = event.target.value;
            const unformattedNewPenaltyTime: number | string = formatMMSSToSeconds(`${newVal}`);
            if (unformattedNewPenaltyTime === 0) event.target.value = DEFAULT_START_OF_LAP_TIME;
            const formattedNewLapTime: string = newVal;
            if (unformattedNewPenaltyTime !== unformattedPenaltyTime) {
              props.onChange(formattedNewLapTime);
            }
          }}
          sx={{ ...editCellTextFieldStyle }}
        />
      );
    },
  },
  {
    title: 'H2H crash rate',
    field: 'headCrashRate',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{round(rowData?.headCrashRate, 2) || 0}</Typography>
    ),
    editComponent: (props: any) => (
      <DecimalField
        value={props.value}
        onBlur={(e: any) => {
          const newVal = +e.target.value;
          props.onChange(newVal);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
        thousandSeparator={false}
        decimalScale={2}
        sx={editCellTextFieldStyle}
      />
    ),
  },
  {
    title: 'Race crash rate',
    field: 'raceCrashRate',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{round(rowData?.raceCrashRate, 2) || 0}</Typography>
    ),
    editComponent: (props: any) => (
      <DecimalField
        value={props.value}
        onBlur={(e: any) => {
          const newVal = +e.target.value;
          props.onChange(newVal);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
        thousandSeparator={false}
        decimalScale={2}
        sx={editCellTextFieldStyle}
      />
    ),
  },
  {
    title: 'Solo crash rate',
    field: 'soloCrashRate',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{round(rowData?.soloCrashRate, 2) || 0}</Typography>
    ),
    editComponent: (props: any) => (
      <DecimalField
        value={props.value}
        onBlur={(e: any) => {
          const newVal = +e.target.value;
          props.onChange(newVal);
        }}
        error={props?.error}
        helperText={props?.helperText || ''}
        thousandSeparator={false}
        decimalScale={2}
        sx={editCellTextFieldStyle}
      />
    ),
  },
  {
    title: 'Notes',
    field: 'notes',
    render: (rowData: EventSeed) => (
      <Typography sx={tableCellStyle}>{rowData?.notes || '-'}</Typography>
    ),
    editComponent: (props: any) => (
      <>
        <TextField
          sx={{ ...editCellTextFieldStyle }}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
          error={props?.error}
          helperText={props?.helperText || ''}
        />
      </>
    ),
  },
];

export default NRXTableEventSeedsColumns;
