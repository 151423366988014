import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import { ButtonBase } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import FilterSelect from '@/components/FilterSelect';

import { sortEventsByStatusAndStartDate } from '@/helpers/events';

import { theme } from '@/constants/theme';
import { sportOptions } from '@/constants/sportTypes';
import { eventStatus } from '@/constants/events';

import { IEvent } from '@/types/newTypes/event';
import { SportTourFutures } from '@/types/futures';

type filterOption = {
  label: string;
  value: string | number;
};

type filterOptions = {
  sportOptions: filterOption[];
  // tourOptions: filterOption[];
  yearOptions: filterOption[];
  statusOptions: filterOption[];
};

type filterValues = {
  sport: filterOption | null;
  // tour: filterOption | null;
  year: filterOption | null;
  status: filterOption | null;
};

// Initial filter options.
const defaultOptions: filterOptions = {
  sportOptions: sportOptions,
  // tourOptions: [],
  yearOptions: [],
  statusOptions: eventStatus,
};

export default function Filters(props: any) {
  const isExtraLargeAndUp = useMediaQuery(theme.breakpoints.up('lg'));
  const { allFutures, setFilteredFutures, setFutureStatus, setQueryParam } = props;
  // const filterOptionsRef = useRef(options);
  const [filterOptions, setFilterOptions] = useState<filterOptions>(defaultOptions);

  useEffect(() => {
    // const tours: string[] = [];
    const years: number[] = [];
    const statuses: number[] = [];

    allFutures.forEach((event: SportTourFutures) => {
      const { year, futureStatus } = event;
      // tour, league,
      // If sport is SLS handle league prop, else handle tour prop.
      // event.sportType === SPORT_TYPES.SLS.toLowerCase()
      //   ? league?.name && tours.push(league?.name)
      //   : tour?.name && tours.push(tour?.name);
      year && years.push(year);
      futureStatus && statuses.push(futureStatus);
    });

    // const tourOptions = [...new Set(tours)].map((tour) => ({
    //   label: tour,
    //   value: tour,
    // }));
    const yearOptions = [...new Set(years)].map((year) => ({
      label: year.toString(),
      value: year.toString(),
    }));
    const options = {
      ...filterOptions,
      // tourOptions,
      yearOptions,
    };
    // Update filter options.
    setFilterOptions(options);
  }, [allFutures]);

  // Handle filter submit to update events displayed on the table.
  const handleFilterSubmit = (values: filterValues) => {
    const { sport, year } = values;
    // tour,
    let filteredArr: IEvent[];
    filteredArr = JSON.parse(JSON.stringify(allFutures));
    if (sport) {
      filteredArr = filteredArr.filter((event: IEvent) => event.sportType === sport?.value);
    }
    // if (tour) {
    //   filteredArr = filteredArr.filter(
    //     (event: IEvent) => event.tour?.name === tour.label || event.league?.name === tour.label,
    //   );
    // }
    if (year) {
      filteredArr = filteredArr.filter((event: IEvent) => event.year.toString() === year.label);
    }
    // if (status) {
    //   const selectedStatuses = status?.value;
    //   setQueryParam(`eventStatus[]=${selectedStatuses}`);
    //   setFutureStatus([selectedStatuses]);
    // } else {
    //   setQueryParam('');
    //   setFutureStatus([]);
    // }
    const sortedEvents = sortEventsByStatusAndStartDate(filteredArr);
    setFilteredFutures(sortedEvents);
  };

  return (
    <Formik
      initialValues={{
        sport: null,
        // tour: null,
        year: null,
        status: null,
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleFilterSubmit(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Box sx={{ marginBottom: '2rem' }}>
          <Paper sx={{ boxShadow: 'none' }}>
            <Grid
              container
              justifyContent="flex-start"
              alignContent="center"
              sx={(theme) => ({
                '& .MuiGrid-item': {
                  // paddingTop: '0.75rem',
                  // paddingBottom: '0.2rem',
                  padding: '0.2rem',
                  display: 'flex',
                  alignItems: 'flex-end',
                  maxHeight: '7rem',
                  border: '1px solid rgba(0, 0, 0, 0.125)',
                  '&:first-of-type': {
                    borderRadius: '0.25rem 0 0 0.25rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                    borderRadius: '0.25rem',
                    borderBottom: 'none',
                  },
                  [theme.breakpoints.between('md', 'lg')]: {
                    width: '50%',
                    '&:nth-of-type(2)': {
                      borderLeft: 'none',
                      borderRadius: '0 0.25rem 0.25rem 0',
                    },
                    '&:nth-of-type(3)': {
                      borderTop: 'none',
                      borderRadius: '0.25rem 0 0 0.25rem',
                    },
                    '&:nth-of-type(4)': {
                      borderLeft: 'none',
                      borderTop: 'none',
                      borderRadius: '0 0.25rem 0.25rem 0',
                    },
                  },
                  [theme.breakpoints.up('lg')]: {
                    width: '23.86%',
                    borderRight: 'none',
                  },
                },
                '& .MuiButtonBase-root.apply-filter': {
                  color: 'white',
                  fontWeight: '500',
                  fontSize: '1.125rem',
                  fontFamily: "'Inter', sans-serif",
                  backgroundColor: theme.palette.primary.main,
                  minHeight: '4rem',
                  borderRadius: '0.25rem',
                  '& .MuiSvgIcon-root': {
                    width: '1.6rem',
                    height: '1.6rem',
                  },
                  [theme.breakpoints.down('md')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.between('md', 'lg')]: {
                    width: '100%',
                  },
                  [theme.breakpoints.up('lg')]: {
                    width: '4.525%',
                    borderRadius: '0 0.25rem 0.25rem 0',
                  },
                },
                '& .MuiAutocomplete-root': {
                  width: '100%',
                },
              })}
            >
              <Grid item>
                <FilterSelect
                  name="sport"
                  type="text"
                  label="Sport"
                  options={filterOptions.sportOptions}
                  onChange={(event: any, newValue: filterOption | null) => {
                    setFieldValue('sport', newValue);
                    handleSubmit();
                  }}
                />
              </Grid>
              {/* <Grid item>
                <FilterSelect
                  name="tour"
                  type="text"
                  label="Tour"
                  options={filterOptions.tourOptions}
                  onChange={(event: any, newValue: filterOption | null) => {
                    setFieldValue('tour', newValue);
                    handleSubmit();
                  }}
                />
              </Grid> */}
              <Grid item>
                <FilterSelect
                  name="year"
                  type="text"
                  label="Year"
                  options={filterOptions.yearOptions}
                  onChange={(event: any, newValue: filterOption | null) => {
                    setFieldValue('year', newValue);
                    handleSubmit();
                  }}
                />
              </Grid>
              <Grid item>
                <FilterSelect
                  name="status"
                  type="text"
                  label="Future Status"
                  value={values.status}
                  options={filterOptions.statusOptions}
                  // multiple
                  // filterSelectedOptions
                  onChange={(event: any, newValue: any) => {
                    setFieldValue('status', newValue);
                    const selectedStatus = newValue?.value;
                    if (selectedStatus) {
                      setQueryParam(`eventStatus[]=${selectedStatus}`);
                      setFutureStatus([selectedStatus]);
                    } else {
                      setQueryParam('');
                      setFutureStatus([]);
                    }
                  }}
                />
              </Grid>
              <ButtonBase className={'apply-filter'} onClick={() => handleSubmit()}>
                {isExtraLargeAndUp ? <SearchIcon sx={{ color: 'white' }} /> : 'Apply filters'}
              </ButtonBase>
            </Grid>
          </Paper>
        </Box>
      )}
    </Formik>
  );
}
