import React from 'react';
import { Button } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadBtn = ({
  handleOnClick,
  btnText = '',
}: {
  handleOnClick: Function;
  btnText?: string;
}) => {
  return (
    <Button
      variant="contained"
      startIcon={<UploadFileIcon />}
      onClick={() => handleOnClick()}
      disableElevation
    >
      {btnText || 'Upload CSV'}
    </Button>
  );
};

export default UploadBtn;
