import { useMutation } from '@tanstack/react-query';

import { authenticatedAxios } from '..';

const addOrUpdateUser = async (requestPayload: any) => {
  const { data } = await authenticatedAxios({
    method: requestPayload?.id ? 'PUT' : 'POST',
    url: `admin/users${requestPayload?.id ? `/${requestPayload?.id}` : ''}`,
    data: requestPayload?.data,
  });

  return data;
};

export default function useAddOrUpdateUser() {
  return useMutation((requestPayload: any) => addOrUpdateUser(requestPayload));
}
