import orderBy from 'lodash.orderby';

import { FUTURE_STATUS } from '@/constants/futures';

import { SportTourFutures } from '@/types/futures';

/* Sorts events by futureStatus (live above the rest) and startDate (most recent first) */
export const sortFuturesByStatusAndStartDate = (
  futures: SportTourFutures[],
): SportTourFutures[] => {
  const sortedLiveUpcomingNextFutures = orderBy(
    futures.filter((future) => future.futureStatus === FUTURE_STATUS.LIVE),
    ['futureStatus', (future) => new Date(future.startDate).valueOf()],
    ['asc', 'asc'],
  );
  const sortedNonLiveUpcomingNextFutures = orderBy(
    futures.filter((future) => future.futureStatus !== FUTURE_STATUS.LIVE),
    ['futureStatus', (future) => new Date(future.startDate).valueOf()],
    ['desc', 'asc'],
  );
  return [...sortedLiveUpcomingNextFutures, ...sortedNonLiveUpcomingNextFutures];
};

export const sortFuturesByStartDate = (futures: SportTourFutures[]) =>
  orderBy(futures, [(future) => new Date(future.startDate).valueOf()], ['asc']);
