// import SPORT_TYPES from '@/constants/sportTypes';

export const allowedTableHeaders = [
  { title: 'Event', field: 'event' },
  { title: 'Tour Name', field: 'tourName' },
  { title: 'Gender', field: 'gender' },
  { title: 'Tour Year', field: 'year' },
  { title: 'Athlete', field: 'athlete' },
  { title: 'Position', field: 'position' },
  { title: 'Odds', field: 'americanOdds' },
  { title: 'Probability', field: 'probability' },
];

// export const allowedTableHeaders = {
//   [SPORT_TYPES.SPR]: allowedSPRHeaders,
// };

interface CommonTraderEventOddRow {
  event: string;
  tour_name: string;
  gender: string;
  year: number;
  athlete: string;
  position: number;
  american_odds: number;
}

// interface SPRTraderEventOddRow extends CommonTraderEventOddRow {}

export type TraderEventOddsRow = CommonTraderEventOddRow;

const commonRequiredKeys = [
  'event',
  'tour_name',
  'gender',
  'year',
  'athlete',
  'position',
  'american_odds',
];

// export const SPRRequiredKeys = [...commonRequiredKeys];

export const requiredPayloadKeys = [...commonRequiredKeys];
// {
//   [SPORT_TYPES.SPR]: SPRRequiredKeys,
// };
