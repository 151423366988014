import * as React from 'react';

import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { styled } from '@mui/material';
import SPORT_TYPES from '@/constants/sportTypes';
import { useLocation, useNavigate } from 'react-router-dom';
import { EVENT_TAB_QUERY_PARAMS } from '@/constants/misc';

interface EventTabsProps {
  sportType: string;
  currentTabValue: string;
  setNextTabValue: (nextTabValue: string) => void;
  DatabaseElement?: () => JSX.Element;
  SeedsElement?: () => JSX.Element;
  ScoresElement?: () => JSX.Element;
  OddsElement?: () => JSX.Element;
  ReportsElement?: () => JSX.Element;
  defaultTabValue?: string;
}

const StyledTabList = styled(TabList)({
  marginTop: '0.25rem',
  marginBottom: '0.75rem',
  minHeight: 0,
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

const StyledTab = styled((props: TabProps) => <Tab component="span" disableRipple {...props} />)(
  ({ theme }) => ({
    minHeight: 'unset',
    textTransform: 'none',
    alignItems: 'flex-start',
    color: theme.palette.info.contrastText,
    fontWeight: 500,
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    minWidth: 'auto',
    maxWidth: 'auto',
    padding: 0,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'white',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    paddingRight: '1rem',
  }),
);

const StyledTabPanel = styled(TabPanel)({
  padding: '0.5rem 0',
});

const ODD_TAB = '3';

const EventTabs = (props: EventTabsProps) => {
  const {
    sportType,
    currentTabValue,
    // setNextTabValue,
    DatabaseElement,
    SeedsElement,
    ScoresElement,
    OddsElement,
    ReportsElement,
    // defaultTabValue,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    if (newValue !== currentTabValue) {
      const redirectParams = new URLSearchParams();
      redirectParams.set(EVENT_TAB_QUERY_PARAMS.eventTab, newValue);
      navigate(
        {
          pathname: location.pathname,
          search: redirectParams.toString(),
        },
        { replace: true },
      );
    }
  };

  const eventTabsCount = React.useMemo(() => {
    const tabCount =
      [!!SeedsElement, !!ScoresElement, !!OddsElement, !!DatabaseElement, !!ReportsElement].filter(
        Boolean,
      ).length || 0;
    return tabCount;
  }, [SeedsElement, ScoresElement, OddsElement, DatabaseElement, ReportsElement]);

  return (
    <TabContext value={currentTabValue}>
      {eventTabsCount > 1 && (
        <Box>
          <StyledTabList onChange={handleChange}>
            {!!DatabaseElement && <StyledTab label="Database" value="0" />}
            {!!SeedsElement && (
              <StyledTab
                label={
                  [SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP].includes(sportType)
                    ? 'Participants'
                    : 'Seeds'
                }
                value="1"
              />
            )}
            {!!ScoresElement && (
              <StyledTab
                label={
                  [SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP].includes(sportType)
                    ? 'Results'
                    : 'Scores'
                }
                value="2"
              />
            )}
            {!!OddsElement && <StyledTab label="Odds" value={ODD_TAB} />}
            {!!ReportsElement && <StyledTab label="Reports" value="4" />}
          </StyledTabList>
        </Box>
      )}
      {!!DatabaseElement && (
        <StyledTabPanel value="0">
          <DatabaseElement />
        </StyledTabPanel>
      )}
      {!!SeedsElement && (
        <StyledTabPanel value="1">
          <SeedsElement />
        </StyledTabPanel>
      )}
      {!!ScoresElement && (
        <StyledTabPanel value="2">
          <ScoresElement />
        </StyledTabPanel>
      )}
      {!!OddsElement && (
        <StyledTabPanel value={ODD_TAB}>
          <OddsElement />
        </StyledTabPanel>
      )}
      {!!ReportsElement && (
        <StyledTabPanel value="4">
          <ReportsElement />
        </StyledTabPanel>
      )}
    </TabContext>
  );
};

export default EventTabs;
