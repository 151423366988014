import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';
import { ToursProps } from '@/components/EventDetails';

const getTours = async (sportType: string): Promise<ToursProps[]> => {
  const query = new URLSearchParams();
  query.append('sportType', sportType);
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/tours?${query.toString()}`,
  });

  return data;
};

export default function useFetchTours(sportType: string, fromComponent: string) {
  return useQuery([keys.events.fetchTours, sportType, fromComponent], () => getTours(sportType), {
    staleTime: Infinity,
  });
}
