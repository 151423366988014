import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '..';

const getHeat = async (heatId: string, sportType: string) => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/traders/scores/heats/${heatId}`,
  });

  return data;
};

export default function usefetchHeat(heatId: string, sportType: string) {
  return useQuery([keys.traders.fetchHeat, sportType, heatId], () => getHeat(heatId, sportType), {
    staleTime: Infinity,
    refetchInterval: 3 * (60 * 1000),
    refetchIntervalInBackground: true,
    enabled: !!heatId,
  });
}
