import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import useFetchSportTourFutures from '@/hooks/futures/sports/useFetchSportTourFutures';

import { invalidateCachedSportTourFutures } from '@/helpers/cachedQueries';

const useSportTourFutures = () => {
  const { sportType = '', tourYearId = '' } = useParams();
  const { data: sportTourFutures, isLoading } = useFetchSportTourFutures({
    sportType,
    tourYearId,
    fromComponent: 'SportTourFutures',
  });

  const queryClient = useQueryClient();

  /* To refetch data when sportTourFutures (cached) is visited again */
  useEffect(() => {
    if (sportTourFutures) {
      invalidateCachedSportTourFutures({
        queryClient,
        sportType,
        tourYearId,
        fromComponent: 'SportTourFutures',
      });
    }
  }, [sportType, tourYearId]);

  return { sportTourFutures, isLoading };
};

export default useSportTourFutures;
