import round from 'lodash.round';

import * as oddsHelpers from '@/helpers/odds';

import { FutureOdd } from '@/types/helpers/futureOdds';

export const getTotalProbability = (athleteList: FutureOdd[]) => {
  if (!athleteList || (athleteList && athleteList?.length === 0)) return 0;

  const totalProbability = round(
    athleteList.reduce((previousValue: number, currentValue: FutureOdd) => {
      return previousValue + round(Number(currentValue?.probability), 2);
    }, 0),
  );
  return totalProbability === 0 ? 100 : totalProbability;
};

export const futureOddsDataLoader = (futureOdds: FutureOdd[]) => {
  // if there are no odds then return an empty array
  if (!futureOdds || (futureOdds && futureOdds.length === 0)) return [];

  const modifiedFutureOdds: FutureOdd[] = [];

  // modify future odds position
  futureOdds?.map((currentFutureOdd) => {
    const probability = Number(currentFutureOdd?.probability);
    const odds = oddsHelpers.getDecimalOddsFromProbability(probability);
    const futureOdd: FutureOdd = {
      ...currentFutureOdd,
      odds: odds,
      decimalOdds: oddsHelpers.getDecimalOdds(odds).toString(),
      fractionalOdds: oddsHelpers.getFractionalOdds(odds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(probability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(probability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(probability)}`,
    };

    modifiedFutureOdds.push(futureOdd);
    return currentFutureOdd;
  });

  const sortedOdds = modifiedFutureOdds.sort(
    (a, b) => Number(b.probability) - Number(a.probability),
  );
  return sortedOdds;
};

export const futureOddsPayloadFormatter = (futureOdds: FutureOdd[] = []) => {
  if (!futureOdds || (futureOdds && futureOdds?.length === 0)) return [];

  const payload: FutureOdd[] = [];

  futureOdds.map((data) => {
    const futureOdd = {
      id: data?.id,
      probability: round(Number(data?.probability), 2),
      hasModifiedProbability: data?.hasModifiedProbability,
      odds: round(Number(data?.odds), 2),
      trueProbability: data?.trueProbability || 0,
    };
    payload.push(futureOdd);
    return data;
  });

  return payload;
};

export const futureOddsModifier = ({
  futureOdds,
  newHoldPercentage = 100,
  oldHoldPercentage = 100,
  defaultHoldPercentage = 100,
}: {
  futureOdds: FutureOdd[];
  newHoldPercentage: number;
  oldHoldPercentage: number;
  defaultHoldPercentage?: number;
}) => {
  // if there are no odds then return an empty array
  if (!futureOdds || (futureOdds && futureOdds.length === 0)) return [];

  const modifiedFutureOdds: FutureOdd[] = [];
  // modify event odds position
  futureOdds?.map((futureOdd) => {
    // Get new probability
    const newProbability = oddsHelpers.getNewProbabilityAfterEdit({
      probability: Number(futureOdd?.probability),
      newHoldPercentage,
      oldHoldPercentage,
      defaultHoldPercentage,
    });
    // get new odds
    const newOdds = oddsHelpers.getDecimalOddsFromProbability(+newProbability);
    const eventOdd: FutureOdd = {
      ...futureOdd,
      odds: newOdds,
      decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toFixed(2),
      fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
      americanOdds:
        oddsHelpers.getAmericanOddsFromProbability(newProbability) > 0
          ? `+${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`
          : `${oddsHelpers.getAmericanOddsFromProbability(newProbability)}`,
      probability: newProbability > 0 ? newProbability.toFixed(2) : 0,
      // hasModifiedProbability: false,
    };

    modifiedFutureOdds.push(eventOdd);
    return futureOdd;
  });

  const sortedOdds = modifiedFutureOdds.sort(
    (a, b) => Number(b.probability) - Number(a.probability),
  );
  return sortedOdds;
};

export const editRowFormatter = (modifiedRow: FutureOdd, odds: FutureOdd[] | undefined = []) => {
  const oddRows = odds ? [...odds] : [];

  const oldRow = oddRows.find((row) => row.id === modifiedRow.id);

  const probabilityChangeDifference =
    Number(oldRow?.probability) - Number(modifiedRow?.probability);

  // finding the modified row and replacing it
  const athleteOdds = odds.map((obj) => [modifiedRow].find((o) => o.id === obj.id) || obj);

  const unlockedAthletes = oddsHelpers.getEditableAthletesCount(athleteOdds);

  // Difference that has to be added/subtracted from every unlocked athlete
  const unlockedAthletesChangeDelta = round(probabilityChangeDifference / unlockedAthletes, 2);

  const updatedAthleteOdds = athleteOdds.map((athlete) => {
    if (athlete.hasModifiedProbability) {
      return athlete;
    } else {
      const newProbability =
        round(Number(athlete.probability), 2) > 0
          ? round(Number(athlete.probability), 2) + unlockedAthletesChangeDelta
          : 0;
      const normalisedProbability = newProbability < 0 ? 0 : newProbability;
      // const normalisedProbability = newProbability;
      const newOdds = oddsHelpers.getDecimalOddsFromProbability(normalisedProbability);
      const updatedUnlockedOdds = {
        ...athlete,
        odds: newOdds,
        decimalOdds: oddsHelpers.getDecimalOdds(newOdds).toString(),
        fractionalOdds: oddsHelpers.getFractionalOdds(newOdds).toString(),
        americanOdds:
          oddsHelpers.getAmericanOddsFromProbability(normalisedProbability) > 0
            ? `+${oddsHelpers.getAmericanOddsFromProbability(normalisedProbability)}`
            : `${oddsHelpers.getAmericanOddsFromProbability(normalisedProbability)}`,
        probability: round(normalisedProbability, 2),
      };
      return updatedUnlockedOdds;
    }
  });

  return updatedAthleteOdds;
};
