import { TextField as DecimalTextField } from '@mui/material';
import React from 'react';
import { NumericFormat } from 'react-number-format';

interface CustomProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  onBlur: (event: { target: { name: string; value: string } }) => void;
  name: string;
  id: string;
  thousandSeparator?: string | boolean | undefined;
  decimalScale?: number | undefined;
  allowNegative?: boolean;
}

const NumberFormatCustom = React.forwardRef<NumericFormat<any>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const {
      onChange,
      thousandSeparator = false,
      decimalScale = 2,
      allowNegative = false,

      ...other
    } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator={thousandSeparator}
        decimalScale={decimalScale}
        allowNegative={allowNegative}
      />
    );
  },
);

const DecimalField = (props: any) => {
  const {
    thousandSeparator = false,
    decimalScale = 2,
    allowNegative = false,
    id,
    ...otherProps
  } = props;
  return (
    <DecimalTextField
      id={id || undefined}
      {...otherProps}
      InputProps={{
        inputComponent: NumberFormatCustom as any,
        ...otherProps.InputProps,
      }}
      inputProps={{ thousandSeparator, decimalScale, allowNegative, 'data-testid': id }}
    />
  );
};

export default DecimalField;
