import round from 'lodash.round';

import {
  getAmericanOddsFromProbability,
  getDecimalOdds,
  getDecimalOddsFromProbability,
  getFractionalOdds,
} from './odds';

export const fightEditRowFormatter = (
  modifiedRow: any,
  odds: any[] = [],
  holdPercentage: number,
) => {
  const oddRows = [...odds];
  const otherRow = oddRows.find((row) => row.id !== modifiedRow.id) || {};
  const isPlayer1Modified = oddRows[0]?.id === modifiedRow.id;

  const [player1Row, player2Row] = isPlayer1Modified
    ? [modifiedRow, otherRow]
    : [otherRow, modifiedRow];

  const newProbabilities = [player1Row, player2Row].map((player) => {
    const probability =
      player.id === modifiedRow.id ? player.probability : holdPercentage - modifiedRow.probability;
    return Math.max(0, Math.min(probability, holdPercentage));
  });

  const [newOddsPlayer1, newOddsPlayer2] = newProbabilities.map(getDecimalOddsFromProbability);

  const calculateOdds = (newOdds: number, probability: number) => ({
    decimalOdds: round(getDecimalOdds(newOdds) || 0, 2),
    fractionalOdds: getFractionalOdds(newOdds).toString(),
    americanOdds: getAmericanOddsFromProbability(probability).toString(),
    probability: round(probability, 2),
    odds: newOdds,
  });

  return [
    { ...player1Row, ...calculateOdds(newOddsPlayer1, newProbabilities[0]) },
    { ...player2Row, ...calculateOdds(newOddsPlayer2, newProbabilities[1]) },
  ];
};
