import { parseISO } from 'date-fns';
import orderBy from 'lodash.orderby';

import {
  DEFAULT_START_OF_LAP_TIME,
  formatMMSSToSeconds,
  formatSecondsToMMSS,
} from './timeConverters';

import { NRX_DEFAULT_NUMBER_OF_LAPS, NRX_LAPS_STARTS_WITH } from '@/constants/heats';
import { SKIP_SCORE_FORM_HEAT_STATUSES } from '@/constants/skipStatuses';
import SPORT_TYPES from '@/constants/sportTypes';
import {
  INITIAL_NRX_HEAT_SCORES_STATUS_OPTION,
  NRX_HEAT_SCORES_STATUS_OPTIONS,
  NRX_HEAT_SCORES_STATUS,
} from '@/constants/nrxHeatScoresStatus';

import { displayInPT as format } from '@/helpers/timeConverters';

import { RoundDTO } from '@/types/round';
import { HeatWithHeatKeyDTO, HeatDTO, HeatScoresDTO } from '@/types/heat';
import { AthleteDTO } from '@/types/athlete';
import { HLRS_HEAT_SCORES_STATUS } from '@/constants/hlrsHeatScoresStatus';
import { FDRIFT_HEAT_SCORES_STATUS } from '@/constants/fdriftHeatScoresStatus';

export const formatHeatObj = (heatObj: HeatWithHeatKeyDTO): HeatDTO => {
  return {
    ...heatObj.heat,
    scores: heatObj.scores,
  };
};

export const sortHeatsByHeatNo = (heats: HeatDTO[]) => {
  if (!heats || (heats && heats.length === 0)) return [];
  const modHeats = heats.sort((a: any, b: any) => Number(a?.heatNo || 0) - Number(b?.heatNo || 0));
  return modHeats;
};

export const formatNRXHeatScores = (scores: HeatScoresDTO[]) => {
  const formattedHeatScoresObj: any = {};
  let lapStartsWith = NRX_LAPS_STARTS_WITH;
  let lapsCount = NRX_DEFAULT_NUMBER_OF_LAPS;

  if (scores && scores.length > 0) {
    lapStartsWith = 1;
    lapsCount = 1;
    scores.forEach((hScore: any) => {
      const heatScore = JSON.parse(JSON.stringify(hScore));
      const currentRoundSeed = heatScore.roundSeed;
      const lapNumber = 'lap_' + heatScore.lapNumber;
      const score = formattedHeatScoresObj[currentRoundSeed];
      const unformattedLapTime: number = heatScore.lapTime; // Ex: 125.41 (in seconds)
      const formattedLapTime: string = formatSecondsToMMSS(unformattedLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)
      const formattedJokerLapTime: string = formatSecondsToMMSS(heatScore.jokerLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)
      const formattedPenaltyTime: string = formatSecondsToMMSS(heatScore.penaltyTime?.toString());
      // Get the lowest lap count for the heat.
      lapStartsWith = heatScore.lapNumber < lapStartsWith ? heatScore.lapNumber : lapStartsWith;
      // Get the highest lap count for the heat.
      lapsCount = heatScore.lapNumber > lapsCount ? heatScore.lapNumber : lapsCount;

      // Format status
      const formattedStatus = NRX_HEAT_SCORES_STATUS_OPTIONS.find(
        (scoreStatus: any) => scoreStatus.value === heatScore?.status,
      );
      // If object/row exists in formattedHeatScoresObj then update with lap values & best lap.
      if (score) {
        const unformattedBestLapTime: number = formatMMSSToSeconds(score.bestLap);

        score[lapNumber] = {
          storedId: heatScore.id,
          lapTime: formattedLapTime,
          isJoker: heatScore.isJoker,
          jokerLapTime: formattedJokerLapTime,
        };
        score.penaltyTime = formattedPenaltyTime;
        score.status = formattedStatus || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION;
        if (
          (unformattedBestLapTime > unformattedLapTime && unformattedLapTime > 0) ||
          unformattedBestLapTime === 0
        )
          score.bestLap = formattedLapTime;
      } else {
        formattedHeatScoresObj[currentRoundSeed] = {
          ...heatScore,
          toDelete: false,
          athlete: {
            ...heatScore.athlete,
            label: [
              heatScore?.athlete?.firstName,
              heatScore?.athlete?.middleName,
              heatScore?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
          [lapNumber]: {
            storedId: heatScore.id,
            lapTime: formattedLapTime,
            isJoker: heatScore.isJoker,
            jokerLapTime: formattedJokerLapTime,
          },
          bestLap: formattedLapTime,
          penaltyTime: formattedPenaltyTime,
          status: formattedStatus || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION,
        };
      }
    });
  }

  // Create an array of lap count with values beginning from 0 or 1, to be used wherever lap traversal necessary.
  const lapsArr = Array(lapsCount - lapStartsWith + 1)
    .fill(0)
    .map((_, idx) => lapStartsWith + idx);

  const formattedHeatScoresArr: HeatScoresDTO[] = Object.values(formattedHeatScoresObj);
  const sortedHeatScores = formattedHeatScoresArr.sort(
    (a: HeatScoresDTO, b: HeatScoresDTO) => a.roundSeed - b.roundSeed,
  );

  return {
    sortedHeatScores: sortedHeatScores,
    lapsArr: lapsArr,
  };
};

const HLRS_LAPS_STARTS_WITH = 0;
const HLRS_DEFAULT_NUMBER_OF_LAPS = 6;
export const formatHLRSHeatScores = (scores: HeatScoresDTO[]) => {
  const formattedHeatScoresObj: any = {};
  let lapStartsWith = HLRS_LAPS_STARTS_WITH;
  let lapsCount = HLRS_DEFAULT_NUMBER_OF_LAPS;

  if (scores && scores.length > 0) {
    lapStartsWith = 1;
    lapsCount = 1;
    scores.forEach((hScore: any) => {
      const heatScore = JSON.parse(JSON.stringify(hScore));
      const currentRoundSeed = heatScore.roundSeed;
      const lapNumber = 'lap_' + heatScore.lapNumber;
      const score = formattedHeatScoresObj[currentRoundSeed];
      const unformattedLapTime: number = heatScore.lapTime; // Ex: 125.41 (in seconds)
      const formattedLapTime: string = formatSecondsToMMSS(unformattedLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)
      const formattedJokerLapTime: string = formatSecondsToMMSS(heatScore.jokerLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)
      const formattedPenaltyTime: string = formatSecondsToMMSS(heatScore.penaltyTime?.toString());
      // Get the lowest lap count for the heat.
      lapStartsWith = heatScore.lapNumber < lapStartsWith ? heatScore.lapNumber : lapStartsWith;
      // Get the highest lap count for the heat.
      lapsCount = heatScore.lapNumber > lapsCount ? heatScore.lapNumber : lapsCount;

      // Format status
      const formattedStatus = NRX_HEAT_SCORES_STATUS_OPTIONS.find(
        (scoreStatus: any) => scoreStatus.value === heatScore?.status,
      );
      // If object/row exists in formattedHeatScoresObj then update with lap values & best lap.
      if (score) {
        const unformattedBestLapTime: number = formatMMSSToSeconds(score.bestLap);

        score[lapNumber] = {
          storedId: heatScore.id,
          lapTime: formattedLapTime,
          isJoker: heatScore.isJoker,
          jokerLapTime: formattedJokerLapTime,
        };
        score.penaltyTime = formattedPenaltyTime;
        score.status = formattedStatus || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION;
        if (
          (unformattedBestLapTime > unformattedLapTime && unformattedLapTime > 0) ||
          unformattedBestLapTime === 0
        )
          score.bestLap = formattedLapTime;
      } else {
        formattedHeatScoresObj[currentRoundSeed] = {
          ...heatScore,
          toDelete: false,
          athlete: {
            ...heatScore.athlete,
            label: [
              heatScore?.athlete?.firstName,
              heatScore?.athlete?.middleName,
              heatScore?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
          [lapNumber]: {
            storedId: heatScore.id,
            lapTime: formattedLapTime,
            isJoker: heatScore.isJoker,
            jokerLapTime: formattedJokerLapTime,
          },
          bestLap: formattedLapTime,
          penaltyTime: formattedPenaltyTime,
          status: formattedStatus || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION,
        };
      }
    });
  }

  // Create an array of lap count with values beginning from 0 or 1, to be used wherever lap traversal necessary.
  const lapsArr = Array(lapsCount - lapStartsWith + 1)
    .fill(0)
    .map((_, idx) => lapStartsWith + idx);

  const formattedHeatScoresArr: HeatScoresDTO[] = Object.values(formattedHeatScoresObj);
  const sortedHeatScores = formattedHeatScoresArr.sort(
    (a: HeatScoresDTO, b: HeatScoresDTO) => a.roundSeed - b.roundSeed,
  );

  return {
    sortedHeatScores: sortedHeatScores,
    lapsArr: lapsArr,
  };
};

const FDRIFT_LAPS_STARTS_WITH = 0;
const FDRIFT_DEFAULT_NUMBER_OF_LAPS = 1;
export const formatFDRIFTHeatScores = (scores: HeatScoresDTO[]) => {
  const formattedHeatScoresObj: any = {};
  let lapStartsWith = FDRIFT_LAPS_STARTS_WITH;
  let lapsCount = FDRIFT_DEFAULT_NUMBER_OF_LAPS;

  if (scores && scores.length > 0) {
    lapStartsWith = 1;
    lapsCount = 1;
    scores.forEach((hScore: any) => {
      const heatScore = JSON.parse(JSON.stringify(hScore));
      const currentRoundSeed = heatScore.roundSeed;
      const lapNumber = 'lap_' + heatScore.lapNumber;
      const score = formattedHeatScoresObj[currentRoundSeed];
      const unformattedLapTime: number = heatScore.lapTime; // Ex: 125.41 (in seconds)
      const formattedLapTime: string = formatSecondsToMMSS(unformattedLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)
      const formattedJokerLapTime: string = formatSecondsToMMSS(heatScore.jokerLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)
      const formattedPenaltyTime: string = formatSecondsToMMSS(heatScore.penaltyTime?.toString());
      // Get the lowest lap count for the heat.
      lapStartsWith = heatScore.lapNumber < lapStartsWith ? heatScore.lapNumber : lapStartsWith;
      // Get the highest lap count for the heat.
      lapsCount = heatScore.lapNumber > lapsCount ? heatScore.lapNumber : lapsCount;

      // Format status
      const formattedStatus = NRX_HEAT_SCORES_STATUS_OPTIONS.find(
        (scoreStatus: any) => scoreStatus.value === heatScore?.status,
      );
      // If object/row exists in formattedHeatScoresObj then update with lap values & best lap.
      if (score) {
        const unformattedBestLapTime: number = formatMMSSToSeconds(
          score.bestLap as unknown as number,
        );

        score[lapNumber] = {
          storedId: heatScore.id,
          lapTime: formattedLapTime,
          isJoker: heatScore.isJoker,
          jokerLapTime: formattedJokerLapTime,
        };
        score.penaltyTime = formattedPenaltyTime;
        score.status = formattedStatus || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION;
        if (
          (unformattedBestLapTime > unformattedLapTime && unformattedLapTime > 0) ||
          unformattedBestLapTime === 0
        )
          score.bestLap = formattedLapTime;
      } else {
        formattedHeatScoresObj[currentRoundSeed] = {
          ...heatScore,
          toDelete: false,
          athlete: {
            ...heatScore.athlete,
            label: [
              heatScore?.athlete?.firstName,
              heatScore?.athlete?.middleName,
              heatScore?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
          [lapNumber]: {
            storedId: heatScore.id,
            lapTime: formattedLapTime,
            isJoker: heatScore.isJoker,
            jokerLapTime: formattedJokerLapTime,
          },
          bestLap: formattedLapTime,
          penaltyTime: formattedPenaltyTime,
          status: formattedStatus || INITIAL_NRX_HEAT_SCORES_STATUS_OPTION,
        };
      }
    });
  }

  // Create an array of lap count with values beginning from 0 or 1, to be used wherever lap traversal necessary.
  const lapsArr = Array(lapsCount - lapStartsWith + 1)
    .fill(0)
    .map((_, idx) => lapStartsWith + idx);

  const formattedHeatScoresArr: HeatScoresDTO[] = Object.values(formattedHeatScoresObj);
  const sortedHeatScores = formattedHeatScoresArr.sort(
    (a: HeatScoresDTO, b: HeatScoresDTO) => a.roundSeed - b.roundSeed,
  );

  return {
    sortedHeatScores: sortedHeatScores,
    lapsArr: lapsArr,
  };
};

const NHRA_LAPS_STARTS_WITH = 1;
const NHRA_DEFAULT_NUMBER_OF_LAPS = 1;
export const formatNHRAHeatScores = (scores: HeatScoresDTO[]) => {
  const formattedHeatScoresObj: any = {};
  let lapStartsWith = NHRA_LAPS_STARTS_WITH;
  let lapsCount = NHRA_DEFAULT_NUMBER_OF_LAPS;

  if (scores && scores.length > 0) {
    lapStartsWith = 1;
    lapsCount = 1;
    scores.forEach((hScore: any) => {
      const heatScore = JSON.parse(JSON.stringify(hScore));
      const currentRoundSeed = hScore?.athleteId;
      const score = formattedHeatScoresObj[currentRoundSeed];
      const unformattedLapTime: number = heatScore.lapTime; // Ex: 125.41 (in seconds)
      const formattedLapTime: string = formatSecondsToMMSS(unformattedLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)

      // Get the lowest lap count for the heat.
      lapStartsWith = +heatScore.lapNumber < lapStartsWith ? +heatScore.lapNumber : lapStartsWith;
      // Get the highest lap count for the heat.
      lapsCount = +heatScore.lapNumber > lapsCount ? +heatScore.lapNumber : lapsCount;

      // If object/row exists in formattedHeatScoresObj then update with lap values & best lap.
      if (score) {
        // const unformattedBestLapTime: number = formatMMSSToSeconds(score.bestLap);

        score.lapTempTime = {
          storedId: heatScore.id,
          lapTime: formattedLapTime,
        };
        // if (
        //   (unformattedBestLapTime > unformattedLapTime && unformattedLapTime > 0) ||
        //   unformattedBestLapTime === 0
        // )
        //   score.bestLap = formattedLapTime;
      } else {
        formattedHeatScoresObj[currentRoundSeed] = {
          ...heatScore,
          toDelete: false,
          athlete: {
            ...heatScore.athlete,
            label: [
              heatScore?.athlete?.firstName,
              heatScore?.athlete?.middleName,
              heatScore?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
          lapTempTime: {
            storedId: heatScore.id,
            lapTime: formattedLapTime,
          },
          // bestLap: formattedLapTime
        };
      }
    });
  }

  // Create an array of lap count with values beginning from 0 or 1, to be used wherever lap traversal necessary.
  // const lapsArr = Array(lapsCount - lapStartsWith)
  //   .fill(1)
  //   .map((_, idx) => lapStartsWith + 1 + idx);
  const formattedHeatScoresArr: HeatScoresDTO[] = Object.values(formattedHeatScoresObj);
  const sortedHeatScores = formattedHeatScoresArr.sort(
    (a: HeatScoresDTO, b: HeatScoresDTO) => a.roundSeed - b.roundSeed,
  );

  const lapsArr = [1];

  return {
    sortedHeatScores: sortedHeatScores,
    lapsArr: lapsArr,
  };
};

const USAC_LAPS_STARTS_WITH = 0;
const USAC_DEFAULT_NUMBER_OF_LAPS = 1;
export const formatUSACHeatScores = (scores: HeatScoresDTO[]) => {
  const formattedHeatScoresObj: any = {};
  let lapStartsWith = USAC_LAPS_STARTS_WITH;
  let lapsCount = USAC_DEFAULT_NUMBER_OF_LAPS;

  if (scores && scores.length > 0) {
    lapStartsWith = 1;
    lapsCount = 1;
    scores.forEach((hScore: any) => {
      const heatScore = JSON.parse(JSON.stringify(hScore));
      const currentRoundSeed = heatScore.roundSeed;
      const lapNumber = 'lap_' + +heatScore.lapNumber;
      const score = formattedHeatScoresObj[currentRoundSeed];
      const unformattedLapTime: number = heatScore.lapTime; // Ex: 125.41 (in seconds)
      const formattedLapTime: string = formatSecondsToMMSS(unformattedLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)

      // Get the lowest lap count for the heat.
      lapStartsWith = +heatScore.lapNumber < lapStartsWith ? +heatScore.lapNumber : lapStartsWith;
      // Get the highest lap count for the heat.
      lapsCount = +heatScore.lapNumber > lapsCount ? +heatScore.lapNumber : lapsCount;

      // If object/row exists in formattedHeatScoresObj then update with lap values & best lap.
      if (score) {
        // const unformattedBestLapTime: number = formatMMSSToSeconds(score.bestLap);

        score[lapNumber] = {
          storedId: heatScore.id,
          lapTime: formattedLapTime,
        };
        // if (
        //   (unformattedBestLapTime > unformattedLapTime && unformattedLapTime > 0) ||
        //   unformattedBestLapTime === 0
        // )
        //   score.bestLap = formattedLapTime;
      } else {
        formattedHeatScoresObj[currentRoundSeed] = {
          ...heatScore,
          toDelete: false,
          athlete: {
            ...heatScore.athlete,
            label: [
              heatScore?.athlete?.firstName,
              heatScore?.athlete?.middleName,
              heatScore?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
          [lapNumber]: {
            storedId: heatScore.id,
            lapTime: formattedLapTime,
          },
          // bestLap: formattedLapTime
        };
      }
    });
  }

  // Create an array of lap count with values beginning from 0 or 1, to be used wherever lap traversal necessary.
  const lapsArr = Array(lapsCount - lapStartsWith + 1)
    .fill(0)
    .map((_, idx) => lapStartsWith + idx);

  const formattedHeatScoresArr: HeatScoresDTO[] = Object.values(formattedHeatScoresObj);
  const sortedHeatScores = formattedHeatScoresArr.sort(
    (a: HeatScoresDTO, b: HeatScoresDTO) => a.roundSeed - b.roundSeed,
  );

  return {
    sortedHeatScores: sortedHeatScores,
    lapsArr: lapsArr,
  };
};

const SPRMTCRS_LAPS_STARTS_WITH = 0;
const SPRMTCRS_DEFAULT_NUMBER_OF_LAPS = 1;
export const formatSPRMTCRSHeatScores = (scores: HeatScoresDTO[]) => {
  const formattedHeatScoresObj: any = {};
  let lapStartsWith = SPRMTCRS_LAPS_STARTS_WITH;
  let lapsCount = SPRMTCRS_DEFAULT_NUMBER_OF_LAPS;

  if (scores && scores.length > 0) {
    lapStartsWith = 1;
    lapsCount = 1;
    scores.forEach((hScore: any) => {
      const heatScore = JSON.parse(JSON.stringify(hScore));
      const currentRoundSeed = heatScore.roundSeed;
      const lapNumber = 'lap_' + +heatScore.lapNumber;
      const score = formattedHeatScoresObj[currentRoundSeed];
      const unformattedLapTime: number = heatScore.lapTime; // Ex: 125.41 (in seconds)
      const formattedLapTime: string = formatSecondsToMMSS(unformattedLapTime?.toString()); // Ex: 02:15.123 (in mins:secs.ms)

      // Get the lowest lap count for the heat.
      lapStartsWith = +heatScore.lapNumber < lapStartsWith ? +heatScore.lapNumber : lapStartsWith;
      // Get the highest lap count for the heat.
      lapsCount = +heatScore.lapNumber > lapsCount ? +heatScore.lapNumber : lapsCount;

      // If object/row exists in formattedHeatScoresObj then update with lap values & best lap.
      if (score) {
        // const unformattedBestLapTime: number = formatMMSSToSeconds(score.bestLap);

        score[lapNumber] = {
          storedId: heatScore.id,
          lapTime: formattedLapTime,
        };
        // if (
        //   (unformattedBestLapTime > unformattedLapTime && unformattedLapTime > 0) ||
        //   unformattedBestLapTime === 0
        // )
        //   score.bestLap = formattedLapTime;
      } else {
        formattedHeatScoresObj[currentRoundSeed] = {
          ...heatScore,
          toDelete: false,
          athlete: {
            ...heatScore.athlete,
            label: [
              heatScore?.athlete?.firstName,
              heatScore?.athlete?.middleName,
              heatScore?.athlete?.lastName,
            ]
              .filter(Boolean)
              .join(' '),
          },
          [lapNumber]: {
            storedId: heatScore.id,
            lapTime: formattedLapTime,
          },
          // bestLap: formattedLapTime
        };
      }
    });
  }

  // Create an array of lap count with values beginning from 0 or 1, to be used wherever lap traversal necessary.
  const lapsArr = Array(lapsCount - lapStartsWith + 1)
    .fill(0)
    .map((_, idx) => lapStartsWith + idx);

  const formattedHeatScoresArr: HeatScoresDTO[] = Object.values(formattedHeatScoresObj);
  const sortedHeatScores = formattedHeatScoresArr.sort(
    (a: HeatScoresDTO, b: HeatScoresDTO) => a.roundSeed - b.roundSeed,
  );

  return {
    sortedHeatScores: sortedHeatScores,
    lapsArr: lapsArr,
  };
};

export const getHeatFromId = (heatId: string, round: RoundDTO | null) => {
  if (!heatId || !round) return null;
  const heats = round.heats;
  if (heats.length === 0) return null;
  return heats.find((heat: any) => heat.id === heatId) || null;
};

export const getPreviousAndNextHeat = (round: RoundDTO | null, heatId: string) => {
  let prvHeat = null;
  let nxtHeat = null;
  const heats = round?.heats || [];
  if (heats.length === 0) return { prevHeat: prvHeat, nextHeat: nxtHeat };
  const heatsLen = heats.length || 0;
  const currentHeatIndex = heats.findIndex((currentHeat: any) => currentHeat.id === heatId);
  if (currentHeatIndex !== 0 && heatsLen > 1) prvHeat = heats[currentHeatIndex - 1];
  if (currentHeatIndex + 1 !== heatsLen && heatsLen > 1) nxtHeat = heats[currentHeatIndex + 1];
  return { prevHeat: prvHeat, nextHeat: nxtHeat };
};

export const getPreviousAndNextRound = (rounds: RoundDTO[] = [], roundId: string) => {
  let prvRound = null;
  let nxtRound = null;
  // const rounds = rounds || [];
  if (rounds.length === 0) return { prevRound: prvRound, nextRound: nxtRound };
  const roundsLen = rounds.length || 0;
  const currentRoundIndex = rounds.findIndex((round: RoundDTO) => round.id === roundId);
  if (currentRoundIndex !== 0 && roundsLen > 1) prvRound = rounds[currentRoundIndex - 1];
  if (currentRoundIndex + 1 !== roundsLen && roundsLen > 1)
    nxtRound = rounds[currentRoundIndex + 1];
  return { prevRound: prvRound, nextRound: nxtRound };
};

export const getProcessedColumns = (columns: any[]) => {
  return {
    filteredColumns: columns.filter((column) => !column.hidden),
    filteredHiddenColumns: columns.filter((column) => column.hidden === true),
    generatedGridTemplateColumns: columns
      .filter((column) => !column.hidden)
      .map((column) => column.width || '1fr')
      .join(' '),
  };
};

export const getSelectedHeatId = (heats: HeatDTO[], currentSelectedHeatId: string) => {
  const modHeats = sortHeatsByHeatNo(heats);
  const currentSelectedHeatIdx = modHeats.findIndex((heat) => heat.id === currentSelectedHeatId);
  const currentlySelectedHeat =
    currentSelectedHeatIdx >= 0 ? modHeats[currentSelectedHeatIdx] : null;

  if (currentlySelectedHeat) {
    return currentlySelectedHeat.id;
  } else {
    const firstHeatIndex = modHeats.findIndex(
      (heat: any) => !SKIP_SCORE_FORM_HEAT_STATUSES.includes(heat.heatStatus),
    );
    const firstHeat = firstHeatIndex >= 0 ? modHeats[firstHeatIndex] : null;

    if (firstHeat) {
      return firstHeat.id;
    } else {
      const [alternateFirstHeat] = modHeats;
      if (alternateFirstHeat) {
        return alternateFirstHeat.id;
      } else return '';
    }
  }
};

export const addFullnameLabelAndDeleteProps = (scores: HeatScoresDTO[]) => {
  return scores
    ? scores.map((score: any) => {
        return {
          ...score,
          toDelete: false,
          athlete: {
            ...score.athlete,
            label: [score?.athlete?.firstName, score?.athlete?.middleName, score?.athlete?.lastName]
              .filter(Boolean)
              .join(' '),
          },
        };
      })
    : [];
};

export const getNewRow = (sport: string, lastSeedVal: string, lapsArr: number[] | null = null) => {
  const commonProps = {
    roundSeed: +lastSeedVal + 1,
    athleteId: null,
    heatPosition: null,
    toDelete: false,
  };
  switch (sport) {
    case SPORT_TYPES.WSL:
      return {
        ...commonProps,
        heatScore: null,
      };
    case SPORT_TYPES.SLS:
      return {
        ...commonProps,
        roundScore: null,
        lineScore1: null,
        lineScore2: null,
        trick1Score: null,
        trick2Score: null,
        trick3Score: null,
        trick4Score: null,
        trick5Score: null,
        trick6Score: null,
      };
    case SPORT_TYPES.NRX: {
      const newNRXRow: any = {
        ...commonProps,
        penaltyTime: '',
        totalTime: '',
        status: INITIAL_NRX_HEAT_SCORES_STATUS_OPTION.value,
      };
      lapsArr?.forEach((key) => {
        const lapNumberKey = `lap_${key}`;
        newNRXRow[lapNumberKey] = {
          lapTime: '',
          isJoker: false,
          jokerLapTime: '',
          penaltyTime: '',
        };
      });
      return newNRXRow;
    }
    case SPORT_TYPES.HLRS:
    case SPORT_TYPES.FDRIFT:
    case SPORT_TYPES.NHRA: {
      const newNHRARow: any = {
        ...commonProps,
        totalTime: '',
      };
      lapsArr?.forEach((key) => {
        const lapNumberKey = `lap_${key}`;
        newNHRARow[lapNumberKey] = {
          lapTime: '',
        };
      });
      return newNHRARow;
    }
    default:
      return {};
  }
};

export const getHeatDate = (startDate: string) => {
  return startDate ? format(parseISO(startDate || ''), 'MMM dd, yyyy') : 'TBD';
};

/* Update "heatPosition" value in WSL heat scores, based on "heatScore" */
export const getUpdatedWSLRanksInScores = (heatScores: any[]) => {
  const scores = heatScores.slice().map((score) => {
    return !score.toDelete ? +score.heatScore : -1;
  });
  const sortedScores = scores.slice().sort((a, b) => b - a);
  const ranks = scores.map((score) => {
    return score === -1 ? '' : sortedScores.indexOf(score) + 1;
  });
  return heatScores.map((score, idx) => {
    score.heatPosition = ranks[idx];
    return score;
  });
};

/* Update "heatPosition" value in SLS heat scores, based on "heatScore"
 Also calculates and updates "roundScore" */
export const getUpdatedSLSRanksInScores = (heatScores: any[]) => {
  const scores = heatScores.map((roundScores) => {
    const roundScore = !roundScores?.toDelete ? calcSLSRoundScores(roundScores) : -1;
    return roundScore;
  });
  const sortedScores = scores.slice().sort((a, b) => b - a);
  const ranks = scores.map((score) => {
    return score === -1 ? '' : sortedScores.indexOf(score) + 1;
  });
  return heatScores.map((score, idx) => {
    score.heatPosition = ranks[idx];
    score.roundScore = scores[idx];
    return score;
  });
};

/* Update "heatPosition" value in NRX heat scores, based on "Total time" or "Best lap time" */
export const getUpdatedNRXRanksInScores = (
  scores: HeatScoresDTO[],
  lapsArr: number[],
  roundNo: number,
  firstRoundNo: number,
) => {
  const clonedScores = scores.slice();
  const addTotalTimeScores = clonedScores.map((score: any) => {
    let totalSeconds = 0;
    let noOfLapsCompleted = 0;
    const penaltyTime = Number(
      formatMMSSToSeconds(score?.penaltyTime.toString() || DEFAULT_START_OF_LAP_TIME),
    );
    lapsArr.map((lap: number) => {
      const calcSec = Number(
        formatMMSSToSeconds(score['lap_' + lap]?.lapTime || DEFAULT_START_OF_LAP_TIME),
      );
      // If round is first round, then relevant column is "Total times",
      // else the column is "best lap time" (shortest time among lap times)
      if (roundNo === firstRoundNo) {
        if (calcSec > 0) {
          if (totalSeconds === 0) {
            totalSeconds = +calcSec;
          } else if (calcSec < totalSeconds) {
            totalSeconds = +calcSec;
          }
        }
      } else {
        if (calcSec > 0) {
          noOfLapsCompleted++;
        }

        if (calcSec > 0) {
          totalSeconds += +calcSec;
        }
      }
      return lap;
    });
    score.totalTime = totalSeconds + +penaltyTime;
    score.noOfLapsCompleted = noOfLapsCompleted;
    return score;
  });

  // ordering scores based on following condition
  const sortedScores = [
    ...orderBy(
      // filtering athlete scores which are not deleted, whose total time is greater then 0 and who hasn't been disqualified.
      addTotalTimeScores
        .slice()
        .filter(
          (scr: any) =>
            !scr?.toDelete &&
            (scr?.totalTime || 0) > 0 &&
            scr.status &&
            scr.status.value !== NRX_HEAT_SCORES_STATUS.DSQ,
        ),
      roundNo === firstRoundNo ? ['totalTime'] : ['noOfLapsCompleted', 'totalTime'],
      roundNo === firstRoundNo ? ['asc'] : ['desc', 'asc'],
    ),
    // filtering athlete scores which are not deleted, whose total time is equal to 0 and who hasn't been disqualified.
    ...addTotalTimeScores
      .slice()
      .filter(
        (scr: any) =>
          !scr?.toDelete &&
          (scr?.totalTime || 0) === 0 &&
          scr.status &&
          scr.status.value !== NRX_HEAT_SCORES_STATUS.DSQ,
      ),
    // filtering athlete scores who have been disqualified.
    ...addTotalTimeScores
      .slice()
      .filter((scr: any) => scr.status && scr.status.value === NRX_HEAT_SCORES_STATUS.DSQ),
    // filtering athlete scores which are deleted.
    ...addTotalTimeScores.slice().filter((scr: any) => scr?.toDelete),
  ];
  // Assign a rank to each row that has a valid best lap time.
  addTotalTimeScores.forEach((score: any) => {
    score.heatPosition =
      score?.toDelete ||
      (!score?.toDelete && (score?.totalTime || 0) === 0) ||
      (score.status && score.status.value === NRX_HEAT_SCORES_STATUS.DSQ)
        ? ''
        : sortedScores.findIndex((a) => a.roundSeed === score.roundSeed) + 1;
    score.totalTime = formatSecondsToMMSS((score?.totalTime || 0).toString());
    delete score.noOfLapsCompleted;
  });
  return addTotalTimeScores;
};

/* Update "heatPosition" value in HLRS heat scores, based on "Total time" or "Best lap time" */
export const getUpdatedHLRSRanksInScores = (
  scores: HeatScoresDTO[],
  lapsArr: number[],
  roundNo: number,
  firstRoundNo: number,
) => {
  const clonedScores = scores.slice();
  const addTotalTimeScores = clonedScores.map((score: any) => {
    let totalSeconds = 0;
    let noOfLapsCompleted = 0;
    const penaltyTime = Number(
      formatMMSSToSeconds(score?.penaltyTime.toString() || DEFAULT_START_OF_LAP_TIME),
    );
    lapsArr.map((lap: number) => {
      const calcSec = Number(
        formatMMSSToSeconds(score['lap_' + lap]?.lapTime || DEFAULT_START_OF_LAP_TIME),
      );
      // If round is first round, then relevant column is "Total times",
      // else the column is "best lap time" (shortest time among lap times)
      if (roundNo === firstRoundNo) {
        if (calcSec > 0) {
          if (totalSeconds === 0) {
            totalSeconds = +calcSec;
          } else if (calcSec < totalSeconds) {
            totalSeconds = +calcSec;
          }
        }
      } else {
        if (calcSec > 0) {
          noOfLapsCompleted++;
        }

        if (calcSec > 0) {
          totalSeconds += +calcSec;
        }
      }
      return lap;
    });
    score.totalTime = totalSeconds + +penaltyTime;
    score.noOfLapsCompleted = noOfLapsCompleted;
    return score;
  });

  // ordering scores based on following condition
  const sortedScores = [
    ...orderBy(
      // filtering athlete scores which are not deleted, whose total time is greater then 0 and who hasn't been disqualified.
      addTotalTimeScores
        .slice()
        .filter(
          (scr: any) =>
            !scr?.toDelete &&
            (scr?.totalTime || 0) > 0 &&
            scr.status &&
            scr.status.value !== HLRS_HEAT_SCORES_STATUS.DSQ,
        ),
      roundNo === firstRoundNo ? ['totalTime'] : ['noOfLapsCompleted', 'totalTime'],
      roundNo === firstRoundNo ? ['asc'] : ['desc', 'asc'],
    ),
    // filtering athlete scores which are not deleted, whose total time is equal to 0 and who hasn't been disqualified.
    ...addTotalTimeScores
      .slice()
      .filter(
        (scr: any) =>
          !scr?.toDelete &&
          (scr?.totalTime || 0) === 0 &&
          scr.status &&
          scr.status.value !== HLRS_HEAT_SCORES_STATUS.DSQ,
      ),
    // filtering athlete scores who have been disqualified.
    ...addTotalTimeScores
      .slice()
      .filter((scr: any) => scr.status && scr.status.value === HLRS_HEAT_SCORES_STATUS.DSQ),
    // filtering athlete scores which are deleted.
    ...addTotalTimeScores.slice().filter((scr: any) => scr?.toDelete),
  ];
  // Assign a rank to each row that has a valid best lap time.
  addTotalTimeScores.forEach((score: any) => {
    score.heatPosition =
      score?.toDelete ||
      (!score?.toDelete && (score?.totalTime || 0) === 0) ||
      (score.status && score.status.value === HLRS_HEAT_SCORES_STATUS.DSQ)
        ? ''
        : sortedScores.findIndex((a) => a.roundSeed === score.roundSeed) + 1;
    score.totalTime = formatSecondsToMMSS((score?.totalTime || 0).toString());
    delete score.noOfLapsCompleted;
  });
  return addTotalTimeScores;
};

/* Update "heatPosition" value in FDRIFT heat scores, based on "Total time" or "Best lap time" */
export const getUpdatedFDRIFTRanksInScores = (
  scores: HeatScoresDTO[],
  lapsArr: number[],
  roundNo: number,
  firstRoundNo: number,
) => {
  const clonedScores = scores.slice();
  const addTotalTimeScores = clonedScores.map((score: any) => {
    let totalSeconds = 0;
    let noOfLapsCompleted = 0;
    const penaltyTime = Number(
      formatMMSSToSeconds(score?.penaltyTime?.toString() || DEFAULT_START_OF_LAP_TIME),
    );
    lapsArr.map((lap: number) => {
      const calcSec = Number(
        formatMMSSToSeconds(score['lap_' + lap]?.lapTime || DEFAULT_START_OF_LAP_TIME),
      );
      // If round is first round, then relevant column is "Total times",
      // else the column is "best lap time" (shortest time among lap times)
      if (roundNo === firstRoundNo) {
        if (calcSec > 0) {
          if (totalSeconds === 0) {
            totalSeconds = +calcSec;
          } else if (calcSec < totalSeconds) {
            totalSeconds = +calcSec;
          }
        }
      } else {
        if (calcSec > 0) {
          noOfLapsCompleted++;
        }

        if (calcSec > 0) {
          totalSeconds += +calcSec;
        }
      }
      return lap;
    });
    score.totalTime = totalSeconds + +penaltyTime;
    score.noOfLapsCompleted = noOfLapsCompleted;
    return score;
  });

  // ordering scores based on following condition
  const sortedScores = [
    ...orderBy(
      // filtering athlete scores which are not deleted, whose total time is greater then 0 and who hasn't been disqualified.
      addTotalTimeScores
        .slice()
        .filter(
          (scr: any) =>
            !scr?.toDelete &&
            (scr?.totalTime || 0) > 0 &&
            scr.status &&
            scr.status.value !== NRX_HEAT_SCORES_STATUS.DSQ,
        ),
      roundNo === firstRoundNo ? ['totalTime'] : ['noOfLapsCompleted', 'totalTime'],
      roundNo === firstRoundNo ? ['asc'] : ['desc', 'asc'],
    ),
    // filtering athlete scores which are not deleted, whose total time is equal to 0 and who hasn't been disqualified.
    ...addTotalTimeScores
      .slice()
      .filter(
        (scr: any) =>
          !scr?.toDelete &&
          (scr?.totalTime || 0) === 0 &&
          scr.status &&
          scr.status.value !== FDRIFT_HEAT_SCORES_STATUS.DSQ,
      ),
    // filtering athlete scores who have been disqualified.
    ...addTotalTimeScores
      .slice()
      .filter((scr: any) => scr.status && scr.status.value === FDRIFT_HEAT_SCORES_STATUS.DSQ),
    // filtering athlete scores which are deleted.
    ...addTotalTimeScores.slice().filter((scr: any) => scr?.toDelete),
  ];
  // Assign a rank to each row that has a valid best lap time.
  addTotalTimeScores.forEach((score: any) => {
    score.heatPosition =
      score?.toDelete ||
      (!score?.toDelete && (score?.totalTime || 0) === 0) ||
      (score.status && score.status.value === FDRIFT_HEAT_SCORES_STATUS.DSQ)
        ? ''
        : sortedScores.findIndex((a) => a.roundSeed === score.roundSeed) + 1;
    score.totalTime = formatSecondsToMMSS((score?.totalTime || 0).toString());
    delete score.noOfLapsCompleted;
  });
  return addTotalTimeScores;
};

/* Update "heatPosition" value in NRX heat scores, based on "Total time" or "Best lap time" */
export const getUpdatedNHRARanksInScores = (scores: HeatScoresDTO[]) => {
  const clonedScores = scores.slice();

  const addTotalTimeScores = clonedScores.map((score: any) => {
    const lapTime = formatSecondsToMMSS(score.lapTime);
    score.lapTempTime = {
      lapTime,
      storedId: score.id,
    };
    return score;
  });

  // Sort the athletes based on their lap times (ascending, so the lower lap time is first)
  const sortedScores = [...addTotalTimeScores].sort((a, b) => {
    if (a.lapTime == null) return 1;
    if (b.lapTime == null) return -1;

    // If both `lapTime` values are valid, sort them numerically
    return Number(a.lapTime) - Number(b.lapTime);
  });

  // Map heat positions based on the sorted order of lap times
  const updatedScores = addTotalTimeScores.map((score: any) => {
    const rank = sortedScores.findIndex((sortedScore: any) => sortedScore.id === score.id) + 1;
    score.heatPosition = rank;

    return score;
  });
  return updatedScores;
};

/* Update "heatPosition" value in NRX heat scores, based on "Total time" or "Best lap time" */
export const getUpdatedUSACRanksInScores = (
  scores: HeatScoresDTO[],
  lapsArr: number[],
  roundNo: number,
  firstRoundNo: number,
) => {
  const clonedScores = scores.slice();
  const addTotalTimeScores = clonedScores.map((score: any) => {
    let totalSeconds = 0;
    let noOfLapsCompleted = 0;
    lapsArr.map((lap: number) => {
      const calcSec = Number(
        formatMMSSToSeconds(score['lap_' + lap]?.lapTime || DEFAULT_START_OF_LAP_TIME),
      );
      // If round is first round, then relevant column is "Total times",
      // else the column is "best lap time" (shortest time among lap times)
      if (roundNo === firstRoundNo) {
        if (calcSec > 0) {
          if (totalSeconds === 0) {
            totalSeconds = +calcSec;
          } else if (calcSec < totalSeconds) {
            totalSeconds = +calcSec;
          }
        }
      } else {
        if (calcSec > 0) {
          noOfLapsCompleted++;
        }

        if (calcSec > 0) {
          totalSeconds += +calcSec;
        }
      }
      return lap;
    });
    score.totalTime = totalSeconds;
    score.noOfLapsCompleted = noOfLapsCompleted;
    return score;
  });

  // ordering scores based on following condition
  const sortedScores = [
    ...orderBy(
      // filtering athlete scores which are not deleted, whose total time is greater then 0.
      addTotalTimeScores.slice().filter((scr: any) => !scr?.toDelete && (scr?.totalTime || 0) > 0),
      roundNo === firstRoundNo ? ['totalTime'] : ['noOfLapsCompleted', 'totalTime'],
      roundNo === firstRoundNo ? ['asc'] : ['desc', 'asc'],
    ),
    // filtering athlete scores which are not deleted, whose total time is equal to 0.
    ...addTotalTimeScores
      .slice()
      .filter((scr: any) => !scr?.toDelete && (scr?.totalTime || 0) === 0),
    // filtering athlete scores who have been disqualified.
    ...addTotalTimeScores.slice(),
    // filtering athlete scores which are deleted.
    ...addTotalTimeScores.slice().filter((scr: any) => scr?.toDelete),
  ];
  // Assign a rank to each row that has a valid best lap time.
  addTotalTimeScores.forEach((score: any) => {
    score.heatPosition =
      score?.toDelete || (!score?.toDelete && (score?.totalTime || 0) === 0)
        ? ''
        : sortedScores.findIndex((a) => a.roundSeed === score.roundSeed) + 1;
    score.totalTime = formatSecondsToMMSS((score?.totalTime || 0).toString());
    delete score.noOfLapsCompleted;
  });
  return addTotalTimeScores;
};

/* Update "heatPosition" value in NRX heat scores, based on "Total time" or "Best lap time" */
export const getUpdatedSPRMTCRSRanksInScores = (
  scores: HeatScoresDTO[],
  lapsArr: number[],
  roundNo: number,
  firstRoundNo: number,
) => {
  const clonedScores = scores.slice();
  const addTotalTimeScores = clonedScores.map((score: any) => {
    let totalSeconds = 0;
    let noOfLapsCompleted = 0;
    lapsArr.map((lap: number) => {
      const calcSec = Number(
        formatMMSSToSeconds(score['lap_' + lap]?.lapTime || DEFAULT_START_OF_LAP_TIME),
      );
      // If round is first round, then relevant column is "Total times",
      // else the column is "best lap time" (shortest time among lap times)
      if (roundNo === firstRoundNo) {
        if (calcSec > 0) {
          if (totalSeconds === 0) {
            totalSeconds = +calcSec;
          } else if (calcSec < totalSeconds) {
            totalSeconds = +calcSec;
          }
        }
      } else {
        if (calcSec > 0) {
          noOfLapsCompleted++;
        }

        if (calcSec > 0) {
          totalSeconds += +calcSec;
        }
      }
      return lap;
    });
    score.totalTime = totalSeconds;
    score.noOfLapsCompleted = noOfLapsCompleted;
    return score;
  });

  // ordering scores based on following condition
  const sortedScores = [
    ...orderBy(
      // filtering athlete scores which are not deleted, whose total time is greater then 0.
      addTotalTimeScores.slice().filter((scr: any) => !scr?.toDelete && (scr?.totalTime || 0) > 0),
      roundNo === firstRoundNo ? ['totalTime'] : ['noOfLapsCompleted', 'totalTime'],
      roundNo === firstRoundNo ? ['asc'] : ['desc', 'asc'],
    ),
    // filtering athlete scores which are not deleted, whose total time is equal to 0.
    ...addTotalTimeScores
      .slice()
      .filter((scr: any) => !scr?.toDelete && (scr?.totalTime || 0) === 0),
    // filtering athlete scores who have been disqualified.
    ...addTotalTimeScores.slice(),
    // filtering athlete scores which are deleted.
    ...addTotalTimeScores.slice().filter((scr: any) => scr?.toDelete),
  ];
  // Assign a rank to each row that has a valid best lap time.
  addTotalTimeScores.forEach((score: any) => {
    score.heatPosition =
      score?.toDelete || (!score?.toDelete && (score?.totalTime || 0) === 0)
        ? ''
        : sortedScores.findIndex((a) => a.roundSeed === score.roundSeed) + 1;
    score.totalTime = formatSecondsToMMSS((score?.totalTime || 0).toString());
    delete score.noOfLapsCompleted;
  });
  return addTotalTimeScores;
};

export const checkIfJokerLapTaken = (
  scores: HeatScoresDTO[],
  players: AthleteDTO[],
  lapsArr: number[],
) => {
  const hasJokerLapBeenTakenInfo: { [key: string]: boolean } = {};
  const scoresClone = JSON.parse(JSON.stringify(scores));
  const validScores = scoresClone.filter((score: any) => score.athlete !== undefined);
  validScores.map((rowData: any) => {
    const athlete = players.find((athlete: any) => athlete.id === rowData.athlete.id);
    if (athlete) {
      let hasUsedJoker = false;
      lapsArr.map((lap: any) => {
        if (rowData['lap_' + lap]?.isJoker) {
          hasUsedJoker = true;
        }
        return lap;
      });
      hasJokerLapBeenTakenInfo[athlete?.id as keyof {}] = hasUsedJoker;
    }
    return rowData;
  });
  return hasJokerLapBeenTakenInfo;
};

export const calcSLSRoundScores = (data: HeatScoresDTO) => {
  const trickScores: number[] = [];
  Object.keys(data).forEach((key) => {
    if (key.includes('trick')) {
      trickScores.push(Number(data[key as keyof HeatScoresDTO] || 0));
    }
  });
  const lineScore1 = +data?.lineScore1;
  const lineScore2 = +data?.lineScore2;
  // descending order sort

  // const roundScore = [...trickScores, lineScore1, lineScore2]
  //   .sort((a, b) => b - a)
  //   .slice(0, 4)
  //   .reduce((total, score) => total + score, 0);

  const roundScore =
    Math.max(lineScore1, lineScore2) +
    [...trickScores]
      .sort((a, b) => b - a)
      .slice(0, 3)
      .reduce((total, score) => total + score, 0);

  return roundScore;
};
