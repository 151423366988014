import { useQuery } from '@tanstack/react-query';
import keys from '@/constants/queryKeys';
import { authenticatedAxios } from '@/hooks/index';
import { SportTourFutures } from '@/types/futures';

const getSportTourFutures = async ({
  sportType,
  tourYearId,
}: {
  sportType: string;
  tourYearId: string;
}): Promise<SportTourFutures> => {
  const {
    data: { data = {} },
  } = await authenticatedAxios({
    method: 'GET',
    url: `admin/${sportType}/futures/tour/${tourYearId}`,
  });

  return data;
};
// sportType: string, eventId: string, fromComponent: string
export default function useFetchSportTourFutures({
  sportType,
  tourYearId,
  fromComponent,
}: {
  sportType: string;
  tourYearId: string;
  fromComponent: string;
}) {
  return useQuery(
    [keys.futures.fetchSportTourFutures, sportType, tourYearId, fromComponent],
    () => getSportTourFutures({ sportType, tourYearId }),
    {
      staleTime: Infinity,
      refetchInterval: 10 * (60 * 1000),
      refetchIntervalInBackground: true,
      enabled: !!tourYearId,
    },
  );
}
