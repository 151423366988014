import * as React from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { styled } from '@mui/material';

import { processEventStatus } from '@/helpers/events';

import routes from '@/constants/routes';
import { SPORT_NAMES } from '@/constants/sportTypes';

interface EventBreadCrumbsProps {
  name: string;
  eventStatus: number;
  tour?: { name: string };
  league?: { name: string };
  sportType: string;
  year?: number;
}

export const BreadcrumbLink = styled(Link)(({ theme }) => ({
  color: theme.palette.info.contrastText,
  fontSize: '0.812rem',
  fontWeight: '400',
  '&.eventName': {
    color: theme.palette.info.main,
  },
}));

const EventBreadcrumbs = ({ event }: { event: EventBreadCrumbsProps }) => {
  const {
    name = '',
    eventStatus = 0,
    tour: { name: tourName } = { name: '' },
    league: { name: leagueName } = { name: '' },
    sportType = '',
    year = null,
  } = event;

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadcrumbLink underline="hover" color="inherit" href={routes.events.home}>
        Events
      </BreadcrumbLink>
      <BreadcrumbLink underline="hover" color="inherit" href={routes.events.home}>
        {processEventStatus(eventStatus) === 'Completed' ? 'Completed' : 'Live and Upcoming'}
      </BreadcrumbLink>
      <BreadcrumbLink underline="hover" color="inherit" href={routes.events.home}>
        {SPORT_NAMES[sportType as keyof typeof SPORT_NAMES]}
      </BreadcrumbLink>
      <BreadcrumbLink underline="hover" color="inherit" href={routes.events.home}>
        {tourName || leagueName}
      </BreadcrumbLink>
      <BreadcrumbLink underline="hover" color="inherit" href={routes.events.home}>
        {year?.toString()}
      </BreadcrumbLink>
      <BreadcrumbLink className="eventName" underline="none">
        {name}
      </BreadcrumbLink>
    </Breadcrumbs>
  );
};

export default EventBreadcrumbs;
