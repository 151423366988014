import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import ODDS_TYPES from '@/constants/oddsTypes';

const { persistAtom } = recoilPersist();

const oddsType = atom({
  key: 'oddsTypeState',
  default: ODDS_TYPES.AMERICAN,
  effects_UNSTABLE: [persistAtom],
});

export { oddsType };
