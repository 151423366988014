import { Box } from '@mui/material';

import { MASLBetTypes } from '@/constants/masl';

import {
  // Styled Components
  GridCell,
  CellLabel,
} from '../components';

const BetTypeHeaders = ({ betType, commonCellHeaderStyles }: any) => {
  const headerConfig = {
    [MASLBetTypes.TOTALS]: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel homeTotalLabel homeTotalLabel homeTotalLabel homeTotalLabel awayTotalLabel awayTotalLabel awayTotalLabel awayTotalLabel blank blank blank blank"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'homeTotalLabel', text: 'Home Total' },
        { area: 'awayTotalLabel', text: 'Away Total' },
        { area: 'blank', text: '' },
        // { area: 'spreadLabel', text: 'Spread' },
        // { area: 'totalLabel', text: 'Total' },
      ],
    },

    default: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel moneyLineLabel moneyLineLabel moneyLineLabel moneyLineLabel spreadLabel spreadLabel spreadLabel spreadLabel totalLabel totalLabel totalLabel totalLabel"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'moneyLineLabel', text: 'Money Line' },
        { area: 'spreadLabel', text: 'Spread' },
        { area: 'totalLabel', text: 'Total' },
      ],
    },
  };

  const { gridTemplateAreas, labels } = headerConfig[betType] || headerConfig.default;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(20, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(1, minmax(0, 50px))',
        mt: 1,
        ...{ gridTemplateAreas }, // Spread the gridTemplateAreas
      }}
    >
      {labels.map(({ area, text }) => (
        <GridCell
          key={area}
          sx={{
            gridArea: area,
            borderTopLeftRadius: area === 'teamLabel' ? '0.25rem' : undefined,
            borderTopRightRadius: area === 'totalLabel' ? '0.25rem' : undefined,
            ...commonCellHeaderStyles,
          }}
        >
          <CellLabel>{text}</CellLabel>
        </GridCell>
      ))}
    </Box>
  );
};

export default BetTypeHeaders;
