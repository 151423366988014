import { useState, useEffect } from 'react';

// import { getNumberFromCharacters } from '@/helpers/misc';
// import { format } from 'date-fns';
import useFetchUserList from '@/hooks/user/useFetchUserList';
import useFetchClientList from '@/hooks/user/useFetchClientList';
import { ROLE_OPTIONS } from '@/constants/user';
import { SPORT_NAMES } from '@/constants/sportTypes';

const userListFormatter = (result: any) => {
  return {
    ...result,
    userTypeMod: result?.userType
      ? ROLE_OPTIONS?.find((userTypeOpt: any) => userTypeOpt.value === result?.userType)?.label
      : '',
    userRestrictedSportsMod:
      result?.userRestrictedSports && result?.userRestrictedSports?.length > 0
        ? result?.userRestrictedSports?.map((sport: string) => SPORT_NAMES[sport])
        : [],
    name: [result?.firstName, result?.middleName, result?.lastName].filter(Boolean).join(' '),
    // weightClass: result?.weightClass || '',
    // coinTossWinnerWinsMatch: result?.coinTossWinnerWinsMatch ? 'Yes' : 'No',
    // coinTossWinnerWinsByFirstRound: result?.coinTossWinnerWinsByFirstRound ? 'Yes' : 'No',
    // date: result?.date ? format(new Date(result?.date), 'MM-dd-yyyy') : '',
  };
};

const clientListFormatter = (result: any) => {
  return {
    ...result,
    name: [result?.firstName, result?.middleName, result?.lastName].filter(Boolean).join(' '),
    // corner: result?.corner ? v.titleCase(result.corner) : '',
    // other: result?.other ? v.titleCase(result.other) : '',
    // coinTossWinnerWinsByFirstRound: result?.coinTossWinnerWinsByFirstRound ? 'Yes' : 'No',
    // kd: result?.kd ? 'Yes' : 'No',
    // step: result?.step ? 'Yes' : 'No',
    // stagger: result?.stagger ? 'Yes' : 'No',
    // round: getNumberFromCharacters(result?.round || ''),
    // date: result?.date ? format(new Date(result?.date), 'MM-dd-yyyy') : '',
  };
};

const useUserManagement = () => {
  const [modUserList, setModUserList] = useState<any[]>([]);
  const [modClientList, setModClientList] = useState<any[]>([]);

  const { data: userList = [] } = useFetchUserList();
  const { data: clientList = [] } = useFetchClientList();

  useEffect(() => {
    if (userList && userList.length > 0) {
      setModUserList(userList.map(userListFormatter));
    }
  }, [userList]);

  useEffect(() => {
    if (clientList && clientList.length > 0) {
      setModClientList(clientList.map(clientListFormatter));
    }
  }, [clientList]);

  return {
    modUserList,
    modClientList,
  };
};

export default useUserManagement;
