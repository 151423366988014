import v from 'voca';

export const countKeysWithObjectValues = (obj: { [key: string]: any }): number => {
  return Object.keys(obj).reduce((count, key) => {
    if (typeof obj[key] === 'object') {
      return count + 1;
    }
    return count;
  }, 0);
};

export const getKeysWithObjectValues = (obj: { [key: string]: any }): string[] =>
  Object.keys(obj).filter((key) => typeof obj[key] === 'object');

export const getNumberFromCharacters = (str: any): number => {
  const strAsString = String(str);
  const numericOnly = strAsString.replace(/[^0-9]/g, '');
  return parseFloat(numericOnly) || 0;
};

export const getFirstLettersCombined = (text: string) =>
  v
    .words(text)
    .map((word) => v.first(word))
    .join('');
