import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import { EventScoresSkeleton } from '@/components/PageSkeletons';
// import Head from '@/components/Head';
// import EventDetails from '@/components/EventDetails';
// import EventTabs from '@/components/EventTabs';

import SportTourFuturesDetails from '@/components/SportTourFuturesDetails';
import { FutureOddsContainer } from '@/features/odds';

// import { SportTourFuturesScores } from '@/features/scores';
// import { Odds } from '@/features/odds';
// import { EventSeeds, MOTOCRSTableEventSeedsColumns } from '@/features/seeds';
// import SPORT_TYPES from '@/constants/sportTypes';

// import routes from '@/constants/routes';

import useSportTourFutures from './useSportTourFutures';

const SportTourFutures = () => {
  const { sportType = '', tourYearId = '' } = useParams();
  const { sportTourFutures, isLoading } = useSportTourFutures();

  //   const Tabs = React.useMemo(
  //     () => (
  //       <EventTabs
  //         currentTabValue={currentTabValue}
  //         setNextTabValue={setNextTabValue}
  //         SeedsElement={
  //           undefined
  //           // () => (
  //           //   <EventSeeds sportType={SPORT_TYPES.MOTOCRS} columns={MOTOCRSTableEventSeedsColumns} />
  //           // )
  //         }
  //         ScoresElement={
  //           undefined
  //           // () =>
  //           // <SportTourFuturesScores />
  //         }
  //         OddsElement={() => <Odds sport={SPORT_TYPES.MOTOCRS} event={event} />}
  //       />
  //     ),
  //     [currentTabValue, event],
  //   );

  if (isLoading)
    return (
      <Box>
        {/* <Head title={sportTourFutures?.name || ''} canonicalUrl={routes.sportsType.futures.tourYearId} /> */}
        <EventScoresSkeleton />{' '}
      </Box>
    );

  return (
    <Box>
      {/* <Head title={event?.name || ''} canonicalUrl={routes.sportsType.futures.tourYearId} /> */}
      {sportTourFutures && (
        <>
          <SportTourFuturesDetails
            sportType={sportType}
            tourYearId={tourYearId}
            sportTourFutures={sportTourFutures}
          />
          <FutureOddsContainer
            sportType={sportType}
            tourYearId={tourYearId}
            markets={sportTourFutures?.markets || []}
            sportTourFutures={sportTourFutures}
          />
        </>
      )}
      {/* {Tabs} */}
    </Box>
  );
};

export default SportTourFutures;
