import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import v from 'voca';

import { sportsType } from '@/atoms/sportsType';

import Dropzone from '@/components/Dropzone';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import FilterSport from '@/components/FilterSport';
import Head from '@/components/Head';

import useAddTraderPodiumOdds from '@/hooks/traders/odds/useAddTraderPodiumOdds';

import routes from '@/constants/routes';
import { sportOptions } from '@/constants/sportTypes';
import {
  TraderPodiumOddsRow,
  allowedTableHeaders,
  requiredPayloadKeys,
} from '@/pages/trader/odds/podium/upload/constants';

import { TraderPodiumOdd } from '@/types/traderOddUpload';

import { requiredPropertyChecker, invalidRowErrorMsgHelper } from '@/helpers/uploadHelpers';
import * as oddsHelpers from '@/helpers/odds';
import ODDS_TYPES from '@/constants/oddsTypes';

export default function TraderPodiumOddsUpload() {
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [allowedHeaders, setAllowedHeaders] = useState([]);
  const { mutate: addTraderPodiumOdds } = useAddTraderPodiumOdds();
  const [selectedSport] = useRecoilState(sportsType);
  const [sheetData, setSheetData] = useState<TraderPodiumOdd[]>([]);

  const handleToggleConfirmDialog = () => setIsDialogOpen(!isDialogOpen);

  useEffect(() => {
    if (disableBtn) {
      setTimeout(() => {
        const modSheet = JSON.parse(JSON.stringify(sheetData));
        addTraderPodiumOdds(
          {
            sport: selectedSport,
            requestPayload: {
              items: modSheet.map((data: any) => {
                delete data.americanOdds;
                return data;
              }),
            },
          },
          {
            onSuccess: () => {
              enqueueSnackbar('Added Podium Odds Successfully');
              setDisableBtn(false);
            },
            onError: () => {
              setDisableBtn(false);
            },
          },
        );
      }, 600);
    }
  }, [disableBtn]);

  useEffect(() => {
    if (selectedSport) {
      const headers: any = allowedTableHeaders || [];
      setAllowedHeaders(headers);
    }
  }, [selectedSport]);

  const handleChange = () => setSheetData([]);

  const tableRowChecker = (rows: TraderPodiumOddsRow[]) => {
    let message = '';
    const requiredKeys = requiredPayloadKeys;
    const requiredKeysSet = new Set(requiredKeys);

    rows.find((row, index) => {
      const isValidRow = requiredPropertyChecker(requiredKeys, row);
      if (!isValidRow) {
        const missingColumns = invalidRowErrorMsgHelper(requiredKeysSet, new Set(Object.keys(row)));
        message = `Error on row { ${index + 2} }, Missing { ${missingColumns} }`;
      }
      // To loop through all rows
      return !isValidRow;
    });

    return message;
  };

  const tableDataModifierFn = (data: TraderPodiumOddsRow) => {
    const { event, tour_name, year, athlete, american_odds, gender } = data;
    const odds = oddsHelpers.getDecimalOddsFromOtherTypes(ODDS_TYPES.AMERICAN, american_odds);
    return {
      event,
      tourName: tour_name,
      gender,
      year,
      athlete,
      americanOdds: american_odds,
      odds: odds,
    };
  };

  return (
    <Box>
      <Head title={'Altsportsdata - Upload'} canonicalUrl={routes.traders.odds.podiums.upload} />
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <Typography variant="h5" sx={{ fontWeight: '500' }}>
            Manage Podium Odds
          </Typography>
        </Grid>
        <Grid item xs={4} display="flex" justifyContent="flex-end">
          <FilterSport handleChange={handleChange} />
        </Grid>
      </Grid>
      <Dropzone
        title="Podium Odds"
        sheetName="Podium Odds"
        sheetData={sheetData}
        setSheetData={setSheetData}
        allowedHeaders={allowedHeaders}
        tableRowChecker={tableRowChecker}
        tableDataModifierFn={tableDataModifierFn}
      />

      {sheetData.length > 0 ? (
        <Grid container justifyContent="flex-end" sx={{ mt: '2rem' }}>
          <Button variant="contained" disabled={disableBtn} onClick={handleToggleConfirmDialog}>
            Upload Data
          </Button>
        </Grid>
      ) : (
        ''
      )}
      {isDialogOpen && (
        <ConfirmDialog
          open={isDialogOpen}
          handleClose={handleToggleConfirmDialog}
          handleConfirm={() => {
            handleToggleConfirmDialog();
            setDisableBtn(true);
          }}
          title={`ARE YOU SURE YOU WANT TO UPLOAD ${v.upperCase(
            sportOptions.find((opt) => opt.value === selectedSport)?.label || '',
          )} PODIUM ODDS?`}
          body={'This will upload the podium odds.'}
        />
      )}
    </Box>
  );
}
